import { db } from "@/firebase"
const project_partners_ref = db.collection("project_partners")

const createProjectPartners = async (data) => {
    try {
        const get_data = await project_partners_ref.add({ ...data, deletedAt: null });
        await editProjectPartners(get_data.id, { ...data, id_doc: get_data.id })
        return get_data.id;
    } catch (error) {
        throw new Error("createProjectPartners Fail!" + error)
    }
}
const editProjectPartners = async (idDoc, data) => {
    try {
        await project_partners_ref.doc(idDoc).update(data);
        return true;
    } catch (error) {
        throw new Error("editProjectPartners Fail!" + error)
    }
}
async function getProjectPartners() {
    let project_partner_list = []
    try {
        const unitsRef = await project_partners_ref.where("deletedAt", "==", null).get()
        unitsRef.forEach((doc) => project_partner_list.push(doc.data()));
        return project_partner_list
    } catch (error) {
        console.error(error)
        throw new Error("getProjectPartners Fail!" + error)
    }
}
async function getProjectPartnersByIdFranchisee(IdFranchise) {
    let project_partner_list = []
    try {
        const unitsRef = await project_partners_ref
            .where("id_franchisee", "==", IdFranchise)
            .where("deletedAt", "==", null)
            .get()
        unitsRef.forEach((doc) => project_partner_list.push(doc.data()));
        return project_partner_list
    } catch (error) {
        console.error(error)
        throw new Error("getProjectPartnersByIdFranchisee Fail!" + error)
    }
}
async function getProjectPartnersById(idProjectPartner) {
    try {
        const projectPartner = await project_partners_ref.doc(idProjectPartner).get()
        return projectPartner.data()
    } catch (error) {
        console.error(error)
        throw new Error("getProjectPartnersById Fail!" + error)
    }
}
async function getProjectPartnersByDocument(document) {
    let project_partner_list = []
    try {
        const unitsRef = await project_partners_ref
            .where("document", "==", document)
            .get()

        unitsRef.forEach((doc) => project_partner_list.push(doc.data()));
        return project_partner_list.length ? project_partner_list[0] : null
    } catch (error) {
        console.error(error)
        throw new Error("getProjectPartnersByDocument Fail!" + error)
    }
}

export default {
    getProjectPartnersByIdFranchisee,
    getProjectPartnersByDocument,
    getProjectPartnersById,
    createProjectPartners,
    editProjectPartners,
    getProjectPartners,
};