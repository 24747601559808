import apiService from "./BaseApi";
const ENDPOINT_PATH = "bucket/";
import store from '@/store/index'
const BucketService = {
    copyFolder: async (currentPath, destPath) => apiService.post(ENDPOINT_PATH + "copy", {
        currentUserId: store.state.currentUser?.uid,
        currentPath,
        destPath
    }),
    deletePathList: async (filePaths) => apiService.post(ENDPOINT_PATH + "delete", {
        currentUserId: store.state.currentUser?.uid,
        filePaths,
    }),
};

export default BucketService;
