<template>
  <v-container fluid>

    <v-dialog transition="dialog-bottom-transition" max-width="800" v-model="modalFlag">
      <template v-slot:default>
        <v-card>
          <v-overlay absolute style="opacity: 0.8" :value="isWaitingLoraResponse">
            <v-progress-circular indeterminate color="primary" :size="30"></v-progress-circular>
          </v-overlay>
          <v-card-title class="primary lighten white--text">{{ modalTitle }}
            <v-spacer></v-spacer>

            <v-btn text class="litte white--text" @click="FSM_Modem('close-modal')">
              <v-icon white>mdi-window-close</v-icon>

            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-tabs v-model="tabModems">
              <v-tab v-for="selectedTab in tabItems" :key="selectedTab">
                {{ selectedTab }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabModems">
              <v-tab-item key="1">
                <v-row>
                  <v-col cols="6">
                    <v-select v-model="modem.supplyOrigin" :items="supply" label="Fornecedor" type="text" />
                  </v-col>
                  <v-col cols="6">
                    <v-select v-model="modem.deviceCommunicationType" :items="filterConectionType(modem.supplyOrigin)"
                      label="Conexão" type="text" :disabled="!modem.supplyOrigin" />
                  </v-col>
                </v-row>
                <v-row v-show="modem.supplyOrigin">
                  <v-col cols="12">
                    <v-text-field label="Nome do Modem" placeholder="Nome" v-model="modem.name"></v-text-field>
                  </v-col>

                  <v-col cols="12" v-if="modem.supplyOrigin === 'BOTTUMUP'">
                    <v-text-field label="ID do Modem" placeholder="ID" v-model="modem.id" :readonly="isEditingModem" />
                  </v-col>
                  <v-col cols="12" v-else>
                    <v-text-field label="ID do Modem" placeholder="ID" v-model="modem.id" :readonly="true"
                      @click:append="generateID" append-icon="mdi-update" />
                  </v-col>
                  <v-col cols="12" v-if="modem.supplyOrigin === 'BOTTUMUP'">
                    <v-select v-model="modem.model" :items="models" label="Modelo" type="text" />
                  </v-col>
                  <v-col v-if="modalTitle === 'Edição de Modem'" sm="12" cols="12" style="
                          background: #1976D205;
                          border-radius: 15px;
                          border: 1px solid #ccc!important;
                      ">
                    <h2 style="text-align: center;padding-bottom: 10px">{{ sectorCurrentModemLoading ||
                      sectorCurrentModem.name ? "Setor" : "Não existe setor vinculado a esse modem!" }}</h2>
                    <v-divider />
                    <v-row v-if="sectorCurrentModem.name">
                      <v-col cols="12">
                        <v-text-field :loading="sectorCurrentModemLoading" background-color="white" outlined
                          label="Nome" v-model="sectorCurrentModem.name" :readonly="true">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field :loading="unitCurrentSectorLoading" background-color="white" outlined
                          label="Unidade" v-model="unitCurrentSector.name" :readonly="true">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-alert v-if="modemCreationFailed" dense outlined type="error">
                    <strong>{{ modemErrorMsg }}</strong> Tente novamente ou entre em contato com o administrador.
                  </v-alert>
                </v-row>
              </v-tab-item>
              <v-tab-item key="2">
                <br />

                <h3>Alertas de Telemetria</h3>
                <v-row>
                  <v-col cols="11" sm="3">
                    <v-checkbox label="Bateria Fraca" prepend-icon="mdi-battery-charging-low"
                      v-model="modem.alertLowBaterry_IsActive">
                    </v-checkbox>
                  </v-col>
                  <v-col cols="11" sm="3">
                    <v-checkbox label="Falta de Sinal" prepend-icon="mdi-signal-off"
                      v-model="modem.alertUnsignal_IsActive">
                    </v-checkbox>
                  </v-col>
                  <v-col cols="11" sm="3">
                    <v-checkbox label="Não Variação" prepend-icon="mdi-repeat-off"
                      v-model="modem.alertNoVariation_IsActive">
                    </v-checkbox>
                  </v-col>
                  <v-col cols="11" sm="3">
                    <v-checkbox label="Sem Decodificação" prepend-icon="mdi-xml"
                      v-model="modem.alertUndecoded_IsActive">
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-divider style="margin-bottom: 10px"></v-divider>

              </v-tab-item>
              <v-tab-item key="3" v-if="modalTitle === 'Edição de Modem'">
                <v-row>
                  <v-col cols="10">
                    <h3>Atualizações em tempo real</h3>
                  </v-col>
                  <v-col cols="2">
                    <v-btn class="mx-2" fab dark small :color="playTelemetryButton ? 'red' : 'green'"
                      @click="startListenerData()">

                      <v-icon v-if="playTelemetryButton">
                        mdi-stop
                      </v-icon>
                      <v-icon v-else>
                        mdi-play
                      </v-icon>
                    </v-btn></v-col>
                  <v-col cols="6">
                    <v-text-field label="Última Leitura" :value="modemListener.lastReading" placeholder="Sem leituras"
                      type="text" readonly></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field label="Última Dado" :value="modemListener.lastData" placeholder="Sem dados"
                      type="text" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-data-table :headers="headersTelemetry" :items="readingsSnapashot" :items-per-page="5"
                      class="elevation-1"></v-data-table>
                    <!-- {{  readingsSnapashot }} -->
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>

          <v-card-actions class="justify-end">

            <v-btn text @click="FSM_Modem('save-modal', { modem: modem, index: modemIndex })">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <v-card class="rounded-xl">
          <v-overlay absolute style="opacity: 0.8" :value="loadingModens">
            <v-progress-circular indeterminate color="primary" :size="50" />
          </v-overlay>
          <v-data-table :items="filteredItems" :headers="headers" :footer-props="{
            'items-per-page-options': [5, 10, 20, 30]
          }" no-data-text="Sem modems para exibir">

            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title class="title-displays">Modems</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn @click="FSM_Modem('create')" text color="primary">
                  Adicionar
                  <v-icon>add</v-icon>
                </v-btn>
              </v-toolbar>
              <!-- Busca -->
              <v-row style="justify-content: space-between">
                <v-text-field style="width: 80%; padding: 1em" class="px-4" prepend-icon="search" label="Busca"
                  v-model="searchText"></v-text-field>
                <v-select hint="Buscar nessa coluna" persistent-hint style="width: 20%; padding: 1em"
                  v-model="columnToSearch" :items="columnsToChoose" return-object single-line></v-select>
              </v-row>
            </template>
            <template v-slot:item.status="{ item }">
              <v-row>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" :color="getStatusColor(item.lastReading)">circle</v-icon>
                  </template>
                  <span>
                    <p>Verde: Enviou hoje</p>
                    <p>Amarelo: Enviou nesta semana</p>
                    <p>Vermelho: Não enviou a mais de uma semana</p>
                  </span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on"
                      :color="getStatusVariationColor(item.lastVariationAt)">circle</v-icon>
                  </template>
                  <span>
                    <p>{{ getDateVariation(item.lastVariationAt) }}</p>
                    <p>Verde: Variou hoje</p>
                    <p>Amarelo: Variou nesta semana</p>
                    <p>Vermelho: Não Variou a mais de uma semana</p>
                  </span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on"
                      :color="getStatusDecodingColor(item.lastErrorDecodingAt)">circle</v-icon>
                  </template>
                  <span>
                    <p>{{ getDateVariation(item.lastErrorDecodingAt) }}</p>
                    <p>Verde: Falha na decodificação a mais de uma semana</p>
                    <p>Amarelo: Falha na decodificação nesta semana</p>
                    <p>Vermelho: Falha na decodificação hoje</p>
                  </span>
                </v-tooltip>
              </v-row>
            </template>
            <template v-slot:item.lastBattery="{ item }">
              {{ checarBateria(item) }}
            </template>
            <template v-slot:item.lastReadingDate="{ item }">
              {{ stringDate(item) || "Sem leituras" }}
            </template>
            <template v-slot:item.lastData="{ item }">
              {{ item.lastData || "Sem dados" }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" color="primary" style="font-weight: bold" outlined>
                    <v-icon small>
                      mdi-text-box-multiple-outline
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item link v-for="(line, index) in listActions" :key="index" :value="index">
                    <v-list-item-title @click="selectAction(item, line.value)">{{ line.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ModemsScript from './ModemsScript';
export default ModemsScript;
</script>


<style scoped lang="scss">
.title-displays {
  font-size: 25px;
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>