import apiService, { HOST } from "./BaseApi";
const ENDPOINT_PATH = "report/called";

const CalledApi = {
    generatePdf: async (data, date_time) => await apiService.post(ENDPOINT_PATH, { data, date_time }),

    downloadPdf: async (name) => {
        name = name.split(" ").join("_");
        window.open(HOST + `download/pdf/${"Chamados " + name}`)
    },
};

export default CalledApi;
