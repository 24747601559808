import HistoricList from "@/components/Projects/Diagnostic/Tabs/Historic/HistoricList.vue"

const newHistorics = () => {
    return {
        historics: [],
    }
}

export default {
    name: "HistoricTab",
    
    components: {
        HistoricList,
    },
    
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        param: {
            type: Object,
            default: () => ({})
        },
    },
    
    data: () => ({
        is_loading: false,
        diagnostic: {},
        items: newHistorics(),
    }),

    computed: {

    },

    methods: {
        emitDiagnostic() {
            this.$emit('param:update', this.diagnostic)
        },
        emitValidate(status) {
            this.$emit('validate', status)
        },
        addHistoric(historic, index) {
            if (index < 0) {
                this.items.historics.push(historic)
            } else {
                this.items.historics[index] = historic
            }
        },
        removeHistoric(index) {
            this.items.historics = this.items.historics.filter((_, i) => i !== index)
        },
    },

    watch: {
        param: {
            immediate: true,
            handler: function (newValue) {
                if (newValue) {
                    this.diagnostic = newValue
                }
            }
        },
    },

};