import Utils from '@/scripts/Utils';
import Rules from "@/utils/Rules";
import SolutionsGroupServices from "@/services/firebase/SolutionsGroup/SolutionsGroup";
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper";
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper";

export default {
  name: "SolutionsGroup",
  data: () => ({
    page: 1,
    newItem: {
      name: "",
      type: "",
      description: "",
    },
    dataSolutionsGroup: [],
    validForm: true,
    saveButtonLoading: false,
    isLoadingSolutionGroup: false,
    isLoadingSolutionGroupForm: false,
    dialogSolutionGroup: false,
    columnsToChoose: [{ text: "Grupo", value: "name" },{ text: "Tipo", value: "type" }],
    columnToSeach: "name",
    headers: [
      { text: "Grupo", value: "name"},
      { text: "Tipo", value: "type"},
      { text: "Descrição", value: "description" },
      { text: "Ações", value: "actions", sortable: false  },
    ],
    searchText: "",
    formTitle: "Adicionar Grupo",
    deleteItem: null,
  }),
  methods: {
    closeDialog() {
      this.dialogSolutionGroup = false
    },
    openSolutionGroupDialog(item = null) {
      this.dialogSolutionGroup = true
      this.newItem = {}
      if (item) {
        this.formTitle = "Editar Grupo"
        this.newItem = item
      } else {
        this.formTitle = "Adicionar Grupo"
      }
    },
    async saveSolutionGroup() {
      if (this.$refs.addSolutionGroupForm && !this.$refs.addSolutionGroupForm.validate()) return;
      this.isLoadingSolutionGroupForm = true
      try {
        if (this.formTitle === "Editar Grupo") {
          await this.editSolutionGroup()
        } else if (this.formTitle === "Adicionar Grupo") {
          await this.createSolution()
        }
      } catch (err) {
        console.error(err)
        showErrorSnackBar("Ocorreu um erro interno!. Entre em contato com nossa equipe.")
      } finally {
        this.closeDialog();
        this.isLoadingSolutionGroupForm = false
      }
    },
    async listSolutionsGroup() {
      this.isLoadingSolutionGroup = true
      this.dataSolutionsGroup = await SolutionsGroupServices.getSolutionsGroup()
      this.isLoadingSolutionGroup = false
    },
    async createSolution() {
      try {
        const newItemId = await SolutionsGroupServices.addSolutionGroup({ ...this.newItem })
        this.dataSolutionsGroup.unshift({ ...this.newItem, id: newItemId })
        showSuccessSnackBar("Grupo cadastrada com sucesso!")
      } catch (err) {
        throw "Ocorreu um erro ao Criar Grupo!"
      }
    },
    async editSolutionGroup() {
      try {
        await SolutionsGroupServices.updateSolutionsGroup(this.newItem)
        this.dataSolutionsGroup.forEach((solution) => solution === this.newItem ? solution = this.newItem : '')
        showSuccessSnackBar("Grupo editada com sucesso!")
      } catch (err) {
        throw "Ocorreu um erro ao Editar Grupo!"
      }
    },
    async confirmDeleteSolutionsGroup(item) {
      this.deleteItem = item
      showDeleteDialogAlert(`Deseja excluir essa Grupo ${item.name}?`, this.deleteSolutionGroup)
    },
    async deleteSolutionGroup() {
      try {
        await SolutionsGroupServices.deleteSolutionGroup(this.deleteItem.id)
        this.dataSolutionsGroup = this.dataSolutionsGroup.filter((solution) => this.deleteItem != solution)
      } catch (err) {
        console.error(err)
        showErrorSnackBar("Ocorreu um erro interno!. Entre em contato com nossa equipe.")
      }
      this.deleteItem = null
    },
  },
  computed: {
    filterSoluitionsGroup() {
      let text = Utils.defaultSpace(this.searchText)
      let list = this.dataSolutionsGroup
      list = list.filter((solutionGroup) => {
        let value = solutionGroup[this.columnToSeach]
        if (value) return (Utils.defaultSpace(value).toLowerCase().indexOf(text.toLowerCase()) > -1);
      });
      return list
    },
    rulesRequired() {
      return Rules.required();
    },
  },
  mounted() {
    this.listSolutionsGroup();
  },
};