import { mapActions, mapGetters } from "vuex";
import EconomyApi from "@/services/apiPdf/EconomyApi";
import EmailService from "@/services/email/Email";
import firebase from "@/firebase";

import { generateXlsx, generateResumeXlsx } from "./ReportGenerateXLSX";

import FormReport from "@/components/Forms/Report/Report.vue";
import DialogSendEmail from "@/components/DialogSendEmail/DialogSendEmail";
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import Utils from '@/scripts/Utils';
import UsersService from "@/services/server/UsersService";

export default {
  components: {
    FormReport,
    DialogSendEmail
  },
  data() {
    return {
      show_expand: true,
      main_headers: [
        {
          text: 'Cliente',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Referencia', value: 'reference', align: 'center' },
        { text: 'Conta', value: 'account_value', align: 'center' },
        { text: 'Água (M³)', value: 'water_liters', align: 'center' },
        { text: 'Esgoto (M³)', value: 'sewer_liters', align: 'center' },
        { text: 'Economia (R$)', value: 'economy_monetary', align: 'center' },
        { text: 'Economia (%)', value: 'economy_percentage', align: 'center' },
        { text: 'Meta', value: 'goal', align: 'center' },
        { text: 'Ações', value: 'actions', sortable: false, align: 'center' },
        { text: '', value: 'data-table-expand' },
      ],
      secondary_headers: [
        {
          text: 'Unidade',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Referencia', value: 'reference', align: 'center' },
        { text: 'Conta', value: 'account_value', align: 'center' },
        { text: 'Água (M³)', value: 'water_liters', align: 'center' },
        { text: 'Esgoto (M³)', value: 'sewer_liters', align: 'center' },
        { text: 'Economia (R$)', value: 'economy_monetary', align: 'center' },
        { text: 'Economia (%)', value: 'economy_percentage', align: 'center' },
        { text: 'Meta', value: 'goal', align: 'center' },
        { text: 'Ações', value: 'actions', sortable: false, align: 'center' },
      ],
      franchisee_id: null,
      client_id: null,
      unit_id: null,
      startDatePicker: [Utils.formatDate(Utils.get_last_months()[0], '<YYYY>-<MM>'), Utils.formatDate(Utils.get_last_months()[5], '<YYYY>-<MM>')],
      titles_button_download: [{ title: "PDF" }, { title: "XLSX" }, { title: "Gráfico" }, { title: "Email" }],
      last_type_selected: null,
      last_serach_alerts: null,
      loading_units: false,
      data_units: [],
      data_table: [],
      data_table_expand: [],
      report_type: '',
      summary: {
        reference: 0,
        account_value: 0,
        water_liters: 0,
        sewer_liters: 0,
        economy_monetary: 0,
        economy_percentage: 0,
        goal: 0
      },
      dialog_send_email: false,
      data_body_email: false
    }
  },
  methods: {
    async catch_report_tipe(type, func, prop, status) {
      this.report_type = type
      func(prop, status)
    },
    async catch_id(data_id, onExpand = false) {
      if (!onExpand) this.summary = {
        reference: 0,
        account_value: 0,
        water_liters: 0,
        sewer_liters: 0,
        economy_monetary: 0,
        economy_consumption: 0,
        goal: 0
      }
      const id = data_id.id

      if (!onExpand) {
        this.data_table = []
        this.last_serach_alerts = data_id
        this.last_type_selected = data_id.type
      } else {
        this.data_table_expand = []
      }
      this.show_expand = true
      let listTableFilter = []
      let raw_client_list_by_franchisee = []
      let list_filter_units_with_historic = []

      function separeteUnits(units) {
        let list = []
        units.forEach((unit) => {
          if (list.find((item) => item.id === unit.id_client)) {
            list.map((unitList) => {
              if (unitList.name === unit.name_client) {
                unitList.units = [...unitList.units, unit]
                unitList.size += 1
              }
            })
          } else {
            if (unit.name_client) {
              list.push({
                units: [unit],
                name: unit.name_client,
                id: unit.id_client,
                size: 1
              })
            }
          }


        })
        return list
      }

      // try {
      switch (data_id.type) {
        case "franchisee":
          this.franchisee_id = id;

          if (!onExpand) this.setHeaders("client")
          else this.setHeadersExpand("client")



          const response = await UsersService.findClientsByFranchisee(this.franchisee_id)
          const clientsByFranchisee = response.data.data
          if (this.data_units.length) {
            raw_client_list_by_franchisee = this.data_units.filter(({ id_client }) => clientsByFranchisee.find(({ id }) => id === id_client))


            list_filter_units_with_historic = this.checkUnitsWithHistoric(raw_client_list_by_franchisee)
            list_filter_units_with_historic = separeteUnits(list_filter_units_with_historic)
            listTableFilter = await this.sumHistoricValues(list_filter_units_with_historic)
          }
          break;
        case "client":
          if (!onExpand) this.setHeaders("unit")
          else this.setHeadersExpand("unit")
          this.client_id = id;
          if (this.data_units.length) {
            let client_filtered = this.data_units.filter(({ id_client }) => id_client === this.client_id)

            list_filter_units_with_historic = this.checkUnitsWithHistoric(client_filtered)
            list_filter_units_with_historic = separeteUnits(list_filter_units_with_historic)
            if (list_filter_units_with_historic.length) listTableFilter = this.sumHistoricValuesClient(list_filter_units_with_historic[0].units)
            if (this.getCurrentUser.data.rank === "Associado") {
              let list = []
              this.getCurrentUser.currentUser.data.unitsAllowedByClient.forEach(idUnit => {
                list.push(...listTableFilter.filter(({ id }) => idUnit === id))
              });
              listTableFilter = list
            }

          }
          // listTableFilter = await filterAssociatedUnit(listTableFilter) 
          // -------------------------------------------------------------- IMPORTANTE

          break;
        case "unit":
          if (!onExpand) this.setHeaders("sector")
          else this.setHeadersExpand("sector")

          this.unit_id = id;
          if (this.last_type_selected === "unit") this.show_expand = false

          if (this.data_units.length) {
            raw_client_list_by_franchisee = this.data_units.filter(({ id }) => id === this.unit_id)
            list_filter_units_with_historic = this.checkUnitsWithHistoric(raw_client_list_by_franchisee)
            list_filter_units_with_historic = separeteUnits(list_filter_units_with_historic)
            if (list_filter_units_with_historic.length) listTableFilter = this.historicValuesPerMonthUnit(list_filter_units_with_historic[0].units.filter(({ id }) => id === this.unit_id))
          }

          break;
        default:
          alert("Tipo não identificado entre em contato com o suporte!")
          break;
      }
      if (!onExpand) await this.summarySum(listTableFilter)

      // } catch {
      //   alert("Erro não identificado entre em contato com o suporte!")
      // }
      if (listTableFilter.length === 0) showErrorSnackBar("Não existe unidades ou historico relacionados!")
      if (!onExpand) {
        this.data_table = listTableFilter
        this.formatValueTable(this.data_table)
      } else {
        this.data_table_expand = listTableFilter
        this.formatValueTable(this.data_table_expand)
      }

    },
    async sumHistoricValues(data) {
      let filtered_clients_with_units = []
      if (data.length > 0) {
        filtered_clients_with_units = data.filter((line) => line.units.length > 0)
        filtered_clients_with_units.map((client, index) => {
          let account_value = 0
          let water_liters = 0
          let sewer_liters = 0
          let economy_monetary = 0
          let economy_percentage = 0
          let goal = 0
          let reference = 0
          client.units.map((unit) => {
            let data_install = unit.dataMediaHistorico
            unit.historicos.map((historic) => {
              if (this.orderStartDate()[0] <= historic.month && this.orderStartDate()[1] >= historic.month && data_install < historic.month) {
                account_value += Number(historic.cost_consumption)
                water_liters += Number(historic.water_cubic_meters)
                sewer_liters += Number(historic.sewer_cubic_meters)
                economy_monetary += Number(historic.financial_economy)
                reference += Number(historic.reference_economy)
              }
            })
            goal += Number(unit.metaEconomia) / client.units.length

          })
          economy_percentage = (economy_monetary / reference) * 100
          economy_percentage = economy_percentage > 0 ? economy_percentage : 0
          filtered_clients_with_units[index] = {
            ...filtered_clients_with_units[index],
            account_value: account_value.toFixed(2),
            water_liters: water_liters.toFixed(2),
            sewer_liters: sewer_liters.toFixed(2),
            economy_monetary: economy_monetary.toFixed(2),
            economy_percentage: economy_percentage.toFixed(2),
            reference: reference.toFixed(2),
            goal: goal.toFixed(2),
            index
          }
        })

      }
      return filtered_clients_with_units
    },
    sumHistoricValuesClient(data) {
      let filtered_units_with_historic = []
      if (data.length > 0) {
        filtered_units_with_historic = data.map((unit, index) => {
          let data_install = unit.dataMediaHistorico
          let account_value = 0
          let water_liters = 0
          let sewer_liters = 0
          let economy_monetary = 0
          let economy_percentage = 0
          let goal = 0
          let reference = 0
          unit.historicos.map((historic) => {
            if (this.orderStartDate()[0] <= historic.month && this.orderStartDate()[1] >= historic.month && data_install < historic.month) {
              account_value += Number(historic.cost_consumption)
              water_liters += Number(historic.water_cubic_meters)
              sewer_liters += Number(historic.sewer_cubic_meters)
              economy_monetary += Number(historic.financial_economy)
              reference += Number(historic.reference_economy)
            }
          })
          goal += Number(unit.metaEconomia)


          economy_percentage = (economy_monetary / reference) * 100
          economy_percentage = economy_percentage > 0 ? economy_percentage : 0

          return {
            ...unit,
            account_value: account_value.toFixed(2),
            water_liters: water_liters.toFixed(2),
            sewer_liters: sewer_liters.toFixed(2),
            economy_monetary: economy_monetary.toFixed(2),
            economy_percentage: economy_percentage.toFixed(2),
            reference: reference.toFixed(2),
            goal: goal.toFixed(2),
            index
          }
        })

      }
      return filtered_units_with_historic
    },
    historicValuesPerMonthUnit(data) {
      let list_month = []
      data.map((unit) => {
        let data_install = unit.dataMediaHistorico
        unit.historicos.map((historic) => {
          if (
            new Date(this.orderStartDate()[0]).getTime() <= new Date(historic.month).getTime() &&
            new Date(this.orderStartDate()[1]).getTime() >= new Date(historic.month).getTime() &&
            new Date(data_install).getTime() < new Date(historic.month).getTime()
          ) {
            let economy_percentage = (Number(historic.financial_economy) / Number(historic.reference_economy)) * 100
            economy_percentage = economy_percentage > 0 ? economy_percentage : 0
            list_month.push({
              name: Utils.formatMonthDate(historic.month, '<MM>/<YY>', true),
              reference: Number(historic.reference_economy),
              account_value: Number(historic.cost_consumption),
              water_liters: Number(historic.water_cubic_meters),
              sewer_liters: Number(historic.sewer_cubic_meters),
              economy_monetary: Number(historic.financial_economy).toFixed(2),
              economy_percentage: economy_percentage.toFixed(2),
              goal: Number(unit.metaEconomia)
            })
          }
        })
      })
      return list_month
    },
    async summarySum(data) {
      this.summary = {
        reference: 0,
        account_value: 0,
        water_liters: 0,
        sewer_liters: 0,
        economy_monetary: 0,
        economy_percentage: 0
      }
      data.map((line) => {
        this.summary.reference += Number(line.reference)
        this.summary.account_value += Number(line.account_value)
        this.summary.water_liters += Number(line.water_liters)
        this.summary.sewer_liters += Number(line.sewer_liters)
        this.summary.economy_monetary += Number(line.economy_monetary)
        this.summary.economy_percentage += Number(line.economy_percentage)
      })
    },
    async formatValueTable(data) {
      data.map((line) => {
        line.reference = Utils.formatCurrency(line.reference)
        line.account_value = Utils.formatCurrency(line.account_value)
        line.water_liters = line.water_liters + " M³"
        line.sewer_liters = line.sewer_liters + " M³"
        line.economy_monetary = Utils.formatCurrency(line.economy_monetary)
        line.economy_percentage = line.economy_percentage + "%"
        line.goal = line.goal + "%"
      })
    },
    checkUnitsWithHistoric(unitsList) {
      return unitsList.filter((unit) => unit.historicos?.length >= 1)
    },
    async getAlertsPerData(selectDate) {
      this.startDatePicker = selectDate

      if (this.last_serach_alerts) {
        this.catch_id(this.last_serach_alerts)
      }
    },
    orderStartDate() {
      if (this.startDatePicker[0] > this.startDatePicker[1]) {
        this.startDatePicker = [
          this.startDatePicker[1],
          this.startDatePicker[0]
        ]
      }
      this.startDatePicker = [
        this.startDatePicker[0],
        this.startDatePicker[1]
      ]
      return this.startDatePicker
    },
    async onExpand({ item, value }) {
      let type = null
      switch (this.last_type_selected) {
        case "franchisee":
          type = "client"
          break;
        case "client":
          type = "unit"
          break;
      }
      await this.catch_id({ id: item.id, type }, true)
    },
    setHeaders(action) {
      switch (action) {

        case 'client':
          if (this.main_headers.length === 8) {

            this.main_headers.push({ text: 'Ações', value: 'actions', sortable: false, align: 'center' })
            this.main_headers.push({ text: '', value: 'data-table-expand' })

          }
          this.main_headers['0'].text = 'Cliente'
          this.secondary_headers['0'].text = 'Unidade'
          break;
        case 'unit':
          if (this.main_headers.length === 8) {
            this.main_headers.push({ text: 'Ações', value: 'actions', sortable: false, align: 'center' })
            this.main_headers.push({ text: '', value: 'data-table-expand' },)
          }
          this.main_headers['0'].text = 'Unidade'
          this.secondary_headers['0'].text = 'Data'
          break;
        case 'sector':
          this.main_headers['0'].text = 'Data'
          this.main_headers = this.main_headers.filter(({ value }) => value !== "actions" && value !== "data-table-expand")
          break;
      }
    },
    setHeadersExpand(action) {
      switch (action) {
        case 'client':
          break;
        case 'unit':
          if (this.secondary_headers.length === 8) this.secondary_headers.push({ text: 'Ações', value: 'actions', sortable: false, align: 'center' })
          break;
        case 'sector':
          this.secondary_headers = this.secondary_headers.filter(({ value }) => value !== "actions")
          break;
      }
    },
    async generatePdf(item, status = null) {

      let data = {}
      data.id = item.id
      data.currentUser = this.getCurrentUser.uid
      data.rank = this.getCurrentUser.data.rank
      this.report_type === "" ? data.report_type = "PDF" : data.report_type = this.report_type
      switch (this.last_serach_alerts.type) {
        case "franchisee":
          data.type = status ? "unit_id" : "client_id"
          break;
        case "client":
          data.type = status ? "modem_id" : "unit_id"
          if (item.entity) data.type = "client_id"
          break;
        case "unit":
          data.type = "modem_id"
          if (item.entity) data.type = "unit_id"
          break;
        default:
          console.error("type error!")
      }
      const entityName = item.name ? item.name : await this.getClientName(item)

      if (this.report_type === "XLSX") {
        let monthSelect = []

        if (data.type === "unit_id") {
          monthSelect = this.historicValuesPerMonthUnit([item])
          this.formatValueTable(monthSelect)
          await generateXlsx([{ ...item, monthSelect }])
        } else {
          let resultSumHistoric = this.sumHistoricValuesClient(item.units)
          this.formatValueTable(resultSumHistoric)
          await generateResumeXlsx([{ ...item, resultSumHistoric }])
        }
      } else if (this.report_type === "Email") {
        this.dialog_send_email = !this.dialog_send_email
        this.data_body_email = { data, startDatePicker: this.startDatePicker, name: entityName }
      } else {

        let response = await EconomyApi.generatePdf(data, this.startDatePicker)
        response.data && response.data.success == true ? await EconomyApi.downloadPdf(this.orderStartDate()[0] + "_" + this.orderStartDate()[1] + " " + entityName) : showErrorSnackBar("Erro ao gerar pdf!")
      }
      this.report_type = ""
    },
    async getClientName(item) {
      let data = []
      if (item.entity === "client") data = await firebase.getUserbyId(item.id)
      else data = await firebase.getUnitbyId(item.id)
      return data.name
    },
    async sendEmail(receiver, message) {
      try {
        if (!this.data_body_email) throw "Body não definido!"
        const name = this.data_body_email.name
        const user_email = receiver
        const relatorio = "Financeiro"
        const data = {
          id: this.data_body_email.data.id,
          currentUser: this.data_body_email.data.currentUser,
          rank: this.data_body_email.data.rank,
          report_type: 'PDF',
          type: this.data_body_email.data.type
        }
        const date_time = this.data_body_email.startDatePicker
        const file_name = this.data_body_email.name.split(" ").join("_")
        const endpoint = "/report/economy"

        await EmailService.sendEmail({
          name,
          user_email,
          relatorio,
          data,
          date_time,
          file_name,
          endpoint
        })

        showSuccessSnackBar("Email enviado!")
      } catch (err) {
        console.error(err)
        showErrorSnackBar("Erro ao enviar email!")
      }
      this.data_body_email = null
    },

  },
  computed: {
    ...mapGetters([
      "getCurrentUser",
    ]),
    sumarryFormated() {
      const everageEconomyPercentage = (this.summary.economy_percentage / this.data_table.length).toFixed(2)
      return {
        reference: Utils.formatCurrency(this.summary.reference),
        account_value: Utils.formatCurrency(this.summary.account_value),
        water_liters: (this.summary.water_liters).toFixed(2) + " M³",
        sewer_liters: (this.summary.sewer_liters).toFixed(2) + " M³",
        economy_monetary: Utils.formatCurrency(this.summary.economy_monetary),
        economy_percentage: isNaN(everageEconomyPercentage) ? "0 %" : everageEconomyPercentage + "%"
      }
    }
  },
  mounted() {
    this.loading_units = true
    firebase.getUnits()
      .then(res => this.data_units = res)
      .finally(() => {
        if (this.last_serach_alerts) this.catch_id(this.last_serach_alerts)
        this.loading_units = false
      })

  }

}