<template>
    <div v-if="show">
        <v-form ref="form" v-model="validForm" lazy-validation>
            <v-row>
                <v-col cols="12">
                    <h3>Histórico de Referência:</h3>
                </v-col>
            </v-row>
            <v-row>
                <!-- Start Table Solutions -->
                <v-data-table
                    :headers="headers"
                    :items="list"
                    :items-per-page="12"
                    class="elevation-1"
                    style="width: 100%"
                >
                    <template v-slot:top>
                        <v-toolbar flat class="rounded-lg">
                            <v-toolbar-title>Histórico</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" class="mb-2" text @click="() => create()">
                                <v-icon>add</v-icon>
                            </v-btn>
                        </v-toolbar>
                    </template>
                
                    <template v-slot:body="{ items }">
                        <tbody>
                            <tr v-for="historic, index in items" :key="index">
                                <td style="display: flex">
                                    <v-text-field
                                        readonly
                                        v-model="historic.monthFormatted"
                                        :rules="rulesRequired"
                                    />
                                </td>
                                <td>
                                    <v-text-field
                                        readonly
                                        type="Number"
                                        v-model="historic.water"
                                        suffix="m³"
                                        :rules="rulesRequired"
                                    />
                                </td>
                                <td>
                                    <v-text-field
                                        readonly
                                        type="Number"
                                        v-model="historic.sewer"
                                        suffix="m³"
                                        :rules="rulesRequired"
                                    />
                                </td>
                                <td>
                                    <v-text-field
                                        readonly
                                        v-model="historic.consumptionTotal"
                                        suffix="m³"
                                        :rules="rulesRequired"
                                    />
                                </td>
                                <td>
                                    <v-text-field
                                        readonly
                                        v-model="historic.consumptionTypeWater"
                                        :rules="rulesRequired"
                                    />
                                </td>
                                <td>
                                    <v-text-field
                                        v-model="historic.consumptionTypeSewer"
                                        :rules="rulesRequired"
                                    />
                                </td>
                                <td>
                                    <v-text-field
                                        v-model="historic.accountAmount"
                                        prefix="R$"
                                        type="number"
                                        :rules="rulesRequired"
                                    />
                                </td>
                                <td>
                                    <v-btn
                                        elevation="2"
                                        @click="() => confirmDeleteSolution(historic, index)"
                                        style="height: 30px; margin: 2px"
                                        :loading="loading"
                                        :disaled="loading"
                                    >
                                        <v-icon color="primary" small> mdi-delete </v-icon>
                                    </v-btn>
                                    <v-btn
                                        elevation="2"
                                        @click="() => edit(index)"
                                        style="height: 30px; margin: 2px"
                                        :loading="loading"
                                        :disaled="loading"
                                    >
                                        <v-icon color="primary" small> mdi-pencil </v-icon>
                                    </v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
                <!-- End Table Solutions -->
            </v-row>
            <v-dialog transition="dialog-top-transition" v-model="dialog" v-if="dialog" persistent max-width="500px">
                <v-card>
                    <v-toolbar color="primary" dark> <span class="text-h5">Adicionar Histórico</span></v-toolbar>
                    <v-card-title>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text style="padding-bottom: 0px;">
                        <v-row>
                            <v-col cols="12">
                                <date-picker-dialog
                                    title="Escolha o mês do histórico"
                                    type="month"
                                    :show="datePickerDialog"
                                    @close="(status) => (datePickerDialog = status)"
                                    :input="dateMonth"
                                    @change="(newDate) => confirmDatePicker(newDate)"
                                    :rules="rulesRequired"
                                    :loading="loading"
                                    :disaled="loading"
                                />        
                                <v-text-field
                                    label="Mês"
                                    v-model="historic.monthFormatted"
                                    readonly
                                    @click="openDataDialog()"
                                    :rules="rulesRequired"
                                    :loading="loading"
                                    :disaled="loading"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    label="Água"
                                    type="Number"
                                    v-model="historic.water"
                                    suffix="m³"
                                    @change="sumWaterAndSewer()"
                                    :rules="rulesRequired"
                                    :loading="loading"
                                    :disaled="loading"
                                />
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    label="Esgoto"
                                    type="Number"
                                    v-model="historic.sewer"
                                    suffix="m³"
                                    @change="sumWaterAndSewer()"
                                    :rules="rulesRequired"
                                    :loading="loading"
                                    :disaled="loading"
                                />
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    label="Consumo Total"
                                    v-model="historic.consumptionTotal"
                                    suffix="m³"
                                    disabled
                                    :rules="rulesRequired"
                                    :loading="loading"
                                    :disaled="loading"
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-select
                                    label="Tipo do Cons. da Água"
                                    :items="consumptionType"
                                    v-model="historic.consumptionTypeWater"
                                    :rules="rulesRequired"
                                    :loading="loading"
                                    :disaled="loading"
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-select
                                    label="Tipo do Cons. do Esgoto"
                                    :items="consumptionType"
                                    v-model="historic.consumptionTypeSewer"
                                    :rules="rulesRequired"
                                    :loading="loading"
                                    :disaled="loading"
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    label="Valor da Conta"
                                    v-model="historic.accountAmount"
                                    prefix="R$"
                                    type="number"
                                    :rules="rulesRequired"
                                    :loading="loading"
                                    :disaled="loading"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn 
                            color="blue darken-1" 
                            text 
                            @click="() => closeDialog()"
                            :loading="loading"
                            :disaled="loading"
                        >
                            Cancelar
                        </v-btn>
                        <v-btn 
                            color="blue darken-1" 
                            text 
                            @click="() => save()"
                            :loading="loading"
                            :disaled="loading"
                        > 
                            Salvar 
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>
    </div>
</template>
  
<script>
// import HeaderCheckList from "@/components/Projects/Diagnostic/Tabs/CheckList/HeaderCheckList.vue"
// import CardCheckList from "@/components/Projects/Diagnostic/Tabs/CheckList/CardCheckList.vue"

import DatePickerDialog from "@/components/DatePickerDialog/DatePickerDialog.vue";
import WaterScanService from '@/services/firebase/WaterScan/WaterScanService';
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper";

import Rules from "@/utils/Rules";
import Utils from '@/scripts/Utils';

const newHistoric = () => {
    return {
        accountAmount: 0,
        consumptionTotal: 0,
        consumptionTypeSewer: null,
        consumptionTypeWater: null,
        month: null,
        monthFormatted: null,
        sewer: 0,
        water: 0,
    }
}

export default {
    name: "HydrometerList",
    
    components: {
        DatePickerDialog,
        // HeaderCheckList,
    },
    
    props: {
        show: {
            type: Boolean,
            default: true
        },
        list: {
            type: Array,
            default: () => ([]),
        },
        add: {
            type: Function,
            default: (hydrometer, index) => {}
        },
        remove: {
            type: Function,
            default: (index) => {}
        },
    },
    
    data: () => ({
        validForm: true,
        dialog: false,
        loading: false,
        index: -1,
        historic: newHistoric(),
        consumptionType: [
            "Real",
            "Fixo",
            "Const Cort",
            "Estimado",
            "Informado",
            "Media HD",
            "Min Fixed",
        ],
        headers: [
            { text: "Mês", value: "mounth", width: 180 },
            { text: "Água", value: "water" },
            { text: "Esgoto", value: "sewer" },
            { text: "Total", value: "consumptionTotal" },
            { text: "Tipo Consumo Água ", width: 120 , value: "consumptionTypeWater" },
            { text: "Tipo Consumo Esgoto ", width: 120 , value: "consumptionTypeSewer" },
            { text: "Conta (R$) ", value: "accountAmount" },
            { text: "Ações ", value: "action" },
        ],
        datePickerDialog: false,
        dateMonth: null,
    }),

    computed: {
        rulesRequired() {
            return Rules.required();
        },
    },

    methods: {
        create() {
            this.index = -1
            this.historic = newHistoric()
            this.dialog = true
        },
        edit(index) {
            this.index = index
            this.historic = Utils.copyObject(this.list[index])
            this.dialog = true
        },
        save() {
            this.loading = true
            this.add(Utils.copyObject(this.historic), this.index)
            this.dialog = false
            this.loading = false
        },
        closeDialog() {
            this.dialog = false
        },
        openDataDialog() {
            this.datePickerDialog = true;
        },
        confirmDatePicker(newMonth) {
            let hasMonth = false
            if (this.list.length > 0) {
                let checkMonth = this.list.filter(
                    ({ month }) => month == newMonth
                );
                if (checkMonth.length > 0) {
                    hasMonth = true
                    showErrorSnackBar(`O mês já selecionado. Por favor, selecione outro mês!`)
                }
            }
            if (!hasMonth) {
                this.historic.month = newMonth
                this.historic.monthFormatted = Utils.formatMonthDate(this.historic.month, "<MM> de <YYYY>", true)
            }
        },
        sumWaterAndSewer() {
            this.historic.water = parseFloat(this.historic.water)
            this.historic.sewer = parseFloat(this.historic.sewer)
            this.historic.consumptionTotal = this.historic.water + this.historic.sewer
        },
        async confirmDeleteSolution(item, index) {
            showDeleteDialogAlert(`Deseja excluir o histórico referente ao mês de ${item.monthFormatted}?`, () => this.remove(index))
        },
    },

    watch: {
        
    },

};
</script>
  
<style scoped>

</style>