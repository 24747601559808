<template>
    <v-row style="
        background-color: rgba(25, 118, 210, .2);
        border-radius: 10px;
        padding: 0 10px;
    "
    >
        <v-dialog 
            transition="dialog-top-transition" 
            v-model="dialog" 
            v-if="dialog" 
            persistent 
            max-width="700px"
        >
        <v-card style="background-color: white !important;">
            <v-toolbar color="primary" dark> <span class="text-h5">Listagem das Ordens de Serviços</span></v-toolbar>
            <v-card-text style="padding-bottom: 0px;padding: 20px 20px;">
                <UploadPDFFile
                    :fileList="list"
                    :channel="channel"
                    :name="name"
                    :download="downloadPDF"
                />
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="() => closeDialog()" :loading="loading">
                    Cancelar
                </v-btn>
                <v-btn  color="blue darken-1" text @click="() => saveDialog()" :loading="loading" :disabled="!hasFilesToSave"> Salvar </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
        
        <v-col>
            <v-row style="align-content: center;height: 100%;">
                <span>Lista de Ordem de Serviços</span>
            </v-row>
        </v-col>
        <v-col>
            <v-row style="justify-content: end;">
                <v-btn 
                    color="primary" 
                    text
                    @click="() => openDialog()"
                    :loading="loading"
                    elevation="2"
                    style="height: 30px; margin: 2px;background-color: #1976d2;"
                >
                    <v-icon style="color: white;">mdi-eye-outline</v-icon>
                </v-btn>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper";
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import Utils from '@/scripts/Utils';
import UploadPDFFile from "./UploadPDFFile.vue";
import WaterFixStorage from '@/services/storage/WaterFixStorage';

export default {
    components: {
        UploadPDFFile,
    },
    props: {
        proposal: {
            type: Object,
            required: true,
        },
        local: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        id: null,
        name: 'osList',
        channel: 'waterfix',
        dialog: false,
        osList: {},
        list: [],
        registration: null,
        local_formatted: null,
        newPdfs: [],
        removePdfs: [],
        loading: false,
    }),
    methods: {
        ...mapActions([
            'publishChannel',
            'subscribeChannel'
        ]),
        async downloadPDF(filepath) {
            return await WaterFixStorage.downloadPDF(filepath)
        },
        openDialog() {
            this.dialog = true
        },
        closeDialog() {
            this.dialog = false
        },
        async saveDialog() {
            this.loading = true
            // -------------------------------------------------------
            // Adição dos PDFs caso exista
            for (let i = 0; i < this.newPdfs.length; i++) {
                const file = this.newPdfs[i].file;
                await WaterFixStorage.uploadPDF(file, this.newPdfs[i].rawFile)
            }
            let filesToSave = this.newPdfs.forEach(
                (file) => {
                    delete file['rawFile']
                    // return file;
                    this.list.push(file)
                }
            )
            // -------------------------------------------------------
            // Remoção dos PDFs caso exista
            let filenameToDelete = []
            for (let i = 0; i < this.removePdfs.length; i++) {
                const file = this.removePdfs[i].file;
                await WaterFixStorage.deletePDF(file)
                filenameToDelete.push(this.removePdfs[i].name)
            }
            this.list = this.list.filter(
                (file) => {
                    return !filenameToDelete.includes(file.name)
                }
            )
            // Emitir um evento para atualizar a lista de OS
            this.publishChannel({
                channel: this.channel,
                event: this.name,
                val: { local: this.local, list: Utils.copyObject(this.list) },
            })
            this.loading = false
            this.newPdfs = []
            this.removePdfs = []
        },
        parseOSList(proposal, local) {
            this.registration = proposal.registration
            if (proposal) {
                if (proposal.hasOwnProperty('osList')) {
                    this.osList = proposal.osList
                } else {
                    this.osList = {}
                }
            } 
            if (local) {
                this.local_formatted = Utils.createSlug(local)
                if (this.osList.hasOwnProperty(this.local_formatted)) {
                    this.list = this.osList[this.local_formatted]
                } else {
                    this.list = []
                }
            }
        },
        pushNewPDF(files) {
            let existingFilenames = this.newPdfs.map(({ name }) => name)
            for (let i = 0; i < files.length; i++) {
                if (existingFilenames.includes(files[i].name)) continue
                const fileNameSaved = files[i].rawFile.name;
                let filepath = `water_fix_os/${this.registration}/${this.local_formatted}/${fileNameSaved}`;
                files[i].file = filepath
                this.newPdfs.push(files[i])
            }
        },
        pushRemovePDF(files) {
            let existingFilenames = this.removePdfs.map(({ name }) => name)
            for (let i = 0; i < files.length; i++) {
                if (existingFilenames.includes(files[i].name)) continue
                this.removePdfs.push(files[i])
            }
        },
    },
    computed: {
        hasFilesToSave() {
            return this.newPdfs.length > 0 || this.removePdfs.length > 0
        }
    },
    watch: {
        proposal(newVal) {
            if (newVal) {
                this.parseOSList(newVal, this.local)
            }
        },
    },
    async created() {
        this.parseOSList(this.proposal, this.local)
        this.subscribeChannel({
            channel: 'waterfix',
            events: [
                {
                    event: `${this.name}:new`,
                    fn: async (files) => {
                        this.pushNewPDF(files)
                    }
                },
                {
                    event: `${this.name}:remove`,
                    fn: async (files) => {
                        this.pushRemovePDF(files)
                    }
                },
            ]
        })
    }
};
</script>