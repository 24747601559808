<template>
  <ul class="progressbar">
    <li
      v-for="progresso in progress"
      :key="progresso.id"
      :class="[progresso.active ? 'active' : '']"
    >
      <div
        :style="[widthScreenMoment]"
      >
        {{ progresso.name }}
      </div>
      <!-- <p>{{isMobile ? '' :  progresso.name}}</p> -->
    </li>
  </ul>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["progress"],
  data() {
    return {
      windowHeight: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters(["isFirstLoadedConfigs"]),
    widthScreenMoment() {
      let widthScreen = this.$vuetify.breakpoint.width
      let  styleObject = {
              'text-overflow': 'ellipsis',
                overflow: 'hidden',
              'white-space': 'nowrap',
            }
      if(widthScreen > 742){
         return styleObject
      }else if(widthScreen <= 742 && widthScreen > 616){
        return styleObject
      }else{
        styleObject['max-width'] = '0px'
        return styleObject
      }   
    },
  },
};
</script>
<style scoped>
.container {
  padding-left: 0px;
  position: absolute;
  z-index: 1;
  width: 100%;
  max-width: 100% !important;
}
.progressbar {
  display: flex;
  padding-left: 0px;
  justify-content: center;
  counter-reset: step;
}
.progressbar li {
  list-style-type: none;
  float: left;
  width: 14.28%;
  position: relative;
  text-align: center;
}
.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  width: 30px;
  height: 30px;
  border: 2px solid #bebebe;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  background: white;
  color: #bebebe;
  text-align: center;
  font-weight: bold;
}
.progressbar li:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background: #979797;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.progressbar li.active:before {
  border-color: rgb(25, 118, 210);
  background: rgb(25, 118, 210);
  color: white;
}
.progressbar li.active:after {
  background: rgb(25, 118, 210);
}
.progressbar li.active li:after {
  background: rgb(25, 118, 210);
}
.progressbar li.active li:before {
  border-color: rgb(25, 118, 210);
  background: rgb(25, 118, 210);
  color: white;
}
.progressbar li:first-child:after {
  content: none;
}
</style>