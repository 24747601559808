import { mapActions } from "vuex";
import Utils from '@/scripts/Utils';
import FormReport from "@/components/Forms/Report/Report.vue";
import AlertsService from "../../../services/firebase/Alerts/AlertsService";
import UnitsService from "@/services/server/UnitsService";
import UsersService from "@/services/server/UsersService.js";
import AlertsConfigService from "@/services/firebase/AlertsConfig/AlertsConfigService.js";
import SectorsService from "@/services/firebase/Sectors/SectorsService";

import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
export default {
  components: {
    FormReport,
  },
  name: "Alerts",
  data: () => ({
    main_headers: [
      {
        text: 'Cliente',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      { text: 'Consumo no Periodo (L)', value: 'liters_consumed_in_period', align: 'center' },
      { text: 'Excedido no Periodo (L)', value: 'liters_exceeded_in_period', align: 'center' },
      { text: 'Qntd.', value: 'amount', align: 'center' },
      { text: 'Ações', value: 'actions', sortable: false },
      { text: '', value: 'data-table-expand' }
    ],
    secondary_headers: [
      {
        text: 'Alerta',
        align: 'start',
        value: 'name'
      },
      { text: 'Data do Alerta', value: 'date_of_occurrence', align: 'center' },
      { text: 'Ações', value: 'actions', sortable: false },
      { text: '', value: 'data-table-expand' },
    ],
    summary: {
      amount: 0,
    },
    show_expand: true,
    last_type_selected: null,
    alerts_data: [],
    startDatePicker: [Utils.formatDateDayJs(Utils.subtractDate(new Date(),1,"month"), "YYYY-MM-DD"),
      Utils.formatDateDayJs(new Date(), "YYYY-MM-DD")],
    loading: {
      main_table: false,
      main_button_check: false,
      secondary_table: false,
      secondary_button_check: false,
      pdf_index_button: '',
    },
    startDateDialog: false,
    data_table: [],
    data_table_expand: [],
    alertsConfigData: []
  }),
  methods: {
    async catch_view(func, line, status) {
      func(line, status)
    },
    async catch_id(data_id, onExpand = false) {
      if (!onExpand) this.summary = {
        amount: 0,
        exceeded_limit: 0
      }
      const id = data_id.id
      if (!onExpand) {
        this.data_table = []
        this.last_serach_alerts = data_id
        this.last_type_selected = data_id.type
      } else {
        this.data_table_expand = []
      }
      this.show_expand = true
      let alerts = []
      let alertsListTable = []

      try {
        switch (data_id.type) {
          case "franchisee":
            this.unit_id = null
            this.client_id = null
            this.franchisee_id = id;
            if (!onExpand) this.setHeaders("client")
            this.alerts_data = await AlertsService.getAlertsPayloadConsumptionByEntity("id_franchisee", this.franchisee_id, this.formatDateTimestamp())
            alertsListTable = await this.countAlerts(this.alerts_data, "id_client")
            await this.getClientsName(this.franchisee_id, alertsListTable)
            await this.sumValuesTable(alertsListTable)
            if (!onExpand) await this.summarySum(alertsListTable)
            break;
          case "client":
            this.client_id = id;
            if (!onExpand) this.setHeaders("unit")
            alerts = this.alerts_data.filter((alert) => alert.id_client === this.client_id)
            alertsListTable = await this.countAlerts(alerts, "id_unit")
            await this.getUnitsName(this.client_id, alertsListTable)
            await this.sumValuesTable(alertsListTable)
            if (!onExpand) await this.summarySum(alertsListTable)
            break;
          case "unit":
            this.setHeaders("sector")
            this.show_expand = false
            this.unit_id = id;
            alerts = this.alerts_data.filter((alert) => alert.id_unit === this.unit_id)
            alertsListTable = await this.alterAlertList(alerts)
            if (!onExpand) await this.summarySum(alertsListTable)
            await this.sumValuesTable(alertsListTable)
            break;
          default:
            alert("Tipo não identificado entre em contato com o suporte!")
            break;
        }

      } catch {
        alert("Erro não identificado entre em contato com o suporte!")
      }
      if (alertsListTable.length === 0) showErrorSnackBar("Não existe alertas para o item selecionado!")
      !onExpand ? this.data_table = alertsListTable : this.data_table_expand = alertsListTable
    },

    setHeaders(action) {
      const secondary_franchisee = [
        {
          text: 'Unidade',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Qntd.', value: 'amount', align: 'center' },
        { text: 'Limite Consumo (L)', value: 'limit_consumption', align: 'center' },
        { text: 'Consumo no Periodo (L)', value: 'liters_consumed_in_period', align: 'center' },
        { text: 'Excedido no Periodo (L)', value: 'liters_exceeded_in_period', align: 'center' },
        { text: 'Ações', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' }
      ]
      const secondary_other = [
        { text: 'Setor', align: 'start', sortable: false, value: 'name' },
        { text: 'Alerta', value: 'type', align: 'center' },
        { text: 'Limite Consumo (L)', value: 'limit_consumption', align: 'center' },
        { text: 'Consumo no Periodo (L)', value: 'liters_consumed_in_period', align: 'center' },
        { text: 'Excedido no Periodo (L)', value: 'liters_exceeded_in_period', align: 'center' },
        { text: 'Data do Alerta', value: 'date_of_occurrence', align: 'center' },
        { text: 'Ações', value: 'actions', sortable: false },
      ]
      const main_headers = [{
        text: 'Cliente',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      { text: 'Qntd.', value: 'amount', align: 'center' },
      { text: 'Limite Consumo (L)', value: 'limit_consumption', align: 'center' },
      { text: 'Consumo no Periodo (L)', value: 'liters_consumed_in_period', align: 'center' },
      { text: 'Excedido no Periodo (L)', value: 'liters_exceeded_in_period', align: 'center' },
      { text: 'Ações', value: 'actions', sortable: false },
      { text: '', value: 'data-table-expand' }
      ]
      switch (action) {
        case 'client':
          this.main_headers = main_headers
          this.secondary_headers = secondary_franchisee
          break;
        case 'unit':
          this.main_headers = main_headers
          this.main_headers['0'].text = 'Unidade'
          this.secondary_headers = secondary_other
          break;
        case 'sector':
          this.main_headers = secondary_other
          break;
      }
    },
    async onExpand({ item, value }) {
      let type = null
      switch (this.last_type_selected) {
        case "franchisee":
          type = "client"
          await this.catch_id({ id: item.id, type }, true)
          break;
        case "client":
          type = "unit"
          this.data_table_expand = await this.alterAlertList(item.alerts)
          break;
        case "unit":
          this.data_table_expand = await this.alterAlertList(item.alerts)
          break;
      }

    },
    async summarySum(data) {
      this.summary = {
        amount: 0,
        peak: 0,
        liters_consumed_in_period: 0,
        liters_exceeded_in_period: 0,
        limit_consumption: 0,
      }
      data.map((line) => {
        this.summary.amount += line.amount ? line.amount : 1
        this.summary.liters_consumed_in_period += +line.liters_consumed_in_period.toFixed(2)
        this.summary.liters_exceeded_in_period = +line.liters_exceeded_in_period.toFixed(2) + +this.summary.liters_exceeded_in_period.toFixed(2)
        this.summary.limit_consumption += +line.liters_consumed_in_period.toFixed(2) - +line.liters_exceeded_in_period.toFixed(2)
      })
    },
    async alterAlertList(alertsGroup) {
      let listSectors = []
      if (alertsGroup.length) listSectors = await SectorsService.getSectorsByUnitId(alertsGroup[0].id_unit)
      return alertsGroup.map((alert) => {
        const alertConfig = this.alertsConfigData.find(({ id_doc }) => id_doc === alert.id_alert_config)
        const sectorByAlert = listSectors.find(({ id_doc }) => id_doc === alert.id_sector)
        const date_of_occurrence = Utils.formatDateDayJs(new Date(alert.date_of_occurrence.seconds * 1000), 'DD/MM/YYYY [às] hh:mm:ss')
        return {
          ...alert,
          name: sectorByAlert.name,
          type: alertConfig.name,
          limit_consumption: +alert.liters_consumed_in_period.toFixed(2) - +alert.liters_exceeded_in_period.toFixed(2),
          liters_consumed_in_period: +alert.liters_consumed_in_period.toFixed(2),
          liters_exceeded_in_period: Number(alert.liters_exceeded_in_period.toFixed(2)),
          date_of_occurrence,
        }
      })
    },
    async getClientsName(franchiseeId, data) {
      const response = await UsersService.findClientsByFranchisee(franchiseeId)
      const clientsByFranchisee = response.data.data
      data.map(({ name }, i) => {
        let client_data = clientsByFranchisee.filter(({ id }) => name === id)
        data[i].id = data[i].name
        data[i].name = client_data[0].name
      })
    },
    async getUnitsName(clientId, data) {
      const response = await UnitsService.findAllByClient(clientId)
      const unitsByClient = response.data.data
      data.map(({ name }, i) => {
        let unit_data = unitsByClient.filter(({ id }) => name === id)
        if (unit_data.length > 0) {
          data[i].id = data[i].name
          data[i].name = unit_data[0].name
        }
      })
    },

    async sumValuesTable(data) {
      if (data[0] && data[0].alerts) {

        data.map((line, index) => {
          let amount = 0
          let liters_consumed_in_period = 0
          let liters_exceeded_in_period = 0
          let limit_consumption = 0

          line.alerts.map((alert) => {
            liters_consumed_in_period += +alert.liters_consumed_in_period.toFixed(2)
            liters_exceeded_in_period = +alert.liters_exceeded_in_period.toFixed(2) + +liters_exceeded_in_period.toFixed(2)
            limit_consumption = Number(alert.liters_consumed_in_period.toFixed(2)) - Number(alert.liters_exceeded_in_period.toFixed(2)) + +limit_consumption.toFixed(2)
            amount += 1

            data[index] = {
              ...data[index],
              liters_consumed_in_period: +liters_consumed_in_period.toFixed(2),
              liters_exceeded_in_period: +liters_exceeded_in_period.toFixed(2),
              limit_consumption,
              index
            }

          })
        })

      } else {
        this.summary = {
          amount: 0,
          liters_consumed_in_period: 0,
          days_without_water: 0,
          liters_exceeded_in_period: 0,
          limit_consumption: 0,
        }
        data.map((alert) => {

          this.summary.liters_consumed_in_period += +alert.liters_consumed_in_period.toFixed(2)
          this.summary.liters_exceeded_in_period += +alert.liters_exceeded_in_period.toFixed(2)
          // this.summary.limit_consumption = +alert.liters_consumed_in_period.toFixed(2) - +alert.liters_exceeded_in_period.toFixed(2) + +this.summary.limit_consumption.toFixed(2)
          this.summary.amount += 1

        })
      }
    },
    async countAlerts(alerts, entity) {
      let list = []
      if (entity) {
        alerts.map(async (alert) => {
          let resultSearchName = list.map(i => {
            return i.name === alert[entity]
          }).find(resultMap => resultMap === true)
          if (!resultSearchName) {
            list.push({
              name: alert[entity],
              amount: 1,
              // liters_consumed_in_period: +alert.liters_consumed_in_period.toFixed(2),
              // // liters_exceeded_in_period: +alert.liters_exceeded_in_period.toFixed(2),
              // // limit_consumption: +alert.liters_consumed_in_period.toFixed(2) - +alert.liters_exceeded_in_period.toFixed(2),
              alerts: [alert]
            })
          }
          else {
            list.map((n) => {
              if (n.name === alert[entity]) {
                n.alerts = [...n.alerts, alert]
                // n.liters_consumed_in_period += +alert.liters_consumed_in_period.toFixed(2)
                // // n.liters_exceeded_in_period += +alert.liters_exceeded_in_period.toFixed(2)
                // // n.limit_consumption += +alert.liters_consumed_in_period.toFixed(2) - +alert.liters_exceeded_in_period.toFixed(2)
                n.amount += 1
              }
            })
          }
        })
        return list
      }
      return []
    },
    findGenerateType(type = null, item) {
      switch (this.last_serach_alerts.type) {
        case "franchisee":
          return type ? "unit_id" : "client_id"
        case "client":
          return type ? "id" : "unit_id"
        case "unit":
          if (item.amount) return "unit_id"
          return "id"
        default:
          console.error("Find Generate Type error!")
      }
    },
    async generatePdf(item, status = null) {
      try {
        let data = {}
        data.id = item.id
        data.type = this.findGenerateType(status, item)
        await this.checkAlert(item, data)
      } catch (e) {
        console.error(e)
        showErrorSnackBar("Erro ao arquivar alerta!")
      }
    },
    async checkAlert(alert, data) {
      let ids = []
      if (!alert.alerts) {
        if (alert.entity === "client") {
          data.type = "null"
          alert.alerts = this.alerts_data.filter(({ client_id }) => client_id === data.id)
          alert.alerts.map(i => {
            ids.push(i.id)
          })
          await AlertsService.updateAlerts(ids)
          this.data_table = []
          this.alerts_data = this.alerts_data.filter(({ client_id }) => client_id != data.id)

        } else if (alert.entity === "unit") {
          data.type = "unit_id"
          alert.alerts = this.alerts_data.filter(({ unit_id }) => unit_id === data.id)

          let units = await this.getUnitsBy({
            by: "cliente",
            id: this.client_id,
          });
          let unit_data = units.filter(({ id }) => alert.id === id)
          if (unit_data.length > 0) {
            alert.name = unit_data[0].data.name
          }
        }
      }
      switch (data.type) {
        case "client_id":
          alert.alerts.map(i => {
            ids.push(i.id)

          })
          await AlertsService.updateAlerts(ids)
          this.data_table = this.data_table.filter(({ name }) => {
            return name != alert.name
          })
          this.alerts_data = this.alerts_data.filter(({ client_id }) => client_id != data.id)
          break;
        case "unit_id":
          alert.alerts.map(i => {
            ids.push(i.id)
          })
          await AlertsService.updateAlerts(ids)
          if (this.last_serach_alerts.type === "franchisee") {
            this.data_table_expand = this.data_table_expand.filter(({ name }) => {
              return name != alert.name
            })
          } else if (this.last_serach_alerts.type === "unit") {
            this.data_table = this.data_table.filter(({ unit_id }) => {
              return unit_id != alert.id
            })
          } else {
            this.data_table = this.data_table.filter(({ name }) => {
              return name != alert.name
            })
          }
          this.alerts_data = this.alerts_data.filter(({ unit_id }) => unit_id != data.id)
          break;
        case "id":

          await AlertsService.updateAlerts([data.id])
          this.data_table = this.data_table.filter(({ id }) => {
            return id != alert.id
          })
          this.data_table_expand = this.data_table_expand.filter(({ id }) => {
            return id != alert.id
          })
          this.alerts_data = this.alerts_data.filter(({ id }) => id != data.id)
          break;
        default:
          console.error("type error!")
      }
      showSuccessSnackBar("Alerta arquivado!")
    },
    async getAlertsPerData(selectDate) {

      this.startDatePicker = selectDate
      if (this.last_serach_alerts) {
        if (this.last_serach_alerts.type === "franchisee") await this.catch_id(this.last_serach_alerts, false)
        else {
          this.alerts_data = await AlertsService.getAlertsPayloadConsumptionByEntity("id_franchisee", this.franchisee_id, this.formatDateTimestamp())
          await this.catch_id(this.last_serach_alerts, false)
        }

      }
    },
    formatDateTimestamp() {
      let date = this.startDatePicker.map(date => {
        return (new Date(date).getTime())
      })
      if (date[0] > date[1]) {
        date = [
          date[1],
          date[0]
        ]
      }
      date = [
        new Date(date[0] + 10800000), // 00:00
        new Date(date[1] + 86400000 + 10799999), // 23:59:59
      ]
      this.startDatePicker
      return date
    },

  },
  computed: {

  },
  async created() {
    this.alertsConfigData = await AlertsConfigService.getAlertsConfig()
  }
};