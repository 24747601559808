<template>
  <v-container fluid>
    <FormReport :title="'Visualização Telemetria'" 
      :main_headers="main_headers" 
      :secondary_headers="secondary_headers"
      :select_id="catch_id"
      :data_table_prop="data_table" 
      :data_table_expand_prop="data_table_expand"
      :show_expand_prop="show_expand" 
      :onExpand_prop="onExpand"
      :getAlertsPerData="getAlertsPerData"
      :generatePdf="generatePdf"
      :activate_pdf_button="false"
      :general_button_pdf_name="'Arquivar Todos'"
      :start_view_date="startDatePicker"
      >
      
      <table style="
        border-radius: 5px;
        border-collapse: collapse;
        width: 100%;
        overflow: hidden;
        height: 85%;
      "
      slot="summary-header"
      >
        <caption class="TitleChecklist" style="background: #0a517f; color: white">
          <strong> Resumo </strong>
        </caption>
        <tr>
          <td class="TitleChecklist column-statitcs-info" style="background: #056aae; color: white;">
            Alertas: <strong> {{ summary.amount }}</strong>
          </td>
          <td class="TitleChecklist column-statitcs-info" style="background: #0e76bc; color: white">
            Falta de Sinal: <strong> {{ summary.lack_of_signal }}</strong>
          </td>      
          <td class="TitleChecklist column-statitcs-info" style="background: #056aae; color: white;">
            Bateria Fraca: <strong> {{ summary.low_battery }}</strong>
          </td>
          <td class="TitleChecklist column-statitcs-info" style="background: #0e76bc; color: white">
            Sem Variação: <strong> {{ summary.no_variation }}</strong>
          </td>      
          <td class="TitleChecklist column-statitcs-info" style="background: #056aae; color: white;">
            Sem Decodificação: <strong> {{ summary.no_decodification }}</strong>
          </td>
          <td v-if="isActiveSolutionButton()" class="TitleChecklist column-statitcs-info" style="text-align: center;background: #0e76bc; color: white;">
            <v-btn color="white" outlined @click="generatedAlertSolution()">Gerar Solução</v-btn>
          </td>
        </tr>
      </table>
        <template v-slot:button-main-table="{onClick,prop,disabled,loading,status}">
          <v-menu  offset-y >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                style="font-weight: bold"
                outlined       
                :disabled="disabled(prop)"
                :loading="loading(prop)"                     
              >
                <v-icon small >
                  mdi-text-box-multiple-outline
                </v-icon>
              </v-btn>
            </template>
            <v-list >
                <v-list-item
                  link
                  v-for="(line, index) in isActiveDetails"
                  :key="index"
                  :value="index"
                >
                  <v-list-item-title  @click="catch_view(line.title,onClick,prop,status)">{{ line.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
          </v-menu>
        </template>
    </FormReport>

    <v-row justify="center">
      <v-dialog v-model="details_dialog" v-if="details_dialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5"><strong>Detalhes do Alerta</strong></span>
           
          </v-card-title>
          <v-card-text>
            <v-divider />
            <v-row>
              <v-col sm="8">
                <h2 style="padding: 20px"> {{data_alert_for_dialog.type }}</h2>
              </v-col>   
              <v-spacer></v-spacer>    
              <v-col sm="4">
                  <v-text-field
                    :readonly="true"
                    label="Data do Alerta"
                    v-model="data_alert_for_dialog.date_of_occurrence"
                  ></v-text-field>
              </v-col>   

            </v-row>
            <v-row>     
              <v-col sm="8">
                <v-text-field
                  label="Unidade"
                  v-model="data_alert_for_dialog.unit_name"
                  :readonly="true"
                ></v-text-field>
              </v-col>             
              <v-col sm="4">
                <v-text-field
                  label="Modem"
                  v-model="data_alert_for_dialog.modem_id"
                  :readonly="true"
                ></v-text-field>
              </v-col>             
            </v-row>
            <v-row v-if="data_alert_for_dialog.type === 'Falta de Sinal' " >
              <v-col sm="4">
                <v-text-field
                  :readonly="true"
                  label="Data do Alerta"
                  v-model="data_alert_for_dialog.date_of_occurrence"
                ></v-text-field>
              </v-col>
              <v-col sm="4">
                <v-text-field
                  :readonly="true"
                  label="Último Sinal"
                  v-model="data_alert_for_dialog.last_signal_date"
                ></v-text-field>
              </v-col>
              <v-col sm="4">
                <v-text-field
                  :readonly="true"
                  label="Dias sem sinal"
                  v-model="data_alert_for_dialog.diff_dates"
                ></v-text-field>
              </v-col>      
            </v-row>
            <v-row v-if="data_alert_for_dialog.type === 'Bateria Fraca' " >
              <div class="battery">
                <div  class="batteryCharger" :style="'width:'+ data_alert_for_dialog.percentage + '%;'"></div>
                <h2 style="    position: absolute;
                  left: 320px;
                  top: 26px;"
                  >{{data_alert_for_dialog.percentage + "%"}}
                </h2>
              </div>
            </v-row>
            <v-row v-if="data_alert_for_dialog.type === 'Sem Decodificação' " >
              <v-col sm="6">
                <v-text-field
                  :readonly="true"
                  label="Sinais com erro no dia do Alerta"
                  v-model="data_alert_for_dialog.total_errors"
                ></v-text-field>
              </v-col>
              
            </v-row>
            <v-divider />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="details_dialog = false">
              Fechar
            </v-btn>            
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="solution_dialog" v-if="solution_dialog" persistent>
        <v-card>
          <v-card-title>
            <span class="text-h5">Possivel solução </span>   
          </v-card-title>
          <v-card-text>
            <v-divider />
              <v-row v-if="loading.skeleton">
                <v-col v-for="n in 3" :key="n" cols="12" md="12">
                  <skeleton-component
                    style="height: 30px; border-radius: 15px"
                  ></skeleton-component>
                </v-col>
              </v-row>
              <v-row v-for="modem in modems_solutiion" :key="modem.id">
                <v-col sm="4" style="display: flex;">
                  <div v-if="modem.has_urgency" style="margin-right: 10px;display: flex;justify-content: center;margin-bottom: 15px;">
                    <v-icon color="red" style="font-size: 39px;">warning</v-icon>
                  </div>
                  <v-text-field
                    :readonly="true"
                    label="Modem"
                    v-model="modem.name"
                  ></v-text-field>
                </v-col>
                <v-col sm="4">
                  <v-text-field
                    :readonly="true"
                    label="Problema"
                    v-model="modem.problem"
                  ></v-text-field>
                </v-col>
                <v-col sm="4">
                  <v-text-field
                    :readonly="true"
                    label="Solução"
                    v-model="modem.solutions"
                  ></v-text-field>
                </v-col>
              </v-row>
            <v-divider />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="solution_dialog = false">
              Fechar
            </v-btn>            
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import AlertsScript from './AlertsScript';
export default AlertsScript;
</script>


<style >
 .battery {
  position: relative;
  margin: 10px auto;
  width: 300px;
  border: 10px solid rgb(0, 151, 212);
  height: 100px;
  border-radius: 15px;
}

.batteryCharger {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  height: 68px;
  background-color: rgb(230, 16, 16,0.5);
  border-radius: 5px;
 }
.battery:after {
  content: "";
  position: absolute;
  right: -30px;
  top: 14px;
  width: 15px;
  height: 50px;
  background-color: rgb(0, 151, 212);
  border-bottom-right-radius: 90px;
  border-top-right-radius: 90px;
} 
</style>