<template>
  <v-container fluid>
    <v-row class="dashboardData">
      <v-col cols="12">
        <v-card class="rounded-xl">
          <v-card-title class="title-displays">Relatório de Chamados</v-card-title>
          <v-card-title style="padding-bottom: 0px;">
            <v-row>
              <v-col cols="12" sm="4">
                <select-franchisee @id="catch_franchisee_id" />
              </v-col>
              <v-col cols="12" sm="4">
                <select-client @id="catch_client_id" :reset="reset_client" :franchisee-id="franchisee_id"
                  :disabled="loading.main_table" />
              </v-col>
              <v-col cols="12" sm="4">
                <select-unit @id="catch_unit_id" :client-id="client_id" :disabled="loading.main_table" />
              </v-col>
              <v-dialog v-model="startDateDialog" max-width="338px" @click="startDateDialog = !startDateDialog">
                <v-card>
                  <v-card-title>
                    <span>Intervalo de Datas</span>
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card-text>
                    <v-date-picker class="date-picker-height" v-model="startDatePicker" type="date" locale="pt-br"
                      :max="maxStartDate" @change="(newDate) => selectDateCalendar(newDate)" range></v-date-picker>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="startDateDialog = !startDateDialog">
                      Fechar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-col cols="8" sm="4">
                <v-row>
                  <v-col cols="2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" big color="primary" @click="startDateCalendar()"
                          :disabled="loading.main_table">mdi-calendar</v-icon>
                      </template>
                      <span>Selecionar Data
                      </span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="10">
                    <v-btn width="100%" color="primary" style="font-weight: bold" outlined @click="resetSelects()"
                      :disabled="loading.main_table">
                      Limpar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4" style="margin: 14px;color: #1976d2;" >
                Período: {{  SelectDateFormat  }} 
                               
              </v-col>
              <v-spacer> </v-spacer>

              <v-col v-if="lastValueSelect[1].select != 'client'" cols="12" sm="2"
                style="margin: 10px 0px 10px 0px;display: flex;justify-content: end">
                <v-btn :disabled="loading.main_table || data_table.length == 0" color="primary"
                  style="font-weight: bold;width: 100%;" outlined @click="generateGeneralPdf()"
                  :loading="loading.general_button_pdf">
                  <v-icon small class="mr-2">
                    mdi-file-document-arrow-right-outline
                  </v-icon>
                  PDF RESUMO
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-actions class="text-center" style="
                            border-style: solid;
                            border-width: 2px 0px 2px 0px;
                            border-color: #dddddd;
      
                          ">
            <table style="
                              border-radius: 5px;
                              border-collapse: collapse;
                              width: 100%;
                              overflow: hidden;
                              height: 85%;
                            ">
              <caption class="TitleChecklist" style="background: #0a517f; color: white">
                <strong> Resumo </strong>
              </caption>
              <tr>
                <td class="TitleChecklist column-statitcs-info" style="background: #056aae; color: white">
                  Chamados: <strong>{{ summary.size }}</strong>
                </td>
                <td class="TitleChecklist column-statitcs-info" style="background: #0e76bc; color: white">
                  Abertos: <strong>{{ summary.openCall }}</strong>
                </td>
                <td class="TitleChecklist column-statitcs-info" style="background: #056aae; color: white">
                  Andamento: <strong>{{ summary.progressCall }}</strong>
                </td>
                <td class="TitleChecklist column-statitcs-info" style="background: #0e76bc; color: white">
                  Concluídos: <strong>{{ summary.concluded }}</strong>
                </td>
              </tr>

            </table>
          </v-card-actions>

          <v-data-table :headers="dessertHeaders" :items="data_table" :single-expand="true" :expanded.sync="expanded"
            item-key="name" :show-expand="show_expand" @item-expanded="onExpand" :loading="loading.main_table">
            <template v-slot:item.actions="{ item }">

              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" color="primary" style="font-weight: bold" outlined
                    :disabled="!show_expand || loading.general_button_pdf" :loading="loading.main_button_pdf &&
                      loading.pdf_index_button == item.index
                      ">
                    <v-icon small>
                      mdi-text-box-multiple-outline
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item link v-for="(line, index) in titles_button_download" :key="index" :value="index">
                    <v-list-item-title @click="generatePdf(item, null, line.title)">{{ line.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" style="padding: 10px">
                <v-data-table :headers="dessertHeaders_2" :items="data_table_expand" :single-expand="true" item-key="name"
                  class="elevation-1" :loading="loading.secondary_table">
                  <template v-slot:item.actions="{ item }">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" color="primary" style="font-weight: bold" outlined
                          :disabled="dessertHeaders_2[0].text === 'Setor'" :loading="loading.secondary_button_pdf &&
                            loading.pdf_index_button == item.index">
                          <v-icon small>
                            mdi-text-box-multiple-outline
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item link v-for="(line, index) in titles_button_download" :key="index" :value="index">
                          <v-list-item-title @click="generatePdf(item, 'table_2', line.title)">{{ line.title
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <DialogSendEmail :open="dialog_send_email" :runFunc="sendEmail"/>
  </v-container>
</template>

<script>
import ReportScript from "./ReportScript";
export default ReportScript;
</script>

<style ></style>