<template>
    <v-container fluid>
        <!-- start ROI Dialog -->
        <v-row justify="center">
        <v-dialog transition="dialog-top-transition" v-model="definedModal" persistent max-width="800px">
            <v-card>
            <v-form ref="downloadPdfForm" lazy-validation>
                <v-toolbar color="primary" dark> <span class="text-h5">Geração do ROI de Performance</span></v-toolbar>
                <v-card-text style="padding-bottom: 0px;">
                    <v-row>
                        <v-col>
                            <PercentageReferenceAverageField :roiType="roiType" :proposalType="proposalType" :proposal="proposal" />
                        </v-col>
                        <v-col>
                            <TaxField :roiType="roiType" :proposalType="proposalType" :proposal="proposal" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <CostsAdditional :roiType="roiType" :proposalType="proposalType" :proposal="proposal" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <MonthlyCosts :roiType="roiType" :proposalType="proposalType" :proposal="proposal" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <Investors :roiType="roiType" :proposalType="proposalType" :proposal="proposal" />
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="() => closeModal()" :loading="isLoadingROI">
                        Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="() => generate()" :loading="isLoadingROI" :disabled="isDisabledROI">Gerar</v-btn>
                </v-card-actions> 
            </v-form>
            </v-card>
        </v-dialog>
        </v-row>
        <!-- end ROI Dialog -->
    </v-container>
</template>
<script>
import { mapActions } from "vuex";
import RoiApi from '@/services/apiPdf/RoiApi.js'
import Investors from '@/components/ROI/Investors.vue'
import CostsAdditional from '@/components/ROI/CostsAdditional.vue'
import MonthlyCosts from '@/components/ROI/MonthlyCosts.vue'
import TaxField from '@/components/ROI/TaxField.vue'
import PercentageReferenceAverageField from '@/components/ROI/PercentageReferenceAverageField.vue'
import WaterScanService from '@/services/firebase/WaterScan/WaterScanService';
import Utils from "@/scripts/Utils";

export default {
    props: {
        modal: {
          type: Boolean,
          require: true,
          default: false,
        },
        // Parâmetros obrigatórios
        proposalType: {
          type: String,
          require: true,
        },
        idProposal: {
          type: String,
          require: true,
        },
    },
    components: {
        Investors,
        CostsAdditional,
        TaxField,
        MonthlyCosts,
        PercentageReferenceAverageField,
    },
    data: () => ({
        definedModal: false,
        definedLoading: true,
        definedDisabled: false,
        identification: null,
        roiType: 'performance',
        proposal: null,
        disabled: {
            'tax': false,
            'costsAdditional': false,
            'investors': false,
            'monthlyCosts': false,
            'percentageReferenceAverage': false,
        },
        loading: {
            'tax': false,
            'costsAdditional': false,
            'investors': false,
            'monthlyCosts': false,
            'percentageReferenceAverage': false,
        },
    }),
    computed: {
        isOpenROIModal() {
            return this.definedModal
        },
        isLoadingROI() {
            return this.definedLoading || this.hasLoading
        },
        isDisabledROI() {
            return this.isLoadingROI || this.definedDisabled || this.hasDisabled
        },
        hasLoading() {
            return Object.values(this.loading).reduce((prev, cur) => prev || cur, false)
        },
        hasDisabled() {
            return Object.values(this.disabled).reduce((prev, cur) => prev || cur, false)
        },
    },
    methods: {
        ...mapActions([
            'subscribeChannel',
        ]),
        openROIModal() {
            this.definedModal = true
        },
        emitModal() {
            this.$emit('close')
        },
        closeModal() {
            this.definedModal = false
            this.tax = 0
            this.emitModal()
        },
        async generate() {
            try {
                this.definedLoading = true
                await RoiApi.generateRoiPerformancePdf(
                    this.identification, {
                    proposalType: 'waterscan',
                    idProposal: this.idProposal,
                })
                this.definedLoading = false
            } catch (e) {
                console.error(e)
                this.definedLoading = false
            }
        },
        async fetchProposal(idProposal) {
            // ------------------------------------
            // Buscar a proposta
            try {
                if (this.proposalType == 'waterscan') {
                    this.proposal = await WaterScanService.getProposalById(idProposal)
                    this.identification = this.proposal.locationInformation.name
                } else {
                    
                }
                this.definedLoading = false
            } catch (e) {
                console.error(e)
                this.definedLoading = false
            }
        },
    },
    watch: {
        async modal(newValue) {
            this.definedModal = newValue
        },
        async idProposal(newValue) {
            if (newValue) await this.fetchProposal(newValue)
        },
    },
    created() {
        this.subscribeChannel({
            channel: 'roi',
            events: [
                // -----------------------------------------------------
                // Imposto
                {
                    event: 'tax',
                    fn: (tax) => {
                        this.proposal.tax = tax
                    }
                }, 
                {
                    event: 'tax:loading',
                    fn: (status) => {
                        this.loading['tax'] = status
                    }
                }, 
                {
                    event: 'tax:disabled',
                    fn: (status) => {
                        this.disabled['tax'] = status
                    }
                },
                // -----------------------------------------------------
                // Custos Adicionais
                {
                    event: 'costsAdditional',
                    fn: (costsAdditional) => {
                        this.proposal.costsAdditional = costsAdditional
                    }
                }, 
                {
                    event: 'costsAdditional:loading',
                    fn: (status) => {
                        this.loading['costsAdditional'] = status
                    }
                }, 
                {
                    event: 'costsAdditional:disabled',
                    fn: (status) => {
                        this.disabled['costsAdditional'] = status
                    }
                },
                // -----------------------------------------------------
                // Investidores
                {
                    event: 'investors',
                    fn: (investors) => {
                        this.proposal.investors = investors
                    }
                }, 
                {
                    event: 'investors:loading',
                    fn: (status) => {
                        this.loading['investors'] = status
                    }
                }, 
                {
                    event: 'investors:disabled',
                    fn: (status) => {
                        this.disabled['investors'] = status
                    }
                },
                // -----------------------------------------------------
                // Custos Mensais
                {
                    event: 'monthlyCosts',
                    fn: (monthlyCosts) => {
                        this.proposal.monthlyCosts = monthlyCosts
                    }
                }, 
                {
                    event: 'monthlyCosts:loading',
                    fn: (status) => {
                        this.loading['monthlyCosts'] = status
                    }
                }, 
                {
                    event: 'monthlyCosts:disabled',
                    fn: (status) => {
                        this.disabled['monthlyCosts'] = status
                    }
                },
                // -----------------------------------------------------
                // Porcentagem da Média de Referencia
                {
                    event: 'percentageReferenceAverage',
                    fn: (percentageReferenceAverage) => {
                        this.proposal.percentageReferenceAverage = percentageReferenceAverage
                    }
                }, 
                {
                    event: 'percentageReferenceAverage:loading',
                    fn: (status) => {
                        this.loading['percentageReferenceAverage'] = status
                    }
                }, 
                {
                    event: 'percentageReferenceAverage:disabled',
                    fn: (status) => {
                        this.disabled['percentageReferenceAverage'] = status
                    }
                },
            ]
        })
    },
}

</script>