import * as firebase from "firebase/app";

import "firebase/analytics";
import "firebase/auth";
import "firebase/firestore";
//import 'firebase/messaging'
import Utils from '@/scripts/Utils';
import "firebase/functions";
import "firebase/storage";
var { DateTime } = require("luxon");

// firebase init - add your own config here
if (process.env.NODE_ENV == 'development' || window.location.host == 'centralfoz-homolog.web.app') {
  console.log("---Develop---")
  var firebaseConfig = {
    apiKey: "AIzaSyCWGMHkhjOAA0C0aT7ep7iGPDSnMZEKLPw",
    authDomain: "centralfoz-homolog.firebaseapp.com",
    projectId: "centralfoz-homolog",
    storageBucket: "centralfoz-homolog.appspot.com",
    messagingSenderId: "465458998613",
    appId: "1:465458998613:web:cbe8aee74afda494c013dc",
    measurementId: "G-E2KRQDFXX0"
  };
} else {
  //Production
  var firebaseConfig = {
    apiKey: "AIzaSyB31ipzXfkizKuaf3A0BJBiEKPZ7XujAmI",
    authDomain: "sistema-foz.firebaseapp.com",
    databaseURL: "https://sistema-foz.firebaseio.com",
    projectId: "sistema-foz",
    storageBucket: "sistema-foz.appspot.com",
    messagingSenderId: "601341373999",
    appId: "1:601341373999:web:ca0589f39c7dad731be0da",
    measurementId: "G-4QFMPST3ZM",
  };
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// utils
export const db = firebase.firestore();
if (process.env.NODE_ENV == 'development') {
  // TODO: Get port from environment variable
  firebase.functions().useFunctionsEmulator('http://localhost:5001');
}
export const auth = firebase.auth();
const auth_class = firebase.auth;
//const messaging = firebase.messaging();
export const storage = firebase.storage();

// const storageWaterScan = firebase.app().storage("gs://centralfoz-homolog.appspot.com/water_scan_imgs");


async function updateAlert(newAlertReaded, idAlert) {
  try {
    await db.collection("alerts").doc(idAlert).update({
      average: newAlertReaded.average,
      consumption: newAlertReaded.consumption,
      time: newAlertReaded.time,
      excessive: newAlertReaded.excessive,
      intervalDays: newAlertReaded.intervalDays,
      modem: newAlertReaded.modem,
      threshold: newAlertReaded.threshold,
      usersStatus: newAlertReaded.usersStatus
    });
  } catch (error) {
    console.error(error);
    return 1;
  }
}


async function getAlerts(clientID) {
  var alertsCurrentUser = [];
  try {
    var queryAllAlerts = await db.collection("alerts").orderBy("time", "desc").get();

    queryAllAlerts.docs.forEach((alert) => {

      if (alert.data().usersStatus[clientID]) {

        var newAlert = { ...alert.data(), idAlert: alert.id }
        alertsCurrentUser.push(newAlert);
      }
    });


    return alertsCurrentUser;

  } catch (error) {
    console.error("Erro inesperado ao buscar alerta: ", error)
  }
}

async function addModem(modem) {
  try {
    if (modem["id"] === undefined) {
      throw "field 'id' is missing";
    }
    await db
      .collection("modens")
      .doc(modem["id"])
      .set(modem);
    return 0;
  } catch (error) {
    console.error(error);
    return 1;
  }
}

async function getModem(id) {
  try {
    let doc = await db
      .collection("modens")
      .doc(id)
      .get();
    return doc.data();
  } catch (error) {
    console.error(error);
    return;
  }
}

async function getModems() {
  try {
    var modems = [];
    let doc = await db.collection("modens").get();
    doc.forEach((modem) => {
      modems.push(modem.data());
    });
    return modems;
  } catch (error) {
    console.error(error);
    return;
  }
}

async function getLeituras(modem) {
  try {
    var leituras = [];
    let doc = await db.collection("readings").get();
    doc.forEach((leituras) => {
      leituras.push(leituras.data());
    });
    return leituras;
  } catch (error) {
    console.error(error);
    return;
  }
}

async function updateModem(id, modem) {
  try {
    if (modem["id"] !== id) {
      throw "id cannot be changed./Ids don't match";
    }
    await db
      .collection("modens")
      .doc(id)
      .update(modem);
    return 0;
  } catch (error) {
    console.error(error);
    return 1;
  }
}


async function deleteModem(id) {
  try {
    await db
      .collection("modens")
      .doc(id)
      .delete();
    return 0;
  } catch (error) {
    console.error(error);
    return 1;
  }
}


async function getRejectedReadings(start, end, modem, hydrometer) {
  let model = modem.model;
  let modemId = modem.id;
  let ref = db.collection(
    model == "2" ? "readings" : model == "3" ? "rejected_readings" : ""
  );
  let isModel3 = model == 3;

  if (modemId !== undefined) {
    ref = ref.where("origin", "==", modemId);
  }
  if (!isModel3) {
    ref.where("hydrometer4", ">", 0);
  }
  if (start !== undefined) {
    ref = ref.where("datetime", ">=", start);
  }
  if (end !== undefined) {
    ref = ref.where("datetime", "<=", end).orderBy("datetime");
  }
  try {
    let readings = [];
    let querySnapshot = await ref.get();

    // const first = querySnapshot.docs[0].data();

    // let previous = undefined;
    // if (!isModel3) {
    //   previous = db
    //     .collection("readings")
    //     .where("hydrometer4", "<", first.hydrometer4);
    // } else {
    //   previous = db.collection("readings");
    // }

    // previous = await previous.get();
    // previous = previous.docs[previous.docs.length - 1];
    // var previousData = previous.data();
    // if (isModel3) {
    //   let array = new Uint8Array(previousData["decoded"]);
    //   let view = new DataView(array.buffer, 0);
    //   let hoursQuantity = view.getUint8(0);
    //   readings.push({
    //     id: previous.id.split("-")[0],
    //     datetime: previousData.datetime.seconds,
    //     reading:
    //       (view.getUint32(2) - hydrometer.baseReading) *
    //       hydrometer.litersPerPulse,
    //   });
    //   for (let i = 0; i < hoursQuantity; i++) {
    //     readings.push({
    //       id: previous.id.split("-")[0],
    //       datetime: previousData.datetime.seconds - 3600 * (i + 1),
    //       reading:
    //         (view.getUint32(2) +
    //           view.getUint8(10 + i) -
    //           hydrometer.baseReading) *
    //         hydrometer.litersPerPulse,
    //     });
    //   }
    // } else {
    //   readings.push({
    //     id: previous.id.split("-")[0],
    //     datetime: previousData.datetime.seconds,
    //     reading: previousData.hydrometer4,
    //   });
    // }


    querySnapshot.docs
      .filter(
        (doc, i) =>
          i == 0 ||
          doc.data().datetime.seconds !=
          querySnapshot.docs[i - 1].data().datetime.seconds
      )
      .forEach((doc, i) => {
        if (isModel3) {
          let array = new Uint8Array(doc.data()["decoded"]);
          let view = new DataView(array.buffer, 0);
          let hoursQuantity = view.getUint8(0);
          readings.push({
            id: doc.id.split("-")[0],
            datetime: doc.data().datetime.seconds - 3600 * hoursQuantity,
            reading: view.getUint32(2),
          });

          let sum = readings[readings.length - 1].reading;
          for (let i = 0; i < hoursQuantity; i++) {
            sum -= view.getUint8(10 + i * 2);

          }
          readings[readings.length - 1].reading = sum;
          for (let i = 0; i < hoursQuantity; i++) {
            readings.push({
              id: doc.id.split("-")[0],
              datetime:
                doc.data().datetime.seconds - 3600 * (hoursQuantity - (i + 1)),
              // reading:
              //   (view.getUint32(2) +
              //     view.getUint8(10 + i) -
              //     hydrometer.baseReading) *
              //   hydrometer.litersPerPulse,
              reading: view.getUint8(10 + i * 2) + sum,
            });
            sum = readings[readings.length - 1].reading;

          }
        } else {
          readings.push({
            id: doc.id.split("-")[0],
            datetime: doc.data().datetime.seconds,
            reading:
              (doc.data().hydrometer4 - hydrometer.baseReading) *
              hydrometer.litersPerPulse,
          });
        }
      });
    readings = [
      ...readings.map((el) => {
        return {
          ...el,
          reading:
            (el.reading - hydrometer.baseReading) * hydrometer.litersPerPulse,
        };
      }),
    ];

    return readings;
  } catch (error) {
    console.error(error);
    return [];
  }
}
async function getReadingsFromModem(start, end, mode, modemId) {
  var readings = [];
  // Consumo por dia
  if (mode == "day") { // OK
    try {
      let querySnapshot = await db.collection("daily_readings")
        .where("modem", "==", modemId)
        .where("time", ">=", start)
        .where("time", "<=", end)
        .orderBy("time")
        .get();
      querySnapshot.docs.forEach((doc) => readings.push(doc.data()));
    } catch (error) {
      console.error("Ocorreu um erro inesperado: ", error);
    }
  } else if (mode == "hour") {   // Consumo por hora
    try {
      let querySnapshot = await db.collection("hourly_readings")
        .where("modem", "==", modemId)
        .where("time", ">=", start)
        .where("time", "<=", end)
        .orderBy("time")
        .get();
      querySnapshot.docs.forEach((doc) => readings.push(doc.data()));
    } catch (error) {
      console.error("Ocorreu um erro inesperado: ", error);
    }
  } else if (mode == "month") {  // Consumo por Mês      
    try {
      let querySnapshot = await db.collection("monthly_readings")
        .where("modem", "==", modemId)
        .where("time", ">=", start)
        .where("time", "<=", end)
        .orderBy("time")
        .get();
      querySnapshot.docs.forEach((doc) => readings.push(doc.data()));
    } catch (error) {
      console.error("Ocorreu um erro inesperado: ", error);
    }
  }
  readings = readings.map((item) => ({ ...item, time: Utils.instanceDate(item.time.seconds) }))
  return readings;
}
//goalCardData(sector) retorna dados prontos para ir no card de meta
//sector: String com o identificador do setor.
async function goalCardData(sector) {
  let doc = await db
    .collection("sectors")
    .doc(sector)
    .get();

  let meta = doc.data()["meta"];

  let now = new Date();
  now.setDate(1);
  now.setHours(0);
  now.setMinutes(0);
  now.setSeconds(0);
  now.setMilliseconds(0);

  let end = now;
  end.setDate(2);

  let savings = await savingsChartData(sector, now, end);

  return { meta: meta, atingido: savings[0] };
}

//ticketsCardData(uid, sector) retorna dados prontos para ir no card de chamados
//uid: String com o identificador do usuário.
//
//sector: String com o identificador do setor.
async function ticketsCardData(uid, sector) {
  let tickets = await getTickets(
    uid,
    undefined,
    undefined,
    undefined,
    undefined,
    sector
  );

  let opened = 0;
  let closed = 0;

  for (let ticket of tickets) {
    if (ticket.data["status"] === "closed") {
      closed += 1;
    } else {
      opened += 1;
    }
  }
  return { opened: opened, closed: closed };
}

//savingsChartData(sector, start, end) retorna data pronto para ir no chartjs para o gráfico de economia
//sector: String com id de um setor
//
//start: Um objeto Date.
//Se especificado, retorna as medições feitas a partir do datetime em start, caso contrário, retorna todas as medições.
//
//end: Um objeto Date.
//Se especificado, retorna as medições feitas até o datetime em end, caso contrário, retorna todas as medições.
async function ticketsChartData(uid, device, start, end) {
  let tickets = await getTickets(uid, undefined, undefined, start, end, device);

  let opened = [];
  let closed = [];
  for (let i = 0; i <= monthDiff(start, end); i++) {
    opened.push(0);
    closed.push(0);
  }

  for (let ticket of tickets) {
    if (ticket.data["status"] === "closed") {
      closed[monthDiff(start, ticket.data["openedAt"])] += 1;
    } else {
      opened[monthDiff(start, ticket.data["openedAt"])] += 1;
    }
  }

  return { opened: opened, closed: closed };
}

//savingsChartData(uid, device, start, end) retorna data pronto para ir no chartjs para o gráfico de economia
//user: String com o identificador do usuário.
//
//device: String que identifica um modem:hidrometro no formato "<identificador do modem>:<número do hidrometro(1,2,3 ou 4)>".
//
//start: Um objeto Date.
//Se especificado, retorna as medições feitas a partir do datetime em start, caso contrário, retorna todas as medições.
//
async function savingsChartData(sector, start, end) {
  let nfozreadings = await getReadings(sector, undefined, undefined, false);
  let readings = await getReadings(sector, start, end, true);

  let data_nfoz = [[], [], [], [], [], [], [], [], [], [], [], []];
  let data_foz = [];
  for (let i = 0; i <= monthDiff(start, end); i++) {
    data_foz.push([]);
  }

  for (let reading of readings) {
    data_foz[reading["datetime"].getMonth()].push(reading["data"]);
  }
  for (let reading of nfozreadings) {
    data_nfoz[monthDiff(start, reading["datetime"])].push(reading["data"]);
  }
  for (let month in data_foz) {
    let sum = data_foz[month].reduce((a, b) => a + b, 0);
    let avg = sum / data_foz[month].length || null;
    data_foz[month] = avg;
  }
  for (let month in data_nfoz) {
    let sum = data_nfoz[month].reduce((a, b) => a + b, 0);
    let avg = sum / data_nfoz[month].length || null;
    data_nfoz[month] = avg;
  }
  for (let i in data_foz) {
    if (data_nfoz[i] === null || data_foz[i] === null) {
      data_foz[i] = null;
    } else {
      data_foz[i] = (100 * (data_nfoz[i] - data_foz[i])) / data_nfoz[i];
    }
  }
  return data_foz;
}

//monthlyChartData(sector, start, end) retorna json com 2 data prontos para ir no chartjs para o gráfico de consumo mensal
//O formato do json é { "foz": data_foz, "notfoz": data_nfoz };
//
//sector: String com o id de um setor.
//
//start: Um objeto Date.
//Se especificado, retorna as medições feitas a partir do datetime em start, caso contrário, retorna todas as medições.
//
//end: Um objeto Date.
//Se especificado, retorna as medições feitas até o datetime em end, caso contrário, retorna todas as medições.
//
async function monthlyChartData(sector, start, end) {
  let readings = await getReadings(sector, start, end, undefined);

  let data_foz = [];
  let data_nfoz = [];
  for (let i = 0; i <= monthDiff(start, end); i++) {
    data_foz.push([]);
    data_nfoz.push([]);
  }

  for (let reading of readings) {
    if (reading["isFoz"] === true) {
      data_foz[monthDiff(start, reading["datetime"])].push(reading["data"]);
    } else {
      data_nfoz[monthDiff(start, reading["datetime"])].push(reading["data"]);
    }
  }
  for (let month in data_foz) {
    let sum = data_foz[month].reduce((a, b) => a + b, 0);
    let avg = sum / data_foz[month].length || null;
    data_foz[month] = avg;
  }
  for (let month in data_nfoz) {
    let sum = data_nfoz[month].reduce((a, b) => a + b, 0);
    let avg = sum / data_nfoz[month].length || null;
    data_nfoz[month] = avg;
  }
  return { foz: data_foz, notfoz: data_nfoz };
}

//historyChartData(sector, start, end, mode) retorna data pronto para ir no chartjs para o gráfico de histórico de consumo
//sector: String com o id de um setor.
//
//start: Um objeto Date.
//Se especificado, retorna as medições feitas a partir do datetime em start, caso contrário, retorna todas as medições.
//
//end: Um objeto Date.
//Se especificado, retorna as medições feitas até o datetime em end, caso contrário, retorna todas as medições.
//
//mode: Uma String com o modo de exibição do gráfico
//Pode ter os valores "day" para mostrar os dados do dia (intervalo de 1h), "week" para mostrar os dados da semana (médias já calculados por dia)
//"month" para mostrar os dados do mês (médias calculadas por dia) e "year" para mostrar os dados do ano (médias calculados por mês)
async function historyChartData(sector, start, end, mode) {
  let readings = await getReadings(sector, start, end, undefined);

  if (mode === "day") {
    let data = [];
    for (let i = 0; i <= hourDiff(start, end); i++) {
      data.push(null);
    }

    for (let reading of readings) {
      data[hourDiff(start, reading["datetime"])] = reading["data"];
    }
    return data;
  } else if (mode === "month") {
    let data = [];
    for (let i = 0; i <= dayDiff(start, end); i++) {
      data.push([]);
    }

    for (let reading of readings) {
      data[dayDiff(start, reading["datetime"])].push(reading["data"]);
    }
    for (let day in data) {
      let sum = data[day].reduce((a, b) => a + b, 0);
      let avg = sum / data[day].length || null;
      data[day] = avg;
    }
    return data;
  } else if (mode === "year") {
    let data = [];
    for (let i = 0; i <= monthDiff(start, end); i++) {
      data.push([]);
    }

    for (let reading of readings) {
      data[monthDiff(start, reading["datetime"])].push(reading["data"]);
    }
    for (let month in data) {
      let sum = data[month].reduce((a, b) => a + b, 0);
      let avg = sum / data[month].length || null;
      data[month] = avg;
    }
    return data;
  } else {
    return [];
  }
}

//registerUser(user, units, sectors): Cria um usuário e adiciona os dados no BD
//user: JSON os os dados do usuário
async function registerUser(user) {
  try {

    await firebase.auth().createUserWithEmailAndPassword(user.email, user.password)
      .then((userCredential) => {
        const uidUser = userCredential.user.uid;
        user.id = uidUser
      })
      .catch((error) => {
        throw new Error(error)
      });
    delete user.password
    delete user.metadata.image
    await db.collection('users').doc(user.id).set(user)
    return user
  } catch (error) {
    return error
  }

}
async function registerUserORIGINAL(user) {

  // var email = user.data.email;
  // var password = user.data.password;
  // var name = user.data.name;
  // var manager = user.data.manager === "" ? undefined : user.data.manager;
  // var client = user.data.client === "" ? undefined : user.data.client;
  // var accessRank = stringToRank(user.data.rank);
  // var metadata = user.data.metadata;



  try {
    // if (
    //   email === undefined ||
    //   password === undefined ||
    //   name === undefined ||
    //   accessRank === undefined
    // ) {
    //   throw "Data missing";
    // }
    // let hasManagerRanks = [3, 4];
    // if ((hasManagerRanks.includes(accessRank)) && manager === undefined) {
    //   throw "Manager missing";
    // }
    // if ((accessRank == 5) && client === undefined) {
    //   throw "Client missing";
    // }

    let json = user;

    // Se for usuário admin, incluí-se um campo referente a permissão para deletar dados.
    // if(accessRank === 1) {

    //   var canDeletedData = user.data.canDeletedData;    

    //   json = {
    //     email: email,
    //     password: password,
    //     name: name,
    //     rank: accessRank,
    //     FCM_TOKENS: [],
    //     metadata: metadata,
    //     canDeletedData: canDeletedData
    //   };


    // } else {
    //   json = {
    //     email: email,
    //     password: password,
    //     name: name,
    //     rank: accessRank,
    //     FCM_TOKENS: [],
    //     metadata: metadata,
    //   };
    // } 


    // if (manager !== undefined) {
    //   json["manager"] = manager;
    // }
    // if (client !== undefined) {
    //   json["client"] = client;
    // }
    // if (status !== undefined) {
    //   json["status"] = status;
    // }



    let func = firebase.functions().httpsCallable("createUser");
    let ret = await func(json);
    if (ret.data["result"] == 0) {
      return ret.data["uid"];
    } else {
      throw ret.data["error"];
    }
  } catch (error) {
    console.error(error);
    alert(error);
    // deleteUser(user.user.uid);
  }
}

//updateUserData(uid, name, status, manager, accessRank, dataList): atualiza os dados de um usuário
//uid: String com i do usuário
//Os outros parâmetros são iguais aos da função registerUser
//Os dados que não forem passados (ou passados como undefined) não serão alterados no BD
async function updateUserData(user) {
  try {
    await db
      .collection("users")
      .doc(user.id)
      .update(user);
  } catch (error) {
    console.error(error);
  }
}

async function getUnits() {
  try {
    let ref = db.collection("units").where("deletedAt", "==", null);
    let units = [];
    let querySnapshot = await ref.get();

    let historics = await db.collectionGroup("unit_historics").get()

    let historicsFormatByUnitId = []
    historics.forEach((doc) => {
      historicsFormatByUnitId.push(doc.ref.parent.parent.id)
    });
    querySnapshot.forEach((doc) => {
      const unit_id = doc.id
      const unit_doc = doc

      if (historicsFormatByUnitId.find((id) => id === unit_id)) {
        let accum_historics = []
        historics.forEach((doc) => {
          if (doc.ref.parent.parent.id === unit_id) {
            accum_historics.push(doc.data())
          }
        });
        units.push({ ...unit_doc.data(), historicos: accum_historics });

      } else {
        units.push(
          unit_doc.data());
      }
    });
    return units
  } catch (e) {
    console.error(e)
  }
}

async function getClientByName(name) {
  return new Promise(
    async (resolve, reject) => {
      try {
        let ref = db.collection("users");
        ref = ref.where("name", "==", name);

        let users = [];
        let querySnapshot = await ref.get();
        querySnapshot.forEach((doc) => {
          users.push({
            id: doc.id,
            data: doc.data()
          });
        });
        resolve(users);
      } catch (error) {
        reject(error)
      }
    }
  );
}
async function getClientById(id) {

  try {
    let doc = await db.collection("users")
      .doc(id)
      .get();

    return doc.data()
  } catch (error) {
    console.error(error)
  }

}

async function updateUnit(unit) {
  return new Promise(
    async (resolve, reject) => {
      try {
        await db
          .collection("units")
          .doc(unit.id)
          .update(unit);
        resolve(true);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    }
  );
}

async function getUserUnits(uid) {
  try {
    let snapshot = await db
      .collection("units")
      .where("user", "==", uid)
      .get();
    let units = [];
    snapshot.forEach((doc) =>
      units.push({
        id: doc.id,
        data: doc.data(),
      })
    );
    return units;
  } catch (error) {
    console.error(error);
    return [];
  }
}
async function getUnitbyId(id) {
  try {
    let doc = await db
      .collection("units")
      .doc(id)
      .get();
    return rankToString(doc.data());
  } catch (error) {
    console.error(error);
    return 1;
  }
}

async function deleteSector(uid) {
  try {
    let func = firebase.functions().httpsCallable("deleteSector");
    let ret = await func({ id: id });
    if (ret.data["result"] == 0) {
      return 0;
    } else {
      throw ret.data["error"];
    }
  } catch (error) {
    console.error(error);
    return 1;
  }
}

async function getUserbyId(uid) {
  try {
    let doc = await db
      .collection("users")
      .doc(uid)
      .get();
    return rankToString(doc.data());
  } catch (error) {
    console.error(error);
    return 1;
  }
}

async function getUsersbyIdArray(uids) {
  var users = [];

  for (const uid of uids) {
    try {
      let doc = await db
        .collection("users")
        .doc(uid)
        .get();
      users.push(rankToString(doc.data()));
    } catch (error) {
      console.error(error);
      return 1;
    }
  }
  return users;
}

//getUsers(name, manager, accessRank, status): retorna lista com usuários
//Todos os parâmetros são opcionais, os que são forem utilizados devem ser passados como undefined
//name (opcional): String com nome do usuário
//matricula (opcional): String com matricula
//manager (opcional): String com id do gerente (franqueado)
//accessRank (opcional): array de inteiros, representando os níveis de acesso
async function getUsers(name, matricula, manager, accessRank) {
  try {
    if (accessRank !== undefined) {
      let promises = [];
      for (let rank of accessRank) {
        promises.push(getUsers_sr(name, matricula, manager, rank));
      }
      promises = await Promise.all(promises);

      let users = [];
      for (let ele of promises) {
        users = users.concat(ele);
      }
      return users;
    } else {
      return await getUsers_sr(name, matricula, manager, undefined);
    }
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function getUsers_sr(name, matricula, manager, accessRank) {
  try {
    let ref = db.collection("users");

    if (name !== undefined) {
      ref = ref.where("name", ">=", name);
    }
    if (manager !== undefined) {
      ref = ref.where("manager", "==", manager);
    }
    if (accessRank !== undefined) {
      ref = ref.where("rank", "==", accessRank);
    }

    let users = [];
    let querySnapshot = await ref.get();
    querySnapshot.forEach((doc) => {
      if (matricula !== undefined && isIterable(doc.data()["dataList"])) {
        for (let u_data of doc.data()["dataList"]) {
          if (
            u_data["matricula"] !== undefined &&
            u_data["matricula"] == matricula
          ) {
            users.push({ id: doc.id, data: rankToString(doc.data()) });
            break;
          }
        }
      } else {
        users.push({ id: doc.id, data: rankToString(doc.data()) });
      }
    });
    return users;
  } catch (error) {
    console.error(error);
    return [];
  }
}

//deleteUser(uid): deleta usuário
//uid: String com id do usuário
async function deleteUser(data) {
  try {
    // let deletUser = await firebase.auth().updateUser(uid,{disabled: true}) 
    data = Utils.copyObject(data)
    let func = firebase.functions().httpsCallable("deleteUser");
    let ret = await func(data);
    if (ret.data["result"] == 0) {
      return 0;
    } else {
      throw ret.data["error"];
    }
  } catch (error) {
    console.error(error);
    return 1;
  }
}

//openTicket(title, description, descriptors = [], sector): cria um novo chamado
//title: String com título do chamado, a ideia é que seja um breve resumo do problema
//description: String com descrição do chamado, aqui o problema é descrito por completo
//desdriptors: Lista com descritores JSON para cada arquivo a ser anexado. Esse JSON tem o seguinte formato:
//{
//  file: <Objeto File(), Blob() ou Uint8Array()> contendo o arquivo
//  filename: String com nome do arquivo
//  progressUpdateFunction: função para acompanhar o progresso do upload (para mais detalhes consulte a documentação da função uploadFile)
//}
//sector: String com id do setor
async function openTicket(title, description, descriptors = [], sector) {
  try {
    if (auth.currentUser.uid === undefined) {
      throw "not logged in";
    }

    let ref = db.collection("tickets").doc();

    let urls = [];
    for (let descriptor of descriptors) {
      urls.push(
        uploadTicketFile(
          descriptor["file"],
          descriptor["filename"],
          ref.id,
          descriptor["progressUpdateFunction"]
        )
      );
    }
    urls = await Promise.all(urls);
    for (let url of urls) {
      if (url === 1) {
        console.error("Error uploading files. Reverting...");
        await storage
          .ref()
          .child("tickets")
          .child(ref.id)
          .delete();
        await ref.delete();
        return 1;
      }
    }

    let date = firebase.firestore.Timestamp.fromDate(new Date());
    await ref.set({
      title: title,
      description: description,
      urls: urls,
      openedAt: date,
      openedBy: auth.currentUser.uid,
      status: "notAssigned",
      statusUpdatedAt: date,
      assignedTo: null,
      sector: sector,
    });

    return 0;
  } catch (error) {
    console.error(error);
    return 1;
  }
}

//getTicketsByManager(manager, assignedTo, status, openedFrom, openedTo): retorna os chamados de acordo com os parâmetros de busca
//Para os parâmtetros opcionais, caso algum não seja usado basta passar undefined como argumento.
//Os parâmetros undefined não serão levados em conta na busca. Por exemplo, se todos forem undefined a busca retorna todos os chamados
//
//manager: id do gerente
//assignedTo: id do técnico responsável por atender o chamado
//status: Uma String com o status do chamado. Há 4 possibilidades:
//        "notAssigned": O chamado foi criado, mas não foi atribuido a nenhum técnico,
//        "assigned": O chamado foi atribuído a um técnico, mas ele não começou a trabalhar no chamado,
//        "processing": O técnico está trabalhando do chamado,
//        "closed": O chamado foi encerrado.
//openedFrom: Objeto Date(). Serão retornados chamados abertos a partir dessa data-hora.
//openedTo: Objeto Date(). Serão retornados chamados abertos até essa data-hora.
async function getTicketsByManager(
  manager,
  assignedTo,
  status,
  openedFrom,
  openedTo
) {
  try {
    let snapshot = await db
      .collection("users")
      .where("manager", "==", manager)
      .get();

    let uids = [];
    snapshot.forEach((doc) => {
      uids.push(doc.id);
    });

    let snapshots = [];
    for (let uid of uids) {
      let ref = db.collection("tickets").where("openedBy", "==", uid);
      if (assignedTo !== undefined) {
        ref = ref.where("assignedTo", "==", assignedTo);
      }
      if (status !== undefined) {
        if (
          status !== "notAssigned" &&
          status !== "assigned" &&
          status !== "processing" &&
          status !== "closed"
        ) {
          throw "invalid status.";
        }
        ref = ref.where("status", "==", status);
      }
      if (openedFrom !== undefined) {
        ref = ref.where("openedAt", ">=", openedFrom);
      }
      if (openedTo !== undefined) {
        ref = ref.where("openedAt", "<=", openedTo);
      }

      snapshots.push(ref.get());
    }
    snapshots = await Promise.all(snapshots);

    let tickets = [];
    let regex = /tickets\%2F.*\%2F(.*)\?/;
    for (let snapshot of snapshots) {
      snapshot.forEach((doc) => {
        let doc_data = doc.data();
        doc_data["openedAt"] = doc_data["openedAt"].toDate();
        let filenames = [];
        for (let url of doc_data["urls"]) {
          filenames.push(decodeURI(regex.exec(url)[1]));
        }
        data["filenames"] = filenames;
        data["openedAt"] = data["openedAt"].toDate();
        tickets.push({
          id: doc.id,
          data: data,
        });
      });
    }

    return tickets;
  } catch (error) {
    console.error(error);
    return [];
  }
}

//getTickets(openedBy, assignedTo, status, openedFrom, openedTo, sector): retorna os chamados de acordo com os parâmetros de busca
//Todos os parâmtetros são opcionais, caso algum não seja usado basta passar undefined como argumento.
//Os parâmetros undefined não serão levados em conta na busca. Por exemplo, se todos forem undefined a busca retorna todos os chamados
//
//openedBy: id do usuário que abriu o chamado
//assignedTo: id do técnico responsável por atender o chamado
//status: Uma String com o status do chamado. Há 4 possibilidades:
//        "notAssigned": O chamado foi criado, mas não foi atribuido a nenhum técnico,
//        "assigned": O chamado foi atribuído a um técnico, mas ele não começou a trabalhar no chamado,
//        "processing": O técnico está trabalhando do chamado,
//        "closed": O chamado foi encerrado.
//openedFrom: Objeto Date(). Serão retornados chamados abertos a partir dessa data-hora.
//openedTo: Objeto Date(). Serão retornados chamados abertos até essa data-hora.
//sector: String com id do setor
async function getTickets(
  openedBy,
  assignedTo,
  status,
  openedFrom,
  openedTo,
  sector
) {
  try {
    let ref = db.collection("tickets");

    if (openedBy !== undefined) {
      ref = ref.where("openedBy", "==", openedBy);
    }
    if (assignedTo !== undefined) {
      ref = ref.where("assignedTo", "==", assignedTo);
    }
    if (status !== undefined) {
      if (
        status !== "notAssigned" &&
        status !== "assigned" &&
        status !== "processing" &&
        status !== "closed"
      ) {
        throw "invalid status.";
      }
      ref = ref.where("status", "==", status);
    }
    if (openedFrom !== undefined) {
      ref = ref.where("openedAt", ">=", openedFrom);
    }
    if (openedTo !== undefined) {
      ref = ref.where("openedAt", "<=", openedTo);
    }
    if (sector !== undefined) {
      ref = ref.where("sector", "==", sector);
    }

    let tickets = [];
    let regex = /tickets\%2F.*\%2F(.*)\?/;
    let querySnapshot = await ref.get();
    querySnapshot.forEach((doc) => {
      let doc_data = doc.data();
      doc_data["openedAt"] = doc_data["openedAt"].toDate();
      let filenames = [];
      for (let url of doc_data["urls"]) {
        filenames.push(decodeURI(regex.exec(url)[1]));
      }
      data["filenames"] = filenames;
      data["openedAt"] = data["openedAt"].toDate();
      tickets.push({
        id: doc.id,
        data: doc_data,
      });
    });
    return tickets;
  } catch (error) {
    console.error(error);
    return [];
  }
}

//getTicketById(id): retorna um chamado pelo seu id
//id: String com o id do chamado
async function getTicketById(id) {
  try {
    let doc = await db
      .collection("tickets")
      .doc(id)
      .get();
    let doc_data = doc.data();
    doc_data["openedAt"] = doc_data["openedAt"].toDate();
    let regex = /tickets\%2F.*\%2F(.*)\?/;
    let filenames = [];
    for (let url of doc_data["urls"]) {
      filenames.push(decodeURI(regex.exec(url)[1]));
    }
    data["filenames"] = filenames;
    data["openedAt"] = data["openedAt"].toDate();
    return data;
  } catch (error) {
    console.error(error);
    return 1;
  }
}

//updateTicketStatus(id, newStatus, assignedTo): Atualiza o status de um chamado
//id: String com o id do status
//newStatus: String com o novo status
//assignedTo (opcional): id do técnico (obrigatório se newStatus for "assigned" ou "processing")
async function updateTicketStatus(id, newStatus, assignedTo) {
  let ref = db.collection("tickets").doc(id);

  try {
    if (
      newStatus !== "notAssigned" &&
      newStatus !== "assigned" &&
      newStatus !== "processing" &&
      newStatus !== "closed"
    ) {
      throw "invalid status.";
    }
    if (
      (newStatus === "assigned" || newStatus === "processing") &&
      assignedTo === undefined
    ) {
      throw "assignedTo is undefined and newStatus is assined";
    }
    //todo: validar assignedTo com lsita de técnicos
    await ref.update({
      status: newStatus,
      statusUpdatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
      assignedTo: assignedTo,
    });

    return 0;
  } catch (error) {
    console.error(error);
    return 1;
  }
}

//uploadFile(file, filename, allowedUsers, overwrite, progressUpdateFunction) envia arquivo para o firebase
//file: Pode ser um objeto File(), Blob() ou Uint8Array() que contém o arquivo a ser enviado
//
//filename: Uma String para o nome do arquivo (com extensão)
//
//allowedUsers: Uma lista de Strings com os UIDs dos usuários que vão ter acesso ao arquivo
//
//overwrite: Um booleano. Caso o arquivo já exista, ele vai ser sobrescrito se overwrite=true, mas se overwrite=false da um erro.
//
//progressUpdateFunction: Uma função para acompanhar o progesso do upload. Essa função precisa aceitar um Number como parâmetro
//Ela vai ser chamada periodicamente com um valor de 0 a 100 sendo passado como parâmetro
//Por exemplo, a seguitne função printa o progresso no console:
//(progress) => {
//}
async function uploadFile(
  file,
  filename,
  allowedUsers,
  overwrite = false,
  progressUpdateFunction = () => { }
) {
  try {
    let ref = storage.ref();
    ref = ref
      .child("user_files")
      .child(auth.currentUser.uid)
      .child(filename);

    let fileListJSON = await listUploadedFiles();
    let fileList = [];
    fileListJSON.forEach((json) => {
      fileList.push(json["filename"]);
    });

    if (fileList.includes(filename) && overwrite) {
      await deleteFile(filename);
    }

    if (!fileList.includes(filename) || overwrite) {
      let uploadTask = ref.put(file);
      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          function (snapshot) {
            let progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            progressUpdateFunction(progress);
          },
          (error) => {
            console.error(error);
            reject(1);
          },
          () => {
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then(async (downloadURL) => {

                let batch = db.batch();
                let ref = db
                  .collection("users")
                  .doc(auth.currentUser.uid)
                  .collection("files")
                  .doc(filename);
                batch.set(ref, {
                  filename: filename,
                  allowedUsers: allowedUsers,
                  url: downloadURL,
                  uploadedAt: firebase.firestore.Timestamp.fromDate(new Date()),
                });

                for (let user of allowedUsers) {
                  let uref = db
                    .collection("users")
                    .doc(user)
                    .collection("grantedFiles")
                    .doc(auth.currentUser.uid + "-" + filename);
                  batch.set(uref, {
                    owner: auth.currentUser.uid,
                    url: downloadURL,
                    filename: filename,
                    grantedAt: firebase.firestore.Timestamp.fromDate(
                      new Date()
                    ),
                  });
                }
                await batch.commit();
                resolve(0);
              });
          }
        );
      });
    } else {
      throw "file exists, but overwrite != true";
    }
  } catch (error) {
    console.error(error);
    return 1;
  }
}

//downloadFileByURL(url, filename): baixa um arquivo e salva no disco local
//url: String com url do arquivo
//filename: String com nome do arquivo
async function downloadFileByURL(url, filename) {
  try {
    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.open("GET", url);
    xhr.send();

    xhr.onload = function (event) {
      var blob = xhr.response;
      var a = document.createElement("a");
      a.style = "display: none";
      document.body.appendChild(a);
      var blob_url = window.URL.createObjectURL(blob);
      a.href = blob_url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(blob_url);
    };
  } catch (error) {
    console.error(error);
  }
}

//downloadFile(path): baixa um arquivo e salva no disco local
//path: caminho do arquivo no Firebase Storage (dica: use a função makePath)
async function downloadFile(path) {
  let segments = path.split("/");
  let name = segments[segments.length - 1];

  storage
    .ref()
    .child(path)
    .getDownloadURL()
    .then(function (url) {
      var xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.open("GET", url);
      xhr.send();

      xhr.onload = function (event) {
        var blob = xhr.response;
        var a = document.createElement("a");
        a.style = "display: none";
        document.body.appendChild(a);
        var blob_url = window.URL.createObjectURL(blob);
        a.href = blob_url;
        a.download = name;
        a.click();
        window.URL.revokeObjectURL(blob_url);
      };

      return 0;
    })
    .catch(function (error) {
      console.error(error);
      return 1;
    });
}

//getFileByURL(url): baixa um arquivo e retorna um Blob()
//url: url do arquivo
async function getFileByURL(url) {
  try {
    return await makeRequest(url);
  } catch (error) {
    console.error(error);
  }
}

//getFile(path): baixa um arquivo e retorna um Blob()
//path: caminho do arquivo no Firebase Storage (dica: use a função makePath)
async function getFile(path) {
  try {
    let url = await storage
      .ref()
      .child(path)
      .getDownloadURL();
    return await makeRequest(url);
  } catch (error) {
    console.error(error);
    return 1;
  }
}

//getFileURL(path): retorna a URL de um arquivo
//path: caminho do arquivo no Firebase Storage (dica: use a função makePath)
async function getFileURL(path) {
  try {
    return await storage
      .ref()
      .child(path)
      .getDownloadURL();
  } catch (error) {
    console.error(error);
    return 1;
  }
}

//deleteFile(filename): Apaga um arquivo
//filename: O nome do arquivo
async function deleteFile(filename) {
  let path = "user_files/" + auth.currentUser.uid + "/" + filename;

  try {
    await storage
      .ref()
      .child(path)
      .delete();
  } catch (error) {
    console.error(error);
  }

  let permissionsRef = db
    .collection("users")
    .doc(auth.currentUser.uid)
    .collection("files")
    .doc(filename);
  return db
    .runTransaction(function (transaction) {
      return transaction.get(permissionsRef).then(function (doc) {
        if (!doc.exists) {
          throw "Document does not exist!";
        }

        let allowedUsers = doc.data()["allowedUsers"];
        for (let user of allowedUsers) {
          let uref = db
            .collection("users")
            .doc(user)
            .collection("grantedFiles")
            .doc(auth.currentUser.uid + "-" + filename);
          transaction.delete(uref);
        }
      });
    })
    .then(async () => {
      try {
        await permissionsRef.delete();
      } catch (error) {
        console.error(error);
        return 1;
      }
    })
    .catch((error) => {
      console.error("Transaction failed: ", error);
      return 1;
    });
}

//listUploadedFiles(): List uploaded files
//returns a list of JSON in the following format:
//{
//  filename: <String with file name>
//  allowedUsers: <List of Users UIDs that can access this file>,
//  uploadedAt: <firebase.firestore.Timestamp Object>,
//  url: <String with file URL>
//}
function listUploadedFiles() {
  return new Promise((resolve, reject) => {
    db.collection("users")
      .doc(auth.currentUser.uid)
      .collection("files")
      .get()
      .then((querySnapshot) => {
        let list = [];
        querySnapshot.forEach((doc) => {
          list.push(doc.data());
        });
        resolve(list);
      })
      .catch((error) => {
        console.error("Transaction failed: ", error);
        reject([]);
      });
  });
}

//listSharedFiles(): List files that the user has access to but has not uploaded
//returns a list of JSON in the following format:
//{
//  filename: <A String with the file name>,
//  grantedAt: <firebase.firestore.Timestamp Object with datetime information of when the file was shared>,
//  url: <String with file URL>,
//  owner: <A String with >
//}
function listSharedFiles() {
  return new Promise((resolve, reject) => {
    db.collection("users")
      .doc(auth.currentUser.uid)
      .collection("grantedFiles")
      .get()
      .then((querySnapshot) => {
        let list = [];
        querySnapshot.forEach((doc) => {
          list.push(doc.data());
        });
        resolve(list);
      })
      .catch((error) => {
        console.error("Transaction failed: ", error);
        reject([]);
      });
  });
}

//revokeUserAccesstoFile(filename, user): Revoke user access to file
//filename: String with the name of the file
//user: String with UID of user that file access shall be revoked
function revokeUserAccesstoFile(filename, user) {
  let ref = db
    .collection("users")
    .doc(user)
    .collection("grantedFiles")
    .doc(auth.currentUser.uid + "-" + filename);
  let permissionsRef = db
    .collection("users")
    .doc(auth.currentUser.uid)
    .collection("files")
    .doc(filename);
  return db
    .runTransaction(function (transaction) {
      return transaction.get(permissionsRef).then(function (doc) {
        if (!doc.exists) {
          throw "Document does not exist!";
        }

        let allowedUsers = doc.data()["allowedUsers"];
        if (allowedUsers.includes(user)) {
          transaction.update(permissionsRef, {
            allowedUsers: firebase.firestore.FieldValue.arrayRemove(user),
          });
          transaction.delete(ref);
        }
      });
    })
    .then(() => {
      return 0;
    })
    .catch((error) => {
      console.error("Transaction failed: ", error);
      return 1;
    });
}

//grantUserAccesstoFile(filename, user): Grants user access to file
//filename: String with the name of the file
//user: String with UID of user that file access shall be granted
function grantUserAccesstoFile(filename, user) {
  let ref = db
    .collection("users")
    .doc(user)
    .collection("grantedFiles")
    .doc(auth.currentUser.uid + "-" + filename);
  let permissionsRef = db
    .collection("users")
    .doc(auth.currentUser.uid)
    .collection("files")
    .doc(filename);
  return db
    .runTransaction(function (transaction) {
      return transaction.get(permissionsRef).then(function (doc) {
        if (!doc.exists) {
          throw "Document does not exist!";
        }

        transaction.update(permissionsRef, {
          allowedUsers: firebase.firestore.FieldValue.arrayUnion(user),
        });
        transaction.set(ref, {
          owner: auth.currentUser.uid,
          url: doc.data()["url"],
          filename: filename,
          grantedAt: firebase.firestore.Timestamp.fromDate(new Date()),
        });
      });
    })
    .then(() => {
      return 0;
    })
    .catch((error) => {
      console.error("Transaction failed: ", error);
      return 1;
    });
}

//initialize cloud messaging
/*async function initFSM() {
  messaging.getToken({ vapidKey: "BG3ACtHOy4CKf5QM5QXlHCMy81GnGJmHSf-KgKEywA9Yo6GCJ1YMMFNffc89298uVNPe5O3F__XnP3nnCEnrEg8" }).then(async (currentToken) => {
    if (currentToken) {
      await sendTokenToServer(currentToken);
      //updateUIForPushEnabled(currentToken);
    } else {
      // Show permission request.
      // Show permission UI.
      //updateUIForPushPermissionRequired();
      //setTokenSentToServer(false);
    }
  }).catch((err) => {
    console.error('An error occurred while retrieving token. ', err);
    return 1;
    //showToken('Error retrieving registration token. ', err);
    //setTokenSentToServer(false);
  });
}*/

/*async function sendTokenToServer(currentToken) {
  if (auth.currentUser.uid !== null) {
    try {
      db.collection("users").doc(auth.currentUser.uid).update({
        "FCM_TOKENS": firebase.firestore.FieldValue.arrayUnion(currentToken)
      });
    } catch (error) {
      console.error(error);
      return 1;
    }
  }
  else {
    console.error("not logged in");
    return 1;
  }
}*/

//setNotificationCallback(func) define a função que é executada ao receber uma notificação
//Provavelmente deve conter o seguinte código
/*messaging.onMessage((payload) => {
  ...
});*/
/*function setNotificationCallback(func) {
  messaging.onMessage(func);
}*/

//getReadings(sector, from, to) retorna uma lista ordenada de JSON contendo medições. Em caso de falha retorna undefined
//sector: String com o identificador do setor.
//
//start (opcional): Um objeto Date.
//Se especificado, retorna as medições feitas a partir do datetime em start, caso contrário, retorna todas as medições.
//
//end (opcional): Um objeto Date.
//Se especificado, retorna as medições feitas até o datetime em end, caso contrário, retorna todas as medições.
//isFoz (opcional): booleano
//Se especificado, retorna medições feitas com a foz (true) ou sem a foz (false)
async function getReadings(sector, start, end, isFoz) {
  let ref = db
    .collection("sectors")
    .doc(sector)
    .collection("readings");
  let data = [];


  if (start !== undefined) {
    ref = ref.where("datetime", ">=", start);
  }
  if (end !== undefined) {
    ref = ref.where("datetime", "<=", end);
  }

  try {
    let querySnapshot = await ref.get();
    if (querySnapshot.empty) {
      return [];
    } else {
      querySnapshot.forEach((doc) => {
        if (isFoz !== undefined) {
          if (
            (isFoz && doc.data()["isFoz"]) ||
            (!isFoz && !doc.data()["isFoz"])
          ) {
            let doc_data = doc.data();
            doc_data["datetime"] = doc_data["datetime"].toDate();
            data.push(doc_data);
          }
        } else {
          let doc_data = doc.data();
          doc_data["datetime"] = doc_data["datetime"].toDate();
          data.push(doc_data);
        }
      });
      data.sort((a, b) => {
        return compareDates(a["datetime"], b["datetime"]);
      });
      return data;
    }
  } catch (error) {
    console.error("Error getting documents:", error);
    return [];
  }
}

async function readingsByModem(modemId) {

  try {
    var readings = [];
    let doc = await db.collection("readings").where("origin", "==", modemId).orderBy("datetime", "desc").limit(1000).get();

    doc.forEach((reading) => {
      readings.push(reading.data());

    });
    return readings;
  } catch (error) {
    console.error(error);
    return;
  }


  //const asMilPrimeiras = await db.collection("readings").orderBy("datetime", "desc").limit(10).get();

  //return asMilPrimeiras;
}

//getUserDevices(user): retorna uma lista com os ids dos dipositivos ligado à ele
//user: String com o identificador do usuário.
//exemplo: "dummy"
/*async function getUserDevices(user) {
  let ref = db.collection("users").doc(user).collection("hidrometros");
  let devices = [];

  try {
    let querySnapshot = await ref.get();
    if (querySnapshot.empty) {
    }
    else {
      querySnapshot.forEach((doc) => {
        devices.push(doc.id);
      });
      return devices;
    }
  }
  catch (error) {
    return 1;
  };
}*/

//addReadings(sectors, list_json): adiciona leituras a um setor pertencente a um user. Retorna 0 se sucesso, 1 caso erro
//sector: String com o identificador do setor.
//
//list_json: uma lista com objetos JSON compatível com um Reading (dica: use a função makeReadingJSON para gerar um JSON)
//TODO: Essa função realiza 2 operações, caso haja falha na segunda, as médias mensais vão ficar inconsistentes.
//      Se o problema for de conexão, setar um cookie para que da próxima vez que o usuário logar, chamar um função no cloud function
//      que recalcula as médias.
//      Se o problema não for de conexão, basta chamar essa função depois de return 1;
async function addReadings(sector, list_json) {
  //adiciona dados a Readings
  let ref = db
    .collection("sectors")
    .doc(sector)
    .collection("readings");
  let batch = db.batch();

  list_json.forEach((json) => {
    let docRef = ref;
    docRef = docRef.doc(json["datetime"].toMillis().toString());
    batch.set(docRef, json);
  });
  try {
    batch.commit();
  } catch (error) {
    console.error(error);
    return 1;
  }

  //recalcula as médias mensais
  ref = db.collection("sectors").doc(sector);

  return db
    .runTransaction((transaction) => {
      return transaction.get(ref).then((doc) => {
        let docData = doc.data();
        let isInit = true;
        if (docData === undefined) {
          docData = {};
          isInit = false;
        }
        let avgs = docData["monthsAvg"];
        avgs = avgs !== undefined ? avgs : {};

        list_json.forEach((json) => {
          let date = json["datetime"].toDate();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();
          let avgData = avgs[month + "-" + year];
          avgData = avgData !== undefined ? avgData : { avg: 0, n: 0 };

          avgData["avg"] +=
            (json["data"] - avgData["avg"]) / (avgData["n"] + 1);
          avgData["n"] += 1;

          avgs[month + "-" + year] = avgData;
        });

        if (isInit) {
          transaction.update(ref, { monthsAvg: avgs });
        } else {
          transaction.set(ref, { monthsAvg: avgs });
        }
        return 0;
      });
    })
    .then(() => {
      return 0;
    })
    .catch((error) => {
      console.error("Transaction failed: ", error);
      return 1;
    });
}

//retorna o uid do usuário atual. Se não houver usuário logado, retorna undefined;
function getCurrentUserUID() {
  if (auth.currentUser !== null) {
    return auth.currentUser.uid;
  }
}

//retorna os dados do usuário atual
async function getCurrentUser() {
  if (auth.currentUser !== null) {
    let doc = await db
      .collection("users")
      .doc(auth.currentUser.uid)
      .get();
    return { uid: auth.currentUser.uid, data: doc.data() };
  } else {
    throw Error('firebase.auth().currentUser is null');
  }
}

//makeReadingJSON(data, datetime, isFoz): retorna um JSON compatível com um Reading
//data: um número com o valor da medição do hidrômetro
//datetime: objeto de Data com a data da medição
//isFoz: um booleano indicando se o dado em questão é de uma medição com sistema Foz ou não.
function makeReadingJSON(data, datetime, isFoz) {
  return {
    data: data,
    datetime: firebase.firestore.Timestamp.fromDate(datetime),
    isFoz: isFoz,
  };
}

//helper functions
function compareDates(a, b) {
  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  } else {
    return 0;
  }
}

function makeRequest(url) {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response);
      } else {
        reject({
          status: xhr.status,
          statusText: xhr.statusText,
        });
      }
    };
    xhr.onerror = () => {
      reject({
        status: this.status,
        statusText: xhr.statusText,
      });
    };
    xhr.send();
  });
}

async function uploadTicketFile(
  file,
  filename,
  ticket_id,
  progressUpdateFunction
) {
  try {
    let ref = storage.ref();
    ref = ref
      .child("tickets")
      .child(ticket_id)
      .child(filename);

    let uploadTask = ref.put(file);
    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        function (snapshot) {
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          progressUpdateFunction(progress);
        },
        (error) => {
          console.error(error);
          reject(1);
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  } catch (error) {
    console.error(error);
    return 1;
  }
}

// Water Authorities
async function getWaterAuthority(authority) {
  try {
    var tariffs = [];
    let docs = await db.collection("tarifas_compesa").get();
    docs.forEach((doc) => {
      tariffs.push({ validity: doc.id, tariffs: doc.data() });
    });
    return tariffs;
  } catch (error) {
    console.error(error);
    return;
  }
}


async function updateTariff(authority, tariff) {
  try {
    await db
      .collection("tarifas_compesa")
      .doc(tariff.validity)
      .update(tariff.tariffs);
    return 0;
  } catch (error) {
    console.error(error);
    return 1;
  }
}

async function createTariff(authority, tariff) {
  try {
    if (tariff["validity"] === undefined) {
      throw "field 'validity' is missing";
    }
    await db
      .collection("tarifas_compesa")
      .doc(tariff["validity"])
      .set(tariff.tariffs);
    return 0;
  } catch (error) {
    console.error(error);
    return 1;
  }
}

async function deleteTariff(id) {
  try {
    await db
      .collection("tarifas_compesa")
      .doc(id)
      .delete();
    return 0;
  } catch (error) {
    console.error(error);
    return 1;
  }
}

// Hydrometers
async function createHydrometer(hydrometer) {
  return new Promise(
    async (resolve, reject) => {
      try {
        // if (!hydrometer['id']) {
        //   return reject("Está faltando o id do hidrômetro");
        // }
        const doc_ref = await db.collection("hydrometers").add(hydrometer);
        hydrometer.id = doc_ref.id;
        await db
          .collection("hydrometers")
          .doc(hydrometer["id"])
          .update({ id: hydrometer.id });
        return resolve(hydrometer);
      } catch (e) {
        reject(e);
      }
    }
  );

  // try {
  //   if (hydrometer["name"] === undefined) {
  //     throw "field 'name' is missing";
  //   }
  //   await db
  //     .collection("hydrometers")
  //     .doc(hydrometer["name"])
  //     .set(hydrometer);
  //   return 0;
  // } catch (error) {
  //   console.error(error);
  //   return 1;
  // }
}

async function getHydrometer(hydrometer) {
  return new Promise(
    async (resolve, reject) => {
      try {
        let response = await db
          .collection("hydrometers")
          .doc(hydrometer.id)
          .get();
        resolve(response.data());
      } catch (e) {
        reject(e);
      }
    }
  );
  // try {
  //   let doc = await db
  //     .collection("hydrometers")
  //     .doc(name)
  //     .get();
  //   return doc.data();
  // } catch (error) {
  //   console.error(error);
  //   return;
  // }
}

async function getHydrometers() {
  try {
    var hydrometers = [];
    let doc = await db.collection("hydrometers").get();
    doc.forEach((hydrometer) => {
      hydrometers.push(hydrometer.data());
    });
    return hydrometers;
  } catch (error) {
    console.error(error);
    return;
  }
}

async function updateHydrometer(hydrometer) {
  return new Promise(
    async (resolve, reject) => {
      try {
        let response = await db
          .collection("hydrometers")
          .doc(hydrometer.id)
          .update(hydrometer);
        resolve(response);
      } catch (e) {
        reject(e);
      }
    }
  );

  // try {
  //   await db
  //     .collection("hydrometers")
  //     .doc(hydrometer.name)
  //     .update(hydrometer);
  //   return 0;
  // } catch (error) {
  //   console.error(error);
  //   return 1;
  // }
}

async function deleteHydrometer(hydrometer) {
  return new Promise(
    async (resolve, reject) => {
      try {
        let response = await db
          .collection("hydrometers")
          .doc(hydrometer.id)
          .delete();
        resolve(response);
      } catch (e) {
        reject(e);
      }
    }
  );
  // try {
  //   await db
  //     .collection("hydrometers")
  //     .doc(name)
  //     .delete();
  //   return 0;
  // } catch (error) {
  //   console.error(error);
  //   return 1;
  // }
}
//Water Scan ------------------
async function deleteProposal(registration) {
  try {
    let ref = await db
      .collection("water_scan")
      .where("registration", "==", registration)
      .get();

    let id = ''
    ref.forEach((doc) => {
      id = doc['id']
    });
    await db
      .collection("water_scan")
      .doc(id)
      .delete();
    return 0;
  } catch (error) {
    console.error(error);
    return 1;
  }
}
async function createProposal(Proposal) {
  try {
    const doc_ref = await db.collection("water_scan").add(Proposal);
    Proposal.id = doc_ref.id;
    await db
      .collection("water_scan")
      .doc(Proposal["id"])
      .update({ id: Proposal.id });
    return Proposal;
  } catch (e) {
  }
}
async function updateProposal(data, registration) {
  try {
    let ref = await db
      .collection("water_scan")
      .where("registration", "==", registration)
      .get();

    let id = ''
    ref.forEach((doc) => {
      id = doc['id']
    });

    await db
      .collection("water_scan")
      .doc(id)
      .update(data);
    return (true);
  } catch (error) {
    console.error(error);
  }
}
async function checkRegistration(registration) {
  try {
    const querySnapshot = await db.collection("water_scan")
      .where("registration", "==", registration)
      .get();
    return (!querySnapshot.empty);
  } catch (error) {
    return (error)
  }
}

async function getProposalWaterFix(registration = []) {
  if (registration.length > 8) {
    try {
      let response = []
      let querySnapshot = await db.collection("water_fix")
        .where("registration", "==", registration)
        .get();

      querySnapshot.forEach((doc) => {
        response = doc.data();
      });
      return (response);
    } catch (error) {
      return (error)
    }
  } else {
    try {
      let response = []
      let querySnapshot = await db.collection("water_fix").get();
      querySnapshot.forEach((doc) => {
        response.push(doc.data());
      });
      return (response);
    } catch (error) {
      return (error)
    }
  }
}

async function getProposal(registration = []) {
  if (registration.length > 8) {
    try {
      let response = []
      let querySnapshot = await db.collection("water_scan")
        .where("registration", "==", registration)
        .get();

      querySnapshot.forEach((doc) => {
        response = doc.data();
      });
      return (response);
    } catch (error) {
      return (error)
    }
  } else {
    try {
      let response = []
      let querySnapshot = await db.collection("water_scan").get();
      querySnapshot.forEach((doc) => {
        response.push(doc.data());
      });
      return (response);
    } catch (error) {
      return (error)
    }
  }
}
// Hydrometers
async function createCalls(called) {
  return new Promise(
    async (resolve, reject) => {
      try {
        const doc_ref = await db.collection("calls").add(called);
        called.id = doc_ref.id;
        await db
          .collection("calls")
          .doc(called["id"])
          .update({ idDoc: called.id });
        return resolve(called);
      } catch (e) {
        reject(e);
      }
    }
  );
}
async function updateCalls(idDoc, called) {
  return new Promise(
    async (resolve, reject) => {
      try {
        await db
          .collection("calls")
          .doc(idDoc)
          .update(called);
        resolve(true);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    }
  );
}
async function addCommment(comment) {

  try {
    await db.collection("calls_comments").add(comment);

    let ref = db.collection("calls_comments").orderBy("time", "desc").where("id_post", "==", comment.id_post)
    let list = [];
    let querySnapshot = await ref.get();
    querySnapshot.forEach((doc) => {
      list.push(doc.data());
    });
    return list;
  } catch (error) {
    console.error(error);
  }

}
async function getComments(id_post) {

  try {
    let ref = db.collection("calls_comments").orderBy("time", "desc").where("id_post", "==", id_post)
    let list = [];
    let querySnapshot = await ref.get();

    return querySnapshot.size;
  } catch (error) {
    console.error(error);
  }

}
async function paginationComments(id_post, lastDoc) {
  let ref = db.collection("calls_comments").orderBy("time", "desc").where("id_post", "==", id_post).limit(10)
  if (lastDoc) {
    ref = ref.startAfter(lastDoc)
  }
  const refSnap = await ref.get()
  const lastDocSnapshot = refSnap.docs[refSnap.docs.length - 1]
  const list = []
  refSnap.forEach((doc) => {
    list.push(doc.data());
  });
  return { result: list.length, list, lastDocSnapshot }

}
async function deleteCalls(idCall) {
  try {
    await db
      .collection("calls")
      .doc(idCall)
      .delete();
    const batch = db.batch();

    let ref = db.collection("calls_comments").where("id_post", "==", idCall)
    const refSnap = await ref.get()

    refSnap.forEach((doc) => {
      batch.delete(doc.ref)
    });
    await batch.commit()

    return 0;
  } catch (error) {
    console.error(error);
    return 1;
  }
}
async function paginationCalls(lastDoc, where = null) {
  let callsRef = ''
  if (where) {
    callsRef = db.collection('calls').where(where.nameParam, "==", where.param).orderBy("openTime", "desc").limit(10)
  } else {
    callsRef = db.collection('calls').orderBy("openTime", "desc").limit(10)
  }
  if (lastDoc) {
    callsRef = callsRef.startAfter(lastDoc)
  }
  const callsSnap = await callsRef.get()
  const lastDocSnapshot = callsSnap.docs[callsSnap.docs.length - 1]
  const calls = []
  callsSnap.forEach((doc) => {
    calls.push(doc.data());
  });
  return { result: calls.length, calls, lastDocSnapshot }
  //return calls.length
}
async function getCallsWhere(call) {

  try {
    let ref = null
    let calls = []
    let querySnapshot = []

    ref = db.collection("calls").where("connections", "==", { unit_id: call });
    querySnapshot = await ref.get();
    querySnapshot.forEach((doc) => {
      calls.push(doc.data());
    });
    return calls
  } catch (error) {
    console.error(error);
  }

}
async function getCalls(call) {
  return new Promise(
    async (resolve, reject) => {
      try {
        let ref = null
        let calls = []
        let querySnapshot = []
        if (call !== undefined) {
          calls = await db.collection("calls").doc(call).get();
        } else {
          ref = db.collection("calls").orderBy("openTime", "desc");
          querySnapshot = await ref.get();
          querySnapshot.forEach((doc) => {
            calls.push(doc.data());
          });
        }
        resolve(calls);
      } catch (error) {
        reject(error)
      }
    }
  );
}
async function callsSize() {
  return new Promise(
    async (resolve, reject) => {
      try {
        let ref = db.collection("calls").orderBy("identifier_OS", "desc");;
        let querySnapshot = await ref.get();
        let calls = []
        querySnapshot.forEach((doc) => {
          calls.push(doc.data());
        });
        resolve(calls);
      } catch (error) {
        reject(error)
      }
    }
  );
}
async function getCallsBy(where, dataTime = null) {
  return new Promise(
    async (resolve, reject) => {
      try {
        let calls = []
        let ref = db.collection("calls");
        if (dataTime) {

          if (dataTime.length > 1 && dataTime[0] > dataTime[1]) {
            ref = ref.where('openTime', '>=', dataTime[1] + 10800000);
            ref = ref.where('openTime', '<=', dataTime[0] + 86400000 + 10800000);
          } else if (dataTime.length > 1 && dataTime[0] < dataTime[1]) {
            ref = ref.where('openTime', '<=', dataTime[1] + 86400000 + 10800000);
            ref = ref.where('openTime', '>=', dataTime[0] + 10800000);
          } else if (dataTime.length > 1 && dataTime[0] === dataTime[1]) {
            ref = ref.where('openTime', '<=', (dataTime[1] + 86400000 + 10800000));
            ref = ref.where('openTime', '>=', (dataTime[0] + 10800000));
          } else {
            ref = ref.where('openTime', '>', dataTime[0]);
          }
        }
        for (const property in where) {
          ref = ref.where(property, '==', where[property]);
        }
        let querySnapshot = await ref.get();
        querySnapshot.forEach((doc) => {
          calls.push(doc.data());
        });
        resolve(calls);
      } catch (error) {
        reject(error)
      }
    }
  );
}
async function getAlertsBy(array_where) {
  return new Promise(
    async (resolve, reject) => {
      try {
        let alerts = []
        let ref = db.collection("modens");
        array_where.forEach(
          (where) => {
            ref = ref.where(where[0], where[1], where[2]);
          }
        );
        let querySnapshot = await ref.get();
        querySnapshot.forEach((doc) => {
          let modem = doc.data();
          modem = modem.hasOwnProperty('data') ? modem.data : modem;
          alerts.push(modem['alerts']);
        });
        resolve(alerts);
      } catch (error) {
        reject(error)
      }
    }
  );
}

async function updateAlerts(data) {
  try {
    const batch = db.batch();
    for (let key in data) {
      let ref = db.collection("alerts_payload").doc(data[key])
      batch.update(ref, { view: true });
    }
    await batch.commit();
    return true;
  } catch (e) {
    console.error("updateAlerts - Erro", e)
    return false;
  }
}
async function getNewAlerts(id_modem) {
  try {
    let ref = db.collection("alerts").where('modem_id', '==', id_modem)
    let list = []
    let querySnapshot = await ref.get();
    querySnapshot.forEach((doc) => {
      let alerts = doc.data();
      list.push(alerts);
    });
    return list;
  } catch (e) {
    console.error("getNewAlerts - Erro", e)
    return false;
  }
}
async function getAlertsPayload(modem_id = null, date = null) {
  try {
    let list = []
    if (modem_id) {
      let ref = []
      if (date) {
        ref = db.collection("alerts_payload")
          .where('modem_id', '==', modem_id)
          .where('date_of_occurrence', '>=', date[0])
          .where('date_of_occurrence', '<=', date[1])
      } else {
        ref = db.collection("alerts_payload")
          .where('modem_id', '==', modem_id)
      }
      let querySnapshot = await ref.get();
      querySnapshot.forEach((doc) => {
        let alerts = doc.data();
        list.push(alerts);
      });
    } else {
      let ref = db.collection("alerts_payload").where('view', '==', false)
      let querySnapshot = await ref.get();
      querySnapshot.forEach((doc) => {
        let alerts = doc.data();
        list.push(alerts);
      });
    }
    return list;
  } catch (e) {
    console.error("getAlertsPayload - Erro", e)
    return false;
  }
}
function isIterable(obj) {
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === "function";
}

function rankToString(json) {
  switch (json["rank"]) {
    case 0:
      json["rank"] = "Super Admin";
      break;
    case 1:
      json["rank"] = "Admin";
      break;
    case 2:
      json["rank"] = "Franqueado";
      break;
    case 3:
      json["rank"] = "Técnico";
      break;
    case 4:
      json["rank"] = "Cliente";
      break;
    case 5:
      json["rank"] = "Associado";
      break
    default:
      json["rank"] = "N/D";
      break;
  }
  return json;
}

function stringToRank(rank) {
  switch (rank) {
    case "Super Admin":
      rank = 0;
      break;
    case "Admin":
      rank = 1;
      break;
    case "Franqueado":
      rank = 2;
      break;
    case "Técnico":
      rank = 3;
      break;
    case "Cliente":
      rank = 4;
      break;
    case "Associado":
      rank = 5;
      break;
    default:
      rank = 4;
      break;
  }
  return rank;
}

function monthDiff(dateFrom, dateTo) {
  return (
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear())
  );
}

function hourDiff(dateFrom, dateTo) {
  return Math.floor((dateTo.getTime() - dateFrom.getTime()) / 3600000);
}

function dayDiff(dateFrom, dateTo) {
  return Math.floor((dateTo.getTime() - dateFrom.getTime()) / 86400000);
}

function createModem(data) {
  const func = firebase.functions().httpsCallable("createModem_NLT_API");
  return func(data).then(() => addModem(data));
}

// --------------------------------------------------------------------------------
// Gerenciamento de consumo

async function getDailyConsumption(unitId) {
  return new Promise(
    async (resolve, reject) => {
      try {
        let ref = db.collection("daily_consumptions").where("unitId", "==", unitId).where("deletedAt", "==", null);
        let list = [];
        let querySnapshot = await ref.get();
        querySnapshot.forEach((doc) => {
          list.push({
            id: doc.id,
            ...doc.data()
          });
        });
        resolve(list);
      } catch (error) {
        reject(error)
      }
    }
  );
}

// --------------------------------------------------------------------------------

async function addWaterAuthority(water) {
  try {
    if (water["id"] === undefined) {
      throw "field 'id' is missing";
    }
    let companySlug = Utils.createSlug(water['company']);
    water['company'] = companySlug;
    water["id"] = water["id"] ?? `${companySlug}_${water['date']}`;
    let ref = db
      .collection("water_authorities")
      .doc(water["id"])
    await ref.set(water)
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

async function deleteWaterAuthority(id) {
  try {
    await db
      .collection("water_authorities")
      .doc(id)
      .delete();
    return 0;
  } catch (error) {
    console.error(error);
    return 1;
  }
}

async function getWaterAuthorities(company = null) {
  try {
    let slugCompany = Utils.createSlug(company)
    var waterAuthorities = [];
    let docs = []
    if (company !== null) {
      docs = await db
        .collection("water_authorities")
        .where('company', '==', slugCompany)
        .where('deletedAt', '==', null)
        .get();
    } else {
      docs = await db
        .collection("water_authorities")
        .where('deletedAt', '==', null)
        .get();
    }
    docs.forEach((water) => {
      waterAuthorities.push(water.data());
    });
    return waterAuthorities;
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function getWaterAuthorityByIDs(newIDsArray) {
  try {
    var waterAuthorities = [];
    let docs = await db
      .collection("water_authorities")
      .where('id', 'in', newIDsArray)
      .get();
    docs.forEach((water) => {
      waterAuthorities.push(water.data());
    });
    return waterAuthorities;
  } catch (error) {
    console.error(error);
    return [];
  }
}

var firestore = firebase.firestore;
// export utils/refs
export default {
  db,
  auth,
  auth_class,
  //messaging,
  storage,
  //initFSM,
  // storageWaterScan,
  updateAlert,
  getRejectedReadings,
  getReadingsFromModem,
  getReadings,
  historyChartData,
  monthlyChartData,
  savingsChartData,
  //getUserDevices,
  makeReadingJSON,
  addReadings,
  getCurrentUserUID,
  getCurrentUser,
  //setNotificationCallback,
  getClientByName,
  downloadFile,
  downloadFileByURL,
  getFile,
  getFileByURL,
  getFileURL,
  getUsers_sr,
  uploadFile,
  deleteFile,
  listUploadedFiles,
  listSharedFiles,
  revokeUserAccesstoFile,
  grantUserAccesstoFile,
  openTicket,
  getTickets,
  getTicketById,
  updateTicketStatus,
  registerUser,
  updateUserData,
  getUsers,
  deleteUser,
  getUserbyId,
  getUsersbyIdArray,
  getTicketsByManager,
  stringToRank,
  ticketsChartData,
  ticketsCardData,
  goalCardData,
  createCalls,
  getUserUnits,
  getUnitbyId,
  updateUnit,
  deleteSector,
  addModem,
  getModem,
  getModems,
  getAlerts,
  monthDiff,
  hourDiff,
  dayDiff,
  updateModem,
  deleteModem,
  getWaterAuthority,
  updateTariff,
  createTariff,
  deleteTariff,
  createHydrometer,
  getHydrometer,
  getHydrometers,
  updateHydrometer,
  deleteHydrometer,
  createModem,
  getUnits,
  getCalls,
  updateCalls,
  getClientById,
  getDailyConsumption,
  addWaterAuthority,
  deleteWaterAuthority,
  getWaterAuthorities,
  getWaterAuthorityByIDs,
  readingsByModem,
  getCallsBy,
  getNewAlerts,
  updateAlerts,
  getAlertsPayload,
  callsSize,
  getAlertsBy,
  paginationCalls,
  getCallsWhere,
  paginationComments,
  // water-scan Start 
  deleteProposal,
  createProposal,
  updateProposal,
  checkRegistration,
  getProposal,
  // water-scan End 
  getProposalWaterFix,
  deleteCalls,
  addCommment,
  getComments,
};
