// const HOST = "https://generate-pdf-ms.herokuapp.com/api/pdf/v1/"
// const HOST = "http://localhost:3000/api/pdf/v1/"
import Utils from "@/scripts/Utils";
var HOST = Utils.getEnv() == 'local' ? 'http://localhost:3000/api/pdf/v1/' : "https://generate-pdf-ms.herokuapp.com/api/pdf/v1/"

const Request = async (data,route,name) => {
    return new Promise(
        async (resolve, reject) => {
            fetch(HOST + route, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
                .then(response => {
                    response.json()
                    .then(res => {                        
                        resolve(downloadPdf(res.success,name))
                    })
                })
                .catch(err => {
                    resolve(false)
                })
        }
    )

}
const downloadPdf = (res,name) => {
    name = name.split(" ").join("_");
    if (res === true) {
        window.open(HOST + `download/pdf/${name}`);
        return true
    } else return false 
}

const consumptionPdf = (data) => {
    return  Request(data,'consumptionpdf',data.data_abstract_client.name)
}
const waterScanPdf = (data) => {
    return  Request(data,'waterscan',data.locationInformation.name)
}
const waterScanCheckListPdf = (data) => {
    return  Request(data,'waterscanpdfchecklist',data.locationInformation.name)
}
const reportCalled = (data) => {
    return  Request(data,'report/called',data.locationInformation.name)
}
const downloadMockupPdf = (PDFName) => {
    window.open(`${HOST}downloadMockupPDF/${PDFName}`)
}

export default { 
    consumptionPdf, 
    waterScanPdf, 
    waterScanCheckListPdf, 
    downloadMockupPdf,
}