import { db } from "@/firebase"
const project_closers_ref = db.collection("project_closers")

const createProjectClosers = async (data) => {
    try { 
        const get_data = await project_closers_ref.add({ ...data, deletedAt: null });
        await editProjectClosers(get_data.id, { ...data, id_doc: get_data.id })
        return get_data.id;
    } catch (error) {
        throw new Error("createProjectClosers Fail!" + error)
    }
}
const editProjectClosers = async (idDoc, data) => {
    try {
        await project_closers_ref.doc(idDoc).update(data);
        return true;
    } catch (error) {
        throw new Error("editProjectClosers Fail!" + error)
    }
}
async function getProjectCloser() {
    let project_partner_list = []
    try {
        const unitsRef = await project_closers_ref.where("deletedAt", "==", null).get()
        unitsRef.forEach((doc) => project_partner_list.push(doc.data()));
        return project_partner_list
    } catch (error) {
        console.error(error)
        throw new Error("getProjectCloser Fail!" + error)
    }
}
async function getProjectCloserByIdFranchisee(IdFranchise) {
    let project_partner_list = []
    try {
        const unitsRef = await project_closers_ref
            .where("id_franchisee", "==", IdFranchise)
            .where("deletedAt", "==", null)
            .get()
        unitsRef.forEach((doc) => project_partner_list.push(doc.data()));
        return project_partner_list
    } catch (error) {
        console.error(error)
        throw new Error("getProjectCloserByIdFranchisee Fail!" + error)
    }
}
async function getProjectCloserById(idProjectPartner) {
    try {
        const projectPartner = await project_closers_ref.doc(idProjectPartner).get()
        return projectPartner.data()
    } catch (error) {
        console.error(error)
        throw new Error("getProjectCloserById Fail!" + error)
    }
}
async function getProjectCloserByDocument(document) {
    let project_partner_list = []
    try {
        const unitsRef = await project_closers_ref
            .where("document", "==", document)
            .get()

        unitsRef.forEach((doc) => project_partner_list.push(doc.data()));
        return project_partner_list.length ? project_partner_list[0] : null
    } catch (error) {
        console.error(error)
        throw new Error("getProjectCloserByDocument Fail!" + error)
    }
}

export default {
    getProjectCloserByIdFranchisee,
    getProjectCloserByDocument,
    getProjectCloserById,
    createProjectClosers,
    editProjectClosers,
    getProjectCloser,
};