<template>
  <v-row>
     <v-overlay absolute style="opacity: 0.6" :value="isLoadingAdress">
        <v-progress-circular
            indeterminate
            color="primary"
            :size="50"
        ></v-progress-circular>
    </v-overlay>
    <v-col cols="12" md="4" lg="4" xl="4" sm="4">
      <v-text-field
        outlined
        label="CEP"
        v-model="addressObj.cep"
        @change="getCep(addressObj.cep)"
        @click="AdressFill()"
        v-mask="'XX.XXX-XXX'"
        :rules="rulesCEP"
      >
      </v-text-field>
    </v-col>
    <v-col cols="12" md="4" lg="4" xl="4" sm="4">
      <v-text-field
        outlined
        label="Logradouro"
        v-model="addressObj.logradouro"
        :rules="rulesLogradouro"
        counter="128"
        @change="someFieldHasChanged"
      >
      </v-text-field>
    </v-col>
    <v-col cols="12" md="4" lg="4" xl="4" sm="4">
      <v-text-field
        outlined
        label="Número"
        type="number"
        v-model="addressObj.numero"
        :rules="rulesHouseNumber"
        counter="16"
        @change="someFieldHasChanged"
      >
      </v-text-field>
    </v-col>
    <v-col cols="12" md="4" lg="4" xl="4" sm="4">
      <v-text-field
        outlined
        label="Complemento"
        v-model="addressObj.complemento"
        :rules="rulesAdditionalAddress"
        counter="64"
        @change="someFieldHasChanged"
      >
      </v-text-field>
    </v-col>
    <v-col cols="12" md="4" lg="4" xl="4" sm="4">
      <v-text-field
        outlined
        label="Bairro"
        v-model="addressObj.bairro"
        :value="addressObj.bairro"
        :rules="rulesDistrict"
        counter="64"
        @change="someFieldHasChanged"
      >
      </v-text-field>
    </v-col>
    <v-col cols="12" md="4" lg="4" xl="4" sm="4">
      <v-text-field
        outlined
        label="Cidade"
        v-model="addressObj.cidade"
        :rules="rulesCity"
        counter="64"
        @change="someFieldHasChanged"
      >
      </v-text-field>
    </v-col>
    <v-col cols="12" md="4" lg="4" xl="4" sm="4">
      <v-select outlined label="UF" :items="uf" v-model="addressObj.uf" @change="someFieldHasChanged">
      </v-select>
    </v-col>
  </v-row>
</template>
<script>
import Rules from "@/utils/Rules";

export default {
  name: "addressForm",
  props: ["adressInfo","requestData"],
  data: function () {
    return {
      isLoadingAdress: false,
      addressObj: {
        bairro: null,
        cep: null,
        cidade: null,
        complemento: null,
        logradouro: null,
        numero: null,
        uf: null,
      },

      uf: [
        "AC",
        "AL",
        "AP",
        "AM",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MT",
        "MS",
        "MG",
        "PA",
        "PB",
        "PR",
        "PE",
        "PI",
        "RJ",
        "RN",
        "RS",
        "RO",
        "RR",
        "SC",
        "SP",
        "SE",
        "TO",
      ],
      adressValid: false,
    };
  },
  methods: {
    setAddress(address) {
      this.addressObj.bairro = address.bairro;
      this.addressObj.cidade = address.cidade;
      this.addressObj.cep = address.cep;
      this.addressObj.complemento = address.complemento;
      this.addressObj.logradouro = address.logradouro;
      this.addressObj.uf = address.uf;
      this.addressObj.numero = address.numero;
    },
    AdressFill() {
      this.setAddress(this.adressInfo)
    },
    clearAdress() {
      this.addressObj.bairro = null;
      this.addressObj.cidade = null;
      this.addressObj.complemento = null;
      this.addressObj.logradouro = null;
      this.addressObj.numero = null;
      this.addressObj.uf = null;
    },
    someFieldHasChanged() {
      this.$emit("update:address", this.addressObj);
    },
    getCep() {
      let cep = this.addressObj.cep;
      cep = cep.replace(/\D/g, "");

      if (cep != "" && cep.length == 8) {
        this.isLoadingAdress = true
        this.clearAdress();
        let url = "https://viacep.com.br/ws/" + cep + "/json/";

        fetch(url)
          .then((response) => response.json())
          .then((responseJson) => {
            this.addressObj.bairro = responseJson.bairro;
            this.addressObj.cidade = responseJson.localidade;
            this.addressObj.complemento = responseJson.complemento;
            this.addressObj.logradouro = responseJson.logradouro;
            this.addressObj.uf = responseJson.uf;
            this.isLoadingAdress = false
            this.$emit("update:address", this.addressObj);
          })
          .catch((err) => {
            this.isLoadingAdress = false
            console.error("Erro", err)});
          
      } else {
        //cep sem cep, limpa formulário.
        this.clearAdress();
      }
    },
  },
  computed: {
    rulesCEP() {
      return Rules.cep();
    },
    rulesLogradouro() {
      return Rules.string(false, null, 128);
    },
    rulesCity() {
      return Rules.string(false, null, 64);
    },
    rulesDistrict() {
      return Rules.string(false, null, 64);
    },
    rulesAdditionalAddress() {
      return Rules.string(false, null, 64);
    },
    rulesHouseNumber() {
      return Rules.string(false, null, 16);
    },
  },
  watch: {
    adressInfo: {
      immediate: true,
      handler: function (newVal, oldVal) {
        this.setAddress(newVal)
      }
    }
  },
};
</script>