<template>
  <div style="display: flex">
    <v-autocomplete dense v-model="selected" :items="franchisee" :label="label" item-text="name" item-value="id" outlined
      hide-details :loading="isLoading || loading" :disabled="isLoading || loading">
    </v-autocomplete>
    <div v-if="addClearButton" class="text-center" style="
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2px;
        ">
      <v-btn small color="blue" dark style="height: 100%; margin-left: 5px" @click="clear_option">
        X
      </v-btn>
    </div>
  </div>
</template>

<script>
import UsersService from "@/services/server/UsersService";

export default {
  data: () => ({
    label: "Franqueados",
    franchisee: [],
    list: [],
    disabledRank: false,
    selected: null,
  }),

  props: {
    loading: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    addClearButton: {
      default: false,
      type: Boolean,
    },
  },

  computed: {},
  methods: {
    clear_option() {
      this.selected = null;
    },
  },
  watch: {
    selected(id) {
      if (id) {
        this.$emit("id", id);
      } else {
        this.$emit("id", null);
      }
    },
  },
  async created() {
    this.isLoading = true
    const response = await UsersService.findAllFranchisee()
    this.franchisee = response.data.data
    if (this.franchisee.length == 1) this.selected = this.franchisee[0].id;
    this.isLoading = false
  },
};
</script>


<style></style>