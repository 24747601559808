import Utils from '@/scripts/Utils';
import Rules from "@/utils/Rules";
import SolutionsServices from "@/services/firebase/SolutionsServices/SolutionsServices";
import SolutionsGroupServices from "@/services/firebase/SolutionsGroup/SolutionsGroup";
import SolutionsPointServices from "@/services/firebase/SolutionsPoint/SolutionsPoint";
import { showErrorSnackBar, showSuccessSnackBar,showInfoSnackBar } from "@/utils/SnackBartHelper";
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper";
import InputMoney from "@/components/Forms/Input/input-money.vue";
import * as XLSX from 'xlsx-js-style';

export default {
  name: "SolutionsServices",
  components: {
    InputMoney,
  },
  data: () => ({
    page: 1,
    newItem: {
      solutionCod: "",
      solution: "",
      price: "",
      cost: "",
      description: "",
      type: "",
      groupId: "",
      pointId: ""
    },
    dataSolutions: [],
    dataGroups: [],
    dataPoints: [],
    validForm: true,
    saveButtonLoading: false,
    isLoadingSolution: false,
    isLoadingSolutionForm: false,
    loadingSelectGroup: false,
    loadingSelectPoint: false,
    dialogSolution: false,
    titles_button_download: [{ title: "Com custo" }, { title: "Sem custo" }],
    menuOpen: false,
    columnsToChoose: [{ text: "Solução", value: "solution" },
    { text: "Grupo", value: "groupId" },
    { text: "Ponto", value: "pointId" },
    { text: "Tipo", value: "type" }],
    columnToSeach: "solution",
    headers: [
      { text: "Código", value: "solutionCod"},
      { text: "Solução", value: "solution"},
      { text: "Valor", value: "price"},
      { text: "Custo", value: "cost" },
      { text: "Tipo", value: "type"},
      { text: "Ponto", value: "pointId"},
      { text: "Grupo", value: "groupId" },

      // { text: "Descrição" },
      { text: "Ações", value: "actions", sortable: false },
    ],
    searchText: "",
    formTitle: "Adicionar Solução",
    deleteItem: null,
  }),
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    async exportSheets(type, title) {
      
      const select_datas = this.dataSolutions.map(({id, cost, groupId, description, group, solutionCod, solution, pointId, type, price,}) => {
        if (title === "Sem custo") {
          return {
            codigo: solutionCod, 
            solucao: solution, 
            valor: price, 
            tipo: type, 
            descricao: description, 
            grupo: group, 
            grupo: this.changeIDGroupPerName(groupId), 
            ponto: this.changeIDPointPerName(pointId)
          }
        }else{
          return {
            codigo: solutionCod, 
            solucao: solution,
            valor: price, 
            tipo: type, 
            descricao: description, 
            grupo: group, 
            grupo: this.changeIDGroupPerName(groupId), 
            ponto: this.changeIDPointPerName(pointId),
            custo: this.checkCusto(cost),
          }
        }
      })
      
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(select_datas)
      
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      
      const wscols = [
        { wch: 22 }
      ];
      worksheet['!cols'] = wscols;
      workbook.Sheets["Log"] = worksheet;
            
      XLSX.writeFile(workbook, `soluções_${title.toLowerCase()}.${type}`);
    },
    async listSolutions() {
      this.loadingSelectGroup = true
      this.dataSolutions = await SolutionsServices.GetSolutions()
      this.loadingSelectGroup = false
    },
    async listSolutionsGroups() {
      this.isLoadingSolution = true
      this.dataGroups = await SolutionsGroupServices.getSolutionsGroup(),
      this.isLoadingSolution = false
    },
    async listSolutionsPoints() {
      this.loadingSelectPoint = true
      this.dataPoints = await SolutionsPointServices.getSolutionsPoint(),
      this.loadingSelectPoint = false
    },
    closeDialog() {
      this.dialogSolution = false
    },
    openSolutionDialog(item = null) {
      this.dialogSolution = true
      this.newItem = {}
      if (item) {
        this.formTitle = "Editar Solução"
        this.newItem = item
      } else {
        this.formTitle = "Adicionar Solução"
      }
    },
    async saveSolution() {
      if (this.$refs.addSolutionForm && !this.$refs.addSolutionForm.validate()) return;
      this.isLoadingSolutionForm = true
      try {
        if (this.formTitle === "Editar Solução") {
          await this.editSolution()
        } else if (this.formTitle === "Adicionar Solução") {
          await this.createSolution()
        }
      } catch (err) {
        console.error(err)
        showErrorSnackBar("Ocorreu um erro interno!. Entre em contato com nossa equipe.")
      } finally {
        this.closeDialog();
        this.isLoadingSolutionForm = false
      }
    },
    async createSolution() {
      try {
        const newItemId = await SolutionsServices.AddSolutions({ ...this.newItem })
        this.dataSolutions.unshift({ ...this.newItem, id: newItemId })
        showSuccessSnackBar("Solução cadastrada com sucesso!")
      } catch (err) {
        throw "Ocorreu um erro ao Criar Solução!"
      }
    },
    async editSolution() {
      try {
        await SolutionsServices.UpdateSolutions(this.newItem)
        this.dataSolutions.forEach((solution) => solution === this.newItem ? solution = this.newItem : '')
        showSuccessSnackBar("Solução editada com sucesso!")
      } catch (err) {
        throw "Ocorreu um erro ao Editar Solução!"
      }
    },
    async confirmDeleteUnit(item) {
      this.deleteItem = item
      showDeleteDialogAlert(`Deseja excluir essa solução ${item.solution}?`, this.deleteSolution)
    },
    async deleteSolution() {
      try {
        this.isLoadingSolution = true
        await SolutionsServices.DeleteSolution(this.deleteItem.id)
        this.dataSolutions = this.dataSolutions.filter((solution) => this.deleteItem != solution)
      } catch (err) {
        console.error(err)
        showErrorSnackBar("Ocorreu um erro interno!. Entre em contato com nossa equipe.")
      }
      this.deleteItem = null
      this.isLoadingSolution = false
    },
    changeIDGroupPerName(id_group) {
      if(this.dataGroups.length){
        const group =  this.dataGroups.filter(({id}) => id === id_group)
        if(group.length) return group[0].name
        else return " - / - "
      }
      
    },
    changeIDPointPerName(id_point) {
      if(this.dataPoints.length){
        const point =  this.dataPoints.filter(({id}) => id === id_point)
        if(point.length) return point[0].name
        else return " - / - "
      }
      
    },

    checkCusto(custo) {
      if(custo){
        return custo
        
      }else return "0,00"
      
    },
    currencyFormat(value) {
     return Utils.formatCurrency(value)
      
    },
    changeIdPerName(columSearch,value){
      let name = ""
      switch (columSearch) {
        case "groupId":
          name = this.changeIDGroupPerName(value)
          return name
        case "pointId":
          name = this.changeIDPointPerName(value)
          return name
        default:
          return value
      }
    }
  },
  computed: {
    filterSoluitions() {
      let text = Utils.defaultSpace(this.searchText)
      let list = this.dataSolutions
      list = list.filter((solution) => {
        let value = ""
        if(solution[this.columnToSeach]) value = this.changeIdPerName(this.columnToSeach,solution[this.columnToSeach])
        if (value) return (Utils.defaultSpace(value).toLowerCase().indexOf(text.toLowerCase()) > -1);
        else return ""
      });
      return list
    },
    rulesRequired() {
      return Rules.required();
    },
    getSolutionsGroupsByType(){
      if(this.newItem.type){
        const groups = this.dataGroups.filter(({type}) => type === this.newItem.type)
        if(!groups.length) showInfoSnackBar(`Não existem grupos cadastrados para o tipo ${this.newItem.type}`)
        return this.dataGroups.filter(({type}) => type === this.newItem.type)
      }
    }
  },
  mounted() {
    this.listSolutions();
    this.listSolutionsGroups();
    this.listSolutionsPoints();
  },
};