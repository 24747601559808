import apiService,{ HOST } from "./BaseApi";
import Utils from '@/scripts/Utils';
// const ENDPOINT_PATH = "/consumption/generate-pdf";
import { showErrorSnackBar } from "@/utils/SnackBartHelper"

const downloadPdf = async (fileName, newName) => {  
    newName = newName.split(" ").join("_");
    window.open(HOST + `download/v2/${fileName}/${newName}`)
}

const RoiApi = {
    generateRoiPerformancePdf: async (pdfName, params) => {
        params = {...params, env: Utils.getEnv()}
        let response = await apiService.post('/roi/performance', params)
        await downloadPdf(response.data.fileName, `${pdfName}_ROI_PERFORMANCE`)
    },
    generateSalesRoiPdf: async (pdfName, params) => {
        params = {...params, env: Utils.getEnv()}
        try {
            let response = await apiService.post('/roi/sales', params)
            await downloadPdf(response.data.fileName, `${pdfName}_ROI_DE_VENDA`)
            return true
        } catch (e) {
            return false
        }
    },
    generateFinancialFlowRoiPdf: async (pdfName, params) => {
        params = {...params, env: Utils.getEnv()}
        try {
            let response = await apiService.post('/roi/financial-flow', params)
            await downloadPdf(response.data.fileName, `${pdfName}_FLUXO_FINANCEIRO`)
            return true
        } catch (e) {
            return false
        }
    },
};

export default RoiApi;
