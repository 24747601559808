<template>
  <ChooseRoute 
    :routes="optionsSettings"
  />
</template>
<script>
import ChooseRoute from "@/components/ChooseRoute/ChooseRoute.vue";
import { RoutesPath } from '@/router/paths.js';
import {  SubMenuFeatures } from "@/router/features.js";
export default {
  name: "Settings",
  components:{
    ChooseRoute
  },
  data() {
    return {
      optionsSettings: [
        { name: "Usuários", feature: SubMenuFeatures.USERS_CONFIG, icon: 'person', path: RoutesPath.SETTING_USERS},
        { name: "Modems", feature: SubMenuFeatures.MODEMS_CONFIG, icon: 'router', path: RoutesPath.SETTING_MODEMS},
        { name: "Alertas", feature: SubMenuFeatures.ALERTS_CONFIG, icon: 'mdi-alert-outline', path: RoutesPath.SETTING_ALERTS},
        { name: "Permissões", feature: SubMenuFeatures.PERMISSIONS_CONFIG, icon: 'mdi-account-lock', path: RoutesPath.SETTING_PERMISSIONS},
        { name: "Concessionárias", feature: SubMenuFeatures.WATERAUTHORITIES_CONFIG, icon: 'apartment', path: RoutesPath.SETTING_WATER_AUTHORITIES}
      ],
    }
  },
}
</script>