import { render, staticRenderFns } from "./Reservoir.vue?vue&type=template&id=c631f3f2"
import script from "./Reservoir.vue?vue&type=script&lang=js"
export * from "./Reservoir.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports