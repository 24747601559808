<template>
    <!-- <div>{{ id }}</div> -->
    <div>
        <v-textarea 
            v-model="item" 
            outlined 
            :label="label" 
            :type="type" 
            @change="inputChange"
            @focus="inputFocus"
            @blur="inputBlur"
            :loading="loading"
            :disabled="disabled"
            :rules="rules">
        </v-textarea>
    </div>
</template>

<script>
import { showInfoSnackBar, showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import Rules from "@/utils/Rules";
import Utils from '@/scripts/Utils';
import { mapActions, mapGetters } from "vuex";

export default {
    name: "TextAreaField",
    props: {
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        value: {
            required: true,
            default: null,
        },
        label: {
            type: String,
            required: false,
            default: null,
        },
        type: {
            type: String,
            required: false,
            default: null,
        },
        rules: {
            type: Array,
            required: false,
            default: () => [],
        },
        noRule: {
            type: Boolean,
            default: false,
        },
    },
    components: {

    },
    data: () => ({
        item: null,
        focus: false,
    }),
    methods: {
        inputChange() {
            this.emitInput()
        },
        inputFocus() {
            this.focus = true
            this.emitFocus(this.focus)
        },
        inputBlur() {
            this.focus = false
            this.emitFocus(this.focus)
        },
        emitLoading(status) {
            this.$emit('loading:input', status)
        },
        emitDisabled(status) {
            this.$emit('disabled:input', status)
        },
        emitFocus(status) {
            this.$emit('focus:input', status)
        },
        emitInput() {
            this.$emit('change:input', this.item)
        }
    },
    computed: {
        
    },
    watch: {
        value: {
            immediate: true,
            handler: function (newVal) {
                if (newVal) {
                    this.item = newVal
                }
            }
        },
    }
};
</script>