<template>
  <div class="text-center" style="margin-right: 1.5rem">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div v-if="hasNotifications" >
          <v-badge overlap color="error" :content="hasNotifications">
            <v-icon v-bind="attrs" v-on="on" large>
              {{ "mdi-bell" }}
            </v-icon>
          </v-badge>
        </div>
        <div  v-else>
          <v-icon overlap v-bind="attrs" v-on="on" large>
            {{ "mdi-bell" }}
          </v-icon>
        </div>
      </template>

      <v-list v-if="!hasNotifications" style="max-height: 80vh" class="overflow-y-auto">
        <v-list-item>
          <v-list-item-title>
            <p style="font-weight: bold">Não há alertas!</p>
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <v-list v-else style="max-height: 300px" class="overflow-y-auto">
        <v-list-item style="cursor: pointer" v-for="(alert, index) in notifications" :key="index">
          <v-list-item-title>
            <v-alert border="left" colored-border elevation="2" color="orange"
              @click="redirectToAlerts(alert.id)">
                  <span style="font-size: 16px;">{{ alert.alertName + " - " }}</span>
                  <span style="font-size: 14px;">{{ alert.secotorNameOrIdModem }}</span> <br>
                  <span style="font-size: 14px;">{{ formatDate(alert) }}</span>
            </v-alert>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>


<script>
import Utils from '@/scripts/Utils';
import AlertsNotificationsService from "@/services/server/AlertsNotificationService";

export default {
  name: "Notifications",
  data: () => ({
    infoAlerts: 0,
    notifications: [],
  }),
  computed: {
    hasNotifications() {
      return this.notifications.length;
    },
  },

  methods: {
    async redirectToAlerts(alertID) {
      if (this.$route.path !== "/alertas") {
        this.$router.push("/alertas");
      }
    },
    formatDate(alert) {
      return Utils.formatDateDayJs(new Date(alert.date_of_occurrence._seconds * 1000), 'DD/MM/YYYY [às] HH:mm:ss')
    },

    checkAlerts() {
      this.infoAlerts = 0;
    },
  },
  async mounted() {
    AlertsNotificationsService.findAll().then(res => {
      this.notifications = res.data
    }).finally(() => { })
  },
};
</script>


<style scoped></style>