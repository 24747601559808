import { db } from "@/firebase"
import Utils from '@/scripts/Utils';
const solutions_group_ref = db.collection("solutions_group")

async function getSolutionsGroup() {
    try {
        let solutionsGroup = []
        const get_data = await solutions_group_ref.get();
        get_data.forEach((doc) => solutionsGroup.push(doc.data()));
        return solutionsGroup
    } catch (error) {
        console.error(error);
    }
}

async function GetSolutionsGroupByIds(solutionGroupIds) {
    try {
        let chunkIds = Utils.chunkArray(Utils.removeDuplicates(solutionGroupIds))
        let responses = {}
        for (let i = 0; i < chunkIds.length; i++) {
            const get_data = await solutions_group_ref.where("id", "in", chunkIds[i]).get();
            chunkIds[i].forEach((id) => responses[id] = null)
            get_data.forEach((doc) => responses[doc.id] = {...doc.data(), id: doc.id});
        }
        return responses
    } catch (error) {
        console.error(error);
    }
}


async function getSolutionsGroupByType(type) {
    try {
        let solutionsGroup = []
        const get_data = await solutions_group_ref.where("type","==",type).get();
        get_data.forEach((doc) => solutionsGroup.push(doc.data()));
        return solutionsGroup
    } catch (error) {
        console.error(error);
    }
}

async function updateSolutionsGroup(solutionsGroup) {
    try {
        await solutions_group_ref.doc(solutionsGroup.id).update(solutionsGroup);
    } catch (error) {
        console.error(error);
    }
}

async function addSolutionGroup(solutionsGroup) {
    if (solutionsGroup === undefined) throw "field 'user' is missing";
    try {
        const doc_ref = await solutions_group_ref.add(solutionsGroup);
        const doc_id = doc_ref.id;
        await solutions_group_ref
            .doc(doc_id)
            .update({
                id: doc_id,
            });
        return doc_id;

    } catch (e) {

    }
}

async function deleteSolutionGroup(solutionsGroupId) {
    try {
        await solutions_group_ref.doc(solutionsGroupId).delete();
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}


export default {
    getSolutionsGroup,
    GetSolutionsGroupByIds,
    addSolutionGroup,
    deleteSolutionGroup,
    updateSolutionsGroup,
    getSolutionsGroupByType
};
