<template>
    <v-card style="display: flex;background-color: rgba(0, 164, 222, 0.90);
        margin: 10px" :max-width="width" outlined shaped elevation="2" @click="editF()">
        <v-hover>
        <v-list-item three-line>        
          <v-list-item-content style="color: white">
            <slot></slot>
          </v-list-item-content>
        </v-list-item>
      </v-hover>
      <v-icon @click="(e)=> deleteF(e)" color="white" style=" height: 30px;">mdi-delete-outline</v-icon>
    </v-card>
</template>

<script>
export default {
  data: () => ({
  }),

  props: {
    width: {
      default: "200px",
      type: String,
    },
    deleteFunc: {},
    editFunc: {},
    index: {},
    type: {},
  },

  computed: {},

  methods: {
    deleteF(event) {
      event.stopPropagation()
      this.deleteFunc(this.type,this.index)
    },
    editF() {
      this.editFunc(this.type,this.index)
    }
  },

  watch: {},

  created() { },
};
</script>


<style>
.CardItensWaterScan {
  background-color: rgba(0, 164, 222, 0.90);
  margin: 10px
}
</style>