
import { mapGetters, mapMutations, mapActions } from "vuex";
import firebase from "@/firebase";
import { jsonp } from "vue-jsonp";
import latinize from "latinize";
import Rules from "@/utils/Rules";
import Utils from '@/scripts/Utils';
import RolesService from "@/services/firebase/Roles/RolesService";
import UsersService from "@/services/firebase/Users/UsersService";
import UsersServerService from "@/services/server/UsersService";
import UnitsService from "@/services/server/UnitsService";
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper"
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper";

export default {
    name: "Users",

    data: () => ({
        ufs: [
            'AC',
            'AL',
            'AP',
            'AM',
            'BA',
            'CE',
            'DF',
            'ES',
            'GO',
            'MA',
            'MT',
            'MS',
            'MG',
            'PA',
            'PB',
            'PR',
            'PE',
            'PI',
            'RJ',
            'RN',
            'RS',
            'RO',
            'RR',
            'SC',
            'SP',
            'SE',
            'TO',
        ],
        rolesData: [],
        franchisees: [],
        clients: [],
        loadingfranchisees: false,
        loadingUsers: false,
        loadingClients: false,
        loadingUnitsByUser: false,
        route: 'users',
        validSignup: true,
        photoRules: [],
        usersData: [],
        rules: {
            photo: [],
            idType: Rules.required(),
            idNumber: [],
        },
        eventPhoto: null,
        unitsByClient: [],
        newUnitAllowedByClient: [],
        columnToSearch: "Nome",
        columnsToChoose: ["Nome", "E-mail", "Nível de Acesso", "Gestor"],
        searchText: "",
        canDelete: false,
        users: [],
        usersKey: 0,
        managers: [],
        dialog: false,
        dateMenu: false,
        isPwsVisible: false,
        saveButtonLoading: false,
        editedIndex: -1,
        editedManagerIndex: -1,
        imageUploadError: undefined,
        user: {
            name: "",
            manager: "",
            email: "",
            password: "",
            rank: "",
            status: "Ativo",
            dataList: [],
            metadata: {
                image: null,
                imageName: "",
                imageURL: "",
                idType: "",
                idNumber: "",
                address: {},
                phone: "",
            },
        },
        editedUser: {
            name: "",
            manager: "",
            email: "",
            password: "",
            rank: 0,
            status: "Ativo",
            dataList: [],
            canDeleteData: "",
            metadata: {
                image: null,
                imageName: "",
                imageURL: "",
                idType: "",
                idNumber: "",
                address: {},
                phone: "",
            },

        },
        leitura: {
            leitura: null,
            datetime: undefined,
        },
        deleteData: [
            { text: "Sim", value: true },
            { text: "Não", value: false },
        ],
        ranks: ["Super Admin", "Admin", "Franqueado", "Cliente"],
        headers: [
            { text: "Nome", value: "name" },
            { text: "E-mail", value: "email" },
            { text: "Nível de Acesso", value: "rank" },
            { text: "Gestor", value: "manager" },
            { text: "Ações", value: "actions", sortable: false },
        ],

        isLoadingDeleteUser: false,

        // ---------------------------------------------------------------------

        action: "view",
        rankOptions: [
            { text: "Super Admin", value: 0 },
            { text: "Admin", value: 1 },
            { text: "Franqueado", value: 2 },
            { text: "Cliente", value: 4 },
            { text: "Associado", value: 5 }],

        mapUsers: {},

        // Array de IDs dos usuários
        list: {
            "SUPER ADMIN": [],
            ADMIN: [],
            FRANQUEADO: [],
            CLIENTE: [],
        },

        /*
          Cada tipo de usuário pode ter um relacionamento com outro usuário
          No caso o relacionamento é de pai e filho
          Cada tipo de rank terá um objeto indicando pai e filho caso haja
        */
        relationship: {},

        // ---------------------------------------------------------------------
    }),

    methods: {
        managerSlug(item) {
            if (item.manager) {
                const manager = this.usersData.find(({ id }) => id === item.manager)
                return manager ? manager.name : "Indefinido!"
            }
            else return ""
        },
        rankSlug(rank) {
            switch (rank) {
                case 0:
                    return "Super Admin"
                case 1:
                    return "Admin"
                case 2:
                    return "Franqueado"
                case 4:
                    return "Cliente"
                case 5:
                    return "Associado"
                default:
                    return "Não Definido!"
            }
        },
        changeClient(id) {
            this.unitsByClient = []
            this.loadingUnitsByUser = true
            UnitsService.findAllByClient(id)
                .then(res => this.unitsByClient = res.data.data)
                .finally(() => this.loadingUnitsByUser = false)
            if (!this.editedUser.client) return;
            this.editedUser.unitsAllowedByClient = [];
        },
        isEnabledAction(action) {
            return this.hasAction(this.route, action);
        },

        onChangeNewUnitAllowedByClient(idUnit) {
            this.editedUser.unitsAllowedByClient = this.newUnitAllowedByClient
        },
        deleteUnitAllowedByClient(idUnit) {
            this.newUnitAllowedByClient = [];
        },

        consultaCnpj() {

            if (this.editedUser.metadata.idNumber.length == 18) {
                jsonp(
                    "https://receitaws.com.br/v1/cnpj/" +
                    this.editedUser.metadata.idNumber
                        .split(".")
                        .join("")
                        .split("/")
                        .join("")
                        .split("-")
                        .join("")
                )
                    .then((response) => {
                        this.editedUser.name = response.fantasia;
                        this.editedUser.metadata.phone = response.telefone;
                        this.editedUser.email = response.email;
                        this.editedUser.metadata["address"] = {
                            logradouro: response.logradouro,
                            numero: response.numero,
                            complemento: response.complemento,
                            bairro: response.bairro,
                            cidade: response.municipio,
                            cep: response.cep,
                            uf: response.uf,
                        };
                        this.usersKey++;
                    })
                    .catch((err) => console.error(err));
            }
        },

        pickFile() {
            this.$refs.image_photo.click();
        },

        onFilePicked(e) {
            this.imageUploadError = undefined;
            const files = e.target.files;
            this.rules.photo = Rules.photoUser(files);
            this.editedUser.metadata.imageName = null;
            this.editedUser.metadata.image = null;
            this.editedUser.metadata.imageURL = "";
            if (
                files[0] !== undefined &&
                files[0].type.split("/")[0] == "image" &&
                files[0].size < 700000
            ) {
                this.editedUser.metadata.imageName = files[0].name;
                // this.editedUser.metadata.image = files[0];

                if (this.editedUser.metadata.imageName.lastIndexOf(".") <= 0) {
                    return;
                }
                const fr = new FileReader();
                fr.readAsDataURL(files[0]);
                fr.addEventListener("load", () => {
                    this.editedUser.metadata.imageURL = fr.result;
                    this.imageFile = files[0]; // this is an image file that can be sent to server...
                });
            } else if (files[0].size > 699999) {
                this.imageUploadError = "Tamanho máximo: 700 KB.";
            } else {
                this.imageUploadError = "Arquivo inválido.";
            }
        },
        changeUserType() {
            switch (this.editedUser.rank) {
                case 4:
                    this.loadingfranchisees = true
                    UsersServerService.findAllFranchisee().then(res => this.franchisees = res.data.data).finally(() => this.loadingfranchisees = false)
                    break;
                case 5:
                    this.loadingClients = true
                    UsersService.getUsersByRank(4)
                        .then(res => this.clients = res)
                        .finally(() => this.loadingClients = false)

                    break;
            }
        },
        editItem(item) {
            if (this.$refs.signupForm) this.$refs.signupForm.reset();
            this.setAction("edit");

            this.editedUser = { ...item }
            this.newUnitAllowedByClient = item.unitsAllowedByClient
            switch (this.editedUser.rank) {
                case 4:
                    this.loadingfranchisees = true
                    UsersServerService.findAllFranchisee().then(res => this.franchisees = res.data.data).finally(() => this.loadingfranchisees = false)
                    break;
                case 5:
                    this.loadingUnitsByUser = true
                    UnitsService.findAllByClient(item.client).then(res => this.unitsByClient = res.data.data).finally(() => this.loadingUnitsByUser = false)
                    this.loadingClients = true
                    UsersService.getUsersByRank(4)
                        .then(res => this.clients = res)
                        .finally(() => this.loadingClients = false)

                    break;
            }

            this.dialog = true;
        },

        deleteItem(item) {
            this.editedUser = item;
            showDeleteDialogAlert(`Deseja excluir esse usuário "${item.name}"?`, this.deleteItemConfirm)
        },
        async deleteItemConfirm() {
            try {
                let userNewFormat = { 'id': this.editedUser.id, 'idUserRequest': this.getCurrentUserID }
                this.isLoadingDeleteUser = true;
                await firebase.deleteUser(userNewFormat);
                this.usersData.filter(({ id }) => id != this.editedUser.id)
                this.clearEditedItem()
            } catch (e) {
                throw new Error(e);
            } finally { this.isLoadingDeleteUser = false; }
        },
        searchUnitName(idUnit) {
            const unit = this.unitsByClient.find(({ id }) => id === idUnit)
            return unit ? unit.name : ""
        },
        close() {
            this.dialog = false;
            this.imageUploadError = undefined;
            this.setAction("view");
        },
        async save() {
            if ((this.$refs.signupForm) && !this.$refs.signupForm.validate()) return;

            this.saveButtonLoading = true;
            let userSaving = { ...this.editedUser }
            switch (userSaving.rank) {
                case 4:
                    delete userSaving.client;
                    delete userSaving.unitsAllowedByClient;
                    break;
                case 5:
                    delete userSaving.manager;
                    delete userSaving.dataList;
                    break;
                default:
                    delete userSaving.client;
                    delete userSaving.manager;
                    delete userSaving.unitsAllowedByClient;
            }
            if (userSaving.id) {
                await firebase.updateUserData(userSaving)
                this.usersData = this.usersData.map((user) => user.id === userSaving.id ? userSaving : user)
            } else {
                const userSaved = await firebase.registerUser(userSaving)
                if (userSaved.message) {
                    if (userSaved.message === "Error: The email address is already in use by another account.") showErrorSnackBar("Troque o Email. Esse email já está cadastrado!")
                    else showErrorSnackBar("Erro ao cadastrar!")
                    this.saveButtonLoading = false;
                    console.error(userSaved)
                    return
                } else {
                    showSuccessSnackBar("Usuário cadastrado!")
                    this.usersData.push(userSaved)
                }
            }
            this.close();

            this.saveButtonLoading = false;
        },
        clearEditedItem() {
            if (this.$refs.signupForm) this.$refs.signupForm.reset();
            this.unitsByClient = []
            this.editedUser = {
                rank: 0,
                name: null,
                email: null,
                password: null,
                client: null,
                manager: null,
                unitsAllowedByClient: [],
                metadata: {
                    image: null,
                    imageName: null,
                    imageURL: null,
                    idType: null,
                    idNumber: null,
                    address: {},
                    phone: null,
                },
            }
        },
        isFranqueado(id = null) {
            if (id !== null) {
                return this.list["FRANQUEADO"].includes(id);
            } else {
                return this.getCurrentUserRank.toUpperCase() == "FRANQUEADO";
            }
        },
        isCliente(id = null) {
            if (id !== null) {
                return this.list["CLIENTE"].includes(id);
            } else {
                return this.getCurrentUserRank.toUpperCase() == "CLIENTE";
            }
        },
        addNewUser() {
            this.clearEditedItem();
            this.setAction('signup');
            this.dialog = true;
            this.loadingfranchisees = true
            UsersServerService.findAllFranchisee()
                .then(res => this.franchisees = res.data.data)
                .finally(() => this.loadingfranchisees = false)
            this.loadingUnitsByUser = true
            UsersService.getUsersByRank(4)
                .then(res => this.clients = res)
                .finally(() => this.loadingUnitsByUser = false)
        },
        setAction(value = "view") {
            this.action = value;
        },

    },

    computed: {
        ...mapGetters([
            "hasAction",
            "getCurrentUserID",
            "getCurrentUserRank",
        ]),
        rulesIdNumber() {
            return this.rules.idNumber = this.editedUser.metadata.idType === 'CPF' ? Rules.cpf() : Rules.cnpj();
        },
        rulesName() {
            return Rules.string(true, 1, 64);
        },
        rulesEmail() {
            return Rules.email(true, 64);
        },
        rulesPassword() {
            return this.getAction === 'edit' || this.getAction === 'view' ? [] : Rules.password(6, 32);
        },
        rulesRank() {
            return Rules.required();
        },
        rulesRequired() {
            return Rules.required();
        },
        rulesGestor() {
            return this.editedUser.rank === 4 ? Rules.required() : [];
        },
        rulesGerente() {
            return this.editedUser.rank === 5 ? Rules.required() : [];
        },
        rulesPhone() {
            return Rules.phone();
        },
        rulesCEP() {
            return Rules.cep(true);
        },
        rulesLogradouro() {
            return Rules.string(true, null, 128);
        },
        rulesHouseNumber() {
            return Rules.string(true, null, 16);
        },
        rulesAdditionalAddress() {
            return Rules.string(true, null, 64);
        },
        rulesDistrict() {
            return Rules.string(true, null, 64);
        },
        rulesCity() {
            return Rules.string(true, null, 64);
        },
        getPhone() {
            return this.editedUser.metadata.phone ?? '';
        },
        getImageURL() {
            return this.editedUser.metadata.imageURL ?? '';
        },
        isSelectedClient() {
            return this.editedUser.rank === 4;
        },
        isSelectedAssociate() {
            return this.editedUser.rank === 5;
        },
        isSelectedClientOfAssociate() {
            return (
                (this.editedUser.client)
                &&
                this.editedUser.client !== null
                &&
                this.isSelectedAssociate
            )
        },
        getAction() {
            return this.action;
        },
        hasUnitsAllowedByClient() {
            this.validSignup = (this.editedUser.unitsAllowedByClient) && this.editedUser.unitsAllowedByClient.length > 0;
            return this.validSignup;
        },

        formTitle() {
            return this.editedIndex === -1 ? "Adicionar Usuário" : "Editar Usuário";
        },

        searchedUsers() {
            const users = this.usersData
            function searchUser(search, searchText) {
                searchText = Utils.defaultSpace(searchText)
                return users.filter((user) => {
                    if (user[search] !== null && user[search] !== undefined) {
                        return (
                            Utils.defaultSpace(String(user[search])).toLowerCase().indexOf(searchText.toLowerCase()) > -1
                        );
                    } else if (searchText == '') return user

                });
            }
            if (this.searchText) {
                let text = ""
                switch (this.columnToSearch) {
                    case 'Nome':
                        return searchUser('name', this.searchText)
                    case 'E-mail':
                        return searchUser('email', this.searchText)
                    case 'Nível de Acesso':
                        text = Utils.defaultSpace(this.searchText)
                        return users.filter((user) => {
                            if (user.rank !== null && user.rank !== undefined) return (Utils.defaultSpace(this.rankSlug(user.rank)).toLowerCase().indexOf(text.toLowerCase()) > -1);
                        });
                    case 'Gestor':
                        text = Utils.defaultSpace(this.searchText)
                        return users.filter((user) => {
                            if (user.manager !== null && user.manager !== undefined) return (Utils.defaultSpace(this.managerSlug(user.manager)).toLowerCase().indexOf(text.toLowerCase()) > -1);
                        });

                }
            } else {
                return users.sort(function (a, b) {
                    return a.rank < b.rank ? -1 : a.rank > b.rank ? 1 : 0;
                });
            }
        },


        isClienteRank() {
            return (this.editedUser.rank ?? '').toUpperCase() == "CLIENTE";
        },

        isSuperAdminRank() {
            return (this.editedUser.rank ?? '').toUpperCase() == "SUPER ADMIN";
        },

        isAdminRank() {
            return (this.editedUser.rank ?? '').toUpperCase() == "ADMIN";
        },

        isFranqueadoRank() {
            return (this.editedUser.rank ?? '').toUpperCase() == "FRANQUEADO";
        },

        isAssociadoRank() {
            return (this.editedUser.rank ?? '').toUpperCase() == "ASSOCIADO";
        },

        isView() {
            return this.action === "view";
        },

        isEdit() {
            return this.action === "edit";
        },

        isSignup() {
            return this.action === "signup";
        },

        getRanks() {
            return this.rankOptions;
        },

        hasRankOptionsToSignup() {
            return !this.isCliente() && !this.isFranqueado();
        },

        isSingleRankOptions() {
            return this.rankOptions.length == 1;
        },

        isMultiRankOptions() {
            return this.rankOptions.length > 1;
        },

        isEmptyRankOptions() {
            return this.rankOptions.length <= 0;
        },
    },
    async created() {
        this.loadingUsers = true
        this.usersData = await UsersService.getUsers()
        this.loadingUsers = false
        RolesService.getRoles().then((res) => this.rolesData = res)
    },

};
