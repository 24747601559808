<template>
    <v-col cols="12" class="d-flex">
        <div class="d-flex f-align-center" style="margin-right: 10px;">
            <h4>{{ title }}</h4>
        </div>
        <div class="f-1">
            <v-btn
                color="primary"
                rounded
                text    
                @click="click"          
            >
                <v-icon>add</v-icon>
            </v-btn>
        </div>
    </v-col>
</template>
  
<script>
export default {
    name: "HeaderCheckList",
    
    components: {
        
    },
    
    props: {
        click: {
            type: Function,
            default: () => {}
        },
        title: {
            type: String,
            default: 'Digite um título',
        }
    },
    
    data: () => ({
        
    }),

    computed: {

    },

    methods: {

    },

    watch: {
        
    },

};
</script>
  
<style scoped>
.no-margin {
    margin: 0;
}
.d-flex {
    display: flex;
}
.f-align-center {
    align-items: center;
}
.f-1 {
    flex: 1;
}
.f-2 {
    flex: 2;
}
</style>