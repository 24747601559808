<template>
  <div style=" display: flex">
    <!-- <v-col cols="12" md="6">
      <table style="
          border-radius: 5px;
          border-collapse: collapse;
          width: 100%;
          overflow: hidden;
          height: 85%;
        ">
        <caption class="TitleChecklist" style="background: #0a517f; color: white">
          <strong> CONSUMO </strong>
        </caption>
        <tr>
          <td class="TitleChecklist column-statitcs-info" style="background: #0e76bc; color: white">
            MÉDIA
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd30">
            {{ consumption.formatAverage }} m³
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd30">
            {{ valueCurrency.formatAverage }}
          </td>
        </tr>
        <tr>
          <td class="TitleChecklist column-statitcs-info" style="background: #0c68a5; color: white">
            MÁXIMO
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd80">
            {{ consumption.formatMax }} m³
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd80">
            {{ valueCurrency.formatMax }}
          </td>
        </tr>
        <tr>
          <td class="TitleChecklist column-statitcs-info" style="background: #0e76bc; color: white">
            MÍNIMO
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd30">
            {{ consumption.formatMin }} m³
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd30">
            {{ valueCurrency.formatMin }}
          </td>
        </tr>
        <tr>
          <td class="TitleChecklist column-statitcs-info" style="background: #0a517f; color: white">
            TOTAL
          </td>
          <td class="borderTDandTH" style="background-color: #0e76bc; color: white">
            {{ consumption.formatTotal }} m³
          </td>
          <td class="borderTDandTH" style="background-color: #0e76bc; color: white">
            {{ valueCurrency.formatTotal }}
          </td>
        </tr>
      </table>
    </v-col> -->
    <v-col cols="12" md="12">
      <v-skeleton-loader v-show="loading" type="image"></v-skeleton-loader>
      <table v-show="!loading" style="
          border-radius: 5px;
          border-collapse: collapse;
          width: 100%;
          overflow: hidden;
          height: 85%;
        ">
        <caption class="TitleChecklist" style="background: #218c24; color: white">
          <strong>  ECONOMIA </strong>
        </caption>
        
        <!-- <tr>
          <td  style="background: #218c24; color: white">
            
          </td>
          <td class="TitleChecklist" style="background: #218c24; color: white">
            <strong> CONSUMO </strong>
          </td>
          <td class="TitleChecklist" style="background: #218c24; color: white">
            <strong> ECONOMIA </strong>
          </td>
        </tr> -->
        <tr>
          <td class="TitleChecklist column-statitcs-info" style="background: #3ab436; color: white">
            MÉDIA
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd30">
            {{ econometer.avaregeWaterEconomy }} m³
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd30">
            {{ econometer.avaregeFinancialEconomy }}
          </td>
        </tr>
        <tr>
          <td class="TitleChecklist column-statitcs-info" style="background: #218c24; color: white">
            MÁXIMO
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd80">
            {{ econometer.maxWaterEconomy }} m³
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd80">
            {{ econometer.maxFinancialEconomy }}
          </td>
        </tr>
        <tr>
          <td class="TitleChecklist column-statitcs-info" style="background: #3ab436; color: white">
            MÍNIMO
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd30">
            {{ econometer.minWaterEconomy }} m³
          </td>
          <td class="borderTDandTH" style="background-color: #dddddd30">
            {{ econometer.minFinancialEconomy }}
          </td>
        </tr>
        <tr>
          <td class="TitleChecklist column-statitcs-info" style="background: #218c24; color: white">
            TOTAL
          </td>
          <td class="borderTDandTH" style="background-color: #3ab436; color: white">
            {{ econometer.totalWaterEconomy }} m³
          </td>
          <td class="borderTDandTH" style="background-color: #3ab436; color: white">
            {{ econometer.totalFinancialEconomy }}
          </td>
        </tr>
      </table>
    </v-col>
  </div>
</template>

<script>
import Utils from "@/scripts/Utils";

export default {
  data: () => ({
    color: "red",
  }),

  props: {
    loading: {
      type: Boolean,
      default: false, // Indica se o valor está carregando
    },
    econometer: {
      default: {
        avaregeWaterEconomy: 0,
        minWaterEconomy: 0,
        totalWaterEconomy: 0,
        avaregeFinancialEconomy: 0,
        minFinancialEconomy: 0,
        totalFinancialEconomy: 0,
        maxFinancialEconomy: 0,
        maxWaterEconomy: 0,
      },
    },
  },

  computed: {
  },

  methods: {
    formatCurrency(value) {
      return Utils.formatCurrency(value);
    },
    formatNumber(value) {
      return Utils.formatNumber(value);
    },
  },
};
</script>


<style scoped>
.borderTDandTH {
  font-size: 14px;
  padding: 8px;
  text-align: center;
}

.TitleChecklist {
  font-size: 16px;
  padding: 8px;
  text-align: center;
}

.column-statitcs-info {
  width: 30%;
}

.checkedAlert {
  margin: 2px;
  background-color: #79c9ff;
  color: #79c9ff;
  border-radius: 10px;
}
</style>