<template>
    <!-- :style="{position: 'absolute',left: (width-30)+'px',
    top: '5px'}" -->
    <v-card style="display: flex;background-color: rgba(0, 164, 222, 0.90);
        margin: 10px" :max-width="width" outlined shaped elevation="2">
  
  <v-hover>
        <v-list-item three-line>        
        
          <v-list-item-content style="color: white">
            <slot></slot>
          </v-list-item-content>
        </v-list-item>
      </v-hover>
        <v-icon @click=" deleteF()" color="white" style="    height: 30px;">mdi-delete-outline</v-icon>
      </v-card>
</template>

<script>
export default {
  data: () => ({
  }),

  props: {
    width: {
      default: "200px",
      type: String,
    },
    deleteFunc: {},
    index: {},
    type: {},
  },

  computed: {},

  methods: {
    deleteF() {
      this.deleteFunc(this.type,this.index)
      this.$emit('status:save', true)
    }
  },

  watch: {},

  created() { },
};
</script>


<style>
.CardItensWaterScan {
  background-color: rgba(0, 164, 222, 0.90);
  margin: 10px
}
</style>