<template>
    <div id="wrapper-graphic" style="height: 100%;width: 100%;">
    </div>
    <!-- <canvas id="graphic"></canvas> -->
</template>

<script>
import Chart from "chart.js";

/*
* Plugin usado para projetar a linha vermelha vertical
* É esperado nas options o array de labels e a label de referencia
* no qual será projetado a linha
*/
const verticalLine = {
  id: "verticalLine",
  beforeDraw(chart, args, options) {
   
    const {ctx, chartArea: {top, right, bottom, left, width, height}, scales} = chart;
    let xAxis0 = scales['x-axis-0'];
    let yAxis0 = scales['y-axis-0'];
    let { label, labels } = options;

    
    ctx.save();
    if (!label || labels.length <= 0) return;

    let indexTarget = labels.findIndex(l => l === label);

    if (indexTarget < 0) return;

    let lastIndex = labels.length - 1;
    let nextIndexTarget = indexTarget + 1;

    if (nextIndexTarget > lastIndex) return;

    ctx.strokeStyle = 'red';
    let diffPoints = (xAxis0.getPixelForValue(nextIndexTarget) - xAxis0.getPixelForValue(indexTarget));
    // if (diffPoints > 5) ctx.lineWidth = "5";
    // else if (diffPoints > 1 && diffPoints < 5) ctx.lineWidth = diffPoints.toString();
    // else ctx.lineWidth = "1";
    let midpoint = xAxis0.getPixelForValue(indexTarget) + diffPoints/2;
    let lineWidth = chart.getDatasetMeta(0).data[0]._view.width;
    ctx.lineWidth = `${lineWidth > 5 ? 5 : lineWidth}`
    // x0 = ponto inicial na linha horizontal
    // y0 = ponto inicial na linha vertical
    // x1 = ponto final na linha horizontal // A largura do retângulo.
    // y1 = ponto final na linha vertical // A altura do retângulo.
    let x0 = midpoint;
    let y0 = yAxis0.top;
    let x1 = 0;
    let y1 = yAxis0.bottom - yAxis0.top;

    ctx.strokeRect(x0, y0, x1, y1);
    ctx.restore();
  }
}

export default {
  props: {
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    labels: {
      type: Array,
      default: () => ([]),
    },
    datasets: {
      type: Array,
      default: () => ([]),
    },
    label: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    context: null,
    config: {
      type: 'bar',
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        title: {
          display: false,
        },
        scales: {
          xAxes: [
            { 
              gridLines: { display: true }, 
              display: true,
              ticks: {
                autoSkip: false,
                beginAtZero: true,
                maxTicksLimit: 25 // DEBUG
              },
            }
          ],
          yAxes: [
            {
              gridLines: { display: true },
              display: true,
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 8
              }
            }
          ]
        },
        plugins: {
          datalabels: {
            display: false
          },
          verticalLine: {
            label: null,
            labels: [],
          }
        },
        hover: {
          mode: "nearest",
          intersect: true
        },
      },
      plugins: [
        verticalLine,
      ]
    },
    chart: null,
  }),
  methods: {
    render(config) {
      if (
        config.data.labels.length <= 0 
        || 
        config.data.datasets.length <= 0
      ) return;
      let canvasEl = document.querySelector("#graphic");
      if (canvasEl) canvasEl.remove();
      document.querySelector("#wrapper-graphic").innerHTML = `<canvas id="graphic"></canvas>`;
      let context = document.getElementById('graphic').getContext('2d');
      this.chart = new Chart(context, config);
    },
    // drawVerticalLine(canvasEl, label = null) {
    //   if (!label || !canvasEl) return;
    //   let context = canvasEl.getContext('2d');
    //   let chart = context.canvas.$chartjs;
    //   // let width   = canvasEl.width;
    //   // let height  = canvasEl.height;
    //   // let widthByLabel = (width/this.config.data.labels.length).toFixed(2);
    // },
  },
  watch: {
    labels(newValue, oldValue) {
      this.config.data.labels = newValue;
      this.config.options.plugins.verticalLine.labels = newValue;
      this.$nextTick(
        () => {
          this.render(this.config);
        }
      );
    },
    datasets(newValue, oldValue) {
      // if (newValue) {
      this.config.data.datasets = newValue;
      this.$nextTick(
        () => {
          this.render(this.config);
        }
      );
    },
    label(newValue, oldValue) {
      // if (newValue) {
      this.config.options.plugins.verticalLine.label = newValue;
      this.$nextTick(
        () => {
          this.render(this.config);
        }
      );
    }
  },
  created() {
    this.config.data.labels = this.labels;
    this.config.data.datasets = this.datasets;
    this.config.options.plugins.verticalLine.labels = this.labels;
    this.config.options.plugins.verticalLine.label = this.label;
  },
  mounted() {
    this.render(this.config);
  },
  // beforeUpdate() {
  //   this.render(this.context, this.config);
  // }
}

// TODO opção de tirar o eixo Y nas props e, consequentemente, colocar as labels Y diretamente em cada uma
// export default {
//   extends: Bar,
//   mixins: [reactiveProp],
//   props: ["chartData", "titulo", "showYAxis"],
//   data() {
//     return {
//       options: {
//         scaleShowValues: true,
//         title: {
//           display: true,
//           text: this.titulo
//         },
//         responsive: true,
//         maintainAspectRatio: false,
//         scales: {
//           xAxes: [
//             { 
//               gridLines: { display: false }, 
//               ticks: {
//                 autoSkip: false,
//                 beginAtZero: true,
//                 maxTicksLimit: 25 // DEBUG
//               },
//             }
//           ],
//           yAxes: [
//             {
//               gridLines: { display: this.showYAxis != undefined ? this.showYAxis : true },
//               display: this.showYAxis != undefined ? this.showYAxis : true,
//               ticks: {
//                 beginAtZero: true,
//                 maxTicksLimit: 8
//               }
//             }
//           ]
//         },
//         hover: {
//           mode: "nearest",
//           intersect: true
//         },
//         onClick: function clickHandler(evt) {
//           var firstPoint = this.getElementAtEvent(evt)[0];

//           if (firstPoint) {
//             var label = this.data.labels[firstPoint._index];
//             var value = this.data.datasets[firstPoint._datasetIndex].data[
//               firstPoint._index
//             ];

//             evt.stopPropagation()

//             document.dispatchEvent(
//               new CustomEvent("openDay", {
//                 detail: label
//               })
//             );
//           }
//         },
//         plugins: {
//           datalabels: !this.showYAxis ? {
//             anchor: 'end',
//             align: 'end',
//             clamp: true
//           } : false
//         }
//       }
//     };
//   },
//   mounted() {
//     this.renderChart(this.chartData, this.options);
//   },
//   watch: {
//     chartData(newValue, oldValue) {
//       if (newValue) this.renderChart(newValue, this.options);
//     }
//   },
//   computed: {
//     // filteredData: function(){
//     //   if(this.chartData.length > 7 && this.showYAxis){
//     //     // ? run sorting algorithm to choose 7 max labels
//     //     let newArray = [], last = this.chartData.length -1
//     //     //using roof, remove midway, first and last, then x1.3, x1.6, x0.7 and x0.4 from mid
//     //     newArray.push([this.chartData[0], this.chartData[last], this.chartData[Math.floor(last/2)]])

//     //     newArray.push([this.chartData[Math.floor((last/2)*0.4)], this.chartData[Math.floor((last/2)*0.7)]])
//     //     newArray.push([this.chartData[Math.floor((last/2)*1.3)], this.chartData[Math.floor((last/2)*1.6)]])

//     //     return newArray
//     //   }else return this.chartData
//     // }
//   }
// };
</script>