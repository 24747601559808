<template>
  <div>
    <v-row>
      <h3> {{ label }} </h3>
      <v-btn color="primary" text @click="pickFile()">
        <v-icon>add</v-icon>
      </v-btn>
      <input multiple type="file" id="get_image" ref="get_image" accept="image/*" style="display: none"
        @input="onFilePicked" />
    </v-row>
    <vue-horizontal>
      <section style=" margin: 10px;" v-for="img, n in listImgs" :key="n">
        <v-img :src="img.image" aspect-ratio="1" style="width: 200px" @click="deleteImgSelect(img)">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey-lighten-5"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </section>
    </vue-horizontal>
  </div>
</template>
   
<script>
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper";
import { showErrorSnackBar } from "@/utils/SnackBartHelper"
import WaterScanStoragefrom from '@/services/storage/WaterScanStorage';
import VueHorizontal from 'vue-horizontal';

export default {
  components: {
    VueHorizontal
  },
  props: {
    imageListProp: {},
    label: {
      type: String,
      default: "Adiconar Imagem"
    },
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false
      },
      listImgs: [],
      editedItem: {},
      deleteImgFile: {},
      rawFiles: [],
      solutionReport: {},
    }
  },
  methods: {
    async deleteImgSelect(event) {
      this.deleteImgFile = event
      showDeleteDialogAlert(`Deseja Excluir a imagem ${event.imageName}`, this.deleteImg)
    },
    async deleteImg() {
      this.listImgs = this.listImgs.filter(({ image }) => image != this.deleteImgFile.image)
      this.rawFiles = this.rawFiles.filter(({ name }) => name != this.deleteImgFile.imageName)
      this.$emit("rawFiles", this.rawFiles);
    },
    pickFile() {
      this.$refs.get_image.click();
    },
    async onFilePicked(e) {
      const inputFiles = document.getElementById("get_image").files;
      if (inputFiles[0] !== undefined && inputFiles[0].type.split("/")[0] == "image") {
        this.rawFiles.push(...document.getElementById("get_image").files)
        for (let file of inputFiles) {
          await this.processImg(file)
        }
        this.$emit("rawFiles", this.rawFiles);
      } else {
        showErrorSnackBar("Arquivo inválido.")
        this.editedItem.image = "";
        this.editedItem.imageName = "";
      }
    },
    async processImg(file) {
      try {
        this.editedItem = {}
        this.editedItem.imageName = file.name;
        if (this.editedItem.imageName.lastIndexOf(".") <= 0) reject("Nome Invalido")
        var output = document.getElementById('output');
        this.listImgs.unshift({
          image: URL.createObjectURL(file),
          imageName: file.name
        })
      } catch (err) {
        console.error(err)
        showErrorSnackBar("Erro ao processar imagem.")
      }
    },
    async getStorageImages(listImgs) {
      for (let imgPath of listImgs) {
        const urlImg = await WaterScanStoragefrom.downloadImg(imgPath)

        const mainPathEnd = imgPath.indexOf('/')+1
        const matricula = imgPath.slice(mainPathEnd)
        const matriculaEnd = imgPath.slice(mainPathEnd).indexOf('/')+1
        const imgNameOrigin = matricula.slice(matriculaEnd+14)

       
        this.listImgs.unshift({
          image: urlImg,
          pathFirebase: imgPath,
          imageName: imgNameOrigin
        })
      }
    },
  },
}
</script> 