<template>
  <v-container>
    <v-col style="padding: 0;">
      <v-row  >
        <h4>{{ title }}</h4> 
        <v-icon class="ml-3" color="error" @click="clearFile" v-if="file">mdi-delete</v-icon>
      </v-row>
    </v-col>

    <v-row>
      <v-col>
        <v-card
          class="d-flex justify-center align-center"
          outlined
          max-width="250"
          style="height: 200px; cursor: pointer; overflow: hidden;"
          @click="openFile"
        >
          <v-overlay absolute style="opacity: 0.8" :value="loading">
            <v-progress-circular
                indeterminate
                color="primary"
                :size="50"
            ></v-progress-circular>
          </v-overlay>
          <template v-if="isImage">
            <v-img
              :src="filePreview"
              alt="Pré-visualização da imagem"
              contain
              class="image-preview"
            ></v-img>
          </template>
          <template v-else-if="isPdf">
            <v-icon size="48" color="red">mdi-file-pdf-box</v-icon>
          </template>
          <template v-else>
            <v-icon size="48">mdi-plus</v-icon>
          </template>
        </v-card>
      </v-col>
    </v-row>

    <!-- Input de arquivo oculto -->
    <input
      type="file"
      ref="fileInput"
      style="display: none;"
      :accept="computedAccept"
      @change="handleFileUpload"
    />
    <samp style="font-size: 12px;"> {{ fileName }}</samp>
    <!-- Botão para limpar arquivo -->
    
  </v-container>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Adicione um arquivo", // Título padrão
    },
    loading: {
      type: Boolean,
      default: false, // Título padrão
    },
    showFile: {
      type: [Object, Blob], // Aceita tanto Object quanto Blob
      default: () => ({}), // Valor padrão
    },
    fileTypes: {
      type: Array,
      default: () => [], 
    },
  },
  data() {
    return {
      file: null,
      filePreview: null, 
      fileUrl: null
    };
  },
  computed: {
    computedAccept() {
      const accept = [];
      if (this.fileTypes.includes("image")) accept.push("image/*");
      if (this.fileTypes.includes("pdf")) accept.push(".pdf");
      return accept.join(",");
    },
    isImage() {
      return this.file && this.file.type.startsWith("image/");
    },
    fileName() {
      return this.file && this.file.name? this.file.name : "";
    },
    isPdf() {
      return this.file && this.file.type === "application/pdf";
    },
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;
      this.file = file;
      this.fileUrl = URL.createObjectURL(file);

      if (this.isImage) {
        this.filePreview = URL.createObjectURL(file);
      } else if (this.isPdf) {
        this.filePreview = null; 
      } else {
        alert("Tipo de arquivo inválido.");
        this.clearFile();
      }
      this.$emit("change:file",this.file)
    },
    clearFile() {
      this.file = null;
      this.fileUrl = null;
      this.filePreview = null;
      if(this.$refs.fileInput) this.$refs.fileInput.value = null;
      this.$emit("change:file",this.file)
    },
    openFile() {
      if (this.fileUrl) {
        window.open(this.fileUrl, "_blank");
      } else {
        this.triggerFileInput(); 
      }
    },
    async urlToFile(url, fileName) {
      try {
        // Busca o arquivo através do fetch
        const response = await fetch(url);
        console.log(response)
        const blob = await response.blob();

        // Converte o blob em um objeto File
        const file = new File([blob], fileName, { type: blob.type });

        return file;
      } catch (error) {
        console.error("Erro ao converter URL para File:", error);
        throw error;
      }
    }
  },
  watch:{
    showFile(newValue){
      if (!(newValue instanceof Blob || newValue instanceof File)) {
        this.clearFile();
        return;
      }
      this.file = newValue;
      this.fileUrl = URL.createObjectURL(newValue);

      if (this.isImage) {
        this.filePreview = URL.createObjectURL(newValue);
      } else if (this.isPdf) {
        this.filePreview = null; 
      } else {
        alert("Tipo de arquivo inválido.");
        this.clearFile();
      }
      this.$emit("change:file",this.file)
    }
  },  
  created(){
    this.clearFile()
  }
};
</script>

<style scoped>
.image-preview {
  object-fit: contain; 
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}
</style>