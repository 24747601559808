<template>
  <div>
    <v-autocomplete dense v-model="selected" :items="list" :label="label" item-text="name" item-value="id" outlined
      hide-details :loading="isLoading" :disabled="isDisabled">
    </v-autocomplete>
  </div>
</template>

<script>
import UnitsService from "@/services/server/UnitsService";
export default {
  data: () => ({
    label: "Unidades",
    list: [],
    selected: null,
    client_id: null,
    isLoadingData: false,
  }),

  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    clientId: {
      default: null,
      type: String,
    },
    value: {
      default: null,
      type: String,
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },

  computed: {
    isLoading() {
      return this.isLoadingData || this.loading;
    },
    isDisabled() {
      return this.disabled || this.isLoading || this.list.length == 0;
    },
  },

  methods: {},

  watch: {
    selected(id) {
      if (id) this.$emit("id", id);
    },
    async clientId(newValue) {
      this.list = [];
      this.isLoadingData = true;
      this.selected = null
      this.$emit("id", null);
      if (typeof newValue === "string") {
        this.isLoadingData = true;
        this.client_id = newValue;
        const response = await UnitsService.findAllByClient(this.client_id)
        this.list = response.data.data
        if (this.list.length === 1) this.selected = this.list[0].id
        this.isLoadingData = false;
      } else {
        this.list = [];
        this.client_id = null;
      }
      this.isLoadingData = false;
    },
    async value(newValue) {
      this.selected = newValue
    },
  },
  async created() {

  },
};
</script>


<style></style>