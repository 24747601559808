<template>
  <v-container fluid>
    <v-row class="dashboardData">
      <v-col cols="12">
        <v-card class="rounded-xl">
          <v-card-title class="title-displays"
            >{{ title }}</v-card-title
          >
          <v-card-title>
            <v-row>
              <v-col cols="12" sm="4">
                <select-franchisee :loading="loading_prop" @id="catch_franchisee_id" />
              </v-col>
              <v-col cols="12" sm="4">
                <select-client
                  :loading="loading_prop"
                  @id="catch_client_id"
                  :reset="reset_client"
                  :franchisee-id="franchisee_id"
                  :disabled="loading.main_table"
                  :value="client_id"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <select-unit
                  :loading="loading_prop"
                  @id="catch_unit_id"
                  :client-id="client_id"
                  :disabled="loading.main_table"
                  :value="unit_id"
                />
              </v-col>
              <v-dialog
                v-model="startDateDialog"
                max-width="338px"
                @click="startDateDialog = !startDateDialog"
              >
                <v-card>
                  <v-card-title>
                    <span>Intervalo de Datas</span>
                    <v-spacer></v-spacer>
                  </v-card-title>
                  <v-card-text>
                     <v-date-picker
                      class="date-picker-height"
                      v-model="startDatePicker"
                      :type="calendar_type"
                      locale="pt-br"
                      :max="maxStartDate"
                      @change="(newDate) => selectDateCalendar(newDate)"
                      range
                    ></v-date-picker>                  
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" text @click="startDateDialog = !startDateDialog">
                      Fechar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
             <!-- @change="(newDate) => selectDateCalendar(newDate)" -->           
              <v-col cols="8" sm="4">
                <v-row>
                  <v-col cols="2">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          big
                          color="primary"
                          @click="startDateCalendar()"
                          >mdi-calendar</v-icon
                        >
                      </template>
                      <span
                        >Selecionar Data
                       </span
                      >
                    </v-tooltip>
                  </v-col>
                  <v-col cols="10">
                    <v-btn
                      width="100%"
                      color="primary"
                      style="font-weight: bold"
                      outlined
                      @click="resetSelects()"
                      :disabled="loading.main_table"
                    >
                      Limpar
                    </v-btn>                    
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="4" style="margin: 14px;color: #1976d2;" >
                Período: {{  SelectDateFormat  }} 
                               
              </v-col>
              <v-spacer> </v-spacer>

              <v-col v-if="showGeneralButtonPdf" cols="12" sm="2" style="margin: 10px 0px 10px 0px;display: flex;justify-content: end">
                <v-btn :disabled="disabledGeneralButtonPdf" color="primary"
                  style="
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-weight: bold;
                    width: 100%;" 
                  outlined 
                  @click="generatePdfComponent(null,'general')"
                  :loading="loading.general_button_pdf">
                  <v-icon small class="mr-2">
                    mdi-file-document-arrow-right-outline
                  </v-icon>
                  {{ general_button_pdf_name }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-actions class="text-center" style="
                            border-style: solid;
                            border-width: 2px 0px 2px 0px;
                            border-color: #dddddd;
      
                          ">
                <slot name="summary-header"></slot>
          </v-card-actions>
          <v-data-table
            :headers="main_headers"
            :items="processedItems"
            :single-expand="true"
            :expanded.sync="expanded"
            item-key="uniqueKey"
            :show-expand="show_expand_prop"
            @item-expanded="onExpand"
            :loading="loading.main_table"
             @click:row="clickTableRow"
          >
          <template v-slot:item.actions="{ item }">
            <slot name="button-main-table" 
              :onClick="generatePdfComponent" 
              :prop="item"
              :disabled="disabledMainButtonPdf"
              :loading="activeLoadingMainButtonPdf"
              :status="null"
               ></slot>
              <v-btn
                v-if="activate_pdf_button"
                color="primary"
                style="font-weight: bold"
                outlined
                :disabled="disabledMainButtonPdf(item)"
                :loading="activeLoadingMainButtonPdf(item)"
                @click="generatePdfComponent(item)"
              >
                <v-icon small class="mr-2">
                  mdi-file-document-arrow-right-outline
                </v-icon>
                PDF
              </v-btn>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" style="padding: 10px">
                <v-data-table
                  :headers="secondary_headers"
                  :items="data_table_expand_prop"
                  :single-expand="true"
                  item-key="index"
                  class="elevation-1"
                  :loading="loading.secondary_table"
                >
                  <template v-slot:item.actions="{ item }">
                    <slot name="button-main-table" 
                    :onClick="generatePdfComponent" 
                    :prop="item"
                    :disabled="disabledMainButtonPdf"
                    :loading="activeLoadingMainButtonPdf"
                    :status="'exapand'"
                    ></slot>
                    <v-btn
                      v-if="activate_pdf_button"
                      color="primary"
                      style="font-weight: bold"
                      outlined
                      :disabled="disabledSecondaryButtonPdf(item)"
                      :loading="activeLoadingSecondaryButtonPdf(item)"
                      @click="generatePdfComponent(item, 'exapand')"
                    >
                      <v-icon small class="mr-2">
                        mdi-file-document-arrow-right-outline
                      </v-icon>
                      PDF
                    </v-btn>
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ReportScript from "./ReportScript";
export default ReportScript;
</script>

<style >
</style>