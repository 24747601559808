<template>
    <div>
      <v-row style="justify-content: space-between;">
          <h3>Ordens de Serviços</h3>
          <v-btn 
            color="primary" 
            text
            @click="pickFile()"
            elevation="2"
            style="height: 30px; margin: 2px;background-color: #1976d2;"
          >
            <v-icon style="color: white;">add</v-icon>
          </v-btn>
              <input 
                  multiple 
                  type="file" 
                  id="get_file" 
                  ref="get_file" 
                  accept="application/pdf"
                  style="display: none"
                  @input="onFilePicked" 
              />
      </v-row>
      <v-row>
        <v-data-table
          :headers="headers"
          :items="getFileList"
          class="elevation-1"
          style="width: 100%"
          >
          <template v-slot:body="{ items }">
              <tbody>
                  <tr v-for="(file, index) in items" :key="index">
                      <td>
                        <v-text-field   
                          :value="file.filename"
                          readonly
                        />
                        </td>
                      <td>
                        <v-text-field
                          readonly
                          :value="file.filetype"
                        />
                      </td>
                      <td>
                        <v-text-field
                          readonly
                          :value="file.fileLastModified"
                        />
                      </td>
                      <td>
                        <v-btn
                            elevation="2"
                            @click="() => downloadFile(file)"
                            style="height: 30px; margin: 2px"
                            :disabled="file.isNew"
                        >
                            <v-icon color="primary" small> mdi-download-box </v-icon>
                        </v-btn>
                        <v-btn
                            elevation="2"
                            @click="() => openAlertRemoveFile(file.index)"
                            style="height: 30px; margin: 2px"
                        >
                            <v-icon color="primary" small> mdi-delete </v-icon>
                        </v-btn>
                      </td>
                  </tr>
              </tbody>
          </template>
        </v-data-table>
      </v-row>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import Rules from "@/utils/Rules";
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper";
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import ObjectValidator from '@/scripts/ObjectValidator';
import Utils from '@/scripts/Utils';
import LocalField from "@/components/Waterfix/Fields/LocalField.vue";
import SublocalField from "@/components/Waterfix/Fields/SublocalField.vue";
import VueHorizontal from 'vue-horizontal';
import WaterFixStorage from '@/services/storage/WaterFixStorage';

export default {
  components: {
    LocalField,
    SublocalField,
    VueHorizontal,
  },
  props: {
    fileList: {
      type: Array,
      required: true,
    },
    channel: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    download: {
      type: Function,
      required: true,
    }
  },

  data: () => ({
    headers: [
      { text: "Nome", value: "filename", sortable: true },
      { text: "Tipo", value: "filetype", width: 80, sortable: true },
      { text: "Modificação", value: "fileLastModified", width: 200, sortable: false },
      { text: "Ações", value: "action", sortable: false },
    ],
    list: [],
    img: {
        image: null,
        imageName: null,
    },
    acceptFiles: ['pdf'],
  }),
  methods: {
    ...mapActions([
        'publishChannel',
    ]),
    async downloadFile(file) {
      let url = await this.download(file.filepath)
      window.open(url, "_blank")
    },
    pickFile() {
      this.$refs.get_file.click();
    },
    checkTypeFile(type) {
      return type.match(/^application\s*\/\s*pdf$/) !== null
    },
    checkHasFileName(name) {
      return name.match(/[^\.]+\./) !== null
    },
    async onFilePicked(e) {
      const inputFiles = [...document.getElementById("get_file").files]
      let isAllChecked = inputFiles.every(
        (file) => {
          return this.checkTypeFile(file.type)
        }
      )
      if (!isAllChecked) {
        showErrorSnackBar("Um ou mais tipo de arquivo inválido.")
      } else {
        let isAllNameChecked = inputFiles.every(
          (file) => {
            return this.checkHasFileName(file.name)
          }
        )
        if (!isAllNameChecked) {
          showErrorSnackBar("Um ou mais nome de arquivo inválido.")
        } else {
          // Verificar se ja tem algum arquivo com o mesmo nome
          let allNewNames = inputFiles.map(({name}) => name)
          let isAllDifferentName = !this.list.some(
            (file) => {
              if (file.isRemoved) return false
              return allNewNames.includes(file.name)
            }
          )
          if (!isAllDifferentName) {
            showErrorSnackBar("Um ou mais arquivo já anexado.")
          } else {
            let lastIndex = this.list.length - 1
            inputFiles.forEach(
              (file) => {
                lastIndex++
                this.list.push({
                  file: null,
                  name: file.name,
                  size: file.size,
                  type: file.type,
                  lastModified: new Date(file.lastModified),
                  // -----------------------------------------
                  index: lastIndex,
                  isNew: true,
                  isRemoved: false,
                  rawFile: file,
                })
              }
            )
            this.newFilesEvent()
          }
        }
      }
    },
    separateByFilename() {
      let files = {}
      this.list.forEach(
        (file) => {
          let name = file.name
          if (!files.hasOwnProperty(name)) {
            files[name] = []
          }
          files[name].push(file)
        }
      )
      return files
    },
    newFilesEvent() {
      let files = this.separateByFilename()
      let newFiles = []
      Object.keys(files).forEach(
        (filename) => {
          let file = files[filename].pop()
          if (file.isRemoved) return false
          if (!file.isNew) return false
          newFiles.push({
            file: file.file,
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: new Date(file.lastModified),
            rawFile: file.rawFile
          })
        }
      )
      if (newFiles.length > 0) {
        this.publishChannel({
          channel: `waterfix`,
          event: `${this.name}:new`,
          val: newFiles,
        })
      }
    },
    removeFilesEvent() {
      let files = this.separateByFilename()
      let removeFiles = []
      Object.keys(files).forEach(
        (filename) => {
          let file = files[filename].pop()
          if (file.isNew) return false
          if (!file.isRemoved) return false
          removeFiles.push({
            file: file.file,
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: new Date(file.lastModified),
            rawFile: file.rawFile
          })
        }
      )
      if (removeFiles.length > 0) {
        this.publishChannel({
          channel: `waterfix`,
          event: `${this.name}:remove`,
          val: removeFiles,
        })
      }
    },
    openAlertRemoveFile(index) {
      showDeleteDialogAlert(`Deseja excluir o arquivo ${this.list[index].name}`, () => this.removeFile(index))
    },
    removeFile(index) {
      this.list = this.list.map(
        (file) => {
          if (file.index === index) {
            return {
              ...file,
              isRemoved: true,
            }
          } else {
            return file
          }
        }
      )
      this.removeFilesEvent()
    },
    parseFileList(fileList) {
      if (fileList) {
          this.list = fileList.map((file, index) => ({...file, index, isNew: false, isRemoved: false}))
      }
    }
  },
  computed: {
    getFileList() {
      return this.list.map(
        (file) => {
          if (file.isRemoved) return null
          let filepath = file.file
          let filename = ''
          let filetype = ''
          let fileMatch = file.name.match(/(.*)\.([^\.]+)/)
          if (fileMatch) {
            filename = fileMatch[1]
            filetype = fileMatch[2].toUpperCase()
          }
          let filesize = Utils.formatNumber(file.size/1024)
          let fileLastModified = Utils.formatDate(file.lastModified, "<dd>/<MM>/<YYYY> às <hh>:<mm>")
          return {
            index: file.index,
            filepath,
            filename,
            filetype,
            fileLastModified,
            isNew: file.isNew,
            isRemoved: file.isRemoved,
          }
        }
      ).filter(
        (file) => file !== null
      )
    }
  },
  watch: {
    fileList(newVal) {
      this.parseFileList(newVal)
    },
  },
  async created() {
    this.parseFileList(this.fileList)
  }
};
</script>