import { mapActions,mapGetters } from "vuex";
import firebase from "@/firebase"
import AlertsService from "@/services/firebase/Alerts/AlertsService";
import AlertApi from "@/services/apiPdf/AlertApi";
import EmailService from "@/services/email/Email";
import DialogSendEmail from "@/components/DialogSendEmail/DialogSendEmail";
import { filterAssociatedUnit } from "@/utils/AssocietedUnitsFilter"
import FormReport from "@/components/Forms/Report/Report.vue";

import { showErrorSnackBar,showSuccessSnackBar } from "@/utils/SnackBartHelper"
import Utils from '@/scripts/Utils';

export default {
  components: {
    FormReport,
    DialogSendEmail
  },
  data() {
    return {
      show_expand: true,
      main_headers: [
        {
          text: 'Cliente',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Alertas.', value: 'amount', align: 'center' },
        { text: 'Falta de Sinal', value: 'lack_of_signal', align: 'center' },
        { text: 'Bateria Fraca', value: 'low_battery', align: 'center' },
        { text: 'Sem Variação', value: 'no_variation', align: 'center' },
        { text: 'Sem Decodificação', value: 'no_decodification', align: 'center' },
        { text: 'Ações', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      secondary_headers: [
        {
          text: 'Unidade',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Alertas', value: 'amount', align: 'center' },
        { text: 'Falta de Sinal', value: 'lack_of_signal', align: 'center' },
        { text: 'Bateria Fraca', value: 'low_battery', align: 'center' },
        { text: 'Sem Variação', value: 'no_variation', align: 'center' },
        { text: 'Sem Decodificação', value: 'no_decodification', align: 'center' },
        { text: 'Ações', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      franchisee_id: null,
      client_id: null,
      unit_id: null,
      sector_id: null,
      startDatePicker: [Utils.formatDate(Utils.generateLastDays(15)[14], "<YYYY>-<MM>-<dd>"), Utils.formatDate(Utils.generateLastDays(15)[0], "<YYYY>-<MM>-<dd>")],
      select_unit: [],
      select_unit_adress: null,
      select_sector_name: null,
      titles_button_download: [{ title: "PDF" },{ title: "Email" }],

      date_format: null,
      reset_client: true,
      last_type_selected: null,
      last_serach_alerts: null,
      data_table: [],
      data_table_expand: [],
      data_table_filter_days: [],
      summary: {
        amount: 0,
        lack_of_signal: 0,
        low_battery: 0,
        no_variation: 0,
        no_decodification: 0,
      },
      desabled: {
        search_button: true,
        report_view: false
      },
      startDateDialog: false,
      maxStartDate: '',
      notification: {
        open: false,
        text: ""
      },
      dialog_send_email: false,
      data_body_email: false,
      report_type: '',
      alerts_raw: [],
    }
  },
  methods: {
    ...mapActions(["getClientsBy", "getUnitsBy"]),
    async catch_report_tipe(type, func, prop, status) {
      this.report_type = type
      func(prop, status)
    },
    async catch_id(data_id, onExpand = false) {
      if(!onExpand) this.summary = {
        amount: 0,
        lack_of_signal: 0,
        low_battery: 0,
        no_variation: 0,
        no_decodification: 0
      }
      const id = data_id.id

      if (!onExpand) {
        this.data_table = []
        this.last_serach_alerts = data_id
        this.last_type_selected = data_id.type
      } else {
        this.data_table_expand = []
      }
      this.show_expand = true
      let alerts = []
      let alertsListTable = []

      try {
        switch (data_id.type) {
          case "franchisee":
            this.franchisee_id = id;
            if (!onExpand) this.setHeaders("client")
            this.alerts_raw = await this.searchAlertList("franchisee_id", this.franchisee_id)
            alertsListTable = await this.filterAlerts(this.alerts_raw, "client_id")
            await this.getClientsName(this.franchisee_id, alertsListTable)
            break;
          case "client":
            this.client_id = id;
            if (!onExpand) this.setHeaders("unit")
            alerts = this.alerts_raw.filter((alert)=> alert.client_id === this.client_id)
            alertsListTable = await this.filterAlerts(alerts, "unit_id")
            await this.getUnitsName(this.client_id, alertsListTable)
            alertsListTable = await filterAssociatedUnit(alertsListTable)

            break;
          case "unit":
            if (!onExpand) this.setHeaders("sector")
            if (this.last_type_selected === "unit") this.show_expand = false
            this.unit_id = id;
            alerts = this.alerts_raw.filter((alert)=> alert.unit_id === this.unit_id)
            alertsListTable = await this.filterAlerts(alerts, "modem_id")
            // await this.getSectoresName(this.unit_id, alertsListTable)
            break;
          default:
            alert("Tipo não identificado entre em contato com o suporte!")
            break;
        }
        await this.sumValuesTable(alertsListTable)
        if(!onExpand) await this.summarySum(alertsListTable)
      } catch {
        alert("Erro não identificado entre em contato com o suporte!")
      }
      if (alertsListTable.length === 0) showErrorSnackBar("Não existe alertas para o item selecionado!")
      !onExpand ? this.data_table = alertsListTable : this.data_table_expand = alertsListTable
    },
    async filterAlerts(AlertsData, entity) {
      let list = []
      if (AlertsData.length > 0) {
        AlertsData.map(alert => {

          let resultSearchName = list.map(i => {
            return i.name === alert[entity]
          }).find(resultMap => resultMap === true)

          if (!resultSearchName) {
            list.push({
              name: alert[entity],
              size: 1,
              alerts: [alert]
            })
          } else {
            list.map((n) => {
              if (n.name === alert[entity]) {
                n.alerts = [...n.alerts, alert]
                n.size += 1
              }
            })
          }
        })
      }
      return list
    },
    async sumValuesTable(data) {
      data.map((generalAlert, index) => {
        let amount = 0
        let lack_of_signal = 0
        let low_battery = 0
        let no_variation = 0
        let no_decodification = 0
        generalAlert.alerts.map((alert) => {
          amount += 1
          switch (alert.type) {
            case "lack_of_signal":
              lack_of_signal += 1
              break;
            case "low_battery":
              low_battery += 1
              break;
            case "no_variation":
              no_variation += 1
              break;
            case "no_decodification":
              no_decodification += 1
              break;
          }
        })
        data[index] = {
          ...data[index],
          amount,
          lack_of_signal,
          low_battery,
          no_variation,
          no_decodification,
          index
        }
      })
    },
    async sendEmail(receiver, message) {
      try {
        if (!this.data_body_email) throw "Body não definido!"
        const name = this.data_body_email.name
        const user_email = receiver
        const relatorio = "Alertas"
        const data = {
          id: this.data_body_email.data.id,
          currentUser: this.data_body_email.data.currentUser,
          rank: this.data_body_email.data.rank,
          report_type: 'PDF',
          type: this.data_body_email.data.type
        }
        const date_time = this.data_body_email.startDatePicker
        const file_name = this.data_body_email.name.split(" ").join("_")
        const endpoint = "/report/alert"
        
        await EmailService.sendEmail({
          name,
          user_email,
          relatorio,
          data,
          date_time,
          file_name,
          endpoint
        })

        showSuccessSnackBar("Email enviado!")
      } catch (err) {
        console.error(err)
        showErrorSnackBar("Erro ao enviar email!")
      }
      this.data_body_email = null
    },
    async getClientsName(franchiseeId, data) {
      let clients = await this.getClientsBy({
        by: "franqueado",
        id: franchiseeId,
      });
      data.map(({ name }, i) => {
        let client_data = clients.filter(({ id }) => name === id)
        data[i].id = data[i].name
        data[i].name = client_data[0].data.name
      })
    },
    async getUnitsName(clientId, data) {
      let units = await this.getUnitsBy({
        by: "cliente",
        id: clientId,
      });
      data.map(({ name }, i) => {
        let unit_data = units.filter(({ id }) => name === id)
        if(unit_data.length > 0){
          data[i].id = data[i].name
          data[i].name = unit_data[0].data.name
        }
      })
    },
    async getSectoresName(unitId, data) {
      let list = []
      let { sectors } = await firebase.getUnitbyId(unitId);

      if (sectors && sectors.length > 0) {
        list = sectors.map((i) => {
          if (i.name && i.modem) return { name: i.name, id: i.modem.id };
        });

        data.map(({ name }, i) => {
          let sector_data = list.filter(({ id }) => name === id)
          data[i].id = data[i].name
          data[i].name = sector_data[0].name
        })

      } else {
        delete data[0]
        showErrorSnackBar("A Unidade não possui setores vinculados!")
      }
    },
    async summarySum(data) {
      this.summary = {
        amount: 0,
        lack_of_signal: 0,
        low_battery: 0,
        no_variation: 0,
        no_decodification: 0,
      }
      data.map((line) => {
        this.summary.amount += line.amount
        this.summary.lack_of_signal += line.lack_of_signal
        this.summary.low_battery += line.low_battery
        this.summary.no_variation += line.no_variation
        this.summary.no_decodification += line.no_decodification
      })
    },
    formatDateTimestamp() {
      let date = this.startDatePicker.map(date => {
        return (new Date(date).getTime())
      })
      if (date[0] > date[1]) {
        date = [
          date[1],
          date[0]
        ]
      }
      date = [
        new Date(date[0] + 10800000), // 00:00
        new Date(date[1] + 86400000 + 10799999), // 23:59:59
      ]
      this.startDatePicker
      return date
    },
    async onExpand({ item, value }) {
      let type = null
      switch (this.last_type_selected) {
        case "franchisee":
          type = "client"
          break;
        case "client":
          type = "unit"
          break;
      }
      await this.catch_id({ id: item.id, type }, true)
    },
    async getAlertsPerData(selectDate) {
      this.startDatePicker = selectDate
      if (this.last_serach_alerts) {
        if(this.last_serach_alerts.type === "franchisee") await this.catch_id(this.last_serach_alerts, false)
        else{
          this.alerts_raw = await AlertsService.getAlertsPayloadTelemetryAndEntity("franchisee_id",this.franchisee_id,this.formatDateTimestamp())
          await this.catch_id(this.last_serach_alerts, false)
        }
        
      }
    },
    async searchAlertList(entity, id = null) {
      let alerts = await AlertsService.getAlertsPayloadTelemetryAndEntityWithoutView(entity, id, this.formatDateTimestamp())
      return alerts
    },
    setHeaders(action) {
      switch (action) {
        case 'client':
          this.main_headers['0'].text = 'Cliente'
          this.secondary_headers['0'].text = 'Unidade'
          break;
        case 'unit':
          this.main_headers['0'].text = 'Unidade'
          this.secondary_headers['0'].text = 'Modem'
          break;
        case 'sector':
          this.main_headers['0'].text = 'Modem'
          break;
      }
    },
    async generatePdf(item, status = null) {
      // let data = {}
      // data.id = item.id
      // data.currentUser = this.getCurrentUser.uid
      // data.rank = this.getCurrentUser.data.rank
      // switch (this.last_serach_alerts.type) {
      //   case "franchisee":
      //     data.type = status ? "unit_id" : "client_id"
      //     break;
      //   case "client":
      //     data.type = status ? "modem_id" : "unit_id"
      //     if(item.entity) data.type = "client_id"
      //     break;
      //   case "unit":
      //     data.type = "modem_id"
      //     if(item.entity) data.type = "unit_id"
      //     break;
      //   default:
      //     console.error("type error!")
      // }
      // if (this.report_type === "Email") {
      //   this.dialog_send_email = !this.dialog_send_email
      //   this.data_body_email = {data, startDatePicker: this.startDatePicker,name: item.name || await this.getClientName(item) }
      // } else {
      //   let response = await AlertApi.generatePdf(data, this.startDatePicker)
      //   response.data && response.data.success == true ? await AlertApi.downloadPdf(this.orderStartDate()[0] + "_" + this.orderStartDate()[1] + " " + item.name || await this.getClientName(item)) : showErrorSnackBar("Erro ao gerar pdf!")
      // }
    },
    async getName(item){      
      let data = []
      if(item.entity === "client") data = await firebase.getClientById(item.id)
      else data = await firebase.getUnitbyId(item.id)
      return data.name
    }
  },
  computed: {
    ...mapGetters([
      "getCurrentUser"
    ])
  }
}