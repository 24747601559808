<template>
    <div v-if="show">
        <v-form ref="form" v-model="validForm" lazy-validation>
            <v-col>
                <HeaderCheckList
                    title="Bombas"
                    :click="() => create()"
                />
            </v-col>
            <div v-for="_bomb, i in list">
                <CardCheckList
                    :view="() => edit(i)"
                    :remove="() => remove(i)"
                >
                    <template v-slot:content>
                        <div style="
                            padding: 10px;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-around;
                        ">
                            <div>
                                <h3 style="
                                    font-size: 15px;
                                    color: black;
                                    text-align: center;
                                ">{{ _bomb.localBombas }}</h3>
                            </div>
                            <div>
                                <h4 style="
                                    font-size: 12px;
                                    color: black;
                                    text-align: center;
                                ">{{ _bomb.reservatorioBombas }} / {{ _bomb.potencia }} W</h4>
                            </div>
                            <div>
                                <h5 style="
                                    font-size: 12px;
                                    color: black;
                                    text-align: center;
                                ">{{ _bomb.horasUso }} H/Dia / {{ _bomb.kWH }} kWH</h5>
                            </div>
                        </div>
                    </template>
                </CardCheckList>
            </div>
            <v-dialog transition="dialog-top-transition" v-model="dialog" v-if="dialog" persistent max-width="600px">
                <v-card>
                    <v-toolbar color="primary" dark> <span class="text-h5">Adicionar Bomba</span></v-toolbar>
                    <v-card-title>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text style="padding-bottom: 0px;">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field 
                                    v-model="bomb.localBombas" 
                                    outlined 
                                    label="Local" 
                                    type="text" 
                                    required 
                                    :rules="rulesRequired"
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-select 
                                    v-model="bomb.reservatorioBombas"
                                    outlined 
                                    label="Reservatorio" 
                                    :items="optionsBomb"
                                    prepend-inner-icon="mdi-cup-water" 
                                    required 
                                    :rules="rulesRequired"
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field 
                                    outlined 
                                    label="Potênca" 
                                    prefix="W" 
                                    type="Number" 
                                    v-model="bomb.potencia"
                                    prepend-inner-icon="mdi-lightning-bolt-outline" 
                                    required 
                                    :rules="rulesRequired"
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field 
                                    v-model="bomb.horasUso" 
                                    outlined 
                                    label="Horas Uso/Dia" 
                                    type="Number"
                                    prepend-inner-icon="mdi-clock-time-five-outline" 
                                    required 
                                    :rules="rulesNumber"
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field 
                                    v-model="bomb.kWH" 
                                    outlined 
                                    label="Valor do Kw/h" 
                                    prefix="R$ " 
                                    type="Number" 
                                    required
                                    :rules="rulesRequired"
                                />
                            </v-col>
                        </v-row>
                        <!-- <InputImg v-on:rawFiles="rawFiles = $event"/> -->
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDialog()">
                        Cancelar
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="save()"> Salvar </v-btn>
                    </v-card-actions>
                </v-card>
              </v-dialog>
        </v-form>
    </div>
</template>
  
<script>
import HeaderCheckList from "@/components/Projects/Diagnostic/Tabs/CheckList/HeaderCheckList.vue"
import CardCheckList from "@/components/Projects/Diagnostic/Tabs/CheckList/CardCheckList.vue"

import Rules from "@/utils/Rules";
import Utils from '@/scripts/Utils';

const newBomb = () => {
    return {
        horasUso: "",
        kWH: "",
        localBombas: "",
        potencia: "",
        reservatorioBombas: "",
    }
}

export default {
    name: "HydrometerList",
    
    components: {
        HeaderCheckList,
        CardCheckList,
    },
    
    props: {
        show: {
            type: Boolean,
            default: true
        },
        list: {
            type: Array,
            default: () => ([]),
        },
        add: {
            type: Function,
            default: (hydrometer, index) => {}
        },
        remove: {
            type: Function,
            default: (index) => {}
        },
    },
    
    data: () => ({
        validForm: true,
        dialog: false,
        index: -1,
        bomb: newBomb(),
        optionsBomb: ["Poço", "Recalque"],
    }),

    computed: {
        rulesRequired() {
            return Rules.required();
        },
        rulesNumber() {
            return Rules.number(true, 0, 24);
        },
    },

    methods: {
        create() {
            this.index = -1
            this.bomb = newBomb()
            this.dialog = true
        },
        edit(index) {
            this.index = index
            this.bomb = Utils.copyObject(this.list[index])
            this.dialog = true
        },
        save() {
            this.add(Utils.copyObject(this.bomb), this.index)
            this.dialog = false
        },
        closeDialog() {
            this.dialog = false
        },
    },

    watch: {
        
    },

};
</script>
  
<style scoped>

</style>