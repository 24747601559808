<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" v-if="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Finalizar Chamado</span>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="6" md="4">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  label="Data Solução"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker class="date-picker-height" v-model="date" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-textarea
                label="Solução"
                v-model="solutionText"
                required
                auto-grow
                outlined
                rows="1"
                row-height="15"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Link Imagens"
                v-model="dataLink"
                required
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              v-for="item in checkBoxValue"
              :sm="item.value ? 6 : 4"
              :key="item.key"
            >
              <v-row>
                <v-col :cols="item.value ? 6 : 12">
                  <v-checkbox
                    style="overflow: hidden"
                    :label="item.text"
                    v-model="item.value"
                  >
                  </v-checkbox>
                </v-col>
                <v-col cols="6" v-if="item.value">
                  <v-text-field
                    :label="item.text"
                    v-model="item.total"
                    type="number"
                    required
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog()">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="save()"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: {
      default: false,
      type: Boolean,
    },
    saveFunc: {},
  },

  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    checkBoxValue: [
      { text: "Visita", key: "visit", value: false, total: '1' },
      { text: "Instalação", key: "installation", value: false, total: '1' },
      { text: "Reparo", key: "repair", value: false, total: '1' },
      { text: "Revisão", key: "revision", value: false, total: '1' },
      { text: "Garantia", key: "guarantee", value: false, total: '1' },
    ],
    solutionText: "",
    dataLink: "",
  }),
  methods: {
    closeDialog() {
      this.saveFunc(false, { dialog: false });
    },
    async mountResult(){
      let checkValue = this.checkBoxValue
      let result = {
        [checkValue[0].key]: checkValue[0].value? checkValue[0].total : '0',
        [checkValue[1].key]: checkValue[1].value? checkValue[1].total : '0',
        [checkValue[2].key]: checkValue[2].value? checkValue[2].total : '0',
        [checkValue[3].key]: checkValue[3].value? checkValue[3].total : '0',
        [checkValue[4].key]: checkValue[4].value? checkValue[4].total : '0'
      }
      return result
    },
    async save() {
      if (this.solutionText.length > 0){
        let detail = await this.mountResult()
        this.saveFunc(true, {
          dialog: false,
          text: this.solutionText,
          dataLink: this.dataLink,
          date: this.date,
          detailSolution:  detail,
        });
      
      }else{
        alert('Explique qual foi a solução!')
      }
    },
  },
};
</script>