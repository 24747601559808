import { db } from "@/firebase"
const project_sellers_ref = db.collection("project_sellers")

const createProjectSellers = async (data) => {
    try {
        const get_data = await project_sellers_ref.add({ ...data, deletedAt: null });
        await editProjectSellers(get_data.id, { ...data, id_doc: get_data.id })
        return get_data.id;
    } catch (error) {
        throw new Error("createProjectSellers Fail!" + error)
    }
}
const editProjectSellers = async (idDoc, data) => {
    try {
        await project_sellers_ref.doc(idDoc).update(data);
        return true;
    } catch (error) {
        throw new Error("editProjectSellers Fail!" + error)
    }
}
async function getProjectSeller() {
    let project_partner_list = []
    try {
        const unitsRef = await project_sellers_ref.where("deletedAt", "==", null).get()
        unitsRef.forEach((doc) => project_partner_list.push(doc.data()));
        return project_partner_list
    } catch (error) {
        console.error(error)
        throw new Error("getProjectSeller Fail!" + error)
    }
}
async function getProjectSellerByIdFranchisee(IdFranchise) {
    let project_partner_list = []
    try {
        const unitsRef = await project_sellers_ref
            .where("id_franchisee", "==", IdFranchise)
            .where("deletedAt", "==", null)
            .get()
        unitsRef.forEach((doc) => project_partner_list.push(doc.data()));
        return project_partner_list
    } catch (error) {
        console.error(error)
        throw new Error("getProjectSellerByIdFranchisee Fail!" + error)
    }
}
async function getProjectSellerById(idProjectPartner) {
    try {
        const projectPartner = await project_sellers_ref.doc(idProjectPartner).get()
        return projectPartner.data()
    } catch (error) {
        console.error(error)
        throw new Error("getProjectSellerById Fail!" + error)
    }
}
async function getProjectSellerByDocument(document) {
    let project_partner_list = []
    try {
        const unitsRef = await project_sellers_ref
            .where("document", "==", document)
            .get()

        unitsRef.forEach((doc) => project_partner_list.push(doc.data()));
        return project_partner_list.length ? project_partner_list[0] : null
    } catch (error) {
        console.error(error)
        throw new Error("getProjectSellerByDocument Fail!" + error)
    }
}

export default {
    getProjectSellerByIdFranchisee,
    getProjectSellerByDocument,
    getProjectSellerById,
    createProjectSellers,
    editProjectSellers,
    getProjectSeller,
};