<template>
  <div>

    <v-overlay absolute style="opacity: 0.6" :value="isLoadingCNPJ">
      <v-progress-circular indeterminate color="primary" :size="50"></v-progress-circular>
    </v-overlay>

    <v-row>
      <v-col cols="12" v-if="getCurrentUserRank === 'Super Admin'">
        <v-autocomplete 
          v-model="franchiseeSelected" :items="listFranchisees" item-text="name" item-value="id"
          label="Franqueado" dense outlined hide-details 
          :rules="rulesRequired"
          />
      </v-col>
      <v-col cols="12">
        <h3>Cliente:</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-autocomplete hide-no-data hide-selected item-text="cpfOrCnpj" item-value="id_doc" label="Cliente"
          placeholder="Procurar Cliente" prepend-icon="mdi-database-search" return-object v-model="searchCpfOrCnpj"
          :items="clientData" :filter="customFilter">
          <template v-slot:selection="{ item, index }">
            <span>{{ item.name + " - " + item.cpfOrCnpj }}</span>
          </template>
          <template v-slot:item="{ item, index }">
            <span>{{ item.name + " - " + item.cpfOrCnpj }}</span>
          </template>
          <template v-slot:append-outer>
            <v-slide-x-reverse-transition mode="out-in">
              <v-icon v-if="!clientExists || !clientData.length" v-text="'mdi-plus'" :color="'success'" @click="createClient"></v-icon>
              <v-icon v-if="searchCpfOrCnpj" v-text="'mdi-pencil'" :color="'blue'" @click="editClient"></v-icon>
            </v-slide-x-reverse-transition>
          </template>
        </v-autocomplete>
      </v-col>

    </v-row>
    <v-dialog v-model="modalNewClient" v-if="modalNewClient" max-width="800px">
      <v-form ref="form" v-model="validForm" lazy-validation>
        <v-card>
          <v-card-title class="primary lighten white--text"> Novo Cliente
            <v-spacer></v-spacer>
            <v-btn class="litte white--text" text @click="modalNewClient = false">
              <v-icon white>mdi-window-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text style="font-size: 1.2rem">

              <v-row>
                <v-col cols="12" xl="6" sm="6" md="6" lg="6">
                  <v-text-field id="teste" outlined label="Nome do Local" prepend-inner-icon="person"
                    v-model="editedClient.name" :rules="rulesName" counter="64" required>
                  </v-text-field>
                </v-col>
                <v-col cols="12" xl="6" sm="6" md="6" lg="6">
                  <CpfCnpjInput v-model="editedClient.cpfOrCnpj" />
                </v-col>
                <v-col cols="12" xl="6" sm="6" md="6" lg="6">
                  <v-text-field outlined prepend-inner-icon="alternate_email" v-model="editedClient.email"
                    color="primary" label="E-mail" required :rules="rulesEmail" counter="64"></v-text-field>
                </v-col>
                <v-col cols="12" xl="6" sm="6" md="6" lg="6">
                  <v-text-field label="Telefone / Celular" outlined v-mask="'(XX) XXXXXXXXX'"
                    v-model="editedClient.phone" :rules="rulesPhone" required></v-text-field>
                </v-col>
                <v-divider></v-divider>
                <v-col cols="12">
                  <h3>Endereço:</h3>
                </v-col>
                <v-col cols="12">
                  <AddressForm :adressInfo="editedClient" :address.sync="addressObj" />
                </v-col>
              </v-row>
              <!-- Inicio Endereço -->
              <v-row>
              </v-row>
              <!-- Fim Endereço -->
              <v-divider></v-divider>
              <!-- Inicio Informações do responsável -->
              <v-row>
                <v-col cols="12">
                  <h3>Informações do responsável:</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xl="6" sm="6" md="6" lg="6">
                  <v-text-field outlined label="Nome do responsável" prepend-inner-icon="person"
                    v-model="editedClient.responsible_name" :rules="rulesName" counter="64">
                  </v-text-field>
                </v-col>
                <v-col cols="12" xl="6" sm="6" md="6" lg="6">
                  <v-text-field outlined prepend-inner-icon="alternate_email" v-model="editedClient.responsible_email"
                    color="primary" label="E-mail" counter="64"></v-text-field>
                </v-col>
                <v-col cols="12" xl="6" sm="6" md="6" lg="6">
                  <v-text-field label="CPF" outlined v-mask="'###.###.###-##'"
                    v-model="editedClient.responsible_idNumber" ref="refCpf"></v-text-field>
                </v-col>
                <v-col cols="12" xl="6" sm="6" md="6" lg="6">
                  <v-text-field label="Telefone" outlined v-mask="'(XX) XXXXX-XXXX'"
                    v-model="editedClient.responsible_phone" :rules="rulesPhone"></v-text-field>
                </v-col>
              </v-row>
              <!-- Fim Informações do responsável -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="addClient" :loading="loadingSaveClient" text :disabled="!validForm" color="primary">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- Start DADOS DO CLIENTE -->
    <v-row class="dataCliente">
      <v-col cols="12" style="background: rgb(25, 118, 210); color: white; padding: 6px">
        <h3 style="text-align: center">DADOS DO CLIENTE</h3>
      </v-col>
      <v-col md="4" lg="4" xl="4" sm="4" cols="12">
        Nome/Razão: {{ clientDataScreen.name || "" }}
      </v-col>
      <v-col md="4" lg="4" xl="4" sm="4" cols="12">
        CPF/CNPJ: {{ clientDataScreen.cpfOrCnpj || "" }}
      </v-col>
      <v-col md="4" lg="4" xl="4" sm="4" cols="12">
        Endereço: {{ clientDataScreen.logradouro || "" }}
      </v-col>
      <v-col md="4" lg="4" xl="4" sm="4" cols="12">
        Bairro: {{ clientDataScreen.bairro || "" }}
      </v-col>
      <v-col md="4" lg="4" xl="4" sm="4" cols="12">
        Cidade: {{ clientDataScreen.cidade || "" }}
      </v-col>
      <v-col md="4" lg="4" xl="4" sm="4" cols="12">
        Estado: {{ clientDataScreen.uf || "" }}
      </v-col>
      <v-col md="4" lg="4" xl="4" sm="4" cols="12">
        CEP: {{ clientDataScreen.cep || "" }}
      </v-col>
      <v-col md="4" lg="4" xl="4" sm="4" cols="12">
        Fone celular: {{ clientDataScreen.phone || "" }}
      </v-col>
      <v-col md="4" lg="4" xl="4" sm="4" cols="12">
        e-mail: {{ clientDataScreen.email || "" }}
      </v-col>
    </v-row>

    <!-- End DADOS DO CLIENTE -->
    <v-divider></v-divider>
  </div>


</template>
<script>
import Rules from "@/utils/Rules";
import AddressForm from "../Address.vue";
import ObjectValidator from "../../../scripts/ObjectValidator";
import { jsonp } from "vue-jsonp";
import WaterScanService from '@/services/firebase/WaterScan/WaterScanService';
import LeadsService from '@/services/firebase/Leads/LeadsService';
import CpfCnpjInput from "@/components/Forms/Input/input-cpf-cnpj.vue";
import { mapGetters } from "vuex";
import UsersService from "@/services/firebase/Users/UsersService";

export default {
  props: {
    registration: {
      default: ""
    },
    idProposal: {
      default: ""
    },
    requestSave: {
      default: null,
    },
    viewOrEdit: {
      default: "create"
    },
    validFormProp: {
      default: () => { },
    },
    closeProposed: {
      default: () => { },
    },
  },
  name: "locationInformation",
  components: {
    AddressForm,
    CpfCnpjInput
  },
  data: function () {
    return {
      options: [
        { text: "Modelo Performance", value: "model_performance" },
        { text: "Venda do Projeto", value: "payback_sell" },
        { text: "Investimento", value: "investment" },
        { text: "Pré-Fixado", value: "pre_fixed" },
        { text: "Simplificada", value: "simplified" },
      ],
      clientData: [],
      loadingSaveClient: false,
      newClientData: {},
      searchCpfOrCnpj: '',
      editedClient: {
        bairro: '',
        cep: '',
        cidade: '',
        complemento: '',
        cpfOrCnpj: '',
        id_doc: '',
        logradouro: '',
        name: '',
        numero: '',
        phone: '',
        uf: ''
      },
      responseCheckRegister: null,
      validForm: true,
      modalNewClient: false,
      isLoadingCNPJ: false,
      lalala: null,
      clientObj: {},
      addressObj: {},
      valid: false,
      isPwsVisible: false,
      requestAdress: false,
      enrollObj: ObjectValidator.create("enroll"),
      proposalObject: {},
      savedProposal: false,
      searchQuery: '',
      clientExists: true,
      franchiseeSelected: null,
      listFranchisees: [],
    };
  },
  methods: {
    async addClient() {
      this.requestAdress = true
      if ((this.$refs.form) && !this.$refs.form.validate()) return;
      this.loadingSaveClient = true

      const newClient = {
        ...this.addressObj,
        ...this.editedClient,
      }
      if (!this.searchCpfOrCnpj.id_doc) {
        const clientId = await LeadsService.createLead(newClient)
        this.searchCpfOrCnpj = { ...newClient, id_doc: clientId }
        this.clientData.push({ ...newClient, id_doc: clientId })
      } else {
        await LeadsService.editLead(newClient.id_doc, newClient)
        this.searchCpfOrCnpj = { ...newClient }
      }

      this.loadingSaveClient = false
      this.modalNewClient = false
    },
    async createClient() {
      this.modalNewClient = true
      this.editedClient = { ...this.searchCpfOrCnpj }
    },
    async editClient() {
      this.modalNewClient = true
      this.editedClient = { ...this.searchCpfOrCnpj }
    },
    clearObject() {
      this.enrollObj = {
        manager: null,
        localeData: {
          address: {
            bairro: null,
            cep: null,
            cidade: null,
            complemento: null,
            logradouro: null,
            numero: null,
            uf: null,
          },
          idNumber: null,
          phone: null,
          idType: this.enrollObj.localeData.idType,
        },
        responsible: {
          idNumber: null,
          phone: null,
          idType: "CPF",
          email: null,
          name: null,
        },
      };
    },
    consultaCnpj() {
      if (this.enrollObj.localeData.idNumber !== null) {
        if (this.enrollObj.localeData.idNumber.length == 18) {
          this.isLoadingCNPJ = true;
          jsonp(
            "https://receitaws.com.br/v1/cnpj/" +
            this.enrollObj.localeData.idNumber
              .split(".")
              .join("")
              .split("/")
              .join("")
              .split("-")
              .join("")
          )
            .then((response) => {
              this.enrollObj.name = response.fantasia;
              this.enrollObj.localeData.phone = response.telefone;
              this.enrollObj.email = response.email;
              this.enrollObj.localeData["address"] = {
                logradouro: response.logradouro,
                numero: response.numero,
                complemento: response.complemento,
                bairro: response.bairro,
                cidade: response.municipio,
                cep: response.cep,
                uf: response.uf,
              };
              this.usersKey++;
              this.isLoadingCNPJ = false;
            })
            .catch((err) => {
              this.isLoadingCNPJ = false;
              console.error(err);
            });
        }
      }
    },
    async createProposalinFirebase(objSave) {
      if (!this.responseCheckRegister) {
        await WaterScanService.createProposal(objSave, this.idProposal)
          .then((reponse) => {
            this.proposalObject = reponse;
            this.savedProposal = true
            this.validFormProp(true, 0)
          })
          .catch((err) => {
            alert("Erro ao CRIAR proposta!");
          });
      } else {
        await WaterScanService.updateProposal(objSave, this.idProposal)
          .then(() => {
            this.validFormProp(true, 0)
          })
          .catch((err) => {
            console.error("LocationInformation - Erro ao ATUALIZAR proposta!" + err);
          });
      }
    },
    async resquetEvent(pag, pullStep = false) {

      if (pag != 0 || pullStep) {

        if (!(typeof this.searchCpfOrCnpj === "object" && this.searchCpfOrCnpj.name)) {
          this.validFormProp(false, 0)
          return false;
        }
        if (!this.savedProposal) {
          this.$emit("update:overley", true);
          let objSave = {
            id_franchisee: this.franchiseeSelected,
            id_lead: this.searchCpfOrCnpj.id_doc,
            proposed: this.responseCheckRegister?.proposed ?
              { ...this.responseCheckRegister.proposed, typeProposal: { ...this.responseCheckRegister.proposed.typeProposal, type: 'simplified' } }
              :
              { typeProposal: { type: 'simplified' } }
            ,
            locationInformation: { name: this.searchCpfOrCnpj.name },
            registration: this.registration,
          };
          await this.createProposalinFirebase(objSave);
        }
        if (pullStep) this.closeProposed();
      }
    },
    customFilter(item, queryText, itemText) {
      const text = queryText.toLowerCase();
      this.selectedClient(text)
      return (
        item.name.toLowerCase().includes(text) ||
        item.cpfOrCnpj.toLowerCase().includes(text)
      );
    },
    selectedClient(val) {
      const query = val.toLowerCase();
      this.clientExists = this.clientData.some(item => {
        return item.name.toLowerCase().includes(query) ||
          item.cpfOrCnpj.toLowerCase().includes(query)
      }
      );
    },
  },
  computed: {
    ...mapGetters(["getCurrentUserRank", "getCurrentUser", "getUnitByIDs", "getAssociados"]),
    clientDataScreen() {
      if (typeof this.searchCpfOrCnpj === "object") {
        return this.searchCpfOrCnpj.name ? this.searchCpfOrCnpj : false
      } else return false
    },
    rulesIdNumber() {
      return this.enrollObj["localeData"].idType === "CPF"
        ? Rules.cpf()
        : Rules.cnpj();
    },
    rulesIdNumberCpf() {
      return Rules.cpf();
    },
    rulesRequired() {
      return Rules.required();
    },
    rulesPhone() {
      return Rules.phone();
    },
    rulesEmail() {
      return Rules.email(true, 64);
    },
    rulesName() {
      return Rules.string(true, 1, 64);
    },

  },
  async created() {
    if (this.getCurrentUserRank === "Franqueado")this.franchiseeSelected = this.getCurrentUser.uid 
    else if (this.getCurrentUserRank === "Super Admin"){
      UsersService.getUsersByRank(2)
      .then((res) => {
        this.listFranchisees = res
      }).catch(() => { })
    }

    this.clientData = await LeadsService.getLeads()
    if (this.viewOrEdit === 'view') {
      this.$emit("update:overley", true);
      await WaterScanService.getProposalById(this.idProposal).then((response) => {
        this.responseCheckRegister = response;
        this.$emit("update:overley", false);
      })
        .catch((err) => {
          alert("Erro ao consultar proposta!");
          console.error(err);
        });

      if (this.responseCheckRegister) {
        this.enrollObj = this.responseCheckRegister.locationInformation
        this.clientData.find((lead) => lead.id_doc === this.responseCheckRegister.id_lead)
        if (this.responseCheckRegister.id_lead) this.searchCpfOrCnpj = this.clientData.find((lead) => lead.id_doc === this.responseCheckRegister.id_lead)
      }
    }

  },
};
</script>