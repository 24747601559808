<template>
  <v-container fluid>
    <!-- Exibe lista de usuário com as respectivas informações. -->
    <v-row>
      <v-col cols="12">
        <v-card class="rounded-xl">

          <v-toolbar flat color="white">
            <v-toolbar-title class="title-displays">Usuários</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="addNewUser" text color="primary" v-if="isEnabledAction('newUser')">
              Adicionar
              <v-icon>add</v-icon>
            </v-btn>
          </v-toolbar>

          <!-- Busca -->
          <v-row style="justify-content: space-between">
            <v-text-field style="width: 80%; padding: 1em" class="px-4" prepend-icon="search" label="Busca"
              v-model="searchText"></v-text-field>
            <v-select hint="Buscar nessa coluna" persistent-hint style="width: 20%; padding: 1em"
              v-model="columnToSearch" :items="columnsToChoose" return-object single-line></v-select>
          </v-row>
          <v-data-table :loading="loadingUsers" no-data-text="Sem usuários para exibir" :headers="headers"
            :items="searchedUsers" :key="usersKey">
            <template v-slot:item.rank="{ item }">
              {{ rankSlug(item.rank) }}
            </template>
            <template v-slot:item.manager="{ item }">
              {{ managerSlug(item) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <!-- Se usuário logado for:
                      Super Admin, pode editar tudo;
                      Admin, não pode editar usuários Super Admins
                      Outros, não pode editar usuários Admin e Super Admins. -->
              <v-icon small color="primary" class="mr-2" @click="editItem(item)" v-if="isEnabledAction('editUser')">
                mdi-pencil
              </v-icon>
              <v-icon small color="primary" @click="deleteItem(item)" v-if="isEnabledAction('deleteUser')">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <!-- Aqui é o modal para cadastro de usuário -->
    <v-dialog persistent max-width="1200" v-if="dialog" v-model="dialog">
      <v-card>
        <v-form v-model="validSignup" lazy-validation ref="signupForm">

          <v-card-title class="primary lighten white--text">{{
            formTitle
          }}

            <v-spacer></v-spacer>

            <v-btn class="litte white--text" text @click="close">

              <v-icon white>mdi-window-close</v-icon>

            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-col cols="12" />
            <!-- user infos -->
            <v-col sm="12" cols="12" style="
                          background: #1976D205;
                          border-radius: 15px;
                          border: 1px solid #ccc!important;
                      ">
              <h2 style="text-align: center;padding-bottom: 10px">Informações do Usuário</h2>
              <v-divider />
              <v-row>
                <v-col sm="3" cols="12">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-img v-bind="attrs" v-on="on" :src="editedUser.metadata.imageURL" contain style="
                            height: 170px;                           
                            margin-top: 10px;
                            border: solid blue thin;
                            border-radius: 15px;" @click="() => pickFile()" />
                      <input type="file" id="image_photo" ref="image_photo" accept="image/*" style="display: none"
                        @input="onFilePicked" />
                    </template>
                    <span>Clique para selecione uma imagem!</span>
                  </v-tooltip>
                </v-col>
                <v-col sm="9" cols="12">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field background-color="white" outlined label="Nome" prepend-inner-icon="person"
                        v-model="editedUser.name" :rules="rulesName" counter="64"/>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field background-color="white" outlined prepend-inner-icon="alternate_email"
                        v-model="editedUser.email" color="primary" label="E-mail" required :rules="rulesEmail"
                        counter="64"/>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field background-color="white" prepend-inner-icon="lock" outlined
                        :type="isPwsVisible ? 'text' : 'password'" v-model="editedUser.password" color="primary"
                        label="Senha" :append-icon="isPwsVisible ? 'visibility' : 'visibility_off'" @click:append="() => {
                          isPwsVisible = !isPwsVisible;
                        }
                          " required :rules="rulesPassword" counter="32" :disabled="this.getAction === 'edit'"/>
                    </v-col>
                    <v-col cols="12" md="6">
                  <v-text-field background-color="white" label="Telefone" outlined v-mask="'(XX) XXXXX-XXXX'"
                    v-model="editedUser.metadata.phone" :mask="getPhone.length > 0 &&
                    getPhone[2] == 9
                    ? '(##) #####-####'
                    : '(##) ####-####'
                    " :rules="rulesPhone"/>
                </v-col>
                  </v-row>
                </v-col>
                <v-col cols="3">
                  <v-radio-group background-color="white" v-model="editedUser.metadata.idType" :rules="rules.idType"
                    @change="editedUser.metadata.idNumber = null" style="margin: 0;">
                    <v-radio :key="1" :label="'Pessoa Física'" value="CPF"></v-radio>
                    <v-radio :key="2" :label="'Pessoa Jurídica'" value="CNPJ"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="9" sm="9">
                  <v-text-field background-color="white" v-if="editedUser.metadata.idType == 'CPF'" label="CPF" outlined
                    v-model="editedUser.metadata.idNumber" v-mask="'###.###.###-##'" required ref="refCpf"
                    :rules="rulesIdNumber"></v-text-field>
                  <v-text-field background-color="white" v-else-if="editedUser.metadata.idType == 'CNPJ'" label="CNPJ"
                    outlined v-model="editedUser.metadata.idNumber" v-mask="'##.###.###/####-##'" required
                    @blur="consultaCnpj" ref="refCnpj" :rules="rulesIdNumber"></v-text-field>
                </v-col>
              </v-row>

            </v-col>
            <v-col cols="12" />
            <!-- access maneger -->
            <v-col sm="12" cols="12" style="
                          background: #1976D205;
                          border-radius: 15px;
                          border: 1px solid #ccc!important;
                      ">
              <h2 style="text-align: center;padding-bottom: 10px">Tipo de Usuário </h2>
              <v-divider />
              <v-row>
                <v-col cols="12" :md="isSelectedClient || isSelectedAssociate ? 6 : 12">
                  <v-select v-model="editedUser.rank" 
                    @change="changeUserType"
                    label="Tipo de Usuário" 
                    outlined 
                    :items="getRanks"
                    background-color="white" />
                </v-col>
                <v-col v-if="isSelectedClient && !isFranqueado()" cols="12" md="6">
                  <v-select :loading="loadingfranchisees" :disabled="loadingfranchisees" 
                    v-model="editedUser.manager" background-color="white"
                    :items="franchisees" 
                    item-value="id" 
                    item-text="name" 
                    outlined color="primary"
                    label="Gestor" :rules="rulesGestor">
                  </v-select>
                </v-col>
                <v-col v-if="isSelectedAssociate" cols="12" md="6">
                  <v-select v-model="editedUser.client" :loading="loadingClients"  :items="clients" 
                    item-value="id" 
                    item-text="name" 
                    outlined
                    background-color="white" color="primary" label="Gerente" :rules="rulesGerente"
                    @change="changeClient">
                  </v-select>
                </v-col>
                <v-col v-if="isSelectedClientOfAssociate" cols="12">
                  <v-autocomplete v-model="newUnitAllowedByClient" :loading="loadingUnitsByUser" :disabled="loadingUnitsByUser" :items="unitsByClient"
                    :rules="rulesRequired" multiple chips required outlined background-color="white" label="Unidade"
                    item-value="id" item-text="name" @change="onChangeNewUnitAllowedByClient">
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index <= 3">
                        <span>{{ item.name }}</span>
                      </v-chip>
                      <span v-if="index === 4" class="grey--text text-caption">
                        (+{{ newUnitAllowedByClient.length - 4 }} unidades)
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
            <!-- roles -->
            <v-col sm="12" cols="12" style="
                          background: #1976D205;
                          border-radius: 15px;
                          border: 1px solid #ccc!important;
                      ">
              <h2 style="text-align: center;padding-bottom: 10px">Gerenciamento de Acesso</h2>
              <v-divider />
              <v-row>
                <v-col cols="12" md="6">
                  <v-select 
                    v-model="editedUser.id_role" 
                    label="Perfil" 
                    outlined 
                    item-text="name"
                    item-value="id_doc"
                    :items="rolesData"
                    background-color="white" />
                </v-col>
        
              </v-row>
            </v-col>
            <v-col cols="12" />
            <!-- address -->
            <v-col sm="12" cols="12" style="
                          background: #1976D205;
                          border-radius: 15px;
                          border: 1px solid #ccc!important;
                      ">
              <h2 style="text-align: center;padding-bottom: 10px">Endereço</h2>
              <v-divider />
              <v-row>
                <v-col cols="12" md="6" lg="4">
                  <v-text-field background-color="white" outlined required label="CEP"
                    v-model="editedUser.metadata.address.cep" v-mask="'XX.XXX-XXX'" :rules="rulesCEP">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                  <v-text-field background-color="white" outlined label="Cidade"
                    v-model="editedUser.metadata.address.cidade" :rules="rulesCity" counter="64">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                  <v-text-field background-color="white" outlined label="Bairro"
                    v-model="editedUser.metadata.address.bairro" :rules="rulesDistrict" counter="64">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="4">
                  <v-text-field background-color="white" outlined label="Logradouro"
                    v-model="editedUser.metadata.address.logradouro" :rules="rulesLogradouro" counter="128">
                  </v-text-field>
                </v-col>
                <v-col cols="12"  md="2" lg="2">
                  <v-text-field background-color="white" outlined label="Número" type="number"
                    v-model="editedUser.metadata.address.numero" :rules="rulesHouseNumber" counter="16">
                  </v-text-field>
                </v-col>
                <v-col cols="12"  md="6" lg="3">
                  <v-text-field background-color="white" outlined label="Complemento"
                    v-model="editedUser.metadata.address.complemento" :rules="rulesAdditionalAddress" counter="64">
                  </v-text-field>
                </v-col>
                <v-col cols="12"  md="4" lg="3">
                  <v-select background-color="white" outlined label="UF" :items="ufs" :rules="rulesRank"
                    v-model="editedUser.metadata.address.uf">
                  </v-select>
                </v-col>
              </v-row>

            </v-col>
            <v-col cols="12" />
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="save" :loading="saveButtonLoading" text color="primary" :disabled="!validSignup">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>



<script>
import UsersScripts from './UsersScripts.js';
export default UsersScripts;
</script>


<style scoped>
.title-displays {
  font-size: 25px;
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>