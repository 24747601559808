import apiService,{ HOST } from "./BaseApi";
const ENDPOINT_PATH = "report/economy";

const EconomyApi = {
    generatePdf: async (data, date_time) => await apiService.post(ENDPOINT_PATH, { data, date_time }),

    downloadPdf: async (name) => {  
        name = name.split(" ").join("_");
        window.open(HOST + `download/pdf/${"Economia "+name}`)},
};

export default EconomyApi;
