import DatePickerDialog from "@/components/DatePickerDialog/DatePickerDialog.vue";
import firebase from "@/firebase";
import ObjectValidator from '@/scripts/ObjectValidator';
import Utils from '@/scripts/Utils';
import AlertsService from "@/services/firebase/Alerts/AlertsService";
import UnitsService from "@/services/firebase/Units/UnitsService";
import UnitsServiceServer from "@/services/server/UnitsService";
import SectorsService from "@/services/firebase/Sectors/SectorsService";
import ModensFirebaseService from "@/services/firebase/Modens/MdensService";
import ModensService from "@/services/server/ModensService";
import UsersService from "@/services/server/UsersService";
import Rules from "@/utils/Rules";
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper";
import { mapGetters } from "vuex";
import DefaultAddImg from "../../../public/img/icons/add_img.png";

import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper";
// Importação de componentes
import Historics from "@/pages/Units/historics.vue";

const CONSTANTS = {
  route: 'units',
  FormColumns: [
    'Dados Estruturais',
    'Endereço',
    'Dados Extras',
    'Setores',
    'Histórico',
    'Meta',
  ],
  TabUnitFirstPage: [
    'Concessionária',
    'Meta',
  ],
  SectorTypes: [
    'Macro',
    'Interno'
  ],
  waterTariffsTypes: [
    'Real',
    'Suprimido 30%',
    'Fixo'
  ],
  sewerTariffsTypes: [
    'Real',
    'Fixo',
    'Sem Esgoto'
  ],
  efluentesTratadosTypes: [
    'Água',
    'Esgoto',
    'Água e Esgoto'
  ],
  typeGas: [
    'Gás Natural',
    'GLP'
  ],
  tipoConsumoTypes: [
    "Real/Real",
    "Real/Fixo",
    "Fixo/Real",
    "Fixo/Fixo",
    "Const Cort/Const Cort",
    "Estimado/Estimado",
    "Informado/Informado",
    "Media HD/Media HD",
    "Min Fixed/Min Fixed",
    "Real/Min Fixed"
  ],
  ufList: [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ],
  waterSupplyTypeList: [
    { value: null, text: 'Nenhum' },
    { value: "WELL", text: 'Poço' },
    { value: "DEALERSHIP", text: 'Concessionaria' }
  ],
  faturadoList: [
    'Sim',
    'Não'
  ],
  labels: [
    'Media Antes',
    'Economia',
  ],
  value: [
    200,
    100,
  ],
}
export default {
  components: {
    DatePickerDialog,
    Historics,
  },
  name: "Units",
  data: () => ({
    ...CONSTANTS,
    flagEditModem: false,
    sectorDialog: false,
    newSectorData: {},
    hydrometerModels: [
      { value: "DN_8", text: '1/4"' },
      { value: "DN_10", text: '3/8"' },
      { value: "DN_15", text: '"1/2"' },
      { value: "DN_20", text: '3/4"' },
      { value: "DN_32", text: '1"' },
      { value: "DN_40", text: '1 1/2"' },
      { value: "DN_50", text: '2"' },
      { value: "DN_65", text: '2 1/2"' },
      { value: "DN_80", text: '3"' },
      { value: "DN_100", text: '4"' },
      { value: "DN_125", text: '5"' },
      { value: "DN_150", text: '6"' },
      { value: "DN_200", text: '8"' },
      { value: "DN_250", text: '10"' },
      { value: "DN_300", text: '12"' },
      { value: "DN_350", text: '14"' },
      { value: "DN_400", text: '16"' },
    ],
    radioInvoiced: false,
    modensWithouUnit: [],
    modensWithouUnitLoading: false,
    sectorsByUnitList: [],
    validSectorForm: true,
    sectorLoadings: {
      delete: false,
      edit: false,
      save: false,
      getData: false,
    },
    usersLoadings: {
      getData: false,
    },
    usersByRank: [],
    selectedUser: [],
    historicsByUnitId: [],

    validForm: true,
    unit: ObjectValidator.create('unit'),
    sector: ObjectValidator.create('sector'),

    datePickerDialog: false,
    newHistoricoConsumo: [],

    removeHistoricoDialog: false,
    removeHistoricoIndex: null,

    itemsSectors: -1,
    dataMediaHistoricoDialog: false,
    dataMediaHistoricoObject: null,
    unwatchDataMediaHistorico: () => { },

    alertGoalActive: false,
    // Snackbar da Mensagem 
    textSnackbar: '',
    timeoutSnackbar: 10000,

    isMainLoading: true,

    columnsToChoose: ["Cliente", "Unidade", "Matrícula", "Grupo de Faturamento"],
    columnToSeach: "Unidade",
    searchText: "",
    isFirstLoadedUnit: false,

    // Área de teste
    dialog: false,
    canDeleted: false,

    // A lista de usuários que é buscado do servidor
    users: [],

    // Lista das unidades de todos os usuários, com referencia a qual usuário
    units: [],
    users_parsed: {},
    users_items: [],
    user_item: null,

    id_client: null,

    // Index da unidade selecionado no openUnit
    index_unit: null,

    // Modo no qual os dados serão apresentados
    readOnly: true,

    // Ação a ser tomada de acordo com as unidades
    // São possíveis 3
    // view   = visualização
    // edit   = edição
    // signup = cadastro
    action: "signup",
    nextTick: false,
    tab: null,
    tabUnit: null,
    selectTabUnitFirstPage: null,
    waterAuthorities: ["Compesa", "Sem Concessionária"],
    dialog: false,
    openModal: false,
    dialogDelete: false,
    isLoadingDeleteUnit: false,
    saveButtonLoading: false,
    editedIndex: -1,
    imageUploadError: undefined,
    user: {
      data: {
        name: "",
        manager: "",
        email: "",
        password: "",
        rank: "",
        status: "Ativo",
        dataList: [],
        metadata: {
          image: undefined,
          imageName: "",
          imageURL: "",
          idType: "",
          idNumber: "",
          address: {},
          phone: "",
        },
      },
      id: "",
    },

    // A unidade nova ou antiga que está sendo editada
    editedItem: ObjectValidator.create('unit'),

    // O item que indica qual a unidade que é para deletar
    deletedItem: null,

    unidade: ObjectValidator.create('unit'),
    selectedSector: {
      name: "",
      matricula: null,
      meta: null,
      hydrometer: {},
      modem: {},
      historico: [],
    },
    day: '01',
    headers: [
      { text: "" },
      { text: "Unidade" },
      { text: "Matricula" },
      { text: "Grupo de Faturamento" },
      { text: "Endereço" },
      { text: "" }
    ],
    headersSector: [
      { text: 'Nome Setor', value: "name", },
      { text: 'Id Modem', value: 'id_modem', },
      { text: 'Tipo', value: 'waterSupplyType', },
      { text: 'Faturado', value: 'invoiced', },
      { text: 'Hidrômetro', value: 'hydrometerModel', },
      { text: "Ações", value: "actions", sortable: false },
    ],
    options: [{ sortable: true }],
    page: 1,
    averageLine: { name: "( % )", color: "green" },
    historics: [],
  }),

  methods: {
    isEnabledAction(action) {
      return this.hasAction(this.route, action);
    },
    async insertSector() {
      this.newSectorData = {}
      this.sectorDialog = true
      this.modensWithouUnitLoading = true
      ModensService.findAllWithoutSector()
        .then(res => this.modensWithouUnit = res.data.data)
        .finally(() => this.modensWithouUnitLoading = false)
    },
    async saveSector() {
      if ((this.$refs.sectorForm) && !this.$refs.sectorForm.validate()) return;
      this.sectorLoadings.save = true
      if (this.flagEditModem) {
        const idDoc = this.newSectorData.id_doc
        await SectorsService.editSector(idDoc, this.newSectorData)
        this.sectorsByUnitList = this.sectorsByUnitList.map((sector) => sector.id_doc === idDoc ? this.newSectorData : sector)
      } else {
        this.newSectorData.id_unit = this.editedItem.id
        const idNewSector = await SectorsService.createSector(this.newSectorData)
        this.sectorsByUnitList.push({ ...this.newSectorData, id_doc: idNewSector })
      }
      this.sectorDialog = false
      this.flagEditModem = false
      this.sectorLoadings.save = false
      // this.$refs.form.resetValidation()
    },
    closeSectorDialog() {
      this.sectorDialog = false
      this.flagEditModem = false
    },
    editSector(sector) {
      this.sectorDialog = true
      this.modensWithouUnitLoading = true
      ModensFirebaseService.getModemByID(sector.id_modem).then(res => this.modensWithouUnit.push(res))
      ModensService.findAllWithoutSector()
        .then(res => this.modensWithouUnit.push(...res.data.data))
        .finally(() => this.modensWithouUnitLoading = false)
      this.newSectorData = { ...sector }
      this.flagEditModem = true
    },
    async removeSector(sector) {
      this.sectorLoadings.delete = true
      this.newSectorData = sector
      await SectorsService.deleteSector(sector.id_doc)
      this.sectorsByUnitList = this.sectorsByUnitList.filter(({ id_modem }) => id_modem != sector.id_modem)
      this.newSectorData = {}
      this.sectorLoadings.delete = false

    },
    loadingSectors(sector, disabled) {
      return sector.id_modem === this.newSectorData.id_modem && disabled ? true : false
    },
    orderHistory(historics) {

      if (historics.length) {
        return historics.sort((a, b) => {
          const aDate = new Date(Utils.formatDate(`${a.month}-${a.day}`, '<MM>/<DD>/<YYYY>')).getTime()
          const bDate = new Date(Utils.formatDate(`${b.month}-${b.day}`, '<MM>/<DD>/<YYYY>')).getTime()
          return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
        })
      } else return []

    },

    removeHistorico() {
      if (this.removeHistoricoIndex === null) return;
      let index = this.removeHistoricoIndex;
      this.newHistoricoConsumo = this.newHistoricoConsumo.filter(i => i !== index).map(i => i > index ? i - 1 : i);
      this.removeHistoricoDialog = false;
      this.removeHistoricoIndex = null;
    },

    async openUnit(action = "view", unitObj = null) {
      this.alertGoalActive = false
      this.sectorsByUnitList = []

      this.unwatchDataMediaHistorico();
      this.clearEditedItem();
      this.user_item = null;
      this.readOnly = this.action === "view";

      if (unitObj !== null) {
        this.editedItem = { ...unitObj }
        // refactor \/
        // const alertActive = await AlertsService.getActiveAlertsPerTypeAndUnit('goal', unitObj.id)
        // if (alertActive.length) this.alertGoalActive = true
        ///////////////////////////////////////////////////////////////

        this.sectorLoadings.getData = true
        // this.usersLoadings.getData = true

        SectorsService.getSectorsByUnitId(unitObj.id)
          .then((response) => this.sectorsByUnitList = response).catch().finally(() => this.sectorLoadings.getData = false)
        UnitsService.getSubCollectionHistorics(unitObj.id)
          .then((response) => {
            this.historicsByUnitId = this.orderHistory(response)
          }).catch()

        this.action = action;
      } else {
        this.id_client = null;
        this.index_unit = null;
        this.action = "signup";
      }
      this.dataMediaHistoricoObject = Utils.instanceDate(this.editedItem.dataMediaHistorico)
      this.unwatchDataMediaHistorico = this.$watch('editedItem.dataMediaHistorico',
        (newValue) => {
          this.dataMediaHistoricoObject = Utils.instanceDate(newValue)
        }
      )
      this.addedDayInOldHistorics();
      this.dialog = true;
    },
    addedDayInOldHistorics() {
      if (this.editedItem.hasOwnProperty("historicos")) {
        this.historicsByUnitId = this.historicsByUnitId.map(d => {
          let day = '01';
          if (d.hasOwnProperty('day')) day = d.day;
          return { ...d, day }
        })
      }
    },
    closeUnit() {
      this.historicsByUnitId = []
      this.sectorsByUnitList = []
      this.flagEditModem = false
      this.sortBy = 'month';
      this.dialog = false;
      this.imageUploadError = undefined;
      this.id_client = null;
      this.index_unit = null;
      this.user_item = null;
      this.action = "";

      this.$nextTick(() => {
        this.editedItem = { ...this.unidade };
        this.selectedSector = { ...this.sector };
        this.editedIndex = -1;
      });
    },

    confirmDeleteUnit(unitObj) {

      this.deletedItem = unitObj;
      showDeleteDialogAlert(`Deseja excluir essa unidade ${unitObj.name}?`, this.removeUnit)
    },

    async removeUnit() {
      try {
        const unit_id = this.deletedItem.id
        this.isLoadingDeleteUnit = true;

        // Soft delete da unidade
        await UnitsService.softDeleteUnit(unit_id);
        await UnitsService.updateSubCollectionHistorics(unit_id, [])
        await SectorsService.deleteSectorsByUnit(unit_id)

        this.units = this.units.filter(({ id }) => id != unit_id)

        this.isLoadingDeleteUnit = false;
        this.deletedItem = null;
        this.dialogDelete = false;
        showSuccessSnackBar("Unidade deletada com sucesso.")

      } catch (e) {
        showErrorSnackBar("Erro deletar unidade.")
        this.isLoadingDeleteUnit = false;
        throw new Error(e);
      }
    },

    clearEditedItem() {
      this.editedItem = ObjectValidator.create('unit');
    },

    pickFile() {
      this.$refs.get_image.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (
        files[0] !== undefined &&
        files[0].type.split("/")[0] == "image" &&
        files[0].size < 700000
      ) {
        this.editedItem.imageName = files[0].name;
        if (this.editedItem.imageName.lastIndexOf(".") <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener("load", () => {
          this.editedItem.image = fr.result;
          this.imageFile = files[0]; // this is an image file that can be sent to server...
        });
      } else if (files[0].size > 699999) {
        showErrorSnackBar("Tamanho máximo: 700 KB.")
        this.editedItem.image = "";
        this.editedItem.imageName = "";
      } else {
        showErrorSnackBar("Arquivo inválido.")
        this.editedItem.image = "";
        this.editedItem.imageName = "";
      }
      document.getElementById('get_image').value = "";
    },
    close() {
      this.flagEditModem = false
      this.dialog = false;
      this.action = '';
      this.imageUploadError = undefined;
      // this.$refs.form.resetValidation()

      this.$nextTick(() => {
        this.editedItem = ObjectValidator.create('unit');
        this.selectedSector = { ...this.sector };
        this.editedIndex = -1;
      });
    },
    async save() {
      if ((this.$refs.form) && !this.$refs.form.validate()) return;
      this.saveButtonLoading = true;
      this.sortBy = 'month';
      if (this.action == "edit") {
        await this.editUnit()
      }
      else if (this.action == "signup") {
        await this.createUnit();
      }
      this.saveButtonLoading = false;
    },
    async editUnit() {
      try {

        let savedObj = this.editedItem;

        await AlertsService.deleteActiveGoalAlertsByUnit(savedObj.id)
        if (this.alertGoalActive) await AlertsService.createActiveGoalAlert(savedObj.id)

        if (!await UnitsService.updateSubCollectionHistorics(savedObj.id, this.historicsByUnitId)) throw "Error ao editar historico!"

        await firebase.updateUnit(savedObj)
        this.units = this.units.map((unit) => this.editedItem.id === unit.id ? this.editedItem : unit)
        this.closeUnit();
        showSuccessSnackBar("Unidade editada com sucesso!")
      } catch (err) {
        showErrorSnackBar("Ocorreu um erro interno ao editar unidade. Entre em contato com nossa equipe.")
        console.error(err)
      }

    },
    async createUnit() {
      try {
        let unit_id = await UnitsService.addUnit(this.editedItem);
        if (this.historicsByUnitId.length) await UnitsService.createSubCollectionHistorics(unit_id, this.historicsByUnitId)
        // await AlertsService.deleteActiveGoalAlertsByUnit(unit_id)
        // if (this.alertGoalActive) await AlertsService.createActiveGoalAlert(unit_id)
        this.units.unshift(this.editedItem)
        this.closeUnit();
        showSuccessSnackBar("Unidade cadastrada com sucesso!")
      } catch (err) {
        showErrorSnackBar("Ocorreu um erro interno ao salvar unidade. Entre em contato com nossa equipe.")
        console.error(err)
        await UnitsService.hardDeleteUnit(unit_id)
      }
    },
    /*
    * Converte um objeto que tem valor de chave nula
    */
    convertUndefinedToNullValue(obj) {
      Object.keys(obj).forEach(
        key => {
          if (obj[key] === undefined) obj[key] = null;
        }
      );
      return obj;
    },
    filterModens(modem) {
      let modensFilter = this.getModems.filter((i) => {
        return i.unit_id == null
      })
      if (modem.name !== undefined) modensFilter.unshift(modem.name)

      return modensFilter
    },
    waterSupplyTypeSlug(waterSupplyType) {
      return this.waterSupplyTypeList.find((waterSupply) => waterSupply.value === waterSupplyType).text
    },
    hydrometerModelsSlug(hydrometerModel) {
      const result = this.hydrometerModels.find((hydrometer) => hydrometer.value === hydrometerModel)
      return result? result.text : (hydrometerModel || "Indefinido!")
    },

    getIdUserByName(userName) {
      for (let index in this.users_parsed) {
        let userObj = this.users_parsed[index];
        if (userObj.data.name.toUpperCase() === userName.toUpperCase()) {
          return userObj;
        }
      }
    },

    getImage(userId) {
      const user = this.usersByRank.find(({ id }) => userId === id)
      if (user) return user.metadata?.imageURL ? user.metadata.imageURL : DefaultAddImg
      else return DefaultAddImg
    },
    getRegistration(registration) {
      return registration == null || registration == "" ? "Não há matricula" : registration
    },
    getInvoicingGroup(invoicingGroup) {
      return invoicingGroup != null ? invoicingGroup : '- / -';
    },
    getAddress(address) {
      let hasBairro = !address ? false : !address.bairro ? false : typeof address.bairro === 'string' ? address.bairro.length > 0 : false;
      let hasUF = !address ? false : !address.uf ? false : typeof address.uf === 'string' ? address.uf.length > 0 : false;
      return `${hasBairro ? this.capitalize(address.bairro) : '-'} / ${hasUF ? address.uf.toUpperCase() : '-'}`;
    },
    /*
    * Adiciona as chaves de obj dentro do objeto de referencia
    */

    capitalize(str) {
      let ignoreRegex = /^((d)?((a|e|i|o|u)(s)?)|ou|.{1,2})$/gi;
      return str
        .replace(/(^ | $)/, '')
        .replace(/( +)/, ' ')
        .split(/ /)
        .map(word => word.match(ignoreRegex) ? word : (word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()))
        .join(" ");
    },

    selectUser(userId) {
      this.selectedUser = this.usersByRank.find(({ id }) => userId === id)
      this.editedItem.id_franchisee = this.selectedUser.manager
      this.editedItem.name_client = this.selectedUser.name
      this.editedItem.image = this.selectedUser.metadata?.imageURL ? this.selectedUser.metadata.imageURL : DefaultAddImg
    },
    changeHistorics(historics) {
      this.historicsByUnitId = historics.historicos
      this.editedItem.dataMediaHistorico = historics.dataMediaHistorico
      this.editedItem.averageBeforeFoz = parseFloat(historics.averageBeforeFoz)
      this.editedItem.lastReadingDate = historics.lastReadingDate ? new Date(historics.lastReadingDate) : null
    },
  },

  computed: {
    ...mapGetters([
      "getCurrentUserRank",
      "getCurrentUserID",
      "hasAction",
    ]),
    getGoalEconomy() {
      const averageBeforeFoz = this.getAverageBeforeFoz
      return (averageBeforeFoz - (averageBeforeFoz * (this.editedItem.metaEconomia / 100))).toFixed(2)
    },
   
    getAverageBeforeFoz() {
      if (this.historicsByUnitId.length && this.editedItem.dataMediaHistorico) {
        let avaregeBefore = []

        this.historicsByUnitId.forEach((historic) => {
          if (new Date(historic.month).getTime() < new Date(this.editedItem.dataMediaHistorico).getTime()) {
            avaregeBefore.push(historic.consumption_cubic_meters)
          }
        })
        return avaregeBefore.length ? Number((avaregeBefore.reduce((a, b) => a + b) / avaregeBefore.length).toFixed(2)) : 0
      }
      return 0
    },
    rulesRequired() {
      return Rules.required();
    },
    clientName() {
      const client = this.usersByRank.find(({ id }) => this.editedItem.id_client === id)
      if (client) return client.metadata?.idNumber ? client.metadata.idNumber : ""
      return ""
    },
    formTitle() {
      if (this.action == "view") return "Visualização da Unidade";
      else if (this.action == "signup") return "Adicionar Unidade";
      else if (this.action == "edit") return "Editar Unidade";
    },
    getUnitsLocal() {
      let items = []
      let unity = this.units.sort(function (a, b) {
        return a.id_franchisee < b.id_franchisee ? -1 : a.id_franchisee > b.id_franchisee ? 1 : 0;
      });
      let search = 'name'
      function searchUnit(search, searchText) {
        searchText = Utils.defaultSpace(searchText)
        items = unity.filter((unit) => {
          if (unit[search] !== null && unit[search] !== undefined) {
            return (
              Utils.defaultSpace(unit[search]).toLowerCase().indexOf(searchText.toLowerCase()) > -1
            );
          } else if (searchText == '') return unit

        });
      }
      switch (this.columnToSeach) {
        case 'Unidade':
          search = 'name'
          searchUnit(search, this.searchText)
          break;
        case 'Cliente':
          search = 'name_client'
          searchUnit(search, this.searchText)
          break;
        case 'Matrícula':
          search = 'matricula'
          searchUnit(search, this.searchText)
          break;
        case 'Grupo de Faturamento':
          search = 'grupoFaturamento'
          searchUnit(search, this.searchText)
          break;
      }
      return items
    },
    isActiveOnlyView() {
      return this.action == "view";
    },
    hasHistoricBefore() {
      return this.getAverageBeforeFoz !== 0
    },
    isEdit() {
      return this.action == "edit";
    },
    getCurrentImage() {
      const user = this.usersByRank.find(({ id }) => this.editedItem.id_client === id)
      if (user) return user.metadata?.imageURL ? user.metadata.imageURL : DefaultAddImg
      else return DefaultAddImg
    },
    isActiveViewSewerTarrifsTypes() {
      return this.editedItem.tarrifsTypes.sewer.tariffType && this.editedItem.tarrifsTypes.sewer.tariffType != 'Real' ? true : false;
    },
    isActiveWaterTarrifsTypes() {
      return this.editedItem.tarrifsTypes.water.tariffType && this.editedItem.tarrifsTypes.water.tariffType === 'Fixo' ? true : false;
    },
  },
  watch: {

  },
  async created() {
    UsersService.findAllClients()
      .then((response) => this.usersByRank = response.data.data)
    const response = await UnitsServiceServer.findAll()
    this.units = response.data.data
    if (this.getCurrentUserRank === "Super Admin" || this.getCurrentUserRank === "Admin") this.canDeleted = true
    this.isMainLoading = false;

  },
};