import apiService from "./BaseApi";
const ENDPOINT = "alerts";
import store from '@/store/index'
const AlertsNotificationsService = {
    findAll: async () => apiService.post(ENDPOINT + "/notifications/findAll", { currentUserId: store.state.currentUser?.uid }),
    mapFindAll: async () => apiService.post(ENDPOINT + "/maps/findAll", { currentUserId: store.state.currentUser?.uid }),
    findByUnit: async (unit_id) => apiService.post(ENDPOINT + "/findByUnit", { currentUserId: store.state.currentUser?.uid,unit_id }),
};

export default AlertsNotificationsService;
