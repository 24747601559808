<template>
  <div>
    <v-text-field v-bind="$attrs" v-money="money"
      @input="v => $emit('input', (v.replaceAll('.', '').replace(',', '.')))" />
  </div>
</template>
   
<script>
import { VMoney } from 'v-money'
export default {
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false
      }
    }
  },
  directives: { money: VMoney }

}
</script> 