import { mapActions } from "vuex";
import { showErrorSnackBar } from "@/utils/SnackBartHelper"
import Utils from '@/scripts/Utils';
import Rules from "@/utils/Rules";
import diff from "deep-diff"

import LeadsService from "@/services/server/LeadsService.js";
import ProjectsService from "@/services/server/ProjectsService.js";
import NextStepProject from "@/components/Projects/NextStepProject.vue"

import selectResponsibleType from "../Components/select-responsible-type.vue"
import selectProjectPartner from "../Components/select-project-partner.vue"
import selectProjectHunter from "../Components/select-project-hunter.vue"
import selectProjectSeller from "../Components/select-project-seller.vue"
import selectProjectCloser from "../Components/select-project-closer.vue"

import InputMoney from "@/components/Forms/Input/input-money.vue";
import InputFiles from "@/components/Forms/Input/input-files.vue";

import LeadsStorage from '@/services/storage/LeadsStorage';

export default {
    name: "Prospecção",
    components: {
        InputFiles,
        InputMoney,
        NextStepProject,
        selectResponsibleType,
        selectProjectPartner,
        selectProjectHunter,
        selectProjectSeller,
        selectProjectCloser,
    },
    props: {
        openModal: {
            type: Boolean,
            default: false
        },
        idLead: {
            type: String,
            default: null
        },
        closeFunc: {
            type: Function,
            default: () => { }
        },
        updateLead: {
            type: Boolean,
            default: true
        },
        newLead: {
            type: [Object], 
            default: () => ({}), 
        }
    },
    data: () => ({
        is_loading: false,
        is_created: false,
        is_new_project: false,
        mode: 'draft',
        is_create_lead: false,
        dialog: false,
        radioDocument: null,
        radioDocumentOptions: ['CPF', 'CNPJ'],
        project_name: null,
        id_project: null,
        is_completed: false,
        isValidForm: false,
        prospecting: {
            id_franchisee: null,
            name: null,
            document: null,
            phone: null,
            email: null,

            zip_code: null,
            uf: null,
            city: null,
            district: null,
            address: null,
            house_number: null,
            address_complement: null,

            responsible_name: null,
            responsible_document: null,
            responsible_phone: null,
            responsible_email: null,
            // responsible_role: null,

            registration: null,
            minimum_rate: null,
            financial_average_per_unit: null,
            number_units: null,
            number_towers: null,
            number_floors: null,
            unit_by_floor: null,
            wcs_by_unit: null,
        },
        requiredFieldListByMode: {
            id_franchisee: {
                draft: true,
                save: true,
            },
            name: {
                draft: true,
                save: true,
            },
            document: {
                draft: true,
                save: true,
            },
            phone: {
                draft: false,
                save: true,
            },
            email: {
                draft: false,
                save: true,
            },

            zip_code: {
                draft: false,
                save: true,
            },
            uf: {
                draft: false,
                save: true,
            },
            city: {
                draft: false,
                save: true,
            },
            district: {
                draft: false,
                save: true,
            },
            address: {
                draft: false,
                save: true,
            },
            house_number: {
                draft: false,
                save: true,
            },
            address_complement: {
                draft: false,
                save: true,
            },

            responsible_name: {
                draft: false,
                save: true,
            },
            responsible_document: {
                draft: false,
                save: true,
            },
            responsible_phone: {
                draft: false,
                save: true,
            },
            responsible_email: {
                draft: false,
                save: true,
            },
            // responsible_role: {
            //     draft: false,
            //     save: true,
            // },

            registration: {
                draft: false,
                save: true,
            },
            minimum_rate: {
                draft: false,
                save: true,
            },
            financial_average_per_unit: {
                draft: false,
                save: true,
            },
            number_units: {
                draft: false,
                save: true,
            },
            number_towers: {
                draft: false,
                save: true,
            },
            number_floors: {
                draft: false,
                save: true,
            },
            unit_by_floor: {
                draft: false,
                save: true,
            },
            wcs_by_unit: {
                draft: false,
                save: true,
            },
        },
        initialProspecting: {},
        state_acronyms: [
            "AC",
            "AL",
            "AP",
            "AM",
            "BA",
            "CE",
            "DF",
            "ES",
            "GO",
            "MA",
            "MT",
            "MS",
            "MG",
            "PA",
            "PB",
            "PR",
            "PE",
            "PI",
            "RJ",
            "RN",
            "RS",
            "RO",
            "RR",
            "SC",
            "SP",
            "SE",
            "TO",
        ],
    }),

    computed: {
        is_draft_mode() {
            return this.mode == 'draft'
        },
        is_save_mode() {
            return this.mode == 'save'
        },
    },

    methods: {
        ...mapActions([
            "subscribeChannel",
            "publishChannel",
        ]),
        clearProspecting() {
            this.$nextTick(
                () => {
                    this.prospecting = {
                        id_franchisee: null,
                        name: null,
                        document: null,
                        phone: null,
                        email: null,

                        zip_code: null,
                        uf: null,
                        city: null,
                        district: null,
                        address: null,
                        house_number: null,
                        address_complement: null,

                        responsible_name: null,
                        responsible_document: null,
                        responsible_phone: null,
                        responsible_email: null,
                        // responsible_role: null,

                        registration: null,
                        minimum_rate: null,
                        financial_average_per_unit: null,
                        number_units: null,
                        number_towers: null,
                        number_floors: null,
                        unit_by_floor: null,
                        wcs_by_unit: null,
                    }
                    // this.is_loading = true;
                    this.is_created = false;
                    this.is_new_project = false;
                    this.is_create_lead = false;
                    this.radioDocument = null;
                    this.project_name = null;
                    this.id_project = null;
                }
            )
        },
        convertFields(prospecting) {
            let _prospecting = Utils.copyObject(prospecting)
            var fieldsAreIntegers = ['number_floors', 'number_towers', 'number_units', 'unit_by_floor', 'wcs_by_unit']
            var fieldsAreFloats = ['financial_average_per_unit', 'minimum_rate']
            for (let key in _prospecting) {
                if (_prospecting[key] === '') {
                    _prospecting[key] = null
                    continue
                }
                _prospecting[key] = fieldsAreIntegers.includes(key) ?
                    parseInt(_prospecting[key])
                    :
                    fieldsAreFloats.includes(key) ?
                        parseFloat(_prospecting[key])
                        :
                        _prospecting[key]
            }
            return _prospecting
        },
        getProspectingDiff() {
            var prospecting = {}
            var differences = diff(this.initialProspecting, this.prospecting);

            if (!differences) return null
            for (let difference of differences) {
                if (difference.kind !== 'E') continue
                // Detectar somente as mudanças de valores
                let key = difference.path[0]
                let value = difference.rhs
                // É um campo obrigatório mas está vazio
                if (this.requiredFieldListByMode[key][this.mode] && value === '') continue
                prospecting[key] = value === '' ? null : value
            }
            return Object.keys(prospecting).length > 0 ? prospecting : null
        },
        getModifiedProperties(obj1, obj2) {
            const differences = {};

            const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

            for (const key of allKeys) {
                const value1 = obj1[key];
                const value2 = obj2[key];

                if (!(key in obj1)) {
                    differences[key] = value2;
                } else if (!(key in obj2)) {
                    continue;
                } else if (value1 instanceof File || value2 instanceof File) {
                    if (!(value1 instanceof File) || !(value2 instanceof File) || value1.name !== value2.name || value1.size !== value2.size || value1.type !== value2.type) {
                        differences[key] = value2;
                    }
                } else if (typeof value1 === 'object' && value1 !== null && value2 !== null) {
                    const nestedDiff = this.getModifiedProperties(value1, value2);
                    if (Object.keys(nestedDiff).length > 0) {
                        differences[key] = nestedDiff;
                    }
                } else if (value1 !== value2) {
                    differences[key] = value2;
                }
            }

            return differences;
        },
        setProspectingWithReact(prospecting) {
            Object.keys(prospecting).forEach(
                (key) => {
                    this.$set(this.prospecting, key, prospecting[key])
                }
            )
        },
        async save() {
            if (!this.$refs.propectingForm.validate()) {
                showErrorSnackBar("Há pendência(s) no formulário de prospecção")
                return
            }
            this.is_loading = true
            this.dialog = true
            let prospecting = this.prospecting
            this.setProspectingWithReact(prospecting)
            if (this.is_new_project) {
                let id_lead = null

                // if (this.is_create_lead) {
                //     let [status_lead, response_lead] = await LeadsService.createLead(this.prospecting)
                //     console.log(response_lead)
                // }
                
                // else {
                //     prospecting = this.getProspectingDiff()
                //     if (prospecting) {
                //         id_lead = this.prospecting.id_doc
                //         let [status_lead, response_lead] = await LeadsService.updateLead(id_lead, prospecting)
                //     }
                //     this.closeProspectingModal()
                // }

                // Criar um projeto
                let [status_lead, response_lead] = await LeadsService.createLead(this.prospecting)
                let [status_project, response_project] = await ProjectsService.createProject({
                    id_lead: response_lead.id_lead,
                    project_name: this.project_name,
                    id_franchisee: this.prospecting.id_franchisee,
                })

                // Redirecionar para a url do projeto
                let { id_project } = response_project
                this.is_loading = false
                this.$router.push(`/projects/${id_project}`)
                
            } else {
                prospecting = this.getModifiedProperties(this.initialProspecting, this.prospecting)
                console.log('getProspectingDiff', prospecting)

                await this.checkExistImagesFromSave(prospecting)

                if (Object.keys(prospecting).length) await LeadsService.updateLead(this.prospecting.id_doc, prospecting)

                this.closeProspectingModal()
            }
        },
        
        async checkExistImagesFromSave(prospecting) {

            if (prospecting.hasOwnProperty('file_photo_account_dealership')) {
                const allowedTypes = ["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
                await LeadsStorage.deleteImg(this.initialProspecting.path_file_photo_account_dealership)
                prospecting.path_file_photo_account_dealership = null
                if (prospecting.file_photo_account_dealership && allowedTypes.includes(prospecting.file_photo_account_dealership.type)) {
                    prospecting.path_file_photo_account_dealership = await LeadsStorage.uploadFile(this.prospecting.id_doc, prospecting.file_photo_account_dealership)
                } else if (prospecting.file_photo_account_dealership) prospecting.path_file_photo_account_dealership = await LeadsStorage.uploadImg(this.prospecting.id_doc, prospecting.file_photo_account_dealership)

                delete prospecting.file_photo_account_dealership

            }
            if (prospecting.hasOwnProperty('file_photo_unit_facade')) {
                await LeadsStorage.deleteImg(this.initialProspecting.path_file_photo_unit_facade)
                prospecting.path_file_photo_unit_facade = null
                if (prospecting.file_photo_unit_facade) prospecting.path_file_photo_unit_facade = await LeadsStorage.uploadImg(this.prospecting.id_doc, prospecting.file_photo_unit_facade)

                delete prospecting.file_photo_unit_facade
            }
        },
        async save_draft() {
            this.mode = 'draft'
            await this.save()
        },
        ruleRequired() {
            return Rules.required()
        },
        ruleString(isRequired = false, max = 64) {
            return Rules.string(isRequired, 1, max)
        },
        ruleCPF(isRequired) {
            return Rules.cpf(isRequired)
        },
        ruleCNPJ(isRequired) {
            return Rules.cnpj(isRequired)
        },
        ruleEmail(isRequired) {
            return Rules.email(isRequired, 64)
        },
        ruleCEP(isRequired) {
            return Rules.cep(isRequired)
        },
        ruleIntPositive(isRequired) {
            return Rules.number(isRequired)
        },
        searchCEP(CEP) {
            if (!CEP) return
            if (!CEP.match(/^\d{2}\.\d{3}\-\d{3}$/)) return
            this.is_loading = true
            this.prospecting.uf = null
            this.prospecting.city = null
            this.prospecting.district = null
            this.prospecting.address = null
            this.prospecting.house_number = this.prospecting.house_number ? this.prospecting.house_number : null
            this.prospecting.address_complement = this.prospecting.address_complement ? this.prospecting.address_complement : null
            let url = "https://viacep.com.br/ws/" + CEP.replace(/\D/g, "") + "/json/";
            fetch(url)
                .then((response) => response.json())
                .then((responseJson) => {
                    var { bairro, localidade, logradouro, uf } = responseJson
                    this.prospecting.uf = uf
                    this.prospecting.city = localidade
                    this.prospecting.district = bairro
                    this.prospecting.address = logradouro
                    this.is_loading = false
                })
                .catch((err) => {
                    this.is_loading = false
                    console.error("Erro", err)
                });
            this.is_loading = false
        },
        async nextStep() {
            this.mode = 'save'
            await this.save()
        },
        closeProspectingModal() {
            this.clearProspecting()
            this.closeFunc("prospecting")
            this.$nextTick(
                () => {
                    this.prospecting = {
                        id_franchisee: null,
                        name: null,
                        document: null,
                        phone: null,
                        email: null,

                        zip_code: null,
                        uf: null,
                        city: null,
                        district: null,
                        address: null,
                        house_number: null,
                        address_complement: null,

                        responsible_name: null,
                        responsible_document: null,
                        responsible_phone: null,
                        responsible_email: null,
                        // responsible_role: null,

                        registration: null,
                        minimum_rate: null,
                        financial_average_per_unit: null,
                        number_units: null,
                        number_towers: null,
                        number_floors: null,
                        unit_by_floor: null,
                        wcs_by_unit: null,
                    }
                    // this.is_loading = true;
                    this.is_created = false;
                    this.is_new_project = false;
                    this.is_create_lead = false;
                    this.radioDocument = null;
                    this.project_name = null;
                    this.id_project = null;
                }
            )
        }
    },

    watch: {
        'prospecting.zip_code': function (newVal) {
            this.searchCEP(newVal)
        },
        'prospecting': function (newVal) {
            // console.log("prospecting", newVal)
        }
    },

    async created() {
        this.is_loading = true;
        this.is_create_lead = false

        if (this.idLead) {
            this.is_new_project = false
            const [status, lead] = await LeadsService.getLeadById(this.idLead)
            if (!status) {
                showErrorSnackBar("O lead não foi encontrado!")
                this.is_loading = false;
                this.closeProspectingModal()
                return
            }

            if (lead.path_file_photo_account_dealership && lead.hasOwnProperty('path_file_photo_account_dealership')) {
                lead.file_photo_account_dealership = await LeadsStorage.downloadImg(lead.path_file_photo_account_dealership)
            }
            if (lead.path_file_photo_unit_facade && lead.hasOwnProperty('path_file_photo_unit_facade')) {
                lead.file_photo_unit_facade = await LeadsStorage.downloadImg(lead.path_file_photo_unit_facade)
            }

            if (lead.document.match(/^\d{2}\.\d{3}\.\d{3}\/\d{3}$/)) this.radioDocument = 'CNPJ'
            else this.radioDocument = 'CPF'

            this.$nextTick(
                () => {
                    this.initialProspecting = Utils.copyObject(lead)
                    Object.keys(lead).forEach(
                        (key) => {
                            this.$set(this.prospecting, key, lead[key])
                        }
                    )
                    this.is_loading = false;
                }
            )
        } else {
            this.is_new_project = true
            this.is_created = true;
            this.is_create_lead = true
            this.is_loading = false;

            console.log("this.newLead",this.newLead)

            this.id_project = null
            this.prospecting.id_franchisee = this.newLead.id_franchisee


            this.radioDocument = this.newLead.type_lead_document_selected
            this.prospecting.document = this.newLead.lead_document
            this.initialProspecting = Utils.copyObject(this.prospecting)
            this.project_name = this.newLead.project_name



        }

        // this.is_create_lead = false
        // this.is_created = true;

        // this.is_loading = false;


        // this.subscribeChannel({
        //     channel: 'projects',
        //     events: [
        //         {
        //             event: 'leads:create',
        //             fn: ([
        //                 id_franchisee, 
        //                 lead_document, 
        //                 project_name, 
        //                 document_type,
        //             ]) => {
        //                 this.is_new_project = true
        //                 this.is_loading = false;
        //                 this.id_project = null
        //                 this.dialog = true
        //                 this.prospecting.id_franchisee = id_franchisee
        //                 this.radioDocument = document_type
        //                 this.prospecting.document = lead_document
        //                 this.initialProspecting = Utils.copyObject(this.prospecting)
        //                 this.project_name = project_name
        //                 this.is_create_lead = true
        //                 this.is_created = true;
        //             }
        //         },
        //         {
        //             event: 'leads:get',
        //             fn: ([
        //                 project_name, 
        //                 document_type,
        //                 lead,
        //             ]) => {
        //                 this.is_new_project = true
        //                 this.is_loading = false;
        //                 this.dialog = true
        //                 this.id_project = null
        //                 this.radioDocument = document_type
        //                 this.project_name = project_name
        //                 this.prospecting = lead
        //                 this.initialProspecting = Utils.copyObject(lead)
        //                 this.is_create_lead = false
        //                 this.is_created = true;
        //             }
        //         },
        //         {
        //             event: 'leads:open',
        //             fn: async ([
        //                 id_lead,
        //                 id_project,
        //                 is_completed,
        //             ]) => {
        //                 this.is_new_project = false
        //                 this.is_loading = true;
        //                 this.dialog = true
        //                 this.is_completed = is_completed

        //                 // Buscar o lead
        //                 const [status, lead] = await LeadsService.getLeadById(
        //                     id_lead,
        //                 )
        //                 if (!status) {
        //                     showErrorSnackBar("O lead não foi encontrado")
        //                     this.is_loading = false;
        //                     this.dialog = false;
        //                     return
        //                 }
        //                 this.id_project = id_project
        //                 if (lead.document.match(/^\d{2}\.\d{3}\.\d{3}\/\d{3}$/)) {
        //                     this.radioDocument = 'CNPJ'
        //                 } else {
        //                     this.radioDocument = 'CPF'
        //                 }
        //                 this.is_create_lead = false
        //                 this.is_created = true;
        //                 this.$nextTick(
        //                     () => {
        //                         this.initialProspecting = Utils.copyObject(lead)
        //                         Object.keys(lead).forEach(
        //                             (key) => {
        //                                 this.$set(this.prospecting, key, lead[key])  
        //                             }
        //                         )
        //                         this.is_loading = false;
        //                     }
        //                 )
        //             }
        //         },
        //     ]
        // })

    },
};