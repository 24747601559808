<template>
    <v-col cols="12">
        <!-- start Investor Dialog -->
        <v-row justify="center">
            <v-dialog transition="dialog-top-transition" v-model="modal" persistent max-width="500px">
                <v-card>
                <v-form ref="modal" lazy-validation>
                    <v-toolbar color="primary" dark> <span class="text-h5">{{ headerTxt }}</span></v-toolbar>
                    <v-card-text style="padding-bottom: 0px;">
                        <v-row>
                            <v-col>
                                <v-autocomplete
                                    :items="solutionsServiceList"
                                    item-text="solution"
                                    item-value="id"
                                    v-model="item.service"
                                    :rules="ruleRequired"
                                    @focus="setFocus"
                                    @blur="setBlur"
                                    @update:error="(e) => setError(e, 'service')"
                                >
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field 
                                    label="Quantidade"
                                    type="number"
                                    v-model="item.amount"
                                    :rules="ruleValue"
                                    @focus="setFocus"
                                    @blur="setBlur"
                                    @update:error="(e) => setError(e, 'amount')"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field 
                                    label="R$ Unidade"
                                    type="number"
                                    v-model="item.unitPrice"
                                    :rules="ruleValue"
                                    @focus="setFocus"
                                    @blur="setBlur"
                                    @update:error="(e) => setError(e, 'unitPrice')"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field 
                                    label="Total"
                                    :value="formatCurrency(item.total)"
                                    readonly
                                    disabled
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="() => closeModal()" :loading="loading" :disabled="loading">
                            Cancelar
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="() => saveItem()" :loading="loading" :disabled="loading || hasDisabled">{{ saveButtonTxt }}</v-btn>
                    </v-card-actions> 
                </v-form>
                </v-card>
            </v-dialog>
        </v-row>
        <!-- end Investor Dialog -->

        <!-- Start Table Solutions -->
        <v-data-table
            :headers="headers"
            :items="list"
            :items-per-page="12"
            class="elevation-1"
            style="width: 100%"
        >
        <template v-slot:top>
            <v-toolbar flat class="rounded-lg">
            <v-toolbar-title>Serviços</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn 
                color="primary" 
                class="mb-2"
                text 
                @click="() => addNewItem()" 
                :loading="loading" 
                :disabled="loading"
            >
                <v-icon>add</v-icon>
            </v-btn>
            </v-toolbar>
        </template>
        
        <template v-slot:body="{ items }">
            <tbody>      
            <tr v-for="(item, index) in items" :key="index">
                <td style="display: flex">
                <v-text-field
                    :value="item.serviceName"
                    readonly
                >
                </v-text-field> 
                </td>
                <td>
                <v-text-field
                    :value="item.amount"
                    readonly
                >
                </v-text-field>
                </td>
                <td>
                <v-text-field
                    :value="formatCurrency(item.unitPrice)"
                    readonly
                >
                </v-text-field>
                </td>
                <td>
                <v-text-field
                    :value="formatCurrency(item.total)"
                    readonly
                >
                </v-text-field>
                </td>
                <td>
                <v-btn
                    elevation="2"
                    @click="() => editItem(index)"
                    style="height: 30px; margin: 2px"
                    :loading="loading" 
                    :disabled="loading"
                >
                    <v-icon color="primary" small> edit </v-icon>
                </v-btn>
                <v-btn
                    elevation="2"
                    @click="() => deleteItem(index)"
                    style="height: 30px; margin: 2px"
                    :loading="loading" 
                    :disabled="loading"
                >
                    <v-icon color="primary" small> mdi-delete </v-icon>
                </v-btn>
                </td>
            </tr>
            </tbody>
        </template>
        </v-data-table>
        <!-- End Table Solutions -->
    </v-col>
</template>
<script>
import { mapActions } from "vuex";
import WaterScanService from '@/services/firebase/WaterScan/WaterScanService';
import Utils from "@/scripts/Utils";
import Rules from "@/utils/Rules";

export default {
    props: {
        proposal: {
            type: Object,
            require: true,
        },
        solutions: {
            type: Array,
            require: true,
        },
    },
    data: () => ({
        field: 'servicePrice',
        key: `proposed.servicePrice`,
        id: null,
        headers: [
            { text: "Serviço", value: "serviceName" },
            { text: "Quantidade", value: "amount" },
            { text: "R$ Unidade", value: "unitPrice" },
            { text: "Total", value: "total" },
            { text: "Ações ", value: "action" },
        ],
        modal: false,
        loading: true,
        focus: false,
        blur: false,
        error: false,
        list: [],
        mode: 'create',
        headerTxt: 'Adição de Serviço',
        saveButtonTxt: 'Adicionar',
        indexEdit: null,
        item: {
            serviceName: '',
            service: '',
            amount: null,
            unitPrice: null,
            total: 0,
        },
        errors: {
            service: false,
            amount: false,
            unitPrice: false,
            total: false,
        },
    }),
    computed: {
        ruleRequired() {
            return Rules.required()
        },
        ruleValue() {
            return Rules.number(true, 0)
        },
        hasError() {
            return Object.values(this.errors).reduce((prev, cur) => prev || cur, false)
        },
        hasDisabled() {
            return this.hasError || this.focus
        },
        solutionsServiceList() {
            let data = this.solutions.filter(({type})=> type === "Serviço")
            const orderSolution = (itemA, itemB) => {
                let solutionNameA = ((itemA.group ? itemA.group : '') + (itemA.solutionCod ? itemA.solutionCod : ''))
                let solutionNameB = ((itemB.group ? itemB.group : '') + (itemB.solutionCod ? itemB.solutionCod : ''))
                if (solutionNameA > solutionNameB) {
                    return 1
                } else if (solutionNameA < solutionNameB) {
                    return -1
                } else {
                    return 0
                }
            }
            data.sort(orderSolution)
            return data;
        },
    },
    methods: {
        ...mapActions([
            'publishChannel',
        ]),
        formatNumber(value, numberDigits = 2) {
            return Utils.formatNumber(value, numberDigits)
        },
        formatCurrency(value, numberDigits = 2) {
            if (value < 0) {
                return `R$ -${this.formatNumber(value, numberDigits)}`
            } else {
                return `R$ ${this.formatNumber(value, numberDigits)}`
            }
        },
        resetItem() {
            this.item = {
                serviceName: '',
                service: '',
                amount: null,
                unitPrice: null,
                total: 0,
            }
            this.errors = {
                service: false,
                amount: false,
                unitPrice: false,
                total: false,
            }
        },
        addNewItem() {
            if (this.$refs['modal']) this.$refs['modal'].reset()
            this.resetItem()
            this.mode = 'create'
            this.headerTxt = 'Adição de Serviço'
            this.indexEdit = null
            this.saveButtonTxt = 'Adicionar'
            this.openModal()
        },
        openModal() {
            this.modal = true
        },
        closeModal() {
            this.modal = false
        },
        setLoading(status) {
            this.loading = status
            this.publishChannel({
                channel: 'waterscan',
                event: `${this.key}:loading`,
                value: status,
            })
        },
        setDisabled(status) {
            this.publishChannel({
                channel: 'waterscan',
                event: `${this.key}:disabled`,
                value: status,
            })
        },
        setError(error, key) {
            this.errors[key] = error
        },
        setFocus(status) {
            this.focus = true
            this.blur = false
        },
        setBlur(status) {
            this.focus = false
            this.blur = true
        },
        forceUpdateList(newList) {
            this.list = Utils.copyObject(newList)
        },
        async saveItem() {
            if (!this.$refs['modal'].validate()) return
            this.setLoading(true)
            if (this.mode == 'create') {
                this.list.push(Utils.copyObject(this.item))
            } else {
                this.list[this.indexEdit] = Utils.copyObject(this.item)
            }
            this.forceUpdateList(this.list)
            try {
                this.emitList()
                this.setLoading(false)
                this.closeModal()
            } catch (e) {
                console.error(e)
                this.setLoading(false)
            }
        },
        async editItem(index) {
            this.resetItem()
            this.mode = 'edit'
            this.indexEdit = index
            this.headerTxt = 'Edição de Serviço'
            this.saveButtonTxt = 'Editar'
            this.item = Utils.copyObject(this.list[index])
            this.openModal()
        },
        async deleteItem(index) {
            this.setLoading(true)
            try {
                this.list.splice(index, 1)
                this.emitList()
                this.closeModal()
                this.setLoading(false)
            } catch (e) {
                console.error(e)
                this.setLoading(false)
            }
        },
        emitList() {
            this.publishChannel({
                channel: 'waterscan',
                event: this.key,
                val: Utils.copyObject(this.list),
            })
        },
        getSolution(solutionId) {
            return this.solutions.find((item) => item.id == solutionId)
        },
    },
    watch: {
        async proposal(newValue) {
            if (newValue) {
                this.id = newValue.id
                try {
                    this.key = `proposed.${this.field}`
                    try {
                        this.list = Utils.getting(newValue, this.key)
                    } catch (e) {
                        this.list = []
                        await WaterScanService.updateById(this.key, this.list, this.id)
                    }
                    let unnamedServices = this.list.filter(({ serviceName }) => serviceName === undefined || serviceName === "" || serviceName === null).map(({ service }) => service)
                    if (unnamedServices.length > 0) {
                        this.list = this.list.map(
                            (item) => {
                                const { serviceName, service } = item
                                if (serviceName === undefined || serviceName === "" || serviceName === null) {
                                    const solution = this.getSolution(service)
                                    if (!solution) {
                                        return {
                                            ...item,
                                            serviceName: "",
                                        }
                                    }
                                    return {
                                        ...item,
                                        serviceName: solution.solution,
                                    }
                                }
                                return item
                            }
                        )
                        await WaterScanService.updateById(this.key, this.list, this.id)
                    }
                    this.emitList()
                    this.setLoading(false)
                } catch (e) {
                    console.error(e)
                    this.setLoading(false)
                }
            }
        },
        hasDisabled(newVal) {
            if (newVal) {
                this.setDisabled(true)
            } else {
                this.setDisabled(false)
            }
        },
        'item.service': function (newVal) {
            if (newVal) {
                const solution = this.getSolution(newVal)
                this.item.unitPrice = parseFloat(solution.price)
                this.item.serviceName = solution.solution
            }
        },
        'item.amount': function (newVal) {
            if (newVal !== null) {
                this.item.total = this.item.unitPrice * newVal
            }
        },
        'item.unitPrice': function (newVal) {
            if (newVal !== null) {
                this.item.total = this.item.amount * newVal
            }
        },
    },
}

</script>