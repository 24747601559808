

export const MenuFeatures = {
  UNITS: "units",
  ALERTS: "alerts",
  CONFIGS: "configs",
  CALLEDS: "calleds",
  WATERFIX: "waterfix",
  DASHBOARD: "dashboard",
  WATERSCAM: "waterscan",
  SOLUTIONS: "solutions",
  CONSUMPTION: "consumption",
  REPORTS: "reports",
  GOAL_FORECASTS: "goal_forecasts",
  PROJECTS: "projects",
}

export const SubMenuFeatures = {
  GOAL_ALERTS: "goal_alerts",
  TELEMETRY_ALERTS: "telemetry_alerts",
  CONSUMPTION_ALERTS: "consumption_alerts",

  ECONOMY_REPORT: "economy_report",
  CALLED_REPORT: "called_report",
  ALERTS_REPORT: "alerts_report",
  TELEMETRY_REPORT: "telemetry_report",

  USERS_CONFIG: "users_configs",
  MODEMS_CONFIG: "modens_configs",
  ALERTS_CONFIG: "alerts_configs",
  PERMISSIONS_CONFIG: "permissions_configs",
  WATERAUTHORITIES_CONFIG: "waterauthorities_configs",

  CONSULT_WATERSCAN: "consult_waterscan",
  SIMPLIFIED_WATERSCAN: "simplified_waterscan",
  
  CONSULT_WATERFIX: "consult_waterfix",
  
  SOLUTIONS: "solutions_solutions",
  GROUPS: "groups_solutions",
  POINTS: "points_solutions",
}

// CREATE_WATERFIX: "sub_button_create_waterfix",
// CREATE_WATERSCAN: "sub_button_create_waterscan",
