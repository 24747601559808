<template>
  <div>
    <div>
      <span v-for="option in filterOptionsSetting()" :key="option.name">
        <v-btn color="white" id="btn-styles-options" @click="optionClick(option)">
          <v-card class="text-xs-center ma-3">
            <v-responsive class="pt-4">
              <div>
                <v-icon class="icon-style-options" x-large>
                  {{ option.icon }}
                </v-icon>
              </div>
            </v-responsive>
            <v-card-actions>
              <div style="
                  width: 100%;
                  color: #737373;
                  font-size: 1.2rem;
                  text-transform: capitalize;
                ">
                {{ option.name }}
              </div>
            </v-card-actions>
          </v-card>
        </v-btn>
      </span>
    </div>

    <!-- Inicio consulta matricula -->
    <v-dialog v-model="enrollDialog" max-width="300px">
      <v-card width="100%">
        <v-card-title> Consultar Matrícula </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="validForm" lazy-validation>
            <v-text-field label="Matrícula" v-model="registration" v-mask="'#########'" type="number"
              :rules="rulesRequired">
            </v-text-field>
          </v-form>
          <v-btn width="100%" color="primary" :disabled="!validForm" @click="checkRegistrationFirebase(registration)">
            Consultar
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="enrollDialog = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Fim consulta matricula -->

    <!-- Inicio Nova Proposta -->
    <NewWaterScan 
      :open="openNewProposal" 
      :closeFunc="closeComponent" 
      :registration="registration" 
      :idProposal="idProposal" 
    />
    <!-- Fim Nova Proposta -->
    <!-- Inicio Nova Proposta -->
    <NewProposalSimplified 
      :openNewProposalSimplified="openNewProposalSimplified" 
      :closeFunc="closeComponent" 
      :registration="registration" 
      :idProposal="idProposal" 
    />
    <!-- Fim Nova Proposta -->
    <!-- Start Dialog Alert -->
    <v-dialog v-model="dialogAlert" max-width="300px">
      <v-card width="300px">
        <v-card-title>
          <h3 style="text-align: center; width: 100%">Alerta</h3>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text style="font-size: 1.2rem">
          {{ textAlert }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="openViewConsult()"> Consultar </v-btn>
          <v-btn color="primary" text @click="dialogAlert = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Start Dialog Alert -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NewWaterScan from "./NewProposal/NewWaterScan.vue";
import NewProposalSimplified from "./NewProposalSimplified/NewProposalSimplified.vue";

import {  SubMenuFeatures } from "@/router/features.js";
import { RoutesPath } from '@/router/paths.js';
import WaterScanService from '@/services/firebase/WaterScan/WaterScanService';
export default {
  name: "ChooseWaterScan",
  components: {
    NewWaterScan,
    NewProposalSimplified,
  },
  data() {
    return {
      validForm: true,
      textAlert:
        "Já existe uma proposta vinculada a essa matrícula!\n Verifique em Consultar Proposta.",
      dialogAlert: false,
      registration: "",
      idProposal: "",
      openNewProposal: false,
      openNewProposalSimplified: false,
      enrollDialog: false,
      optionsSettings: [
        {
          name: "Nova Proposta",
          feature: SubMenuFeatures.CONSULT_WATERSCAN,
          icon: "mdi-book-plus",
          path: RoutesPath.WATER_SCAN_ALL,
        },
        {
          name: "Nova Proposta Simplificada",
          feature: SubMenuFeatures.SIMPLIFIED_WATERSCAN,
          icon: "mdi-book-plus",
          path: RoutesPath.WATER_SCAN_ALL,
        },
        {
          name: "Consultar Proposta",
          feature: SubMenuFeatures.CONSULT_WATERSCAN,
          icon: "mdi-book-search",
          path: RoutesPath.WATER_SCAN_ALL,
        },
      ],
    };
  },
  computed: {
    // Incialização dos getters definido na inicialização do vue.store em index.js
    ...mapGetters([
      "getCurrentUserData",
    ]),
    rulesRequired() {
      return [
        (value) => !!value || "Matrícula requerida",
        (value) => !(value && value.length < 9) || `Mínimo de 9 caratere(s)`,
      ];
    },
  },

  methods: {
    openViewConsult() {
      this.$router.push(`/water-scan/consult-proposal/${this.idProposal}`);
    },
    closeComponent() {
      this.openNewProposal = false;
      this.openNewProposalSimplified = false;
      this.enrollDialog = false;
    },
    async optionClick(option) {
      if (option.name == "Nova Proposta"){
        this.idProposal =  await WaterScanService.generateIdDocRef()
        this.openNewProposal = true;
      }else if(option.name == "Nova Proposta Simplificada"){
        this.idProposal =  await WaterScanService.generateIdDocRef()
        this.openNewProposalSimplified = true;
      }
      else this.$router.push(option.path);
    },

    filterOptionsSetting() {
      return this.optionsSettings.filter(({feature}) => this.getCurrentUserData.permissions.includes(feature))
    },
  },
};
</script>

<style scoped>
#btn-styles-options {
  margin-left: 25px;
  margin-top: 25px;
  width: 15rem;
  height: 15rem;
  border-radius: 1rem;
  padding: 0px;
  /* box-shadow: inset 0 0 1em gold, 0 0 1em black; */
  box-shadow: 0 0 0.5em black;
}

.icon-style-options {
  color: #737373 !important;
  font-size: 10rem !important;
}

.v-card:hover {
  background-color: #eeeeee;
}

.v-card {
  width: 15rem;
  height: 15rem;
  margin: 0;
  border-radius: 1rem;
}
</style>