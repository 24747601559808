import apiService, { HOST } from "./BaseApi";
import Utils from '@/scripts/Utils';
import store from '@/store/index'


const ENDPOINT_PROPOSAL_PATH = "waterscan/proposal";
const ENDPOINT_SIMPLIFIED_PROPOSAL_PATH = "waterscan/simplified-proposal";
const ENDPOINT_CHECKLIST_PATH = "waterscan/checklist";
const ENDPOINT_CHECKLIST_WITH_PHOTO_PATH = "waterscan/checklist-with-photo";



const WaterScanApi = {
    generateProposalPdf: async (proposalId) => await apiService.post(ENDPOINT_PROPOSAL_PATH, { proposalId, currentUserId: store.state.currentUser?.uid, env: Utils.getEnv() }),
    generateChecklistPdf: async (proposalId) => await apiService.post(ENDPOINT_CHECKLIST_PATH, { proposalId, currentUserId: store.state.currentUser?.uid, env: Utils.getEnv() }),
    generateChecklisWithPhototPdf: async (proposalId) => await apiService.post(ENDPOINT_CHECKLIST_WITH_PHOTO_PATH, { proposalId, currentUserId: store.state.currentUser?.uid, env: Utils.getEnv() }),
    generateSimplifiedProposalPdf: async (proposalId) => await apiService.post(ENDPOINT_SIMPLIFIED_PROPOSAL_PATH, { proposalId, currentUserId: store.state.currentUser?.uid, env: Utils.getEnv() }),

    downloadPdf: async (fileName, newName) => {
        newName = newName.split(" ").join("_");
        window.open(HOST + `download/v2/${fileName}/${newName}`)
    },
};

export default WaterScanApi;
