import CryptoJS from 'crypto-js';
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

const secret_key = "ciJhFiivVuJ4F6MeCpq"


var keySize = 256;
var ivSize = 128;
var iterations = 100;

function encrypt(msg, pass) {
  var salt = CryptoJS.lib.WordArray.random(128 / 8);

  var key = CryptoJS.PBKDF2(pass, salt, {
    keySize: keySize / 32,
    iterations: iterations
  });

  var iv = CryptoJS.lib.WordArray.random(ivSize / 8);

  var encrypted = CryptoJS.AES.encrypt(msg, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
    hasher: CryptoJS.algo.SHA256
  });

  // salt, iv will be hex 32 in length
  // append them to the ciphertext for use  in decryption
  var transitmessage = salt.toString() + iv.toString() + encrypted.toString();
  return transitmessage;
}

function decrypt(transitmessage, pass) {
  var salt = CryptoJS.enc.Hex.parse(transitmessage.substr(0, 32));
  var iv = CryptoJS.enc.Hex.parse(transitmessage.substr(32, 32))
  var encrypted = transitmessage.substring(64);

  var key = CryptoJS.PBKDF2(pass, salt, {
    keySize: keySize / 32,
    iterations: iterations
  });

  var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC,
    hasher: CryptoJS.algo.SHA256
  })
  return decrypted.toString(CryptoJS.enc.Utf8);
}

Vue.use(Vuex)

export default new VuexPersist({
  key: 'data',
  storage: window.localStorage,
  saveState: (key, state, storage) => {
    let currentUser = state.currentUser;
    let json_payload = JSON.stringify({
      loggedIn: state.loggedIn,
      currentUser: currentUser
    });
    let encrypted_txt = encrypt(json_payload, secret_key)
    return storage.setItem(key, encrypted_txt)
  },
  restoreState: (key, storage) => {
    try {
      let encrypted_txt = storage.getItem(key)
      let decrypted_txt = decrypt(encrypted_txt, secret_key)
      let json_parsed = JSON.parse(decrypted_txt)
      return json_parsed;
    } catch (e) {
      return;
    }
  }
})

