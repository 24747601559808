import DatePickerDialog from "@/components/DatePickerDialog/DatePickerDialog.vue";
import SelectClient from "@/components/Widgets/Select/select-client";
import SelectFranchisee from "@/components/Widgets/Select/select-franchisee.vue";
import SelectUnit from "@/components/Widgets/Select/select-unit.vue";
import ChartAlert from "@/components/charts/ChartsDashboard/Alert.vue";
import ChartCall from "@/components/charts/ChartsDashboard/Call.vue";
import ChartConsumption from "@/components/charts/ChartsDashboard/Consumption.vue";
import Economy from "@/pages/Dashboard/Widgets/economy.vue";
import GeneralDetails from "@/pages/Dashboard/Widgets/generalDetails.vue";
import endpoint from "@/request/endpoint/dashboard.js";
import DashboardService from "@/services/server/DashboardService.js";
import ConsumptionService from "@/services/server/ConsumptionService.js";
import Utils from '@/scripts/Utils';




import KpiCard from "@/components/KPI/KpiCard.vue";
import Alerts from "@/components/charts/ChartsDashboard/Alerts.vue";

import UnitsService from "@/services/server/UnitsService";
import AlertsNotificationsService from "@/services/server/AlertsNotificationService";
import { mapActions, mapGetters } from "vuex";



export default {
  name: "Dashboard",
  components: {
    KpiCard,
    DatePickerDialog,
    GeneralDetails,
    ChartAlert,
    ChartConsumption,
    ChartCall,
    SelectFranchisee,
    SelectClient,
    SelectUnit,
    Economy,
    Alerts,
  },

  data() {
    return {
      startDateDialog: false,
      startDatePicker: [Utils.formatDate(Utils.get_last_months()[0], '<YYYY>-<MM>'), Utils.formatDate(Utils.get_last_months()[5], '<YYYY>-<MM>')],
      franchisee_id: null,
      client_id: null,
      unit_id: null,
      openSelct: false,
      units: [],
      unit_ids: [],
      current_index_id: 0,
      is_executing: false,
      is_cycling: false,
      period: 6,
      progress_bar_fn: null,
      has_meta: false,
      parsed: null,
      selected: {
        franchisee_id: null,
        client_id: null,
        unit_id: null,
      },
      loading: {
        alerts: true,
        KPIData: true,
        consumption: true,
      },
      consumptionChatData: {
        labels: [],
        dataset: []
      },
      continuousCycle: true,
      intervalId: null,
      isMounted: false,
      alertsData: [],
      KPIData: {
        alerts: '0',
        calleds: '0',
        totalFinancialEconomy: 'R$ 0',
        lastDateReading: "--/--/--"
      },
      KPIDataColor: {
        alerts: 'primary',
        lastDateReading: 'primary',
      },
      econometerData: {
        avaregeFinancialEconomy: 0,
        avaregeWaterEconomy: 0,
        minFinancialEconomy: 0,
        minWaterEconomy: 0,
        totalFinancialEconomy: 0,
        totalWaterEconomy: 0,
      },
      display: {
        unit_name: "",
        percentage: 0,
        consumption: {
          title: "Consumo",
          percentage: 0,
          is_loading: false,
          data: {
            labels: [],
            datasets: [],
          }
        },
        economy: {
          title: "Economia",
          percentage: 0,
          is_loading: false,
          data: {
            consumption: {
              average: 0,
              min: 0,
              max: 0,
              total: 0,
              formatAverage: Utils.formatNumber(0),
              formatMin: Utils.formatNumber(0),
              formatMax: Utils.formatNumber(0),
              formatTotal: Utils.formatNumber(0),
            },
            valueCurrency: {
              average: 0,
              min: 0,
              max: 0,
              total: 0,
              formatAverage: Utils.formatCurrency(0),
              formatMin: Utils.formatCurrency(0),
              formatMax: Utils.formatCurrency(0),
              formatTotal: Utils.formatCurrency(0),
            },
            savingConsumption: {
              average: 0,
              min: 0,
              max: 0,
              total: 0,
              formatAverage: Utils.formatNumber(0),
              formatMin: Utils.formatNumber(0),
              formatMax: Utils.formatNumber(0),
              formatTotal: Utils.formatNumber(0),
            },
            savingCurrency: {
              average: 0,
              min: 0,
              max: 0,
              total: 0,
              formatAverage: Utils.formatCurrency(0),
              formatMin: Utils.formatCurrency(0),
              formatMax: Utils.formatCurrency(0),
              formatTotal: Utils.formatCurrency(0),
            }
          }
        },
        call: {
          title: "Chamados",
          percentage: 0,
          is_loading: false,
          data: {
            labels: [],
            datasets: [],
            total: 0,
          }
        },
        alert: {
          title: "Alerta",
          percentage: 0,
          is_loading: false,
          data: {
            data: [],
            labels: [],
            total: 0,
          }
        },
        goal: {
          title: "Meta",
          percentage: 0,
          is_loading: false,
          data: {
            last_reading_date: null,
            next_reading_date: null,
            end_reading_date: null,
            meta_estimate: 0,
            economy_estimate: 0,
            total_calls_finished: 0,
          }
        },
      },
      request: {
        consumption: null,
        call: null,
        alert: null,
        goal: null,
        reading: null,
        historic: null,
      },
      stack_execution: [],
      is_active_execution: false,
      is_process_execution: false,

      units_id_allowed_current_user: [],
      hasUnits: true,
    };
  },
  computed: {
    ...mapGetters([
      "getUsers",
      "getCurrentUser",
      "getCurrentUserRank",
      "getCurrentUserID",
    ]),
    first_row_lg() {
      return this.has_meta ? 10 : 12
    },
    format_meta() {
      if (this.get_diff_meta == 0) return "--"
      return `${Utils.formatNumber(this.get_diff_meta)} M³`;
    },
    get_style_meta() {
      if (this.get_diff_meta == 0) return "padding: 15px;background-color: grey;color: white;font-weight: bold;font-size: 2vh;border-radius: 30px;";
      else if (this.get_diff_meta > 0) return "padding: 15px;background-color: rgb(34, 140, 34);color: white;font-weight: bold;font-size: 2vh;border-radius: 30px;";
      else return "padding: 15px;background-color: red;color: white;font-weight: bold;font-size: 2vh;border-radius: 30px;";
    },
    get_text_meta() {
      if (this.get_diff_meta == 0) return "--"
      else if (this.get_diff_meta > 0) return "Economia"
      else return "Ultrapassou"
    },
    get_text_style_meta() {
      if (this.get_diff_meta == 0) return "padding: 15px;color: grey;font-weight: bold;font-size: 2.2vh;"
      else if (this.get_diff_meta > 0) return "padding: 15px;color: rgb(34, 140, 34);font-weight: bold;font-size: 2.2vh;"
      else return "padding: 15px;color: red;font-weight: bold;font-size: 2.2vh;"
    },
    get_last_reading_date() {
      if (!this.display.goal.data.last_reading_date) return "--/--/--";
      return Utils.formatDate(this.display.goal.data.last_reading_date, "<DD>/<MM>/<YYYY>")
    },
    get_next_reading_date() {
      if (!this.display.goal.data.next_reading_date) return "--/--/--";
      return Utils.formatDate(this.display.goal.data.next_reading_date, "<DD>/<MM>/<YYYY>")
    },
    get_meta_estimate() {
      return Utils.formatNumber(this.display.goal.data.meta_estimate);
    },
    get_economy_estimate() {
      return Utils.formatNumber(this.display.goal.data.economy_estimate)
    },
    get_total_alerts() {
      return this.display.alert.data.total
    },
    get_end_reading_date() {
      if (!this.display.goal.data.end_reading_date) return "--/--/--";
      return Utils.formatDate(this.display.goal.data.end_reading_date, "<DD>/<MM>/<YYYY>")
    },
    get_total_calls_finished() {
      return this.display.goal.data.total_calls_finished
    }
  },

  methods: {
    ...mapActions([
      "getUnitsBy",
      "initUsersAndUnits",
      "getUsersBy",
    ]),
    async clearDateCalendar() {
      this.startDatePicker = [Utils.formatDate(Utils.get_last_months()[0], '<YYYY>-<MM>'), Utils.formatDate(Utils.get_last_months()[5], '<YYYY>-<MM>')]
    },
    async selectDateCalendar(date) {
      if (this.startDatePicker[0] > this.startDatePicker[1]) {
        let date_1 = this.startDatePicker[1]
        let date_2 = this.startDatePicker[0]
        this.startDatePicker[0] = date_1
        this.startDatePicker[1] = date_2
      }
      this.startDateDialog = false

    },
    add_execution(fn_promise) {
      this.stack_execution.push(fn_promise)
    },
    catch_franchisee_id(franchisee_id) {
      this.franchisee_id = franchisee_id;
    },
    catch_client_id(client_id) {
      this.client_id = client_id;
    },
    async catch_unit_id(unit_id) {
      this.unit_id = unit_id;
      if (this.units.length <= 0) return;
    },
    async searchUnit() {

      this.continuousCycle = false
      this.display.unit_name = this.get_unit_name(this.unit_id);
      this.openSelct = !this.openSelct


      await Promise.all([this.getAlertsData(this.unit_id), this.getKPIsData(this.unit_id), this.getConsumptionData(this.unit_id)])
    },

    set_selected(franchisee_id, client_id, unit_id) {
      this.selected = {
        franchisee_id,
        client_id,
        unit_id,
      }
    },

    clear_selected() {
      this.set_selected(null, null, null)
    },

    get_next_unit() {
      let parsed = {
        "type": null,
        "id": null,
        "unit_id": null,
        "start_date": this.startDatePicker[0],
        "end_date": this.startDatePicker[1],
      }
      if (this.selected.unit_id !== null) {
        if (this.selected.unit_id !== 'selecionar_todos') {
          parsed = {
            type: "unit",
            id: this.selected.unit_id,
            unit_id: this.selected.unit_id,
            "start_date": this.startDatePicker[0],
            "end_date": this.startDatePicker[1],
          }
        } else if (this.selected.client_id) {
          parsed = {
            type: "client",
            id: this.selected.client_id,
            unit_id: this.selected.unit_id,
            "start_date": this.startDatePicker[0],
            "end_date": this.startDatePicker[1],
          }
        } else if (this.selected.franchisee_id) {
          parsed = {
            type: "franchisee",
            id: this.selected.franchisee_id,
            unit_id: this.selected.unit_id,
            "start_date": this.startDatePicker[0],
            "end_date": this.startDatePicker[1],
          }
        }
      } else {
        let max_length = this.unit_ids.length
        if (this.current_index_id >= max_length) this.current_index_id = 0;
        parsed.type = "unit"
        parsed.id = this.unit_ids[this.current_index_id]
        parsed.unit_id = this.unit_ids[this.current_index_id]
        this.current_index_id++;
      }
      return parsed;
    },
    set_loading_display(type, status) {
      this.display[type].is_loading = status
    },

    get_loading_display(type) {
      return this.display[type].is_loading
    },

    set_display_data(type, data) {
      this.display[type].data = {
        ...this.display[type].data,
        ...data
      }
    },

    get_user_name(id) {
      let user = this.getUsers.find(
        (u) => {
          return u.id == id
        }
      )
      return user.hasOwnProperty('data') ? user.data.name : user.name;
    },

    get_unit_name(unit_id) {
      let unit = this.units.find(
        (u) => {
          return u.id == unit_id;
        }
      )
      return unit.name;
    },

    set_unit_name(parsed) {
      if (parsed.unit_id != 'selecionar_todos') {
        this.display.unit_name = this.get_unit_name(parsed.unit_id);
      } else if (parsed.type !== null) {
        this.display.unit_name = this.get_user_name(parsed.id);
      } else {
        this.display.unit_name = ''
      }
    },


    async getNextReading(parsed) {
      let type = parsed.type;
      if (type !== 'unit') {
        this.request.historic = null
      }
      let response = await endpoint.historic({
        id: parsed.id,
      })
      let historic = response.status ? response.unit.historicos : []
      let dates = historic.map(h => Utils.instanceDate(`${h.month}-${h.day}`))
      dates = dates.sort((datePrevious, dateNext) => {
        if (datePrevious < dateNext) return -1;
        else if (datePrevious > dateNext) return 1;
        else return 0;
      });
      let lastDate = null
      if (dates.length > 0) lastDate = dates.pop()
      // Fazer a função para gerar o próximo dia
      let list_days = []
      if (lastDate) list_days = Utils.generate_list_monthly_by_day_fixed(lastDate, 12)
      let next_reading_date = null;
      let now = Utils.instanceDate('now')
      let now_month = Utils.getDateInfo(now, false)['month']
      // let previous_date = null
      if (list_days.length > 0) {
        for (let i = 1; i < list_days.length; i++) {
          let current_date = list_days[i]
          let diff = Utils.diff_days(now, current_date)
          if (diff < 1 || (diff > 28 && diff < 30)) {
            next_reading_date = Utils.add_day(Utils.getMidnight(now), -7)
            break;
          } else if (diff >= 1 && diff <= 28) {
            next_reading_date = current_date
            break;
          }
        }
      }
      return next_reading_date;
    },




    async getKPIsData(unit_id) {
      this.loading.KPIData = true;
      this.KPIDataColor.lastDateReading = "primary"
      this.KPIDataColor.alerts = "primary"

      this.econometerData = {
        avaregeFinancialEconomy: 0,
        avaregeWaterEconomy: 0,
        minFinancialEconomy: 0,
        minWaterEconomy: 0,
        totalFinancialEconomy: 0,
        totalWaterEconomy: 0,
        maxWaterEconomy: 0,
        maxFinancialEconomy: 0,
      }



      await DashboardService.findKPIbyUnit(unit_id)
        .then((res) => {
          const dataKPI = res.data.data

          const formatCurrencyValue = (value) => {
            return new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(value);
          }


          this.econometerData.avaregeFinancialEconomy = formatCurrencyValue(dataKPI.econometer.avaregeFinancialEconomy)
          this.econometerData.minFinancialEconomy = formatCurrencyValue(dataKPI.econometer.minFinancialEconomy)
          this.econometerData.totalFinancialEconomy = formatCurrencyValue(dataKPI.econometer.totalFinancialEconomy)
          this.econometerData.maxFinancialEconomy = formatCurrencyValue(dataKPI.econometer.maxFinancialEconomy)

          this.econometerData.minWaterEconomy = (dataKPI.econometer.minWaterEconomy).toFixed(2)
          this.econometerData.avaregeWaterEconomy = (dataKPI.econometer.avaregeWaterEconomy).toFixed(2)
          this.econometerData.totalWaterEconomy = (dataKPI.econometer.totalWaterEconomy).toFixed(2)
          this.econometerData.maxWaterEconomy = (dataKPI.econometer.maxWaterEconomy).toFixed(2)




          this.KPIData.lastDateReading = dataKPI.lastDateReading ? Utils.formatDateDayJs(dataKPI.lastDateReading, "DD/MM/YYYY [as] HH:MM") : "--/--/--"
          this.KPIData.totalFinancialEconomy = formatCurrencyValue(dataKPI.totalFinancialEconomy)
          this.KPIData.alerts = String(dataKPI.alerts)
          this.KPIData.calleds = String(dataKPI.calleds)

          if (new Date(new Date(dataKPI.lastDateReading).setHours(0, 0, 0, 0)).getTime() < new Date(new Date().setHours(0, 0, 0, 0)).getTime()) this.KPIDataColor.lastDateReading = "red"
          if (dataKPI.alerts) this.KPIDataColor.alerts = "red"

        }).finally(() => this.loading.KPIData = false)
    },
    async getConsumptionData(unit_id) {
      this.loading.consumption = true;
      await ConsumptionService.findByUnit({
        start_date: Utils.subtractDate(new Date(), 15),
        end_date: new Date(),
        mode: 'day',
        unit_measurement: 'liters',
        unit_id
      }).then((res) => {
        const resultConsumptionsUnit = res.data.data


        const labels = resultConsumptionsUnit.consumptions.map(consumption => {
          return new Date(consumption.time).toLocaleDateString();
        });
        const dataset = [
          {
            label: 'Água',
            data: resultConsumptionsUnit.consumptions.map(consumption => consumption.valueWater),  // Exemplo: [10, 20, 30, 0]
            backgroundColor: '#2FA4DD',
            borderColor: '#2FA4DD',
            fill: false,
            draw: false,
            borderSkipped: false,
            // barThickness: 30,
            order: 1
          },
          {
            label: 'Efluentes',
            data: resultConsumptionsUnit.consumptions.map(consumption => consumption.valueSewer),  // Exemplo: [5, 0, 10, 15]
            backgroundColor: '#0A517F',
            borderColor: '#0A517F',
            fill: false,
            draw: false,
            borderSkipped: false,
            // barThickness: 30,
            order: 1
          },
          {
            label: 'Média Água',
            data: Array(labels.length).fill(resultConsumptionsUnit.totalWaterAverageCurrent), // Cria um array com a média repetida
            type: 'line',
            borderColor: '#0E76BC',
            backgroundColor: '#0E76BC',
            borderWidth: 1,
            fill: false,
            pointRadius: 0, // Remove os pontos da linha
            order: 2,
          },
          // Linha média para Consumo de Esgoto
          {
            label: 'Média Efluentes',
            data: Array(labels.length).fill(resultConsumptionsUnit.totalSewerAverageCurrent), // Cria um array com a média repetida
            type: 'line',
            borderColor: '#0a317f',
            backgroundColor: '#0a317f',
            borderWidth: 1,
            fill: false,
            pointRadius: 0, // Remove os pontos da linha
            order: 2,
          },
        ]
        this.consumptionChatData.labels = labels
        this.consumptionChatData.dataset = dataset

      }).finally(() => this.loading.consumption = false)


    },
    async getAlertsData(unit_id) {
      this.loading.alerts = true
      const teste = await AlertsNotificationsService.findByUnit(unit_id)
      this.alertsData = teste.data
      this.loading.alerts = false
    },
    async execute() {
      const execute = async () => {
        try {
          if (!this.isMounted || !this.continuousCycle) {
            clearTimeout(this.intervalId);
            return;
          }
          this.parsed = this.get_next_unit();
          this.set_unit_name(this.parsed);
          this.loading.consumption = true;

          await Promise.all([this.getAlertsData(this.parsed.unit_id), this.getKPIsData(this.parsed.unit_id), this.getConsumptionData(this.parsed.unit_id)])


        } catch (error) {
          console.error('Erro durante a execução:', error);
        } finally {
          if (this.continuousCycle) this.intervalId = setTimeout(execute, 10000);
        }
      };
      execute();
    },
    checkContinuousCycle() {
      this.continuousCycle = !this.continuousCycle
      if (this.continuousCycle) this.execute()
    },
  },
  async mounted() {
    this.isMounted = true;
    this.units = UnitsService.findAll()
      .then((res) => {
        this.units = res.data.data
        this.unit_ids = this.units.map(unit => unit.id)
        this.current_index_id = 0;
        if (this.units.length <= 0) {
          this.hasUnits = false
        }
        if (this.units.length > 0) this.execute();
      })
      .catch()

  },
  beforeDestroy() {
    this.isMounted = false;
    clearTimeout(this.intervalId);
  },
};