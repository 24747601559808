<template>
    <div style="display: flex;justify-content: center;align-items: center;margin-right: 10px;width: 80px;">
        <v-chip
          :color="getColorMonth()"
        >
          {{ getTagMonth() }}
        </v-chip>
    </div>
</template>

<script>
import DatePickerDialog from "@/components/DatePickerDialog/DatePickerDialog.vue";
import Utils from '@/scripts/Utils';

import { showErrorSnackBar } from "@/utils/SnackBartHelper"

/**
 * 
 * @params {String} competence: é o campo da competência do mês no format de 'YYYY-MM'
 * @params {Array} listCompetencies é um array de datas no formato 'YYYY-MM' que são as competências já criadas
*/
export default {

  components: {
    DatePickerDialog,
  },

  props: {
    instalattionDate: {
        type: String,
        required: false,
        default: null,
    },
    month: {
        type: String,
        required: true,
    }
  },

  data: () => ({
    
  }),

  computed: {
    
  },

  methods: {
    getColorMonth() {
      if (!this.instalattionDate) return "grey";
      let dateMonth = Utils.instanceDate(this.month)
      let _instalattionDate = Utils.instanceDate(this.instalattionDate)
      if (dateMonth < _instalattionDate) return "red"
      else return "green"
    },
    getTagMonth() {
      if (!this.instalattionDate) return "--";
      let dateMonth = Utils.instanceDate(this.month)
      let _instalattionDate = Utils.instanceDate(this.instalattionDate)
      if (dateMonth < _instalattionDate) return "Antes";
      else return "Depois";
    },
  },

  watch: {
    
  },

};
</script>