import Rules from "@/utils/Rules";

import HeaderCheckList from "@/components/Projects/Diagnostic/Tabs/CheckList/HeaderCheckList.vue"
import CardCheckList from "@/components/Projects/Diagnostic/Tabs/CheckList/CardCheckList.vue"
import HydrometerList from "@/components/Projects/Diagnostic/Tabs/CheckList/HydrometerList.vue"
import BombList from "@/components/Projects/Diagnostic/Tabs/CheckList/BombList.vue"
import ReservoirList from "@/components/Projects/Diagnostic/Tabs/CheckList/ReservoirList.vue"
import SolutionList from "@/components/Projects/Diagnostic/Tabs/CheckList/SolutionList.vue"

const newCheckList = () => {
    return {
        tipoProjeto: "",
        numeroUsuarios: "",
        torresBlocosGalpoes: "",
        totalDeUnidades: "",
        totalWcs: "",
        unidadesPorPavimento: "",
        wcsPorUnidade: "",
        chuveiroGas: "",
        chuveiroEletrico: "",
        pavimentos: "",
        solucoes: [],
        bombas: [],
        hidrometros: [],
        reservatorios: [],
    }
}

export default {
    name: "CheckListTab",
    
    components: {
        HeaderCheckList,
        CardCheckList,
        HydrometerList,
        BombList,
        ReservoirList,
        SolutionList,
    },
    
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        param: {
            type: Object,
            default: () => ({})
        },
        projectId: {
            type: String,
            default: null,
        }
    },
    
    data: () => ({
        is_loading: false,
        diagnostic: {},
        options: [
            { text: "Apto Individual", value: "single_apartment" },
            { text: "Casa", value: "home" },
            { text: "Cond. Residencial", value: "residential_condominium" },
            { text: "Cond. Empresarial", value: "business_condominium" },
            { text: "Cond. Misto", value: "mixed_condominium" },
            { text: "Comercial", value: "commercial" },
            { text: "Industrial", value: "industry" },
            { text: "Orgão Público", value: "public_agency" },
        ],
        checkList: newCheckList(),
    }),

    computed: {
        rulesRequired() {
            return Rules.required();
        },
        rulesCEP() {
            return Rules.cep();
        },
        rulesLogradouro() {
            return Rules.string(false, null, 128);
        },
        rulesCity() {
            return Rules.string(false, null, 64);
        },
        rulesDistrict() {
            return Rules.string(false, null, 64);
        },
        rulesAdditionalAddress() {
            return Rules.string(false, null, 64);
        },
        rulesHouseNumber() {
            return Rules.string(false, null, 16);
        },
    },

    methods: {
        emitDiagnostic() {
            this.$emit('param:update', this.diagnostic)
        },
        emitValidate(status) {
            this.$emit('validate', status)
        },
        getGroupProjectType() {
            if (['single_apartment', 'home'].includes(this.checkList.tipoProjeto)) {
                return '1'
            } else {
                //  Cond. Residencial / Cond. Empresarial / Cond. Misto / Comercial / Indústria / Orgão Público
                return '2'
            }
        },
        showHydrometerList() {
            return true
        },
        showBombList() {
            return true
        },
        showReservoirList() {
            return true
        },
        addHydrometer(hydrometer, index) {
            console.log("Adicionando o hidrometro...", hydrometer, index)
            if (index < 0) {
                this.checkList.hidrometros.push(hydrometer)
            } else {
                this.checkList.hidrometros[index] = hydrometer
            }
        },
        removeHydrometer(index) {
            console.log("Removendo o hidrometro...", index)
            this.checkList.hidrometros = this.checkList.hidrometros.filter((_, i) => i !== index)
        },
        addBomb(bomb, index) {
            console.log("Adicionando o bomba...", bomb, index)
            if (index < 0) {
                this.checkList.bombas.push(bomb)
            } else {
                this.checkList.bombas[index] = bomb
            }
        },
        removeBomb(index) {
            console.log("Removendo o bomba...", index)
            this.checkList.bombas = this.checkList.bombas.filter((_, i) => i !== index)
        },
        addReservoir(reservoir, index) {
            console.log("Adicionando o reservatorio...", reservoir, index)
            if (index < 0) {
                this.checkList.reservatorios.push(reservoir)
            } else {
                this.checkList.reservatorios[index] = reservoir
            }
        },
        removeReservoir(index) {
            console.log("Removendo o reservatorio...", index)
            this.checkList.reservatorios = this.checkList.reservatorios.filter((_, i) => i !== index)
        },
        addSolution(solution, index) {
            console.log("Adicionando o solução...", solution, index)
            if (index < 0) {
                this.checkList.solucoes.push(solution)
            } else {
                this.checkList.solucoes[index] = solution
            }
        },
        removeSolution(index) {
            console.log("Removendo o solução...", index)
            this.checkList.solucoes = this.checkList.solucoes.filter((_, i) => i !== index)
        },
    },

    watch: {
        param: {
            immediate: true,
            handler: function (newValue) {
                if (newValue) {
                    this.diagnostic = newValue
                }
            }
        },
        'checkList.tipoProjeto': function (newValue) {
            console.log("checkList.tipoProjeto - watch", newValue)
            if (['single_apartment', 'home'].includes(newValue)) {
                this.checkList.torresBlocosGalpoes = ""
                this.checkList.pavimentos = ""
                this.checkList.unidadesPorPavimento = ""
                this.checkList.wcsPorUnidade = ""
                this.checkList.totalDeUnidades = ""
            } else {
                this.checkList.numeroUsuarios = ""
                this.checkList.chuveiroEletrico = ""
                this.checkList.chuveiroGas = ""
            }
        },
    },

};