<template>
    <div 
        name="historic-container" 
        v-if="show" 
        style="
            width: 100%;
            margin-top: 72px;
        "
    >
        <v-form style="height: 100%;">
            
            <HistoricList 
                :list="items.historics"
                :add="addHistoric"
                :remove="removeHistoric"
            />

        </v-form>
    </div>
</template>
  
<script>
import Historic from "./Historic.js";
export default Historic;
</script>
  
<style scoped>
.no-margin {
    margin: 0;
}
.d-flex {
    display: flex;
}
.f-align-center {
    align-items: center;
}
.f-1 {
    flex: 1;
}
.f-2 {
    flex: 2;
}
</style>