<template>
  <div>
    <span v-for="option in filterOptionsSetting()" :key="option.name">
      <v-btn
        color="white"
        id="btn-styles-options"
        @click="optionClick(option.path)"
        :loading="isLoading"
        :disabled="option.disabled"
      >
        <v-card class="text-xs-center ma-3">
          <v-responsive class="pt-4">
            <div>
              <v-icon class="icon-style-options" x-large>
                {{ option.icon }}
              </v-icon>
            </div>
          </v-responsive>
          <v-card-actions>
            <div
              style="
                width: 100%;
                color: #737373;
                font-size: 1.2rem;
                text-transform: capitalize;
              "
            >
              {{ option.name }}
            </div>
          </v-card-actions>
        </v-card>
      </v-btn>
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Settings",
  props: ['routes', 'isLoading'],
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "getCurrentUserData"
    ]),
  },
  methods: {
    optionClick(path) {
      this.$router.push(path);
    },
    filterOptionsSetting() {
      return this.routes.filter(({feature}) => this.getCurrentUserData.permissions.includes(feature))
    },
  },
};
</script>

<style scoped>
#btn-styles-options {
  margin-left: 25px;
  margin-top: 25px;
  width: 15rem;
  height: 15rem;
  border-radius: 1rem;
  padding: 0px;
  box-shadow: 0 0 0.5em black;
}
.icon-style-options {
  color: #737373 !important;
  font-size: 10rem !important;
}
.v-card:hover {
  background-color: #eeeeee;
}
.v-card {
  width: 15rem;
  height: 15rem;
  margin: 0;
  border-radius: 1rem;
}
</style>