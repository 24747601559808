<template >
    <v-container fluid>
        <v-container style="background-color: white;border-radius: 20px;box-shadow: 2px 2px;">
            <header style="display: flex;justify-content: space-between;">
                <div>
                    <h1 style="border-bottom: 2px solid black;">
                        Concessionária
                    </h1>
                    <h2 style="margin-top: 10px">Compesa</h2>
                </div>
                <div style="display: flex;justify-content: center;align-items: center;">
                    <v-btn
                        class="mx-2"
                        fab
                        dark
                        color="black"
                        @click="FSM('create')"
                        :disabled="firstLoading"
                        :loading="firstLoading"
                    >
                        <v-icon dark>
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </div>
            </header>
        </v-container>
        
        <v-item-group active-class="primary">
            <v-container>
                <v-row v-if="isLoading">
                    <v-col
                        v-for="n in 3"
                        :key="n"
                        cols="12"
                        md="4"
                    >
                        <skeleton-component 
                            style="height: 120px;border-radius: 20px;"
                        ></skeleton-component>
                    </v-col>
                </v-row>
                <v-row v-if="!isLoading">
                    <v-col
                        v-for="intervalConsumption in list"
                        :key="intervalConsumption.id"
                        cols="12"
                        md="4"
                    >
                        <v-hover v-slot="{ hover }">
                            <v-card
                                class="d-flex align-center"
                                dark
                                height="200"
                                style="height: 120px;border-radius: 20px;background-color: #fffafa;position:relative;"
                            >
                                <div
                                    class="text-h2 flex-grow-1 text-center"
                                >
                                    <span style="font-size: 30px;font-weight: bold;color: black;">{{ formatDateInterval(intervalConsumption) }}</span>
                                </div>
                               
                                    <div
                                        v-if="hover"
                                        class="d-flex   v-card--reveal text-h2 white--text"
                                        style="background: #ebeeef;height: 100%;position: absolute;width:100%;border-radius: 20px;display:flex;flex-direction:column;"
                                    >
                                        <div style="width: 100%;display: flex;justify-content: center;align-items: center;">
                                            <span style="font-size: 16px;font-weight: bold;color: black;">{{ formatDateInterval(intervalConsumption) }}</span>
                                        </div>
                                        <div style="width: 100%;display: flex;justify-content: center;align-items: center;">
                                            <v-btn
                                                class="mx-2"
                                                fab
                                                dark
                                                small
                                                color="primary"
                                                @click="FSM('view', {id: intervalConsumption.id})"
                                            >
                                                <v-icon dark>
                                                    mdi-eye
                                                </v-icon>
                                            </v-btn>
                                            <v-btn
                                                class="mx-2"
                                                fab
                                                dark
                                                small
                                                color="cyan"
                                                @click="FSM('edit', {id: intervalConsumption.id})"
                                            >
                                                <v-icon dark>
                                                    mdi-pencil
                                                </v-icon>
                                            </v-btn>
                                            <v-btn
                                                class="mx-2"
                                                fab
                                                dark
                                                small
                                                color="red"
                                                @click="FSM('delete', {id: intervalConsumption.id})"
                                            >
                                                <v-icon dark>
                                                    mdi-delete
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                              
                            </v-card>
                        </v-hover>
                    </v-col>
                </v-row>
            </v-container>
        </v-item-group>

        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="concessionareModal"
            @click:outside="FSM('close-modal')"
        >
            <v-card>
                <v-toolbar
                color="primary"
                dark
                >{{ concessionareModalTitle }}</v-toolbar>
                <v-card-text style="padding: 20px 0 20px 0;">
                    <v-col>
                        <v-text-field
                            label="Mês da Tarifa"
                            outlined
                            :rules="[val => (val || '').length > 0]"
                            @click="dateDialog = true, alertDialog = false"
                            
                            :value="getDateTariff"                            
                            :readonly="isView"
                            :disabled="isView"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-divider></v-divider>
                        <v-tabs
                            v-model="tabConcessionare"                         
                    
                            >
                            <v-tab
                                v-for="type in typesConcessionare"
                                :key="type"
                            >
                                {{ tariffsTitle[type] }}
                            </v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tabConcessionare">
                            <v-tab-item
                                v-for="type in typesConcessionare"
                                :key="type"
                                
                                
                            >
                                <v-row>
                                    <v-col 
                                        cols="12"
                                        v-for="(intervalConsumption, indexIntervalConsumption) in concessionare.tariffs[type]"
                                        :key="intervalConsumption.start_liter"
                                    >
                                        <v-card-subtitle>{{ formatTitleInterval(intervalConsumption) }}</v-card-subtitle>
                                        <v-text-field
                                            hide-details
                                            single-line
                                            densehide-details
                                            type="number"
                                            step="any"
                                            outlined
                                            clearable
                                            :rules="[val => (val || '').length > 0]"
                                            style="padding-left: 13px !important; padding-right: -13 !important;"
                                            placeholder="00.00"
                                            prefix="R$"
                                            v-model="concessionare.tariffs[type][indexIntervalConsumption].value"
                                            @click="alertDialog = false"
                                            :readonly="isView"
                                            :disabled="isView"
                                            
                                        ></v-text-field>
                                    </v-col>
                                    <v-col >
                                        <v-alert 
                                        style="padding-left: 13px !important; padding-right: -13 !important;"
                                        v-if="alertDialog" 
                                        dense 
                                        outlined 
                                        type="error">
                                            {{message}}
                                        </v-alert>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-col>
                </v-card-text>
                <v-card-actions class="justify-end">
                <v-btn
                    :loading="isLoadingDialog"
                    :disabled="isLoadingDialog"
                    text
                    @click="FSM('close-modal')"
                >Fechar</v-btn>
                <v-btn
                    depressed
                    color="primary"
                    :loading="isLoadingDialog"
                    :disabled="isLoadingDialog"
                    @click="FSM('save-modal')"
                >Salvar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <date-picker-dialog 
            title="Escolha o mês da tarifa"
            type="month"
            :show="dateDialog"
            @close="status => dateDialog = status"
            :input="concessionare.date"
            @change="newDate => concessionare.date = newDate"
        />

    </v-container>
</template>

<script>
import CompesaScript from './CompesaScript';
export default CompesaScript;
</script>

<style>
    /* .skeleton-loader {
        animation: skeleton-loading 1s linear infinite alternate;
    }
    @keyframes skeleton-loading {
        0% {
            background-color: rgba(25, 118, 210, .3);
        }
        100% {
            background-color: rgba(0, 0, 0, .50);
        }
    } */
    .hover-options {
        align-items: center;
        bottom: 0;
        justify-content: center;
        opacity: .5;
        position: absolute;
        width: 100%;
    }.errorclass{

        border-color: rgb(255, 0, 0);
    }
</style>