import apiService from "./BaseApi";
import store from '@/store/index'
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"

const BASE_BODY = {
    currentUserId: store.state.currentUser?.uid,
}

const LeadsService = {
    searchLeadByDocument: async (document, id_franchisee) => {
        try {
            let response = await apiService.post(
                "leads/search", {
                    ...BASE_BODY,
                    document, 
                    id_franchisee,
                }
            )
            // showSuccessSnackBar(response.data.message)
            return [true, response.data.data]
        } catch (e) {
            console.error("error", e)
            showErrorSnackBar(e.response.data.message)
            return [false, null]
        }
    },
    createLead: async (lead) => {
        try {
            let response = await apiService.post(
                "leads", 
                lead
            )
            // showSuccessSnackBar(response.data.message)
            return [true, response.data.data]
        } catch (e) {
            console.error("error", e)
            showErrorSnackBar(e.response.data.message)
            return [false, null]
        }
    },
    updateLead: async (lead_id, lead) => {
        try {

            let response = await apiService.put(
                `leads/${lead_id}`, 
                lead
            )
            // showSuccessSnackBar(response.data.message)
            return [true, response.data.data]
        } catch (e) {
            console.error("error", e)
            showErrorSnackBar(e.response.data.message)
            return [false, null]
        }
    },
    updatePatchLead: async (lead_id, lead) => {
        try {
            let response = await apiService.patch(
                `leads/${lead_id}`, 
                lead
            )
            // showSuccessSnackBar(response.data.message)
            return [true, response.data.data]
        } catch (e) {
            console.error("error", e)
            showErrorSnackBar(e.response.data.message)
            return [false, null]
        }
    },
    getLeadById: async (id_lead) => {
        try {
            let response = await apiService.get(
                `leads/${id_lead}`
            )
            // showSuccessSnackBar(response.data.message)
            return [true, response.data.data]
        } catch (e) {
            console.error("error", e)
            showErrorSnackBar(e.response.data.message)
            return [false, null]
        }
    },
};

export default LeadsService;
