import check, { integer } from 'check-types'
import Model from '../../Model';

const KEYS = [
    'type',
    'imageList',
]

const STRUCT = {
    'type': {
        'default': "",
        'check': (val) => {
            if (check.not.string(val)) throw new Error("The type is not string")
        },
    },
    'imageList': {
        'default': [],
        'check': (val) => {
            if (check.not.array(val)) throw new Error("The image list is not a array")
            if (check.not.array.of.string(val)) throw new Error("The image list is not a string array")
        },
    },
}

export default class SignatureModel extends Model {
    constructor(signature = null) {
        super(KEYS, STRUCT);
        if (signature) this.set(signature)
    }
}