<template>
    <v-autocomplete
      v-bind="$attrs"
      item-text="name"
      item-value="id_doc"
      :items="list"
      :label="label"
      :disabled="isLoadingData || $attrs.disabled"
      :loading="isLoadingData || $attrs.loading"
      persistent-hint
      v-model="selectedItem"
      @change="selectItem"
    />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ResponsibleTypeService from "@/services/firebase/ResponsibleType/ResponsibleTypeService";

export default {
  props:{
    id_responsible_type: {
      type: String,
      default: null,
    }
  },  
  data: () => ({
    label: "Tipo",
    list: [],
    selectedItem: null,
    selectedItemProp: null,
    isLoadingData: true,
  }),

  computed: {
    ...mapGetters(["getFranqueados", "getCurrentUser", "getCurrentUserRank"]),
  },

  methods: {
    ...mapActions(["getClientsBy"]),
    selectItem() {
      console.log(this.selectedItem)
      if( this.selectedItem ) this.$emit('id', this.selectedItem)
      else this.$emit('id', null)
    },
  },

  watch: {
    id_responsible_type(newValue) {
      this.selectedItem = newValue
    },
  },

  async created() {
    this.list = await ResponsibleTypeService.getResponsibleTypes()
    this.isLoadingData = false
  }     
};
</script>


<style>
</style>