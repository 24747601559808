<template>
  <v-container fluid>

    <v-dialog transition="dialog-bottom-transition" max-width="800" v-if="modalRole" v-model="modalRole">
      <template v-slot:default>
        <v-card>
          <v-card-title class="primary lighten white--text">{{ modalTitle }}
            <v-spacer></v-spacer>
            <v-btn text class="litte white--text" @click="closeRoleModal()">
              <v-icon white>mdi-window-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>

            <v-form ref="form" v-model="validForm" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field label="Nome do Perfil" v-model="editedItem.name" placeholder="Nome"
                    :rules="rulesRequired"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <function-selector :features="featuresData"  :selected-features.sync="selectedFeatures"></function-selector>
                </v-col>

              </v-row>
            </v-form>

          </v-card-text>

          <v-card-actions class="justify-end">

            <v-btn :loading="loading.modalRole" text @click="save()"> {{modalTitle == "Editar Perfil"? 'Editar':'Salvar'}}</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <v-card style="border-radius: 20px">

          <v-row style="justify-content: space-between">
            <v-card-title class="title-displays"> Permissões </v-card-title>
            <v-card-title>
              <v-btn right @click="addNewAlert()" color="primary" text>
                <v-icon> add </v-icon>
                Adicionar Perfil
              </v-btn>
            </v-card-title>
            <v-text-field style="width:80%; padding:1em" class="px-4" prepend-icon="search" label="Busca"
              v-model="searchText"></v-text-field>
            <v-select hint="Buscar nessa coluna" persistent-hint style="width:20%; padding: 1em"
              v-model="columnToSearch" :items="columnsToChoose" return-object single-line></v-select>
          </v-row>


          <v-data-table :loading="loading.mainTable" :items="filteredItems" :headers="headers" :footer-props="{
            'items-per-page-options': [5, 10, 20, 30]
          }" no-data-text="Sem Perfis para exibir">

            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :loading="loading.buttonList" v-bind="attrs" v-on="on" color="primary"
                    style="font-weight: bold" outlined>
                    <v-icon small>
                      mdi-text-box-multiple-outline
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item link v-for="(line, index) in listActions" :key="index" :value="index">
                    <v-list-item-title @click="selectAction(item, line.value)">{{ line.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Alertscript from './RolesScript';
export default Alertscript;
</script>


<style scoped lang="scss">
.title-displays {
  font-size: 25px;
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>