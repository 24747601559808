<template>
    <v-row name="diagnostic-container" justify="center">
      <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card
          name="diagnostic-card"
        >
          <v-toolbar
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="() => close()"
              :loading="is_loading"
              :disabled="is_loading"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Diagnóstico</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="() => save()"
                :loading="is_loading"
                :disabled="is_loading"
              >
                Salvar
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <div
            name="diagnostic-tabs"
            style="
              padding-bottom: 72px;
              overflow-y: auto;
            "
          >
            <DiagnosticStepper 
              :steps="steps"
              @changeTab="nextTab"
            />
            <CheckListTab 
              :show="visibleTabs['checklist']"
              :param="diagnostic"
              :projectId="id_project"
              @param:update="updateDiagnostic"
              @validate="(status) => validateTab('checklist', status)"
            />
            <HistoricTab 
              :show="visibleTabs['historic']"
              :param="diagnostic"
              @param:update="updateDiagnostic"
              @validate="(status) => validateTab('historic', status)"
            />
            <NextStepProject
              :id_project="id_project"
              :loading="is_loading"
              :disabled="is_completed || !this.id_diagnostic"
              :nextStep="nextStep"
              @loading="(status) => is_loading = status"
            />
          </div>
        </v-card>
      </v-dialog>
    </v-row>
</template>
  
<script>
import Diagnostic from "./Diagnostic.js";
export default Diagnostic;
</script>
  
<style scoped>

</style>