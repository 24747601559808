<template>
  <div style="width: 100% !important">
    <h3>Tipo da Proposta:</h3>
    <v-form ref="form" v-model="validForm" lazy-validation>
      <v-row>
        <v-col cols="12" sm="3">
          <v-select
            outlined
            label="Tipo"
            :items="options"
            v-model="typeProposal.type"
            :rules="rulesRequired"
          >
          </v-select>
        </v-col>
        <!-- Start  Performance Model-->
        <v-col
          :cols="cols"
          sm="3"
          v-if="typeProposal.type === 'model_performance'"
        >
          <v-text-field
            outlined
            label="Cliente  Percentual"
            suffix="%"
            type="Number"
            v-model="typeProposal.client_percentage"
            :rules="rulesRequired"
          >
          </v-text-field>
        </v-col>
        <v-col
          :cols="cols"
          sm="3"
          v-if="typeProposal.type === 'model_performance'"
        >
          <v-text-field
            outlined
            label="Prestador  Percentual"
            suffix="%"
            type="Number"
            v-model="typeProposal.provider_percentage"
            :rules="rulesRequired"
          >
          </v-text-field>
        </v-col>
        <v-col
          :cols="cols"
          sm="3"
          v-if="typeProposal.type === 'model_performance'"
        >
          <v-text-field
            outlined
            label="Prazo do contrato "
            suffix="Meses"
            type="Number"
            v-model="typeProposal.contract_time"
            :rules="rulesRequired"
          >
          </v-text-field>
        </v-col>
  
        <!-- End Performance Model-->
        <!-- Start  Pre fixed-->
  
        <v-col :cols="cols" sm="3" v-if="typeProposal.type === 'pre_fixed' || typeProposal.type === 'club_foz'">
          <v-text-field
            outlined
            label="Mensalidade  Valor"
            prefix="R$"
            type="Number"
            v-model="typeProposal.monthly_payment"
            :rules="rulesRequired"
          >
          </v-text-field>
        </v-col>
        <v-col :cols="cols" sm="3" v-if="typeProposal.type === 'pre_fixed' || typeProposal.type === 'club_foz'">
          <v-text-field
            outlined
            label="Economia garantida R$"
            prefix="R$"
            type="Number"
            v-model="typeProposal.guaranteed_savings"
            :rules="rulesRequired"
          >
          </v-text-field>
        </v-col>
        <v-col :cols="cols" sm="3" v-if="typeProposal.type === 'pre_fixed' || typeProposal.type === 'club_foz'">
          <v-text-field
            outlined
            label="Prazo do contrato "
            suffix="Meses"
            type="Number"
            v-model="typeProposal.contract_time"
            :rules="rulesRequired"
          >
          </v-text-field>
        </v-col>
  
        <!-- End Pre fixed-->
        <!-- Start Investiment-->
  
        <v-col :cols="cols" sm="3" v-if="typeProposal.type === 'investment'">
          <v-text-field
            outlined
            label="Forma de Pagamento "
            v-model="typeProposal.form_payment"
            :rules="rulesRequired"
          >
          </v-text-field>
        </v-col>
  
        <!-- Start Value Sell-->
  
        <v-col :cols="cols" sm="3" v-if="typeProposal.type === 'payback_sell'">
          <v-text-field
            outlined
            label="Investimento Total"
            prefix="R$"
            v-model="typeProposal.total_investiment_for_type"
            :rules="rulesRequired"
          >
          </v-text-field>
        </v-col>
  
        <!-- End Investiment-->

        <v-col cols="12" style="margin-bottom: -30px">
          <h3>Informações Extras:</h3>
            <v-row>
              <v-col cols="6" style="margin-bottom: -30px">
                <v-text-field
                  outlined
                  suffix="%"
                  label="Economia Min"
                  type="Number"
                  v-model="typeProposal.paybackMax"
                  :rules="rulesRequired"
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" style="margin-bottom: -30px">
                <v-text-field
                  outlined
                  suffix="%"
                  label="Economia Max"
                  type="Number"
                  v-model="typeProposal.paybackMin"
                  :rules="rulesRequired"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          
        <v-col cols="12" style="margin-bottom: -30px">
          <v-text-field
            outlined
            label="Observações"
            type="text"
            v-model="typeProposal.obs"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12"><v-divider style="background-color: #C1E2F7"></v-divider></v-col>
      </v-row>
    </v-form>

    <ReportSolutions
      v-show="showSolututionPrice"
      :proposal="currentProposal"
    />
    <!-- Start DETALHAMENTO DO INVESTIMENTO -->
    <v-row class="dataCliente" style="margin-bottom: 8px">
      <v-col
        cols="12"
        style="
          background: rgb(25, 118, 210);
          color: white;
          padding: 6px;
          border-style: solid;
          border-width: 0 0px 2px 0px;
        "
      >
        <h3 style="text-align: center">DETALHAMENTO DO INVESTIMENTO</h3>
      </v-col>

      <table>
        <tr style="font-weight: bolder">
          <th style="background: rgb(221, 235, 247)">
            SERVIÇOS E SOLUÇÕES FOZ SUSTENTÁVEL
            <v-btn
              style="height: 100%"
              color="primary"
              text
              @click="showSolututionPrice = !showSolututionPrice"
            >
              <v-icon v-if="!showSolututionPrice">add</v-icon>
              <v-icon v-if="showSolututionPrice">mdi-window-minimize</v-icon>
            </v-btn>
          </th>
          <th
            style="
              background: rgb(25, 118, 210);
              color: white;
              text-align: center;
            "
          >
            GRUPO
          </th>
          <th
            style="
              background: rgb(25, 118, 210);
              color: white;
              text-align: center;
              width: 80px;
            "
          >
            QTD
          </th>
          <th
            style="
              background: rgb(25, 118, 210);
              color: white;
              text-align: center;
              width: 120px;
            "
          >
            (R$) UND
          </th>
          <th
            style="
              background: rgb(25, 118, 210);
              color: white;
              text-align: center;
              width: 120px;
            "
          >
            TOTAL
          </th>
        </tr>
        <tr
          style="font-weight: bolder"
          v-for="(solution, index) in solutionList"
          :key="index"
        >
          <td>{{ solution.solution }}</td>
          <td>{{ convertGroupNamePerSolution(solution.solutionId) }}</td>
          <td style="color: rgb(34, 194, 37); text-align: center">
            {{ solution.amount }}
          </td>
          <td style="color: rgb(34, 194, 37); text-align: left">
            {{ formatCurrency(solution.unitPrice) }}
          </td>
          <td style="color: rgb(34, 194, 37); text-align: left">
            {{ formatCurrency(solution.total) }}
          </td>
        </tr>
        <tr
          style="font-weight: bolder"
          v-for="(service, index) in serviceList"
          :key="'B' + index"
        >
          <td>{{ changeIdPerName(service.service) }}</td>
          <td>{{ convertGroupNamePerSolution(service.service) }}</td>
          <td style="color: rgb(34, 194, 37); text-align: center">
            {{ service.amount }}
          </td>
          <td style="color: rgb(34, 194, 37); text-align: left">
            {{ formatCurrency(service.unitPrice) }}
          </td>
          <td style="color: rgb(34, 194, 37); text-align: left">
            {{ formatCurrency(service.total) }}
          </td>
        </tr>
        <tr
          style="font-weight: bolder"
          v-for="(product, index) in productList"
          :key="'A' + index"
        >
          <td>{{ changeIdPerName(product.product) }}</td>
          <td>{{ convertGroupNamePerSolution(product.product) }}</td>
          <td style="color: rgb(34, 194, 37); text-align: center">
            {{ product.amount }}
          </td>
          <td style="color: rgb(34, 194, 37); text-align: left">
            {{ formatCurrency(product.unitPrice) }}
          </td>
          <td style="color: rgb(34, 194, 37); text-align: left">
            {{ formatCurrency(product.total) }}
          </td>
        </tr>
      </table>
      <v-row style="font-size: 1.3rem">
        <v-col cols="6">
          <strong><h3 style="margin-left: 6px">Investimento Total</h3></strong>
        </v-col>
        <v-col cols="6">
          <strong
            ><h3
              style="
                color: rgb(34, 194, 37);
                text-align: center;
                text-align: right;
                margin-right: 6px;
              "
            >
              {{ formatCurrency(sumValueTotal) }}
            </h3>
          </strong>
        </v-col>
      </v-row>
    </v-row>
    <!-- End DETALHAMENTO DO INVESTIMENTO -->
    <!-- Start DADOS DO CLIENTE -->
    <v-row class="dataCliente">
      <v-col
        cols="12"
        style="background: rgb(25, 118, 210); color: white; padding: 6px"
      >
        <h3 style="text-align: center">DADOS DO CLIENTE</h3>
      </v-col>
      <v-col md="4" lg="4" xl="4" sm="4" cols="12">
        Nome/Razão: {{ currenteProposalAdress.name }}
      </v-col>
      <v-col md="4" lg="4" xl="4" sm="4" cols="12">
        CPF/CNPJ: {{ currenteProposalAdress.localeData.idNumber }}
      </v-col>
      <v-col md="4" lg="4" xl="4" sm="4" cols="12">
        Endereço: {{ currenteProposalAdress.localeData.address.logradouro }}
      </v-col>
      <v-col md="4" lg="4" xl="4" sm="4" cols="12">
        Bairro: {{ currenteProposalAdress.localeData.address.bairro }}
      </v-col>
      <v-col md="4" lg="4" xl="4" sm="4" cols="12">
        Cidade: {{ currenteProposalAdress.localeData.address.cidade }}
      </v-col>
      <v-col md="4" lg="4" xl="4" sm="4" cols="12">
        Estado: {{ currenteProposalAdress.localeData.address.uf }}
      </v-col>
      <v-col md="4" lg="4" xl="4" sm="4" cols="12">
        CEP: {{ currenteProposalAdress.localeData.address.cep }}
      </v-col>
      <v-col md="4" lg="4" xl="4" sm="4" cols="12">
        Fone celular: {{ currenteProposalAdress.localeData.phone }}
      </v-col>
      <v-col md="4" lg="4" xl="4" sm="4" cols="12">
        e-mail: {{ currenteProposalAdress.email }}
      </v-col>
      <!-- <v-col
        cols="12"
        style="background: rgb(25, 118, 210); color: white; padding: 6px"
      >
        <h3 style="text-align: center">DADOS DA FATURA</h3>
      </v-col> -->
      <v-col
        cols="12"
        style="
          background: rgb(12, 165, 217);
          color: white;
          text-align: center;
          border-width: 0px 0px 2px 0px;
          border-style: solid;
          border-color: white;
          padding: 4px;
        "
      >
        <h3>RESUMO DO PROJETO</h3>
      </v-col>
      <v-col
        style="
          padding-top: 0px;
          border-width: 0px 3px 0px 0px;
          border-style: solid;
          border-color: rgb(25, 118, 210);
        "
        md="6"
        lg="6"
        xl="6"
        sm="6"
        cols="12"
      >
        <v-row>
          <v-col
            cols="12"
            style="background: rgb(25, 118, 210); color: white; padding: 6px"
          >
            <h3 style="text-align: center">EQUIPAMENTOS</h3>
          </v-col>
        </v-row>
        <v-row style="text-align: center">
          <v-col cols="6"> TOTAL DE EQUIPAMENTOS </v-col>
          <v-col cols="6"> {{ equipamentAmountComputed.total }} </v-col>
          <v-col cols="12" style="color: white">/ </v-col>
          <v-col cols="6"> ECONOMIA MÉDIA NOS PONTOS </v-col>
          <v-col
            cols="6"
            style="
              background: rgb(34, 194, 37);
              border-radius: 10px;
              color: white;
            "
          >
            {{ equipamentAmountComputed.averageEconomy }}%
          </v-col>
        </v-row>
      </v-col>
      <v-col style="padding-top: 0px" md="6" lg="6" xl="6" sm="6" cols="12">
        <v-row>
          <v-col
            cols="12"
            style="background: rgb(25, 118, 210); color: white; padding: 6px"
          >
            <h3 style="text-align: center">ÁGUA E ESGOTO</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6"> CONSUMO MÉDIO DE ÁGUA (M³) </v-col>
          <v-col style="text-align: center" cols="6">
            {{ consumptionValues.averageWater }} (M³)
          </v-col>
          <v-col cols="6"> CONSUMO MÉDIO DE ESGOTO (M³) </v-col>
          <v-col style="text-align: center" cols="6">
            {{ consumptionValues.averageSewer }} (M³)
          </v-col>
          <v-col cols="6"> CUSTO ANUAL DE A/E (R$) </v-col>
          <v-col style="text-align: center" cols="6">
            R$ {{ consumptionValues.sewerAndWaterYearly }}
          </v-col>
          <v-col cols="6"> ECONOMIA POTENCIAL (%) </v-col>
          <v-col style="text-align: center" cols="6"> 50% A 50% </v-col>
          <v-col cols="6"> ECONOMIA ANUAL POTENCIAL (R$) </v-col>
          <v-col
            style="
              text-align: center;
              color: white;
              background: rgb(34, 194, 37);
              border-radius: 10px 10px 0px 0px;
            "
            cols="6"
          >
            R$ {{ consumptionValues.potentialEconomyYearly }} A R$
            {{ consumptionValues.potentialEconomyYearly }}
          </v-col>
          <v-col cols="6"> ECONOMIA MÉDIA ANUAL DE ATÉ (R$) </v-col>
          <v-col
            style="
              text-align: center;
              background: rgb(30, 146, 66);
              color: white;
            "
            cols="6"
          >
            R$ {{ consumptionValues.potentialEconomyYearly }}
          </v-col>
          <v-col cols="6"> ECONOMIA MÉDIA ANUAL DE ATÉ (%) </v-col>
          <v-col
            style="
              text-align: center;
              border-radius: 0px 0px 10px 10px;
              color: white;
              background: rgb(34, 194, 37);
            "
            cols="6"
          >
            50%
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- End DADOS DO CLIENTE -->
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Rules from "@/utils/Rules";
import firebase from "../../../firebase";
import ReportSolutions from '@/components/Waterscan/ProposeTab/ReportSolutions.vue'
import WaterScanService from '@/services/firebase/WaterScan/WaterScanService';

import SolutionsServices from '@/services/firebase/SolutionsServices/SolutionsServices';
import SolutionsGroup from '@/services/firebase/SolutionsGroup/SolutionsGroup';
import Utils from '@/scripts/Utils';
export default {
  props: {
    requestData: {
      default: null,
    },
    requestSave: {
      default: null,
    },
    registration: {
      default: ""
    },
    idProposal: {
      default: ""
    },
    validFormProp: {
      default: () => {},
    },
    closeProposed: {
      default: () => {},
    },
    
  },
  name: "locationInformation",
  components: {
    ReportSolutions,
  },
  data: function () {
    return {
      cols: "12",
      mdApt: "4",
      lgApt: "4",
      xlApt: "4",
      smApt: "4",
      validForm: true,
      historicPage: [],
      equipamentAmount: { sues: 0, vortex: 0 },
      showSolututionPrice: false,
      currentProposal: {},
      currenteProposalAdress: {
        localeData: {
          address: {
            bairro: null,
            cep: null,
            cidade: null,
            complemento: null,
            logradouro: null,
            numero: null,
            uf: null,
          },
          idNumber: null,
          phone: null,
          idType: null,
        },
      },
      currenteProposalHistoric: null,
      solutionsData: [],
      groupsData: [],
      solutionList: [],
      serviceList: [],
      productList: [],
      typeProposal: {
        paybackMax: 30,
        paybackMin: 50,
        type: null
      },
      options: [
        { text: "Modelo Performace", value: "model_performance" },
        { text: "Venda do Projeto", value: "payback_sell" },
        { text: "Investimento", value: "investment" },
        { text: "Pré-Fixado", value: "pre_fixed" },
        { text: "Clube Foz", value: "club_foz" },
      ],
    };
  },
  methods: {
    ...mapActions([
      'subscribeChannel',
    ]),
    async listSolutions(){
      this.solutionsData =  await SolutionsServices.GetSolutions()
    },
    async listGroups(){
      this.groupsData =  await SolutionsGroup.getSolutionsGroup()
    },
    teste(){
      if (this.currentProposal.solutionList) {
        let list = []
        this.currentProposal.solutionList.forEach(solutionItem => {
          const idSolution = solutionItem.solution
          if(!list.find(item => item.id === idSolution)){
            const listSolutionOfId = this.currentProposal.solutionList.filter(solutionSum => idSolution === solutionSum.solution)
            const sum =  listSolutionOfId.length > 1? listSolutionOfId.reduce((a,b)=> Number(a.amountSolution) + Number(b.amountSolution)) : listSolutionOfId[0].amountSolution
            list.push({
              id: idSolution,
              total: Number(sum)
            })
          }
        });
        
        list.forEach((item) => {
          const solutionFinded = this.solutionsData.find((solution) => solution.id === item.id)
          const totalPrice = item.total*Number(solutionFinded.price)

          this.solutionList.unshift({
            solution: solutionFinded.solution,
            amount: item.total,
            unitPrice: Number(solutionFinded.price),
            total: totalPrice,
          });   
        })
      }
    },
    solutionName(solutionSlug) {
      if (solutionSlug !== null) {
        let response = solutionList.filter((i) => {
          return i.value == solutionSlug;
        });
        return response[0].text;
      }
    },
    checkSolutionsPrice() {
      let result = [];
      result = this.solutionList.filter((i) => {
        return i.unitPrice === null || i.unitPrice === "";
      });
      return result.length > 0 ? true : false;
    },
    async updateProposalinFirebase() {
      if (this.$refs.form && !this.$refs.form.validate()) {
          this.validFormProp(false,3)
          return;
        }
      let checkValues = this.checkSolutionsPrice();
      if (checkValues) {
        this.validFormProp(false,3)
        alert("Soluções e serviços sem preços!");
        return;
      }
      this.validFormProp(true,3)
      let objSave = {};
      objSave.proposed = {
        solutionPrice: this.solutionList,
        servicePrice: this.serviceList,
        productPrice: this.productList,
        typeProposal: this.typeProposal,
        summarySolution: {
          total: this.equipamentAmountComputed.total? this.equipamentAmountComputed.total: 0,
          averageEconomy: this.equipamentAmountComputed.averageEconomy? this.equipamentAmountComputed.averageEconomy: 0,
        },
      };
      this.$emit("update:overley", true);
      await WaterScanService
        .updateProposal(objSave, this.idProposal)
        .then(() => {
          this.$emit("update:overley", false);
        })
        .catch((err) => {
          this.$emit("update:overley", false);
          console.error(err);
          alert("Erro ao salvar proposta.");
        });
    },
    changeIdPerName(solutionId){
      const solutionFilter = this.solutionsData.filter(({id}) => id == solutionId)

      return solutionFilter.length > 0? solutionFilter[0].solution : "Não Existe"
    },
    convertGroupNamePerSolution(solutionId){
      const solutionFilter = this.solutionsData.filter(({id}) => id == solutionId)
      if(solutionFilter.length) {
        const groupFilter = this.groupsData.filter(({id}) => id == solutionFilter[0].groupId)
        return groupFilter.length > 0? groupFilter[0].name : "Não Existe"
      }

      return  "Não Existe"
    },
    async getCurrentProposal(pag) {
      if (pag == 3) {
        this.$emit("update:overley", true);
        await this.listSolutions();
        await this.listGroups();
        this.currentProposal = await WaterScanService.getProposalById(this.idProposal);

        if(this.currentProposal){
          this.currenteProposalAdress = this.currentProposal.locationInformation;
          this.currenteProposalHistoric = this.currentProposal.consumptionHistoric;
          if(this.currentProposal.proposed?.typeProposal) this.typeProposal = this.currentProposal.proposed.typeProposal;
        }

        this.$emit("update:overley", false);
      }else if (this.historicPage.includes(3)){
        await this.updateProposalinFirebase()
      }
      this.$emit("update:overley", false);
      this.historicPage.push(pag)
    },
    formatCurrency(number){
      return Utils.formatCurrency(number)
    },
  },
  computed: {
    rulesRequired() {
      return Rules.required();
    },
    equipamentAmountComputed() {
      let total = 0;
      let averageEconomy = 0;
      if(this.currentProposal.solutionList){
        const solutionList = this.currentProposal.solutionList
        for(let solution of solutionList){
          total += Number(solution.amountSolution)
          averageEconomy += (Number(solution.economy) / solutionList.length)
        }
        this.equipamentAmount.total = total;
        this.equipamentAmount.averageEconomy = Number(averageEconomy.toFixed(2));
        return this.equipamentAmount;

      }else return 0
    },
    sumValueTotal() {
      let total = 0;
      for (let key in this.solutionList) {
        total += this.solutionList[key].total;
      }
      for (let key in this.serviceList) {
        total += this.serviceList[key].total;
      }
      for (let key in this.productList) {
        total += this.productList[key].total;
      }
      return total.toFixed(2);
    },
    consumptionValues() {
      let consumptionNull = {
        sewerAndWaterYearly: 0,
        potentialEconomyYearly: 0,
        averageValue: 0,
        averageSewer: 0,
        averageWater: 0,
        comsumptionTotalSewer: 0,
        comsumptionTotalWater: 0,
      };
      let averageValue = 0;
      let averageSewer = 0;
      let averageWater = 0;
      let comsumptionTotalSewer = 0;
      let comsumptionTotalWater = 0;
      if (
        this.currenteProposalHistoric !== null &&
        this.currenteProposalHistoric !== undefined
      ) {
        if (this.currenteProposalHistoric.length > 0) {
          let avaregeValueDivision = this.currenteProposalHistoric.length;
          for (let key in this.currenteProposalHistoric) {
            averageValue += +this.currenteProposalHistoric[key].acountAmount;
            averageSewer += +this.currenteProposalHistoric[key].sewer;
            averageWater += +this.currenteProposalHistoric[key].water;
          }
          comsumptionTotalSewer = averageSewer;
          comsumptionTotalWater = averageWater;

          averageValue = averageValue / avaregeValueDivision;
          averageSewer = averageSewer / avaregeValueDivision;
          averageWater = averageWater / avaregeValueDivision;

          return {
            sewerAndWaterYearly: (averageValue * 12).toFixed(2),
            potentialEconomyYearly: (averageValue * 12 * 0.5).toFixed(2),
            averageValue: averageValue.toFixed(2),
            averageSewer: averageSewer.toFixed(2),
            averageWater: averageWater.toFixed(2),
            comsumptionTotalSewer: comsumptionTotalSewer.toFixed(2),
            comsumptionTotalWater: comsumptionTotalWater.toFixed(2),
          };
        } else {
          return consumptionNull;
        }
      } else {
        return consumptionNull;
      }
    },
  },
  created() {
    this.subscribeChannel({
        channel: 'waterscan',
        events: [
          // -----------------------------------------------------
          // Atualização da lista de serviços (checklist)
          {
            event: 'proposed.solutionPrice',
            fn: (solutionPrice) => {
              this.solutionList = solutionPrice
            }
          }, 
          {
            event: 'proposed.solutionPrice:loading',
            fn: (loading) => {
              
            }
          },
          // -----------------------------------------------------
          // Atualização da lista de serviços
          {
            event: 'proposed.servicePrice',
            fn: (servicePrice) => {
              this.serviceList = servicePrice
            }
          }, 
          {
            event: 'proposed.servicePrice:loading',
            fn: (loading) => {
              
            }
          },
          // -----------------------------------------------------
          // Atualização da lista de produtos
          {
            event: 'proposed.productPrice',
            fn: (productPrice) => {
              this.productList = productPrice
            }
          }, 
          {
            event: 'proposed.productPrice:loading',
            fn: (loading) => {
              
            }
          },
        ]
    })
  }
};
</script>
<style>
.dataCliente {
  border-style: solid;
  border-color: rgb(25, 118, 210);
  border-radius: 10px;
}
.textCenter {
  text-align: center;
}
table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd70;
}
</style>