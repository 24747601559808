import apiService from "./BaseApi";
import store from '@/store/index'
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"



const ProjectsService = {
    getProjects: async () => {
        try {
            let response = await apiService.post(
                "projects/list",
                {currentUserId: store.state.currentUser?.uid}
            )
            // showSuccessSnackBar(response.data.message)
            return [true, response.data.data]
        } catch (e) {
            console.error("error", e)
            showErrorSnackBar(e.response.data.message)
            return [false, null]
        }
    },
    getProjectById: async (id_project) => {
        try {
            let response = await apiService.get(
                `projects/${id_project}`,
                { currentUserId: store.state.currentUser?.uid }
            )
            // showSuccessSnackBar(response.data.message)
            return [true, response.data.data]
        } catch (e) {
            console.error("error", e)
            showErrorSnackBar(e.response.data.message)
            return [false, null]
        }
    },
    createProject: async (project) => {
        try {
            let response = await apiService.post(
                "projects", 
                    {
                        currentUserId: store.state.currentUser?.uid,
                        ...project
                    },
            )
            // showSuccessSnackBar(response.data.message)
            return [true, response.data.data]
        } catch (e) {
            console.error("error", e)
            showErrorSnackBar(e.response.data.message)
            return [false, null]
        }
    },
    nextStepProject: async (id_project) => {
        try {
            let response = await apiService.put(
                `projects/${id_project}/next-step`, {
                    currentUserId: store.state.currentUser?.uid
                }
            )
            // showSuccessSnackBar(response.data.message)
            return [true, response.data.data]
        } catch (e) {
            console.error("error", e)
            showErrorSnackBar(e.response.data.message)
            return [false, null]
        }
    },
};

export default ProjectsService;
