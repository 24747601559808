<template>
  <v-container center>
    <div :style="'width: ' + screenWidth + ' !important;'">
      <v-img id="bg" src="@/assets/bg-foz.jpg"></v-img>
    </div>
    <snack-bar-login :text="notification" />
    <v-dialog max-width="430" v-model="resetPasswordDialog" style="border-radius: 20px !important;">

      <v-card style="border-radius: 20px;">
        <v-form v-model="validFormRecovery" lazy-validation ref="FormRecoveryLogin"
          @submit.prevent="handlePasswordResetEmail">
          <v-card-text>
            <div
              style="display: flex; flex-direction: column; align-items: center; justify-content: center; margin-bottom: 30px; color: #1976d2;">
              <h1 style="text-align: center;">Recuperar Senha</h1>
            </div>
            <p>
              Digite seu e-mail para receber um link de atualização de senha.
            </p>
            <v-text-field required :rules="rulesRequiredEmail" style="border-radius: 10px;" solo
              placeholder="exemplo@exemplo.com" v-model="resetPasswordEmail" />
            <v-btn type="submit" depressed color="primary" @click="handlePasswordResetEmail" :disabled="isLoadingRecoveryPassword"
              width="100%" style="border-radius: 10px;">
              Enviar
            </v-btn>
          </v-card-text>

        </v-form>
      </v-card>
    </v-dialog>

    <div class="content">
      <v-layout column align-center>
        <v-flex>
          <v-card min-width="420px" style="border-radius: 20px;">

            <v-card-text>
              <v-form v-model="validForm" lazy-validation ref="FormLogin" @submit.prevent="logIn">
                <v-layout column style="margin: 30px;">
                  <div
                    style="display: flex; flex-direction: column; align-items: center; justify-content: center; margin-bottom: 30px; color: #1976d2;">
                    <v-img src="@/assets/logo2024.png" width="120" style="margin-bottom: 30px;" />
                    <h4 color="primary" style="text-align: center;">Seja bem vindo ao Central Foz</h4>
                    <h1 style="text-align: center;">Acessar minha conta</h1>
                  </div>
                  <v-text-field :rules="rulesRequiredEmail" solo v-model="email" placeholder="E-mail"
                    style="border-radius: 10px;" background-color="#E5EDF0" required />
                  <div style="display: flex; flex-direction: column; align-items: end; justify-content: center;">
                    <v-text-field :rules="rulesRequiredPassword" solo :type="isPasswordVisible ? 'text' : 'password'"
                      v-model="password" color="primary" placeholder="Senha" style="border-radius: 10px;width: 100%;"
                      background-color="#E5EDF0" :append-icon="isPasswordVisible ? 'visibility' : 'visibility_off'"
                      @click:append="togglePasswordVisibility" required />
                    <span style="cursor: pointer;" @click="resetPasswordDialog = true"> Esqueceu sua senha?</span>
                  </div>

                  <v-btn type="submit" depressed color="primary" @click="logIn" :disabled="isLoading" width="100%"
                    style="border-radius: 10px;">
                    Entrar
                  </v-btn>
                </v-layout>
              </v-form>
            </v-card-text>
            <v-progress-linear color="blue" :indeterminate="isLoading" />
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import firebase from "@/firebase";
import router from "@/router";
import SnackBarLogin from "@/pages/Login/SnackBarLogin.vue";
import { RoutesPath } from '@/router/paths.js';
import { MenuFeatures } from "@/router/features.js";
import Rules from "@/utils/Rules";

export default {
  name: "Login",
  components: {
    SnackBarLogin,
  },
  data: () => ({
    validForm: true,
    validFormRecovery: true,
    resetPasswordDialog: false,
    resetPasswordEmail: "",
    isPasswordVisible: false,
    isLoadingRecoveryPassword: false,
    isLoading: false,
    email: "",
    password: "",
    notification: null,
  }),
  async beforeMount() {
    if (this.isLoggedIn) router.push(RoutesPath.CONSUMPTION)
  },
  computed: {
    ...mapGetters(["isLoggedIn", "getCurrentUserData"]),
    rulesRequiredEmail() {
      return Rules.email(true);
    },
    rulesRequiredPassword() {
      return Rules.password();
    },
    screenWidth() {
      return this.$vuetify.breakpoint.width;
    },
  },
  methods: {
    ...mapActions([
      "loginUser",
    ]),
    async logIn() {
      if (this.$refs.FormLogin && !this.$refs.FormLogin.validate()) return;

      this.notification = null;
      this.isLoading = true;
      try {
        await this.loginUser({ email: this.email, password: this.password })
        this.redirectRouterPermission(this.getCurrentUserData.permissions)
      } catch (error) {
        this.handleLoginError(error);
      } finally { this.isLoading = false }
    },
    redirectRouterPermission(permissions) {
      if (permissions.includes(MenuFeatures.DASHBOARD)) router.push(RoutesPath.DASHBOARD);
      else if (permissions.includes(MenuFeatures.CONSUMPTION)) router.push(RoutesPath.CONSUMPTION);
      else if (permissions.includes(MenuFeatures.UNITS)) router.push(RoutesPath.UNITS);
      else if (permissions.includes(MenuFeatures.ALERTS)) router.push(RoutesPath.ALERTS);
      else if (permissions.includes(MenuFeatures.CONFIGS)) router.push(RoutesPath.CONFIGS);
      else if (permissions.includes(MenuFeatures.WATERSCAN)) router.push(RoutesPath.WATERSCAN);
      else if (permissions.includes(MenuFeatures.WATERFIX)) router.push(RoutesPath.WATERFIX);
      else if (permissions.includes(MenuFeatures.CALLEDS)) router.push(RoutesPath.CALLEDS);
      else if (permissions.includes(MenuFeatures.REPORTS)) router.push(RoutesPath.REPORTS);
      else if (permissions.includes(MenuFeatures.SOLUTIONS)) router.push(RoutesPath.SOLUTIONS);
      else router.push(RoutesPath.CONSUMPTION)
    },
    handleLoginError(error) {
      const errorMessages = {
        "auth/user-not-found": "E-mail e/ou Senha inválido",
        "auth/invalid-email": "E-mail e/ou Senha inválido",
        "auth/wrong-password": "E-mail e/ou Senha inválido",
        "auth/too-many-requests": "Muitas tentativas mal sucedidas, espere um pouco e tente novamente",
        default: "Erro ao enviar e-mail, tente novamente mais tarde",
      };
      this.notification = errorMessages[error.message] || errorMessages.default;
    },
    handleResetPasswordError(error) {
      const errorMessages = {
        "auth/user-not-found": "Foi enviado um email para sua caixa de entrada..",
        default: "Erro ao enviar e-mail, tente novamente mais tarde",
      };
      this.notification = errorMessages[error.code] || errorMessages.default;
    },
    async handlePasswordResetEmail() {
      if (this.$refs.FormRecoveryLogin && !this.$refs.FormRecoveryLogin.validate()) return;
      this.isLoadingRecoveryPassword = true;
      this.notification = null;
      try {
        await firebase.auth.sendPasswordResetEmail(this.resetPasswordEmail)
        this.notification = "Foi enviado um email para sua caixa de entrada";
      } catch (e) {
        this.handleResetPasswordError(e);
      } finally {
        this.resetPasswordDialog = false;
        this.isLoadingRecoveryPassword = false;
        this.resetPasswordEmail = ""
      }
    },
    togglePasswordVisibility() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
  },
};
</script>
<style>
.row {
  margin: 0px;
}

#bg {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

#bg-mobile {
  position: fixed;
  left: 0;
}

#bg-tablet {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, 40%);
}
</style>