<template>
    <div>
        <!-- start Investor Dialog -->
        <v-row justify="center">
            <v-dialog transition="dialog-top-transition" v-model="modal" persistent max-width="500px">
                <v-card>
                <v-form ref="downloadPdfForm" lazy-validation>
                    <v-toolbar color="primary" dark> <span class="text-h5">{{ headerTxt }}</span></v-toolbar>
                    <v-card-text style="padding-bottom: 0px;">
                        <v-row>
                            <v-col>
                                <v-text-field 
                                    label="Nome"
                                    v-model="item.name"
                                    :rules="ruleName"
                                    @change="setName"
                                    @focus="setFocus"
                                    @blur="setBlur"
                                    @update:error="getNameError"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field 
                                    label="Valor"
                                    type="number"
                                    v-model="item.value"
                                    :rules="ruleValue"
                                    @change="setValue"
                                    @focus="setFocus"
                                    @blur="setBlur"
                                    @update:error="getValueError"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field 
                                    label="Observação"
                                    v-model="item.comments"
                                    :rules="ruleComment"
                                    @change="setComment"
                                    @focus="setFocus"
                                    @blur="setBlur"
                                    @update:error="getCommentError"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="() => closeModal()" :loading="loading" :disabled="loading">
                            Cancelar
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="() => saveItem()" :loading="loading" :disabled="loading || hasDisabled">{{ saveButtonTxt }}</v-btn>
                    </v-card-actions> 
                </v-form>
                </v-card>
            </v-dialog>
        </v-row>
        <!-- end Investor Dialog -->

        <v-row>
          <!-- Start Table Solutions -->
          <v-data-table
            :headers="headers"
            :items="list"
            :items-per-page="12"
            class="elevation-1"
            style="width: 100%"
          >
            <template v-slot:top>
              <v-toolbar flat class="rounded-lg">
                <v-toolbar-title>Custos Adicionais</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="primary" class="mb-2" text @click="() => addNewItem()" :loading="loading" :disabled="loading">
                  <v-icon>add</v-icon>
                </v-btn>
              </v-toolbar>
            </template>
          
            <template v-slot:body="{ items }">
              <tbody>      
                <tr v-for="(item, index) in items" :key="index">
                  <td style="display: flex">
                    <v-text-field
                      v-model="item.name"
                      readonly
                    >
                    </v-text-field> 
                  </td>
                  <td>
                    <v-text-field
                      type="Number"
                      v-model="item.value"
                      preffix="R$"
                      readonly
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-model="item.comments"
                      readonly
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-btn
                      elevation="2"
                      @click="() => editItem(index)"
                      style="height: 30px; margin: 2px"
                      :loading="loading" 
                      :disabled="loading"
                    >
                      <v-icon color="primary" small> edit </v-icon>
                    </v-btn>
                    <v-btn
                      elevation="2"
                      @click="() => deleteItem(index)"
                      style="height: 30px; margin: 2px"
                      :loading="loading" 
                      :disabled="loading"
                    >
                      <v-icon color="primary" small> mdi-delete </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <!-- End Table Solutions -->
        </v-row>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import WaterScanService from '@/services/firebase/WaterScan/WaterScanService';
import WaterFixService from '@/services/firebase/WaterFix/WaterFixService';
import Utils from "@/scripts/Utils";
import Rules from "@/utils/Rules";

export default {
    props: {
        proposalType: {
            type: String,
            require: true,
        },
        roiType: {
            type: String,
            require: true,
        },
        proposal: {
            type: Object,
            require: true,
        },
    },
    data: () => ({
        field: 'costsAdditional',
        key: null,
        id: null,
        headers: [
            { text: "Nome", value: "name" },
            { text: "Valor", value: "value" },
            { text: "Observação", value: "comments" },
            { text: "Ações ", value: "action", width: 180 },
        ],
        modal: false,
        loading: true,
        focus: false,
        blur: false,
        error: false,
        list: [],
        mode: 'create',
        headerTxt: 'Adição de Custo',
        saveButtonTxt: 'Adicionar',
        indexEdit: null,
        item: {
            name: '',
            value: 0,
            comments: '',
        },
        errors: {
            name: false,
            value: false,
            comments: false,
        }
    }),
    computed: {
        ruleName() {
            return Rules.string(true, 1, 100)
        },
        ruleValue() {
            return Rules.number(true, 0)
        },
        ruleComment() {
            return Rules.string(false, 1, 100)
        },
        hasDisabled() {
            return Object.values(this.errors).reduce((prev, cur) => prev || cur, false) || this.focus
        },
    },
    methods: {
        ...mapActions([
            'publishChannel',
        ]),
        resetItem() {
            this.item = {
                name: '',
                value: 0,
                comments: '',
            }
            this.errors = {
                name: false,
                value: false,
                comments: false,
            }
        },
        addNewItem() {
            this.resetItem()
            this.mode = 'create'
            this.headerTxt = 'Adição de Custo'
            this.indexEdit = null
            this.saveButtonTxt = 'Adicionar'
            this.openModal()
        },
        openModal() {
            this.modal = true
        },
        closeModal() {
            this.modal = false
            // this.setDisabled(false)
        },
        setLoading(status) {
            this.loading = status
            this.publishChannel({
                channel: 'roi',
                event: `${this.key}:loading`,
                value: status,
            })
        },
        setDisabled(status) {
            this.publishChannel({
                channel: 'roi',
                event: `${this.key}:disabled`,
                value: status,
            })
        },
        getNameError(error) {
            this.errors.name = error
        },
        getValueError(error) {
            this.errors.value = error
        },
        getCommentError(error) {
            this.errors.comments = error
        },
        setFocus(status) {
            this.focus = true
            this.blur = false
        },
        setBlur(status) {
            this.focus = false
            this.blur = true
        },
        setName(value) {
            this.item.name = value
        },
        setValue(value) {
            this.item.value = value
        },
        setComment(value) {
            this.item.comments = value
        },
        forceUpdateList(newList) {
            this.list = Utils.copyObject(newList)
        },
        async saveItem() {
            if (this.error) return
            this.setLoading(true)
            if (this.mode == 'create') {
                this.list.push(Utils.copyObject(this.item))
            } else {
                this.list[this.indexEdit] = Utils.copyObject(this.item)
            }
            this.forceUpdateList(this.list)
            try {
                if (this.proposalType == 'waterscan') {
                    await WaterScanService.updateById(this.key, Utils.copyObject(this.list), this.id)
                } else {
                    
                }
                this.emitList()
                this.setLoading(false)
                this.closeModal()
            } catch (e) {
                console.error(e)
                this.setLoading(false)
            }
        },
        async editItem(index) {
            this.resetItem()
            this.mode = 'edit'
            this.indexEdit = index
            this.headerTxt = 'Edição de Custo'
            this.saveButtonTxt = 'Editar'
            this.item = Utils.copyObject(this.list[index])
            this.openModal()
        },
        async deleteItem(index) {
            this.setLoading(true)
            try {
                this.list.splice(index, 1)
                if (this.proposalType == 'waterscan') {
                    await WaterScanService.updateById(this.key, Utils.copyObject(this.list), this.id)
                } else {
                    
                }
                this.emitList()
                this.closeModal()
                this.setLoading(false)
            } catch (e) {
                console.error(e)
                this.setLoading(false)
            }
        },
        emitList() {
            this.publishChannel({
                channel: 'roi',
                event: this.key,
                val: Utils.copyObject(this.list),
            })
        },
    },
    watch: {
        async proposal(newValue) {
            if (newValue) {
                this.id = newValue.id
                try {
                    if (this.proposalType == 'waterscan') {
                        if (this.roiType == 'performance') {
                            this.key = this.field
                        } else if (this.roiType == 'sales') {
                            this.key = `${this.roiType}-roi.${this.field}`
                        } else {
                            throw new Error("Tipo do roi não identificado")
                        }
                        
                        this.list = []
                        try {
                            this.list = Utils.getting(this.proposal, this.key)
                        } catch (e) {
                            this.list = []
                            await WaterScanService.updateById(this.key, this.list, this.id)
                        }
                    } else {
                        
                    }
                    this.emitList()
                    this.setLoading(false)
                } catch (e) {
                    console.error(e)
                    this.setLoading(false)
                }
            }
            this.setLoading(false)
        },
        hasDisabled(newVal) {
            if (newVal) {
                this.setDisabled(true)
            } else {
                this.setDisabled(false)
            }
        },
    },
}

</script>