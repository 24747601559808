import { db } from "@/firebase"

const roles_ref = db.collection("roles")

async function getRoles() {
    let role_features_list = []
    try {
        const roleFeaturesRef = await roles_ref.where("deletedAt","==",null).get()
        roleFeaturesRef.forEach((doc) => role_features_list.push({ ...doc.data(), id_doc: doc.id }));
        return role_features_list
    } catch (error) {
        console.error(error)
    }
}
const createRole = async (data) => {
    try {
        const get_data = await roles_ref.add(data);
        await editRole(get_data.id, { ...data, id_doc: get_data.id })
        return get_data.id;
    } catch (error) {
        throw new Error("createRole Fail!" + error)
    }
}

const editRole = async (idDoc, data) => {
    try {
        await roles_ref.doc(idDoc).update(data);
        return true;
    } catch (error) {
        throw new Error("editRole Fail!" + error)
    }
}

export default {
    createRole,
    getRoles,
    editRole,
};