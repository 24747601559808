import check from 'check-types'
import Utils from '../scripts/Utils'

export default class Model {
    constructor(KEYS, STRUCT) {
        this.KEYS = KEYS
        this.STRUCT = STRUCT
        KEYS.forEach(
            (key) => {
                this[key] = STRUCT[key]['default']
            }
        )
    }

    /**
     * 
     * @returns A propria referência do objeto
     * 
     * O parametro da função terá a seguinte estrutura:
     *  01. Caso seja passado somente um parâmetro:
     *      - Será verificado se é um objeto, caso não será emitido um erro
     *      - Será verificado se tem alguma chave do objeto que não esteja na estrutura, caso tenha será emitido um erro
     *      - Para cada chave do objeto será verificado:
     *          - O tipo do dado se está de acordo com a estrutura, caso contrário será emitido um erro
     *          - Caso esteja de acordo com a estrutura será atribuido
     * 02. Caso seja passado dois parametros:
     *      - O primeiro parametro é a chave do objeto, será verificado se é uma string
     *      - Será verificado se a chave está definida na estrutura
     *      - O segundo parametro será o valor, será verificado se é um valor definido (que não seja null ou undefined)
     *      - Será verificado se o valor está de acordo com a checagem da estrutura
     *      - Caso esteja tudo de acordo será atribuído o valor a chave correspondente
     */
    set() {
        if (arguments.length == 1) {
            if (check.object(arguments[0])) {
                let solution = arguments[0]
                let allKeys = Object.keys(solution)
                let diffKeys = Utils.difference_array(allKeys, this.KEYS)
                if (diffKeys.length > 0) throw new Error(`The following keys are not allowed: '${diffKeys.join("','")}'`)
                Object.keys(solution).forEach(
                    (key) => {
                        this.STRUCT[key]['check'](solution[key])
                        this[key] = solution[key]
                    }
                )
            } else {
                throw new Error('The argument is expected to be an object')
            }
        } else if (arguments.length == 2) {
            let key = arguments[0]
            let value = arguments[1]
            if (check.not.string(key)) throw new Error('The key is expected to be a string')
            if (!this.KEYS.includes(key)) throw new Error(`The key '${key}' is not allowed`)
            if (check.not.assigned(value)) throw new Error('The value is expected to be some value')
            this.STRUCT[key]['check'](value)
            this[key] = value
        }
        return this
    }

    /**
     * 
     * @returns Objeto com as chaves da estrutura da classe requisitadas por parametro ou todas
     * 
     * O parametro será estrutura da seginte forma:
     *  01. Caso não seja passado nenhum parametro, será retornado em um objeto toda estrutura da classe
     *  02. Caso seja passado um ou mais parametro será retornado da seguinte forma:
     *      - Caso seja passado um parâmetro, será retornado o valor do próprio sem precisar está dentro de um objeto
     *      - Caso mais de um parâmetro, será retornado um objeto com os valores das chaves requisitados
     */
    get() {
        let response = {}
        if (arguments.length <= 0) {
            this.KEYS.forEach(
                (key) => {
                    response[key] = this[key]
                }
            )
        } else {
            for (let i = 0; i < arguments.length; i++) {
                let key = arguments[i]
                if (!this.KEYS.includes(key)) throw new Error(`The key '${key}' is not allowed`)
                response[key] = this[key]
            }
            if (arguments.length == 1) return response[arguments[0]]
        }
        return response
    }

    /**
     * 
     * @param {Object} object É o objeto que será verificado de acordo com a estrutura
     * @param {Boolean} useParse Caso tenha algum parse definido na estrutura para alguma chave do objeto
     * @returns O objeto de acordo com a estrutura da classe
     * 
     * Quando é fornecido um objeto será feito os seguintes passos:
     *  01. Verificar se tem chave que não está definido no objeto será emitido um erro
     *  02. Para cada chave da estrutura da classe será verificado:
     *      - Se não houver ou estiver não definida (null ou undefined) será criado e atribuido o valor padrão da chave
     *      - Caso seja solicitado o parse do valor será feito caso dê algum erro de checagem:
     *          - Se tiver definido algum parse, será usado para transformar o valor
     *          - Caso contrário, será usado usado o valor padrão
     */
    checkAssigned(object, useParse = false) {
        let allKeys = Object.keys(object)
        let diffKeys = Utils.difference_array(allKeys, this.KEYS)
        if (diffKeys.length > 0) throw new Error(`The following keys are not allowed: '${diffKeys.join("','")}'`)
        this.KEYS.forEach(
            (key) => {
                if (check.not.assigned(object[key])) object[key] = this.STRUCT[key]['default']
                let hasParse = this.STRUCT[key].hasOwnProperty('parse')
                if (useParse) {
                    try {
                        this.STRUCT[key]['check'](object[key])
                    } catch (e) {
                        if (hasParse)
                            object[key] = this.STRUCT[key]['parse'](object[key])
                        else
                            object[key] = this.STRUCT[key]['default']
                    }
                }
                this.STRUCT[key]['check'](object[key])
            }
        )
        return object
    }
}