import store from '@/store/index'

const associateds_data = store.state.associados
const currentUser = store.state.currentUser
const getUnitByIDs = store.getters.getUnitByIDs

const filterAssociatedUnit = async (unit_list) => {
  let associado = associateds_data.find(a => a.id === currentUser.uid);
  if (associado) {
    let allowedIDUnits = associado.data.unitsAllowedByClient.map(u => typeof u === 'object' ? u.id : u);
    let listUnit = getUnitByIDs(allowedIDUnits);
    const data_table = unit_list.filter((line_table) => listUnit.find(({ id }) => line_table.id === id))
    return data_table
  } else {
    return unit_list
  }
}
const unitsByAssociated = async () => {
  let associado = associateds_data.find(a => a.id === currentUser.uid);
  if (associado) {
    let allowedIDUnits = associado.data.unitsAllowedByClient.map(u => typeof u === 'object' ? u.id : u);
    let listUnit = getUnitByIDs(allowedIDUnits);
    return listUnit
  } else {
    return "O usuario atual não é um associado!"
  }
}


export { filterAssociatedUnit,unitsByAssociated }