import { db } from "@/firebase"

const units_ref = db.collection("units")

async function addUnit(unit) {
    try {
        if (unit["user"] === undefined) throw "field 'user' is missing";

        const doc_ref = await units_ref.add(unit);
        let doc_id = doc_ref.id;
        await db
            .collection("units")
            .doc(doc_id)
            .update({
                id: doc_id,
            });
        return doc_id;
    } catch (error) {
        console.error(error);
        return 1;
    }
}
async function createSubCollectionHistorics(doc_id, historics) {
    try {
        const batch = db.batch();
        for (let historic of historics) {
            let ref = units_ref.doc(doc_id).collection("unit_historics").doc(historic.month)
            batch.set(ref, historic);
        }
        await batch.commit();

    } catch (e) {
        console.error("createSubCollectionHistorics - Erro", e)
        // return false;
    }
}
async function getSubCollectionHistorics(doc_id) {
    try {
        let ref = await units_ref.doc(doc_id).collection("unit_historics").get()
        let historics = []
        ref.forEach((doc) => historics.push(doc.data()));
        return historics
    } catch (e) {
        console.error("createSubCollectionHistorics - Erro", e)
        // return false;
    }
}
async function updateSubCollectionHistorics(collection_doc_id, historics) {
    try {
        const batch = db.batch();
        let ref = await units_ref.doc(collection_doc_id).collection("unit_historics").get()
        ref.forEach((doc) => batch.delete(doc.ref));

        if (historics.length > 0) {
            for (let historic of historics) {
                ref = units_ref.doc(collection_doc_id).collection("unit_historics").doc(historic.month)
                batch.set(ref, historic);
            }
        }
        await batch.commit();

        return true
    } catch (e) {
        console.error("createSubCollectionHistorics - Erro", e)
        return false;
    }
}
async function hardDeleteUnit(unit_id) {
    try {
        await db
            .collection("units")
            .doc(unit_id)
            .delete();
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}
async function softDeleteUnit(unit_id) {
    try {
        await db
            .collection("units")
            .doc(unit_id)
            .update({
                "deletedAt": new Date(Date.now())
            });
    } catch (error) {
        console.error(error);
    }
}

async function getUnits() {
    try {
        let units_list = []
        let unitsRef = await db.collection("units").where("deletedAt", "==", null).get()

        unitsRef.forEach((doc) => {
            units_list.push(doc.data());
        });
        return units_list
    } catch (error) {
        console.error(error)
    }
}
async function getUnitsByFranchisee(franchiseeId) {
    try {
        let units_list = []
        let unitsRef = await db.collection("units").where("deletedAt", "==", null).where("franchisee_id", "==", franchiseeId).get()

        unitsRef.forEach((doc) => {
            units_list.push(doc.data());
        });
        return units_list
    } catch (error) {
        console.error(error)
    }
}
async function getUnitById(idUnit) {
    try {
        let unitsRef = await db.collection("units").doc(idUnit).get()

        return unitsRef.data()
    } catch (error) {
        console.error(error)
    }
}


// User ------------------------------------
async function updateUserData(user) {
    try {
        await db
            .collection("users")
            .doc(user.id)
            .update(user);

    } catch (error) {
        console.error(error);
    }
}

export default {
    createSubCollectionHistorics,
    updateSubCollectionHistorics,
    getSubCollectionHistorics,
    getUnitsByFranchisee,
    updateUserData,
    softDeleteUnit,
    hardDeleteUnit,
    getUnitById,
    addUnit,
    getUnits,
};