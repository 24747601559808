<template>
    <!-- <div>{{ id }}</div> -->
    <div>
        <v-autocomplete
            v-model="itemId"
            :items="items"
            :label="`Sublocal`"
            :item-value="'id'"
            :item-text="'text'"
            persistent-hint
            @change="inputChange"
            @keyup.enter="inputEnter"
            @keyup.delete="inputDelete"
            @keydown.tab="inputTab"
            @focus="inputFocus"
            @blur="inputBlur"
            :loading="loading"
            :disabled="disabled"
            :rules="rulesRequired"
        >
        </v-autocomplete>
    </div>
</template>

<script>
import { showInfoSnackBar, showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import Rules from "@/utils/Rules";
import Utils from '@/scripts/Utils';
import { mapActions, mapGetters } from "vuex";

export default {
    name: "SublocalField",
    props: {
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        proposal: {
            required: true,
        },
        id: {
            type: String,
            default: '',
        },
        noRule: {
            type: Boolean,
            default: false,
        },
    },
    components: {

    },
    data: () => ({
        items: [],
        itemId: null,
        item: null,
        focus: false,
    }),
    methods: {
        inputTab(e) {
            this.inputEnter(e)
        },
        inputEnter(e) {
            this.emitLoading(true)
            this.emitDisabled(true)
            let value = e.srcElement.value
            let valueId = Utils.createSlug(value)
            this.items.push({
                text: value,
                id: valueId
            })
            this.itemId = valueId
            let sublocals = Utils.sortArray(this.items.map(({text}) => text))
            let items = sublocals.map(
                (sublocal) => {
                    return {
                        text: sublocal,
                        id: Utils.createSlug(sublocal)
                    }
                }
            )
            this.items = items
            this.itemId = valueId
            this.$nextTick(
                () => {
                    document.querySelectorAll("div.v-list[role='listbox'] div div div").forEach(
                        (el) => {
                            let content = el.innerHTML
                            if (content === value) {
                                el.click()
                            }
                        }
                    )
                }
            )
            this.emitInput()
        },
        inputDelete(e) {
            const { key } = e
            if (key !== 'Delete') {
                this.inputFocus()
                return
            }
            this.emitLoading(true)
            this.emitDisabled(true)
            let itemIds = !this.proposal.solutionList ? [] : this.proposal.solutionList.map(({sub_local}) => Utils.createSlug(sub_local))
            let hasDependency = itemIds.includes(this.itemId)
            if (hasDependency) {
                showErrorSnackBar("Não pode deletar o sub local pois há solução no checklist")
                this.emitLoading(false)
                this.emitDisabled(false)
                return
            }
            this.items = this.items.filter(({id}) => id !== this.itemId)
            this.itemId = null
            this.emitLoading(false)
            this.emitDisabled(false)
            this.inputFocus()
        },
        inputChange() {
            this.emitInput()
        },
        inputFocus() {
            this.focus = true
            this.emitFocus(this.focus)
        },
        inputBlur() {
            this.focus = false
            this.emitFocus(this.focus)
        },
        emitLoading(status) {
            this.$emit('loading:input', status)
        },
        emitDisabled(status) {
            this.$emit('disabled:input', status)
        },
        emitFocus(status) {
            this.$emit('focus:input', status)
        },
        emitInput() {
            let item = this.items.find(({id}) => id === this.itemId)
            this.$emit('change:input', item)
            this.emitLoading(false)
            this.emitDisabled(false)
            this.emitFocus(false)
        }
    },
    computed: {
        rulesRequired() {
            if (this.noRule) return []
            return Rules.required();
        },
    },
    watch: {
        proposal: {
            immediate: true,
            handler: function (newVal) {
                if (newVal) {
                    let items = []
                    let solutionList = newVal.solutionList
                    if (!solutionList) {
                        this.items = []
                        return
                    }
                    let sublocals = Utils.sortArray(Utils.removeDuplicates(solutionList.map(({ sub_local }) => sub_local)))
                    items = sublocals.map(
                        (sublocal) => {
                            return {
                                text: sublocal,
                                id: Utils.createSlug(sublocal)
                            }
                        }
                    )
                    this.items = items
                }
            }
        },
        id: {
            immediate: true,
            handler: function (newVal) {
                if (newVal) {
                    this.itemId = newVal
                }
            }
        },
    }
};
</script>