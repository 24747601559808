import apiService,{ HOST } from "./BaseApi";
import Utils from '@/scripts/Utils';
const ENDPOINT_PATH = "/consumption/generate-pdf";

const downloadPdf = async (fileName, newName) => {  
    newName = newName.split(" ").join("_");
    window.open(HOST + `download/v2/${fileName}/${newName}`)
}

const EconomyApi = {
    generateConsumptionPdf: async (pdfName, params) => {
        params = {...params, env: Utils.getEnv()}
        let response = await apiService.post(ENDPOINT_PATH, params)
        await downloadPdf(response.data.fileName, `${pdfName}_CONSUMO`)
    },
};

export default EconomyApi;
