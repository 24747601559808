<template>
  <v-form ref="form" v-model="validForm" lazy-validation>
    <div>
      <v-row>
        <v-col cols="12">
          <h3>Histórico de Referência:</h3>
        </v-col>
      </v-row>
      <v-row>
        <!-- Start Table Solutions -->
        <v-data-table
          :headers="headers"
          :items="historicObj"
          :items-per-page="12"
          class="elevation-1"
          style="width: 100%"
        >
          <template v-slot:top>
            <v-toolbar flat class="rounded-lg">
              <v-toolbar-title>Histórico</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn color="primary" class="mb-2" text @click="addHistoric()">
                <v-icon>add</v-icon>
              </v-btn>
            </v-toolbar>
          </template>
        
          <template v-slot:body="{ items }">
            <tbody>
              <date-picker-dialog
                    title="Escolha o mês do histórico"
                    type="month"
                    :show="datePickerDialog"
                    @close="(status) => (datePickerDialog = status)"
                    :input="dateMonth"
                    @change="(newDate) => confirmDatePicker(newDate)"
                    :rules="rulesRequired"
                />        
              <tr v-for="(report) in items" :key="report.id">
                <td style="display: flex">
                  <v-text-field
                    v-model="report.mounth"
                    readonly
                    @click="openDataDialog(report.id)"
                    :rules="rulesRequired"
                  >
                  </v-text-field> 
                 
                </td>
                <td>
                  <v-text-field
                    type="Number"
                    v-model="report.water"
                    suffix="m³"
                    @change="sumWaterAndSewer(report.id)"
                    :rules="rulesRequired"
                  >
                  </v-text-field>
                </td>
                <td>
                  <v-text-field
                    type="Number"
                    v-model="report.sewer"
                    suffix="m³"
                    @change="sumWaterAndSewer(report.id)"
                    :rules="rulesRequired"
                  >
                  </v-text-field>
                </td>
                <td>
                  <v-text-field
                    v-model="report.consumptionTotal"
                    suffix="m³"
                    disabled
                  >
                  </v-text-field>
                </td>
                <td>
                  <v-select
                    :items="consumptionType"
                    v-model="report.consumptionTypeWater"
                    :rules="rulesRequired"
                  >
                  </v-select>
                </td>
                <td>
                  <v-select
                    :items="consumptionType"
                    v-model="report.consumptionTypeSewer"
                    :rules="rulesRequired"
                  >
                  </v-select>
                </td>
                <td>
                  <v-text-field
                    v-model="report.acountAmount"
                    prefix="R$"
                    type="number"
                    :rules="rulesRequired"
                  >
                  </v-text-field>
                </td>
                <td>
                  <v-btn
                    elevation="2"
                    @click="deleteReportSolution(report)"
                    style="height: 30px; margin: 2px"
                  >
                    <v-icon color="primary" small> mdi-delete </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <!-- End Table Solutions -->
      </v-row>
      <v-divider></v-divider>
    </div>
  </v-form>
</template>
<script>
import DatePickerDialog from "@/components/DatePickerDialog/DatePickerDialog.vue";
import Rules from "@/utils/Rules";
import WaterScanService from '@/services/firebase/WaterScan/WaterScanService';

export default {
  name: "hydrometerForm",
  props: {
    requestData: {
      default: null,
    },
    registration: {
      default: ""
    },
    idProposal: {
      default: ""
    },
    viewOrEdit: {
      default: ""
    },
    requestSave: {
      default: null,
    },
    validFormProp: {
      default: () => {},
    },
    closeProposed: {
      default: () => {},
    },
    
  },
  components: {
    DatePickerDialog,
  },
  data: function () {
    return {
      cols: "12",
      md: "4",
      lg: "4",
      xl: "4",
      sm: "4",
      validForm: true,
      datePickerDialog: false,
      dateMonth: null,
      checkbox: null,
      historicIndex: 0,
      consumptionType: [
        "Real",
        "Fixo",
        "Const Cort",
        "Estimado",
        "Informado",
        "Media HD",
        "Min Fixed",
      ],
      headers: [
        { text: "Mês", value: "mounth", width: 100 },
        { text: "Água", value: "water" },
        { text: "Esgoto", value: "sewer" },
        { text: "Total", value: "consumptionTotal" },
        { text: "Tipo Consumo Água ", width: 120 , value: "consumptionTypeWater" },
        { text: "Tipo Consumo Esgoto ", width: 120 , value: "consumptionTypeSewer" },
        { text: "Conta (R$) ", value: "acountAmount" },
        { text: "Ações ", value: "action" },
      ],
      historicObj: [],
    };
  },
  methods: {
    async updateProposalinFirebase(objSave) {
      await WaterScanService
        .updateProposal(objSave, this.idProposal)
        .then(() => {
          this.validFormProp(true,2)
        })
        .catch((err) => {
          console.error("Historic - Erro ao ATUALIZAR proposta!" + err);
        });
    },
    openDataDialog(index) {
      this.historicIndex = index;
      this.datePickerDialog = true;
      this.datePickerDialog = true;
    },
    confirmDatePicker(newMonth) {
      if (this.historicObj) {
        let checkMonth = this.historicObj.filter(
          ({mounth}) => mounth == newMonth
        );
        if (checkMonth.length > 0)
          return alert(
            `O mês ${newMonth} selecionado já existe!\n Selecione outro mês.`
          );
      }
      this.historicObj = this.historicObj.map((i) => {
        if (i.id == this.historicIndex) {
          return { ...i, mounth: newMonth };
        } else {
          return i;
        }
      });
    },
    sumWaterAndSewer(index) {
      this.historicObj = this.historicObj.map((i) => {
        if (i.id == index) {
          return { ...i, consumptionTotal: +i.water + +i.sewer };
        } else {
          return i;
        }
      });
    },
    formatDataHistorico(date) {
      if (!date) return "";
      let arr = date.split(/\-/);
      return `${arr[1]}/${arr[0]}`;
    },
    addHistoric() {
      let list = this.historicObj;
      //Procura o maior ID e soma 1 para o proximo historic
      function lastId() {
        let ids = 0;
        if (list.length > 0) {
          ids = list.map((i) => {
            return i.id;
          });
          ids = Math.max.apply(null, ids);
          return ids + 1;
        }
        return ids;
      }
      this.historicObj.unshift({
        id: lastId(),
        acountAmount: null,
        consumptionTotal: null,
        sewer: null,
        water: null,
        mounth: null,
      });
    },
    deleteReportSolution(report) {
      this.historicObj = this.historicObj.filter((month) => month !== report);
    },
    async resquetEvent(pag,pullStep = false) {
      if (pag != 2 || pullStep) {
        this.validFormProp(true,2)
        // if (this.$refs.form && !this.$refs.form.validate()) return;
        this.$emit("update:overley", true);
        let historic = { consumptionHistoric: {} };

        historic.consumptionHistoric = this.historicObj;
        if (historic.consumptionHistoric.length) {
          await this.updateProposalinFirebase(historic);
          if(pullStep)this.closeProposed();
        }else{
          this.historicObj = []
        }
      }
    },
  },
  computed: {
    rulesRequired() {
      return Rules.required();
    },
  },
  async created() {
    if (this.viewOrEdit === "view") {
      await WaterScanService.getProposalById(this.idProposal)
        .then((response) => {
          this.historicObj = response.consumptionHistoric || this.historicObj;
        })
        .catch((err) => {
          alert("Erro ao buscar proposta!");
          console.error(err);
        });
    }
  },
};
</script>