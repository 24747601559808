<template>
  <v-form ref="form" v-model="validForm" lazy-validation>
    <v-row justify="center">
      <v-dialog transition="dialog-top-transition" v-model="dialog" v-if="dialog" persistent max-width="700px">
        <v-card>
          <v-toolbar color="primary" dark> <span class="text-h5">Adicionar Solução</span></v-toolbar>
          <v-card-title>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text style="padding-bottom: 0px;">

            <v-row>
              <h3> Informações do Ponto</h3>
            </v-row>
            <v-row>
              <v-col cols="12" :sm="6">
                <LocalField 
                  :id="id"
                  :input="solutionReport.local"
                  @change="changeLocal"
                  />
              </v-col>
              <v-col cols="12" :sm="6">
                <SublocalField 
                  :id="id"
                  :input="solutionReport.sub_local"
                  @change="changeSublocal"
                  />
              </v-col>
              <v-col cols="12" :sm="4">
                <v-autocomplete label="Tipo" 
                  :items="pointsOptions" 
                  item-text="name"
                  item-value="id" 
                  outlined 
                  v-model="solutionReport.type"
                  @change="optionTypeSelect(solutionReport.type)" :rules="rulesRequired" onfocus>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" :sm="4">
                <v-autocomplete label="Soluções" 
                  :items="solutionsOptionsList" 
                  v-model="solutionReport.solution" 
                  outlined
                  item-text="solution"
                  item-value="id"
                  :disabled="solutionReport.type === null" :rules="rulesRequired">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" :sm="4">
                <v-text-field v-model="solutionReport.amountSolution" outlined label="Qtnd." type="Number" required
                  :rules="rulesRequired">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" :sm="4">
                <v-text-field v-model="solutionReport.flowRateBefore" outlined label="Vazão antes" type="Number"
                  suffix="L/min" required :rules="rulesRequired">
                </v-text-field>
              </v-col>
              <v-col cols="12" :sm="4">
                <v-text-field v-model="solutionReport.flowRateAfter" outlined label="Vazão Depois" type="Number"
                  suffix="L/min" required :rules="rulesRequired">
                </v-text-field>
              </v-col>
              <v-col cols="12" :sm="4">
                <v-text-field v-model="percentageEconomy" outlined label="Economia" readonly suffix="%">
                </v-text-field>
              </v-col>
              <v-col cols="12" :sm="6">
                <v-select
                  :items="categoryOptions"
                  label="Categoria"
                  outlined
                  v-model="solutionReport.category"
                  :rules="rulesRequired"
                ></v-select>
              </v-col>
              <v-col cols="12" :sm="6">
                <v-select
                  :items="statusOptions"
                  label="Status"
                  outlined
                  v-model="solutionReport.status"
                  :rules="rulesRequired"
                ></v-select>
              </v-col>
              <v-col cols="12" :sm="12">
                <v-textarea v-model="solutionReport.comments" outlined label="Observação" type="text">
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <h3> Adicionar Imagem </h3>
              <v-btn color="primary" text @click="pickFile()">
                <v-icon>add</v-icon>
              </v-btn>
              <input multiple type="file" id="get_image" ref="get_image" accept="image/*" style="display: none"
                @input="onFilePicked" />
            </v-row>
            <vue-horizontal>
              <section style=" margin: 10px;" v-for="img, n in listImgs" :key="n">
                <!-- :lazy-src="`https://localhost:8080/favicon.ico`" -->
                <v-img :src="img.image" aspect-ratio="1" style="width: 200px" @click="deleteImgSelect(img)">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey-lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </section>

            </vue-horizontal>
            <!-- <video autoplay id="video"></video> -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="loading.uploadImg" color="blue darken-1" text @click="closeDialogBtn">
              Cancelar
            </v-btn>
            <v-btn :loading="loading.uploadImg" color="blue darken-1" text @click="save()"> Salvar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-form>
</template>

<script>
import Rules from "@/utils/Rules";
import VueHorizontal from 'vue-horizontal';
// import WaterScanStoragefrom from '@/services/storage/WaterScanStorage';
import WaterFixStorage from '@/services/storage/WaterFixStorage';
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper";
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import ObjectValidator from '@/scripts/ObjectValidator';
import Utils from '@/scripts/Utils';
import LocalField from "@/components/Waterfix/Fields/LocalField.vue";
import SublocalField from "@/components/Waterfix/Fields/SublocalField.vue";
export default {
  components: {
    VueHorizontal,
    LocalField,
    SublocalField,
  },
  props: {
    dialog: {
      default: false,
      type: Boolean,
    },
    // saveFunc: {},
    // clearEditItem: {},
    closeDialog: {},
    // editableSolution: {},
    registration: "",
    solutionsOptions: {},
    pointsOptions: {},
    editableSolution: {
      type: Object,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    cols: "12",
    validForm: true,
    // solutionReport: {
    //   type: null,
    //   flowRateAfter: null,
    //   flowRateBefore: null,
    //   solution: "",
    // },
    solutionReport:  ObjectValidator.create('waterfix_checklist_solution'),
    optionSolution: [],
    optionsType: [
      { text: "Torneira", value: "faucet" },
      { text: "Chuveiro", value: "shower" },
      { text: "Descarga", value: "discharge" },
    ],
    listImgs: [],
    editedItem: {},
    deleteImgFile: {},
    rawFiles: [],
    urlImgTeste: "",
    loading: {
      uploadImg: false
    },
    categoryOptions: [
      'Instalação',
      'Manutenção',
      'Revisão',
    ],
    statusOptions: [
      'Instalado',
      'Não se Instala',
      'Não Autorizado',
      'Desativado',
      'Pendente',
    ],
  }),
  methods: {
    changeLocal(local) {
      this.solutionReport.local = local.text
    },
    changeSublocal(sublocal) {
      this.solutionReport.sub_local = sublocal.text
    },
    async setSolutionReport(solution = null) {
      if (solution === null) {
        this.solutionReport =  ObjectValidator.create('waterfix_checklist_solution');
      } else {
        this.solutionReport = Utils.copyObject(solution)
        if (this.solutionReport.imgNameList?.length) await this.getStorageImages()
      }
    },
    pickFile() {
      this.$refs.get_image.click();
    },
    async deleteImgSelect(event) {
      this.deleteImgFile = event
      showDeleteDialogAlert(`Deseja Excluir a imagem ${event.imageName}`, this.deleteImg)
    },
    async deleteImg() {
      if(this.deleteImgFile.pathFirebase) await WaterFixStorage.deleteImg(this.deleteImgFile.pathFirebase)
      if(this.solutionReport.imgNameList?.length)this.solutionReport.imgNameList = this.solutionReport.imgNameList.filter(( pathFirebase ) => pathFirebase != this.deleteImgFile.pathFirebase)
      this.listImgs = this.listImgs.filter(({ image }) => image != this.deleteImgFile.image)
    },
    optionTypeSelect(type_id) {
      return this.solutionsOptions.filter(({pointId}) => pointId == type_id)
    },
    async onFilePicked(e) {
      const inputFiles = document.getElementById("get_image").files;
      if (inputFiles[0] !== undefined && inputFiles[0].type.split("/")[0] == "image") {
        this.rawFiles.push(...document.getElementById("get_image").files)
        for (let file of inputFiles) {
          await this.processImg(file)
        }
      } else {
        showErrorSnackBar("Arquivo inválido.")
        this.editedItem.image = "";
        this.editedItem.imageName = "";
      }
    },
    async processImg(file) {
      try {
        this.editedItem = {}
        this.editedItem.imageName = file.name;
        if (this.editedItem.imageName.lastIndexOf(".") <= 0) reject("Nome Invalido")
        var output = document.getElementById('output');
        this.listImgs.unshift({
          image: URL.createObjectURL(file),
          imageName: file.name
        })
      } catch (err) {
        showErrorSnackBar("Erro ao processar imagem.")
      }

    },
    closeDialogBtn() {
      this.closeDialog();
      // this.clearEditItem()
      this.loading.uploadImg = false
      this.$emit("status:save", false)
      this.solutionReport = {}
    },
    async save() {
      if (this.$refs.form && !this.$refs.form.validate()) return
      this.loading.uploadImg = true
      let savedFileNames = []
      for (let file of this.rawFiles) {
        savedFileNames.push(await WaterFixStorage.uploadImg(this.registration, file))
      }
      if(this.solutionReport.imgNameList?.length){
        if(savedFileNames.length)this.solutionReport.imgNameList.push(...savedFileNames) 
      }
      else this.solutionReport.imgNameList = savedFileNames
      this.$emit("report:save", this.solutionReport)
    //   this.saveFunc(this.solutionReport);
      // this.$emit("status:save", true)
      this.closeDialog();
    },
    async getStorageImages() {
      let listImgs = Utils.copyObject(this.solutionReport.imgNameList)
      for (let imgPath of listImgs) {
        try {
          const urlImg = await WaterFixStorage.downloadImg(imgPath)

          const mainPathEnd = imgPath.indexOf('/')+1
          const matricula = imgPath.slice(mainPathEnd)
          const matriculaEnd = imgPath.slice(mainPathEnd).indexOf('/')+1
          const imgNameOrigin = matricula.slice(matriculaEnd+14)

          this.listImgs.unshift({
            image: urlImg,
            pathFirebase: imgPath,
            imageName: imgNameOrigin
          })
        } catch (e) {
          this.solutionReport.imgNameList = this.solutionReport.imgNameList.filter((path) => path !== imgPath)
        }
      }
    }
  },
  computed: {
    rulesRequired() {
      return Rules.required();
    },
    solutionsOptionsList() {
      return this.optionTypeSelect(this.solutionReport.type)
    },
    percentageEconomy() {
      let percentage =
        (+this.solutionReport.flowRateAfter * 100) /
        +this.solutionReport.flowRateBefore - 100;
      percentage = percentage < 0 ? Math.abs(percentage).toFixed(2) : 0;
      this.solutionReport.economy = percentage
      return percentage
    },
  },
  async created() {
    await this.setSolutionReport(this.editableSolution)

    // if (this.editableSolution.length) {
    //   this.solutionReport = this.editableSolution[0]
    //   if (this.solutionReport.imgNameList?.length) await this.getStorageImages(this.solutionReport.imgNameList)

    // }
  }
};
</script>