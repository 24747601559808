import DatePickerDialog from "@/components/DatePickerDialog/DatePickerDialog.vue";
import firebase from "@/firebase";
import Utils from '@/scripts/Utils';
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper";
import Rules from "@/utils/Rules";
import { mapGetters, mapMutations } from "vuex";
import * as XLSX from 'xlsx-js-style';
import AlertsService from "../../../services/firebase/Alerts/AlertsService";
var { DateTime } = require("luxon");
import ModensService from "@/services/firebase/Modens/MdensService";
import SectorsService from "@/services/firebase/Sectors/SectorsService";
import UnitsService from "@/services/firebase/Units/UnitsService";
import { db } from "@/firebase"
import { showErrorSnackBar, showSuccessSnackBar, showInfoSnackBar } from "@/utils/SnackBartHelper";
export default {
  components: {
    DatePickerDialog,
  },
  name: "Modems",

  data: () => ({
    modemOnSnapashot: null,
    readingsSnapashot: [],
    unsubscribeReadingsListener: null,
    unsubscribeModensListener: null,
    playTelemetryButton: false,
    headersTelemetry: [
      {
        text: 'Data / Hora',
        align: 'start',
        sortable: false,
        value: 'lastDate',
        width: 200
      },
      { text: 'Dados brutos', value: 'data' },
    ],
    sectorCurrentModem: {},
    sectorCurrentModemLoading: false,
    unitCurrentSector: {},
    unitCurrentSectorLoading: false,

    endDate: null,
    startDate: null,
    downloadDialog: false,

    modemCreationFailed: false,
    isWaitingLoraResponse: false,
    modemErrorMsg: "",
    searchText: "",
    columnToSearch: "Nome",
    columnsToChoose: ["ID", "Nome", "Modelo", "Bateria"],
    tabItems: ["Dados", "Alertas"],

    tabModems: null,
    loadingModens: true,
    canDeleted: false,
    modems: [],
    dialog: false,
    dialogViewAlert: false,
    models: [{ text: "4 Hidrômetros", value: "2" }, { text: "1 Hidrômetros", value: "3" }],
    supply: [{ text: "BottumUp", value: "BOTTUMUP" }, { text: "IE Tecnologia", value: "IETECH" }],
    conectionType: [{ text: "LoraWan", value: "LORAWAN" }, { text: "Wi-Fi", value: "WIFI" }],
    ranks: ["Super Admin", "Admin", "Franqueado", "Cliente"],
    headers: [
      { text: "Status", value: "status", width: 120, sortable: false },
      { text: "Nome", value: "name" },
      { text: "ID", value: "id", width: 200 },
      { text: "Bateria (%)", value: "lastBattery", width: 100 },
      { text: "Última Leitura", value: "lastReadingDate", width: 190 },
      { text: "Último Dado", value: "lastData", width: 140 },
      { text: "Ações", value: "actions", width: 100, sortable: false },

    ],
    page: 1,

    stateHistory: [],
    indexAlertPeriod: null,
    modem: {
      name: "",
      unit: "",
      id: "",
      model: "",
      lastReading: "",
      lastData: "",
      sectors: "",
      bateria: "",
    },
    validForm: true,
    modemIndex: -1,

    modalTitle: '',
    modalFlag: false,

    beforeState: null,

    currentState: null,

    modalDeleteBody: '',
    modalDelete: false,
    leituras: [],
    pagePerDocSnapshot: { 0: null },
    paginationInfo: {
      pageCount: 1,
      lastDocSnapshot: null,
      penultimateDocSnapshot: null,
      totalDocs: 0,
      length: 100,
      list: [],
      pageList: [],
      loading: true
    },
    getModems: [],
    listActions: [
      { value: "edit", title: "Editar" },
      { value: "download", title: "Log" },
      { value: "delete", title: "Excluir" },
    ]
  }),

  methods: {
    ...mapMutations(["SET_MODEMS"]),
    async generateID() {
      this.modem.id = await ModensService.generateIDModem()
    },
    selectAction(item, value) {
      switch (value) {
        case "edit":
          this.FSM_Modem('edit', { modem: item })
          break;
        case "delete":
          this.FSM_Modem('delete', { modem: item })
          break;
        case "download":
          this.exportSheets('xlsx', item.name, item)
          break;
        default:
          break;
      }
    },
    getStatusColor(lastReading) {
      if (lastReading) {
        var date = DateTime.fromJSDate(new Date(lastReading.split(" ")[0].split("/").reverse().join("/") + " " + lastReading.split(" ")[1]))
        var dateDiff = date.setLocale('pt-br').diffNow(['weeks', 'days', 'hours']).toObject()
        if (dateDiff.weeks != 0) {
          return "red";
        } else if (dateDiff.days != 0) {
          return "yellow";
        } else if (dateDiff.hours != 0) {
          return "green";
        }
      } else return "red";
    },
    getDateVariation(lastVariation) {
      if (lastVariation) {
        let date = new Date(lastVariation.seconds * 1000)
        date = Utils.formatDateDayJs(date, "DD/MM/YYYY [às] <HH>:<mm>:<ss>")
        return "Última data: " + date
      } else return "Sem Dados"
    },
    getStatusVariationColor(lastVariation) {
      if (lastVariation) {

        var date = DateTime.fromJSDate(new Date(lastVariation.seconds * 1000))
        var dateDiff = date.setLocale('pt-br').diffNow(['weeks', 'days', 'hours']).toObject()
        if (dateDiff.weeks != 0) {
          return "red";
        } else if (dateDiff.days != 0) {
          return "yellow";
        } else if (dateDiff.hours != 0) {
          return "green";
        }
      } else return "red";
    },
    getStatusDecodingColor(lastVariation) {
      if (lastVariation) {

        var date = DateTime.fromJSDate(new Date(lastVariation.seconds * 1000))
        var dateDiff = date.setLocale('pt-br').diffNow(['weeks', 'days', 'hours']).toObject()
        if (dateDiff.weeks != 0) {
          return "green";
        } else if (dateDiff.days != 0) {
          return "yellow";
        } else if (dateDiff.hours != 0) {
          return "red";
        }
      } else return "green";
    },
    getModelo(value) {
      let model = this.models.find(model => model.value == value);
      return model ? model.text : '';
    },

    addHistory(state) {
      this.stateHistory.push(state.toLowerCase());
    },

    clearHistory() {
      if (this.stateHistory.length > 1) {
        this.stateHistory = this.stateHistory.splice(this.stateHistory.length - 1, 1);
      }
    },

    hasHistory(state) {
      if (this.stateHistory.length <= 0) return false;
      return this.stateHistory.includes(state.toLowerCase());
    },

    getLastHistory() {
      if (this.stateHistory.length <= 0) return null;
      return this.stateHistory[this.stateHistory.length - 1];
    },
    stringDate(item) {
      if (item.lastReadingDate && item.lastReadingDate.seconds) {
        const date = new Date(item.lastReadingDate.seconds * 1000 + item.lastReadingDate.nanoseconds / 1000000);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}`;
      } return item.lastReading // old object key
    },
    checarBateria(modem) {
      if (modem.lastBattery) return this.bateryConvert(modem.lastBattery)
      else return 0
    },
    bateryConvert(batery) {
      batery = ((batery - 20) * 100) / 12
      if (batery < 0) return 0
      else return batery.toFixed(0)
    },
    async exportSheets(type, name, modem) {

      let leituras = await this.buscarLeituras(modem.id)

      var wb = XLSX.utils.book_new();
      wb.Props = {
        Title: "SheetJS Tutorial",
        Subject: "Test",
        Author: "Red Stapler",
        CreatedDate: new Date(2017, 12, 19)
      };
      wb.SheetNames.push("Log");
      if (modem.model == 3) {
        var ws_data = [[name],
        ['Data', 'Bateria', 'Leitura'],
        ];
      } else {
        var ws_data = [[name],
        ['Data', 'Bateria', 'Leitura 1', 'Leitura 2', 'Leitura 3', 'Leitura 4'],
        ];
      }

      for (let key in leituras) {


        let decodedResult = ["Esse modem está com formato maior que 18 bytes", null, null, null]
        let decoded = leituras[key].decoded
        if (decoded.length <= 18) decodedResult = this.convertByteToDecimal(decoded)


        let datetime = leituras[key].datetime
        let batery = decoded[1]
        let y = null
        if (typeof datetime == 'object' && datetime.hasOwnProperty('seconds')) {
          y = new Date(datetime.seconds * 1000)
        }

        if (modem.model == 3) {
          ws_data.push([Utils.formatDateDayJs(y, "DD/MM/YYYY [às] HH:mm:ss"),
          (batery / 10) + "V",
          decodedResult[0]
          ])
        } else {
          ws_data.push([Utils.formatDateDayJs(y,  "DD/MM/YYYY [às] HH:mm:ss"),
          this.bateryConvert(batery) + "%",
          decodedResult[0],
          decodedResult[1],
          decodedResult[2],
          decodedResult[3]])
        }

      }

      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      var wscols = [
        { wch: 22 }
      ];
      ws['!cols'] = wscols;

      wb.Sheets["Log"] = ws;

      setTimeout(() => {
        XLSX.writeFile(wb, name + ".xlsx");
      }, 2000)

    },
    async buscarLeituras(modemId) {

      let leituras = await firebase.readingsByModem(modemId);
      return leituras;

    },
    convertByteToDecimal(decoded) {
      let a, b, c, d, j = 0
      let result = []
      let decodedPort = []
      for (let i = 0; i < 4; i++) {

        a = decoded[2 + j]
        b = decoded[3 + j]
        c = decoded[4 + j]
        d = decoded[5 + j]
        j += 4

        decodedPort.push(a, b, c, d)
        decodedPort = new Uint8Array(decodedPort);
        decodedPort = new DataView(decodedPort.buffer, 0);

        let soma = decodedPort.getUint32(0)
        result.push(soma)
        decodedPort = []
      }

      return result;
    },
    async FSM_Modem(state = 'edit', params = {}) {

      let modem, index, title, modems;

      this.addHistory(state.toLowerCase());

      switch (this.getLastHistory()) {

        case 'create':
          this.clearHistory();
          this.isEditingModem = false;
          this.modemCreationFailed = false;
          modem = {
            name: "",
            id: "",
            model: "",
            lastReading: "",
            lastData: "",
            sectors: "",
          };
          index = -1;
          title = 'Cadastro de Modem';
          this.FSM_Modem('open-modal', { modem, index, title });
          break;

        case 'edit':
          this.clearHistory();
          this.isEditingModem = true;
          this.modemCreationFailed = false;
          this.hasArguments(['modem'], params);
          // index = params.index;
          modem = params.modem;
          index = this.paginationInfo.pageList.findIndex((m) => m.id === modem.id);
          title = 'Edição de Modem';
          await firebase.getModem(modem.id)
            .then(res => {
              if (res) {
                for (let key in res) {
                  modem[key] = res[key];
                }
                this.FSM_Modem('open-modal', { modem, index, title });
              } else {
                this.FSM_Modem('close-modal');
              }
            })
            .catch(err => {
              console.error(err);
            })
          break;

        case 'delete':
          this.clearHistory();

          this.hasArguments(['modem'], params);
          // index = params.index;
          modem = params.modem;
          index = this.getModems.findIndex((m) => m.id === modem.id);
          this.FSM_Modem('open-delete-modal', { modem, index });
          break;

        case 'open-delete-modal':
          this.hasArguments(['index', 'modem'], params);
          this.modem = params.modem;
          this.modemIndex = params.index;
          this.modalDeleteBody = `Tem certeza que deseja remover o modem ${this.modem.name}?`;
          showDeleteDialogAlert(`Deseja excluir o modem "${this.modem.name}"`, this.deleteModem)
          break;

        case 'close-delete-modal':
          this.modalDelete = false;
          break;

        case 'open-modal':
          this.readingsSnapashot = []
          this.hasArguments(['modem', 'index', 'title'], params);
          this.modem = params.modem;

          if (params.title === 'Edição de Modem') {
            // tabItems
            this.getSector(this.modem.id)
            this.tabItems = ["Dados", "Alertas", "Telemetria"]
          }

          this.modemIndex = params.index;
          this.modalTitle = params.title;
          this.modalFlag = true;

          break;

        case 'close-modal':
          if (typeof this.unsubscribeModensListener === "function") this.unsubscribeModensListener()
          if (typeof this.unsubscribeReadingsListener === "function") this.unsubscribeReadingsListener()
          this.playTelemetryButton = false
          this.tabItems = ["Dados", "Alertas"]
          this.modalFlag = false;
          this.sectorCurrentModem = {}
          this.unitCurrentSector = {}
          break;

        case 'accept-delete-modal':
          this.hasArguments(['modem', 'index'], params);
          modem = params.modem;
          index = params.index;
          this.modalDelete = false;
          this.FSM_Modem('delete-modem', { modem, index });
          break;

        case 'save-modal':
          this.hasArguments(['modem', 'index'], params);

          modem = params.modem;
          index = params.index;
          var error = false;
          this.modemCreationFailed = false;
          if (modem.deviceCommunicationType === "WIFI") {
            await ModensService.createModem(modem)
          } else {
            // Validar os campos
            try {
              if (this.hasHistory('create')) {
                await this.FSM_Modem('add-modem', { modem });
              } else if (this.hasHistory('edit')) {
                await this.FSM_Modem('update-modem', { modem, index });
              }
            } catch (e) {
              console.error(e)
              error = true;
            }
          }

          if (error) {
            this.modalFlag = true;
          } else {
            this.modalFlag = false;
          }
          break;

        case 'add-modem':
          this.hasArguments(['modem'], params);
          this.loadingModens = true
          modem = params.modem;
          modems = this.getModems;
          var error = false;
          this.isWaitingLoraResponse = true;
          await firebase.createModem(modem).catch((e) => {
            this.isWaitingLoraResponse = false;
            console.error(e.message);
            this.modemCreationFailed = true;
            switch (e.message) {
              case 'token error':
                this.modemErrorMsg = 'Credenciais inválidas ao cadastrar na LoRa.';
                break;
              case 'creation error':
                this.modemErrorMsg = 'Não foi possivel criar modem na LoRa.';
                break;
              case 'activation error':
                this.modemErrorMsg = 'Não foi possivel ativar modem na LoRa.';
                break;
            }
            throw new Error(e.message);
          }).finally(() => this.loadingModens = false)
          this.isWaitingLoraResponse = false;
          modems.unshift(modem);
          break;

        case 'update-modem':
          this.hasArguments(['modem', 'index'], params);
          this.loadingModens = true;
          index = params.index;
          modem = params.modem;
          modems = this.getModems;
          modems[index] = modem;
          //update setor e modem correspondente da unidade
          await firebase.updateModem(modem.id, modem)
            .finally(() => this.loadingModens = false)
          this.FSM_Modem('set-modems', { modems })
          break;

        case 'delete-modem':
          this.hasArguments(['modem', 'index'], params);
          this.loadingModens = true;
          index = params.index;
          modem = params.modem;
          modems = this.getModems;
          modems.splice(index, 1);

          await firebase.deleteModem(modem.id)
            .then(res => {
              this.FSM_Modem('set-modems', { modems })
            })
            .catch(err => console.error(err))
            .finally(() => this.loadingModens = false)
          // this.FSM_Modem('set-modems', {modems});
          break;

        case 'set-modems':
          this.hasArguments(['modems'], params);
          modems = params.modems;
          // this.SET_MODEMS(modems);
          this.loadingModens = false;
          break;

      }

    },
    async getSector(modemId) {
      this.sectorCurrentModemLoading = true

      const resSector = await SectorsService.getSectorsByModemId(modemId)
      if (resSector.length) {
        this.sectorCurrentModemLoading = false
        this.sectorCurrentModem = resSector[0]
        this.unitCurrentSectorLoading = true
        UnitsService.getUnitById(this.sectorCurrentModem.id_unit)
          .then(res => this.unitCurrentSector = res)
          .finally(() => {
            this.unitCurrentSectorLoading = false
          })
      } else {
        this.sectorCurrentModemLoading = false
      }

    },
    startListenerData() {
      this.playTelemetryButton = !this.playTelemetryButton

      if (this.playTelemetryButton) {
        this.unsubscribeModensListener = db.collection("modens")
          .doc(this.modem.id)
          .onSnapshot((doc) => {
            this.modemOnSnapashot = doc.data()
          });
        this.unsubscribeReadingsListener = db.collection("readings")
          .where("origin", "==", this.modem.id)
          .orderBy("datetime", "desc")
          .limit(1)
          .onSnapshot((querySnapshot) => {
            var reading = [];
            querySnapshot.forEach((doc) => {
              reading.push(doc.data());
            });
            this.readingsSnapashot.unshift({
              lastDate: Utils.formatDateDayJs(new Date(reading[0].datetime.seconds * 1000), "dd-MM-YYYY [as] HH:mm:ss"),
              data: JSON.stringify({ ...reading[0] })
            })
          });
      } else {
        if (typeof this.unsubscribeModensListener === "function") this.unsubscribeModensListener()
        if (typeof this.unsubscribeReadingsListener === "function") this.unsubscribeReadingsListener()
      }

    },
    async deleteModem() {
      this.loadingModens = true;
      let modem = this.modem;
      let index = this.modemIndex;
      let modems = this.getModems;
      modems.splice(index, 1);

      try {
        const resSector = await SectorsService.getSectorsByModemId(modem.id)
        if (resSector.length) {
          const currentSector = resSector[0]
          await SectorsService.editSector(currentSector.id_doc, { ...currentSector, id_modem: null })
        }
        await firebase.deleteModem(modem.id)
        
        showSuccessSnackBar("Modem deletado com sucesso!")
        this.loadingModens = false
        this.FSM_Modem('set-modems', { modems })
        } catch (error) {
          console.error(error)
          showErrorSnackBar("Erro ao deletar modem!")
          this.loadingModens = false
      }

    },
    hasArguments(keys, params) {
      let keysNotFound = [];
      keys.forEach(key => {
        if (!params.hasOwnProperty(key)) keysNotFound.push(key);
      });
      let length = keysNotFound.length;
      if (length > 0) throw new TypeError(`A${length == 1 ? '' : 's'} chave${length == 1 ? '' : 's'} '${keysNotFound.join("', '")}' não fo${length == 1 ? 'i' : 'ram'} encontrada${length == 1 ? '' : 's'}`);
    },
    filterConectionType(type) {
      switch (type) {
        case "BOTTUMUP":
          this.modem.deviceCommunicationType = "LORAWAN"
          return [{ text: "LoraWan", value: "LORAWAN" }]
        case "IETECH":
          this.modem.deviceCommunicationType = "WIFI"
          return [{ text: "Wi-Fi", value: "WIFI" }]
      }
    },
  },
  computed: {
    ...mapGetters([
      "getCurrentUserRank",
      "getStatusCanDeletedDataAdmin",
    ]),
    rulesName() {
      return Rules.string(true, 1, 64);
    },
    rulesRequired() {
      return Rules.required();
    },
    modemListener() {
      return this.modem
    },

    filteredItems() {
      let items = [];
      let search = "name"
      let busca = ""
      switch (this.columnToSearch) {
        case "ID":
          search = 'id'
          items = this.getModems.filter((i) => {
            return (
              i[search].toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
            );
          });
          break;
        case "Nome":
          search = 'name'
          items = this.getModems.filter((i) => {
            return (
              i[search].toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
            );
          });
          break;
        case "Modelo":
          search = 'model'
          if (this.searchText == '4') busca = '2'
          else if (this.searchText == '1') busca = '3'
          else busca = "";
          items = this.getModems.filter((i) => {
            return (
              i[search].toLowerCase().indexOf(busca) > -1
            );
          });
          break;
        case "Bateria":
          search = 'last_battery'
          if (this.searchText === "") busca = 12
          else busca = (((this.searchText) / 100) * 12).toFixed(2)

          items = this.getModems.filter((i) => {
            let valueBatery = i[search] - 20
            if (valueBatery <= busca && valueBatery >= 0) return i
          });
          break;
      }
      return items;
    },
  },
  watch: {
    async modemOnSnapashot() {

      this.modem = this.modemOnSnapashot
    }
  },
  async mounted() {
    // Verifica o tipo de usuário:
    // se Admin, verifica se tem permissão pra deletar; se tiver, seta true; se não, seta false.
    // se Super Admin, seta true; se não, seta false.""

    this.getModems = await ModensService.getModens()
    this.getCurrentUserRank === "Admin"
      ? this.getStatusCanDeletedDataAdmin == false
        ? (this.canDeleted = false)
        : (this.canDeleted = true)
      : this.getCurrentUserRank === "Super Admin"
        ? (this.canDeleted = true)
        : (this.canDeleted = false);

    this.loadingModens = false
  },
};