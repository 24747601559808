<template>
  <v-container fluid>
    <template>
      <v-row>
        <v-col cols="12">
          <v-card class="rounded-xl">
            <v-overlay absolute style="opacity: 0.6" :value="isLoadingSolutionGroup">
              <v-progress-circular indeterminate color="primary" :size="50"></v-progress-circular>
            </v-overlay>
            <v-card-title>
              <v-row style="justify-content: space-between">
                <v-card-title class="title-displays"> Grupos </v-card-title>
                <v-card-title>
                  <v-btn right @click="openSolutionGroupDialog()" color="primary" text>
                    <v-icon> add </v-icon>
                    Novo Grupo
                  </v-btn>
                </v-card-title>
              </v-row>
            </v-card-title>
            <v-card style="overflow: hidden">
              <v-row style="justify-content: space-between">
                <v-text-field style="width:80%; padding:1em" class="px-4" prepend-icon="search" label="Busca"
                  v-model="searchText"></v-text-field>
                <v-select 
                hint="Buscar nessa coluna" 
                persistent-hint 
                style="width:20%; 
                padding: 1em" 
                item-text="text"
                  item-value="value" 
                  v-model="columnToSeach" 
                  :items="columnsToChoose" 
                  hide-details 
                  single-line></v-select>
              </v-row>

              <v-data-table :headers="headers" :items="filterSoluitionsGroup" no-data-text="Sem grupos para exibir"
                :footer-props="{
                  'items-per-page-options': [5, 10, 20, 30]
                }" :page.sync="page" :items-per-page="10">
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(item, i) in items" :key="i" style="height: 100%;color: #1976d2">

                      <td>
                        <v-card-text class="units-table-names">{{ item.name }}</v-card-text>
                      </td>

                      <td>
                        <v-card-text class="units-table-names">{{ item.type }}</v-card-text>
                      </td>
                      <td>
                        <v-card-text style="width: 200px;   
                          text-overflow: ellipsis;
                          white-space: nowrap;
                          overflow: hidden; " class="units-table-names">
                          {{ item.description }}
                        </v-card-text>
                      </td>
                      <td style="width: 100px;">
                        <v-card-text style="white-space: normal">
                          <v-btn @click="openSolutionGroupDialog(item)" v-ripple>
                            <v-icon small color="primary" class="mr-2">
                              mdi-pencil
                            </v-icon>
                          </v-btn>
                          <v-btn @click="confirmDeleteSolutionsGroup(item)" v-ripple>
                            <v-icon small color="primary"> mdi-delete </v-icon>
                          </v-btn>
                        </v-card-text>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <v-dialog persistent width="600" v-model="dialogSolutionGroup" v-if="dialogSolutionGroup">
      <v-card>
        <v-card-title class="primary lighten white--text">{{
          formTitle
        }}
          <v-spacer></v-spacer>
          <v-btn class="litte white--text" text @click="dialogSolutionGroup = false">
            <v-icon white>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="addSolutionGroupForm" v-model="validForm" lazy-validation>
            <v-overlay absolute style="opacity: 0.6" :value="isLoadingSolutionGroupForm">
              <v-progress-circular indeterminate color="primary" :size="50"></v-progress-circular>
            </v-overlay>
            <v-row>
              <v-col cols="8">
                <v-text-field outlined label="Nome" v-model="newItem.name" :rules="rulesRequired">
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-select background-color="white" outlined label="Tipos" :items="[
                  'Produto',
                  'Serviço',
                ]" v-model="newItem.type" :rules="rulesRequired">
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea outlined label="Descição" v-model="newItem.description">
                </v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="saveSolutionGroup" :loading="false" text :disabled="!validForm" color="primary">
            Salvar
          </v-btn>
        </v-card-actions>
        <v-overlay absolute v-model="saveButtonLoading">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import GroupScript from './GroupsScript';
export default GroupScript;
</script>


<style ></style>