<template>
    <v-container fluid>
        <!-- start ROI Dialog -->
        <v-row justify="center">
        <v-dialog transition="dialog-top-transition" v-model="definedModal" persistent max-width="800px">
            <v-card>
            <v-form ref="downloadPdfForm" lazy-validation>
                <v-toolbar color="primary" dark> <span class="text-h5">Geração do Fluxo Financeiro</span></v-toolbar>
                <v-card-text style="padding-bottom: 0px;">
                    <v-row>
                        <v-col>
                            <v-select
                                :items="items"
                                v-model="roiType"
                                label="Tipo do ROI"
                                :loading="isLoading" 
                                :disabled="isLoading"
                            ></v-select>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
                        color="blue darken-1" 
                        text 
                        @click="() => closeModal()" 
                        :loading="isLoading" 
                        :disabled="isLoading"
                    >Cancelar</v-btn>
                    <v-btn 
                        color="blue darken-1" 
                        text 
                        @click="() => generate()" 
                        :loading="isLoading" 
                        :disabled="isDisabled"
                    >Gerar</v-btn>
                </v-card-actions> 
            </v-form>
            </v-card>
        </v-dialog>
        </v-row>
        <!-- end ROI Dialog -->
    </v-container>
</template>
<script>
import { mapActions } from "vuex";
import RoiApi from '@/services/apiPdf/RoiApi.js'
import Utils from "@/scripts/Utils";

export default {
    props: {
        modal: {
          type: Boolean,
          require: true,
          default: false,
        },
        // Parâmetros obrigatórios
        proposalType: {
          type: String,
          require: true,
        },
        idProposal: {
          type: String,
          require: true,
        },
        clientName: {
          type: String,
          require: true,
        },
    },
    data: () => ({
        definedModal: false,
        definedLoading: false,
        definedDisabled: false,
        proposal: null,
        items: ['Performance', 'Vendas'],
        roiType: 'Performance',
        disabled: {
            'roiType': false,
        },
        loading: {
            'roiType': false,
        },
    }),
    computed: {
        isOpenModal() {
            return this.definedModal
        },
        isLoading() {
            return this.definedLoading || this.hasLoading
        },
        isDisabled() {
            return this.isLoading || this.definedDisabled || this.hasDisabled
        },
        hasLoading() {
            return Object.values(this.loading).reduce((prev, cur) => prev || cur, false)
        },
        hasDisabled() {
            return Object.values(this.disabled).reduce((prev, cur) => prev || cur, false)
        },
    },
    methods: {
        ...mapActions([
            'subscribeChannel',
            'publishChannel',
        ]),
        openModal() {
            this.definedModal = true
        },
        closeModal() {
            this.definedModal = false
        },
        async generate() {
            this.definedLoading = true
            await RoiApi.generateFinancialFlowRoiPdf(
                this.clientName, {
                proposalType: this.proposalType,
                roiType: this.roiType == 'Performance' ? 'performance' : 'sales',
                idProposal: this.idProposal,
            })
            this.definedLoading = false
        }
    },
    watch: {
        async modal(newValue) {
            this.definedModal = newValue
            this.proposal = Utils.copyObject(this.proposal)
        },
        definedModal(newVal) {
            if (newVal) {
                this.publishChannel({
                    channel: 'financial-flow',
                    event: `modal`,
                    value: true,
                })
            } else {
                this.publishChannel({
                    channel: 'financial-flow',
                    event: `modal`,
                    value: false,
                })
            }
        },
        definedLoading(newVal) {
            if (newVal) {
                this.publishChannel({
                    channel: 'financial-flow',
                    event: `loading`,
                    value: true,
                })
            } else {
                this.publishChannel({
                    channel: 'financial-flow',
                    event: `loading`,
                    value: false,
                })
            }
        }
    },
}

</script>