import Utils from '@/scripts/Utils';

const walkObjectInfo = (object, key) => {
    let keys = Object.keys(object);

}

/*
*   Indicará o tipo do objeto mais sua estrura esperada
*
*
*   É preciso definir um padrão para valores não inicializados como null
*/
export default {
    validatedObject: null,
    refObject: null,
    types: {
        'hydrometer': [
            { key: "id", defaultValue: null },
            { key: "name", defaultValue: null },
            { key: "model", defaultValue: null },
            { key: "baseReading", defaultValue: null },
            { key: "litersPerPulse", defaultValue: null },
            { key: "deletedAt", defaultValue: null },
            { key: "updatedAt", defaultValue: null },
        ],
        'unit': [
            { key: "id", defaultValue: null },
            { key: "name", defaultValue: null },
            { key: "matricula", defaultValue: null },
            { key: "concessionaria", defaultValue: null },
            { key: "tarifa", defaultValue: null },
            { key: "grupoFaturamento", defaultValue: null },
            { key: "user", defaultValue: null },
            { key: "ultimaAtualizacao", defaultValue: null },
            { key: "address.logradouro", defaultValue: null },
            { key: "address.numero", defaultValue: null },
            { key: "address.complemento", defaultValue: null },
            { key: "address.bairro", defaultValue: null },
            { key: "address.cidade", defaultValue: null },
            { key: "address.cep", defaultValue: null },
            { key: "address.teste.t1", defaultValue: null },
            { key: "address.uf", defaultValue: null },
            { key: "economias", defaultValue: null },
            { key: "metaEconomia", defaultValue: null },
            { key: "ranking", defaultValue: null },
            { key: "tipoRanking", defaultValue: null },
            { key: "image", defaultValue: null },
            { key: "dataMediaHistorico", defaultValue: null },
            { key: "sectors", defaultValue: [] },
            { key: "imageName", defaultValue: null },
            { key: "deletedAt", defaultValue: null },
            { key: "updatedAt", defaultValue: null },
            {
                key: "tarrifsTypes", defaultValue: {
                    sewer:
                    {
                        tariffType: null,
                        value: 0
                    },
                    water:
                    {
                        tariffType: null,
                        value: 0
                    }
                    ,
                }
            },
        ],
        'sector': [
            // {key: "id", defaultValue: null},
            { key: "name", defaultValue: null },
            { key: "tipo", defaultValue: null },
            { key: "faturado", defaultValue: null },
            { key: "modem", defaultValue: {} },
        ],
        'historico': [
            // {key: "id", defaultValue: null},
            { key: "month", defaultValue: Utils.formatDate('now', '<YYYY>-<MM>') },
            { key: "day", defaultValue: Utils.formatDate('now', '<DD>') },
            { key: "competence", defaultValue: null },
            { key: "consumption_cubic_meters", defaultValue: null },
            { key: "cost_consumption", defaultValue: null },
            { key: "water_cubic_meters", defaultValue: null },
            { key: "sewer_cubic_meters", defaultValue: null },
            { key: "financial_economy", defaultValue: null },
            { key: "reference_economy", defaultValue: null },
            { key: "observation", defaultValue: null },
            { key: "deletedAt", defaultValue: null },
            { key: "updatedAt", defaultValue: null },
        ],
        'daily_consumptions': [
            { key: "id", defaultValue: null, type: 'string', },
            { key: "supplier_liters", defaultValue: null, type: 'number', },
            { key: "day", defaultValue: null, type: 'date', },
            { key: "sewer_liters", defaultValue: null, type: 'number', },
            { key: "well_liters", defaultValue: null, type: 'number', },
            // {key: "grandeza", defaultValue: null, type: 'string',},
            { key: "unitId", defaultValue: null, type: 'string', },
            { key: "deletedAt", defaultValue: null, type: 'date', },
            { key: "updatedAt", defaultValue: null, type: 'date', },
        ],
        'superAdmin': [
            { key: "id", defaultValue: null },
            { key: "email", defaultValue: null },
            { key: "password", defaultValue: null },
            { key: "name", defaultValue: null },
            { key: "rank", defaultValue: 0 },
            { key: "dataList", defaultValue: [] },
            { key: "units", defaultValue: [] },
            { key: "metadata.address.bairro", defaultValue: null },
            { key: "metadata.address.cep", defaultValue: null },
            { key: "metadata.address.cidade", defaultValue: null },
            { key: "metadata.address.complemento", defaultValue: null },
            { key: "metadata.address.logradouro", defaultValue: null },
            { key: "metadata.address.numero", defaultValue: null },
            { key: "metadata.address.uf", defaultValue: null },
            { key: "metadata.idNumber", defaultValue: null },
            { key: "metadata.idType", defaultValue: null },
            { key: "metadata.image", defaultValue: null },
            { key: "metadata.imageName", defaultValue: null },
            { key: "metadata.imageURL", defaultValue: null },
            { key: "metadata.phone", defaultValue: null },
            { key: "deletedAt", defaultValue: null, type: 'date' },
            { key: "updatedAt", defaultValue: null, type: 'date' },
        ],
        'admin': [
            { key: "id", defaultValue: null },
            { key: "email", defaultValue: null },
            { key: "password", defaultValue: null },
            { key: "name", defaultValue: null },
            { key: "rank", defaultValue: 1 },
            { key: "dataList", defaultValue: [] },
            { key: "units", defaultValue: [] },
            { key: "metadata.address.bairro", defaultValue: null },
            { key: "metadata.address.cep", defaultValue: null },
            { key: "metadata.address.cidade", defaultValue: null },
            { key: "metadata.address.complemento", defaultValue: null },
            { key: "metadata.address.logradouro", defaultValue: null },
            { key: "metadata.address.numero", defaultValue: null },
            { key: "metadata.address.uf", defaultValue: null },
            { key: "metadata.idNumber", defaultValue: null },
            { key: "metadata.idType", defaultValue: null },
            { key: "metadata.image", defaultValue: null },
            { key: "metadata.imageName", defaultValue: null },
            { key: "metadata.imageURL", defaultValue: null },
            { key: "metadata.phone", defaultValue: null },
            { key: "deletedAt", defaultValue: null, type: 'date' },
            { key: "updatedAt", defaultValue: null, type: 'date' },
        ],
        'franchisee': [
            { key: "id", defaultValue: null },
            { key: "email", defaultValue: null },
            { key: "password", defaultValue: null },
            { key: "name", defaultValue: null },
            { key: "rank", defaultValue: 2 },
            { key: "dataList", defaultValue: [] },
            { key: "units", defaultValue: [] },
            { key: "metadata.address.bairro", defaultValue: null },
            { key: "metadata.address.cep", defaultValue: null },
            { key: "metadata.address.cidade", defaultValue: null },
            { key: "metadata.address.complemento", defaultValue: null },
            { key: "metadata.address.logradouro", defaultValue: null },
            { key: "metadata.address.numero", defaultValue: null },
            { key: "metadata.address.uf", defaultValue: null },
            { key: "metadata.idNumber", defaultValue: null },
            { key: "metadata.idType", defaultValue: null },
            { key: "metadata.image", defaultValue: null },
            { key: "metadata.imageName", defaultValue: null },
            { key: "metadata.imageURL", defaultValue: null },
            { key: "metadata.phone", defaultValue: null },
            { key: "deletedAt", defaultValue: null, type: 'date' },
            { key: "updatedAt", defaultValue: null, type: 'date' },
        ],
        'client': [
            { key: "id", defaultValue: null },
            { key: "email", defaultValue: null },
            { key: "password", defaultValue: null },
            { key: "name", defaultValue: null },
            { key: "rank", defaultValue: 4 },
            { key: "manager", defaultValue: null, },
            { key: "dataList", defaultValue: [] },
            { key: "units", defaultValue: [] },
            { key: "metadata.address.bairro", defaultValue: null },
            { key: "metadata.address.cep", defaultValue: null },
            { key: "metadata.address.cidade", defaultValue: null },
            { key: "metadata.address.complemento", defaultValue: null },
            { key: "metadata.address.logradouro", defaultValue: null },
            { key: "metadata.address.numero", defaultValue: null },
            { key: "metadata.address.uf", defaultValue: null },
            { key: "metadata.idNumber", defaultValue: null },
            { key: "metadata.idType", defaultValue: null },
            { key: "metadata.image", defaultValue: null },
            { key: "metadata.imageName", defaultValue: null },
            { key: "metadata.imageURL", defaultValue: null },
            { key: "metadata.phone", defaultValue: null },
            { key: "deletedAt", defaultValue: null, type: 'date' },
            { key: "updatedAt", defaultValue: null, type: 'date' },
        ],
        'associate': [
            { key: "id", defaultValue: null },
            { key: "client", defaultValue: null },
            { key: "email", defaultValue: null },
            { key: "password", defaultValue: null },
            { key: "rank", defaultValue: 5 },
            { key: "unitsAllowedByClient", defaultValue: [] },
            { key: "metadata.address.bairro", defaultValue: null },
            { key: "metadata.address.cep", defaultValue: null },
            { key: "metadata.address.cidade", defaultValue: null },
            { key: "metadata.address.complemento", defaultValue: null },
            { key: "metadata.address.logradouro", defaultValue: null },
            { key: "metadata.address.numero", defaultValue: null },
            { key: "metadata.address.uf", defaultValue: null },
            { key: "metadata.idNumber", defaultValue: null },
            { key: "metadata.idType", defaultValue: null },
            { key: "metadata.image", defaultValue: null },
            { key: "metadata.imageName", defaultValue: null },
            { key: "metadata.imageURL", defaultValue: null },
            { key: "metadata.phone", defaultValue: null },
            { key: "deletedAt", defaultValue: null },
            { key: "updatedAt", defaultValue: null },
        ],
        //Caso nome das "tariffs" forem alterados alterar tambem em typesConcessionare() no compesaScript
        'concessionare_compesa': [
            { key: "id", defaultValue: null },
            { key: "year", defaultValue: null },
            { key: "month", defaultValue: null },
            { key: "date", defaultValue: null },
            {
                key: "tariffs", defaultValue: {
                    residential: [
                        {
                            start_liter: 1,
                            final_liter: 10000,
                            value: null,
                            is_fixed: true,
                        },
                        {
                            start_liter: 10001,
                            final_liter: 20000,
                            value: null,
                            is_fixed: false,
                        },
                        {
                            start_liter: 20001,
                            final_liter: 30000,
                            value: null,
                            is_fixed: false,
                        },
                        {
                            start_liter: 30001,
                            final_liter: 50000,
                            value: null,
                            is_fixed: false,
                        },
                        {
                            start_liter: 50001,
                            final_liter: 90000,
                            value: null,
                            is_fixed: false,
                        },
                        {
                            start_liter: 90001,
                            final_liter: 999999000,
                            value: null,
                            is_fixed: false,
                        },
                    ],
                    commercial: [
                        {
                            start_liter: 1,
                            final_liter: 10000,
                            value: null,
                            is_fixed: true,
                        },
                        {
                            start_liter: 10001,
                            final_liter: 999999000,
                            value: null,
                            is_fixed: false,
                        },
                    ],
                    industrial: [
                        {
                            start_liter: 1,
                            final_liter: 10000,
                            value: null,
                            is_fixed: true,
                        },
                        {
                            start_liter: 10001,
                            final_liter: 999999000,
                            value: null,
                            is_fixed: false,
                        },
                    ],
                    public: [
                        {
                            start_liter: 1,
                            final_liter: 10000,
                            value: null,
                            is_fixed: true,
                        },
                        {
                            start_liter: 10001,
                            final_liter: 999999000,
                            value: null,
                            is_fixed: false,
                        },
                    ],
                }
            },
            { key: "company", defaultValue: 'compesa' },
            { key: "deletedAt", defaultValue: null },
            { key: "updatedAt", defaultValue: null },
        ],
        'called': [
            { key: "user", defaultValue: null },
            { key: "comments", defaultValue: [] },
            { key: "openTime", defaultValue: null },
            { key: "status", defaultValue: null },
            { key: "natureCalled", defaultValue: null },
            { key: "imageName", defaultValue: null },
            { key: "imageURL", defaultValue: null },
            { key: "description", defaultValue: null },
            { key: "connections", defaultValue: false },
        ],
        'enroll': [
            { key: "manager", defaultValue: null, },
            { key: "name", defaultValue: null, },
            { key: "email", defaultValue: null, },

            { key: "localeData.idNumber", defaultValue: null },
            { key: "localeData.phone", defaultValue: null },
            { key: "localeData.idType", defaultValue: null },
            { key: "localeData.address.bairro", defaultValue: null },
            { key: "localeData.address.cep", defaultValue: null },
            { key: "localeData.address.cidade", defaultValue: null },
            { key: "localeData.address.complemento", defaultValue: null },
            { key: "localeData.address.logradouro", defaultValue: null },
            { key: "localeData.address.numero", defaultValue: null },
            { key: "localeData.address.uf", defaultValue: null },
            
            { key: "responsible.idNumber", defaultValue: null },
            { key: "responsible.phone", defaultValue: null },
            { key: "responsible.idType", defaultValue: null },
            { key: "responsible.email", defaultValue: null },
            { key: "responsible.name", defaultValue: null },
        ],
        'waterfix_checklist_solution': [
            { key: "local", defaultValue: null, },
            { key: "sub_local", defaultValue: null, },
            { key: "type", defaultValue: null, },
            { key: "solution", defaultValue: null, },
            { key: "amountSolution", defaultValue: null, },
            { key: "flowRateBefore", defaultValue: null, },
            { key: "flowRateAfter", defaultValue: null, },
            { key: "comments", defaultValue: null, },
            // { key: "category", defaultValue: null, },
            { key: "date", defaultValue: null, },
            { key: "status", defaultValue: null, },
            { key: "economy", defaultValue: 0, },
            { key: "photos.before", defaultValue: [] },
            { key: "photos.after", defaultValue: [] },
            { key: "photos.extras", defaultValue: [] },
        ],

    },
    hasStruct(type) {
        if (!type) return false;
        type = type.toLowerCase();
        return this.types.hasOwnProperty(type);
    },
    getStruct(type) {
        if (!this.hasStruct(type)) return null;
        return this.types[type.toLowerCase()];
    },
    validator(type, object, isCompleteStructure = false) {
        if (!this.hasStruct(type)) throw new Error("O tipo informado não existe na validação")
        type = type.toLowerCase();
        this.validatedObject = null;
        let allKeys = Object.keys(object);
        let overKeys = [];
        let lowKeys = [];
        let allowedKeys = this.getStruct(type).map(struct => struct.key);

        /*
        *   overKeys mostra quais a chaves foram adicionadas além do definido
        *   lowKeys mostra as chaves que estão faltando da estrutura
        */
        overKeys = allKeys.filter((key) => !allowedKeys.includes(key));

        let keyErrorLength = overKeys.length;
        if (keyErrorLength > 0) throw new Error(`O objeto do tipo '${type}' a${keyErrorLength > 1 ? 's' : ''} chave${keyErrorLength > 1 ? 's' : ''} '${overKeys.join("', '")}' não faz${keyErrorLength > 1 ? 'em' : ''} parte da estrutura`);

        if (isCompleteStructure) {
            lowKeys = allowedKeys.map((key) => {
                if (!object.hasOwnProperty(key) && !overKeys.includes(key)) {
                    return key;
                } else {
                    return null;
                }
            }).filter(value => value ? true : false);

            keyErrorLength = lowKeys.length;
            if (keyErrorLength > 0) throw new Error(`O objeto do tipo '${type}' a${keyErrorLength > 1 ? 's' : ''} chave${keyErrorLength > 1 ? 's' : ''} '${lowKeys.join("', '")}' ${keyErrorLength > 1 ? 'são' : 'é'} obrigatória${keyErrorLength > 1 ? 's' : ''}`);
        }

        this.validatedObject = object;

        return this;
    },
    create(type, data = null) {
        if (!this.hasStruct(type)) throw new Error("O tipo informado não existe na criação")
        type = type.toLowerCase();
        let object = {};
        this.getStruct(type).forEach(
            (struct) => {
                let keys = struct.key.split("\.");
                let lastKey = keys.pop();
                let ref = object;
                let dataRef = data ? data : null;
                keys.forEach(
                    (key) => {
                        if (!ref.hasOwnProperty(key)) ref[key] = {};
                        ref = ref[key];
                        if (dataRef) {
                            if (!dataRef.hasOwnProperty(key)) dataRef[key] = {};
                            dataRef = dataRef[key];
                        }
                    }
                );

                if (data && struct.hasOwnProperty('type')) {
                    let hasConverted = false;
                    if (dataRef[struct.key] !== null && dataRef[struct.key] !== undefined) {
                        switch (struct.type.toLowerCase()) {
                            case 'date':
                                if (typeof dataRef[struct.key] === 'object') {
                                    if (dataRef[struct.key].hasOwnProperty('seconds')) {
                                        ref[lastKey] = new Date(parseInt(dataRef[struct.key].seconds) * 1000);
                                        hasConverted = true;
                                    }
                                } else {
                                    let content = dataRef[struct.key].toString();
                                    if (content.match(/^[0-9]+$/)) {
                                        ref[lastKey] = new Date(parseInt(dataRef[struct.key]) * 1000);
                                        hasConverted = true;
                                    } else {
                                        ref[lastKey] = new Date(dataRef[struct.key]);
                                        hasConverted = true;
                                    }
                                }
                                break;

                            case 'number':
                                ref[lastKey] = parseFloat(dataRef[struct.key]);
                                hasConverted = true;
                                break;
                        }
                    }
                    if (!hasConverted) ref[lastKey] = dataRef[struct.key] === undefined ? null : dataRef[struct.key];
                } else {
                    ref[lastKey] = (dataRef === null) || dataRef[struct.key] === undefined ? struct.defaultValue : dataRef[struct.key];
                }
            }
        );
        return object;
    },
    toDataBase(object = null) {
        if (!object && !this.validatedObject) throw new Error("Não houve objeto informado para salvar no banco de dados");
        let objectRead = {}
        if (object) objectRead = object;
        else if (this.validatedObject) objectRead = this.validatedObject;
        Object.keys(objectRead).map(
            (key) => {
                if (
                    (typeof objectRead[key] === 'object'
                        &&
                        objectRead[key] !== null)
                    &&
                    objectRead[key].hasOwnProperty('id')
                ) {
                    objectRead[key] = objectRead[key].id;
                } else if (Array.isArray(objectRead[key])) {
                    objectRead[key] = objectRead[key].map(
                        (obj) => {
                            if (
                                (
                                    typeof obj === 'object'
                                    &&
                                    obj !== null
                                )
                                &&
                                obj.hasOwnProperty('id')
                            ) {
                                obj = obj.id;
                            }
                            return obj;
                        }
                    );
                }
            }
        );
        this.validatedObject = null;
        return objectRead;
    },
    // merge(type, toObjhect, fromObject) {
    //     this.validator(type, toObjhect);

    // },
    verification(type, data) {
        if (!this.hasStruct(type)) throw new Error("O tipo informado não existe na criação")
        type = type.toLowerCase();
        let object = data
        this.getStruct(type).forEach(
            (struct) => {
                let keys = struct.key.split("\.");
                let lastKey = keys.pop();
                let ref = object;
                keys.forEach(
                    (key) => {
                        if (!ref.hasOwnProperty(key)) ref[key] = {};
                        ref = ref[key];
                    }
                );
                if (!ref.hasOwnProperty(lastKey)) ref[lastKey] = struct.defaultValue;
            }
        );
        return object
    },
}