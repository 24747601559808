import { mapActions, mapGetters } from "vuex";
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import Utils from '@/scripts/Utils';
import Rules from "@/utils/Rules";

import LeadsService from "@/services/server/LeadsService.js";
import ProjectsService from "@/services/server/ProjectsService.js";

import NextStepProject from "@/components/Projects/NextStepProject.vue"
import DiagnosticStepper from "@/components/Projects/Diagnostic/DiagnosticStepper.vue"

import CheckListTab from "@/components/Projects/Diagnostic/Tabs/CheckList/CheckList.vue"
import HistoricTab from "@/components/Projects/Diagnostic/Tabs/Historic/Historic.vue"

export default {
	name: "Diagnóstico",

    components: {
        NextStepProject,
        DiagnosticStepper,
        CheckListTab,
        HistoricTab,
    },

	data: () => ({
		is_loading: false,
        is_created: false,
        dialog: false,
        id_project: null,
        id_diagnostic: null,
        // isValidForm: false,
        is_completed: true,
        diagnostic: {
            
        },
        steps: [
            {
                id: 'checklist',
                name: 'CheckList',
                step: '1',
                editable: true,
                complete: false,
                rules: [() => true],
            },
            {
                id: 'historic',
                name: 'Histórico',
                step: '2',
                editable: true,
                complete: false,
                rules: [() => true],
            },
        ],
        visibleTabs: {
            checklist: true,
            historic: false,
        }
	}),

	computed: {
		
	},

	methods: {
		...mapActions([
			"subscribeChannel",
		]),
        nextStep() {
            console.log("Indo para o próximo passo...")
        },
        close() {
            this.dialog = false
        },
        nextTab(tabName) {
            console.log("Proxima aba...", tabName)
            let index = this.steps.findIndex(
                ({ step }) => {
                    return step === tabName
                }
            )
            if (index < 0) {
                throw new Error(`Não foi encontrado a aba: ${tabName}`)
            }
            Object.keys(this.visibleTabs).forEach(
                (key) => {
                    if (key === this.steps[index].id) {
                        this.visibleTabs[key] = true
                    } else {
                        this.visibleTabs[key] = false
                    }
                }
            )
        },
        updateDiagnostic(diagnostic) {
            this.diagnostic = diagnostic
        },
        validateTab(tabName, status) {
            let index = this.steps.findIndex(
                ({ id }) => {
                    return id === tabName
                }
            )
            if (index < 0) {
                throw new Error(`Não foi encontrado a aba: ${tabName}`)
            }
            this.steps[index].rules = [() => status]
        },
	},
	
	watch: {
        id_diagnostic(newValue) {
            console.log("id_diagnostic - watch", newValue)
        },
	},

	async created() {
        this.subscribeChannel({
            channel: 'projects',
            events: [
                {
                    event: 'diagnostic:open',
                    fn: ([
                        id_project,
                        id_diagnostic,
                        is_completed,
                    ]) => {
                        console.log("id_project", id_project)
                        console.log("id_diagnostic", id_diagnostic)
                        console.log("is_completed", is_completed)
                        this.id_project = id_project ? id_project : this.id_project
                        this.id_diagnostic = id_diagnostic ? id_diagnostic : this.id_diagnostic
                        this.is_completed = is_completed
                        this.dialog = true
                        // Caso o id do diagnostic seja diferente de null
                        // busque os dados
                        this.is_created = true;
                        this.is_loading = false;
                    }
                },
            ]
        })
        
    },
};