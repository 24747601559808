import check from 'check-types'
import Utils from '../../../scripts/Utils';

const PIPING_POINT_ID = "vIEqBb4EEnWAJU5HbYXs"

export default class SolutionListController {
    constructor(solutionListModel) {
        this.solutionListModel = solutionListModel
    }

    calculateEconomy() {
        let flowRateBefore = this.solutionListModel.get('flowRateBefore')
        let flowRateAfter = this.solutionListModel.get('flowRateAfter')
        if (flowRateBefore == 0) return 0
        let economy = 100 - ((flowRateAfter / flowRateBefore) * 100)
        economy = parseFloat(Math.abs(economy).toFixed(2))
        this.solutionListModel.set('economy', economy)
    }

    verifyPipingType() {
        let type = this.solutionListModel.get('type')
        let pipingType = this.solutionListModel.get('pipingType')
        if (type !== PIPING_POINT_ID) {
            this.solutionListModel.set('pipingType', "")
        } else {
            if (pipingType === "") {
                this.solutionListModel.set('pipingType', "PVC")
            }
        }
    }

    verifyFlowRateType() {
        let flowRateType = this.solutionListModel.get('flowRateType')
        if (flowRateType === "") {
            this.solutionListModel.set('flowRateType', "REAL")
        }
    }

}