import check, { integer } from 'check-types'
import Model from '../../Model';

const KEYS = [
    'amountSolution',
    'economy',
    'flowRateAfter',
    'flowRateBefore',
    'imgNameList',
    'local',
    'solution',
    'sub_local',
    'type',
    'pipingType',
    'flowRateType',
    'comments',
]

const STRUCT = {
    'amountSolution': {
        'default': 0,
        'check': (val) => {
            if (check.not.integer(val)) throw new Error("The quantity of the solution is not integer")
            if (check.negative(val)) throw new Error("The quantity of the solution cannot be negative")
        },
        'parse': (val) => {
            let valStr = val.toString()
            if (valStr.match(/^\d+$/)) return parseInt(val)
            return 0
        },
    },
    'economy': {
        'default': 0,
        'check': (val) => {
            if (check.not.number(val)) throw new Error("Solution economics is not a number")
            if (check.negative(val)) throw new Error("Solution economics cannot be negative")
        },
        'parse': (val) => {
            let valStr = val.toString()
            if (valStr.match(/^\d+$/)) return parseInt(val)
            return 0
        },
    },
    'flowRateAfter': {
        'default': 0,
        'check': (val) => {
            if (check.not.number(val)) throw new Error("After flow rate is not a number")
            if (check.negative(val)) throw new Error("After flow rate cannot be negative")
        },
        'parse': (val) => {
            let valStr = val.toString()
            if (valStr.match(/^\d+$/)) return parseInt(val)
            return 0
        },
    },
    'flowRateBefore': {
        'default': 0,
        'check': (val) => {
            if (check.not.number(val)) throw new Error("Before flow rate is not a number")
            if (check.negative(val)) throw new Error("Before flow rate cannot be negative")
        },
        'parse': (val) => {
            let valStr = val.toString()
            if (valStr.match(/^\d+$/)) return parseInt(val)
            return 0
        },
    },
    'imgNameList': {
        'default': [],
        'check': (val) => {
            if (check.not.array(val)) throw new Error("The image list is not a array")
            if (check.not.array.of.string(val)) throw new Error("The image list is not a string array")
        },
    },
    'local': {
        'default': "",
        'check': (val) => {
            if (check.not.string(val)) throw new Error("The local is not string")
        },
    },
    'solution': {
        'default': "",
        'check': (val) => {
            if (check.not.string(val)) throw new Error("The solution is not string")
        },
    },
    'sub_local': {
        'default': "",
        'check': (val) => {
            if (check.not.string(val)) throw new Error("The sublocal is not string")
        },
    },
    'type': {
        'default': "",
        'check': (val) => {
            if (check.not.string(val)) throw new Error("The type is not string")
        },
    },
    'pipingType': {
        'default': "",
        'check': (val) => {
            if (check.not.string(val)) throw new Error("The Piping Type is not string")
        },
    },
    'flowRateType': {
        'default': "",
        'check': (val) => {
            if (check.not.string(val)) throw new Error("The Flow Rate Type is not string")
        },
    },
    'comments': {
        'default': "",
        'check': (val) => {
            if (check.not.string(val)) throw new Error("The comments is not string")
        },
    },
}

export default class SolutionListModel extends Model {
    constructor(solution = null) {
        super(KEYS, STRUCT);
        if (solution) this.set(solution)
    }

    static getPipingTypeList() {
        return  [
            'PEX',
            'PPR',
            'PVC',
            'GALVANIZADO',
            'INOX',
        ]
    }

    static getFlowTypeList() {
        return  [
            'REAL',
            'PROJETADA',
        ]
    }

}