import SelectClient from "@/components/Widgets/Select/select-client.vue";
import SelectFranchisee from "@/components/Widgets/Select/select-franchisee.vue";
import SelectUnit from "@/components/Widgets/Select/select-unit.vue";
import firebase from "@/firebase";
import Utils from '@/scripts/Utils';
import CalledApi from "@/services/apiPdf/CalledApi";
import EmailService from "@/services/email/Email";
import { mapGetters } from "vuex";
import DialogSendEmail from "@/components/DialogSendEmail/DialogSendEmail";
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
export default {

  components: {
    SelectFranchisee,
    SelectClient,
    SelectUnit,
    DialogSendEmail
  },
  data() {
    return {
      franchisee_id: null,
      client_id: null,
      unit_id: null,
      reset_client: true,
      data_table: [],
      data_table_expand: [],
      summary: {
        openCall: 0,
        progressCall: 0,
        concluded: 0,
        size: 0
      },
      expanded: [],
      show_expand: true,
      loading: {
        main_table: false,
        general_button_pdf: false,
        main_button_pdf: false,
        secondary_table: false,
        secondary_button_pdf: false,
        pdf_index_button: '',
      },
      dessertHeaders: [
        {
          text: 'Unidade',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Chamados', value: 'size', align: 'center' },
        { text: 'Abertos', value: 'openCall', align: 'center' },
        { text: 'Andamento', value: 'progressCall', align: 'center' },
        { text: 'Concluídos', value: 'concluded', align: 'center' },
        { text: 'Ações', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      dessertHeaders_2: [
        {
          text: 'Setor',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Chamados', value: 'size', align: 'center' },
        { text: 'Abertos', value: 'openCall', align: 'center' },
        { text: 'Andamento', value: 'progressCall', align: 'center' },
        { text: 'Concluídos', value: 'concluded', align: 'center' },
        { text: 'Ações', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      data_units: [],
      startDateDialog: false,
      maxStartDate: '',
      startDatePicker: [Utils.formatDate(Utils.generateLastDays(15)[14], "<YYYY>-<MM>-<dd>"), Utils.formatDate(Utils.generateLastDays(15)[0], "<YYYY>-<MM>-<dd>")],
      lastValueSelect: [
        {},
        { select: 'client' }
      ],
      titles_button_download: [{ title: "PDF" }, { title: "Email" }],
      data_body_email: null,
      dialog_send_email: false
    }

  },
  computed: {
    ...mapGetters(["getCurrentUser", "getCurrentUserRank", "getUsers", "getUnitByIDs", "getAssociados"]),
    isShowAdmin() {
      return this.getCurrentUserRank === "Super Admin" || this.getCurrentUserRank === "Admin"
    },
    isShowFranchisee() {
      return this.getCurrentUserRank === "Franqueado"
    },
    isShowClient() {
      return this.getCurrentUserRank === "Cliente"
    },
    isShowAssociated() {
      return this.getCurrentUserRank === "Associado"
    },
    SelectDateFormat() {
      return this.startDatePicker ? Utils.formatDate(this.orderStartDate()[0], "<dd>/<MM>/<YYYY>") + " a " + Utils.formatDate(this.orderStartDate()[1], "<dd>/<MM>/<YYYY>") : ""
      
    },
  },
  methods: {
    async onExpand({ item, value }) {
      this.data_table_expand = []
      if (!value) return
      this.loading.secondary_table = true
      this.data_table_expand = await this.searchCallsList(item)
      await this.valuesTableSum(this.data_table_expand)
    },
    async catch_franchisee_id(franchisee_id) {
      this.setHeaders('franchisee')
      this.franchisee_id = franchisee_id;
      this.data_table = []
      this.lastValueSelect = [
        { [`connections.franchisee`]: franchisee_id },
        { select: 'client' }
      ]
      if (this.isShowAdmin) {
        let calls = await firebase.getCallsBy({ [`connections.franchisee`]: franchisee_id }, this.getSelectDateTime());
        this.data_table = await this.filterCalls(calls, 'client')
        await this.valuesTableSum(this.data_table)
        await this.summarySum(this.data_table)
      } else {
        this.loading.main_table = false
      }
    },
    async catch_client_id(client_id) {
      if (client_id === null) {
        this.data_table = []
        this.data_table_expand = []
        this.catch_franchisee_id(this.franchisee_id)
        return
      }
      this.setHeaders('unit')
      this.data_table = []
      this.unit_id = null
      this.client_id = client_id;
      this.lastValueSelect = [
        { [`connections.client_id`]: client_id },
        { select: 'unit' }
      ]

      let calls = await firebase.getCallsBy({ [`connections.client_id`]: client_id }, this.getSelectDateTime());

      this.data_table = await this.filterCalls(calls, 'unit')
      //----filter units by associated

      if (this.getCurrentUser.data.rank === "Associado") {
        let list = []
        this.getCurrentUser.data.unitsAllowedByClient.forEach(idUnit => {
          list.push(...this.data_table.filter(({ id }) => idUnit === id))
        });
        this.data_table = list
      }
      //----

      await this.valuesTableSum(this.data_table)
      await this.summarySum(this.data_table)
    },
    async catch_unit_id(unit_id) {
      if (unit_id === null) {
        return
      }
      this.setHeaders('sector')
      this.unit_id = unit_id;
      this.data_table = []

      this.lastValueSelect = [
        { "connections.unit_id": unit_id },
        { select: 'unit' }
      ]
      let calls = await firebase.getCallsBy({ "connections.unit_id": unit_id }, this.getSelectDateTime())
      this.data_table = await this.filterCalls(calls, 'sector')
      await this.valuesTableSum(this.data_table)
      await this.summarySum(this.data_table)
    },
    async filterCalls(value, type) {
      let list = []
      if (value.length > 0) {
        value.map(call => {

          let resultSearchName = list.map(i => {
            return i.name === call.connections[type]
          }).find(resultMap => resultMap === true)

          if (!resultSearchName) {
            list.push({
              name: call.connections[type],
              id: call.connections[`${type}_id`],
              size: 1,
              calls: [call]
            })
          } else {
            list.map((n) => {
              if (n.name === call.connections[type]) {
                n.calls = [...n.calls, call]
                n.size += 1
              }
            })
          }
        })
      }
      return list
    },
    async summarySum(data) {
      this.summary = {
        openCall: 0,
        progressCall: 0,
        concluded: 0,
        size: 0
      }
      data.map((line) => {
        this.summary.concluded += line.concluded
        this.summary.openCall += line.openCall
        this.summary.progressCall += line.progressCall
        this.summary.size += line.size
      })
    },
    async valuesTableSum(data) {
      data.map((i, index) => {
        let openCall = 0
        let progressCall = 0
        let concluded = 0
        i.calls.map((call, i) => {

          if (call.status === "green") {
            concluded += 1
          } else if (call.status === "#FF8300") {
            progressCall += 1
          } else {
            openCall += 1
          }
          data[index] = { ...data[index], openCall, progressCall, concluded, index }
        })
      })
      this.loading.main_table = false
      this.loading.secondary_table = false
    },
    setHeaders(action) {
      this.loading.main_table = true
      switch (action) {
        case 'franchisee':
          this.dessertHeaders['0'].text = 'Cliente'
          this.dessertHeaders_2['0'].text = 'Unidade'
          this.show_expand = true
          break;
        case 'unit':
          this.dessertHeaders['0'].text = 'Unidade'
          this.dessertHeaders_2['0'].text = 'Setor'
          this.show_expand = true
          break;
        case 'sector':
          this.dessertHeaders['0'].text = 'Setor'
          this.show_expand = false
          break;
      }
    },
    dateDownloadPDf() {
      let date = this.startDatePicker.map(date => {
        return (new Date(date).getTime())
      })
      if (date[0] > date[1]) {
        date = [
          date[1],
          date[0]
        ]
      }
      return Utils.formatDate(date[0], "<YYYY>-<MM>-<dd>") + " " + Utils.formatDate(date[1], "<YYYY>-<MM>-<dd>")
    },
    async generateGeneralPdf() {
      this.loading.general_button_pdf = true
      let result = []
      if (!this.unit_id) {
        result = this.getUsers.filter(({ id }) => id == this.lastValueSelect[0]['connections.client_id'])
        let data = { ...this.summary, name: result.map(({ data }) => data.name)[0] }
        data.currentUser = this.getCurrentUser.uid
        data.rank = this.getCurrentUser.data.rank
        data.id = this.client_id
        await CalledApi.generatePdf({ ...data, type: 'client' }, this.getSelectDateTime())
          .then(response => {
            CalledApi.downloadPdf(this.dateDownloadPDf() + " " + data.name)
          })
          .catch(err => console.error(err))
      } else if (this.unit_id) {
        result = this.data_units.filter(({ id }) => id == this.lastValueSelect[0]['connections.unit_id'])
        let data = { ...this.summary, name: result.map(({ data }) => data.name)[0] }
        data.currentUser = this.getCurrentUser.uid
        data.rank = this.getCurrentUser.data.rank
        await CalledApi.generatePdf({ ...data, type: 'unit' }, this.getSelectDateTime())
          .then(response => {
            CalledApi.downloadPdf(this.dateDownloadPDf() + " " + data.name)
          })
          .catch(err => console.error(err))
      }
      this.loading.general_button_pdf = false
    },
    async generatePdf(data, type = null,selectList) {
      this.loading.pdf_index_button = data.index
      type ? this.loading.secondary_button_pdf = true : this.loading.main_button_pdf = true

      data.currentUser = this.getCurrentUser.uid
      data.rank = this.getCurrentUser.data.rank
      let list = []
      delete data.calls
      if(selectList === "PDF"){
        if (this.franchisee_id && !this.client_id && !type) {
          await CalledApi.generatePdf({ ...data, type: 'client' }, this.getSelectDateTime())
            .then(response => {
              CalledApi.downloadPdf(this.dateDownloadPDf() + " " + data.name)
            })
            .catch(err => console.error(err))
        } else {
          await CalledApi.generatePdf({ ...data, type: 'unit' }, this.getSelectDateTime())
            .then(response => {
              CalledApi.downloadPdf(this.dateDownloadPDf() + " " + data.name)
            })
            .catch(err => console.error(err))
        }
      }else if(selectList === "Email"){
        this.dialog_send_email = !this.dialog_send_email
        this.data_body_email = {data: { ...data, type: 'client' }, startDatePicker: this.getSelectDateTime(),name: data.name }
      }
      this.loading.main_button_pdf = false
      this.loading.secondary_button_pdf = false
      this.loading.general_button_pdf = false
      this.loading.pdf_index_button = ''
    },

    async searchCallsList(data, type = null) {
      let list = []
      let calls = []
      if (this.franchisee_id && !this.client_id && !type) {
        calls = await firebase.getCallsBy({ "connections.client": data.name }, this.getSelectDateTime())
        list = await this.filterCalls(calls, 'unit')
      } else {
        calls = await firebase.getCallsBy({ "connections.unit": data.name }, this.getSelectDateTime())
        list = await this.filterCalls(calls, 'sector')
      }
      return list
    },
    getSelectDateTime() {
      let dateReturn = null
      this.startDatePicker ?
        dateReturn = this.startDatePicker.map(date => {
          return new Date(date).getTime()
        })
        : null

      return dateReturn
    },
    resetSelects() {
      if (this.startDatePicker || this.client_id) {
        this.client_id = null
        this.expanded = []
        this.reset_client = !this.reset_client
      }
    },
    async selectDateCalendar(date) {
      this.startDatePicker = date
      this.startDateDialog = !this.startDateDialog
      if (this.lastValueSelect && this.franchisee_id) {
        this.loading.main_table = true
        this.data_table = []
        let calls = await firebase.getCallsBy(this.lastValueSelect[0], this.getSelectDateTime())
        this.data_table = await this.filterCalls(calls, this.lastValueSelect[1].select)
        await this.valuesTableSum(this.data_table)
        await this.summarySum(this.data_table)
      }
    },
    startDateCalendar() {
      const date = Utils.formatDate(new Date(), "<YYYY>-<MM>-<dd>");
      this.startDateDialog = true
      this.maxStartDate = date
    },
    orderStartDate() {
      if (this.startDatePicker[0] > this.startDatePicker[1]) {
        return [
          this.startDatePicker[1],
          this.startDatePicker[0]
        ]
      }
      return [
        this.startDatePicker[0],
        this.startDatePicker[1]
      ]
    },
    async sendEmail(receiver, message) {
      try {
        if (!this.data_body_email) throw "Body não definido!"
        const name = this.data_body_email.name
        const user_email = receiver
        const relatorio = "Chamados"
        const data = {
          ...this.data_body_email.data
        }
        const date_time = this.data_body_email.startDatePicker
        const file_name = this.data_body_email.name.split(" ").join("_")
        const endpoint = "/report/called"
        
        await EmailService.sendEmail({
          name,
          user_email,
          relatorio,
          data,
          date_time,
          file_name,
          endpoint
        })

        showSuccessSnackBar("Email enviado!")
      } catch (err) {
        console.error(err)
        showErrorSnackBar("Erro ao enviar email!")
        return
      }
      this.data_body_email = null
    }
  },
  mounted() {
    this.loading_units = true

    firebase.getUnits()
      .then(res => this.data_units = res)
      .finally(() => {
        if (this.last_serach_alerts) this.catch_id(this.last_serach_alerts)
        this.loading_units = false
      })

  }
}