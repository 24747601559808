import { db } from "@/firebase"

const role_features_ref = db.collection("role_features")

const arrayChunkSeparate500 = async (data, chunkSize = 500) => {
    let result = []
    for (let i = 0; i < data.length; i += chunkSize) {
        const chunk = data.slice(i, i + chunkSize);
        result.push(chunk)
    }
    return result
}


async function getRoleFeaturesByIdRole(idRole) {
    let role_features_list = []
    try {
        const unitsRef = await role_features_ref.where("id_role", "==", idRole).get()
        unitsRef.forEach((doc) => role_features_list.push({...doc.data(),id_doc: doc.id}));
        return role_features_list
    } catch (error) {
        console.error(error)
    }
}
async function getRoleFeatures() {
    let role_features_list = []
    try {
        const roleFeaturesRef = await role_features_ref.get()
        roleFeaturesRef.forEach((doc) => role_features_list.push({...doc.data(),id_doc: doc.id}));
        return role_features_list
    } catch (error) {
        console.error(error)
    }
}

const createRoleFeaturesList = async (alertList) => {
    try {
        const listSave = await arrayChunkSeparate500(alertList, 200)
        for (let list of listSave) {
            let batch = db.batch();
            for (let alert of list) {
                let ref = role_features_ref.doc()
                batch.set(ref, { ...alert });
            }
            await batch.commit();
        }
        return true
    } catch (error) {
        throw new Error(`createRoleFeaturesList Fail! ${error}`)
    }
}

const deleteRoleFeaturesList = async (roleFaturesList) => {
    try {
        const listSave = await arrayChunkSeparate500(roleFaturesList, 200)
        for (let list of listSave) {
            let batch = db.batch();
            for (let roleFeature of list) {
                let ref = role_features_ref.doc(roleFeature.id_doc)
                batch.delete(ref);
            }
            await batch.commit();
        }
        return true
    } catch (error) {
        throw new Error(`deleteRoleFeaturesList Fail! ${error}`)
    }
}


export default {
    getRoleFeaturesByIdRole,
    deleteRoleFeaturesList,
    createRoleFeaturesList,
    getRoleFeatures,
};