<template>
  <div>
    <v-autocomplete v-bind="$attrs" item-text="name" item-value="id" return-object :items="list" :label="label"
      :disabled="isLoadingData || $attrs.disabled" :loading="isLoadingData || $attrs.loading" :filter="customFilter"
      @change="selectItem" hide-no-data hide-selected persistent-hint v-model="selectedItem">
      <template v-slot:selection="{ item, index }">
        <span>{{ item.name + " - " + item.document }}</span>
      </template>
      <template v-slot:append-outer>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="primary" v-bind="attrs" v-on="on">
              mdi-pen-plus
            </v-icon>
          </template>
          <v-list>
            <v-list-item @click="addNewType">
              <v-list-item-title>Criar</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="selectedItem" @click="editProjectSeller(selectedItem)">
              <v-list-item-title>Editar</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="selectedItem" @click="confirmDelete()">
              <v-list-item-title>Excluir</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-slot:item="{ item, index }">
        <span>{{ item.name + " - " + item.document }}</span>
      </template>
    </v-autocomplete>
    <v-dialog v-model="openModal" v-if="openModal" persistent max-width="600px">
      <v-card>
        <!-- <v-progress-linear v-if="loadingBar" indeterminate color="white" class="mb-0"></v-progress-linear> -->
        <v-card-title>
          <span class="text-h5"> Cadastro de Vendedor </span>
        </v-card-title>
        <v-card-text>
          <v-divider />
          <v-form v-model="validForm" lazy-validation ref="FormProjectSeller">
            <v-row>
              <v-col cols="12" v-if="getCurrentUserRank === 'Super Admin'">
                <v-autocomplete v-model="partnerSeller.id_franchisee" :items="listFranchisees" item-text="name"
                  item-value="id" label="Franqueado" dense outlined hide-details :rules="rulesRequired" />
              </v-col>
              <v-col cols="6">
                <v-text-field outlined label="Nome" :rules="rulesRequired" required
                  v-model="partnerSeller.name"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field outlined label="E-mail" :rules="rulesEmail" required
                  v-model="partnerSeller.email"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field outlined v-model="partnerSeller.phone" label="Telefone" required :rules="rulesRequired"
                  v-mask="'(##) #####-####'" />
              </v-col>
              <v-col cols="6">
                <InputCpfCnpj required v-model="partnerSeller.document"></InputCpfCnpj>
              </v-col>
            </v-row>
          </v-form>
          <v-divider />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="openModal = false" :disabled="loading.saveButton">
            Cancelar
          </v-btn>
          <v-btn color="blue darken-1" text @click="save()" :loading="loading.saveButton" :disabled="!validForm">
            {{ buttonConfirmSaveOrEdit }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Rules from "@/utils/Rules";
import { mapActions, mapGetters } from "vuex";
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper";
import { showErrorSnackBar, showInfoSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import ProjectSellerService from "@/services/firebase/ProjectSeller/ProjectSellerService";
import InputCpfCnpj from "@/components/Forms/Input/input-cpf-cnpj.vue";
import UsersService from "@/services/firebase/Users/UsersService";

export default {
  components: {
    InputCpfCnpj
  },
  props: {
    id_project_seller: {
      type: String,
      default: null,
    }
  },
  data: () => ({
    loading: {
      saveButton: false,
      cancelButton: false
    },
    listFranchisees: [],
    selectedItem: null,
    selectedItemProp: null,
    partnerSeller: {},
    buttonConfirmSaveOrEdit: "Salvar",
    openModal: false,
    label: "Vendedor",
    canProceed: true,
    list: [],
    selected: null,
    isLoadingData: true,
    validForm: false,
    franchiseeSelected: null,
  }),

  computed: {
    ...mapGetters(["getFranqueados", "getCurrentUser", "getCurrentUserRank"]),
    rulesRequired() {
      return Rules.required()
    },
    rulesEmail() {
      return Rules.email(true, 64)
    },
    isFranchisee() {
      return this.getCurrentUserRank === "Franqueado"
    }
  },

  methods: {
    ...mapActions(["getClientsBy"]),
    selectItem() {
      if (this.selectedItem && this.selectedItem.id_doc) this.$emit('id', this.selectedItem.id_doc)
      else this.$emit('id', null)
    },
    async confirmDelete() {
      showDeleteDialogAlert(`Deseja excluir esse vendedor ${this.selectedItem.name}?`, this.deletePartner)
    },
    async deletePartner() {
      await ProjectSellerService.editProjectSellers(this.selectedItem.id_doc, { ...this.selectedItem, deletedAt: new Date() })
      this.list = this.list.filter(({ id_doc }) => id_doc !== this.selectedItem.id_doc)
      this.selectedItem = null
    },
    customFilter(item, queryText, itemText) {
      const text = queryText.toLowerCase();
      return (
        item.name.toLowerCase().includes(text) ||
        item.document.toLowerCase().includes(text)
      );
    },
    async checkExistUser() {
      const existProjectPartner = await ProjectSellerService.getProjectSellerByDocument(this.partnerSeller.document)
      if (existProjectPartner && this.isFranchisee && existProjectPartner.id_franchisee !== this.getCurrentUser.uid) {
        showInfoSnackBar('Esse vendedor já está cadastrado com outro Franqueado!')
        throw new Error('Esse vendedor já está cadastrado com outro Franqueado!')
      } else if (existProjectPartner) {
        showInfoSnackBar('Esse vendedor já está cadastrado!')
        throw new Error('Esse vendedor já está cadastrado!')
      }
    },
    async save() {
      try {
        if (this.$refs.FormProjectSeller && !this.$refs.FormProjectSeller.validate()) {
          this.canProceed = false;
          return;
        }

        this.canProceed = true

        if (this.isFranchisee) this.partnerSeller.id_franchisee = this.getCurrentUser.uid
        this.loading.saveButton = true

        if (this.buttonConfirmSaveOrEdit === "Salvar") {
          await this.checkExistUser()
          const idSaved = await ProjectSellerService.createProjectSellers({ ...this.partnerSeller })
          this.selectedItem = { ...this.partnerSeller, id_doc: idSaved }
          this.list.push(this.selectedItem)
          showSuccessSnackBar('Vendedor cadastrado com sucesso!');
        } else {
          await ProjectSellerService.editProjectSellers(this.partnerSeller.id_doc, { ...this.partnerSeller, updateAt: new Date() })
          this.selectedItem = this.partnerSeller
          this.list = this.list.map((partner) => partner.id_doc === this.partnerSeller.id_doc ? this.partnerSeller : partner)
          showSuccessSnackBar('Vendedor editado com sucesso!');
        }
        this.$emit('id', this.selectedItem.id_doc)
      } catch (error) {
        console.error(error)
        showErrorSnackBar(error.message || "Aconteceu um erro inesperado, contacte o suporte!")
      } finally {
        if (this.canProceed) {
          this.partnerSeller = null
          this.openModal = false
          this.loading.saveButton = false
        }
      }


    },
    addNewType(e) {
      this.partnerSeller = {}
      this.buttonConfirmSaveOrEdit = "Salvar"
      this.openModal = true
    },
    editProjectSeller(item) {
      this.buttonConfirmSaveOrEdit = "Editar"
      this.partnerSeller = { ...item }
      this.openModal = true
    }
  },

  watch: {
    id_project_seller(newValue) {
      this.selectedItemProp = newValue
      if (this.list.length) this.selectedItem = this.list.find(({ id_doc }) => id_doc === newValue)
    },
  },

  async created() {
    if (this.getCurrentUserRank === "Franqueado") {
      this.franchiseeSelected = this.getCurrentUser.uid
      this.list = await ProjectSellerService.getProjectSellerByIdFranchisee(this.getCurrentUser.uid)
    } else if (this.getCurrentUserRank === "Super Admin") {
      UsersService.getUsersByRank(2)
        .then((res) => {
          this.listFranchisees = res
        }).catch(() => { })
      this.list = await ProjectSellerService.getProjectSeller()
    }
    if (this.selectedItemProp) this.selectedItem = this.list.find(({ id_doc }) => id_doc === this.selectedItemProp)
    this.isLoadingData = false
  }
};
</script>


<style></style>