import firebase from "@/firebase";
import dayjs from 'dayjs';




const TIMESTAMP_HOUR = 3600;
const TIMESTAMP_DAY = 24 * TIMESTAMP_HOUR;
const TIMESTAMP_YEAR = 365 * TIMESTAMP_DAY

const MONTHS_NAME = [
    {
        full: "Janeiro",
        short: "Jan"
    },
    {
        full: "Fevereiro",
        short: "Fev"
    },
    {
        full: "Março",
        short: "Mar"
    },
    {
        full: "Abril",
        short: "Abr"
    },
    {
        full: "Maio",
        short: "Mai"
    },
    {
        full: "Junho",
        short: "Jun"
    },
    {
        full: "Julho",
        short: "Jul"
    },
    {
        full: "Agosto",
        short: "Ago"
    },
    {
        full: "Setembro",
        short: "Set"
    },
    {
        full: "Outubro",
        short: "Out"
    },
    {
        full: "Novembro",
        short: "Nov"
    },
    {
        full: "Dezembro",
        short: "Dez"
    },
]

const sleep = ms => new Promise(r => setTimeout(r, ms));

function debounce(func, wait, immediate) {
    var timeout;

    return function executedFunction() {
        var context = this;
        var args = arguments;

        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };

        var callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) func.apply(context, args);
    };
};

const isAsync = (fn) => {
    return fn.constructor.name === 'AsyncFunction';
}

const clearAccents = (msg) => {
    return msg.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

const defaultLower = (msg) => {
    return msg.toLowerCase();
}

const defaultUpper = (msg) => {
    return msg.toUpperCase();
}

const prepareStringToSearch = (msg) => {
    return defaultSpace(defaultLower(clearAccents(msg))).split(' ')
}

const chunkArray = (array, chunkSize = 10) => {
    let chunkArray = []
    for (let i = 0; i < array.length; i += chunkSize) {
        const chunk = array.slice(i, i + chunkSize);
        chunkArray.push(chunk)
    }
    return chunkArray;
}

// const removeDuplicates = (array) => {
//     return array.filter((value, index) => array.indexOf(value) === index)
// }

const removeDuplicates = (array) => {
    return array.filter((value, index) => array.indexOf(value) === index)
}

// keys = array de chaves dentro do objeto
// arr = array de objetos
const removeDuplicatesCustom = (arr, keys) => {
    return arr.filter((v, i, a) => a.findIndex(v2 => keys.every(k => v2[k] === v[k])) === i)
}

const createSlug = (name) => {
    if (typeof name !== 'string') return null;
    return name
        .replace(
            /[\(\)\[\]_\+\-\/\\\-\+\.\º]+/g, " "
        )
        .replace(/(^\s+|\s+$)/g, "")
        .replace(/ d((a|e|i|o|u)|(as|es|is|os|us)) /gi, "")
        .replace(/(\s+)/g, "_")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
}

const capitalizeString = (string, isClearAccents = true) => {
    let defaultString = defaultSpace(defaultLower(string))
    if (isClearAccents) defaultString = clearAccents(defaultString)
    return defaultString.charAt(0).toUpperCase() + defaultString.slice(1);
}

function formatCurrency(value, fractionDigits = 2) {
    if (!value) value = 0;
    // return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', maximumFractionDigits: fractionDigits }).format(value);
    let format_value = formatNumber(value, fractionDigits)
    if (value >= 0) {
        format_value = `R$ ${format_value}`
    } else {
        format_value = `R$ -${format_value}`
    }
    return format_value;
}

function formatNumber(value, fractionDigits = 2) {
    if (!value) value = 0;
    let number = new Intl.NumberFormat('pt-BR', { maximumFractionDigits: fractionDigits }).format(value);
    let match_decimal = number.toString().match(/(?<=,)\d+$/g)
    let decimal_value = match_decimal !== null ? match_decimal[0] : 0;
    let decimal_string = decimal_value.toString();
    for (let i = 0; i < fractionDigits; i++) {
        decimal_string = `${decimal_string}0`
    }
    decimal_string = decimal_string.slice(0, fractionDigits)
    number = `${number.toString().replace(/,\d+$/, "")},${decimal_string}`
    return number;
}

function loop() {
    var [fn, time] = [...arguments];
    let wf = setInterval(
        () => {
            fn();
        },
        time * 1000
    );
    return wf;
}

function interval() {
    var [fn, condition, cycle, time] = [...arguments];
    // var timeParams = arguments[2];


    var cycle = !cycle ? 120 : cycle;
    var time = !time ? 1 : time;

    let wf = setInterval(
        async () => {
            if (cycle <= 0) return clearInterval(wf)
            await fn();
            let status = condition();
            if (status) return clearInterval(wf)
            cycle--;
        },
        time * 1000
    );
};

function waitingCondition() {
    var [functionTrigger, functionCondition, functionClose, functionFail, time, timeExecution] = [...arguments];
    var functionClose = !functionClose ? () => { } : functionClose;
    var functionFail = !functionFail ? () => { } : functionFail;
    var time = !time ? 0.2 : time;
    var timeExecution = !timeExecution ? 300 : timeExecution;
    var cycle = Math.ceil(timeExecution / time);
    functionTrigger();
    let wf = setInterval(
        async () => {
            if (cycle <= 0) {
                functionFail();
                return clearInterval(wf)
            }
            let status = await functionCondition();
            if (status) {
                functionClose();
                return clearInterval(wf)
            }
            cycle--;
        },
        time * 1000
    );
}

function waiting() {
    var [functionTrigger, functionCondition, functionClose, functionFail, time, timeExecution] = [...arguments];
    var functionClose = !functionClose ? () => { } : functionClose;
    var functionFail = !functionFail ? () => { } : functionFail;
    var time = !time ? 0.2 : time;
    var timeExecution = !timeExecution ? 300 : timeExecution;
    var cycle = Math.ceil(timeExecution / time);
    functionTrigger();

    return new Promise(
        (resolve) => {
            let wf = setInterval(
                () => {
                    if (cycle <= 0) {
                        functionFail();
                        resolve(false)
                        return clearInterval(wf)
                    }
                    let status = functionCondition();
                    if (status) {
                        functionClose();
                        resolve(true)
                        return clearInterval(wf)
                    }
                    cycle--;
                },
                time * 1000
            );
        }
    )
}

/**
 * A função checa uma condicional e espera até ela ser executada ou não
 * @param {Function} functionCondition É a função onde será testado a condicional
 * @param {Function} functionStart É a função que será executada após a condição for satisfeita
 * @param {Function} functionFail É a função que será executada após a condição passar do tempo esperado
 * @param {Number} time Indica o periodo de tempo em segundos que loop será executado
 * @param {Number} timeExecution Indica o tempo máximo de tempo em segundos que loop será executado
 */
function waitingTrigger() {
    var [functionCondition, functionStart, functionFail, time, timeExecution] = [...arguments];
    var functionStart = !functionStart ? () => { } : functionStart;
    var functionFail = !functionFail ? () => { } : functionFail;
    var time = !time ? 0.2 : time;
    var timeExecution = !timeExecution ? 300 : timeExecution;
    var cycle = Math.ceil(timeExecution / time);
    waitingCondition(
        () => { },
        functionCondition,
        functionStart,
        functionFail,
        time,
        timeExecution
    );
}

const defaultSpace = (name) => {
    return name
        .replace(/(^\s+|\s+$)/g, "")
        .replace(/(\s+)/g, " ");
}

const copyObject = (object) => {
    return JSON.parse(JSON.stringify(object))
    // if (typeof object !== 'object' || object === null || Array.isArray(object)) return object;
    // let objectToCopy = {};
    // Object.keys(object).forEach(
    //     (key) => {
    //         objectToCopy[key] = copyObject(object[key]);
    //     }
    // );
    // return objectToCopy;
};

const mergeObject = (toObject, fromObject) => {
    let newObject = {};
    Object.keys(toObject).forEach(key => newObject[key] = toObject[key]);
    Object.keys(fromObject).forEach(
        (key) => {
            if (
                fromObject[key] === undefined
                ||
                fromObject[key] === null
            ) return;
            newObject[key] = fromObject[key];
        }
    );
    return newObject;
}

const parseDateFormat = (dateInput) => {
    if (!dateInput) return null;
    let dateFormat = dateInput.toString();
    if (dateFormat.match(/^\d{4}\-\d{2}\-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z$/)) { // ISO String
        return dateFormat;
    } else if (dateFormat.match(/^\d{4}\-\d{2}\-\d{2}T\d{2}:\d{2}:\d{2}\-\d{2}:\d{2}$/)) {
        return dateFormat;
    } else if (dateFormat.match(/^today$/i)) {
        let date = new Date();
        dateFormat = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    } else if (dateFormat.match(/^now$/i)) {
        dateFormat = Date.now().toString();
    }

    if (dateFormat.match(/^\d{4}\-\d{2}\-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}(\+|\-)\d{2}:\d{2}$/)) {
        return dateFormat;
    } else if (dateFormat.match(/^[0-9]{13}$/)) {
        return parseFloat(dateFormat);
    } else if (dateFormat.match(/^[0-9]{10}$/)) {
        return parseFloat(`${dateFormat}000`);
    } else {
        let listRegexMatches = [
            /^([0-9]{4})$/,
            /^([0-9]{4})-([0-9]{1,2})$/,
            /^([0-9]{4})-([0-9]{1,2})-([0-9]{1,2})$/,
            /^([0-9]{4})-([0-9]{1,2})-([0-9]{1,2}) ([0-9]{1,2})$/,
            /^([0-9]{4})-([0-9]{1,2})-([0-9]{1,2}) ([0-9]{1,2}):([0-9]{1,2})$/,
            /^([0-9]{4})-([0-9]{1,2})-([0-9]{1,2}) ([0-9]{1,2}):([0-9]{1,2}):([0-9]{1,2})$/,
        ];
        let match = null;
        let partOfDate = [];
        const indexYear = 0;
        let status = listRegexMatches.some(
            (regex, indexRegex) => {
                match = dateFormat.match(regex);
                if (match) {
                    partOfDate = [...match];
                    partOfDate = partOfDate.map((v, i) => i === indexYear ? v : v.length > 1 ? v : `0${v}`);
                    if (indexRegex === 0) {
                        partOfDate = [...partOfDate, "01", "01", "00", "00", "00"];
                    } else if (indexRegex === 1) {
                        partOfDate = [...partOfDate, "01", "00", "00", "00"];
                    } else {
                        let diff = 7 - partOfDate.length;
                        while (diff > 0) {
                            partOfDate.push("00");
                            diff--;
                        }
                    }
                    return true;
                }
                return false;
            }
        )
        if (!status) throw new Error("Format date invalid to parse");
        return `${partOfDate[1]}-${partOfDate[2]}-${partOfDate[3]} ${partOfDate[4]}:${partOfDate[5]}:${partOfDate[6]}`;
    }
}

const instanceDate = (date = 'today') => {
    try {
        if (date instanceof Date) return date;
        let parseDate = parseDateFormat(date);
        if (!parseDate) return null;
        return new Date(parseDate);
    } catch (e) {
        throw new Error(e)
    }
}

const getStartDay = (date = 'today') => { // ok
    return instanceDate(date);
}

const getStartYear = (date = 'today') => { // ok
    let dateInfo = getDateInfo(date)
    return instanceDate(`${dateInfo['year']}`)
}

const getStarMonth = (date = 'today') => { // ok
    let dateInfo = getDateInfo(date)
    return instanceDate(`${dateInfo['year']}-${dateInfo['month']}`)
}

const getDateInfo = (date = 'now', isDefaultFormat = true) => {
    let dateObject = instanceDate(date);
    let day = dateObject.getDate();
    let month = dateObject.getMonth() + 1;
    let year = dateObject.getFullYear();
    let hours = dateObject.getHours();
    let minutes = dateObject.getMinutes();
    let seconds = dateObject.getSeconds();
    let timestamp = Math.floor(dateObject.getTime() / 1000);
    let fullMonthName = MONTHS_NAME[month - 1] ? MONTHS_NAME[month - 1].full : ""
    let shortMonthName = MONTHS_NAME[month - 1] ? MONTHS_NAME[month - 1].short : ""
    if (isDefaultFormat) {
        day = day < 10 ? `0${day}` : day.toString();
        month = month < 10 ? `0${month}` : month.toString();
        year = year.toString();
        hours = hours < 10 ? `0${hours}` : hours.toString();
        minutes = minutes < 10 ? `0${minutes}` : minutes.toString();
        seconds = seconds < 10 ? `0${seconds}` : seconds.toString();
    }
    return {
        day,
        month,
        year,
        hours,
        minutes,
        seconds,
        timestamp,
        fullMonthName,
        shortMonthName,
    };
}

const getFirstDayOfMonth = (dateInput) => { // ok
    let dateObject = instanceDate(dateInput);
    let dateInfo = getDateInfo(dateObject);
    return instanceDate(`${dateInfo.year}-${dateInfo.month}`);
}

const getHourOclock = (dateInput) => {
    let dateObject = instanceDate(dateInput);
    let dateInfo = getDateInfo(dateObject);
    return instanceDate(`${dateInfo.year}-${dateInfo.month}-${dateInfo.day} ${dateInfo.hours}:00:00`);
}

const getLastDateOfDay = (dateInput) => {
    let dateObject = instanceDate(dateInput);
    let dateInfo = getDateInfo(dateObject);
    return instanceDate(`${dateInfo.year}-${dateInfo.month}-${dateInfo.day} 23:59:59`);
}

const getLastDayOfMonth = (dateInput, isEndOfTheDay = false) => {
    let dateObject = instanceDate(dateInput);
    let dateInfo = getDateInfo(dateObject, false);
    let month = dateInfo.month;
    let year = dateInfo.year;
    let nextMonth = (month + 1) > 12 ? 1 : month + 1;
    let nextYear = nextMonth !== 1 ? year : year + 1;
    let firstDayNextMonth = getFirstDayOfMonth(`${nextYear}-${nextMonth}`);
    dateInfo = getDateInfo(firstDayNextMonth, false);
    return instanceDate(dateInfo.timestamp - (!isEndOfTheDay ? TIMESTAMP_DAY : 1));
}

/**
 * Calcula qual é o mês anterior a partir de uma data de referencia
 * @param {String|Date} dateInput Indica uma data de referencia
 * @param {Boolean} isEndOfTheMonth Indica qual será o dia retornado o inicio ou o final do mês
 * @return {Date} É a data inicial ou final do mês correpondente
 */
const previousMonthFrom = (dateInput, isEndOfTheMonth = false) => {
    let dateObject = instanceDate(dateInput);
    let dateInfo = getDateInfo(dateObject, false);
    let month = dateInfo.month;
    let year = dateInfo.year;
    let previousMonth = (month - 1) < 1 ? 12 : month - 1;
    let previousYear = previousMonth == 12 ? year - 1 : year;
    let dateString = `${previousYear}-${previousMonth}`;
    return !isEndOfTheMonth ? instanceDate(dateString) : getLastDayOfMonth(dateString, true);
}

const nextMonthFrom = (dateInput, isEndOfTheMonth = false) => {
    let dateObject = instanceDate(dateInput);
    let dateInfo = getDateInfo(dateObject, false);
    let month = dateInfo.month;
    let year = dateInfo.year;
    let nextMonth = (month + 1) > 12 ? 1 : month + 1;
    let nextYear = nextMonth == 1 ? year + 1 : year;
    let dateString = `${nextYear}-${nextMonth}`;
    return !isEndOfTheMonth ? instanceDate(dateString) : getLastDayOfMonth(dateString, true);
}

const getDiffDays = (startDay, endDay) => {
    let startDate = getStartDay(startDay);
    let startDateInfo = getDateInfo(startDate);
    let endDate = getStartDay(endDay);
    let endDateInfo = getDateInfo(endDate);

    let diffTimezone = (endDate.getTimezoneOffset() - startDate.getTimezoneOffset()) * 60;
    let diffTimestamp = (endDateInfo.timestamp - startDateInfo.timestamp + 1) - diffTimezone;
    if (diffTimestamp < 0) throw new Error("The start day is after the end");
    return Math.floor(diffTimestamp / TIMESTAMP_DAY);
}

const getCurrentMonth = () => {
    let date = instanceDate('today');
    let month = date.getMonth() + 1;
    return month < 10 ? `0${month}` : month.toString();
}

const getCurrentYear = () => {
    let date = instanceDate('today');
    return date.getFullYear().toString();
}

const nextDayFrom = (date = 'now') => {
    let dateObject = instanceDate(date);
    return instanceDate(getDateInfo(dateObject).timestamp + TIMESTAMP_DAY);
}

const previousDayFrom = (date = 'now') => {
    let dateObject = instanceDate(date);
    return instanceDate(getDateInfo(dateObject).timestamp - TIMESTAMP_DAY);
}

const nextHourFrom = (date = 'now') => {
    let dateObject = instanceDate(date);
    return instanceDate(getDateInfo(dateObject).timestamp + TIMESTAMP_HOUR);
}

const previousHourFrom = (date = 'now') => {
    let dateObject = instanceDate(date);
    return instanceDate(getDateInfo(dateObject).timestamp - TIMESTAMP_HOUR);
}

const nextYearFrom = (date = 'now') => {
    let dateObject = instanceDate(date);
    let infoDate = getDateInfo(dateObject)
    let year = parseInt(infoDate['year'])
    let nextYear = year + 1
    return instanceDate(`${nextYear}`);
}

const previousYearFrom = (date = 'now') => {
    let dateObject = instanceDate(date);
    let infoDate = getDateInfo(dateObject)
    let year = parseInt(infoDate['year'])
    let nextYear = year - 1
    return instanceDate(`${nextYear}`);
}

const getFirstDayCurrentYear = () => {
    let year = getCurrentYear();
    return instanceDate(`${year}-01`)
}

const add_day = (date = 'now', days = 1) => {
    let dateObject = instanceDate(date);
    return instanceDate(getDateInfo(dateObject).timestamp + (days * 24 * 3600));
}

const add_hour = (date = 'now', hours = 1) => {
    let dateObject = instanceDate(date);
    return instanceDate(getDateInfo(dateObject).timestamp + (hours * 3600));
}

const add_second = (date = 'now', seconds = 1) => {
    let dateObject = instanceDate(date);
    return instanceDate(getDateInfo(dateObject).timestamp + seconds);
}

const diff_days = (date_reference, date_compare) => {
    date_reference = instanceDate(date_reference)
    let date_reference_ts = getDateInfo(date_reference)['timestamp']
    date_compare = instanceDate(date_compare)
    let date_compare_ts = getDateInfo(date_compare)['timestamp']
    let diff_ts = date_reference_ts - date_compare_ts
    let diff_days = diff_ts / (24 * 3600)
    return diff_days;
}

const getMidnightToday = () => {
    return getMidnight('now')
}

const getDay = (dateInput, isEndOfTheMonth = false) => {
    let date_info = getDateInfo(dateInput);
    date_string = `${date_info['year']}-${date_info['month']}-${date_info['day']}`
    if (isEndOfTheMonth) {
        date_string += ` 23:59:59`;
    }
    return instanceDate(date_string)
}

const getLastDayOfLastMonth = (isEndOfTheDay = false) => {
    let dateObject = previousMonthFrom('now');
    let dateInfo = getDateInfo(dateObject, false);
    let month = dateInfo.month;
    let year = dateInfo.year;
    let nextMonth = (month + 1) > 12 ? 1 : month + 1;
    let nextYear = nextMonth !== 1 ? year : year + 1;
    let firstDayNextMonth = getFirstDayOfMonth(`${nextYear}-${nextMonth}`);
    dateInfo = getDateInfo(firstDayNextMonth, false);
    return instanceDate(dateInfo.timestamp - (!isEndOfTheDay ? TIMESTAMP_DAY : 1));
}

const generateDatesByInterval = (startDay, endDay) => {
    let startDayObject = getStartDay(startDay);
    let endDayObject = getStartDay(endDay);
    let currentDay = startDayObject;
    let intervalDates = [];
    while (currentDay <= endDayObject) {
        intervalDates.push(currentDay);
        currentDay = nextDayFrom(currentDay);
    }
    return intervalDates;
}

const generateYearsByInterval = (startYear, endYear) => {
    let intervalYears = []
    let startYearObject = getStartYear(startYear)
    let endYearObject = getStartDay(endYear);
    let currentYear = startYearObject;
    while (currentYear <= endYearObject) {
        intervalYears.push(currentYear);
        currentYear = nextYearFrom(currentYear);
    }
    return intervalYears;
}

const generateMonthsByInterval = (startMonth, endMonth) => {
    let startMonthObject = getFirstDayOfMonth(startMonth)
    let endMonthObject = getFirstDayOfMonth(endMonth)
    let intervalMonths = [];
    let currentMonth = startMonthObject;
    while (currentMonth <= endMonthObject) {
        intervalMonths.push(currentMonth);
        currentMonth = nextMonthFrom(currentMonth);
    }
    return intervalMonths;
}

const generateDaysByInterval = (startDay, endDay) => {
    let startDayObject = getMidnight(startDay)
    let endDayObject = getMidnight(endDay)
    let intervalDays = [];
    let currentDay = startDayObject;
    while (currentDay <= endDayObject) {
        intervalDays.push(currentDay);
        currentDay = nextDayFrom(currentDay);
    }
    return intervalDays;
}

const generateHoursByInterval = (startHour, endHour) => {
    let startHourObject = getHourOclock(startHour)
    let endHourObject = getHourOclock(endHour)
    let intervalHours = [];
    let currentHour = startHourObject;
    while (currentHour <= endHourObject) {
        intervalHours.push(currentHour);
        currentHour = nextHourFrom(currentHour);
    }
    return intervalHours;
}

const getIntervalDayByLatestMonth = (latest_month = 1) => {
    let now = instanceDate('now');
    let first_day = previousMonthFrom(now)
    let last_day = previousMonthFrom(now, true)
    latest_month -= 1;
    while (latest_month > 0) {
        first_day = previousMonthFrom(first_day)
        latest_month--;
    }
    return {
        "first": first_day,
        "last": last_day,
    }
}

const generateMonthsCurrentYear = () => {
    let currentYear = getCurrentYear();
    let listDates = []
    for (let month = 1; month <= 12; month++) {
        let dateInput = `${currentYear}-${month}-01`;
        let dateObject = instanceDate(dateInput);
        listDates.push(dateObject)
    }
    return listDates;
}

const getMidnight = (date_ref) => {
    let dateObject = instanceDate(date_ref);
    let info = getDateInfo(dateObject, false)
    return instanceDate(`${info['year']}-${info['month']}-${info['day']}`)
}

const generateLastDays = (total_days = 7) => {
    let dateObject = getMidnight(instanceDate('now'));
    let dates = []
    for (let last_day = total_days; last_day > 0; last_day--) {
        dateObject = previousDayFrom(dateObject)
        dates.push(dateObject)
    }
    return dates.reverse();
}

const getDateInfoFromList = (dates) => {
    if (!Array.isArray(dates)) throw new Error("Excepted array of dates");
    return dates.map(date => getDateInfo(date));
}
const subtractDate = (dateInput, subtractNumber, format = 'day') => {
    return dayjs(dateInput).subtract(subtractNumber, format).toDate();
}
const formatDateDayJs = (dateInput, format = 'dd/MM') => {
    if (!dateInput) return null;
    return dayjs(dateInput).format(format).toString()

}
const formatDate = (dateInput, format = '<dd>/<MM>') => {
    if (dateInput === null || dateInput === undefined) return null;
    let infoDate = getDateInfo(dateInput);
    format = format.replace(/(.*)<DD>(.*)/i, `$1${infoDate.day}$2`);
    format = format.replace(/(.*)<MM>(.*)/, `$1${infoDate.month}$2`);
    format = format.replace(/(.*)<YYYY>(.*)/i, `$1${infoDate.year}$2`);
    format = format.replace(/(.*)<hh>(.*)/i, `$1${infoDate.hours}$2`);
    format = format.replace(/(.*)<mm>(.*)/, `$1${infoDate.minutes}$2`);
    format = format.replace(/(.*)<ss>(.*)/i, `$1${infoDate.seconds}$2`);
    return format;
}
const formatMonthDate = (dateInput, format = '<MM>/<YY>', isFullMonthName = false) => {
    if (dateInput === null || dateInput === undefined) return null;
    let infoDate = getDateInfo(dateInput);
    let monthName = isFullMonthName ? infoDate.fullMonthName : infoDate.shortMonthName;
    format = format.replace(/(.*)<MM>(.*)/, `$1${monthName}$2`);
    format = format.replace(/(.*)<YY>(.*)/i, `$1${infoDate.year.toString().slice(2)}$2`);
    format = format.replace(/(.*)<YYYY>(.*)/i, `$1${infoDate.year}$2`);
    return format;
}

const getStringRank = (rankNumber, isSlug = false) => {
    if (!rankNumber.toString().match(/^[0-9]+$/)) throw new Error("Rank must be a number");
    let stringRank = null;
    switch (parseFloat(rankNumber)) {
        case 0:
            stringRank = "Super Admin";
            break;
        case 1:
            stringRank = "Admin";
            break;
        case 2:
            stringRank = "Franqueado";
            break;
        case 3:
            stringRank = "Técnico";
            break;
        case 4:
            stringRank = "Cliente";
            break;
        case 5:
            stringRank = "Associado";
            break;
        default:
            throw new Error(`Rank number '${rankNumber}' unidentified`);
    }
    return !isSlug ? stringRank : createSlug(stringRank);
}

const getNumberRank = (rankString) => {
    let slugRank = createSlug(rankString);
    switch (slugRank) {
        case 'super_admin':
            return 0;
            break;
        case 'admin':
            return 1;
            break;
        case 'franqueado':
            return 2;
            break;
        case 'tecnico':
            return 3;
            break;
        case 'cliente':
            return 4;
            break;
        case 'associado':
            return 5;
            break;
        default:
            throw new Error(`Rank string '${rankString}' unidentified`);
    }
}

const is_equal_array = (arr1, arr2) => {
    if (arr1 == null || arr2 == null) return false;
    if (arr1.length !== arr2.length) return false;
    let arr1_indexes = []
    let arr2_indexes = []
    for (let i = 0; i < arr1.length; i++) {
        arr1_indexes.push(i)
        let index = arr2.findIndex(r => r == arr1[i])
        if (index >= 0 && !arr2_indexes.includes(index)) {
            arr2_indexes.push(index)
        }
    }
    return arr1_indexes.length === arr2_indexes.length;
}

const getList = async (user, requestRank) => {
    let list = []
    if (user.rank === requestRank) return user
    switch (user.rank) {
        case "Super Admin":

            list = await firebase.getUsers_sr(undefined, undefined, undefined, 2);
            return list
            break;
        case "Admin":
            list = await firebase.getUsers_sr(undefined, undefined, undefined, 2);

            return list
            break;
        case "Franqueado":

            return user
            break;
        case "Cliente":

            if (requestRank == 'Cliente') return user
            if (requestRank == 'Associado') return 'Downgrade of user dont possible!'
            return await firebase.getUserbyId(user.manager);
            break;
        case "Associado":

            const client = await firebase.getUserbyId(user.client);
            if (requestRank == 'Cliente') return client
            else if (requestRank == 'Associado') return user
            else if (requestRank == 'Franqueado') return await getList(client)
            else if (requestRank == 'units') {
                let list = []
                for (let unit of user.unitsAllowedByClient) {
                    list.push({ data: await firebase.getUnitbyId(unit), id: unit })
                }
                return list
            }
            break;
        default:
            break;
    }
}
const get_last_months = (total_months = 6) => {
    let dateObject = instanceDate('today');
    let info = getDateInfo(dateObject, false)
    // let total = 6;
    let current_month = info.month
    let current_year = info.year
    let dates = [instanceDate(`${info.year}-${info.month}-01`)]
    for (let i = 0; i < total_months; i++) {
        current_month = current_month - 1
        if (current_month < 1) {
            current_month = 12
            current_year = current_year - 1
        }
        dates.push(instanceDate(`${current_year}-${current_month}-01`))
    }
    return dates.reverse();
}
const checkRank = async (select_step, userUID) => {
    const user = await firebase.getUserbyId(userUID);
    let manager = []
    let franchisee = []
    let associeted = []
    let units = []
    let client = []
    switch (select_step) {
        case 'franchisee': // Descobrir quem é o franqueado do usuario
            franchisee = await getList(user, 'Franqueado')
            return franchisee
            break;
        case 'client': // Descobrir quem é o cliente do usuario
            client = await getList(user, 'Cliente')
            return client
            break;
        case 'associated': // Descobrir quem é o associado do usuario
            associeted = await getList(user, 'Associado')
            return associeted
            break;
        case 'units': // Descobrir as unidades do usuario
            units = await getList(user, 'units')
            return units
            break;
    }

}

const sortArray = (arr, order = 'asc') => {
    return order == 'asc' ? arr.sort((paramPrevious, paramNext) => {
        if (paramPrevious < paramNext) return -1;
        else if (paramPrevious > paramNext) return 1;
        else return 0;
    }) : arr.sort((paramPrevious, paramNext) => {
        if (paramPrevious < paramNext) return 1;
        else if (paramPrevious > paramNext) return -1;
        else return 0;
    })
}

const getEnv = () => {
    switch (window.location.hostname) {
        case '127.0.0.1':
        case 'localhost':
            return 'local'
        case 'centralfoz-homolog.web.app':
            return 'homolog'
        default:
            return 'prod'
    }
}

const generate_list_monthly_by_day_fixed = (start_date, limit = 12) => {
    let start_info = getDateInfo(start_date, false)
    let day = start_info['day']
    let month = start_info['month']
    let year = start_info['year']
    let day_fixed = day;
    let list_dates = []
    while (limit > 0) {
        day = day_fixed
        while (true) {
            try {
                list_dates.push(instanceDate(`${year}-${month}-${day}`))
                break;
            } catch (e) {
                day -= 1
                continue;
            }
        }
        if (month == 12) {
            month = 1
            year += 1;
        } else {
            month += 1;
        }
        limit -= 1;
    }
    return list_dates;
}

/**
 * Operações com arrays
 * https://stackoverflow.com/questions/1187518/how-to-get-the-difference-between-two-arrays-in-javascript
 */

const intersection_array = (arr1, arr2) => {
    arr1.filter(x => arr2.includes(x));
}

const difference_array = (arr1, arr2) => {
    return arr1.filter(x => !arr2.includes(x));
}

const symmetric_difference_array = (arr1, arr2) => {
    return arr1.filter(x => !arr2.includes(x)).concat(arr2.filter(x => !arr1.includes(x)));
}

// Seta as chaves do objeto
const setting = (data, values) => {
    Object.keys(values).forEach(
        (keysString) => {
            let keys = keysString.split('.')
            let lastKey = keys.pop();
            let ref = data;
            keys.forEach(
                (key) => {
                    if (!ref.hasOwnProperty(key)) {
                        ref[key] = {}
                    } else {
                        if (Array.isArray(ref[key])) {
                            ref[key] = {}
                        } else if (!(typeof ref[key] === 'object')) {
                            ref[key] = {}
                        }
                    }
                    ref = ref[key];
                }
            )
            ref[lastKey] = values[keysString]
        }
    )
}

const getting = (data, keysString) => {
    let keys = keysString.split('.')
    let lastKey = keys.pop();
    let ref = data;
    keys.forEach(
        (key) => {
            if (!ref.hasOwnProperty(key)) {
                throw new Error(`Não existe a chave: ${keysString}`)
            } else {
                if (Array.isArray(ref[key])) {
                    throw new Error(`Não existe a chave: ${keysString}`)
                } else if (!(typeof ref[key] === 'object')) {
                    throw new Error(`Não existe a chave: ${keysString}`)
                }
            }
            ref = ref[key];
        }
    )
    if (!ref.hasOwnProperty(lastKey)) {
        throw new Error(`Não existe a chave: ${keysString}`)
    }
    return ref[lastKey];
}

const camelCaseFormat = (name, isFirstLetterCapitalized = false) => {
    let splitted = name.split(/_+/g)
    splitted = splitted.map((str, index) => isFirstLetterCapitalized ? str.charAt(0).toUpperCase() + str.slice(1) : index === 0 ? str : str.charAt(0).toUpperCase() + str.slice(1))
    return splitted.join('')
}

const dataURLToBlob = (dataURL) => {
    return fetch(dataURL)
        .then(res => res.blob());
}

export default {
    chunkArray,
    removeDuplicates,
    formatDateDayJs,
    removeDuplicatesCustom,
    checkRank,
    debounce,
    get_last_months,
    waiting,
    sleep,
    isAsync,
    createSlug,
    capitalizeString,
    formatCurrency,
    formatNumber,
    defaultSpace,
    copyObject,
    mergeObject,
    getCurrentMonth,
    getCurrentYear,
    instanceDate,
    getStartDay,
    generateDatesByInterval,
    getDateInfo,
    nextDayFrom,
    generateMonthsCurrentYear,
    getDateInfoFromList,
    formatDate,
    getStringRank,
    getNumberRank,
    interval,
    waitingCondition,
    waitingTrigger,
    parseDateFormat,
    getFirstDayOfMonth,
    getLastDayOfMonth,
    getDiffDays,
    subtractDate,
    previousMonthFrom,
    generateMonthsByInterval,
    formatMonthDate,
    previousDayFrom,
    generateLastDays,
    getIntervalDayByLatestMonth,
    getFirstDayCurrentYear,
    getMidnight,
    getMidnightToday,
    getDay,
    getLastDayOfLastMonth,
    is_equal_array,
    clearAccents,
    defaultLower,
    defaultUpper,
    prepareStringToSearch,
    sortArray,
    getEnv,
    generate_list_monthly_by_day_fixed,
    add_day,
    add_second,
    diff_days,
    intersection_array,
    difference_array,
    symmetric_difference_array,
    generateDaysByInterval,
    generateHoursByInterval,
    getHourOclock,
    add_hour,
    getLastDateOfDay,
    nextHourFrom,
    previousHourFrom,
    generateYearsByInterval,
    getStartYear,
    getStarMonth,
    nextYearFrom,
    previousYearFrom,
    setting,
    getting,
    camelCaseFormat,
    dataURLToBlob,
}