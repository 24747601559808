<template>
  <div>
    <v-col>
      <v-row>
        <v-col cols="6" sm="2" style="background-color: #1976D250;
            min-width: 150px;
            margin-right: 10px;
            border-color: #1976D2;
            border-bottom: 5px solid #49A94D; 
            border-radius: 5px;">
          <span>Economia Total</span>
          <h2>{{ getTotalEconomy }}</h2>
        </v-col>
        <v-col cols="6" sm="2" style="background-color: #1976D250;
            min-width: 150px;
            margin-right: 10px;
            border-color: #1976D2;
            border-bottom: 5px solid #1976D2; 
            border-radius: 5px;">
          <span>Economia Total</span>
          <h2>M³ {{ getTotalEconomyM3 }}</h2>
        </v-col>
        <v-col cols="6" sm="2" style="background-color: #1976D250; 
            min-width: 150px;
            margin-right: 10px;
            border-color: #1976D2;
            border-bottom: 5px solid #49A94D; 
            border-radius: 5px;">
          <span>Referencia Atual</span>
          <h2>{{ getLastReference }}</h2>
        </v-col>
        <v-col cols="6" sm="2" style="background-color: #1976D250;
            min-width: 150px;
            margin-right: 10px;
            border-color: #1976D2;
            border-bottom: 5px solid #1976D2; 
            border-radius: 5px;">
          <span>Média Antes da Foz</span>
          <h2>M³ {{ getAverageBeforeFoz }}</h2>
        </v-col>
        <v-col cols="6" sm="2" style="background-color: #1976D250;
            min-width: 150px;
            margin-right: 10px;
            border-color: #1976D2;
            border-bottom: 5px solid #1976D2; 
            border-radius: 5px;">
          <span>Média Atual</span>
          <h2>M³ {{ getAverageAfterFoz }}</h2>
        </v-col>
      </v-row>
    </v-col>
    <v-divider></v-divider>
    <v-col>
      <v-row>
        <div
          style="display:flex;border-radius: 10px;padding: 15px;justify-content: space-evenly;align-items: center;height:80px;width:360px;background-color: dodgerblue;">
          <div>
            <span style="font-size: 16px;font-weight: bold;color: white;">Data de Instalação</span>
          </div>
          <div>
            <span style="font-size: 16px;font-weight: bold;color: gold;">{{ getInstalattionDate }}</span>
          </div>
        </div>
        <div style="display: flex;justify-content: center;align-items: center;max-width: 70px;margin-left: 10px;">
          <v-btn color="primary" class="mb-2" text
            @click="openDialogDate({ title: 'Escolha a data de instalação', type: 'month', input: instalattionDate, change: updateInstalattionDate })"
            :disabled="disabled" style="height: 100%;width: 100%;border-radius: 10px;margin: 0 !important;">
            <v-icon>add</v-icon>
          </v-btn>
        </div>
        <v-spacer></v-spacer>
        <div style="display: flex;justify-content: center;align-items: center;max-width: 70px;margin-left: 10px;">
          <v-btn color="primary" class="mb-2" @click="getAccountsCompesa()" :disabled="disabled"
            style="height: 100%;width: 100%;border-radius: 10px;margin: 0 !important;">
            Buscar <br /> Contas
          </v-btn>
        </div>
      </v-row>
    </v-col>

    <v-col>
      <v-data-table :headers="recordHeaders" :items="listHistorics" class="elevation-0 rounded-lg" :items-per-page="6"
        no-data-text="Sem leituras para exibir">

        <template v-slot:top>
          <v-toolbar flat class="rounded-lg">
            <v-toolbar-title>Histórico</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="mb-2" text @click="newHistoricForm" :disabled="disabled">
              <v-icon>add</v-icon>
            </v-btn>
          </v-toolbar>
        </template>

        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="(historico, index) in items" :key="index">
              <td style="display: flex">
                <div style="display: flex;justify-content: center;align-items: center;margin-right: 10px;width: 80px;">
                  <v-chip :color="getColorMonth(historico)">
                    {{ getTagMonth(historico) }}
                  </v-chip>
                </div>
                <div style="width: 85px;">
                  <v-text-field :value="formatDate(`${historico.month}-${historico.day}`, '<DD>/<MM>/<YYYY>')"
                    label="Data" type="text" single-line autofocus readonly></v-text-field>
                </div>
              </td>

              <td>
                <div style="width: 55px;">
                  <v-text-field :value="formatMonthDate(historico.competence, '<MM>/<YY>')" label="Data" type="text"
                    single-line autofocus readonly></v-text-field>
                </div>
              </td>

              <td>
                <v-text-field :value="formatNumber(historico.water_cubic_meters)" label="Água" suffix="m³" single-line
                  style="width: 100px;" readonly></v-text-field>
              </td>

              <td>
                <v-text-field :value="formatNumber(historico.sewer_cubic_meters)" label="Esgoto" suffix="m³" single-line
                  style="width: 100px;" readonly></v-text-field>
              </td>

              <td>
                <v-text-field :value="formatNumber(historico.consumption_cubic_meters)" label="Consumo Total"
                  suffix="m³" single-line style="width: 100px;" readonly></v-text-field>
              </td>

              <td>
                <v-text-field :value="formatNumber(historico.cost_consumption)" label="Custo Total" suffix="R$"
                  single-line style="width: 100px;" readonly></v-text-field>
              </td>

              <td>
                <v-text-field :value="formatNumber(historico.reference_economy)" label="Referência" suffix="R$"
                  single-line style="width: 110px;" readonly></v-text-field>
              </td>

              <td>
                <v-text-field :value="formatNumber(historico.financial_economy)" label="Referência" suffix="R$"
                  single-line style="width: 100px;" readonly></v-text-field>
              </td>

              <td>
                <v-text-field :value="historico.observation" label="Obs" single-line style="width: 100px;"
                  readonly></v-text-field>
              </td>

              <td>
                <v-btn elevation="2" @click="editHistoricForm(`${historico.month}-${historico.day}`)"
                  style="height: 30px;margin: 2px;" :disabled="disabled">
                  <v-icon color="primary" small>
                    mdi-pencil
                  </v-icon>
                </v-btn>

                <v-btn elevation="2" @click="removeHistoric(`${historico.month}-${historico.day}`)"
                  style="height: 30px;margin: 2px;" :disabled="disabled">
                  <v-icon color="primary" small>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-col>

    <v-dialog v-model="dialog" max-width="450">
      <v-card>
        <v-card-title class="text-h5">
          {{ titleForm }}
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="6">
              <historic-reading-date :instalattionDate="instalattionDate" :month="historicForm.month"
                :day="historicForm.day" :disabled="disabled" @change="adapterDate => changeReadingDate(adapterDate)" />
            </v-col>
            <v-col cols="6">
              <competence-month :competence="historicForm.competence" :listCompetencies="competencies"
                :disabled="disabled" @change="competence => changeCompetence(competence)" width="110" />
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="historicForm.water_cubic_meters" label="Água" type="number" suffix="m³"
                @input="updateConsumption()" :rules="rulesNumber(true, 0)" :disabled="disabled"
                style="width: 100px;"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="historicForm.sewer_cubic_meters" label="Esgoto" type="number" suffix="m³"
                @input="updateConsumption()" :rules="rulesNumber(true, 0)" :disabled="disabled"
                style="width: 100px;"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field :value="fixed(historicForm.consumption_cubic_meters)" label="Consumo Total" type="number"
                suffix="m³" :readonly="true" :disabled="disabled" style="width: 100px;"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="historicForm.reference_economy" label="Referência" type="number" suffix="R$"
                :rules="rulesNumber(true, 0)" @input="updateMonetaryConsumption()" :disabled="disabled"
                style="width: 110px;"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="historicForm.cost_consumption" label="Custo Total" type="number" suffix="R$"
                :rules="rulesNumber(true, 0)" @input="updateMonetaryConsumption()" :disabled="disabled"
                style="width: 110px;"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field :value="fixed(historicForm.financial_economy)" label="Economia" suffix="R$" :readonly="true"
                :disabled="disabled" style="width: 110px;"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="historicForm.observation" label="Observação"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            Cancelar
          </v-btn>

          <v-btn color="green darken-1" text @click="addHistoric(currentIndex)">
            {{ confirmForm }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row justify="center">
      <v-dialog v-model="historicsServiceDialog" v-if="historicsServiceDialog" persistent max-width="1050px">
        <v-card>
          <v-card-title>
            <span class="text-h5"> Historicos Encontrados</span>
          </v-card-title>
          <v-card-text>
            <v-divider />
            <v-row v-if="loadingSkeleton">
              <v-col v-for="n in 3" :key="n" cols="12" md="12">
                <skeleton-component style="height: 30px; border-radius: 15px"></skeleton-component>
              </v-col>
            </v-row>
            <div v-if="!loadingSkeleton">
              <tr>
                <th v-for="(headers, key) in recordHeadersHistoricsResponse" :key="key">
                  {{ headers.text }}
                </th>
              </tr>
              <tr v-for="(historic, key) in historicResponse" :key="key">
                <td style="display: flex">
                  <div
                    style="display: flex;justify-content: center;align-items: center;margin-right: 10px;width: 80px;">
                    <v-chip :color="getColorMonth(historic)">
                      {{ getTagMonth(historic) }}
                    </v-chip>
                  </div>
                  <div style="width: 85px;">
                    <v-text-field :value="formatDate(`${historic.month}-${historic.day}`, '<DD>/<MM>/<YYYY>')"
                      label="Data" type="text" single-line autofocus readonly></v-text-field>
                  </div>
                </td>

                <td>
                  <div style="width: 60px;">
                    <v-text-field v-model="historic.competence" label="Data" type="text" single-line autofocus
                      readonly></v-text-field>
                  </div>
                </td>

                <td>
                  <v-text-field v-model="historic.water_cubic_meters" label="Água" suffix="m³" single-line
                    style="width: 100px;" readonly></v-text-field>
                </td>

                <td>
                  <v-text-field v-model="historic.sewer_cubic_meters" label="Esgoto" suffix="m³" single-line
                    style="width: 100px;" readonly></v-text-field>
                </td>

                <td>
                  <v-text-field v-model="historic.consumption_cubic_meters" label="Consumo Total" suffix="m³"
                    single-line style="width: 100px;" readonly></v-text-field>
                </td>

                <td>
                  <v-text-field v-model="historic.cost_consumption" label="Custo Total" prefix="R$" single-line
                    style="width: 100px;" readonly></v-text-field>
                </td>

                <td>
                  <v-text-field v-model="historic.reference_economy" label="Referência" prefix="R$" single-line
                    style="width: 110px;" readonly></v-text-field>
                </td>

                <td>
                  <v-text-field v-model="historic.financial_economy" label="Referência" prefix="R$" single-line
                    style="width: 100px;" readonly></v-text-field>
                </td>
                <td>
                  <v-text-field v-model="historic.observation" label="Observação" prefix="Obs" single-line
                    style="width: 100px;" readonly></v-text-field>
                </td>
              </tr>
            </div>
            <v-divider />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="historicResponse.length === 0" color="blue darken-1" text
              @click="addHistoricResponseOnHistoricUnit()">
              Adicionar
            </v-btn>
            <v-btn color="blue darken-1" text @click="historicsServiceDialog = false">
              Fechar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ObjectValidator from '@/scripts/ObjectValidator';
import Utils from '@/scripts/Utils';
import Rules from "@/utils/Rules";
import { mapActions } from "vuex";

import CompetenceMonth from "@/pages/Units/Widgets/competence-month.vue";
import HistoricReadingDate from "@/pages/Units/Widgets/historic-reading-date.vue";
import SkeletonComponent from "@/components/Skeleton/Skeleton.vue";
import { showInfoSnackBar } from "@/utils/SnackBartHelper"
import axios from "axios";
const CONSTANTS = {
  recordHeaders: [
    { text: "Data", value: "month", sortable: false },
    { text: "Competência", value: "competence" },
    { text: "Água", value: "water_cubic_meters" },
    { text: "Esgoto", value: "sewer_cubic_meters" },
    { text: "Consumo Total", value: "consumption_cubic_meters" },
    { text: "Custo Total", value: "cost_consumption" },
    { text: "Referência", value: "reference_economy" },
    { text: "Economia", value: "financial_economy" },
    { text: "Observação", value: "observation" },

    { text: "Ações", value: "actions", sortable: false },
  ],
  recordHeadersHistoricsResponse: [
    { text: "Data", value: "month", sortable: false },
    { text: "Competência", value: "competence" },
    { text: "Água", value: "water_cubic_meters" },
    { text: "Esgoto", value: "sewer_cubic_meters" },
    { text: "Consumo Total", value: "consumption_cubic_meters" },
    { text: "Custo Total", value: "cost_consumption" },
    { text: "Referência", value: "reference_economy" },
    { text: "Economia", value: "financial_economy" },
    { text: "Observação", value: "observation" },

  ],
}

/**
 * Campo de leitura da data do histórico que tem como objetivo manter a estrutura
 * do histórico para não quebrar a compatibiidade
 * 
 * @params {Array} historics: é uma lista de históricos
 * @params {String} dataMediaHistorico: é mês de instalação foz no formato '<YYYY>-<MM>'
*/
export default {

  components: {
    HistoricReadingDate,
    CompetenceMonth,
    SkeletonComponent,
  },

  props: {
    historicos: {
      type: Array,
      required: false,
      default: () => [],
    },
    unit: {
      default: () => { },
    },
    dataMediaHistorico: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data: () => ({
    ...CONSTANTS,
    listHistorics: [],
    instalattionDate: null,
    observation: "",
    action: 'signup',
    currentIndex: null,
    titleForm: '',
    confirmForm: '',
    historicForm: ObjectValidator.create('historico'),
    competencies: [],
    dialog: false,
    historicResponse: [],
    historicsServiceDialog: false,
    loadingSkeleton: false,
  }),

  computed: {
    getInstalattionDate() {
      if (!this.instalattionDate) return "--/--";
      return Utils.formatMonthDate(this.instalattionDate, '<MM> de <YYYY>', true)
    },
    getTotalEconomy() {
      if (this.historicos.length) return Utils.formatCurrency(
            this.historicos
              .filter(historic => new Date(historic.month).getTime() >= new Date(this.instalattionDate).getTime() && historic.financial_economy > 0)
              .reduce((a, b) => a + b.financial_economy, 0)
              .toFixed(2)
          );
      return 0
    },
    getTotalEconomyM3() {
      if (this.historicos.length && this.getAverageBeforeFoz) {
        const consumptions = this.historicos.map((historic) => parseFloat(this.getAverageBeforeFoz) - parseFloat(historic.consumption_cubic_meters) > 0 ? parseFloat(this.getAverageBeforeFoz) - parseFloat(historic.consumption_cubic_meters) : 0)
        return consumptions.reduce((acc, current) => acc + current, 0).toFixed(2)
      }
      return 0
    },
    getTotalReference() {
      if (this.historicos.length) return this.historicos.reduce((a, b) => a + b.reference_economy, 0).toFixed(2)
      return 0
    },
    getLastReference() {
      if (this.historicos.length) {
        let teste = this.historicos.sort((a, b) => {
          return new Date(a.day + "-" + a.month).getTime() > new Date(b.day + "-" + b.month).getTime() ? -1 : new Date(a.day + "-" + a.month).getTime() < new Date(b.day + "-" + b.month).getTime() ? 1 : 0;
        })
        return Utils.formatCurrency(teste[0].reference_economy)
      }
      return 0
    },
    getAverageBeforeFoz() {
      if (this.historicos.length && this.instalattionDate) {
        let avaregeBefore = []
        this.historicos.forEach((historic) => {
          if (new Date(historic.month).getTime() < new Date(this.instalattionDate).getTime()) {
            avaregeBefore.push(historic.consumption_cubic_meters)
          }
        })
        return avaregeBefore.length ? (avaregeBefore.reduce((a, b) => a + b) / avaregeBefore.length).toFixed(2) : 0
      }
      return 0
    },
    getAverageAfterFoz() {
      if (this.historicos.length && this.instalattionDate) {
        let avaregeBefore = []
        this.historicos.forEach((historic) => {
          if (new Date(historic.month).getTime() >= new Date(this.instalattionDate).getTime()) {
            avaregeBefore.push(historic.consumption_cubic_meters)
          }
        })

        return avaregeBefore.length > 0 ?
          (avaregeBefore.reduce((a, b) => a + b, 0) / avaregeBefore.length).toFixed(2)
          :
          0
      }
      return 0
    },
    
  },

  methods: {
    ...mapActions([
      "openDialogDate",
      "resetDialogDate",
    ]),
    fixed(number) {
      if (number === "" || number === null || number === undefined) return ""
      return number.toFixed(2)
    },
    formatDate(dateInput, format = '<DD>/<MM>/<YYYY>') {
      if (!dateInput) return null;
      return Utils.formatDate(dateInput, format)
    },
    formatMonthDate(dateInput, format = '<MM>/<YY>', isFullMonthName = false) {
      if (!dateInput) return null;
      return Utils.formatMonthDate(dateInput, format, isFullMonthName)
    },
    formatNumber(value, fractionDigits = 2) {
      return Utils.formatNumber(value, fractionDigits)
    },
    formatCurrency(value, fractionDigits = 2) {
      return Utils.formatCurrency(value, fractionDigits)
    },

    emitChange() {
      const historics = this.listHistorics
      function getLastReadingDate() {
        let dates = historics.map(
          (historic) => {
            let day = historic['day']
            let month = historic['month']
            let date_format = `${month}-${day}T03:00:00Z`
            return new Date(date_format);
          }
        )
        if (dates.length) return dates.sort((datePrevious, dateNext) => datePrevious < dateNext ? 1 : -1)[0]
        return null
      }
      
      this.$emit('change', Utils.copyObject({
        historicos: this.listHistorics,
        dataMediaHistorico: this.instalattionDate,
        averageBeforeFoz: this.getAverageBeforeFoz,
        lastReadingDate: getLastReadingDate(),
      }))
    },
    async updateInstalattionDate(date) {
      this.instalattionDate = date
      this.emitChange();
    },

    newHistoricForm() {
      this.titleForm = "Cadastrar um histórico";
      this.confirmForm = "Inserir"
      this.historicForm = ObjectValidator.create('historico')
      this.currentIndex = null
      this.loadCompetencies();
      this.dialog = true;
    },
    editHistoricForm(date_ref) {
      this.titleForm = "Editar um histórico";
      this.confirmForm = "Salvar"

      let index = this.getHistoricIndexByDate(date_ref)
      this.historicForm = Utils.copyObject(this.listHistorics[index])


      this.currentIndex = index
      this.loadCompetencies(this.historicForm.competence)
      this.dialog = true;
    },
    removeHistoric(date_ref) {
      let index = this.getHistoricIndexByDate(date_ref)
      if (index < 0) throw new Error(`Não foi possível deletar o histórico por não encontrar o date '${date_ref}'`)
      let _listHistorics = Utils.copyObject(this.listHistorics)
      _listHistorics.splice(index, 1)
      this.listHistorics = _listHistorics
      this.loadCompetencies()
      this.emitChange();
    },
    getHistoricIndexByDate(date_ref) {
      let historic_index = this.listHistorics.findIndex(
        (h) => {
          return `${h.month}-${h.day}` === date_ref
        }
      )
      return historic_index
    },

    async addHistoric(index = null) {
      let _historic = Utils.copyObject(this.historicForm)
      let _listHistorics = Utils.copyObject(this.listHistorics)
      if (index === null) { // Cadastro
        _listHistorics.unshift(_historic);
      } else { // Edição
        _listHistorics[index] = _historic;
      }
      this.listHistorics = _listHistorics;

      this.dialog = false;
      this.emitChange();
    },

    getColorMonth(historico) {
      if (!this.instalattionDate) return "grey";
      let dateMonth = Utils.instanceDate(historico.month)
      let _instalattionDate = Utils.instanceDate(this.instalattionDate)
      if (dateMonth < _instalattionDate) return "red"
      else return "green"
    },
    getTagMonth(historico) {
      if (!this.instalattionDate) return "--";
      let dateMonth = Utils.instanceDate(historico.month)
      let _instalattionDate = Utils.instanceDate(this.instalattionDate)
      if (dateMonth < _instalattionDate) return "Antes";
      else return "Depois";
    },

    async changeReadingDate(adapterDate) {
      this.historicForm.month = adapterDate.month
      this.historicForm.day = adapterDate.day
    },

    loadCompetencies(date_remove = null) {
      this.competencies = this.listHistorics.map((h) => h.competence).filter((c) => c === null ? false : date_remove !== c)
    },
    async changeCompetence(competence) {
      this.historicForm.competence = competence
    },

    updateConsumption() {
      let total = 0;
      let water_cubic_meters = this.historicForm.water_cubic_meters !== "" && this.historicForm.water_cubic_meters !== null && this.historicForm.water_cubic_meters !== undefined && !isNaN(this.historicForm.water_cubic_meters) ? parseFloat(this.historicForm.water_cubic_meters) : 0
      let sewer_cubic_meters = this.historicForm.sewer_cubic_meters !== "" && this.historicForm.sewer_cubic_meters !== null && this.historicForm.sewer_cubic_meters !== undefined && !isNaN(this.historicForm.sewer_cubic_meters) ? parseFloat(this.historicForm.sewer_cubic_meters) : 0
      total = water_cubic_meters + sewer_cubic_meters
      this.historicForm.consumption_cubic_meters = total;
    },

    updateMonetaryConsumption() {
      let total = 0;
      let reference_economy = this.historicForm.reference_economy !== "" && this.historicForm.reference_economy !== null && this.historicForm.reference_economy !== undefined && !isNaN(this.historicForm.reference_economy) ? parseFloat(this.historicForm.reference_economy) : 0
      let cost_consumption = this.historicForm.cost_consumption !== "" && this.historicForm.cost_consumption !== null && this.historicForm.cost_consumption !== undefined && !isNaN(this.historicForm.cost_consumption) ? parseFloat(this.historicForm.cost_consumption) : 0
      total = reference_economy - cost_consumption
      this.historicForm.financial_economy = total
    },

    rulesNumber(isRequired = false, min = 1, max = null) {
      return Rules.number(isRequired, min, max);
    },
    rulesRequired() {
      let optionals = [];
      optionals.push(value => !!value || 'Requerido');
      return optionals
    },
    async getAccountsCompesa() {
      this.historicResponse = []
      try {
        if (!this.unit.dataMediaHistorico) throw "A unidade precisa ter uma data de Instalação definida!"
        this.historicsServiceDialog = true
        this.loadingSkeleton = true
        let msg = ""
        await axios.get(
          `https://simple-express-cu622gxaua-uc.a.run.app/v1/compesa/${this.unit.id}`)
          .then((res) => {
            this.historicResponse = res.data.data
            msg = res.data.message
          })
        if (msg != "Sucesso na busca das contas!") {
          this.historicsServiceDialog = false
          showInfoSnackBar(msg)
        }
        this.loadingSkeleton = false

      } catch (err) {
        console.error(err)
        showInfoSnackBar(err)
      }

    },
    async addHistoricResponseOnHistoricUnit() {
      let teste = [...this.listHistorics, ...this.historicResponse]
      this.listHistorics = teste.sort((a, b) => {
        const aDate = new Date(this.formatDate(`${a.month}-${a.day}`, '<MM>/<DD>/<YYYY>')).getTime()
        const bDate = new Date(this.formatDate(`${b.month}-${b.day}`, '<MM>/<DD>/<YYYY>')).getTime()
        return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
      })
      this.emitChange();
      this.historicsServiceDialog = false
    },
  },

  watch: {
    dataMediaHistorico: {
      immediate: true,
      handler: function (newValue) {
        this.instalattionDate = newValue
      },
    },

    historicos: {
      immediate: true,
      handler: function (newValue) {
        this.listHistorics = newValue
      },
    },
  },

  async created() {
  },

};
</script>

<style scoped>
.v-date-picker-table {
  height: auto !important;
}
</style>