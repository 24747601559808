import apiService, { HOST } from "./BaseApi";
const ENDPOINT_PATH = "report/alert";

const AlertApi = {
    generatePdf: async (data, date_time) => apiService.post(ENDPOINT_PATH, { data, date_time }),

    downloadPdf: async (name) => {  
        name = name.split(" ").join("_");
        window.open(HOST + `download/pdf/${"Alertas "+name}`)},
};

export default AlertApi;
