<template>
  <div class="text-center">
    <v-snackbar
        absolute
        top
        :color="type"
        right
        transition="slide-x-reverse-transition"
        v-model="snackbar"
        :timeout="waiting"
        style="z-index: 99999;"
    >
      <strong>{{ text }}</strong>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import Utils from '@/scripts/Utils'
const wrong_word = ['invalido', 'invalido,', 'invalidos', 'erro', 'errado', 'nao', 'mal']
export default {
  data: () => ({
    snackbar: false,
    waiting: 4000,
    message: null,
    type: 'success',
  }),
  props: {
    text: {
      default: null,
      type: String,
    },
    timeout: {
      default: 4,
      type: Number,
    },
  },
  watch: {
    text(newValue) {
        if (newValue) {
            this.message = newValue;
            this.type = 'success'
            let arraySearch = Utils.prepareStringToSearch(newValue)
            if (arraySearch.some(m => wrong_word.includes(m))) {
                this.type = 'error'
            }
            this.snackbar = true;
        } else {
          this.snackbar = false;
          this.message = null;
        }
    },
    timeout(newValue) {
      if (newValue) {
        this.waiting = newValue * 1000;
      }
    },
  },
};
</script>

