import { db,auth } from "@/firebase"
const users_ref = db.collection("users")

async function getCurrentUser() {
    if (auth.currentUser !== null) {
      let doc = await db
        .collection("users")
        .doc(auth.currentUser.uid)
        .get();
      return  doc.data() ;
    } else {
      throw Error('firebase.auth().currentUser is null');
    }
  }

async function getUsers() {
    let users_list = []
    try {
        const unitsRef = await users_ref.get()
        unitsRef.forEach((doc) => users_list.push(doc.data()));
        return users_list
    } catch (error) {
        console.error(error)
    }
}
async function getUsersById(idUser) {
    try {
        const user = await users_ref.doc(idUser).get()
        return user.data()
    } catch (error) {
        console.error(error)
    }
}

async function getUsersByRank(rank) {
    try {
        let users_list = []
        let get_users = await users_ref.where("rank", "==", rank).get()

        get_users.forEach((doc) => {
            users_list.push(doc.data());
        });
        return users_list
    } catch (error) {
        console.error(error)
    }
}
async function getUsersByIdRole(idRole) {
    try {
        let users_list = []
        let get_users = await users_ref.where("id_role", "==", idRole).get()

        get_users.forEach((doc) => {
            users_list.push(doc.data());
        });
        return users_list
    } catch (error) {
        console.error(error)
    }
}

export default {
    getUsersByIdRole,
    getCurrentUser,
    getUsersByRank,
    getUsersById,
    getUsers,
};