export default {
    data: {
        isBusyPage:         false,
        isBusyClient:       false,
        isBusyUnit:         false,
        isBusyGraphic:      false,
        isBusyGeneratePDF:  false,
    },
    methods: {
        
    },
    computed: {
        isLoading() {
            return (
                this.isBusyPage
            );
        },
        isLoadingClient() {
            return (
                this.isLoading
                ||
                this.isBusyClient
                ||
                this.isLoadingUsers
            );
        },
        isLoadingUnit() {
            return (
                this.isLoading
                ||
                this.isBusyUnit
                ||
                this.isLoadingUnits
            );
        },
        isLoadingGraphic() {
            return (
                this.isLoading
                ||
                this.isBusyGraphic
                ||
                this.isLoadingClient
                ||
                this.isLoadingUnit
            );
        },
        isLoadingGeneratePDF() {
            return (
                this.isLoading
                ||
                this.isBusyGeneratePDF
                ||
                this.isLoadingClient
                ||
                this.isLoadingUnit
            );
        },
    },
}