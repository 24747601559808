<template>
    <!-- <div>{{ id }}</div> -->
    <div>
        <v-autocomplete 
            v-model="itemId"
            label="Soluções" 
            :items="items" 
            item-text="solution"
            item-value="id" 
            outlined 
            @change="inputChange"
            @focus="inputFocus"
            @blur="inputBlur"
            :loading="loading"
            :disabled="disabled"
            :rules="rulesRequired" 
            >
        </v-autocomplete>
    </div>
</template>

<script>
import { showInfoSnackBar, showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import Rules from "@/utils/Rules";
import Utils from '@/scripts/Utils';
import { mapActions, mapGetters } from "vuex";

export default {
    name: "SolutionOptionsField",
    props: {
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        items: {
            type: Array,
            required: true,
            default: () => [],
        },
        id: {
            type: String,
            default: '',
        },
        noRule: {
            type: Boolean,
            default: false,
        },
    },
    components: {

    },
    data: () => ({
        itemId: null,
        focus: false,
    }),
    methods: {
        inputChange() {
            this.emitLoading(true)
            this.emitDisabled(true)
            this.emitInput()
        },
        inputFocus() {
            this.focus = true
            this.emitFocus(this.focus)
        },
        inputBlur() {
            this.focus = false
            this.emitFocus(this.focus)
        },
        emitLoading(status) {
            this.$emit('loading:input', status)
        },
        emitDisabled(status) {
            this.$emit('disabled:input', status)
        },
        emitFocus(status) {
            this.$emit('focus:input', status)
        },
        emitInput() {
            let item = this.items.find(({id}) => id === this.itemId)
            this.$emit('change:input', {id: item.id, text: item.solution})
            this.emitLoading(false)
            this.emitDisabled(false)
            this.emitFocus(false)
        }
    },
    computed: {
        rulesRequired() {
            if (this.noRule) return []
            return Rules.required();
        },
    },
    watch: {
        id: {
            immediate: true,
            handler: function (newVal) {
                if (newVal) {
                    this.itemId = newVal
                }
            }
        },
    }
};
</script>