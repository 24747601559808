<template>
  <v-text-field outlined label="CPF/CNPJ" 
    v-model="cpfCnpj" 
    v-mask="cpfCnpjMask" 
    @input="handleCpfCnpjInput"
    :rules="rulesCpfCnpj"
    />
</template>

<script>
export default {
  name: "CpfCnpjInput",
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      cpfCnpj: this.value || "",
      cpfCnpjMask: "###.###.###-##",
    };
  },
  methods: {
    handleCpfCnpjInput() {
      const value = this.cpfCnpj.replace(/\D/g, "");
      if (value.length <= 11) this.cpfCnpjMask = "###.###.###-##";
      else this.cpfCnpjMask = "##.###.###/####-##";
      this.$emit("input", this.cpfCnpj);
    },
  },
  computed: {
    rulesCpfCnpj() {
      return [
        (v) => !!v || "CPF/CNPJ é obrigatório",
        (v) => (v.length === 14 || v.length === 18) || "CPF/CNPJ inválido",
      ];
    },
  },
  watch: {
    value(newVal) {
      this.cpfCnpj = newVal;
    }
  }
};
</script>
