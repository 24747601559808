<template>
  <v-container fluid>
    <v-row>
      <v-col :cols="loading ? 12 : 8">
        <div ref="mapContainer" id="map"
          style="height: calc(100vh - 140px); border-radius: 20px; overflow: hidden; margin: 10px;"></div>
        <v-overlay v-if="loading" absolute :value="loading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-col>
      <v-col v-if="!loading" cols="4">
        <v-card v-if="alerts.length" class="mt-4" max-height="calc(100vh - 140px)"
          style="border-radius: 20px; overflow-y: auto;">
          <v-card-title>
            <span v-if="selectedUnit">Alertas para {{ selectedUnit.name }}</span>
            <span v-else>Últimos Alertas</span>
            <!-- <v-btn icon @click="toggleSelectVisibility">
              <v-icon>{{ showSelect ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn> -->
          </v-card-title>
          <v-divider></v-divider>
          <!-- <v-expand-transition>
            <v-select v-if="showSelect" v-model="selectedType" :items="alertTypes" label="Tipo de Alerta" clearable
              @change="filterAlerts" class="mb-3"></v-select>
          </v-expand-transition> -->
          <v-list style="max-height: calc(100vh - 260px); overflow-y: auto;">
            <v-list-item v-for="alert in filteredAlerts" :key="alert.id" @click="showAlertDetails(alert)">
              <v-list-item-content>
                <div><strong>{{ alert.alertName }}</strong></div>
                <div>{{ formatDate(alert.date_of_occurrence) }}</div>
                <div v-if="!selectedUnit"><em>{{ getUnitName(alert.id_unit) }}</em></div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
        <v-row>
          <v-col cols="5">
            <v-btn block color="white" style="border-radius: 20px;color: #345FAD; font-weight: 800;"
              @click="navigateTo('/alertas/telemetry')">Telemetria</v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn block color="white" style="border-radius: 20px;color: #345FAD; font-weight: 800;"
              @click="navigateTo('/alertas/goal')">Meta</v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn block color="white" style="border-radius: 20px;color: #345FAD; font-weight: 800;"
              @click="navigateTo('/alertas/cosumption')">Consumo</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card v-if="selectedAlert" class="pa-4">
        <v-card-title class="headline">
          {{ selectedAlert.alertName }}
        </v-card-title>

        <v-card-text>
          <!-- Seção para informações principais -->
          <v-row>
            <v-col cols="12" md="12">
              <div class="d-flex align-center">
                <v-icon class="mr-2">mdi-home-city</v-icon>
                <strong>Unidade:</strong>
                <span class="ml-2">{{ selectedAlert.unitName }}</span>
              </div>
            </v-col>

            <v-col cols="12" md="12">
              <div class="d-flex align-center">
                <v-icon class="mr-2">mdi-calendar</v-icon>
                <strong>Data de Ocorrência:</strong>
                <span class="ml-2">{{ formatDate(selectedAlert.date_of_occurrence) }}</span>
              </div>
            </v-col>
          </v-row>

          <!-- Seção para consumo -->
          <v-divider class="my-4"></v-divider>
          <v-row>
            <v-col cols="12" md="12">
              <div class="d-flex align-center">
                <v-icon class="mr-2" color="warning">mdi-speedometer</v-icon>
                <strong>Limite do Alerta:</strong>
                <span class="ml-2">{{ formatNumber(selectedAlert.liters_consumed_in_period - selectedAlert.liters_exceeded_in_period) }}</span>
              </div>
            </v-col>
            <v-col cols="12" md="12">
              <div class="d-flex align-center">
                <v-icon class="mr-2" color="primary">mdi-water</v-icon>
                <strong>Litros Consumidos:</strong>
                <span class="ml-2">{{ formatNumber(selectedAlert.liters_consumed_in_period) }}</span>
              </div>
            </v-col>
            <v-col cols="12" md="12">
              <div class="d-flex align-center">
                <v-icon class="mr-2" color="red" >mdi-water-off</v-icon>
                <strong>Litros Excedidos:</strong>
                <span class="ml-2">{{ formatNumber(selectedAlert.liters_exceeded_in_period) }}</span>
              </div>
            </v-col>
          </v-row>

          <!-- Seção para tipo de alerta -->
          <v-divider class="my-4"></v-divider>
          <v-row>
            <v-col cols="12" md="12">
              <div class="d-flex align-center">
                <v-icon class="mr-2" >mdi-alert-circle</v-icon>
                <strong>Tipo: </strong>
                <span class="ml-2">{{ changeSlugAlertType(selectedAlert) }}</span>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialog = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </v-container>
</template>

<script>
import AlertsNotificationsService from "@/services/server/AlertsNotificationService";

export default {
  name: 'ChooseAlerts',
  data() {
    return {
      center: { lat: -8.037114010212356, lng: -34.926027202099725 },
      units: [],
      selectedUnit: null,
      alerts: [],
      filteredAlerts: [],
      selectedType: null,
      alertTypes: ['consumption', 'leak', 'other'],
      showSelect: false,
      loading: true,
      selectedAlert: null,
      dialog: false,
      map: null
    };
  },
  computed: {
    unitsWithAlerts() {
      return this.units.filter(unit => unit.alerts.length > 0);
    },
    sortedAlerts() {
      return this.alerts.sort((a, b) => b.date_of_occurrence._seconds - a.date_of_occurrence._seconds);
    }
  },
  methods: {
    formatNumber(value){
      value = Number(value)
      return value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
    toggleSelectVisibility() {
      this.showSelect = !this.showSelect;
    },
    getUnitName(unitId) {
      const unit = this.units.find(unit => unit.id === unitId);
      return unit ? unit.name : 'Desconhecido';
    },
    changeSlugAlertType(selectedAlert) {
      switch (selectedAlert.type) {
        case "consumption":
          return "Consumo"
        case "lack_of_signal":
          return "Falta de sinal"
        case "no_variation":
          return "Sem variação"
        case "low_battery":
          return "Bateria baixa"
        case "no_communication":
          return "Sem comunicação"
        case "goal_scheduled":
          return `Ponto da Meta - Ultrapassou ${selectedAlert.point_exceeded}% da Meta`
        case "goal_exceeded":
          return "Meta Ultrapassada"
        default:
          return "Indefinido"
      }
    },
    showAlertDetails(alert) {
      const unit = this.units.find(unit => unit.id === alert.id_unit);
      const unitName = unit ? unit.name : 'Desconhecido';
      this.selectedAlert = { ...alert, unitName };
      this.dialog = true;
    },
    selectUnit(unit) {
      this.selectedUnit = unit;
      this.alerts = unit.alerts;
      this.filterAlerts();
    },
    filterAlerts() {
      if (this.selectedType) {
        this.filteredAlerts = this.alerts.filter(alert => alert.type === this.selectedType);
      } else {
        this.filteredAlerts = this.alerts;
      }
    },
    navigateTo(route) {
      this.$router.push(route);
    },
    async fetchAlerts() {
      try {
        const response = await AlertsNotificationsService.mapFindAll();
        this.units = response.data;
        this.alerts = response.data.flatMap(unit => unit.alerts).sort((a, b) => b.date_of_occurrence._seconds - a.date_of_occurrence._seconds);
        this.filteredAlerts = this.alerts;

        this.units.forEach(unit => {
        const markerElement = new google.maps.marker.AdvancedMarkerElement({
          position: unit.position,
          map: this.map,
          title: unit.name,
          content: this.createMarkerElement(unit)
        });
        markerElement.addListener('click', () => {
          this.selectUnit(unit);
        });
      });

      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    initMap() {
      this.map = new google.maps.Map(this.$refs.mapContainer, {
        center: this.center,
        zoom: 10,
        mapId: 'map'
      });
      
    },
    createMarkerElement(unit) {
      const markerElement = document.createElement('div');
      markerElement.className = 'custom-marker';
      markerElement.innerHTML = `
        <div class="custom-marker-label">${unit.name}</div>
        <div class="custom-marker-icon"></div>
      `;
      return markerElement;
    },
    formatDate(date) {
      const d = new Date(date._seconds * 1000);
      return d.toLocaleString();
    }
  },
  async created() {
    await this.fetchAlerts();
  },
  mounted() {
    this.initMap();
  }
};
</script>

<style>
.alert-list {
  margin-top: 20px;
}

.custom-marker {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-marker-icon {
  width: 24px;
  height: 24px;
  background: url('http://maps.google.com/mapfiles/ms/icons/red-dot.png') no-repeat center center;
  background-size: contain;
}

.custom-marker-label {
  margin-bottom: 8px;
  background-color: white;
  padding: 2px 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}
</style>
