import DatePickerDialog from "@/components/DatePickerDialog/DatePickerDialog.vue";
import AlertsConfigService from "@/services/firebase/AlertsConfig/AlertsConfigService";
import SectorsService from "@/services/firebase/Sectors/SectorsService";
import UnitsService from "@/services/server/UnitsService";
import UsersService from "@/services/firebase/Users/UsersService";
import NotificationsService from "@/services/server/NotificationsService";


import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper"
import { showErrorSnackBar, showSuccessSnackBar, showInfoSnackBar } from "@/utils/SnackBartHelper";
import Utils from '@/scripts/Utils';
import Rules from "@/utils/Rules";

export default {
  components: {
    DatePickerDialog,
  },
  name: "Alerts",

  data: () => ({
    tabItem: null,
    tabItems: ['Alerta', 'Notificação'],
    channelsTypes: [
      { value: "platform", text: "Plataforma" },
      { value: "whatsapp", text: "Whatsapp" }
    ],
    alertData: [],
    usersData: [],
    sectorsData: [],
    unitsData: [],
    sectorsAttachAlert: [],
    unitsAttachAlert: [],
    usersAttachWhatsappAlert: [],
    sectorsByAlertData: [],
    unitsByAlertData: [],
    headers: [
      { text: "Nome", value: "name" },
      { text: "Tipo do Alerta", value: "alerType" },
      { text: "Periodo", value: "periodType" },
      { text: "Tipo de Consumo", value: "consumptionType" },
      { text: "Base", value: "consumptionLimit" },
      { text: 'Ações', value: 'actions', sortable: false },
    ],
    headersUnitAlertByAlert: [
      { text: "Unidade", value: "id_unit" },
      { text: "Ultima Leitura", value: "reading_date" },
      { text: "Próxima Leitura", value: "next_reading_date" },
      { text: "Litros no periodo", value: "liters_period" },
      { text: "Pontos Alertados", value: "list_last_alert_goal_scheduled_generated" },
    ],
    headersGoalParam: [
      {
        text: 'Ponto',
        align: 'start',
        sortable: false,
        value: 'point',
      },
      { text: 'Mensagem', value: 'message' },
      { text: 'Ações', value: 'actions', sortable: false },
    ],
    dialogGoalList: false,
    desserts: [],
    deleteOrAddAll: "add",
    deleteOrAddAllUsers: "add",
    modalAlert: false,
    columnsToChoose: [{ value: "name", text: "Nome" }],
    listActions: [
      { value: "edit", title: "Editar" },
      { value: "delete", title: "Excluir" },
    ],
    alertTypes: [
      { value: "goal", text: "Meta" },
      { value: "comsumption", text: "Consumo" }
    ],
    advancedConfigsTypes: [
      { value: "manual", text: "Manual" },
      { value: "movingAverage", text: "Média Móvel" },
      // { value: "averageBefore", text: "Média Antes da Foz" }
    ],
    week: [
      { value: "MONDAY", text: "Segunda" },
      { value: "TUESDAY", text: "Terça" },
      { value: "WEDNESDAY", text: "Quarta" },
      { value: "THURSDAY", text: "Quinta" },
      { value: "FRIDAY", text: "Sexta" },
      { value: "SATURDAY", text: "Sabado" },
      { value: "SUNDAY", text: "Domingo" },
    ],
    month: Array(30).fill(1).map((value, index) => (index + 1)),
    endMonth: [],
    endWeek: [],
    menuStart: false,
    menuEnd: false,
    selectHourStart: '00:00',
    selectHourEnd: '23:59',
    periodTypes: [
      { value: "day", text: "Diario" },
      { value: "week", text: "Semanal" },
      { value: "month", text: "Mensal" },
    ],
    columnToSearch: "name",
    alerTypeSelected: null,
    modalTitle: "Adicionar Alerta",
    searchText: "",
    startDateDialog: false,
    startDatePicker: null,
    minStartDate: null,
    maxStartDate: null,
    avaregeDays: 30,
    percentageAverage: 100,
    consumptionType: null,
    pointsList: [],
    editedPointsList: {},
    editedItem: {
      alerType: null,
      cumulative: false,
      notificationPointWhenForecastExceedingTheGoal: false,
      periodType: null,
      startHour: "00:00",
      endHour: "23:59",
      startPeriod: null,
      endPeriod: null,
      pointsList: [],
      notificationIsActive: false,
      notificationChannel: [],
      usersSendWhatsappNotification: [],
      deletedAt: null
    },
    loading: {
      usersList: false,
      modalAlert: false,
      mainTable: false,
      unitsList: false,
      sectorList: false,
      buttonList: false,
    },
    validForm: true
  }),

  methods: {
    addPointList() {
      this.editedItem.pointsList.push({ ...this.editedPointsList })
      this.dialogGoalList = false
      this.editedPointsList = {}
    },
    deletePointListItem(item) {
      this.editedItem.pointsList = this.editedItem.pointsList.filter(point => point != item)
    },
    selectAction(item, value) {
      switch (value) {
        case "edit":
          this.editAlert(item)
          break;
        case "delete":
          this.editedItem = { ...item }
          showDeleteDialogAlert(`Deseja excluir o alerta "${item.name}"? O alerta será removido de todos os setores correspondentes!`, this.deleteItemConfirm)
          break;
        default:
          break;
      }
    },
    async addAttachSectors(idAlert, listSector) {
      if (listSector.length) {
        const saveList = listSector.map(id_sector => ({ id_sector, id_alert: idAlert }))
        await AlertsConfigService.createAlertConfigList(saveList)
      }
    },
    async addAttachUnits(idAlert, listUnits) {
      if (listUnits.length) {
        const saveList = listUnits.map(id => ({ id_unit: id, id_alert: idAlert, liters_period: 0 }))
        await AlertsConfigService.createUnitsAlertConfigList(saveList)
      }
    },
    async deletAttachSectors(listDelete) {
      if (listDelete.length) {
        await AlertsConfigService.deleteAlertConfigList(listDelete)
      }
    },
    async deletAttachUnits(listDelete) {
      if (listDelete.length) await AlertsConfigService.deleteUnitsAlertConfigList(listDelete)
    },
    async editAttachUnits(idAlert, listUnits) {
      let deleteds = []
      if (listUnits.length) {
        deleteds = this.unitsByAlertData.filter(unitAlert => !listUnits.find(i => unitAlert.id_unit === i))
        const addeds = listUnits.filter(id_unit => !this.unitsByAlertData.find(unitAlert => id_unit === unitAlert.id_unit))
        if (addeds.length) {
          const saveList = addeds.map(id_unit => ({ id_unit, id_alert: idAlert }))
          await AlertsConfigService.createUnitsAlertConfigList(saveList)
        }
        if (deleteds.length) await deletAttachUnits(deleteds)
      } else if (listUnits.length === 0 && this.unitsByAlertData.length) {
        deleteds = this.unitsByAlertData
        if (deleteds.length) await deletAttachUnits(deleteds)
      }
    },
    async editAttachSectors(idAlert, listSector) {
      let deleteds = []
      if (listSector.length) {
        deleteds = this.sectorsByAlertData.filter(sectorAlert => !listSector.find(i => sectorAlert.id_sector === i))
        const addeds = listSector.filter(id_sector => !this.sectorsByAlertData.find(sectorAlert => id_sector === sectorAlert.id_sector))
        this.addAttachSectors(idAlert, addeds)
        this.deletAttachSectors(deleteds)
      } else if (listSector.length === 0 && this.sectorsByAlertData.length) {
        deleteds = this.sectorsByAlertData
        this.deletAttachSectors(deleteds)
      }
    },
    async deleteItemConfirm() {
      this.loading.buttonList = true
      if (this.editedItem.alerType == 'goal') {
        const resSectorsAlerts = await AlertsConfigService.getUnitsByAlertConfig(this.editedItem.id_doc)
        await this.deletAttachUnits(resSectorsAlerts)
      } else {
        const resSectorsAlerts = await AlertsConfigService.getSectorsByAlertConfig(this.editedItem.id_doc)
        await this.deletAttachSectors(resSectorsAlerts)
      }

      await AlertsConfigService.editAlert(this.editedItem.id_doc, { deletedAt: new Date() })

      showSuccessSnackBar("Alerta deletado!")
      this.alertData = this.alertData.filter((alert) => alert.id_doc != this.editedItem.id_doc)
      this.closeAlertModal()
    },
    async editAlert(item) {
      this.editedItem = { ...item }

      if (this.editedItem.alerType == 'goal') {
        this.tabItems = ['Alerta', 'Notificação','Monitor']




        this.loading.unitsList = true
        AlertsConfigService.getUnitsByAlertConfig(item.id_doc).then(res => {
          this.unitsByAlertData = res
          this.unitsAttachAlert = this.unitsByAlertData.map(unitByAlert => (unitByAlert.id_unit))
          if (this.unitsByAlertData.length) this.deleteOrAddAll = 'delete'
        }).finally(() => this.loading.unitsList = false)
      } else {
        this.tabItems = ['Alerta', 'Notificação']
        this.loading.sectorList = true
        AlertsConfigService.getSectorsByAlertConfig(item.id_doc).then(res => {
          this.sectorsByAlertData = res
          this.sectorsAttachAlert = this.sectorsByAlertData.map(sectorByAlert => (sectorByAlert.id_sector))
          if (this.sectorsByAlertData.length) this.deleteOrAddAll = 'delete'
        }).finally(() => this.loading.sectorList = false)
      }
      this.modalTitle = "Editar Alerta"
      this.modalAlert = true
    },
    checkUsersWhatsapp() {
      if (this.editedItem.notificationChannel.find((type) => type === 'whatsapp') && this.editedItem.usersSendWhatsappNotification && (this.editedItem.usersSendWhatsappNotification).length === 0) {
        showInfoSnackBar("Selecione ao menos um usúario para receber a notificação do whatsapp")
        throw new Error("Selecione ao menos um usúario para receber a notificação do whatsapp")
      } 
    },
    async save() {
      try {
        if ((this.$refs.form) && !this.$refs.form.validate()) return;
        this.loading.modalAlert = true
        if (this.editedItem.notificationIsActive) this.checkUsersWhatsapp()

        if (this.modalTitle === "Adicionar Alerta") {
          const newAlertId = await AlertsConfigService.createAlertConfig(this.editedItem)

          if (this.editedItem.alerType == 'goal') this.addAttachUnits(newAlertId, this.unitsAttachAlert)
          else this.addAttachSectors(newAlertId, this.sectorsAttachAlert)

          showSuccessSnackBar("Alerta criado!")
          this.alertData.unshift({ ...this.editedItem, id_doc: newAlertId })
        } else {
          await AlertsConfigService.editAlert(this.editedItem.id_doc, this.editedItem)
          if (this.editedItem.alerType == 'goal') this.editAttachUnits(this.editedItem.id_doc, this.unitsAttachAlert)
          else this.editAttachSectors(this.editedItem.id_doc, this.sectorsAttachAlert)
          showSuccessSnackBar("Alerta editado!")
          this.alertData = this.alertData.map((alert) => alert.id_doc === this.editedItem.id_doc ? this.editedItem : alert)
        }
        this.loading.modalAlert = false
        this.closeAlertModal()
      } catch (error) {
        this.loading.modalAlert = false
        console.error('Error save: ' + error)
      }
    },
    formatData(data1, data2) {

      let vetDatas = [data1, data2]
      let result = []
      if (data2 !== null) {
        for (let key in vetDatas) {
          let partes = vetDatas[key].split('-').map(Number);
          let data = new Date(partes[0], partes[1] - 1, partes[2]);
          result[key] = data.toLocaleString('pt-BR', { weekday: 'long' })
        }
        return (result[0] + ' á ' + result[1])
      }
      return null
    },
    addNewAlert() {
      this.modalTitle = "Adicionar Alerta"
      this.tabItems = ['Alerta', 'Notificação']
      this.modalAlert = true
    },
    closeAlertModal() {
      this.deleteOrAddAll = 'add'
      this.deleteOrAddAllUsers = 'add'
      this.loading.buttonList = false
      this.modalAlert = false
      this.sectorsAttachAlert = []
      this.unitsAttachAlert = []
      this.editedItem = {
        alerType: null,
        cumulative: false,
        notificationPointWhenForecastExceedingTheGoal: false,
        periodType: null,
        startHour: "00:00",
        endHour: "23:59",
        startPeriod: null,
        endPeriod: null,
        notificationIsActive: false,
        pointsList: [],
        notificationChannel: [],
        usersSendWhatsappNotification: [],
        deletedAt: null
      }
    },
    clearEndPeriod() {
      this.editedItem.endPeriod = null
    },
    endSelectDate() {
      switch (this.editedItem.periodType) {
        case "day":
          return []
        case "week":
          this.endWeek = this.week.filter(item => item.value != this.editedItem.startPeriod)
          return []
        case "month":
          this.endMonth = this.month.filter(item => item != this.editedItem.startPeriod)
          return
        case "custom":
          return []
        default:
          return []
      }
    },
    alertTypeSlug(alertType) {
      switch (alertType) {
        case "goal":
          return "Meta"
        case "comsumption":
          return "Consumo"
      }
    },
    changeUnitIdPerUnitName(unitAlert) {
      const unit = this.unitsData.find(({id})=> id === unitAlert)
      return unit? unit.name : "Indefinido!"
    },
    getNextReadingDateByUnit(unitAlert) {
      const unit = this.unitsData.find(({id})=> id === unitAlert)
      return unit && unit.lastScheduledReadingDate?  Utils.formatDateDayJs(new Date(unit.lastScheduledReadingDate._seconds * 1000), 'YYYY-MM-DD') : "Indefinido!"
    },
    getReadingDateByUnit(unitAlert) {
      const unit = this.unitsData.find(({id})=> id === unitAlert)
      if(unit && unit.lastReadingDate && typeof unit.lastReadingDate === 'object'){
        return  Utils.formatDateDayJs(new Date(unit.lastReadingDate._seconds * 1000), 'YYYY-MM-DD') 
      }else if(unit && unit.lastReadingDate && typeof unit.lastReadingDate === 'string'){
        return Utils.formatDateDayJs(new Date(unit.lastReadingDate), 'YYYY-MM-DD') 
      }else return "Indefinido!"
    },
    periodTypeSlug(periodType) {
      switch (periodType) {
        case "day":
          return "Diario"
        case "week":
          return "Semanal"
        case "month":
          return "Mensal"
      }
    },
    consumptionTypeSlug(periodType) {
      switch (periodType) {
        case "manual":
          return "Manual"
        case "movingAverage":
          return "Média Móvel"
        case "averageBefore":
          return "Média Antes da Foz"
      }
    },
    consumptionLimitSlug(alert) {
      switch (alert.consumptionType) {
        case "manual":
          return alert.consumptionLimit + " L"
        case "movingAverage":
          return alert.consumptionLimit + " Dias"
        case "averageBefore":
          return alert.consumptionLimit + " %"
      }
    },
    deleteOrAdd() {
      if (this.deleteOrAddAll === 'delete') {
        this.sectorsAttachAlert = []
        this.deleteOrAddAll = 'add'
      } else {
        this.sectorsAttachAlert = this.sectorsData.map(sectorByAlert => (sectorByAlert.id_doc))
        this.deleteOrAddAll = 'delete'
      }
    },
    deleteOrAdd() {
      if (this.deleteOrAddAll === 'delete') {
        this.unitsAttachAlert = []
        this.sectorsAttachAlert = []
        this.deleteOrAddAll = 'add'
      } else {
        if (this.editedItem.alerType == 'goal') {
          this.unitsAttachAlert = this.unitsData.map(sectorByAlert => (sectorByAlert.id))
        } else {
          this.sectorsAttachAlert = this.sectorsData.map(sectorByAlert => (sectorByAlert.id_doc))
        }
        this.deleteOrAddAll = 'delete'
      }
    },
    deleteUsersList() {
      this.editedItem.usersSendWhatsappNotification = []
    }
  },
  computed: {
    filteredItems() {
      let items = [];
      let search = "name"
      let busca = ""
      switch (this.columnToSearch) {
        case "name":
          search = 'name'
          items = this.alertData.filter((i) => {
            return (
              i[search].toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
            );
          });
          break;
      }
      return items;
    },
    rulesRequired() {
      return Rules.required();
    },

  },
  watch: {

  },
  async mounted() {
    this.loading.mainTable = true
    this.alertData = await AlertsConfigService.getAlertsConfig()
    this.loading.mainTable = false
    this.loading.sectorList = true
    this.loading.unitsList = true
    SectorsService.getSectors().then(res => {
      this.sectorsData = res
    }).finally(() => this.loading.sectorList = false)

    UnitsService.findAll().then(res => {
      this.unitsData = res.data.data
    }).finally(() => this.loading.unitsList = false)

    this.loading.usersList = true
    UsersService.getUsers().then(res => {
      this.usersData = res.filter((user) => {
        if (user.metadata.phone && user.metadata.phone.length > 0) {
          return user
        } else false
      })
    }).finally(() => this.loading.usersList = false)
  },
};