<template>
<div style="position: relative;display: block;">
  <v-row>
    <v-col cols="12"><h3 style="position:absolute; top: 15px;left: 15px;">Detalhamento de Chamados</h3></v-col>
    <v-col cols="12" style="height: 270px;"><canvas id="myChartCall"></canvas></v-col>
  </v-row>
  </div>
</template>
<script>
import Chart from "chart.js";
export default {
  props: {
    amount: {
      type: Number,
      default: 0,
    },
    labels: {
      type: Array
    },   
    data: {
      type: Array
    },   
  },
  data() {
    return {
      myChart: null,
    };
  },
  mounted() {
    const ctx2 = document.getElementById("myChartCall");
    this.myChart = new Chart(ctx2, {
      type: "bar",
      data: {
        labels: [],
        datasets: [],
      },
      options: {
        plugins: {
          datalabels: {
            color: "rgb(200 200, 200)",  
            labels: {
              title: {
                font: {
                  size: 15,
                  weight: "bold",
                },
              },              
            },
          },
        },
        scaleShowValues: true,
        title: {
          display: false,
          text: "Chamados Detalhes",
        },
        responsive: true,
        maintainAspectRatio: false,
        datalabels: {
          color: "white",
          display: true,
          font: {
            size: 15,
            weight: "bold",
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },       
        legend: {
          display: false,
        },
      },
    });
  },
  watch: {
    data(newValue) {
      if (newValue) {
        let backgroundColor = [
          "rgb(56, 116, 204)",
          "rgb(31, 104, 14)",
          "rgb(33, 79, 127)",
          "rgb(68, 139, 52)",
          "rgb(101, 101, 101)",
          // "rgb(30, 144, 255)",
          // "rgb(148, 59, 0)",
        ]
        this.myChart.data.datasets = [{
          data: newValue,
          backgroundColor,
        }]
        this.myChart.update();
      }
    },
    labels(newValue) {
      if (newValue) {
        this.myChart.data.labels = newValue
        this.myChart.update();
      }
    },
  },
  computed: {},
};
</script>