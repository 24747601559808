import { db } from "@/firebase"

const features_ref = db.collection("features")

const getFeatures = async () => {
    let features_list = []
    try {
        const get_data = await features_ref.get();
        get_data.forEach((doc) => { features_list.push(doc.data()); });
        return features_list;
    } catch (error) {
        throw new Error("getFeatures Fail!" + error)
    }
}

export default {
    getFeatures
};