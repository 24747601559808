

import { storage } from "@/firebase"
import Compressor from 'compressorjs';
import { compressorConfig } from "@/utils/Constants.js"

const compressorFile = async (file) => {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            ...compressorConfig,
            success(result) {
                resolve(result);
            },
            error(err) {
                reject(err);
                console.error(err.message);
            },
        });
    })
}
const uploadImageByFilepath = async (pathSaved, file) => {
    const fileCompress = await compressorFile(file)
    const storageWS_REF = storage.ref(pathSaved)
    var task = storageWS_REF.put(fileCompress);
    await new Promise((resolve, reject) => {
        task.on('state_changed',
            function progress(snapshot) {
                var percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;
            },
            function error(err) {
                reject(err)
            },
            function complete() {
                var downloadURL = task.snapshot.downloadURL;
                resolve("Download Complet")
            }
        );
    })
    return pathSaved
}
const getFile = async (path) => {
    const ref = await storage.ref(path)
    return {
        name: ref.name,
        url: await ref.getDownloadURL()
    }
}
const downloadFile = async (path) => {
    return await storage.ref(path).getDownloadURL()
}
const deleteFile = async (path) => {
    return await storage.ref(path).delete()
}
const downloadImg = async (path) => {
    return downloadFile(path)
}
const deleteImg = async (path) => {
    return deleteFile(path)
}

export default {
    getFile,
    deleteImg,
    downloadImg,
    uploadImageByFilepath,
}