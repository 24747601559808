import { db } from "@/firebase"

const sectors_ref = db.collection("sectors")

const createSector = async (data) => {
    try {
        const get_data = await sectors_ref.add(data);
        await editSector(get_data.id, { ...data, id_doc: get_data.id })
        return get_data.id;
    } catch (error) {
        throw new Error("createSector Fail!" + error)
    }
}
const editSector = async (idDoc, data) => {
    try {
        await sectors_ref.doc(idDoc).update(data);
        return true;
    } catch (error) {
        throw new Error("editSector Fail!" + error)
    }
}
const deleteSector = async (idDoc) => {
    try {
        await sectors_ref.doc(idDoc).delete();
        return true;
    } catch (error) {
        throw new Error("editSector Fail!" + error)
    }
}
const deleteSectorsByUnit = async (idUnit) => {
    try {
        const batch = db.batch();
        const get_data = await sectors_ref.where("id_unit", "==", idUnit).get();
        get_data.forEach((doc) => batch.delete(doc.ref));
        await batch.commit();
        return true;
    } catch (error) {
        throw new Error("editSector Fail!" + error)
    }
}
const getSectorsByUnitId = async (idUnit) => {
    let sectors_list = []
    try {
        const get_data = await sectors_ref.where("id_unit", "==", idUnit).get();
        get_data.forEach((doc) => { sectors_list.push(doc.data()); });
        return sectors_list;
    } catch (error) {
        throw new Error("getSectorsByUnitId Fail!" + error)
    }
}
const getSectors = async () => {
    let sectors_list = []
    try {
        const get_data = await sectors_ref.get();
        get_data.forEach((doc) => { sectors_list.push(doc.data()); });
        return sectors_list;
    } catch (error) {
        throw new Error("getSectorsByModemId Fail!" + error)
    }
}
const getSectorsByModemId = async (idModem) => {
    let sectors_list = []
    try {
        const get_data = await sectors_ref.where("id_modem", "==", idModem).get();
        get_data.forEach((doc) => { sectors_list.push(doc.data()); });
        return sectors_list;
    } catch (error) {
        throw new Error("getSectorsByModemId Fail!" + error)
    }
}

export default {
    getSectors,
    editSector,
    createSector,
    deleteSector,
    getSectorsByUnitId,
    getSectorsByModemId,
    deleteSectorsByUnit
};