<template>
  <div style="position: relative; display: block">
    <h3 v-if="title !== null" style="position: absolute; top: 15px; left: 15px">{{ title }}</h3>
    <canvas :id="canvaID"></canvas>
  </div>
</template>
<script>
import Chart from "chart.js";

// Chart.defaults.global.datasets.bar.categoryPercentage = 0.1;

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    datasets: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      id: null,
      canvaID: null,
      colorBarWater: "#3474cb",
      colorBarSewer: "#697e9d",
      colorLineAverage: "#2b82ff",
      gradientBg: null,
      myChart: null,
      options: {
        scaleShowValues: true,
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false,
          },
        },
        legend: {
          display: true,
          labels: {
            usePointStyle: true,
          }
        },
        title: {
          display: true,
          text: "",
        },
        scales: {
            // yAxes: [{
            //     ticks: {
            //         beginAtZero: true
            //     }
            // }],
            // xAxes: [
            //   {
            //     // Change here
            // 	barPercentage: 1
            //   }
            // ]
        },
        dataset: {
          barPercentage: 0.1
        }
      },
      data: {
        labels: [],
        datasets: [],
      },
      totalxAxes: 0,
    };
  },
  created() {
    this.id = this._uid
    this.canvaID = `graphic-consumption-${this.id}`
  },
  mounted() {
    
    this.render();
  },
  methods: {
    render(datasets = []) {
        if (this.myChart) {
          this.myChart.data.datasets = datasets
          this.myChart.update();
          return;
        }
        let ctx = document.getElementById(this.canvaID).getContext('2d');
        const gradientBg = ctx.createLinearGradient(0,0,0,500)
        gradientBg.addColorStop(0,this.colorLineAverage)
        gradientBg.addColorStop(0.5,"white")

        this.gradientBg = gradientBg
        this.updateBaclgroundColorLine();
        this.myChart = new Chart(ctx, {
            type: "bar",
            data: {
                labels: this.labels,
                datasets: datasets,
            },
            options: this.options,
        });
    },
    updateBaclgroundColorLine() {
      if (!this.gradientBg) return;
      this.data.datasets = this.datasets.map(dataset => dataset.type == "line" ? {...dataset, backgroundColor: this.gradientBg} : dataset)
    },
  },
  watch: {
    datasets: {
      immediate: true,
      handler: function (newValue) {
        let total = 100;
        if (newValue.length > 0) {
          let data = newValue[0].data
          total = data.length
        }
        let percentage = 0.025*total;
        if (percentage > 1) percentage = 1
        Chart.defaults.global.datasets.bar.categoryPercentage = percentage;
        if (newValue) {
            this.$nextTick(
                () => {
                    this.render(this.data.datasets);
                }
            );
        }
      },
    },
    labels: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
            this.data.labels = newValue
            this.totalxAxes = newValue.length;
            this.$nextTick(
                () => {
                    this.render(this.data.datasets);
                }
            );
        }
      },
    },
  },
  computed: {},
};
</script>