<template>
  <v-form ref="form" v-model="validForm" lazy-validation>
    <v-row justify="center">
      <v-dialog transition="dialog-top-transition" v-model="dialog" v-if="dialog" persistent max-width="700px">
        <v-card>
          <v-toolbar color="primary" dark> <span class="text-h5">Adicionar Solução</span></v-toolbar>
          <v-card-title>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text style="padding-bottom: 0px;">

            <v-row>
              <h3> Informações do Ponto</h3>
            </v-row>
            <v-row>
              <v-col cols="12" :sm="6">
                <v-text-field v-model="solutionReport.local" outlined label="Local" type="text" required
                  :rules="rulesRequired">
                </v-text-field>
              </v-col>
              <v-col cols="12" :sm="6">
                <v-text-field v-model="solutionReport.sub_local" outlined label="Sub-Local" type="text" required
                  :rules="rulesRequired">
                </v-text-field>
              </v-col>
              <v-col cols="12" :sm="solutionReport.pipingType ? 6 : 4">
                <v-autocomplete label="Tipo" 
                  :items="pointsOptions" 
                  item-text="name"
                  item-value="id" 
                  outlined 
                  v-model="solutionReport.type"
                  @change="optionTypeSelect(solutionReport.type)" :rules="rulesRequired" onfocus>
                </v-autocomplete>
              </v-col>
              <v-col v-if="solutionReport.pipingType" cols="12" :sm="solutionReport.pipingType ? 6 : 4">
                <v-select
                  :items="pipingTypesList"
                  v-model="solutionReport.pipingType"
                  label="Tipo da Tubulação"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" :sm="solutionReport.pipingType ? 6 : 4">
                <v-autocomplete label="Soluções" 
                  :items="solutionsOptionsList" 
                  v-model="solutionReport.solution" 
                  outlined
                  item-text="solution"
                  item-value="id"
                  :disabled="solutionReport.type === null" :rules="rulesRequired">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" :sm="solutionReport.pipingType ? 6 : 4">
                <v-text-field v-model="solutionReport.amountSolution" outlined label="Qtnd." type="Number" required
                  :rules="rulesRequired">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" :sm="4">
                <v-text-field v-model="solutionReport.flowRateBefore" outlined label="Vazão antes" type="Number"
                  suffix="L/min" required :rules="rulesRequired">
                </v-text-field>
              </v-col>
              <v-col cols="12" :sm="4">
                <v-text-field v-model="solutionReport.flowRateAfter" outlined label="Vazão Depois" type="Number"
                  suffix="L/min" required :rules="rulesRequired">
                </v-text-field>
              </v-col>
              <v-col cols="12" :sm="4">
                <v-select
                  :items="flowTypeList"
                  v-model="solutionReport.flowRateType"
                  label="Tipo do fluxo"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="solutionReport.economy" outlined label="Economia" readonly suffix="%">
                </v-text-field>
              </v-col>
              <v-col cols="12" :sm="12">
                <v-textarea v-model="solutionReport.comments" outlined label="Observação" type="text">
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <h3> Adicionar Imagem </h3>
              <v-btn color="primary" text @click="pickFile()">
                <v-icon>add</v-icon>
              </v-btn>
              <input multiple type="file" id="get_image" ref="get_image" accept="image/*" style="display: none"
                @input="onFilePicked" />
            </v-row>
            <vue-horizontal>
              <section style=" margin: 10px;" v-for="img, n in listImgs" :key="n">
                <!-- :lazy-src="`https://localhost:8080/favicon.ico`" -->
                <v-img :src="img.image" aspect-ratio="1" style="width: 200px" @click="deleteImgSelect(img)">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey-lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </section>

            </vue-horizontal>
            <!-- <video autoplay id="video"></video> -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="loading.uploadImg" color="blue darken-1" text @click="closeDialogBtn">
              Cancelar
            </v-btn>
            <v-btn :loading="loading.uploadImg" color="blue darken-1" text @click="save()"> Salvar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-form>
</template>

<script>
import Rules from "@/utils/Rules";
import VueHorizontal from 'vue-horizontal';
import WaterScanStoragefrom from '@/services/storage/WaterScanStorage';
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper";
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import SolutionListModel from '@/objects/Waterscan/CheckListTab/SolutionListModel.js'
import SolutionListController from '@/objects/Waterscan/CheckListTab/SolutionListController.js'
export default {
  components: {
    VueHorizontal
  },
  props: {
    dialog: {
      default: false,
      type: Boolean,
    },
    idProposal: "",
    saveFunc: {},
    clearEditItem: {},
    closeDialog: {},
    editableSolution: {},
    registration: "",
    solutionsOptions: {},
    pointsOptions: {}
  },

  data: () => ({
    cols: "12",
    validForm: true,
    // solutionReport: {
    //   type: null,
    //   flowRateAfter: null,
    //   flowRateBefore: null,
    //   solution: "",
    // },
    solutionReport: {},
    optionSolution: [],
    optionsType: [
      { text: "Torneira", value: "faucet" },
      { text: "Chuveiro", value: "shower" },
      { text: "Descarga", value: "discharge" },
    ],
    listImgs: [],
    editedItem: {},
    deleteImgFile: {},
    rawFiles: [],
    urlImgTeste: "",
    loading: {
      uploadImg: false
    },
    pipingTypesList: SolutionListModel.getPipingTypeList(),
    flowTypeList: SolutionListModel.getFlowTypeList(),

    solution: null,
    solutionListModel: null,
    solutionListController: null,
  }),
  methods: {
    pickFile() {
      this.$refs.get_image.click();
    },
    async catchImg(img) {
      // if ('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices) {
      //   const constraints = {
      //     video: {
      //       width: {
      //         min: 1280,
      //         ideal: 1920,
      //         max: 2560,
      //       },
      //       height: {
      //         min: 720,
      //         ideal: 1080,
      //         max: 1440,
      //       },
      //       facingMode: "environment"
      //     },
      //   }
      //   const video = document.querySelector('#video')
      //   const videoStream = await navigator.mediaDevices.getUserMedia(constraints)
      //   // video.srcObject = videoStream

      //   // ok, o navegador tem suporte
      // }
    },
    async deleteImgSelect(event) {
      this.deleteImgFile = event
      showDeleteDialogAlert(`Deseja Excluir a imagem ${event.imageName}`, this.deleteImg)
    },
    async deleteImg() {
      if(this.deleteImgFile.pathFirebase) await WaterScanStoragefrom.deleteImg(this.deleteImgFile.pathFirebase)
      if(this.solutionReport.imgNameList?.length)this.solutionReport.imgNameList = this.solutionReport.imgNameList.filter(( pathFirebase ) => pathFirebase != this.deleteImgFile.pathFirebase)
      this.listImgs = this.listImgs.filter(({ image }) => image != this.deleteImgFile.image)
    },
    optionTypeSelect(type_id) {
      return this.solutionsOptions.filter(({pointId}) => pointId == type_id)
    },
    async onFilePicked(e) {
      const inputFiles = document.getElementById("get_image").files;
      if (inputFiles[0] !== undefined && inputFiles[0].type.split("/")[0] == "image") {
        this.rawFiles.push(...document.getElementById("get_image").files)
        for (let file of inputFiles) {
          await this.processImg(file)
        }
      } else {
        showErrorSnackBar("Arquivo inválido.")
        this.editedItem.image = "";
        this.editedItem.imageName = "";
      }
    },
    async processImg(file) {
      try {
        this.editedItem = {}
        this.editedItem.imageName = file.name;
        if (this.editedItem.imageName.lastIndexOf(".") <= 0) reject("Nome Invalido")
        var output = document.getElementById('output');
        this.listImgs.unshift({
          image: URL.createObjectURL(file),
          imageName: file.name
        })

      } catch (err) {
        showErrorSnackBar("Erro ao processar imagem.")
      }

    },
    closeDialogBtn() {
      this.closeDialog();
      this.clearEditItem()
      this.loading.uploadImg = false
      this.$emit("status:save", false)
      this.solutionReport = {}
    },
    async save() {
      if (this.$refs.form && !this.$refs.form.validate()) return
      this.loading.uploadImg = true
      let savedFileNames = []
      for (let file of this.rawFiles) {
        savedFileNames.push(await WaterScanStoragefrom.uploadImg(this.idProposal, file))
      }
      if(this.solutionReport.imgNameList?.length){
        if(savedFileNames.length)this.solutionReport.imgNameList.push(...savedFileNames) 
      }
      else this.solutionReport.imgNameList = savedFileNames
      this.solutionListModel.set('imgNameList', [...this.solutionReport.imgNameList])
      this.saveFunc(this.solutionListModel.get());
      this.$emit("status:save", true)
      this.closeDialog();
    },
    async getStorageImages(listImgs) {
      for (let imgPath of listImgs) {
        const urlImg = await WaterScanStoragefrom.downloadImg(imgPath)

        const mainPathEnd = imgPath.indexOf('/')+1
        const matricula = imgPath.slice(mainPathEnd)
        const matriculaEnd = imgPath.slice(mainPathEnd).indexOf('/')+1
        const imgNameOrigin = matricula.slice(matriculaEnd+14)

       
        this.listImgs.unshift({
          image: urlImg,
          pathFirebase: imgPath,
          imageName: imgNameOrigin
        })
      }
    },
  },
  computed: {
    rulesRequired() {
      return Rules.required();
    },
    solutionsOptionsList() {
      return this.optionTypeSelect(this.solutionReport.type)
    },
  },
  watch: {
    'solutionReport.amountSolution': function (newVal) {
      if (newVal) this.solutionListModel.set('amountSolution', parseInt(newVal))
    },
    'solutionReport.economy': function (newVal) {
      if (newVal) this.solutionListModel.set('economy', parseFloat(newVal))
    },
    'solutionReport.flowRateAfter': function (newVal) {
      if (newVal) {
        this.solutionListModel.set('flowRateAfter', parseFloat(newVal))
        this.solutionListController.calculateEconomy()
        this.solutionReport.economy = this.solutionListModel.get('economy')
      }
    },
    'solutionReport.flowRateBefore': function (newVal) {
      if (newVal) {
        this.solutionListModel.set('flowRateBefore', parseFloat(newVal))
        this.solutionListController.calculateEconomy()
        this.solutionReport.economy = this.solutionListModel.get('economy')
      }
    },
    'solutionReport.imgNameList': function (newVal) {
      if (newVal) {
        this.solutionListModel.set('imgNameList', newVal)
      }
    },
    'solutionReport.local': function (newVal) {
      if (newVal) this.solutionListModel.set('local', newVal)
    },
    'solutionReport.sub_local': function (newVal) {
      if (newVal) this.solutionListModel.set('sub_local', newVal)
    },
    'solutionReport.type': function (newVal) {
      if (newVal) {
        this.solutionListModel.set('type', newVal)
        this.solutionListController.verifyPipingType()
        this.solutionReport.pipingType = this.solutionListModel.get('pipingType')
      }
    },
    'solutionReport.solution': function (newVal) {
      if (newVal) this.solutionListModel.set('solution', newVal)
    },
    'solutionReport.pipingType': function (newVal) {
      if (newVal) this.solutionListModel.set('pipingType', newVal)
    },
    'solutionReport.flowRateType': function (newVal) {
      if (newVal) this.solutionListModel.set('flowRateType', newVal)
    },
    'solutionReport.comments': function (newVal) {
      if (newVal) this.solutionListModel.set('comments', newVal)
    },
  },
  async created() {
    this.solutionListModel = new SolutionListModel()
    this.solutionListController = new SolutionListController(this.solutionListModel)
    this.solutionReport = this.solutionListModel.get()

    if (this.editableSolution.length) {
      let _solution = this.editableSolution[0]
      _solution = this.solutionListModel.checkAssigned(_solution, true)
      this.solutionListModel.set(_solution)
      this.solutionListController.calculateEconomy()
      this.solutionListController.verifyPipingType()
      this.solutionListController.verifyFlowRateType()
      this.solutionReport = this.solutionListModel.get()
      if (this.solutionReport.imgNameList?.length) await this.getStorageImages(this.solutionReport.imgNameList)
    } else {
      this.solutionListController.calculateEconomy()
      this.solutionListController.verifyPipingType()
      this.solutionListController.verifyFlowRateType()
      this.solutionReport = this.solutionListModel.get()
    }

  }
};
</script>../../../objects/Waterscan/ProposeTab/Solution