import { mapActions, mapGetters } from "vuex";
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import { showViewDialogAlert } from "@/utils/DialogAlertHelper";
import Utils from '@/scripts/Utils';
import Rules from "@/utils/Rules";

import ProjectsService from "@/services/server/ProjectsService.js";
import LeadsService from "@/services/server/LeadsService.js";
import UsersService from "@/services/server/UsersService";

import Propecting from "@/components/Projects/Prospecting/Prospecting.vue"

export default {
	name: "Projetos",

	components: {
		Propecting,
	},

	data: () => ({
		openProspection: false,
		newLead: {},
		dialog: false,
		is_created: false,
		is_loading: true,
		isValidForm: false,
		form: {
			project_name: null,
			lead_document: null,
			id_franchisee: null,
		},
		type_lead_documents: [
			'CPF',
			'CNPJ',
			'Matrícula',
		],
		type_lead_documents_masks: {
			'CPF': "###.###.###-##",
			'CNPJ': "##.###.###/####-##",
			'Matrícula': null,
		},
		type_lead_document_selected: 'CPF',
		headers: [
			{ text: 'Nome do Projeto', value: "project_name", },
			{ text: 'Cliente', value: 'lead_name', },
			{ text: 'Documento (CPF/CNPJ)', value: 'lead_document', },
			{ text: 'Data de Criação', value: 'created_at_formatted', },
			{ text: 'Ações', value: 'actions', },
		],
        items: [],
		searchField: '',
		searchOptions: [
			'Projeto',
			'Cliente',
			'Documento (CPF/CNPJ)'
		],
		searchOptionSelected: 'Cliente',
		franchisee_list: [],
		franchisee_selected: null,
		project_steps_list: [
			{
				id: 'prospecting',
				name: 'Prospecção',
			},
			{
				id: 'waterscan',
				name: 'Waterscan',
			},
			{
				id: 'approve',
				name: 'Aprovação',
			},
			{
				id: 'closing',
				name: 'Fechamento',
			},
			{
				id: 'contracts',
				name: 'Contratos',
			},
		],
		project_steps_selected: {
			id: 'general',
			name: 'Geral',
		},
		project_franchisee_list: [],
		steps_informations: {
			'id_lead': {
				step: 'prospecting',
				next_step: 'waterscan',
			},
			'id_waterscan': {
				step: 'waterscan',
				next_step: 'waterscan',
			},
			openProspecting: false
		},
		selectedChips: []
	}),

	computed: {
		...mapGetters([
			"getCurrentUserData",
			"getFranqueados",
		]),
		getItems() {
			let items = this.items
			let search = ''
			function filterFranchisee(id_franchisee) {
				if (id_franchisee == 'general') return
				items = items.filter(
					(item) => {
						return item.id_franchisee === id_franchisee
					}
				);
			}
			function filterProjectStep(steps) {
				items = items.filter((item) => steps.includes(item.next_step));
			}
			function searchProject(search, searchText) {
				searchText = Utils.defaultSpace(searchText)
				items = items.filter(
					(item) => {
						if (searchText == '') return true
						if (!item[search]) return false
						return Utils.defaultSpace(item[search]).toLowerCase().indexOf(searchText.toLowerCase()) > -1
					}
				);
			}
			filterFranchisee(this.franchisee_selected && this.franchisee_selected.id)
			filterProjectStep(this.selectedChips)
			switch (this.searchOptionSelected) {
				case 'Nome do Projeto':
					search = 'project_name'
					searchProject(search, this.searchField)
					break;
				case 'Cliente':
					search = 'lead_name'
					searchProject(search, this.searchField)
					break;
				case 'Documento (CPF/CNPJ)':
					search = 'lead_document'
					searchProject(search, this.searchField)
					break;
				default:
					searchProject(null, '')
			}
			return items
		},
	},

	methods: {
		...mapActions([
			"publishChannel",
		]),
		customSort(items, sortBy, sortDesc) {
			if (sortBy[0] === 'project_name') {
			  return items.sort((a, b) => {
				const nameA = a.project_name.toLowerCase();
				const nameB = b.project_name.toLowerCase();
				if (nameA < nameB) return sortDesc[0] ? 1 : -1;
				if (nameA > nameB) return sortDesc[0] ? -1 : 1;
				return 0;
			  });
			}
			else if (sortBy[0] === 'lead_name') {
			  return items.sort((a, b) => {
				const nameA = a.lead_name.toLowerCase();
				const nameB = b.lead_name.toLowerCase();
				if (nameA < nameB) return sortDesc[0] ? 1 : -1;
				if (nameA > nameB) return sortDesc[0] ? -1 : 1;
				return 0;
			  });
			}
			else if (sortBy[0] === 'lead_document') {
			  return items.sort((a, b) => {
				const nameA = a.lead_document.toLowerCase();
				const nameB = b.lead_document.toLowerCase();
				if (nameA < nameB) return sortDesc[0] ? 1 : -1;
				if (nameA > nameB) return sortDesc[0] ? -1 : 1;
				return 0;
			  });
			}
			else if (sortBy[0] === 'created_at_formatted') {
			  return items.sort((a, b) => {
				const nameA = new Date(a.created_at).getTime();
				const nameB = new Date(b.created_at).getTime();
				if (nameA < nameB) return sortDesc[0] ? 1 : -1;
				if (nameA > nameB) return sortDesc[0] ? -1 : 1;
				return 0;
			  });
			}
			return items;
		},
		openDialog() {
			this.dialog = true
			this.form = {
				project_name: null,
				lead_document: null,
				id_franchisee: null,
			}
			this.type_lead_document_selected = 'CPF'
		},
		closeDialog() {
			this.dialog = false
		},
		selectProjectTypeFilter(stepProject) {
			const index = this.selectedChips.indexOf(stepProject);
			if (index > -1) this.selectedChips.splice(index, 1)
			else this.selectedChips.push(stepProject);
		},
		isSelectedChip(id) {
			return this.selectedChips.includes(id);
		},
		closeFunc() {
			this.openProspection = false
		},
		async createProject() {
			if (!this.$refs.newProjectForm.validate()) return
			this.is_loading = true

			const [status, lead] = await LeadsService.searchLeadByDocument(
				this.form.lead_document,
				this.form.id_franchisee.id,
			)
			if (!status) {
				this.is_loading = false
				return
			}
			// Carregar o lead ou criar um novo
			
			if (!lead) {
				this.openProspection = true
				this.newLead = {
						id_franchisee: this.form.id_franchisee.id,
						lead_document: this.form.lead_document,
						project_name: this.form.project_name,
						type_lead_document_selected: this.type_lead_document_selected,
					}

				// this.publishChannel({
				// 	channel: 'projects',
				// 	event: 'leads:create',
				// 	value: newLead,
				// })
			} else {
				const project = this.getItems.find((({id_lead}) => id_lead === lead.id_doc))
				showViewDialogAlert(`Projeto em andamento para esse cliente, deseja visualizar?`,() => this.navigateProject(project))
			}
			this.is_loading = false
		},
		ruleRequired() {
			return Rules.required()
		},
		ruleString(isRequired = false, max = 64) {
			return Rules.string(isRequired, 1, max)
		},
		ruleCPF(isRequired) {
			return Rules.cpf(isRequired)
		},
		ruleCNPJ(isRequired) {
			return Rules.cnpj(isRequired)
		},
		navigateProject(item) {
			this.$router.push(`/projects/${item.id_doc}`)
		},
	},
	
	watch: {
		
	},

	async created() {
		this.selectedChips = this.project_steps_list.map(({id})=> id)

		let [status, projectList] = await ProjectsService.getProjects()
		projectList = projectList.map(
			(project) => {
				let created_at = new Date(project.created_at._seconds * 1000)
				return {
					...project,
					created_at,
					created_at_formatted: Utils.formatDate(created_at, '<dd>/<MM>/<YYYY>')
				}
			}
		)
		this.items = projectList
		let id_steps = Object.keys(this.steps_informations).reverse()
		this.items = this.items.map(
			(item) => {
				let current_id_step = null
				for (let id_step of id_steps) {
					if (item.hasOwnProperty(id_step)) {
						current_id_step = id_step
						break;
					}
				}
				if (current_id_step === null) return {
					...item,
					current_step: null,
					next_step: 'prospecting',
				}
				return {
					...item,
					current_step: this.steps_informations[current_id_step].step,
					next_step: this.steps_informations[current_id_step].next_step,

				}
			}
		)
		const response = await UsersService.findAllFranchisee()
		this.franchisee_list = []
		this.project_franchisee_list = []
		if (response.status == 200) {
			this.franchisee_list = [
				{
					id: 'general',
					name: 'Geral',
				},
				...response.data.data
			]
			this.project_franchisee_list = response.data.data
		} else {
			showErrorSnackBar("Ocorreu um erro na busca da lista de franqueados")
		}
		this.franchisee_list = this.franchisee_list.map(({ id, name }) => ({ id, name }))
		if (this.franchisee_list.length > 0) {
			this.franchisee_list = [
				{
					id: 'general',
					name: 'Geral',
				},
				...this.franchisee_list
			]
			this.franchisee_selected = {
				id: 'general',
				name: 'Geral',
			}
		}
		this.is_created = true
		this.is_loading = false
	},
};