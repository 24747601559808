<template>
    <div>
        <!-- start Investor Dialog -->
        <v-row justify="center">
            <v-dialog transition="dialog-top-transition" v-model="modal" persistent max-width="500px">
                <v-card>
                <v-form ref="downloadPdfForm" lazy-validation>
                    <v-toolbar color="primary" dark> <span class="text-h5">Adição de novo investidor</span></v-toolbar>
                    <v-card-text style="padding-bottom: 0px;">
                        <v-row>
                            <v-col>
                                <v-text-field 
                                    label="Nome"
                                    v-model="item.name"
                                    :rules="ruleName"
                                    @change="setName"
                                    @focus="setFocus"
                                    @blur="setBlur"
                                    @update:error="getNameError"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field 
                                    label="Participação do Lucro"
                                    type="number"
                                    v-model="item.participationProfit"
                                    :rules="ruleProfit"
                                    @change="setProfit"
                                    @focus="setFocus"
                                    @blur="setBlur"
                                    @update:error="getProfitError"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field 
                                    label="Participação do Custo"
                                    type="number"
                                    v-model="item.participationCost"
                                    :rules="ruleCost"
                                    @change="setCost"
                                    @focus="setFocus"
                                    @blur="setBlur"
                                    @update:error="getCostError"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="() => closeModal()" :loading="loading" :disabled="loading">
                            Cancelar
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="() => saveItem()" :loading="loading" :disabled="loading || hasDisabled">{{ saveButtonTxt }}</v-btn>
                    </v-card-actions> 
                </v-form>
                </v-card>
            </v-dialog>
        </v-row>
        <!-- end Investor Dialog -->

        <v-row>
          <!-- Start Table Solutions -->
          <v-data-table
            :headers="headers"
            :items="list"
            :items-per-page="12"
            class="elevation-1"
            style="width: 100%"
          >
            <template v-slot:top>
              <v-toolbar flat class="rounded-lg">
                <v-toolbar-title>Investidores</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn color="primary" class="mb-2" text @click="() => addNewItem()" :loading="loading" :disabled="loading">
                  <v-icon>add</v-icon>
                </v-btn>
              </v-toolbar>
            </template>
          
            <template v-slot:body="{ items }">
              <tbody>      
                <tr v-for="(investor, index) in items" :key="index">
                  <td style="display: flex">
                    <v-text-field
                      v-model="investor.name"
                      readonly
                    >
                    </v-text-field> 
                  </td>
                  <td>
                    <v-text-field
                      type="Number"
                      v-model="investor.participationProfit"
                      suffix="%"
                      readonly
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      type="Number"
                      v-model="investor.participationCost"
                      suffix="%"
                      readonly
                    >
                    </v-text-field>
                  </td>
                  <td>
                    <v-btn
                      elevation="2"
                      @click="() => editItem(index)"
                      style="height: 30px; margin: 2px"
                      :loading="loading" 
                      :disabled="loading"
                    >
                      <v-icon color="primary" small> edit </v-icon>
                    </v-btn>
                    <v-btn
                      elevation="2"
                      @click="() => deleteItem(index)"
                      style="height: 30px; margin: 2px"
                      :loading="loading" 
                      :disabled="loading"
                    >
                      <v-icon color="primary" small> mdi-delete </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <!-- End Table Solutions -->
        </v-row>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import WaterScanService from '@/services/firebase/WaterScan/WaterScanService';
import WaterFixService from '@/services/firebase/WaterFix/WaterFixService';
import { showInfoSnackBar, showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import Utils from "@/scripts/Utils";
import Rules from "@/utils/Rules";

export default {
    props: {
        proposalType: {
            type: String,
            require: true,
        },
        roiType: {
            type: String,
            require: true,
        },
        proposal: {
            type: Object,
            require: true,
        },
    },
    data: () => ({
        field: 'investors',
        key: null,
        id: null,
        headers: [
            { text: "Investidor", value: "name" },
            { text: "Participação do Lucro", value: "participationProfit" },
            { text: "Participação do Custo", value: "participationCost" },
            { text: "Ações ", value: "action", width: 180 },
        ],
        modal: false,
        loading: true,
        focus: false,
        blur: false,
        error: false,
        list: [],
        mode: 'create',
        headerTxt: 'Adição de Investidor',
        saveButtonTxt: 'Adicionar',
        indexEdit: null,
        list: [],
        item: {
            name: '',
            participationProfit: 1,
            participationCost: 1,
        },
        errors: {
            name: false,
            participationProfit: false,
            participationCost: false,
        },
        totalPercentageParticipationProfit: 0,
        totalPercentageParticipationCost: 0,
    }),
    computed: {
        ruleName() {
            return Rules.string(true, 1, 100)
        },
        ruleProfit() {
            return Rules.number(true, 1, 100)
        },
        ruleCost() {
            return Rules.number(true, 1, 100)
        },
        hasDisabled() {
            return Object.values(this.errors).reduce((prev, cur) => prev || cur, false) || this.focus
        },
        complementPercentageParticipationProfit() {
            return 100 - this.totalPercentageParticipationProfit
        },
        complementPercentageParticipationCost() {
            return 100 - this.totalPercentageParticipationCost
        },
    },
    methods: {
        ...mapActions([
            'publishChannel',
        ]),
        resetItem() {
            this.item = {
                name: '',
                participationProfit: 1,
                participationCost: 1,
            }
            this.errors = {
                name: false,
                participationProfit: false,
                participationCost: false,
            }
        },
        addNewItem() {
            this.resetItem()
            this.mode = 'create'
            this.headerTxt = 'Adição de Investidor'
            this.indexEdit = null
            this.saveButtonTxt = 'Adicionar'
            this.openModal()
        },
        openModal() {
            this.modal = true
        },
        closeModal() {
            this.modal = false
        },
        setLoading(status) {
            this.loading = status
            this.publishChannel({
                channel: 'roi',
                event: `${this.key}:loading`,
                value: status,
            })
        },
        setDisabled(status) {
            this.publishChannel({
                channel: 'roi',
                event: `${this.key}:disabled`,
                value: status,
            })
        },
        getNameError(error) {
            this.errors.name = error
        },
        getProfitError(error) {
            this.errors.participationProfit = error
        },
        getCostError(error) {
            this.errors.participationCost = error
        },
        setFocus(status) {
            this.focus = true
            this.blur = false
        },
        setBlur(status) {
            this.focus = false
            this.blur = true
        },
        setName(value) {
            this.item.name = value
        },
        setProfit(value) {
            this.item.participationProfit = value
        },
        setCost(value) {
            this.item.participationCost = value
        },
        forceUpdateList(newList) {
            this.list = Utils.copyObject(newList)
        },
        async saveItem() {
            if (this.error) return
            this.setLoading(true)
            if (this.mode == 'create') {
                if (this.complementPercentageParticipationProfit < this.item.participationProfit) {
                    showErrorSnackBar('O lucro total não pode exceder o 100%')
                    this.setLoading(false)
                    return
                }
                if (this.complementPercentageParticipationCost < this.item.participationCost) {
                    showErrorSnackBar('O custo total não pode exceder o 100%')
                    this.setLoading(false)
                    return
                }
                this.list.push(Utils.copyObject(this.item))
            } else {
                let _item = Utils.copyObject(this.item)
                let _currentProfit = parseFloat(this.list[this.indexEdit].participationProfit)
                let _totalPercentageParticipationProfit = this.totalPercentageParticipationProfit - _currentProfit + parseFloat(_item.participationProfit)
                if (_totalPercentageParticipationProfit > 100) {
                    showErrorSnackBar('O lucro total não pode exceder o 100%')
                    this.setLoading(false)
                    return
                }
                let _currentCost = parseFloat(this.list[this.indexEdit].participationCost)
                let _totalPercentageParticipationCost = this.totalPercentageParticipationCost - _currentCost + parseFloat(_item.participationCost)
                if (_totalPercentageParticipationCost > 100) {
                    showErrorSnackBar('O custo total não pode exceder o 100%')
                    this.setLoading(false)
                    return
                }
                this.list[this.indexEdit] = _item
            }
            this.forceUpdateList(this.list)
            try {
                if (this.proposalType == 'waterscan') {
                    await WaterScanService.updateById(this.key, Utils.copyObject(this.list), this.id)
                } else {
                    
                }
                this.emitList()
                this.setLoading(false)
                this.closeModal()
            } catch (e) {
                console.error(e)
                this.setLoading(false)
            }
        },
        async editItem(index) {
            this.resetItem()
            this.mode = 'edit'
            this.indexEdit = index
            this.headerTxt = 'Edição de Investidor'
            this.saveButtonTxt = 'Editar'
            this.item = Utils.copyObject(this.list[index])
            this.openModal()
        },
        async deleteItem(index) {
            this.setLoading(true)
            try {
                this.list.splice(index, 1)
                if (this.proposalType == 'waterscan') {
                    await WaterScanService.updateById(this.key, Utils.copyObject(this.list), this.id)
                } else {
                    
                }
                this.emitList()
                this.closeModal()
                this.setLoading(false)
            } catch (e) {
                console.error(e)
                this.setLoading(false)
            }
        },
        emitList() {
            this.publishChannel({
                channel: 'roi',
                event: this.key,
                val: Utils.copyObject(this.list),
            })
        },
    },
    watch: {
        async proposal(newValue) {
            if (newValue) {
                this.id = newValue.id
                try {
                    if (this.proposalType == 'waterscan') {
                        if (this.roiType == 'performance') {
                            this.key = this.field
                        } else if (this.roiType == 'sales') {
                            this.key = `${this.roiType}-roi.${this.field}`
                        } else {
                            throw new Error("Tipo do roi não identificado")
                        }
                        
                        this.list = []
                        try {
                            let needToSave = false
                            this.list = Utils.getting(this.proposal, this.key)
                            this.list = this.list.map(
                                (item) => {
                                    needToSave = needToSave || item.profit !== undefined
                                    let newItem = {
                                        ...item,
                                        participationProfit: parseFloat(item.profit ? item.profit : item.participationProfit),
                                        participationCost: parseFloat(item.participationCost ? item.participationCost : 0),
                                    }
                                    delete newItem["profit"]
                                    return newItem
                                }
                            )
                            if (needToSave) {
                                await WaterScanService.updateById(this.key, this.list, this.id)
                            }
                        } catch (e) {
                            this.list = []
                            await WaterScanService.updateById(this.key, this.list, this.id)
                        }
                    } else {
                        
                    }
                    this.emitList()
                    this.setLoading(false)
                } catch (e) {
                    console.error(e)
                    this.setLoading(false)
                }
            }
            this.setLoading(false)
        },
        hasDisabled(newVal) {
            if (newVal) {
                this.setDisabled(true)
            } else {
                this.setDisabled(false)
            }
        },
        list: {
            immediate: true,
            handler: async function (newValue) {
                if (Array.isArray(newValue)) {
                    this.totalPercentageParticipationProfit = newValue.reduce((pre, cur) => pre + parseFloat(cur.participationProfit), 0)
                    this.totalPercentageParticipationCost = newValue.reduce((pre, cur) => pre + parseFloat(cur.participationCost), 0)
                }
            }
        }
    },
}

</script>