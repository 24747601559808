<template>
    <div v-if="show">
        <v-form ref="form" v-model="validForm" lazy-validation>
            <v-col>
                <HeaderCheckList
                    title="Reservatórios"
                    :click="() => create()"
                />
            </v-col>
            <div v-for="_reservoir, i in list">
                <CardCheckList
                    :view="() => edit(i)"
                    :remove="() => remove(i)"
                >
                    <template v-slot:content>
                        <div style="
                            padding: 10px;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-around;
                        ">
                            <div>
                                <h3 style="
                                    font-size: 15px;
                                    color: black;
                                    text-align: center;
                                ">{{ _reservoir.localReservatorio }}</h3>
                            </div>
                            <div>
                                <h4 style="
                                    font-size: 12px;
                                    color: black;
                                    text-align: center;
                                ">{{ _reservoir.reservatorio }}</h4>
                            </div>
                            <div>
                                <h5 style="
                                    font-size: 12px;
                                    color: black;
                                    text-align: center;
                                ">{{ _reservoir.capacidade }} L</h5>
                            </div>
                        </div>
                    </template>
                </CardCheckList>
            </div>
            <v-dialog transition="dialog-top-transition" v-model="dialog" v-if="dialog" persistent max-width="600px">
                <v-card>
                    <v-toolbar color="primary" dark> <span class="text-h5">Adicionar Bomba</span></v-toolbar>
                    <v-card-title>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text style="padding-bottom: 0px;">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field 
                                    v-model="reservoir.localReservatorio" 
                                    outlined 
                                    label="Local" 
                                    type="text" 
                                    required
                                    :rules="rulesRequired"
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-select 
                                    v-model="reservoir.reservatorio" 
                                    outlined 
                                    label="Reservatorio" 
                                    :items="optionsReservoir"
                                    prepend-inner-icon="mdi-cup-water" 
                                    required 
                                    :rules="rulesRequired"
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field 
                                    v-model="reservoir.capacidade" 
                                    outlined 
                                    label="Capacidade" 
                                    prefix="L" 
                                    type="Number" 
                                    required
                                    :rules="rulesRequired"
                                />
                            </v-col>
                        </v-row>
                        <!-- <InputImg v-on:rawFiles="rawFiles = $event"/> -->
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDialog()">
                        Cancelar
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="save()"> Salvar </v-btn>
                    </v-card-actions>
                </v-card>
              </v-dialog>
        </v-form>
    </div>
</template>
  
<script>
import HeaderCheckList from "@/components/Projects/Diagnostic/Tabs/CheckList/HeaderCheckList.vue"
import CardCheckList from "@/components/Projects/Diagnostic/Tabs/CheckList/CardCheckList.vue"

import Rules from "@/utils/Rules";
import Utils from '@/scripts/Utils';

const newReservoir = () => {
    return {
        localReservatorio: "",
        reservatorio: "",
        capacidade: "",
    }
}

export default {
    name: "ReservoirList",
    
    components: {
        HeaderCheckList,
        CardCheckList,
    },
    
    props: {
        show: {
            type: Boolean,
            default: true
        },
        list: {
            type: Array,
            default: () => ([]),
        },
        add: {
            type: Function,
            default: (hydrometer, index) => {}
        },
        remove: {
            type: Function,
            default: (index) => {}
        },
    },
    
    data: () => ({
        validForm: true,
        dialog: false,
        index: -1,
        reservoir: newReservoir(),
        optionsReservoir: ["Cisterna", "Caixa d'água"],
    }),

    computed: {
        rulesRequired() {
            return Rules.required();
        },
    },

    methods: {
        create() {
            this.index = -1
            this.reservoir = newReservoir()
            this.dialog = true
        },
        edit(index) {
            this.index = index
            this.reservoir = Utils.copyObject(this.list[index])
            this.dialog = true
        },
        save() {
            this.add(Utils.copyObject(this.reservoir), this.index)
            this.dialog = false
        },
        closeDialog() {
            this.dialog = false
        },
    },

    watch: {
        
    },

};
</script>
  
<style scoped>

</style>