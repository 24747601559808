<template>
  <v-form 
    ref="form" 
    v-model="validForm" 
    lazy-validation
  >
    <v-row justify="center">
      <v-dialog 
          transition="dialog-top-transition" 
          v-model="dialog" 
          v-if="dialog" 
          persistent 
          max-width="700px"
        >
        <v-card style="background-color: white !important;">
          <v-toolbar color="warning" dark> <span class="text-h5">Edição em Lote</span></v-toolbar>
          <v-card-title style="background-color: rgb(251, 140, 0, .2);">
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text style="padding-bottom: 0px;background-color: rgb(251, 140, 0, .2);">
            <v-row>
              <h3> Informações do Ponto</h3>
            </v-row>
            <v-row>
              <v-col cols="12" :sm="6">
                <LocalField 
                  :proposal="proposal"
                  @change:input="(value) => changeLocal(value)"
                  :noRule="true"
                  />
              </v-col>
              <v-col cols="12" :sm="6">
                <SublocalField 
                  :proposal="proposal"
                  @change:input="(value) => changeSublocal(value)"
                  :noRule="true"
                  />
              </v-col>
              <v-col cols="12" :sm="4">
                <v-autocomplete label="Tipo" 
                  :items="pointsOptions" 
                  item-text="name"
                  item-value="id" 
                  outlined 
                  v-model="solutionReport.type"
                  @change="optionTypeSelect(solutionReport.type)" :rules="rulesRequired" onfocus>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" :sm="4">
                <v-autocomplete label="Soluções" 
                  :items="solutionsOptionsList" 
                  v-model="solutionReport.solution" 
                  outlined
                  item-text="solution"
                  item-value="id"
                  :disabled="solutionReport.type === null" :rules="rulesRequired">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" :sm="4">
                <v-text-field v-model="solutionReport.amountSolution" outlined label="Qtnd." type="Number" required
                  :rules="rulesRequired">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" :sm="4">
                <v-text-field v-model="solutionReport.flowRateBefore" outlined label="Vazão antes" type="Number"
                  suffix="L/min" required :rules="rulesRequired">
                </v-text-field>
              </v-col>
              <v-col cols="12" :sm="4">
                <v-text-field v-model="solutionReport.flowRateAfter" outlined label="Vazão Depois" type="Number"
                  suffix="L/min" required :rules="rulesRequired">
                </v-text-field>
              </v-col>
              <v-col cols="12" :sm="4">
                <v-text-field v-model="percentageEconomy" outlined label="Economia" readonly suffix="%">
                </v-text-field>
              </v-col>
              <v-col cols="12" :sm="6">
                <DateField
                  :noRule="true"
                  @change:input="(value) => changeDate(value)"
                />
              </v-col>
              <v-col cols="12" :sm="6">
                <v-select
                  :items="statusOptions"
                  label="Status"
                  outlined
                  v-model="solutionReport.status"
                  :rules="rulesRequired"
                ></v-select>
              </v-col>
              <v-col cols="12" :sm="12">
                <v-textarea v-model="solutionReport.comments" outlined label="Observação" type="text">
                </v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions style="background-color: rgb(251, 140, 0, .2);">
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeModal()">
              Cancelar
            </v-btn>
            <v-btn  color="blue darken-1" text @click="saveModal()" :disabled="isDisabledSave"> Salvar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import Rules from "@/utils/Rules";
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper";
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import ObjectValidator from '@/scripts/ObjectValidator';
import Utils from '@/scripts/Utils';
import LocalField from "@/components/Waterfix/Fields/LocalField.vue";
import SublocalField from "@/components/Waterfix/Fields/SublocalField.vue";
import DateField from "@/components/Waterfix/Fields/DateField.vue";

export default {
  components: {
    LocalField,
    SublocalField,
    DateField,
  },
  props: {
    dialog: {
      default: false,
      type: Boolean,
    },
    proposalId: {
      type: String,
      required: true,
    },
    pointsOptions: {
      type: Array,
      required: true,
      default: () => [],
    },
    solutionsOptions: {
      type: Array,
      required: true,
      default: () => [],
    },
    proposal: {
      required: true,
    },
  },

  data: () => ({
    validForm: true,
    solutionReport: ObjectValidator.create('waterfix_checklist_solution'),
    optionsType: [
      { text: "Torneira", value: "faucet" },
      { text: "Chuveiro", value: "shower" },
      { text: "Descarga", value: "discharge" },
    ],
    statusOptions: [
      'Instalado',
      'Não se Instala',
      'Não Autorizado',
      'Desativado',
      'Pendente',
      'Revisado',
    ],
  }),
  methods: {
    ...mapActions([
        'publishChannel',
    ]),
    changeLocal(local) {
      this.solutionReport.local = local.text
    },
    changeSublocal(sublocal) {
      this.solutionReport.sub_local = sublocal.text
    },
    changeDate(date) {
      this.solutionReport.date = date
    },
    optionTypeSelect(type_id) {
      return this.solutionsOptions.filter(({pointId}) => pointId == type_id)
    },
    resetSolutionReport() {
      this.solutionReport = ObjectValidator.create('waterfix_checklist_solution');
    },
    publishSolutions() {
        this.publishChannel({
            channel: 'waterfix',
            event: `batch-solutions`,
            value: Utils.copyObject(this.solutionReport),
        })
        this.publishCloseModal()
    },
    publishCloseModal() {
        this.publishChannel({
            channel: 'waterfix',
            event: `batch-solutions:modal`,
            value: false,
        })
        this.resetSolutionReport()
    },
    saveModal() {
        this.publishSolutions()
    },
    closeModal() {
        this.publishCloseModal()
    },
    changeLocal(local) {
      this.solutionReport.local = local.text
    },
    changeSublocal(sublocal) {
      this.solutionReport.sub_local = sublocal.text
    },
  },
  computed: {
    rulesRequired() {
      return Rules.required();
    },
    solutionsOptionsList() {
      return this.optionTypeSelect(this.solutionReport.type)
    },
    percentageEconomy() {
      let percentage =
        (+this.solutionReport.flowRateAfter * 100) /
        +this.solutionReport.flowRateBefore - 100;
      percentage = percentage < 0 ? Math.abs(percentage).toFixed(2) : 0;
      this.solutionReport.economy = percentage
      return percentage
    },
    isDisabledSave() {
      return (
        this.solutionReport.amountSolution === null &&
        this.solutionReport.date === null &&
        this.solutionReport.comments === null &&
        this.solutionReport.flowRateAfter === null &&
        this.solutionReport.flowRateBefore === null &&
        this.solutionReport.local === null &&
        this.solutionReport.solution === null &&
        this.solutionReport.status === null &&
        this.solutionReport.sub_local === null &&
        this.solutionReport.type === null
      )
    }
  },
  watch: {
    
  },
  async created() {
    this.resetSolutionReport()
  }
};
</script>