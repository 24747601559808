<template>
  <ChooseRoute 
    :routes="optionsSettings"
  />
</template>
<script>
import ChooseRoute from "@/components/ChooseRoute/ChooseRoute.vue";
import { RoutesPath } from '@/router/paths.js';
import {  SubMenuFeatures } from "@/router/features.js";
export default {
  name: "Settings",
  components:{
    ChooseRoute
  },
  data() {
    return {
      optionsSettings: [
        {
          name: "Relatório de Economia",
          feature: SubMenuFeatures.ECONOMY_REPORT,
          icon: "mdi-podium-silver",
          path: RoutesPath.REPORT_ECONOMY,
        },
        {
          name: "Relatório de Chamados",
          feature: SubMenuFeatures.CALLED_REPORT,
          icon: "mdi-access-point-network",
          path: RoutesPath.REPORT_CALLED,
        },
        {
          name: "Relatório de Alertas",
          feature: SubMenuFeatures.ALERTS_REPORT,
          icon: "warning",
          path: RoutesPath.REPORT_ALERT,
        },
        // {
        //   name: "Relatório de Telemetria",
        //   feature: SubMenuFeatures.TELEMETRY_REPORT,// alterar
        //   icon: "mdi-satellite-uplink",
        //   path: RoutesPath.REPORT_TELEMETRY,
        // },
      ],
    };
  },
};
</script>