<template>
  <v-form ref="form" v-model="validForm" lazy-validation>
    <v-row justify="center">
      <v-dialog transition="dialog-top-transition" v-model="dialog" v-if="dialog" persistent max-width="600px">
        <v-card>
          <v-toolbar color="primary" dark> <span class="text-h5">Adicionar Bomba</span></v-toolbar>
          <v-card-title>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text style="padding-bottom: 0px;">
            <v-row @keyup="(e) => trigger(e)">
              <v-col :cols="cols">
                <v-text-field v-model="bomb.locale" outlined label="Local" type="text" required :rules="rulesRequired">
                </v-text-field>
              </v-col>
              <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
                <v-select v-model="bomb.reservoir" outlined label="Reservatorio" :items="optionsBomb"
                  prepend-inner-icon="mdi-cup-water" required :rules="rulesRequired">
                </v-select>
              </v-col>
              <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
                <v-text-field outlined label="Potênca" prefix="W" type="Number" v-model="bomb.watt"
                  prepend-inner-icon="mdi-lightning-bolt-outline" required :rules="rulesRequired">
                </v-text-field>
              </v-col>
              <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
                <v-text-field v-model="bomb.hour_day_use" outlined label="Horas Uso/Dia" type="Number"
                  prepend-inner-icon="mdi-clock-time-five-outline" required :rules="rulesNumber">
                </v-text-field>
              </v-col>
              <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
                <v-text-field v-model="bomb.value_kwh" outlined label="Valor do Kw/h" prefix="R$ " type="Number" required
                  :rules="rulesRequired">
                </v-text-field>
              </v-col>
            </v-row>
            <InputImg v-on:rawFiles="rawFiles = $event"/>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog()">
              Cancelar
            </v-btn>
            <v-btn color="blue darken-1" text @click="save()"> Salvar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-form>
</template>

<script>
import Rules from "@/utils/Rules";
import InputImg from "@/components/Forms/Input/input-img";
export default {
  components: {
    InputImg
  },
  props: {
    dialog: {
      default: false,
      type: Boolean,
    },
    saveFunc: {},
  },

  data: () => ({
    cols: "12",
    mdApt: "6",
    lgApt: "6",
    xlApt: "6",
    smApt: "6",
    validForm: true,
    bomb: {
      locale: null,
      reservoir: null,
      watt: null,
      hour_day_use: null,
      value_kwh: null
    },
    optionsBomb: ["Poço", "Recalque"],
    rawFiles: []
  }),
  methods: {
    trigger(e) {
      switch (e.key) {
        case "Enter":
          this.save()
          break;
        case "Escape":
          this.closeDialog()
          break;
      }
    },
    closeDialog() {
      this.saveFunc("bomb", false);
      this.bomb = {
        locale: null,
        reservoir: null,
        watt: null,
        hour_day_use: null,
        value_kwh: null
      }
      this.$emit("status:save", false)
    },

    async save() {
      if (this.$refs.form && !this.$refs.form.validate()) return
      this.bomb.imgList = this.rawFiles
      this.saveFunc("bomb", false, this.bomb);
      this.bomb = {
        locale: null,
        reservoir: null,
        watt: null,
        hour_day_use: null,
        value_kwh: null
      }
      this.$emit("status:save", true)
    },
  },
  computed: {
    rulesRequired() {
      return Rules.required();
    },
    rulesNumber() {
      return Rules.number(true, 0, 24);
    },
  },
  watch: {
    rawFiles() {},
  },
};
</script>