<template>
  <div style="position: relative;display: block;">
        <h3 style="position:absolute; top: 15px;left: 15px;"> 
        Alertas em Detalhes </h3>
      <canvas id="Newbar"></canvas>
      </div>
  </template>
  <script>
  import Chart from "chart.js";
  export default {
    props: {
      labels: {
        type: Array,
      },
      datasets: {
        type: Array,
      },
      amount: {
        type: Number,
        default: 0,
      },
    },
    data() {
    return {
      myChart: null,
      defaultData: {
        labels: [''],
        datasets: [
          {
            data: [1],
            color: "white",
            backgroundColor: [ "rgb(200 200, 200,50)"],
            hoverOffset: 4,
            borderWidth: 0
          },
        ],
      },
    };
  },
  mounted() {
    const ctx2 = document.getElementById("Newbar");
    Chart.pluginService.register({
      beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
          // Get ctx from string
          var ctx = chart.chart.ctx;

          // Get options from the center object in options
          var centerConfig = chart.config.options.elements.center;
          var fontStyle = centerConfig.fontStyle || "Arial";
          var txt = centerConfig.text;
          var color = centerConfig.color || "#000";
          var maxFontSize = centerConfig.maxFontSize || 75;
          var sidePadding = centerConfig.sidePadding || 20;
          var sidePaddingCalculated =
            (sidePadding / 100) * (chart.innerRadius * 2);
          // Start with a base font of 30px
          ctx.font = "30px " + fontStyle;

          // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
          var stringWidth = ctx.measureText(txt).width;
          var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

          // Find out how much the font can grow in width.
          var widthRatio = elementWidth / stringWidth;
          var newFontSize = Math.floor(30 * widthRatio);
          var elementHeight = chart.innerRadius * 2;

          // Pick a new font size so it will not be larger than the height of label.
          var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
          var minFontSize = centerConfig.minFontSize;
          var lineHeight = centerConfig.lineHeight || 25;
          var wrapText = false;

          if (minFontSize === undefined) {
            minFontSize = 20;
          }

          if (minFontSize && fontSizeToUse < minFontSize) {
            fontSizeToUse = minFontSize;
            wrapText = true;
          }

          // Set font settings to draw it correctly.
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
          var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
          ctx.font = fontSizeToUse + "px " + fontStyle;
          ctx.fillStyle = color;

          if (!wrapText) {
            ctx.fillText(txt, centerX, centerY);
            return;
          }

          var words = txt.split(" ");
          var line = "";
          var lines = [];

          // Break words up into multiple lines if necessary
          for (var n = 0; n < words.length; n++) {
            var testLine = line + words[n] + " ";
            var metrics = ctx.measureText(testLine);
            var testWidth = metrics.width;
            if (testWidth > elementWidth && n > 0) {
              lines.push(line);
              line = words[n] + " ";
            } else {
              line = testLine;
            }
          }

          // Move the center up depending on line height and number of lines
          centerY -= (lines.length / 2) * lineHeight;

          for (var n = 0; n < lines.length; n++) {
            ctx.fillText(lines[n], centerX, centerY);
            centerY += lineHeight;
          }
          //Draw text in center
          ctx.fillText(line, centerX, centerY);
        }
      },
    });
    this.myChart = new Chart(ctx2, {
      type: "doughnut",
      data: {
        labels: [''],
        datasets: [
          {
            data: [1],
            color: "white",
            backgroundColor: [ "rgb(200 200, 200,50)"],
            hoverOffset: 4,
            borderWidth: 0
          },
        ],
      },
      options: {
        plugins: {
          datalabels: {
            color: "rgb(200 200, 200)",  
            labels: {
              title: {
                font: {
                  size: 15,
                  weight: "bold",
                },
                  weight: "bold",          
                  formatter: (val, ctx) => (ctx.chart.data.labels[ctx.dataIndex]+ ' \n' + ctx.chart.data.datasets[0].data[ctx.dataIndex]),
                  // formatter: (val, ctx) => (),
              },              
            },
          },
        },
        scaleShowValues: true,
        title: {
          display: false,
          text: "Alertas Detalhes",
        },
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          center: {
            text: "Total",
            color: "#3A70AE", // Default is #000000
            fontStyle: "Roboto", // Default is Arial
            sidePadding: 20, // Default is 20 (as a percentage)
            minFontSize: 25, // Default is 20 (in px), set to false and text will not wrap.
            lineHeight: 25, // Default is 25 (in px), used for when text wraps
          },
        },
        datalabels: {
          color: "white",
          display: true,
          font: {
            size: 15,
            weight: "bold",
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },       
        legend: {
          display: false,
        },
      },
    });
  },
  watch: {
    datasets(newVaue){
      if(this.datasets){
        if(this.amount === 0 ){
          this.myChart.options.plugins.datalabels.color = 'white'
        }else{
          this.myChart.options.plugins.datalabels.color = '#000000'
        }
        this.myChart.data.labels = this.labels
        let backgroundColor = [
          "rgb(56, 116, 204)",
          "rgb(31, 104, 14)",
          "rgb(33, 79, 127)",
          "rgb(68, 139, 52)",
          "rgb(101, 101, 101)"
        ]
        this.myChart.data.datasets[0].backgroundColor = backgroundColor
        this.myChart.data.datasets[0].data = this.datasets
        this.myChart.options.elements.center.text = 'Total '+ this.amount
        this.myChart.update();
      }
    },
    amount(newValue) {
      if (newValue !== null) {
        this.myChart.options.elements.center.text = 'Total '+ newValue
        this.myChart.update();
      }
    }
  },
  computed: {},
};
</script>