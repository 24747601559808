import Utils from '@/scripts/Utils';

const Waterfix = {

    state: {
        waterfix: {
            // proposalUpdatedFlags: {},
            subscribers: {
                'checklist-local:update': [],
                'checklist-sublocal:update': [],
            },
            historicUpdated: [],
            openProposals: [],
            locals: [],
            sublocals: [],
        }
    },

    actions: {
        // setWaterfixProposalUpdatedFlags({ state, commit }, obj) {

        // },
        subscriberWaterfix({ state, commit }, obj) {
            let channel = obj.channel
            let fn = obj.fn
            if (!state.waterfix.subscribers.hasOwnProperty(channel)) {
                throw new Error("O canal não foi encontrado para subscrever")
            }
            let functions = state.waterfix.subscribers[channel]
            functions.push(fn)
            let _key = `waterfix.subscribers.${channel}`
            commit('SET_VALUE', {
                _key: functions
            })
        },
        setWateFixProposal({ state, commit }, proposal) {
            let waterfixOpenProposals = state.waterfix.openProposals
            let index = waterfixOpenProposals.findIndex((item) => item.id === proposal.id)
            if (index >= 0) {
              waterfixOpenProposals[index] = proposal
            } else {
              waterfixOpenProposals.push(proposal)
            }
            commit('SET_VALUE', {
              'waterfix.openProposals': waterfixOpenProposals
            })
        },
        /**
         * 
         * @param {String} obj.id = indica o id da proposta
         * @param {Object|Array} obj.items = indica um local ou uma lista de locais
         */
        setWateFixLocals({ state, commit }, obj) {
            let id = obj.id
            let items = !Array.isArray(obj.items) ? [obj.items] : obj.items
            let waterfixLocals = state.waterfix.locals
            let index = waterfixLocals.findIndex((item) => item.id === id)
            if (index >= 0) {
                let currentItems = waterfixLocals[index].items
                items.forEach(
                    (item) => {
                        let itemIndex = currentItems.findIndex((item2) => item2.id == item.id)
                        if (itemIndex >= 0) {
                            currentItems[itemIndex] = item
                        } else {
                            currentItems.push(item)
                        }
                    }
                )
                waterfixLocals[index] = {id, items: currentItems}
            } else {
                items = Utils.removeDuplicatesCustom(items, ['id'])
                waterfixLocals.push({id, items})
            }
            commit('SET_VALUE', {
                'waterfix.locals': waterfixLocals
            })
        },
        /**
         * 
         * @param {String} obj.id = indica o id da proposta
         * @param {String|Array} obj.locals = indica um local ou uma lista de locais
         */
        initWaterFixLocals({ dispatch }, obj) {
            let id = obj.id
            let locals = typeof(obj.locals) == 'string' ? [obj.locals] : obj.locals
            let items = locals.map(
                (text) => {
                    return {
                        id: Utils.createSlug(text),
                        text
                    }
                }
            )
            dispatch('setWateFixLocals', {id, items})
        },
        async updateWaterfixLocal({ state, commit }, obj) {
            let id = obj.id
            let beforeItemId = obj.beforeItemId
            let afterItemId = obj.afterItemId
            let text = obj.text
            let waterfixLocals = state.waterfix.locals
            let index = waterfixLocals.findIndex((item) => item.id === id)
            if (index < 0) return;
            let items = waterfixLocals[index].items
            // Buscar o índice do item
            let itemIndex = items.findIndex((item) => item.id === beforeItemId)
            let item = null;
            let param = []
            if (itemIndex >= 0) {
                param.push(beforeItemId)
                param.push(afterItemId)
                item = items[itemIndex]
                item.id = afterItemId
            } else {
                itemIndex = items.findIndex((item) => item.id === afterItemId)
                if (itemIndex < 0) return;
                item = items[itemIndex]
                param.push(afterItemId)
                param.push(afterItemId)
            }
            item.text = text
            param.push(text)
            items[itemIndex] = item
            waterfixLocals[index].items = items
            // Rodar as funções subscribers
            let localFunctions = state.waterfix.subscribers['checklist-local:update']
            for (let i = 0; i < localFunctions.length; i++) {
                await localFunctions[i](...param)
            }
            commit('SET_VALUE', {
                'waterfix.locals': waterfixLocals,
            })
        },
        getWaterfixLocal({ state }, obj) {
            let id = obj.id
            let itemId = obj.itemId
            let waterfixLocals = state.waterfix.locals
            let index = waterfixLocals.findIndex((item) => item.id === id)
            if (index < 0) return null;
            let item = waterfixLocals[index].items.find((local) => local.id === itemId)
            if (!item) return null;
            return Utils.copyObject(item);
        },
        // --------------------------------------------------------------------------------------
        /**
         * 
         * @param {String} obj.id = indica o id da proposta
         * @param {Object|Array} obj.items = indica um local ou uma lista de locais
         */
        setWateFixSublocals({ state, commit }, obj) {
            let id = obj.id
            let items = !Array.isArray(obj.items) ? [obj.items] : obj.items
            let waterfixSublocals = state.waterfix.sublocals
            let index = waterfixSublocals.findIndex((item) => item.id === id)
            if (index >= 0) {
                let currentItems = waterfixSublocals[index].items
                items.forEach(
                    (item) => {
                        let itemIndex = currentItems.findIndex((item2) => item2.id === item.id)
                        if (itemIndex >= 0) {
                            currentItems[itemIndex] = item
                        } else {
                            currentItems.push(item)
                        }
                    }
                )
                waterfixSublocals[index] = {id, items: currentItems}
            } else {
                items = Utils.removeDuplicatesCustom(items, ['id'])
                waterfixSublocals.push({id, items})
            }
            commit('SET_VALUE', {
                'waterfix.sublocals': waterfixSublocals
            })
        },
        /**
         * 
         * @param {String} obj.id = indica o id da proposta
         * @param {String|Array} obj.locals = indica um local ou uma lista de locais
         */
        initWaterFixSublocals({ dispatch }, obj) {
            let id = obj.id
            let sublocals = typeof(obj.sublocals) == 'string' ? [obj.sublocals] : obj.sublocals
            let items = sublocals.map(
                (text) => {
                    return {
                        id: Utils.createSlug(text),
                        text
                    }
                }
            )
            dispatch('setWateFixSublocals', {id, items})
        },
        async updateWaterfixSublocal({ state, commit }, obj) {
            let id = obj.id
            let beforeItemId = obj.beforeItemId
            let afterItemId = obj.afterItemId
            let text = obj.text
            let waterfixSublocals = state.waterfix.sublocals
            let index = waterfixSublocals.findIndex((item) => item.id === id)
            if (index < 0) return;
            let items = waterfixSublocals[index].items
            // Buscar o índice do item
            let itemIndex = items.findIndex((item) => item.id === beforeItemId)
            let item = null;
            let param = []
            if (itemIndex >= 0) {
                param.push(beforeItemId)
                param.push(afterItemId)
                item = items[itemIndex]
                item.id = afterItemId
            } else {
                itemIndex = items.findIndex((item) => item.id === afterItemId)
                if (itemIndex < 0) return;
                item = items[itemIndex]
                param.push(afterItemId)
                param.push(afterItemId)
            }
            item.text = text
            param.push(text)
            items[itemIndex] = item
            waterfixSublocals[index].items = items
            let sublocalFunctions = state.waterfix.subscribers['checklist-sublocal:update']
            for (let i = 0; i < sublocalFunctions.length; i++) {
                await sublocalFunctions[i](...param)
            }
            commit('SET_VALUE', {
                'waterfix.sublocals': waterfixSublocals
            })
        },
        getWaterfixSublocal({ state }, obj) {
            let id = obj.id
            let itemId = obj.itemId
            let waterfixSublocals = state.waterfix.sublocals
            let index = waterfixSublocals.findIndex((item) => item.id === id)
            if (index < 0) return null;
            let item = waterfixSublocals[index].items.find((sublocal) => sublocal.id === itemId)
            if (!item) return null;
            return Utils.copyObject(item);
        },
    },

    getters: {
        // WaterfixConsumption: (state) => state.unit_economy.consumption,
        waterfixOpenProposals: state => state.waterfix.openProposals,
        waterfixLocals: state => state.waterfix.locals,
        waterfixSublocals: state => state.waterfix.sublocals,
    },

}

export default Waterfix;