<template>
    <div
        style="
            width: 100%;
            position: absolute;
            z-index: 100
        "
    >
        <v-stepper v-model="model" non-linear>
            <v-stepper-header>
                <template v-for="step, i in steps">
                    <v-stepper-step 
                        :key="i"
                        :editable="step.editable"
                        :complete="step.complete"
                        :step="step.step"
                        :rules="step.rules"
                    >
                        {{ step.name }}
                    </v-stepper-step>
          
                    <v-divider
                        v-if="i !== (steps.length - 1)"
                        :key="`${i}-divider`"
                    ></v-divider>
                </template>
            </v-stepper-header>
        </v-stepper>
    </div>
</template>
  
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"

export default {
    name: "DiagnosticStepper",
    
    components: {
        
    },
    
    props: {
        steps: {
            type: Array,
            required: false,
            default: () => ([])
        },
    },
    
    data: () => ({
        model: null,
    }),

    computed: {

    },

    methods: {
        
    },

    watch: {
        steps: {
            immediate: true,
            handler: function (newVal) {
                if (Array.isArray(newVal)) {
                    if (newVal.length > 0) {
                        this.model = newVal[0].step
                    }
                }
            }
        },
        model: function (newVal) {
            if (newVal) {
                this.$emit('changeTab', this.model)
            }
        },
    },

};
</script>
  
<style scoped>

</style>