<template>
  <v-row justify="center" v-if="openModal">
    <v-dialog v-model="openModal" fullscreen persistent no-click-animation hide-overlay
      transition="dialog-bottom-transition">
      <v-form ref="propectingForm" v-model="isValidForm" lazy-validation>
        <v-card style="
              height: 100%;
            ">
          <v-toolbar dark color="primary" style="
                height: 64px;
                position: fixed;
                width: 100%;
                z-index: 1000;
                border-radius: 0 0 10px 10px;
              ">
            <v-btn icon dark @click="closeProspectingModal" :loading="is_loading" :disabled="is_loading">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Prospecção</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="() => {
                save_draft()
              }" :loading="is_loading" :disabled="is_loading">
                Salvar
              </v-btn>
              <v-dialog v-model="dialog" max-width="320" persistent>
                <v-list class="py-5" color="primary" elevation="12" rounded="lg">
                  <v-list-item class="d-flex justify-space-between">
                    <div>
                      <span style="color: white; font-size: large;">Salvando prospecção...</span>
                    </div>
                    <div>
                      <v-progress-circular color="#1A237E" indeterminate size="32" width="2"></v-progress-circular>
                    </div>
                  </v-list-item>
                </v-list>
              </v-dialog>

            </v-toolbar-items>
          </v-toolbar>
          <div style="
                padding-top: 64px;
                padding-bottom: 86px;
              ">
            <v-row>
              <v-col cols="12">
                <div style="
                      height: 30px;
                      display: flex;
                      align-items: center;
                    "><span style="
                      font-size: 16px;
                      font-weight: 500;
                    ">Dados do Cliente</span></div>
              </v-col>

              <v-col cols="2">
                <v-radio-group v-model="radioDocument" :disabled="is_new_project">
                  <v-radio v-for="option in radioDocumentOptions" :key="option" :label="option"
                    :value="option"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="prospecting.document" :label="`Digite o ${radioDocument} do Cliente`" required
                  :rules="radioDocument == 'CPF' ? ruleCPF(true) : ruleCNPJ(true)" :loading="is_loading"
                  :disabled="is_loading || is_new_project"
                  v-mask="radioDocument == 'CPF' ? '###.###.###-##' : '##.###.###/####-##'"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="prospecting.name" label="Nome do Cliente" :counter="64" required
                  :rules="ruleString(true, 64)" :loading="is_loading" :disabled="is_loading"></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field v-model="prospecting.email" label="Email do Cliente" :counter="64"
                  :required="!is_draft_mode" :rules="is_draft_mode ? [] : ruleEmail(true)" :loading="is_loading"
                  :disabled="is_loading"></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-text-field v-model="prospecting.phone" label="Telefone do Cliente" :required="!is_draft_mode"
                  :rules="is_draft_mode ? [] : ruleRequired()" :loading="is_loading" :disabled="is_loading"
                  v-mask="'(##) # ####-####'"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" style="padding: 0;">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <div style="
                      height: 30px;
                      display: flex;
                      align-items: center;
                    "><span style="
                      font-size: 16px;
                      font-weight: 500;
                    ">Endereço</span></div>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="prospecting.zip_code" label="CEP" :required="!is_draft_mode"
                  :rules="is_draft_mode ? [] : ruleCEP(true)" :loading="is_loading" :disabled="is_loading"
                  v-mask="'##.###-###'"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="prospecting.address" label="Logradouro" :required="!is_draft_mode"
                  :rules="is_draft_mode ? [] : ruleString(true, 128)" :counter="128" :loading="is_loading"
                  :disabled="is_loading"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="prospecting.house_number" label="Número" :required="!is_draft_mode" :counter="16"
                  :rules="is_draft_mode ? [] : ruleString(true, 16)" :loading="is_loading"
                  :disabled="is_loading"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="prospecting.address_complement" label="Complemento" :required="!is_draft_mode"
                  :counter="64" :rules="is_draft_mode ? [] : ruleString(true, 64)" :loading="is_loading"
                  :disabled="is_loading"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="prospecting.district" label="Bairro" :required="!is_draft_mode" :counter="64"
                  :rules="is_draft_mode ? [] : ruleString(true, 64)" :loading="is_loading"
                  :disabled="is_loading"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field v-model="prospecting.city" label="Cidade" :required="!is_draft_mode" :counter="64"
                  :rules="is_draft_mode ? [] : ruleString(true, 64)" :loading="is_loading"
                  :disabled="is_loading"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-select v-model="prospecting.uf" label="UF" :required="!is_draft_mode" :items="state_acronyms"
                  :rules="is_draft_mode ? [] : ruleRequired()" :loading="is_loading" :disabled="is_loading"></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <div style="
                      height: 30px;
                      display: flex;
                      align-items: center;
                    "><span style="
                      font-size: 16px;
                      font-weight: 500;
                    ">Dados do Reponsável</span></div>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="prospecting.responsible_name" label="Nome do Responsável"
                  :required="!is_draft_mode" :counter="64" :rules="is_draft_mode ? [] : ruleString(true, 64)"
                  :loading="is_loading" :disabled="is_loading"></v-text-field>
              </v-col>
              <v-col cols="3">
                <selectResponsibleType :id_responsible_type="prospecting.id_responsible_type" :loading="is_loading"
                  :disabled="is_loading" @id="(id) => prospecting.id_responsible_type = id" />
              </v-col>
              <v-col cols="5">
                <v-text-field v-model="prospecting.responsible_email" label="Email do Responsável"
                  :required="!is_draft_mode" :counter="64" :rules="is_draft_mode ? [] : ruleEmail(true)"
                  :loading="is_loading" :disabled="is_loading"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="prospecting.responsible_document" label="CPF do Responsável"
                  :required="!is_draft_mode" :rules="is_draft_mode ? [] : ruleCPF(true)" :loading="is_loading"
                  :disabled="is_loading" v-mask="'###.###.###-##'"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="prospecting.responsible_phone" label="Telefone do Responsável"
                  :required="!is_draft_mode" :rules="is_draft_mode ? [] : ruleRequired()" :loading="is_loading"
                  :disabled="is_loading" v-mask="'(##) # ####-####'"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" style="padding: 0;">
                <v-divider />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <div style="
                      height: 30px;
                      display: flex;
                      align-items: center;
                    "><span style="
                      font-size: 16px;
                      font-weight: 500;
                    ">Dados Gerais</span></div>
              </v-col>
              <v-col cols="6">
                <selectProjectPartner :id_project_partner="prospecting.id_project_partner" :loading="is_loading"
                  :disabled="is_loading" @id="(id) => prospecting.id_project_partner = id" />
              </v-col>
              <v-col cols="6">
                <selectProjectHunter :id_project_hunter="prospecting.id_project_hunter" :loading="is_loading"
                  :disabled="is_loading" @id="(id) => prospecting.id_project_hunter = id" />
              </v-col>
              <v-col cols="6">
                <selectProjectSeller :id_project_seller="prospecting.id_project_seller" :loading="is_loading"
                  :disabled="is_loading" @id="(id) => prospecting.id_project_seller = id" />
              </v-col>
              <v-col cols="6">
                <selectProjectCloser :id_project_closer="prospecting.id_project_closer" :loading="is_loading"
                  :disabled="is_loading" @id="(id) => prospecting.id_project_closer = id" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" style="padding: 0;">
                <v-divider />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="7">
                <v-row>
                  <v-col cols="12">
                    <div style="
                          height: 30px;
                          display: flex;
                          align-items: center;
                        "><span style="
                          font-size: 16px;
                          font-weight: 500;
                        ">Dados da Conta</span></div>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="prospecting.registration" label="Matrícula" :required="!is_draft_mode"
                      :counter="32" :rules="is_draft_mode ? [] : ruleString(true, 32)" :loading="is_loading"
                      :disabled="is_loading"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <InputMoney prefix="R$" v-model="prospecting.minimum_rate" label="Taxa Mínima"
                      :required="!is_draft_mode" :rules="is_draft_mode ? [] : ruleIntPositive(true)"
                      :loading="is_loading" :disabled="is_loading" />
                  </v-col>
                  <v-col cols="6">
                    <v-checkbox v-model="prospecting.water_consumption" label="A unidade paga água?"></v-checkbox>
                  </v-col>
                  <v-col cols="6">
                    <v-checkbox v-model="prospecting.sewer_consumption" label="A unidade paga esgoto?"></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="5">
                <InputFiles title="Adicionar Foto/PDF Conta" :loading="is_loading" :fileTypes="['pdf', 'image']"
                  :showFile="prospecting.file_photo_account_dealership"
                  @change:file="(file) => prospecting.file_photo_account_dealership = file" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" style="padding: 0;">
                <v-divider />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="7">
                <v-row>
                  <v-col cols="12">
                    <div style="
                          height: 30px;
                          display: flex;
                          align-items: center;
                        "><span style="
                          font-size: 16px;
                          font-weight: 500;
                        ">Dados do Local</span></div>
                  </v-col>
                  <v-col cols="6">
                    <InputMoney v-model="prospecting.financial_average_per_unit" label="Média Financeira Und."
                      prefix="R$" :required="!is_draft_mode" :rules="is_draft_mode ? [] : ruleIntPositive(true)"
                      :loading="is_loading" :disabled="is_loading" />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="prospecting.number_units" label="Quantidade de Unidades"
                      :required="!is_draft_mode" type="Number" :rules="is_draft_mode ? [] : ruleIntPositive(true)"
                      :loading="is_loading" :disabled="is_loading"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="prospecting.number_towers" label="Quantidade de Torres"
                      :required="!is_draft_mode" type="Number" :rules="is_draft_mode ? [] : ruleIntPositive(true)"
                      :loading="is_loading" :disabled="is_loading"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="prospecting.number_floors" label="Número de Pavimentos"
                      :required="!is_draft_mode" type="Number" :rules="is_draft_mode ? [] : ruleIntPositive(true)"
                      :loading="is_loading" :disabled="is_loading"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="prospecting.unit_by_floor" label="Unidades por Andar"
                      :required="!is_draft_mode" type="Number" :rules="is_draft_mode ? [] : ruleIntPositive(true)"
                      :loading="is_loading" :disabled="is_loading"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="prospecting.wcs_by_unit" label="Banheiros por Unidade"
                      :required="!is_draft_mode" type="Number" :rules="is_draft_mode ? [] : ruleIntPositive(true)"
                      :loading="is_loading" :disabled="is_loading"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sm="5">
                <InputFiles title="Adicionar Foto da Fachada" :loading="is_loading" :fileTypes="['image']"
                  :showFile="prospecting.file_photo_unit_facade"
                  @change:file="(file) => prospecting.file_photo_unit_facade = file" />
              </v-col>

            </v-row>

            <!-- <NextStepProject
                :loading="is_loading"
                :disabled="is_completed"
                :click="nextStep"
              /> -->
          </div>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import SelectProjectCloser from "../Components/select-project-closer.vue";
import Prospecting from "./Prospecting.js";
export default Prospecting;
</script>

<style scoped></style>