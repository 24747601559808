import axios from "axios";
import { showErrorSnackBar, showInfoSnackBar } from "@/utils/SnackBartHelper";
export const HOST = "https://us-central1-centralfoz-homolog.cloudfunctions.net/email/service/sendemail/"

const apiService = axios.create({
    baseURL: HOST,
    timeout: 60000,
});

apiService.interceptors.response.use((response) => response, (error) => {
    console.error("Response error", error);
    if (!error.response || error.response.status == 500)
        showErrorSnackBar("Ocorreu um erro não identificado, entre em contato com nossa equipe de suporte.")
    else
        showInfoSnackBar(error.response.data.message)
    throw error;
});

export default apiService