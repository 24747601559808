<template>
  <div style="width: 100%;">
    <v-stepper v-model="e1" :click="clickChangeStep(e1 - 1)">
      <v-stepper-header>
        <template v-for="(n, index) in steps">
          <v-stepper-step :rules="[() => checkRulesStep(index)]"  :key="`${index}-step`" :complete="e1 > index"
            :step="index + 1" editable>
            {{ n }}
          </v-stepper-step>

          <v-divider v-if="index !== (steps.length - 1)" :key="n"></v-divider>
        </template>
      </v-stepper-header>

      <!-- <v-stepper-items>
        <v-stepper-content v-for="(n, index) in steps" :key="`${index}-content`" :step="index + 1">
          <v-col>
            <slot name="item-form"></slot>
          </v-col>
            
              <v-card-actions v-if="enableButtons">
                <v-spacer></v-spacer>
                
                <v-btn text>
                  Cancel
                </v-btn>
                <v-btn color="primary" @click="nextStep(index + 1)">
                  Continue
                </v-btn>
                
              </v-card-actions>
  
        </v-stepper-content>
      </v-stepper-items> -->
    </v-stepper>
  </div>
</template>
<script>
export default {
  props: {
    steps: {
      default: [],
    },
    enableButtons: {
      default: false
    },
    selectStep: {
      default: 0
    },
    enableWarn: {
      default: () => [],
      type: Array,
    },
    
  },
  data() {
    return {
      e1: 1,
      e1: 1,
    }
  },
  watch:{
    selectStep(){
      this.e1 = this.selectStep
    }
  },
  methods: {
    checkRulesStep(step){
      if(this.enableWarn.length && this.enableWarn.find((stepActive)=> step === stepActive) !== undefined){
        return false
      }
      return true
    },
    clickChangeStep(e) {
      this.$emit("update:step", e);
    },
    nextStep(n) {
      if (n === this.steps.length) {
        this.e1 = 1
      } else {
        this.e1 = n + 1
      }
      this.$emit("update:step", this.e1 - 1);
    },
  },
}
</script>
<style scoped></style>