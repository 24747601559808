<template>
    <v-col cols="12" class="d-flex">
        <v-hover v-slot="{ hover }">
            <v-card 
                :elevation="hover ? 12 : 2"
                :class="{ 'on-hover': hover }"
                style="display: flex;background-color: #1976d2;margin: 10px;min-width: 200px;min-height: 150px;" 
                max-width="200px" 
                outlined 
                shaped 
                elevation="2"
            >
                <slot name="content">
                    <v-col>
                        Conteudo aqui...
                    </v-col>
                </slot>
                
                <template v-if="hover">
                    <v-overlay absolute opacity="0.8" color="black">
                        <div class="d-flex flex-column align-center">
                        <v-btn color="primary" class="mb-2" @click="() => view()">
                            <v-icon>mdi-eye-outline</v-icon>
                        </v-btn>
                        <v-btn color="error" @click="() => remove()">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        </div>
                    </v-overlay>
                </template>

            </v-card>
        </v-hover>
    </v-col>
</template>
  
<script>
export default {
    name: "CardsCheckList",
    
    components: {
        
    },
    
    props: {
        // item: {
        //     type: Object,
        //     default: () => ({})
        // },
        view: {
            type: Function,
            default: () => {}
        },
        remove: {
            type: Function,
            default: () => {}
        },
        // itemImage: {
        //     type: String,
        //     required: true,
        // },
    },
    
    data: () => ({
        cards: [],
    }),

    computed: {

    },

    methods: {

    },

    watch: {
        list: {
            immediate: true,
            handler: function (newValue) {
                if (Array.isArray(newValue)) {
                    newValue.forEach(
                        (item) => {
                            if (!item.hasOwnProperty(this.itemImage)) {
                                throw new Error(`Não foi encontrado a chave da imagem: ${this.itemImage}`);
                            }
                        }
                    )

                }
            }
        },
    },

};
</script>
  
<style scoped>
.no-margin {
    margin: 0;
}
.d-flex {
    display: flex;
}
.f-align-center {
    align-items: center;
}
.f-1 {
    flex: 1;
}
.f-2 {
    flex: 2;
}

.v-card {
    transition: opacity .4s ease-in-out;
}
  
.v-card:not(.on-hover) {
    opacity: 0.6;
}
</style>