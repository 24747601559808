<template>
    <div>
        <canvas ref="signaturePad" :width="width" :height="height" style="border: 1px solid black;"></canvas>
    </div>
  </template>
  
  <script>
  import SignaturePad from 'signature_pad';
  
  export default {
    name: 'SignaturePadComponent',
    data() {
      return {
        signaturePad: null,
      };
    },
    props: {
        width: {
            type: Number,
            default: 800,
            required: false
        },
        height: {
            type: Number,
            default: 200,
            required: false
        },
    },
    mounted() {
        const canvas = this.$refs.signaturePad;
        this.signaturePad = new SignaturePad(canvas);
    },
    methods: {
        saveSignature() {
            if (this.signaturePad.isEmpty()) {
                return null
            } else {
                const canvas = this.$refs.signaturePad;
                const croppedDataURL = this._cropSignature(canvas);
                return croppedDataURL
            }
        },
        clearSignature() {
            this.signaturePad.clear();
        },
        _cropSignature(canvas) {
            const context = canvas.getContext('2d');
            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
            const data = imageData.data;
            
            let minX = canvas.width, minY = canvas.height, maxX = 0, maxY = 0;

            for (let y = 0; y < canvas.height; y++) {
                for (let x = 0; x < canvas.width; x++) {
                    const index = (y * canvas.width + x) * 4;
                    const alpha = data[index + 3];
                    if (alpha > 0) {
                        if (x < minX) minX = x;
                        if (x > maxX) maxX = x;
                        if (y < minY) minY = y;
                        if (y > maxY) maxY = y;
                    }
                }
            }

            const croppedWidth = maxX - minX + 1;
            const croppedHeight = maxY - minY + 1;

            const croppedCanvas = document.createElement('canvas');
            const croppedContext = croppedCanvas.getContext('2d');
            croppedCanvas.width = croppedWidth;
            croppedCanvas.height = croppedHeight;
            croppedContext.drawImage(canvas, minX, minY, croppedWidth, croppedHeight, 0, 0, croppedWidth, croppedHeight);

            return croppedCanvas.toDataURL();
        },
    },
  };
  </script>
  
  <style scoped>
  canvas {
    border: 1px solid #000;
  }
  </style>
  