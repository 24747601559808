import { db } from "@/firebase"

async function createActiveAlerts(data) {
    try {
        const batch = db.batch();
        for (let key in data) {
            let ref = db.collection("alerts").doc()
            batch.set(ref, data[key]);
        }
        await batch.commit();
    } catch (e) {
        console.error("createActiveAlerts", e)
    }
}
async function createActiveGoalAlert(unit_id) {
    try {
        const doc_ref = await db.collection("alerts").add(
            {
                generated_at: null,
                unit_id: unit_id,
                type: "goal"
            }
        )
        await db
            .collection("alerts")
            .doc(doc_ref.id)
            .update({
                id: doc_ref.id,
            });

    } catch (e) {
        console.error("createActiveGoalAlert", e)
    }
}
async function deleteActiveAlerts(id_modem) {
    try {
        const batch = db.batch();
        let ref = db.collection("alerts")
            .where('modem_id', '==', id_modem)
        const refSnap = await ref.get()
        refSnap.forEach((doc) => {
            batch.delete(doc.ref)
        });
        await batch.commit();
    } catch (e) {
        console.error("deleteActiveAlerts", e)
    }
}
// async function deleteAllActiveAlerts() {
//     try {
//         const batch = db.batch();
//         let ref = db.collection("alerts_payload")
//             .where('type', '==', "goal")
//         const refSnap = await ref.get()
//         refSnap.forEach((doc) => {
//             batch.delete(doc.ref)
//         });
//         await batch.commit();
//     } catch (e) {
//         console.error("deleteActiveAlerts", e)
//     }
// }
async function deleteActiveGoalAlertsByUnit(unit_id) {
    try {
        const batch = db.batch();
        let ref = db.collection("alerts")
            .where('type', '==', 'goal')
            .where('unit_id', '==', unit_id)
        const refSnap = await ref.get()
        refSnap.forEach((doc) => {
            batch.delete(doc.ref)
        });
        await batch.commit();
    } catch (e) {
        console.error("deleteActiveGoalAlertsByUnit", e)
    }
}
async function getActiveAlertsPerTypeAndUnit(type = null, unit_id) {
    try {
        let list = []
        let ref = db.collection("alerts")
            .where('type', '==', type)
            .where('unit_id', '==', unit_id)
        let querySnapshot = await ref.get();
        querySnapshot.forEach((doc) => {
            let alerts = doc.data();
            list.push(alerts);
        });
        return list;
    } catch (e) {
        console.error("getActiveAlertsPerType - Erro", e)
        return false;
    }
}
async function getActiveAlertsPerTypeAndModem(types = [], modem_id) {
    try {
        let list = []
        let ref = db.collection("alerts")
            .where('type', 'in', types)
            .where('modem_id', '==', modem_id)
        let querySnapshot = await ref.get();
        querySnapshot.forEach((doc) => {
            let alerts = doc.data();
            list.push(alerts);
        });
        return list;
    } catch (e) {
        console.error("getActiveAlertsPerType - Erro", e)
        return false;
    }
}
async function getAlertsPayload(modem_id = null, date = null) {
    try {
        let list = []
        let ref = ""
        if (modem_id) {
            ref = db
                .collection("alerts_payload")
                .where('modem_id', '==', modem_id)
                .where('date_of_occurrence', '>=', date[0])
                .where('date_of_occurrence', '<=', date[1])

        } else {
            ref = db.collection("alerts_payload").where('view', '==', false)
        }
        let querySnapshot = await ref.get();
        querySnapshot.forEach((doc) => {
            let alerts = doc.data();
            list.push(alerts);
        });
        return list;
    } catch (e) {
        console.error("getAlertsPayload - Erro", e)
        return false;
    }
}
async function getAlertsPayloadPerType(type = null) {
    try {
        let list = []
        let ref = db.collection("alerts_payload")
            .where('view', '==', false)
            .where('type', '==', type)

        let querySnapshot = await ref.get();
        querySnapshot.forEach((doc) => {
            let alerts = doc.data();
            list.push(alerts);
        });
        return list;
    } catch (e) {
        console.error("getAlertsPayload - Erro", e)
        return false;
    }
}
async function getAlertsPayloadPerTypeAndEntity(type = null, entity = null, id = null, data = null) {
    try {
        let list = []
        let ref = db.collection("alerts_payload")
            .where('view', '==', false)
            .where('type', '==', type)

        if (entity && id) {
            ref = db.collection("alerts_payload")
                .where('view', '==', false)
                .where('status', '==', true)
                .where(entity, '==', id)
                .where('type', '==', type)
                .where('date_of_occurrence', '>=', data[0])
                .where('date_of_occurrence', '<=', data[1])
        }

        let querySnapshot = await ref.get();
        querySnapshot.forEach((doc) => {
            let alerts = doc.data();
            list.push(alerts);
        });
        return list;
    } catch (e) {
        console.error("getAlertsPayloadPerTypeAndEntity - Erro", e)
        return false;
    }
}
async function getAlertsPayloadTelemetryAndEntity(entity = null, id = null, data = null) {
    try {
        let list = []
        let ref = db.collection("alerts_payload")
            .where('view', '==', false)
            .where('type', 'in', ['low_battery', 'no_variation', 'lack_of_signal', 'no_decodification'])

        if (entity && id) {
            ref = db.collection("alerts_payload")
                .where('view', '==', false)
                .where('status', '==', true)
                .where(entity, '==', id)
                .where('type', 'in', ['low_battery', 'no_variation', 'lack_of_signal', 'no_decodification'])
                .where('date_of_occurrence', '>=', data[0])
                .where('date_of_occurrence', '<=', data[1])
        }
        let querySnapshot = await ref.get();
        querySnapshot.forEach((doc) => {
            let alerts = doc.data();
            list.push(alerts);
        });
        return list;
    } catch (e) {
        console.error("getAlertsPayloadTelemetryAndEntity - Erro", e)
        return false;
    }
}
async function getAlertsPayloadTelemetryAndEntityWithoutView(entity = null, id = null, data = null) {
    try {
        let list = []
        let ref = null
        if (entity && id) {
            ref = db.collection("alerts_payload")
                .where('status', '==', true)
                .where(entity, '==', id)
                .where('type', 'in', ['low_battery', 'no_variation', 'lack_of_signal', 'no_decodification'])
                .where('date_of_occurrence', '>=', data[0])
                .where('date_of_occurrence', '<=', data[1])
        }
        let querySnapshot = await ref.get();
        querySnapshot.forEach((doc) => {
            let alerts = doc.data();
            list.push(alerts);
        });
        return list;
    } catch (e) {
        console.error("getAlertsPayloadTelemetryAndEntity - Erro", e)
        return false;
    }
}
async function getAlertsPayloadConsumptionByEntity(entity = null, id = null, data = null) {
    try {
        let list = []

        let querySnapshot = await db.collection("alerts_payload")
            .where('view', '==', false)
            .where(entity, '==', id)
            .where('type', '==', 'consumption')
            .where('date_of_occurrence', '>=', data[0])
            .where('date_of_occurrence', '<=', data[1])
            .get();

        querySnapshot.forEach((doc) => list.push(doc.data()));
        return list;
    } catch (e) {
        console.error("getAlertsPayloadConsumptionByEntity - Erro", e)
        return false;
    }
}
async function getAlertsPayloadGoalByEntity(entity = null, id = null, data = null) {
    try {
        let list = []

        let querySnapshot = await db.collection("alerts_payload")
            .where('view', '==', false)
            .where(entity, '==', id)
            .where('type', 'in', ['goal_exceeded', 'goal_scheduled'])
            .where('date_of_occurrence', '>=', data[0])
            .where('date_of_occurrence', '<=', data[1])
            .get();

        querySnapshot.forEach((doc) => list.push(doc.data()));
        return list;
    } catch (e) {
        console.error("getAlertsPayloadGoalByEntity - Erro", e)
        return false;
    }
}
async function updateAlerts(data) {
    try {
        let batch = db.batch();
        const batchSize = 500
        let batchCount = 0

        for (let key in data) {
            if (data[key] && typeof data[key] === "string" && data[key].trim() !== "") {
                const ref = db.collection("alerts_payload").doc(data[key])
                batch.update(ref, { view: true });
                batchCount++

                if (batchCount === batchSize) {
                    await batch.commit();
                    batch = db.batch();
                    batchCount = 0;
                }
            }
        }
        await batch.commit();

        return true;
    } catch (e) {
        console.error("updateAlerts - Erro", e)
        return false;
    }
}
async function getAlertsPayloadByWhere(startProp = null, endProp = null, date = null) {
    try {
        let list = []
        let ref = ""
        if (startProp && endProp) {
            ref = db
                .collection("alerts_payload")
                .where(startProp, '==', endProp)
                .where('date_of_occurrence', '>=', date[0])
                .where('date_of_occurrence', '<=', date[1])
            let querySnapshot = await ref.get();
            querySnapshot.forEach((doc) => {
                let alerts = doc.data();
                list.push(alerts);
            });
            return list;

        } else {
            console.error("startProp and endProp undefined")
            return false
        }

    } catch (e) {
        console.error("getAlertsPayload - Erro", e)
        return false;
    }
}
async function getAlertsPayloadConsumptionAndEntity(entity = null, id = null, date = null) {
    try {
        let list = []
        if (entity && id) {
            let querySnapshot = await db
                .collection("alerts_payload")
                .where(entity, '==', id)
                .where('type', 'in', ['goal_exceeded', 'goal_scheduled', 'consumption'])
                .where('date_of_occurrence', '>=', date[0])
                .where('date_of_occurrence', '<=', date[1])
                .get()
            querySnapshot.forEach((doc) => list.push(doc.data()));
            return list;
        } else {
            console.error("entity and id undefined")
            return false
        }
    } catch (e) {
        console.error("getAlertsPayload - Erro", e)
        return false;
    }
}

export default {
    getActiveAlertsPerTypeAndModem,
    // deleteAllActiveAlerts,
    getAlertsPayloadTelemetryAndEntityWithoutView,
    getAlertsPayloadConsumptionByEntity,
    getAlertsPayloadGoalByEntity,
    getAlertsPayloadTelemetryAndEntity,
    getAlertsPayloadConsumptionAndEntity,
    getActiveAlertsPerTypeAndUnit,
    getAlertsPayload,
    getAlertsPayloadPerTypeAndEntity,
    getAlertsPayloadByWhere,
    getAlertsPayloadPerType,
    deleteActiveGoalAlertsByUnit,
    updateAlerts,
    createActiveAlerts,
    createActiveGoalAlert,
    deleteActiveAlerts,
};