<template>
  <v-dialog v-if="closeAllPages" persistent no-click-animation v-model="open" max-width="300px" fullscreen>
    <v-card width="100%">
      <v-card-title class="primary lighten white--text">
        Nova Proposta
        <v-spacer></v-spacer>
        <v-btn class="litte white--text" text @click="clearDisplay()">
          <v-icon white>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
      
          <StepsBar 
            :steps="steps" 
            v-on:update:step="positionStep = $event"
            :enableWarn="enableStepWarn"
            :selectStep="selectStep"
            />
            <v-col v-if="closeAllPages">
        
              <v-overlay absolute style="opacity: 0.6" :value="overlay">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  :size="50"
                ></v-progress-circular>
              </v-overlay>

              <v-row>
                <v-col cols="12">
                  <h3>Matrícula: {{ registration }}</h3>
                </v-col>
              </v-row>

              <LocationInformation
                ref="LocationInformation"
                v-show="nextStep == 0"
                v-if="closeAllPages"
                :registration="registration"
                :closeProposed="clearDisplay"
                @loading="overlay = $event"
                @new:proposal="isNewProposal"
                @page:save="savePage"
                @created:proposal="setHasProposalWaterFixCreated"
              />

              <CheckList
                ref="CheckList"
                v-show="nextStep == 1"
                v-if="closeAllPages && hasProposalWaterFixCreated"
                :registration="registration"
                :closeProposed="clearDisplay"
                @loading="overlay = $event"
                @page:save="savePage"
              />

      </v-col>
      <v-divider></v-divider>
      <v-card-text>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeFunc()">
          Cancel
        </v-btn>
        <v-btn color="primary" @click="saveProposal()" >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import NewWaterFixScript from "./NewWaterFixScript";
export default NewWaterFixScript;
</script>
<style>
#buttonProgressRight {
  position: fixed;
  outline: none;
  color: rgb(25, 118, 210);
  margin-right: 16px;
  cursor: pointer;
  padding: 15px;
  background-color: rgb(207, 236, 250);
  border-radius: 10px;
}
#buttonProgressLeft {
  position: fixed;
  outline: none;
  color: rgb(25, 118, 210);
  margin-left: 16px;
  cursor: pointer;
  padding: 15px;
  background-color: rgb(207, 236, 250);
  border-radius: 10px;
}
</style>