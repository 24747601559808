<template>
  <v-container
    fluid
    style="display: flex; flex-direction: column; align-items: center"
  >
    <v-row style="max-width: 100% !important; width: 100%">
      <v-col cols="12">
        <v-card class="rounded-xl">
          <v-overlay
            absolute
            style="opacity: 0.8"
            :value="isLoadingClient || loadCalls"
          >
            <v-progress-circular
              indeterminate
              color="primary"
              :size="50"
            ></v-progress-circular>
          </v-overlay>
          <v-card-title>
            <v-row style="justify-content: space-between">
              <v-card-title class="title-displays"> Chamados </v-card-title>
              <v-card-title>
                <v-btn right @click="openClalledDialog()" color="primary" text>
                  <v-icon> add </v-icon>
                  Novo Chamado
                </v-btn>
              </v-card-title>
            </v-row>
          </v-card-title>
          <v-row style="justify-content: space-between">
            <!-- <v-text-field
              style="width: 80%; padding: 1em"
              class="px-4"
              prepend-icon="search"
              label="Busca"
              v-model="searchText"
            ></v-text-field> -->
            <!-- v-model="searchText" -->
            <v-col cols="12">
              <v-select
                hint="Buscar Por"
                :items="columnsToChoose"
                v-model="columnToSearch"
                @change="filterCalls(columnToSearch)"
                persistent-hint
                style="padding: 0px 20px 5px 20px"
                return-object
                single-line
              ></v-select>
            </v-col>
            <!-- v-model="columnToSeach" -->
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-container grid-list-xl fluid>
      <v-overlay absolute style="opacity: 0.8" :value="loadingOpenCalled">
      </v-overlay>
      <v-row v-if="!showInfiniteLoading">
        <v-col v-for="n in 3" :key="n" cols="12" md="12">
          <skeleton-component
            style="height: 120px; border-radius: 20px"
          ></skeleton-component>
        </v-col>
      </v-row>
      <v-col
        v-for="(item, i) in filteredItems"
        :key="i"
        cols="12"
        align="center"
      >
        <button
          align="center"
          style="
            background: white;
            width: 100%;
            min-width: 300px;
            max-width: 100%;
            box-shadow: 0px 2px 2px black;
          "
          class="rounded-xl"
          @click="openClalledView(item)"
          v-if="!isLoadingClient || loadCalls"
        >
          <v-card-text>
            <v-row>
              <v-col style="padding-left: 25px" cols="10">
                <div align="left">
                  <v-row>
                    <v-avatar>
                      <img :src="item.imageUser" />
                    </v-avatar>
                    <div style="padding-left: 15px" align="left">
                      <span style="font-weight: bold; font-size: 16px"
                        >{{ item.nameUser }}<br
                      /></span>
                      <span>{{ datePost(item.openTime) }}</span>
                    </div>
                  </v-row>
                </div>
              </v-col>
              <v-col style="padding: 12px; color: black !important" cols="2">
                <v-badge
                  bordered
                  :content="item.comment_size || item.comments.length"
                  :color="item.status"
                  overlap
                >
                  <v-icon v-if="item.natureCalled == 'Técnico'"
                    >mdi-wrench-outline</v-icon
                  >
                  <v-icon v-else>mdi-monitor-edit</v-icon>
                </v-badge>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <div
              align="left"
              style="font-size: 16px; font-style: italic; padding-bottom: 0px"
            >
              {{ item.connections.client }} > {{ item.connections.sector }}
            </div>
            <v-row>
              <v-col md="" cols="12" style="padding-bottom: 2px">
                <div align="left">
                  <div
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                    "
                    align="left"
                  >
                    {{ item.description }}
                  </div>
                </div>
              </v-col>
              <v-col
                md=""
                cols="12"
                style="padding-top: 0px"
                v-if="item.imageURL !== null"
              >
                <v-img
                  :src="item.imageURL"
                  contain
                  max-height="300"
                  align="center"
                  style="
                    border: solid grey thin;
                    border-radius: 4px;
                    padding: 2px;
                    width: 100%;
                  "
                >
                </v-img>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-card-text>
        </button>
      </v-col>
      <InfiniteLoading @infinite="infiniteHandler" v-if="showInfiniteLoading">
        <div slot="no-more">Todos os dados carregados.</div>
        <div slot="no-results">Todos os dados carregados.</div>
      </InfiniteLoading>
    </v-container>
    <!-- New Post -->
    <v-dialog persistent max-width="1200" v-model="dialog" v-if="dialog">
      <v-card>
        <v-card-title class="primary lighten white--text"
          >{{ titulo }}
          <v-spacer></v-spacer>

          <v-btn class="litte white--text" text @click="closeClalledDialog()">
            <v-icon white>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form v-model="validForm" lazy-validation ref="FormNewCalled">
            <v-row align="center">
              <v-col cols="4" sm="4">
                <v-autocomplete
                  v-model="clientObject"
                  :items="listClients"
                  item-text="data.name"
                  outlined
                  label="Cliente"
                  required
                  :rules="rulesRequired"
                  return-object
                  :loading="isLoadingClient"
                  :disabled="isLoadingClient"
                  @change="onChangeClient"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" sm="4">
                <v-autocomplete
                  v-model="unitObject"
                  :items="listUnits"
                  item-text="name"
                  outlined
                  required
                  :rules="rulesRequired"
                  label="Unidade"
                  return-object
                  @change="onChangeUnit"
                  :loading="isLoadingUnit"
                  :disabled="!clientObject"
                ></v-autocomplete>
                <!-- @change="onChangeUnit" -->
              </v-col>
              <v-col cols="4" sm="4">
                <v-autocomplete
                  v-model="selectedSector"
                  :items="listSectors"
                  item-text="name"
                  outlined
                  required
                  :rules="rulesRequired"
                  label="Setor"
                  return-object
                  :loading="isLoadingUnit"
                  :disabled="!clientObject"
                ></v-autocomplete>
                <!-- @change="onChangeUnit" -->
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="4" sm="4">
                <v-select
                  v-model="objCalled.status"
                  outlined
                  :disabled="true"
                  :items="itemsStatus"
                  label="Status"
                >
                </v-select>
              </v-col>
              <v-col cols="4" sm="4">
                <v-select
                  v-model="objCalled.natureCalled"
                  outlined
                  required
                  :rules="rulesRequired"
                  :items="['Técnico', 'Administrativo']"
                  label="Natureza do Chamado"
                >
                </v-select>
              </v-col>

              <v-col cols="4" md="4">
                <v-text-field
                  class="text-no-wrap"
                  :label="'Imagem Referente ao Chamado'"
                  @click="
                    () => {
                      this.pickFile();
                    }
                  "
                  outlined
                  readonly
                  messages="Tamanho máximo: 700 KB"
                  append-icon="attach_file"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col cols="12" sm="12">
                <v-text-field
                  v-model="objCalled.description"
                  outlined
                  required
                  :rules="RulesCharacters"
                  label="Descrição"
                  prepend-inner-icon="mdi-comment-processing-outline"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
            </v-row>
            <!-- <v-row align="center" v-if="showCheckBoxOptions.all">
              <v-col cols="3" md="3">
                <div style="font-size: 16px">Mostrar meu chamado para:</div>
              </v-col>
              <v-col cols="2" md="2" v-if="showCheckBoxOptions.admin">
                <v-checkbox v-model="showToOptions.admin" label="Admin">
                </v-checkbox>
              </v-col>
              <v-col cols="2" md="2" v-if="showCheckBoxOptions.franchisee">
                <v-checkbox
                  v-model="showToOptions.franchisee"
                  label="Franqueado"
                >
                </v-checkbox>
              </v-col>
              <v-col cols="2" md="2" v-if="showCheckBoxOptions.client">
                <v-checkbox v-model="showToOptions.client" label="Cliente">
                </v-checkbox>
              </v-col>
              <v-col cols="2" md="2" v-if="showCheckBoxOptions.associated">
                <v-checkbox
                  v-model="showToOptions.associated"
                  label="Associado"
                >
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" md="3">
                <v-select
                  v-model="value"
                  :items="usersNames.adminNames"
                  chips
                  label="Administradores"
                  :disabled="!showCheckBoxOptions.admin"
                  multiple
                  solo
                ></v-select>
              </v-col>
              <v-col cols="3" md="3">
                <v-select
                  v-model="value"
                  :items="usersNames.franchiseeNames"
                  chips
                  label="Franqueados"
                  :disabled="!showCheckBoxOptions.franchisee"
                  multiple
                  solo
                ></v-select>
              </v-col>
              <v-col cols="3" md="3">
                <v-select
                  v-model="value"
                  :items="usersNames.clientNames"
                  chips
                  label="Clientes"
                  multiple
                  :disabled="!showCheckBoxOptions.client"
                  solo
                ></v-select>
              </v-col>
              <v-col cols="3" md="3">
                <v-select
                  v-model="value"
                  :items="usersNames.associatedNames"
                  chips
                  label="Associado"
                  multiple
                  :disabled="!showCheckBoxOptions.associated"
                  solo
                ></v-select>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col cols="12" md="12" sm="12">
                <v-img
                  :src="image"
                  contain
                  max-height="300"
                  style="
                    border: solid blue thin;
                    border-radius: 4px;
                    padding: 2px;
                    width: 100%;
                  "
                >
                </v-img>
                <input
                  type="file"
                  style="display: none"
                  ref="image"
                  accept="image/*"
                  @change="onFilePicked"
                />
              </v-col>
            </v-row>
            <v-row v-if="viewDialog">
              <v-col cols="12" md="12" sm="12">
                <v-text-field
                  outlined
                  label="Comentario"
                  prepend-inner-icon="mdi-comment-processing-outline"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>

              <v-alert v-if="flagErroMsg" dense outlined type="error">
                {{ erroMsg }}
              </v-alert>
              <v-spacer></v-spacer>
            </v-row>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click="checkSave"
                text
                color="primary"
                :disabled="!validForm"
              >
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
        <v-overlay absolute style="opacity: 0.6" :value="isLoadingCreate">
          <v-progress-circular
            indeterminate
            color="primary"
            :size="50"
          ></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
    <!-- View Post -->
    <v-dialog persistent max-width="100%" v-model="dialogView">
      <v-overlay absolute style="opacity: 0.6" :value="isLoadingDelet">
        <v-progress-circular
          indeterminate
          color="primary"
          :size="50"
        ></v-progress-circular>
      </v-overlay>
      <v-card>
        <v-card-title style="padding-top: 2px; padding-left: 0px;padding-top: 2px;padding-left: 0px;flex-direction: row;justify-content: space-between;">
          <div style="display: flex;margin-left: 18px;margin-top: 10px;">
            <div style="width: 150px">
              <v-select
                v-model="statusModel"
                :items="statusToChoose"
                @change="alterStatus()"
                :loading="loadingStatus"
                :disabled="CalledViewClick.status === 'green'"
                label="Status"
              ></v-select>
            </div>
            <div align="right" style="">
              <v-badge bordered :color="CalledViewClick.status" overlap>
                <v-icon v-if="CalledViewClick.natureCalled == 'Técnico'"
                  >mdi-wrench-outline</v-icon
                >
                <v-icon v-else>mdi-monitor-edit</v-icon>
              </v-badge>
            </div>
          </div>
          <v-btn
            align="left"
            class="litte grey--text"
            text
            style="margin-left: 15px;"
            @click="closeClalledDialog()"
          >
            <v-icon white>mdi-window-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              md="8"
              cols="12"
              style="padding: 2px"
              v-if="CalledViewClick.imageURL !== null"
            >
              <v-img
                v-if="CalledViewClick.imageURL !== null"
                :src="CalledViewClick.imageURL"
                contain
                max-height="300"
                align="center"
                style="
                  border: solid grey thin;
                  border-radius: 4px;
                  padding: 2px;
                  width: 100%;
                "
              >
              </v-img>
            </v-col>

            <v-col
              :md="viewMD(CalledViewClick.imageURL)"
              cols="12"
              style="padding-left: 20px; padding-top: 0px"
            >
              <div align="left">
                <v-row>
                  <v-avatar>
                    <img :src="CalledViewClick.imageUser" />
                  </v-avatar>
                  <div style="padding-left: 15px" align="left">
                    <span style="font-weight: bold; font-size: 16px"
                      >{{ CalledViewClick.nameUser }}<br
                    /></span>
                    <span>{{ datePost(CalledViewClick.openTime) }}</span>
                  </div>
                </v-row>
                <p style="font-size: 14px">
                  <span style="font-weight: bold">Natureza do Chamado: </span
                  >{{ CalledViewClick.natureCalled }}<br />
                </p>
                <span style="color: black"
                  >{{ CalledViewClick.description }}<br
                /></span>
              </div>
              <v-divider></v-divider>
              <v-btn
                text
                block
                @click="commentCalled()"
                :color="colorComment"
                :loading="loadingComment"
              >
                Comentar
              </v-btn>
              <v-divider></v-divider>
              <textarea
                v-model="commentText"
                v-if="dialogComment"
                style="width: 100%; border: 1px solid black; border-radius: 5px"
              >
              </textarea>

              <div align="left" style="padding-top: 20px">
                <div v-for="(item, i) in commentCurrentIndex" :key="i">
                  <v-row style="padding-left: 10px">
                    <v-avatar size="40px">
                      <img :src="item.imageUser" />
                    </v-avatar>
                    <div style="padding-left: 10px" align="left">
                      <span style="font-weight: bold; font-size: 14px"
                        >{{ item.userComment }}<br
                      /></span>
                      <span style="font-size: 12px">{{
                        datePost(item.time)
                      }}</span>
                    </div>
                  </v-row>
                  <p
                    style="
                      border-radius: 8px;
                      background: #f0f2f5;
                      padding: 10px;
                      color: black;
                      margin-left: 45px;
                    "
                  >
                    {{ item.comment }}<br />
                  </p>
                </div>
                <InfiniteLoading
                  :identifier="infiniteId"
                  @infinite="infiniteHandlerComment"
                >
                  <div slot="no-more">Todos os dados carregados.</div>
                  <div slot="no-results">Todos os dados carregados.</div>
                </InfiniteLoading>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showDeleteDialogAlert('Deseja excluir esse chamado?',deleteCall)" text color="primary">
            <v-icon color="primary"> mdi-delete </v-icon>
          </v-btn>
        </v-card-actions>
        <v-spacer></v-spacer>
      </v-card>
    </v-dialog>
  
    <DialogFormFinishCalled
      :dialog="dialogFinishCalled"
      :saveFunc="alterStatus"
      v-if="dialogFinishCalled"
    />

    <div class="text-center">
      <v-dialog v-model="dialogStatus" width="300">
        <v-card>
          <v-card-title
            style="font-size: 16px; padding: 10px"
            class="primary lighten white--text"
          >
            Mudança de Status
          </v-card-title>

          <v-card-text style="font-size: 14px; padding-top: 10px">
            Deseja trocar o status do chamado para "{{ statusModel }}"?
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="alterStatus(true)"> Sim </v-btn>
            <v-btn color="primary" text @click="alterStatus(false)">
              Não
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <!-- <DialogAlert
      :title="'Excluir'"
      :text="'Deseja excluir esse chamado?'"
      :open="dialogAlert"
      v-on:update:close="dialogAlert = $event"
      :runFunc="deleteCall"
    /> -->
  </v-container>
</template>

<script>
import CalledReportScript from "./CalledReportScript";
export default CalledReportScript;
</script>

<style>
</style>