<template>
  <v-row>
     <v-overlay absolute style="opacity: 0.6" :value="isLoadingAdress">
        <v-progress-circular
            indeterminate
            color="primary"
            :size="50"
        ></v-progress-circular>
    </v-overlay>
    <v-col cols="12" md="3">
      <v-text-field
        outlined
        label="CEP"
        v-model="addressObj.cep"
        @input="debouncedGetCep"
        v-mask="'XX.XXX-XXX'"
        :rules="rulesCEP"
      >
      </v-text-field>
    </v-col>
    <v-col cols="12"  md="7">
      <v-text-field
        outlined
        label="Logradouro"
        v-model="addressObj.logradouro"
        :rules="rulesLogradouro"
        counter="128"
      >
      </v-text-field>
    </v-col>
    <v-col cols="12"  md="2">
      <v-text-field
        outlined
        label="Número"
        type="number"
        v-model="addressObj.numero"
        :rules="rulesHouseNumber"
        counter="16"
      >
      </v-text-field>
    </v-col>
    <v-col cols="12" md="3" >
      <v-text-field
        outlined
        label="Complemento"
        v-model="addressObj.complemento"
        :rules="rulesAdditionalAddress"
        counter="64"
      >
      </v-text-field>
    </v-col>
    <v-col cols="12" md="4" >
      <v-text-field
        outlined
        label="Bairro"
        v-model="addressObj.bairro"
        :value="addressObj.bairro"
        :rules="rulesDistrict"
        counter="64"
      >
      </v-text-field>
    </v-col>
    <v-col cols="12" md="3" >
      <v-text-field
        outlined
        label="Cidade"
        v-model="addressObj.cidade"
        :rules="rulesCity"
        counter="64"
      >
      </v-text-field>
    </v-col>
    <v-col cols="12" md="2">
      <v-select outlined label="UF" :items="uf" v-model="addressObj.uf">
      </v-select>
    </v-col>
  </v-row>
</template>
<script>
import Rules from "@/utils/Rules";
import debounce from 'lodash/debounce';

export default {
  name: "addressForm",
  props: ["adressInfo"],
  data: function () {
    return {
      isLoadingAdress: false,
      uf: [
        "AC",
        "AL",
        "AP",
        "AM",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MT",
        "MS",
        "MG",
        "PA",
        "PB",
        "PR",
        "PE",
        "PI",
        "RJ",
        "RN",
        "RS",
        "RO",
        "RR",
        "SC",
        "SP",
        "SE",
        "TO",
      ],
      adressValid: false,
    };
  },
  methods: {
    clearAdress() {
      this.addressObj.bairro = null;
      this.addressObj.cidade = null;
      this.addressObj.complemento = null;
      this.addressObj.logradouro = null;
      this.addressObj.numero = null;
      this.addressObj.uf = null;
    },
    getCep() {

      if (this.addressObj.numero) return; 

      let cep = this.addressObj.cep;
      cep = cep.replace(/\D/g, "");

      if (cep != "" && cep.length == 8) {
        this.isLoadingAdress = true
        this.clearAdress();
        let url = "https://viacep.com.br/ws/" + cep + "/json/";

        fetch(url)
          .then((response) => response.json())
          .then((responseJson) => {
            this.addressObj.bairro = responseJson.bairro;
            this.addressObj.cidade = responseJson.localidade;
            this.addressObj.complemento = responseJson.complemento;
            this.addressObj.logradouro = responseJson.logradouro;
            this.addressObj.uf = responseJson.uf;
            this.isLoadingAdress = false
          })
          .catch((err) => {
            this.isLoadingAdress = false
            console.error("Erro", err)});
          
      } else {
        this.clearAdress();
      }
    },
    debouncedGetCep: debounce(function () {
      this.getCep();
    }, 300)
  },
  computed: {
    addressObj: {
      get() {
        return this.adressInfo;
      },
      set(value) {
        this.$emit('update:address', value);
      }
    },
    rulesCEP() {
      return Rules.cep();
    },
    rulesLogradouro() {
      return Rules.string(false, null, 128);
    },
    rulesCity() {
      return Rules.string(false, null, 64);
    },
    rulesDistrict() {
      return Rules.string(false, null, 64);
    },
    rulesAdditionalAddress() {
      return Rules.string(false, null, 64);
    },
    rulesHouseNumber() {
      return Rules.string(false, null, 16);
    },
  },
  watch: {
    addressObj: {
      handler(val) {
        this.$emit('update:address', val);
      },
      deep: true
    }
  },
};
</script>