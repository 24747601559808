import Request from '@/request/Request.js';


const consumption = (data) => {
    return Request('consumption', data)
}
const calls = (data) => {
    return Request('calls', data)
}
const alerts = (data) => {
    return Request('alerts', data)
}
const goal = (data) => {
    return Request('goal', data)
}
const reading = (data) => {
    return Request('reading', data)
}
const historic = (data) => {
    return Request('historic', data)
}
const daily_consumption = (data) => {
    return Request('daily-consumption', data)
}
const last_reading_date = (data) => {
    return Request('units/last-reading-date', data)
}
const resume_meta_estimate = (data) => {
    return Request('units/resume-meta-estimate', data)
}
const average_before_foz = (data) => {
    return Request('units/average-before-foz', data)
}
const calls_finished = (data) => {
    return Request('calls/calls-finished', data)
}

export default { 
    consumption,
    calls,
    alerts,
    goal,
    reading,
    historic,
    daily_consumption,
    last_reading_date,
    resume_meta_estimate,
    average_before_foz,
    calls_finished,
}