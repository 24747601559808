<template>
  <v-container fluid>
    <template>
      <v-row>
        <v-col cols="12">
          <v-card class="rounded-xl">
            <v-overlay absolute style="opacity: 0.8" :value="isMainLoading">
              <v-progress-circular indeterminate color="primary" :size="50"></v-progress-circular>
            </v-overlay>

            <v-card-title>
              <v-row style="justify-content: space-between">
                <v-card-title class="title-displays"> Unidades </v-card-title>
                <v-card-title>
                  <v-btn v-if="isEnabledAction('newUnit')" right @click="openUnit('signup')" color="primary" text>
                    <v-icon> add </v-icon>
                    Nova Unidade
                  </v-btn>
                </v-card-title>
              </v-row>
            </v-card-title>
            <v-card style="overflow: hidden">
              <v-row style="justify-content: space-between">
                <v-text-field style="width:80%; padding:1em" class="px-4" prepend-icon="search" label="Busca"
                  v-model="searchText"></v-text-field>
                <v-select hint="Buscar nessa coluna" persistent-hint style="width:20%; padding: 1em"
                  v-model="columnToSeach" :items="columnsToChoose" return-object single-line></v-select>
              </v-row>

              <v-data-table :headers="headers" :items="getUnitsLocal" no-data-text="Sem unidades para exibir"
                :footer-props="{
                  'items-per-page-options': [5, 10, 20, 30]
                }" :page.sync="page" :items-per-page="10">
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="(unit, i) in items" :key="i" style="height: 100%;color: #1976d2">
                      <td @click="openUnit('view', unit)">
                        <v-img :src="getImage(unit.id_client)" contain max-height="80px" max-width="220px"
                          min-width="220px">
                        </v-img>
                      </td>
                      <td @click="openUnit('view', unit)">
                        <v-card-text class="units-table-names">{{ unit.name_client }} / {{ unit.name }}</v-card-text>
                      </td>
                      <td @click="openUnit('view', unit)">
                        <v-card-text class="units-table-names">{{ getRegistration(unit.matricula) }}</v-card-text>
                      </td>
                      <td @click="openUnit('view', unit)">
                        <v-card-text class="units-table-names">{{ getInvoicingGroup(unit.grupoFaturamento)
                          }}</v-card-text>
                      </td>
                      <td @click="openUnit('view', unit)">
                        <v-card-text class="units-table-names">{{ getAddress(unit.address) }}</v-card-text>
                      </td>
                      <td style="width: 100px;">
                        <v-card-text style="white-space: normal">
                          <v-btn v-if="isEnabledAction('editUnit')" @click="openUnit('edit', unit)" v-ripple>
                            <v-icon small color="primary" class="mr-2">
                              mdi-pencil
                            </v-icon>
                          </v-btn>
                          <v-btn v-if="isEnabledAction('deleteUnit')" @click="confirmDeleteUnit(unit)" v-ripple>
                            <v-icon small color="primary"> mdi-delete </v-icon>
                          </v-btn>
                        </v-card-text>
                      </td>

                    </tr>

                  </tbody>
                </template>


              </v-data-table>

            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <!-- Modal para a visualização dos dados da unidade -->
    <v-dialog v-model="openModal" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Unidade </v-card-title>

        <!-- Conteúdo das informações da unidade -->
        <v-card flat>
          <v-card-text>
            Descricao {{ this.id_client }} / {{ this.index_unit }}
          </v-card-text>
        </v-card>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="openModal = false">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent max-width="1500" v-model="dialog" v-if="dialog">
      <v-card>
        <v-card-title class="primary lighten white--text">{{
          formTitle
        }}
          <v-spacer></v-spacer>

          <v-btn class="litte white--text" text @click="close">

            <v-icon white>mdi-window-close</v-icon>

          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-tabs v-model="tabUnit">
            <v-tab v-for="selectedTab in FormColumns" :key="selectedTab">
              {{ selectedTab }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tabUnit">
            <v-tab-item key="1">
              <v-form ref="form" v-model="validForm" lazy-validation>
                <v-row style="margin-bottom: -10px;">
                  <v-col sm="3" cols="12">
                    <v-tooltip bottom>

                      <template v-slot:activator="{ on, attrs }">
                        <v-img v-bind="attrs" v-on="on" :src="getCurrentImage" contain style="
                            height: 143px;                           
                            margin-top: 20px;
                            border: solid blue thin;
                            border-radius: 15px;
                          " :disabled="isActiveOnlyView" />
                        <!-- <v-img v-bind="attrs" v-on="on" :src="getCurrentImage" contain style="
                            height: 143px;                           
                            margin-top: 20px;
                            border: solid blue thin;
                            border-radius: 15px;
                          " :disabled="isActiveOnlyView" @click="() => pickFile()" /> -->
                        <!-- <input type="file" id="get_image" ref="get_image" accept="image/*" style="display: none"
                          @input="onFilePicked" /> -->
                      </template>
                      <!-- <span>Clique para selecione uma imagem!</span> -->
                    </v-tooltip>
                  </v-col>
                  <v-col sm="9" cols="12">
                    <v-row>
                      <v-col cols="6">
                        <v-select @change="selectUser" :loading="usersLoadings.getData" :items="usersByRank"
                          item-text="name" item-value="id" :label="isEdit || isActiveOnlyView
                            ? 'Usuário Selecionado'
                            : 'Selecione o Usuário'
                            " :disabled="isActiveOnlyView" v-model="editedItem.id_client"
                          :readonly="isActiveOnlyView || usersLoadings.getData" :rules="rulesRequired"></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field label="CNPJ / CPF" v-model="clientName" :disabled="isActiveOnlyView"
                          :readonly="true" :rules="rulesRequired">
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field outlined label="Nome" prepend-inner-icon="apartment" v-model="editedItem.name"
                          :disabled="isActiveOnlyView" :readonly="isActiveOnlyView" :rules="rulesRequired">
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-divider />
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                    <v-row>
                      <v-col sm="8" cols="12" style="
                          background: #1976D205;
                          border-radius: 15px;
                          border: 1px solid #ccc!important;
                      ">
                        <h2 style="text-align: center;padding-bottom: 10px">Dados da Concessionária</h2>
                        <v-divider />
                        <v-row>
                          <v-col sm="6" cols="12">
                            <v-text-field background-color="white" outlined v-model="editedItem.matricula"
                              label="Matrícula" type="number" :disabled="isActiveOnlyView"
                              :readonly="isActiveOnlyView"></v-text-field>
                          </v-col>
                          <v-col sm="6" cols="12">
                            <v-select background-color="white" outlined :items="waterAuthorities" label="Concessionária"
                              v-model="editedItem.concessionaria" :disabled="isActiveOnlyView"
                              :readonly="isActiveOnlyView" :rules="rulesRequired">
                            </v-select>
                          </v-col>
                          <v-col sm="6" cols="12">
                            <v-select background-color="white" outlined :items="[
                              'Residencial',
                              'Comercial',
                              'Industrial',
                              'Pública',
                            ]" label="Grupo de Faturamento" v-model="editedItem.grupoFaturamento"
                              :disabled="isActiveOnlyView || editedItem.concessionaria == 'Sem Concessionária' || editedItem.concessionaria == null"
                              :readonly="isActiveOnlyView"
                              :rules="editedItem.concessionaria == 'Sem Concessionária' || editedItem.concessionaria == null ? [] : rulesRequired">
                            </v-select>
                          </v-col>

                        </v-row>
                      </v-col>

                      <v-col sm="4" cols="12" style="
                          background: #1976D205;
                          border-radius: 15px;
                          border: 1px solid #ccc!important;
                      ">
                        <h2 style="text-align: center;padding-bottom: 10px">Tipo de Consumo</h2>
                        <v-divider />
                        <v-row>
                          <v-col :cols="isActiveWaterTarrifsTypes ? 6 : 12">
                            <v-select :rules="rulesRequired" background-color="white" outlined
                              :items="waterTariffsTypes" label="Água" v-model="editedItem.tarrifsTypes.water.tariffType"
                              :disabled="isActiveOnlyView" :readonly="isActiveOnlyView">
                            </v-select>
                          </v-col>
                          <v-col cols="6" v-if="isActiveWaterTarrifsTypes ? true : false">
                            <v-text-field :rules="rulesRequired" background-color="white" outlined label="Valor"
                              type="number" suffix="m³" :disabled="editedItem.tarrifsTypes.water.tariffType == 'Fixo' && action != 'view'
                                ? false
                                : true" :v-model="editedItem.tarrifsTypes.water.value" :readonly="isActiveOnlyView">
                            </v-text-field>
                          </v-col>
                          <v-col :cols="isActiveViewSewerTarrifsTypes ? 6 : 12">
                            <v-select :rules="rulesRequired" background-color="white" outlined
                              :items="sewerTariffsTypes" label="Esgoto"
                              v-model="editedItem.tarrifsTypes.sewer.tariffType" :disabled="isActiveOnlyView"
                              :readonly="isActiveOnlyView">
                            </v-select>
                          </v-col>
                          <v-col cols="6" v-if="isActiveViewSewerTarrifsTypes ? true : false">
                            <v-text-field :rules="rulesRequired" background-color="white" outlined label="Valor"
                              type="number" suffix="m³" v-model="editedItem.tarrifsTypes.sewer.value" :disabled="editedItem.tarrifsTypes.sewer.tariffType == 'Fixo' && action != 'view'
                                ? false
                                : true" :readonly="isActiveOnlyView">
                            </v-text-field>
                          </v-col>
                        </v-row>

                      </v-col>

                    </v-row>
                  </v-col>
                </v-row>
              </v-form>
            </v-tab-item>
            <v-tab-item key="2">
              <v-row>
                <v-col cols="12">
                  <h2 style="color: grey">Endereço</h2>
                </v-col>
                <v-col cols="4" md="4" sm="6">
                  <v-text-field outlined label="CEP" :v-mask="'XX.XXX-XXX'" :disabled="isActiveOnlyView"
                    v-model="editedItem.address.cep" :readonly="isActiveOnlyView">
                  </v-text-field>
                </v-col>
                <v-col cols="4" md="4" sm="6">
                  <v-text-field outlined label="Logradouro" v-model="editedItem.address.logradouro"
                    :disabled="isActiveOnlyView" :readonly="isActiveOnlyView">
                  </v-text-field>
                </v-col>
                <v-col cols="4" md="4" sm="6">
                  <v-text-field outlined label="Número" type="number" v-model="editedItem.address.numero"
                    :disabled="isActiveOnlyView" :readonly="isActiveOnlyView">
                  </v-text-field>
                </v-col>
                <v-col cols="4" md="4" sm="6">
                  <v-text-field outlined label="Complemento" v-model="editedItem.address.complemento"
                    :disabled="isActiveOnlyView" :readonly="isActiveOnlyView">
                  </v-text-field>
                </v-col>
                <v-col cols="4" md="4" sm="6">
                  <v-text-field outlined label="Bairro" v-model="editedItem.address.bairro" :disabled="isActiveOnlyView"
                    :readonly="isActiveOnlyView">
                  </v-text-field>
                </v-col>
                <v-col cols="4" md="4" sm="6">
                  <v-text-field outlined label="Cidade" v-model="editedItem.address.cidade" :disabled="isActiveOnlyView"
                    :readonly="isActiveOnlyView">
                  </v-text-field>
                </v-col>
                <v-col cols="4" md="4" sm="6">
                  <v-select outlined label="UF" :items="ufList" v-model="editedItem.address.uf"
                    :disabled="isActiveOnlyView" :readonly="isActiveOnlyView">
                  </v-select>
                </v-col>
                <v-col cols="4" md="4" sm="6">
                  <v-text-field outlined label="Latitude" v-model="editedItem.address.lat" :disabled="isActiveOnlyView"
                    :readonly="isActiveOnlyView">
                  </v-text-field>
                </v-col>
                <v-col cols="4" md="4" sm="6">
                  <v-text-field outlined label="Longitude" v-model="editedItem.address.lng" :disabled="isActiveOnlyView"
                    :readonly="isActiveOnlyView">
                  </v-text-field>
                </v-col>

              </v-row>
            </v-tab-item>
            <v-tab-item key="3">
              <v-row>
                <v-col cols="12">
                  <h2 style="color: grey">Dados Extras</h2>
                </v-col>

                <v-col cols="3" md="3" sm="6">
                  <v-checkbox label="Poço" v-model="editedItem.poco" :disabled="isActiveOnlyView"
                    :readonly="isActiveOnlyView">
                  </v-checkbox>
                </v-col>

                <v-col cols="3" md="3" sm="6">
                  <v-text-field outlined label="Quantidade de Poços" type="number" v-model="editedItem.qtdPocos"
                    :disabled="!editedItem.poco" :readonly="isActiveOnlyView">
                  </v-text-field>
                </v-col>

                <v-col cols="3" md="3" sm="6">
                  <v-checkbox label="Tratamento de Efluentes" :disabled="isActiveOnlyView"
                    v-model="editedItem.tratamentoEfluentes" :readonly="isActiveOnlyView">
                  </v-checkbox>
                </v-col>

                <v-col cols="3" md="3" sm="6">
                  <v-select outlined label="Efluentes Tratados" :items="efluentesTratadosTypes"
                    v-model="editedItem.efluentesTratados" :disabled="!editedItem.tratamentoEfluentes"
                    :readonly="isActiveOnlyView">
                  </v-select>
                </v-col>
                <v-col cols="12" md="12" sm="1"></v-col>
                <v-col cols="3" md="3" sm="6">
                  <v-checkbox label="Caminhão Pipa" :disabled="isActiveOnlyView" v-model="editedItem.caminhaoPipa"
                    :readonly="isActiveOnlyView">
                  </v-checkbox>
                </v-col>


                <v-col cols="4" md="4" sm="6">
                  <v-text-field outlined label="Volume dos Caminhões" type="number" suffix="m³"
                    v-model="editedItem.volCaminhoes" :disabled="!editedItem.caminhaoPipa" :readonly="isActiveOnlyView">
                  </v-text-field>
                </v-col>
                <v-col cols="5" md="5" sm="6">
                  <v-text-field outlined label="Qtd. de Caminhões/Mês" type="number" v-model="editedItem.qtdCaminhoes"
                    :disabled="!editedItem.caminhaoPipa" :readonly="isActiveOnlyView">
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="12" sm="1"></v-col>
                <v-col cols="3" md="3" sm="6">
                  <v-checkbox label="Bomba de Recalque" v-model="editedItem.bombaRecalque" :disabled="isActiveOnlyView"
                    :readonly="isActiveOnlyView">
                  </v-checkbox>
                </v-col>
                <v-col cols="3" md="3" sm="6">
                  <v-text-field outlined label="Qtd. de Bombas de Recalque" type="number"
                    v-model="editedItem.qtdBombasRecalque" :disabled="!editedItem.bombaRecalque"
                    :readonly="isActiveOnlyView">
                  </v-text-field>
                </v-col>
                <v-col cols="2" md="2" sm="6">
                  <v-text-field outlined label="Potência" type="number" suffix="CV"
                    v-model="editedItem.potenciaBombasRecalque" :disabled="!editedItem.bombaRecalque"
                    :readonly="isActiveOnlyView">
                  </v-text-field>
                </v-col>
                <v-col cols="2" md="2" sm="6">
                  <v-text-field outlined label="Horas de Uso/dia" type="number" suffix="h"
                    v-model="editedItem.horasPorDiaBombasRecalque" :disabled="!editedItem.bombaRecalque"
                    :readonly="isActiveOnlyView">
                  </v-text-field>
                </v-col>
                <v-col cols="2" md="2" sm="6">
                  <v-text-field outlined label="Preço Médio do kWh" type="number" prefix="R$"
                    v-model="editedItem.kwhMedioRecalque" :disabled="!editedItem.bombaRecalque"
                    :readonly="isActiveOnlyView">
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="12" sm="1"></v-col>

                <v-col cols="3" md="3" sm="6">
                  <v-checkbox label="Bomba de Poço" v-model="editedItem.bombaPoco" :disabled="isActiveOnlyView"
                    :readonly="isActiveOnlyView">
                  </v-checkbox>
                </v-col>
                <v-col cols="3" md="3" sm="6">
                  <v-text-field outlined label="Qtd. de Bombas de Poço" type="number" v-model="editedItem.qtdBombasPoco"
                    :disabled="!editedItem.bombaPoco" :readonly="isActiveOnlyView">
                  </v-text-field>
                </v-col>
                <v-col cols="2" md="2" sm="6">
                  <v-text-field outlined label="Potência" type="number" suffix="CV"
                    v-model="editedItem.potenciaBombasPoco" :disabled="!editedItem.bombaPoco"
                    :readonly="isActiveOnlyView">
                  </v-text-field>
                </v-col>
                <v-col cols="2" md="2" sm="6">
                  <v-text-field outlined label="Horas de Uso/dia" type="number" suffix="h"
                    v-model="editedItem.horasPorDiaBombasPoco" :disabled="!editedItem.bombaPoco"
                    :readonly="isActiveOnlyView">
                  </v-text-field>
                </v-col>
                <v-col cols="2" md="2" sm="6">
                  <v-text-field outlined label="Preço Médio do kWh" type="number" prefix="R$"
                    v-model="editedItem.kwhMedioPoco" :disabled="!editedItem.bombaPoco" :readonly="isActiveOnlyView">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="12" sm="1"></v-col>
                <v-col cols="3" md="3" sm="6">
                  <v-checkbox label="Aquecimento a Gás" :disabled="isActiveOnlyView" v-model="editedItem.aquecimentoGas"
                    :readonly="isActiveOnlyView">
                  </v-checkbox>
                </v-col>
                <v-col cols="3" md="3" sm="6">

                  <v-radio-group outlined label="Tipo do Gás" :disabled="!editedItem.aquecimentoGas"
                    v-model="editedItem.tipoGas">
                    <v-radio :key="1" label="Gás Natural" value="Gás Natural" :readonly="isActiveOnlyView"></v-radio>
                    <v-radio :key="2" label="GLP" value="GLP" :readonly="isActiveOnlyView"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="3" md="3" sm="6">
                  <v-text-field outlined label="Consumo Mensal" type="number" :suffix="editedItem.tipoGas == 'Gás Natural' ? 'm³' : 'kg'
                    " v-model="editedItem.consumoGas" :disabled="!editedItem.aquecimentoGas" :readonly="isActiveOnlyView">
                  </v-text-field>
                </v-col>
                <!-- v-if="editedItem.aquecimentoGas && editedItem.tipoGas" -->
                <v-col cols="3" md="3" sm="6">
                  <v-text-field outlined label="Preço" type="number" :suffix="editedItem.tipoGas == 'Gás Natural'
                    ? 'reais/m³'
                    : 'reais/kg'
                    " v-model="editedItem.precoGas" :disabled="!editedItem.aquecimentoGas" :readonly="isActiveOnlyView">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="12" sm="1"></v-col>
                <v-col cols="3" md="3" sm="6">
                  <v-checkbox label="Ranking" v-model="editedItem.ranking" :disabled="isActiveOnlyView"
                    :readonly="isActiveOnlyView">
                  </v-checkbox>
                </v-col>
                <v-col cols="3" md="3" sm="6">
                  <v-radio-group outlined label="Tipo do Ranking" :disabled="!editedItem.ranking"
                    v-model="editedItem.tipoRanking">
                    <v-radio :key="1" label="por Consumo" value="por Consumo" :readonly="isActiveOnlyView"></v-radio>
                    <v-radio :key="2" label="por Ocorrências" value="por Ocorrências"
                      :readonly="isActiveOnlyView"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" md="12" sm="1"></v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item key="4">
              <v-row>
                <v-col cols="12">
                  <v-dialog v-model="sectorDialog" v-if="sectorDialog" max-width="450">
                    <v-card>
                      <v-card-title class="text-h5">
                        {{ flagEditModem ? "Editar Setor" : "Adicionar Setor" }}
                      </v-card-title>

                      <v-card-text>
                        <v-form ref="sectorForm" v-model="validSectorForm" lazy-validation>
                          <v-row>
                            <v-col cols="12">
                              <v-text-field outlined label="Nome do Setor" v-model="newSectorData.name"
                                :rules="rulesRequired" />
                              <v-autocomplete v-model="newSectorData.id_modem" :disabled="modensWithouUnitLoading"
                                :loading="modensWithouUnitLoading" :items="modensWithouUnit" outlined label="Modem"
                                item-text="name" item-value="id" style="margin-top: 5px;min-width: 200px"
                                :rules="rulesRequired" />
                            </v-col>
                            <v-col cols="6">
                              <v-autocomplete v-model="newSectorData.hydrometerModel" outlined
                                label="Tamanho Hidrômetro" :items="hydrometerModels" :rules="rulesRequired" />
                            </v-col>
                            <v-col cols="6">
                              <v-select v-model="newSectorData.waterSupplyType" outlined :items="waterSupplyTypeList"
                                label="Tipo" :rules="rulesRequired" />
                            </v-col>
                            <v-col cols="12">
                              <v-radio-group row label="Faturado?" v-model="newSectorData.invoiced" mandatory>
                                <v-radio label="Sim" :value="true"></v-radio>
                                <v-radio label="Não" :value="false"></v-radio>
                              </v-radio-group>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="darken-1" :disabled="sectorLoadings.save" text @click="closeSectorDialog()">
                          Cancelar
                        </v-btn>
                        <v-btn color="darken-1" :loading="sectorLoadings.save" text @click="saveSector()">
                          Salvar
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-data-table :loading="sectorLoadings.getData" :headers="headersSector" :items="sectorsByUnitList"
                    item-key="id_modem" class="elevation-1" no-data-text="Sem setores para exibir" hide-default-footer
                    :items-per-page="itemsSectors">

                    <template v-slot:top>
                      <v-toolbar flat class="rounded-lg">
                        <v-toolbar-title>Setores</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" class="mb-2" text @click="insertSector()" :disabled="isActiveOnlyView">
                          <v-icon>add</v-icon>
                        </v-btn>
                      </v-toolbar>
                    </template>

                    <template v-slot:item.actions="{ item }">

                      <v-btn :loading="loadingSectors(item, sectorLoadings.delete)" elevation="2"
                        @click="editSector(item)" style="height: 30px;margin: 2px;"
                        :disabled="isActiveOnlyView || sectorLoadings.delete">
                        <v-icon color="primary" small>
                          edit
                        </v-icon>
                      </v-btn>
                      <v-btn :loading="loadingSectors(item, sectorLoadings.delete)" elevation="2"
                        @click="removeSector(item)" style="height: 30px;margin: 2px;" v-if="canDeleted"
                        :disabled="isActiveOnlyView || sectorLoadings.delete">
                        <v-icon color="primary" small>
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </template>

                    <template v-slot:item.invoiced="{ item }">
                      <v-simple-checkbox v-model="item.invoiced" disabled />
                    </template>
                    <template v-slot:item.waterSupplyType="{ item }">
                      {{ waterSupplyTypeSlug(item.waterSupplyType) }}
                    </template>
                    <template v-slot:item.hydrometerModel="{ item }">
                      {{ hydrometerModelsSlug(item.hydrometerModel) }}
                    </template>

                  </v-data-table>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item key="5">
              <Historics :dataMediaHistorico="editedItem.dataMediaHistorico" :unit="editedItem"
                :historicos="historicsByUnitId" :disabled="isActiveOnlyView" @change="changeHistorics" />
            </v-tab-item>
            <v-tab-item key="6">
              <v-row>
                <v-col cols="6" sm="2" style="background-color: #1976D250;
                    min-width: 150px;
                    margin-right: 10px;
                    border-color: #1976D2;
                    border-bottom: 5px solid #1976D2; 
                    border-radius: 5px;">
                  <span>Antes da Foz</span>
                  <h2>M³ {{getAverageBeforeFoz}}</h2>
                </v-col>
                <v-col cols="6" sm="2" style="background-color: #1976D250;
                    min-width: 150px;
                    margin-right: 10px;
                    border-color: #1976D2;
                    border-bottom: 5px solid #1976D2; 
                    border-radius: 5px;">
                  <span>Meta Aconomia:</span>
                  <h2>M³ {{getGoalEconomy}}</h2>
                </v-col>
                <v-col sm="12" cols="12" style="
                  background: rgba(25, 118, 210, 0.02);
                  border-radius: 15px;
                  border: 1px solid rgb(204, 204, 204) !important;">
                  <h2 style="color: grey; padding: 10px;text-align: center;">Meta de Economia </h2>
                  <v-divider />
                  <v-col cols="12" style="margin-top: 25px;">
                    <v-slider v-model="editedItem.metaEconomia"
                      :label="editedItem.metaEconomia + ' ' + averageLine.name" :thumb-color="averageLine.color"
                      thumb-label="always" :disabled="isActiveOnlyView || !hasHistoricBefore"
                      :readonly="isActiveOnlyView || !hasHistoricBefore" min="0" max="100"></v-slider>
                  </v-col>
                </v-col>
              </v-row>


            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="save" :loading="saveButtonLoading" text :disabled="isActiveOnlyView || !validForm"
            color="primary">
            Salvar
          </v-btn>
        </v-card-actions>
        <v-overlay absolute v-model="saveButtonLoading">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>

    <v-dialog v-model="removeHistoricoDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          Confirma a remoção?
        </v-card-title>
        <v-card-text>
          O histórico será removido caso confirme
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="removeHistoricoDialog = false">
            <v-icon white>mdi-window-close</v-icon>

          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="removeHistorico()">
            Remover
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <date-picker-dialog title="Escolha a data de instalação" type="month" :show="dataMediaHistoricoDialog"
      @close="status => dataMediaHistoricoDialog = status" :input="editedItem.dataMediaHistorico"
      @change="newDate => editedItem.dataMediaHistorico = newDate" />

  </v-container>
</template>

<script>
import UnitScript from './UnitsScript';
export default UnitScript;
</script>


<style scoped>
.title-displays {
  font-size: 25px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.units-table-names {
  white-space: normal;
  font-size: 12px;
  font-weight: bold;
}

tbody,
tr:nth-child(even) {
  background: rgba(25, 118, 210, .08) !important
}

tbody,
tr:nth-child(odd) {
  background: white !important
}
</style>