<template>
  <v-row justify="center">
    <v-dialog v-model="dialogEmail" v-if="dialogEmail" persistent max-width="600px">
      <v-card>
        <v-progress-linear v-if="loadingBar" indeterminate color="white" class="mb-0"></v-progress-linear>
        <v-card-title>
          <span class="text-h5">Enviar Email</span>
        </v-card-title>
        <v-card-text>
          <v-divider />
          <v-form v-model="validForm" lazy-validation ref="FormNewCalled">
            <v-row>
              <v-col sm="4">
                <v-text-field :rules="rulesRequired" v-model="receiver" required label="Enviar para"></v-text-field>
              </v-col>
              <v-col sm="4">
                <v-text-field label="Mensagem Adicional"></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-divider />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="loadingBar" color="blue darken-1" text @click="send()">
            Enviar
          </v-btn>
          <v-btn :disabled="loadingBar" color="blue darken-1" text @click="dialogEmail = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Rules from "@/utils/Rules";
export default {
  props: {
    open: {
      type: Boolean,
      require: true,
      default: false,
    },
    runFunc: {
      type: Function,
      require: true,
    }
  },
  data: () => ({
    validForm: true,
    dialogEmail: false,
    loadingBar: false,
    receiver: null,
    message: null,
  }),
  methods: {
    async send() {
      if (this.$refs.FormNewCalled && !this.$refs.FormNewCalled.validate()) {
        return;
      }
      this.loadingBar = true
      await this.runFunc(this.receiver, this.message);
      this.loadingBar = false
      this.dialogEmail = false
    },
  },
  computed: {
    rulesRequired() {
      return Rules.required();
    },
  },
  watch: {
    open() {
      this.dialogEmail = true
    },
  },
}

</script>