
const alertas = {
    super_admin: true,
    admin: true,
    franqueado: true,
    tecnico: false,
    cliente: false, // true
    associado: false, // true
}
const configs = {
    super_admin: true,
    admin: true,
    franqueado: false,
    tecnico: false,
    cliente: false,
    associado: false,
}
const called = {
    super_admin: true,
    admin: true,
    franqueado: true,
    tecnico: false,
    cliente: true, // true
    associado: true, // true 
}
const comsunption = {
    super_admin: true,
    admin: true,
    franqueado: true,
    tecnico: false,
    cliente: true,
    associado: true,
}
const dashboard = {
    super_admin: true,
    admin: true,
    franqueado: true,
    tecnico: false,
    cliente: true, // true
    associado: false, // true
}
const waterFix = {
    super_admin: true, // true
    admin: true,  // true
    franqueado: true,  // true
    tecnico: false,
    cliente: false,
    associado: false,
}
const waterFixNewProposal = {
    super_admin: true,
    admin: true,
    franqueado: true,
    tecnico: false,
    cliente: false,
    associado: false,
}
const waterFixConsult = {
    super_admin: true,
    admin: true,
    franqueado: true,
    tecnico: false,
    cliente: false,
    associado: false,
}
const waterScan = {
    super_admin: true,
    admin: true,
    franqueado: true,
    tecnico: false,
    cliente: false,
    associado: false,
}


const waterScanNewProposal = {
    super_admin: true,
    admin: true,
    franqueado: true,
    tecnico: false,
    cliente: false,
    associado: false,
}
const waterScanConsult = {
    super_admin: true,
    admin: true,
    franqueado: true,
    tecnico: false,
    cliente: false,
    associado: false,
}
const waterSolutionsService = {
    super_admin: true,
    admin: true,
    franqueado: false,
    tecnico: false,
    cliente: false,
    associado: false,
}
const users = {
    super_admin: true,
    admin: true,
    franqueado: false,
    tecnico: false,
    cliente: false,
    associado: false,
}
const units = {
    super_admin: true,
    admin: true,
    franqueado: true,
    tecnico: false,
    cliente: true,
    associado: true,
}
const modems = {
    super_admin: true,
    admin: true,
    franqueado: false,
    tecnico: false,
    cliente: false,
    associado: false
}
const solutions = {
    super_admin: true,
    admin: true,
    franqueado: false,
    tecnico: false,
    cliente: false,
    associado: false
}
const concessionaires = {
    super_admin: true,
    admin: true,
    franqueado: false,
    tecnico: false,
    cliente: false,
    associado: false,
}
const goalsAndForecasts = {
    super_admin: false,
    admin: false,
    franqueado: false,
    tecnico: false,
    cliente: false, //true
    associado: false, //true
}
const report = {
    super_admin: true,
    admin: true,
    franqueado: true,
    tecnico: false,
    cliente: true,
    associado: true,
}
const reportCliente = {
    super_admin: true,
    admin: true,
    franqueado: true,
    tecnico: false,
    cliente: true,
    associado: true,
}
const reportUnits = {
    super_admin: true,
    admin: true,
    franqueado: true,
    tecnico: false,
    cliente: true,
    associado: true,
}

export default {
    reportUnits,
    reportCliente,
    report,
    goalsAndForecasts,
    concessionaires,
    modems,
    units,
    users,
    waterScanConsult,
    waterScanNewProposal,
    waterSolutionsService,
    waterScan,
    waterFix,
    waterFixNewProposal,
    waterFixConsult,
    dashboard,
    comsunption,
    called,
    configs,
    alertas,
    solutions,
}