import store from '@/store/index'

const baseDialogAlert = (
    title = null,
    text = 'Deseja realizar essa ação?',
    runFunc = () => {},
    closeFunc = () => {},
) => {
    store.dispatch('dialogAlert', {
        title,
        text,
        open: !store.state.optionsDialogAlert.open,
        runFunc,
        closeFunc,
    })
}
const showViewDialogAlert = (
    text,
    runFunc = () => {},
    closeFunc = () => {},
) => {
    baseDialogAlert('Visualizar', text, runFunc, closeFunc)
}
const showDeleteDialogAlert = (
    text,
    runFunc = () => {},
    closeFunc = () => {},
) => {
    baseDialogAlert('Excluir', text, runFunc, closeFunc)
}
const showAditionDialogAlert = (
    text,
    runFunc = () => {},
    closeFunc = () => {},
) => {
    baseDialogAlert('Adicionar', text, runFunc, closeFunc)
}

export {
    showViewDialogAlert,
    showDeleteDialogAlert,
    showAditionDialogAlert,
}