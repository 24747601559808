<template>
    <v-form ref="locationInformationForm" >
    <v-overlay absolute style="opacity: 0.6" :value="isLoadingCNPJ">
      <v-progress-circular
        indeterminate
        color="primary"
        :size="50"
      ></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col cols="12" v-if="getCurrentUserRank === 'Super Admin'">
        <v-autocomplete 
          v-model="selected_franchisee" 
          :items="franchisees_list" 
          item-text="name" 
          item-value="id"
          label="Franqueado" 
          dense 
          outlined 
          hide-details 
          :rules="rulesRequired"
          @change="someFieldHasChanged"
        />
      </v-col>
      <v-col cols="12">
        <h3>Informações do local:</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4" lg="4" xl="4" sm="4" cols="12">
        <v-radio-group
          v-model="enrollObj.localeData.idType"
          @change="selectRadiofCpfCnpj()"
          :rules="rulesRequired"
          style="margin: 0"
          required
        >
          <v-radio :key="1" :label="'CPF'" value="CPF"></v-radio>
          <v-radio :key="2" :label="'CNPJ'" value="CNPJ"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col md="8" lg="8" xl="8" sm="8" cols="12">
        <v-text-field
          v-if="enrollObj.localeData.idType == 'CPF'"
          label="CPF"
          outlined
          v-model="enrollObj.localeData.idNumber"
          v-mask="'###.###.###-##'"
          required
          ref="refCpf"
          :rules="rulesIdNumber"
          @change="someFieldHasChanged"
        ></v-text-field>
        <v-text-field
          v-else-if="enrollObj.localeData.idType == 'CNPJ'"
          label="CNPJ"
          outlined
          v-model="enrollObj.localeData.idNumber"
          v-mask="'##.###.###/####-##'"
          required
          @blur="consultaCnpj"
          ref="refCnpj"
          :rules="rulesIdNumber"
          @change="someFieldHasChanged"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="6" sm="6" md="6" lg="4">
        <v-text-field
          outlined
          label="Nome do Local"
          prepend-inner-icon="person"
          v-model="enrollObj.name"
          :rules="rulesRequired"
          counter="64"
          required
          @change="someFieldHasChanged"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" xl="6" sm="6" md="6" lg="4">
        <v-text-field
          outlined
          prepend-inner-icon="alternate_email"
          v-model="enrollObj.email"
          color="primary"
          label="E-mail"
          :rules="rulesEmail"
          required
          counter="64"
          @change="someFieldHasChanged"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="6" sm="6" md="6" lg="4">
        <v-text-field
          label="Telefone / Celular"
          outlined
          v-mask="'(XX) XXXXX-XXXX'"
          v-model="enrollObj.localeData.phone"
          :rules="rulesPhone"
          required
          @change="someFieldHasChanged"
        ></v-text-field>
      </v-col>      
    </v-row>
    <!-- Inicio Endereço -->
    <v-divider></v-divider>
    <v-row>
      <v-col cols="12">
        <h3>Endereço:</h3>
      </v-col>
    </v-row>
    <AddressForm
      :adressInfo="enrollObj.localeData.address"
      :requestData="requestAdress"
      v-on:update:address="updateAddress"
    />
    <!-- Fim Endereço -->
    <v-divider></v-divider>
    <!-- Inicio Informações do responsável -->
    <v-row>
      <v-col cols="12">
        <h3>Informações do responsável:</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="6" sm="6" md="6" lg="4">
        <v-text-field
          outlined
          label="Nome do responsável"
          prepend-inner-icon="person"
          v-model="enrollObj.responsible.name"
          :rules="rulesName"
          counter="64"
          @change="someFieldHasChanged"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" xl="6" sm="6" md="6" lg="4">
        <v-text-field
          outlined
          prepend-inner-icon="alternate_email"
          v-model="enrollObj.responsible.email"
          color="primary"
          label="E-mail"
          :rules="rulesEmailNotRequired"
          counter="64"
          @change="someFieldHasChanged"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="6" sm="6" md="6" lg="4">
        <v-text-field
          label="CPF"
          outlined
          v-mask="'###.###.###-##'"          
          v-model="enrollObj.responsible.idNumber"
          ref="refCpf"
          :rules="rulesCPFNotRequired"
          @change="someFieldHasChanged"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="6" sm="6" md="6" lg="4">
        <v-text-field
          label="Telefone"
          outlined
          v-mask="'(XX) XXXXX-XXXX'"
          v-model="enrollObj.responsible.phone"
          :rules="rulesPhone"
          @change="someFieldHasChanged"
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- Fim Informações do responsável -->
  </v-form>
</template>
<script>
import Rules from "@/utils/Rules";
import AddressForm from "../NewerAddress.vue";
import ObjectValidator from "../../../scripts/ObjectValidator";
import { jsonp } from "vue-jsonp";
import firebase from "../../../firebase";
import Utils from '@/scripts/Utils';
import WaterFixService from '@/services/firebase/WaterFix/WaterFixService';
import WaterScanService from '@/services/firebase/WaterScan/WaterScanService';
import { mapActions, mapGetters } from "vuex";

import UsersService from "@/services/firebase/Users/UsersService";

export default {
  props: {
    registration: {
      default: ""
    },
    requestSave: {
      default: null,
    },
    validFormProp: {
      default: () => {},
    },
    closeProposed: {
      default: () => {},
    },
  },
  name: "locationInformation",
  components: {
    AddressForm,
  },
  data: function () {
    return {
        isLoadingCNPJ: false,
        // addressObj: {},
        isPwsVisible: false,
        requestAdress: false,
        enrollObj: ObjectValidator.create("enroll"),

        // --------------------------------------------------------
        // --------------------------------------------------------
        // --------------------------------------------------------

        index: 0,
        thereAreChanges: false,
        // hasDocumentCreatedInWaterFix: false,
        proposalId: null,
        selected_franchisee: null,
        franchisees_list: [],
    };
  },
  methods: {
    ...mapActions([
      'setWaterFixItemsToImport',
      'setWaterFixOpenStatus',
      'setWateFixProposal',
      'publishChannel',
    ]),
    clearObject() {
      let enrollObject = ObjectValidator.create("enroll");
      enrollObject.localeData.idType = this.enrollObj.localeData.idType;
      enrollObject.responsible.idType = "CPF";
      this.enrollObj = enrollObject;
    },
    selectRadiofCpfCnpj() {
      this.enrollObj.localeData.idNumber = null;
      this.clearObject();
      this.someFieldHasChanged()
    },
    consultaCnpj() {
      if (this.enrollObj.localeData.idNumber !== null) {
        if (this.enrollObj.localeData.idNumber.length == 18) {
          this.isLoadingCNPJ = true;
          jsonp(
            "https://receitaws.com.br/v1/cnpj/" +
              this.enrollObj.localeData.idNumber
                .split(".")
                .join("")
                .split("/")
                .join("")
                .split("-")
                .join("")
          )
            .then((response) => {
              this.enrollObj.name = response.fantasia;
              this.enrollObj.localeData.phone = response.telefone;
              this.enrollObj.email = response.email;
              this.enrollObj.localeData["address"] = {
                logradouro: response.logradouro,
                numero: response.numero,
                complemento: response.complemento,
                bairro: response.bairro,
                cidade: response.municipio,
                cep: response.cep,
                uf: response.uf,
              };
              this.usersKey++;
              this.isLoadingCNPJ = false;
            })
            .catch((err) => {
              this.isLoadingCNPJ = false;
              console.error(err);
            });
        }
      }
    },
    async synchronizeProposalinFirebase() {
      this.emitLoadingEvent(true)
      await Utils.sleep(250)
      try {
        if (this.proposalId !== null) {
          await WaterFixService.updateProposal(this.proposalId, { 
            id_franchisee: this.selected_franchisee, 
            locationInformation: this.enrollObj 
          })
        } else {
          this.proposalId = await WaterFixService.createProposal({ registration: this.registration, locationInformation: this.enrollObj })
        }
        this.emitLoadingEvent(false)
      } catch (e) {
        this.emitLoadingEvent(false)
        console.error("LocationInformation - Erro ao ATUALIZAR proposta!");
      }
    },
    // ---------------------------------------------------------------------
    // ---------------------------------------------------------------------
    // ---------------------------------------------------------------------
    emitLoadingEvent(status) {
      this.$emit("loading", status);
    },
    emitSavePageEvent() {
      this.$emit("page:save", 'LocationInformation');
    },
    emitNewProposal(status) {
      this.$emit("new:proposal", status)
    },
    emitProposalCreated() {
      this.$emit("created:proposal", true)
    },
    updateAddress(addressObj) {
      this.enrollObj.localeData.address = Utils.copyObject(addressObj)
      this.someFieldHasChanged();
    },
    async getProposalFromWaterFix(registration) {
      // Verificar se existe alguma proposta de water fix
      return await WaterFixService.getProposalByRegistration(registration)
    },
    async getProposalFromWaterScan(registration) {
        return await WaterScanService.getProposalByRegistration(registration)
    },
    setThereAreChanges(status) {
      this.thereAreChanges = status;
    },
    someFieldHasChanged() {
      this.setThereAreChanges(true);
    },
    isValidadePage() {
      if ((this.$refs.locationInformationForm) && !this.$refs.locationInformationForm.validate()) {
        return false;
      }
      if (!this.enrollObj.localeData.address.cep) {
        return false;
      }
      return true;
    },
    async saveAll() {
        if (!this.thereAreChanges) return;
        await this.synchronizeProposalinFirebase();
        this.setThereAreChanges(false)
    },
    async checkSolutionList(proposal) {
      if (this.waterFixOpenStatus == 'register' && !this.waterFixItemsToImport.includes('Check List')) {
        let proposalEdited = Utils.copyObject(proposal)
        proposalEdited.solutionList = []
        await WaterFixService.updateProposal(this.proposalId, { solutionList: [] })
        return proposalEdited;
      } else {
        proposal.solutionList = proposal.solutionList.map(
          (item) => {
            return {
              ...item,
              imgNameList: item.hasOwnProperty('imgNameList') ? item.imgNameList : null,
              status: item.hasOwnProperty('status') ? item.status : null,
              sub_local: item.hasOwnProperty('sub_local') ? item.sub_local : null,
            }
          }
        )
        await WaterFixService.updateProposal(this.proposalId, { solutionList: Utils.copyObject(proposal.solutionList) })
        return proposal;
      }
    },
    async saveMiddlewareWaterFix(newProposal) {
      this.proposalId = await WaterFixService.createProposal(newProposal)
      await this.checkProposalStruct(this.proposalId, newProposal)
      newProposal = await this.checkSolutionList(newProposal)
      this.setWateFixProposal(Utils.copyObject(newProposal))
      this.setWaterFixItemsToImport([])
      this.setWaterFixOpenStatus('view')
      this.publishChannel({
            channel: 'waterfix',
            event: `proposal`,
            value: newProposal,
        })
    },
    async checkProposalStruct(proposalId, proposal) {
      let isUpdate = false;
      let keysToUpdate = []
      if (!proposal.hasOwnProperty('devolutionList')) {
        proposal['devolutionList'] = [];
        keysToUpdate.push('devolutionList')
        isUpdate = true
      }
      if (!proposal.hasOwnProperty('compromiseList')) {
        proposal['compromiseList'] = [];
        keysToUpdate.push('compromiseList')
        isUpdate = true
      }
      if (!proposal.hasOwnProperty('installationList')) {
        proposal['installationList'] = [];
        keysToUpdate.push('installationList')
        isUpdate = true
      }
      if (isUpdate) {
        let proposalToSave = {}
        keysToUpdate.forEach(
          (key) => {
            proposalToSave[key] = proposal[key]
          }
        );
        await WaterFixService.updateProposal(this.proposalId, proposalToSave)
      }
    }
  },
  computed: {
    ...mapGetters([
      'waterFixItemsToImport',
      'waterFixOpenStatus',
      'getCurrentUser',
      "getCurrentUserRank",
      "getCurrentUserName",
    ]),
    rulesIdNumber() {
      return this.enrollObj["localeData"].idType === "CPF"
        ? Rules.cpf()
        : Rules.cnpj();
    },
    rulesRequired() {
      return Rules.required();
    },
    rulesPhone() {
      return Rules.phone();
    },
    rulesEmail() {
      return Rules.email(true, 64);
    },
    rulesEmailNotRequired() {
      return Rules.email(false, 64);
    },
    rulesCPFNotRequired() {
      return Rules.cpf(false);
    },
    rulesCNPJNotRequired() {
      return Rules.cnpj(false);
    },
    rulesName() {
      return Rules.string(true, 1, 64);
    },
  },
  async created() {
    const request_franchisee_list = async () => {
      return new Promise(
        async (resolve, reject) => {
          if (this.getCurrentUserRank === "Franqueado") {
            this.selected_franchisee = this.getCurrentUser.uid 
            resolve({
              name: this.getCurrentUserName,
              id: this.getCurrentUser.uid
            })
          } else if (this.getCurrentUserRank === "Super Admin") {
            try {
              let res = await UsersService.getUsersByRank(2)
              res = res.map(
                ({ id, name, }) => {
                  return { id, name, }
                }
              )
              resolve(res)
            } catch (e) {
              console.error(e)
              reject(e)
            }
          }
        }
      )
    }
    this.franchisees_list = await request_franchisee_list()
    this.emitLoadingEvent(true)
    // Verificando se existe a proposta no water fix
    let proposalFromWaterFix = await this.getProposalFromWaterFix(this.registration);
    if (proposalFromWaterFix) {
        this.publishChannel({
            channel: 'waterfix',
            event: `proposal`,
            value: proposalFromWaterFix,
        })
        this.proposalId = proposalFromWaterFix.id;
        this.selected_franchisee = proposalFromWaterFix.id_franchisee ? proposalFromWaterFix.id_franchisee : null
        await this.checkProposalStruct(this.proposalId, proposalFromWaterFix)
        proposalFromWaterFix = await this.checkSolutionList(proposalFromWaterFix)
        this.setWaterFixItemsToImport([])
        this.setWaterFixOpenStatus('view')
        this.enrollObj = proposalFromWaterFix.locationInformation;
        this.setWateFixProposal(Utils.copyObject(proposalFromWaterFix))
        // this.hasDocumentCreatedInWaterFix = true;
        this.emitLoadingEvent(false);
        this.emitNewProposal(false);
        this.emitProposalCreated();
        return;
    }
    // Verificando se existe a proposta no water scan para salvar no water fix
    let proposalFromWaterScan = await this.getProposalFromWaterScan(this.registration);
    if (proposalFromWaterScan) {
        this.selected_franchisee = proposalFromWaterScan.id_franchisee ? proposalFromWaterFix.id_franchisee : null
        this.enrollObj = proposalFromWaterScan.locationInformation;
        // this.hasDocumentCreatedInWaterFix = false;
        // Salvar o objeto no waterfix
        await this.saveMiddlewareWaterFix(proposalFromWaterScan);
        this.emitLoadingEvent(false);
        this.emitNewProposal(false);
        this.emitProposalCreated();
        return;
    }

    this.emitNewProposal(true);
    this.emitLoadingEvent(false);
    this.emitProposalCreated();
  },
};
</script>