<template>
  <div class="text-center">
    <!-- <v-btn dark color="orange darken-2" @click="snackbar = true">
      TEST
    </v-btn> -->
    <v-snackbar
      :color="severity"
      top
      right
      transition="slide-x-reverse-transition"
      v-model="snackbar"
      :timeout="timeout"
    >
      <strong>{{ text }}</strong>
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
export default {
  data: () => ({
    snackbar: false,
    timeout: 4000,
  }),
  props: {
    open: {
      default: false,
      type: Boolean,
    },
    text: {
      default: "",
      type: String,
    },
    severity:{
      type: String,
      default: "info",
    }
  },
  watch: {
    open() {
      this.snackbar = true;
    },
  },
  created() {},
};
</script>

