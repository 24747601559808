<template>
    <v-form ref="formConsumption" v-model="validForm" lazy-validation>
        <v-row>
            <v-col cols="12">
                <h3>Teste de Consumo Soluções:</h3>
            </v-col>
        </v-row>
        <v-row>
            <!-- Start Table Solutions -->
            <v-data-table
                :headers="headers"
                :items="list"
                class="elevation-1"
                style="width: 100%"
                :items-per-page="itemsPerPage"
            >
                <template v-slot:top>
                    <v-toolbar flat class="rounded-lg">
                        <v-toolbar-title>Solução</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="primary"
                            class="mb-2"
                            text
                            @click="() => create()"
                        >
                            <v-icon>add</v-icon>
                        </v-btn>
                    </v-toolbar>
                </template>

                <template v-slot:body="{ items }">
                    <tbody>
                        <tr v-for="(solution, index) in items" :key="index">
                            <td>
                                <v-text-field       
                                    readonly             
                                    v-model="solution.local" 
                                    :rules="rulesRequired"
                                />
                            </td>
                            <td>
                                <v-text-field    
                                    readonly                
                                    v-model="solution.sublocal" 
                                />
                            </td>
                            <td>
                                <v-text-field   
                                    readonly
                                    :value="convertNameType(solution.type)"
                                    :rules="rulesRequired"
                                />
                            </td>
                            <td>
                                <v-text-field
                                    readonly
                                    :value="convertSolutionName(solution.solution)"
                                    :rules="rulesRequired"
                                />
                            </td>
                            <td>
                                <v-text-field 
                                    readonly
                                    type="Number" 
                                    v-model="solution.amountSolution" 
                                    :rules="rulesRequired"
                                />
                            </td>
                            <td>
                                <v-text-field
                                    readonly
                                    type="Number"
                                    v-model="solution.flowRateBefore"
                                    suffix="L/min"
                                    :rules="rulesRequired"
                                />
                            </td>
                            <td>
                                <v-text-field
                                    readonly
                                    type="Number"
                                    v-model="solution.flowRateAfter"
                                    suffix="L/min"
                                    :rules="rulesRequired"
                                />
                            </td>
                            <td width="90px">
                                <v-text-field
                                    readonly
                                    type="Number"
                                    v-model="solution.economy"
                                    suffix="%"
                                />
                            </td>
                            <td>
                                <v-text-field 
                                    readonly 
                                    v-model="solution.comments"
                                />
                            </td>
                            <td>
                                <v-icon 
                                    small
                                    :color="changeColorIconListImg(solution)"
                                >mdi-image</v-icon>
                            </td>
                            <td>
                                <v-btn
                                    elevation="2"
                                    :loading="loading"
                                    @click="() => confirmDeleteSolution(solution, index)"
                                    style="height: 30px; margin: 2px"
                                >
                                    <v-icon color="primary" small> mdi-delete </v-icon>
                                </v-btn>
                                <v-btn
                                    elevation="2"
                                    :loading="loading"
                                    @click="() => edit(index)"
                                    style="height: 30px; margin: 2px"
                                >
                                    <v-icon color="primary" small> mdi-pencil </v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>
            <!-- End Table Solutions -->
        </v-row>
        <v-dialog 
            transition="dialog-top-transition" 
            v-model="dialog" 
            v-if="dialog" 
            persistent max-width="700px"
        >
            <v-card>
                <v-toolbar color="primary" dark> <span class="text-h5">Adicionar Solução</span></v-toolbar>
                <v-card-title>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text style="padding-bottom: 0px;">
                    <v-row>
                        <h3> Informações do Ponto</h3>
                    </v-row>
                    <v-row>
                        <v-col cols="12" :sm="6">
                            <v-text-field 
                                v-model="solution.local" 
                                outlined 
                                label="Local" 
                                type="text" 
                                required
                                :rules="rulesRequired"
                                :disabled="loading"
                                :loading="loading"
                            />
                        </v-col>
                        <v-col cols="12" :sm="6">
                            <v-text-field 
                                v-model="solution.sub_local" 
                                outlined 
                                label="Sub-Local" 
                                type="text" 
                                required
                                :rules="rulesRequired"
                                :disabled="loading"
                                :loading="loading"
                            />
                        </v-col>
                        <v-col cols="12" :sm="solution.pipingType ? 6 : 4">
                            <v-autocomplete 
                                label="Tipo" 
                                :items="pointsData" 
                                item-text="name"
                                item-value="id" 
                                outlined 
                                v-model="solution.type"
                                @change="optionTypeSelect(solution.type)" 
                                :rules="rulesRequired" 
                                onfocus
                                :disabled="loading"
                                :loading="loading"
                            />
                        </v-col>
                        <v-col v-if="solution.pipingType" cols="12" :sm="solution.pipingType ? 6 : 4">
                            <v-select
                                :items="pipingTypesList"
                                v-model="solution.pipingType"
                                label="Tipo da Tubulação"
                                outlined
                                :disabled="loading"
                                :loading="loading"
                            />
                        </v-col>
                        <v-col cols="12" :sm="solution.pipingType ? 6 : 4">
                            <v-autocomplete label="Soluções" 
                                :items="solutionsOptionsList" 
                                v-model="solution.solution" 
                                outlined
                                item-text="solution"
                                item-value="id"
                                :rules="rulesRequired"
                                :disabled="loading || solution.type === null"
                                :loading="loading"
                            />
                        </v-col>
                        <v-col cols="12" :sm="solution.pipingType ? 6 : 4">
                            <v-text-field 
                                v-model="solution.amountSolution" 
                                outlined 
                                label="Qtnd." 
                                type="Number" 
                                required
                                :rules="rulesRequired"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" :sm="4">
                            <v-text-field 
                                v-model="solution.flowRateBefore" 
                                outlined 
                                label="Vazão antes" 
                                type="Number"
                                suffix="L/min" 
                                required 
                                :rules="rulesRequired"
                                :disabled="loading"
                                :loading="loading"
                            />
                        </v-col>
                        <v-col cols="12" :sm="4">
                            <v-text-field 
                                v-model="solution.flowRateAfter" 
                                outlined 
                                label="Vazão Depois" 
                                type="Number"
                                suffix="L/min" 
                                required 
                                :rules="rulesRequired"
                                :disabled="loading"
                                :loading="loading"
                            />
                        </v-col>
                        <v-col cols="12" :sm="4">
                            <v-select
                                :items="flowTypeList"
                                v-model="solution.flowRateType"
                                label="Tipo do fluxo"
                                outlined
                                :disabled="loading"
                                :loading="loading"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-text-field 
                                v-model="solution.economy" 
                                outlined 
                                label="Economia" 
                                readonly 
                                suffix="%"
                                :disabled="loading"
                                :loading="loading"
                            />
                        </v-col>
                        <v-col cols="12" :sm="12">
                            <v-textarea 
                                v-model="solution.comments" 
                                outlined 
                                label="Observação" 
                                type="text"
                                :disabled="loading"
                                :loading="loading"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <h3> Adicionar Imagem </h3>
                        <v-btn 
                            color="primary" 
                            text 
                            @click="pickFile()"
                            :disabled="loading || loadingImage"
                            :loading="loading || loadingImage"
                        >
                            <v-icon>add</v-icon>
                        </v-btn>
                        <input 
                            multiple 
                            type="file" 
                            id="get_image" 
                            ref="get_image" 
                            accept="image/*" 
                            style="display: none"
                            @input="onFilePicked"
                            :disabled="loading || loadingImage"
                            :loading="loading || loadingImage"
                        />
                    </v-row>
                    <vue-horizontal>
                        <section style=" margin: 10px;" v-for="img, n in listImgs" :key="n">
                            <!-- :lazy-src="`https://localhost:8080/favicon.ico`" -->
                            <v-img 
                                :src="img.image" 
                                aspect-ratio="1" 
                                style="width: 200px" 
                                @click="deleteImgSelect(img)"
                                :disabled="loading || loadingImage"
                                :loading="loading || loadingImage"
                            >
                                <template v-slot:placeholder>
                                    <v-row class="fill-height ma-0" align="center" justify="center">
                                        <v-progress-circular indeterminate color="grey-lighten-5"></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                        </section>
                    </vue-horizontal>
                <!-- <video autoplay id="video"></video> -->
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
                        color="blue darken-1" 
                        text 
                        @click="() => closeDialog()"
                        :disabled="loading"
                        :loading="loading"
                    >Cancelar</v-btn>
                    <v-btn 
                        color="blue darken-1" 
                        text 
                        @click="() => save()"
                        :disabled="loading"
                        :loading="loading"
                    >Salvar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
</template>
  
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Rules from "@/utils/Rules";
import Utils from '@/scripts/Utils';
import { showInfoSnackBar } from "@/utils/SnackBartHelper";
import solutionInsert from "@/components/FormsComponents/DialogCheckList/Solution";
import WaterScanService from '@/services/firebase/WaterScan/WaterScanService';
import WaterScanStoragefrom from '@/services/storage/WaterScanStorage';
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper";
import SolutionsServices from '@/services/firebase/SolutionsServices/SolutionsServices';
import SolutionsPoint from '@/services/firebase/SolutionsPoint/SolutionsPoint';

import SolutionListModel from '@/objects/Waterscan/CheckListTab/SolutionListModel.js'
import VueHorizontal from 'vue-horizontal';


const newSolution = () => {
    return {
        amountSolution: "",
        economy: "",
        flowRateBefore: "",
        flowRateAfter: "",
        local: "",
        sub_local: "",
        solution: "",
        type: "",
        pipingType: "",
        flowRateType: "",
        economy: 0,
        comments: "",
        imgNameList: [],
    }
}

const PIPING_POINT_ID = "vIEqBb4EEnWAJU5HbYXs"

export default {
    name: "SolutionList",
    
    components: {
        VueHorizontal,
    },
    
    props: {
        list: {
            type: Array,
            default: () => ([]),
        },
        add: {
            type: Function,
            default: (solution, index) => {}
        },
        remove: {
            type: Function,
            default: (index) => {}
        },
        projectId: {
            type: String,
            default: null,
        },
    },
    
    data: () => ({
        solution: newSolution(),
        dialog: false,
        index: -1,
        loading: false,
        loadingImage: false,
        validForm: true,
        headers: [
            { text: "Local", value: "local", width: 150, sortable: false },
            { text: "Sub local", value: "sub_local", width: 150, sortable: false },
            { text: "Tipo", value: "name", width: 130, sortable: false },
            { text: "Solução", value: "solution", width: 200, sortable: false },
            { text: "Quantidade", value: "amount", width: 100, sortable: false },
            { text: "Vazão Antes", value: "flowRateBefore", width: 130, sortable: false },
            { text: "Vazão Depois", value: "flowRateAfter", width: 130, sortable: false },
            { text: "Economia (%) ", value: "economy", sortable: false },
            { text: "Observação", value: "comments", sortable: false },
            { text: "Img", value: "Img", sortable: false },
            { text: "Ações", value: "action", sortable: false },
        ],
        optionsType: [
            { text: "Torneira", value: "faucet" },
            { text: "Chuveiro", value: "shower" },
            { text: "Descarga", value: "discharge" },
        ],
        pipingTypesList: SolutionListModel.getPipingTypeList(),
        flowTypeList: SolutionListModel.getFlowTypeList(),
        solutionsData: [],
        pointsData: [],

        listImgs: [],
        editedItem: {},
        deleteImgFile: {},
        rawFiles: [],
        itemsPerPage: 5,
    }),

    computed: {
        rulesRequired() {
            return Rules.required();
        },
        solutionsOptionsList() {
            return this.optionTypeSelect(this.solution.type)
        },
    },

    methods: {
        create() {
            this.index = -1
            this.solution = newSolution()
            this.dialog = true
        },
        async edit(index) {
            this.index = index
            this.solution = Utils.copyObject(this.list[index])
            this.dialog = true
            this.loadingImage = true
            await this.getStorageImages(this.solution.imgNameList)
            this.loadingImage = false
        },
        async save() {
            this.loading = true
            for (let file of this.rawFiles) {
                let filepath = await WaterScanStoragefrom.uploadDiagnosticImgProject(this.projectId, file)
                this.solution.imgNameList.push(filepath) 
            }
            this.add(Utils.copyObject(this.solution), this.index)
            this.dialog = false
            this.loading = false
        },
        closeDialog() {
            this.dialog = false
        },


        optionTypeSelect(type_id) {
            return this.solutionsData.filter(({pointId}) => pointId == type_id)
        },
        async listSolutions(){
            this.solutionsData =  await SolutionsServices.GetSolutions()
        },
        async listPoints(){
            let pointIds = this.solutionsData.map((item) => item.type == 'Produto' ? item.pointId : null).filter((item) => item !== null && item !== undefined)
            let response =  await SolutionsPoint.GetSolutionsPointByIds(pointIds)
            this.pointsData = Object.values(response)
        },
        pickFile() {
            this.$refs.get_image.click();
        },
        async deleteImgSelect(event) {
            this.deleteImgFile = event
            showDeleteDialogAlert(`Deseja Excluir a imagem ${event.imageName}`, this.deleteImg)
        },
        async deleteImg() {
            if(this.deleteImgFile.pathFirebase) await WaterScanStoragefrom.deleteImg(this.deleteImgFile.pathFirebase)
            if(this.solutionReport.imgNameList?.length)this.solutionReport.imgNameList = this.solutionReport.imgNameList.filter(( pathFirebase ) => pathFirebase != this.deleteImgFile.pathFirebase)
            this.listImgs = this.listImgs.filter(({ image }) => image != this.deleteImgFile.image)
        },
        async onFilePicked(e) {
            const inputFiles = document.getElementById("get_image").files;
            if (inputFiles[0] !== undefined && inputFiles[0].type.split("/")[0] == "image") {
                this.rawFiles.push(...document.getElementById("get_image").files)
                for (let file of inputFiles) {
                    await this.processImg(file)
                }
            } else {
                showErrorSnackBar("Arquivo inválido.")
                this.editedItem.image = "";
                this.editedItem.imageName = "";
            }
        },
        async processImg(file) {
            try {
                this.editedItem = {}
                this.editedItem.imageName = file.name;
                if (this.editedItem.imageName.lastIndexOf(".") <= 0) reject("Nome Invalido")
                var output = document.getElementById('output');
                this.listImgs.unshift({
                    image: URL.createObjectURL(file),
                    imageName: file.name
                })

            } catch (err) {
                showErrorSnackBar("Erro ao processar imagem.")
            }
        },
        async getStorageImages(listImgs) {
            for (let imgPath of listImgs) {
                const urlImg = await WaterScanStoragefrom.downloadImg(imgPath)

                const mainPathEnd = imgPath.indexOf('/')+1
                const matricula = imgPath.slice(mainPathEnd)
                const matriculaEnd = imgPath.slice(mainPathEnd).indexOf('/')+1
                const imgNameOrigin = matricula.slice(matriculaEnd+14)
            
                this.listImgs.unshift({
                    image: urlImg,
                    pathFirebase: imgPath,
                    imageName: imgNameOrigin
                })
            }
        },
        calculateEconomy() {
            let flowRateBefore = this.solution.flowRateBefore
            let flowRateAfter = this.solution.flowRateAfter
            if (flowRateBefore == 0) return 0
            let economy = 100 - ((flowRateAfter / flowRateBefore) * 100)
            economy = parseFloat(Math.abs(economy).toFixed(2))
            this.solution.economy = economy
        },
        verifyPipingType() {
            let type = this.solution.type
            let pipingType = this.solution.pipingType
            if (type !== PIPING_POINT_ID) {
                this.solution.pipingType = ""
            } else {
                if (pipingType === "") {
                    this.solution.pipingType = "PVC"
                }
            }
        },
        verifyFlowRateType() {
            let flowRateType = this.solution.flowRateType
            if (flowRateType === "") {
                this.solution.flowRateType = "REAL"
            }
        },
        convertNameType(pointId){
            const type = this.pointsData.filter(({id}) => id == pointId)
            return type.length > 0 ? type[0].name : "Não Existe"
        },
        convertSolutionName(solutionId){
            const solutionFilter = this.solutionsData.filter(({id}) => id == solutionId)
            return solutionFilter.length > 0? solutionFilter[0].solution : "Não Existe"
        },
        async confirmDeleteSolution(item, index) {
            showDeleteDialogAlert(`Deseja excluir esse item ${item.local}?`, () => this.remove(index))
        },
        changeColorIconListImg(solution) {
            if(solution.imgNameList && solution.imgNameList.length > 0){
                return "primary"
            }
            return ""
        },
    },

    watch: {
        dialog(newValue) {
            if (!newValue) {
                this.listImgs = [];
                this.editedItem = {};
                this.deleteImgFile = {};
                this.rawFiles = [];
            }
        },
        'solution.flowRateBefore': function (newValue) {
            this.calculateEconomy()
        },
        'solution.flowRateAfter': function (newValue) {
            this.calculateEconomy()
        },
        'solution.type': function (newValue) {
            this.verifyPipingType()
        },
        'solution.flowRateType': function () {
            this.verifyFlowRateType()
        },
    },

    async created() {
        await this.listSolutions()
        await this.listPoints()
    }

};
</script>
  
<style scoped>

</style>