import { db } from "@/firebase"
import Utils from '@/scripts/Utils';
const solutions_point_ref = db.collection("solutions_point")

async function getSolutionsPoint() {
    try {
        let solutionsPoint = []
        const get_data = await solutions_point_ref.get();
        get_data.forEach((doc) => solutionsPoint.push(doc.data()));
        return solutionsPoint
    } catch (error) {
        console.error(error);
    }
}

async function GetSolutionsPointByIds(solutionGroupIds) {
    try {
        let chunkIds = Utils.chunkArray(Utils.removeDuplicates(solutionGroupIds))
        let responses = {}
        for (let i = 0; i < chunkIds.length; i++) {
            const get_data = await solutions_point_ref.where("id", "in", chunkIds[i]).get();
            chunkIds[i].forEach((id) => responses[id] = null)
            get_data.forEach((doc) => responses[doc.id] = {...doc.data(), id: doc.id});
        }
        return responses
    } catch (error) {
        console.error(error);
    }
}

async function updateSolutionsPoint(solutionsPoint) {
    try {
        await solutions_point_ref.doc(solutionsPoint.id).update(solutionsPoint);
    } catch (error) {
        console.error(error);
    }
}

async function addSolutionPoint(solutionsPoint) {
    if (solutionsPoint === undefined) throw "field 'user' is missing";
    try {
        const doc_ref = await solutions_point_ref.add(solutionsPoint);
        const doc_id = doc_ref.id;
        await solutions_point_ref
            .doc(doc_id)
            .update({
                id: doc_id,
            });
        return doc_id;

    } catch (e) {

    }
}

async function deleteSolutionPoint(solutionsPointId) {
    try {
        await solutions_point_ref.doc(solutionsPointId).delete();
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}


export default {
    getSolutionsPoint,
    GetSolutionsPointByIds,
    addSolutionPoint,
    deleteSolutionPoint,
    updateSolutionsPoint
};
