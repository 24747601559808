<template>
  <v-container fluid>
    <v-toolbar dense color="primary" rounded>
      <v-toolbar-title ><span 
          style="
            font-size: 1.25rem;
            font-weight: 500;
            letter-spacing: 0.0125em; 
            padding: 10px;
            color: white;"
          >
            Consumo
          </span> </v-toolbar-title>
      <v-spacer></v-spacer>
        <span 
          style="
            font-size: 1.3rem;
            font-weight: 400;
            letter-spacing: 0.0125em; 
            padding: 10px;
            color: white;"
          >
          {{getNameByIdClient}}
          </span>
      <v-spacer></v-spacer>

      <v-btn icon  color="white" @click="selectsModal = true"> 
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-toolbar>
    <v-row class="dashboardData">
      <v-col cols="12">
        <!-- Select Dialogs -->
        <v-dialog v-model="selectsModal" max-width="500px" v-if="selectsModal">
         

          <v-card>
            <v-toolbar color="primary" dark dense>
              Consultar
              <v-spacer></v-spacer>
              <v-btn class="litte" text @click="selectsModal = false">
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-form ref="formConsumption" v-model="validForm" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete v-model="selectedClient" :items="listClients" item-text="name" item-value="id"
                      label="Cliente" dense outlined hide-details :loading="selectsLoading.client"
                      :disabled="selectsLoading.client" @change="onChangeClient" @keydown="onKeydownClient"
                      :rules="rulesRequired"
                      >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" v-if="selectedClient">
                    <v-autocomplete 
                      dense 
                      v-model="selectedUnits" 
                      :items="listUnits" 
                      item-text="name" 
                      item-value="id"
                      label="Unidade" 
                      outlined 
                      hide-details 
                      multiple
                      @input="debouncedGetSectors"
                      :loading="selectsLoading.unit"
                      @keydown="onKeydownUnit"
                      :rules="rulesRequiredArray"
                      >
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index <= 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span v-if="index === 0 && selectedUnits.length > 1" class="grey--text text-caption">
                          {{ `(+ ${selectedUnits.length - 1} ${selectedUnits.length > 2? 'Unidades' : 'Unidade'})` }}
                        </span>
                      </template>
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @mousedown.prevent
                          @click="toggleUnitsList"
                        >
                          <v-list-item-action>
                            <v-icon :color="0 ? 'indigo darken-4' : ''">
                              {{ iconUnitSelect }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Todos
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" v-if="selectedUnits.length">
                    <v-autocomplete multiple dense v-model="selectedSector" :items="listSectors" 
                      item-text="name"
                      return-object
                      label="Setor" outlined hide-details :loading="selectsLoading.sector"
                      @change="onChangeSector" @keydown="onKeydownSector">
                      <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index <= 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span v-if="index === 0 && selectedSector.length > 1" class="grey--text text-caption">
                          {{ `(+ ${selectedSector.length - 1} ${selectedSector.length > 2? 'Setores' : 'Setor'})` }}
                        </span>
                      </template>
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @mousedown.prevent
                          @click="toggleSectorsList"
                        >
                          <v-list-item-action>
                            <v-icon :color="0 ? 'indigo darken-4' : ''">
                              {{ iconSectorSelect }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Todos
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" >
                    <v-select :items="chartMenuItems" hide-details  label="Período" v-model="optionPeriod" item-value="item" outlined
                    dense :rules="rulesRequired"></v-select>
                  </v-col>
                  <v-col cols="6" v-if="optionPeriod == 'Por intervalo'">
                    <v-menu v-model="menuDateStart" :close-on-content-click="false" :nudge-right="40"
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="dateFormattedSelectedStart" label="Data Inicial" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" :rules="rulesRequired"></v-text-field>
                      </template>
                      <v-date-picker class="date-picker-height" v-model="dateSelectedStart" @input="menuDateStart = false"
                        locale="pt-br"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6" v-if="optionPeriod == 'Por intervalo'">
                    <v-menu v-model="menuDateEnd" :close-on-content-click="false" :nudge-right="40"
                      transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="dateFormattedSelectedEnd" label="Data Final" prepend-icon="mdi-calendar"
                          readonly v-bind="attrs" v-on="on" :rules="rulesRequired"></v-text-field>
                      </template>
                      <v-date-picker class="date-picker-height" v-model="dateSelectedEnd" @input="menuDateEnd = false"
                        locale="pt-br"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" >
  
                    <!-- Para opção 'Por dia' é necessário escolher o mês. -->
                    <v-select :items="byYear" label="Ano" v-model="yearSelected" item-value="item" outlined dense
                      v-if="optionPeriod == 'Por dia'" :rules="rulesRequired"></v-select>
      
                    <v-select :items="byMonth" label="Mês" v-model="monthSelected" item-value="item" outlined dense
                      v-if="yearSelected && optionPeriod == 'Por dia'" :rules="rulesRequired"></v-select>
      
                    <!-- Para opção 'Por mês' é necessário escolher o ano. -->
                    <v-select :items="byYear" label="Ano" v-model="yearSelected" item-value="item" outlined dense
                      v-if="optionPeriod == 'Por mês'" :rules="rulesRequired"></v-select>
      
                    <!-- Opçao de calendario caso a opção 'Por hora' seja selecionada -->
      
                    <v-menu v-model="menuDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                      offset-y min-width="auto" v-if="optionPeriod == 'Por hora'">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="dateSelectedFormatted" label="Dia" prepend-icon="mdi-calendar" readonly
                          v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker class="date-picker-height" v-model="dateSelected" @input="menuDate = false"
                        locale="pt-br"></v-date-picker>
                    </v-menu>
      
      
                    
      
                    <!-- <v-select :items="optionsToDisplay" item-text="label" label="Exibir por" v-model="optionToDisplaySelected"
                      item-value="value" v-if="optionPeriod == 'Por intervalo'" readonly></v-select> -->
      
                    <v-alert v-if="!validDayPeriodSelected && optionToDisplaySelected == 'day'" dense outlined type="error">
                      Selecione um período de no máximo <strong>50 dias</strong>
                    </v-alert>
                    <v-alert v-if="!validHourPeriodSelected && optionToDisplaySelected == 'hour'" dense outlined type="error">
                      Selecione um período de no máximo <strong>24 horas</strong>
                    </v-alert>
                    <v-alert v-if="!validMonthPeriodSelected && optionToDisplaySelected == 'month'" dense outlined type="error">
                      Selecione um período de no máximo <strong>12 meses</strong>
                    </v-alert>
                  </v-col>
                  <v-col cols="6"  v-if="selectedSector.length === 0">
                    <v-checkbox
                    v-model="groupingDataGraphic"
                    label="Agrupar Dados"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="6"  v-if="selectedSector.length === 0">
                    <v-checkbox
                    v-model="totalConsumptionUnitsSelected"
                    label="Exibir Total"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="6"  >
                    <v-switch v-model="optionMetric" :label="`Exibir em: ${switch1}`"></v-switch>
                  </v-col>
                </v-row> 
              </v-form>
            </v-card-text>
            <v-card-actions class="text-center" style="padding-top: 0px; padding-right:0px;">

            <v-row>

              <v-col  cols="4">
                <v-btn
                  :loading="consultDatalLoading.graphicButton"
                  :disabled="consultDatalLoading.readingButton"
                  @click="consultConsumptionGraphic()"
                  color="primary" style="font-weight: bold;" outlined>
                  <v-icon small>mdi-chart-bar</v-icon>
                  Gráfico
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-btn
                  @click="startConsultTable()"
                  :disabled="consultDatalLoading.graphicButton"
                  :loading="consultDatalLoading.readingButton"
                  color="primary" style="font-weight: bold;" outlined>
                  <v-icon small>mdi-book-open-variant</v-icon>
                  Leitura
                </v-btn>
              </v-col>
              <v-col cols="4">
                <v-row>
                  <v-btn color="primary" text @click="exportSheets()" style="height:100%">
                    <v-img src="~@/assets/images/icon-excel.png" style="width:1.5rem; float: right; margin-left: 0.5rem">
                    </v-img>
                  </v-btn>
                  <v-btn color="primary" text @click="startPdf()" 
                    :loading="loadButtonPdf" style="height:100%">
                    <v-img src="~@/assets/images/icon-pdf.png" style="width:1.5rem; float: right; margin-left: 0.5rem">
                    </v-img>
                  </v-btn>

                </v-row>
              </v-col>

            </v-row>
            <br>
          </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row dense style="margin-bottom: 15px;" v-if="isDataGraphic && selectedUnits.length === 1 && this.unitSelectedDataKPIs.averageBeforeFoz">
      <v-col cols="3" >
        <KpiCard   
          icon="mdi-chart-sankey"
          :value="this.unitSelectedDataKPIs.averageBeforeFoz"
          label="Média Antes da Foz"
          :loading="false"
          color="primary"
          :progress="100"
        />
      </v-col>
      <v-col cols="3">
        <KpiCard   
          icon="mdi-percent"
          :value="this.unitSelectedDataKPIs.goalPercentage"
          label="Meta"
          :loading="false"
          color="primary"
          :progress="100"
        />
      </v-col>
      <v-col cols="3">
        <KpiCard   
          icon="mdi-flag-checkered"
          :value="this.unitSelectedDataKPIs.goalEconomy"
          label="Meta de Economia"
          :loading="false"
          color="green"
          :progress="100"
        />
      </v-col>
      <v-col cols="3">
        <KpiCard   
          icon="mdi-information"
          :value="this.unitSelectedDataKPIs.consumptionType"
          label="Tipo Consumo"
          :loading="false"
          color="primary"
          :progress="100"
        />
      </v-col>
    </v-row>

    <v-row no-gutters v-for="(summedGraph, i) in summedGraphs" :key="i+123" >
      <v-col cols="12" style="padding-bottom: 25px">
        <NewChartConsumption
          class="boxChartsAutoHeighFirstLine"
          :title="'Consumo Total'"
          :labels="summedGraph.labels"
          :datasets="summedGraph.dataset"
          :average="0"
          :loading="false"
        />
      </v-col>
    </v-row>
    <v-row no-gutters v-for="(dataGraphicUnit, i) in unitsDataGraphic" :key="i">
      <v-col cols="12" style="padding-bottom: 25px">
        <NewChartConsumption
          class="boxChartsAutoHeighFirstLine"
          :title="dataGraphicUnit.unit_name"
          :labels="dataGraphicUnit.labels"
          :datasets="dataGraphicUnit.dataset"
          :average="0"
          :loading="false"
        />
      </v-col>
    </v-row>

    <!-- Gráfico -->

          <v-row v-if="isDataGraphic" no-gutters v-for="(reading, i) in readings" :key="i">
            <v-col cols="12" style="margin-bottom: 15px;">
              <!-- // * Histórico de Consumo -->
              <v-dialog v-model="dialog">
                <template v-slot:activator="{ attrs }">
                  <v-card class="rounded-xl" outlined raised v-bind="attrs">

                    <v-row style="padding: 0 30px;">
                      <v-col>
                        <v-row style="min-width: 160px;flex-direction: column;">
                          <h2 style="color: #437EBE; font-size: 1.5em;">
                            Consumo de Água
                          </h2>
                          <h2 style="color: #696969; font-size: 100%; margin-left: 0.5rem">
                             {{switch1 != 'Metros Cúbicos (m³)'? 'Medidas em L' : 'Medidas em m³' }}
                          </h2>
                        </v-row>
                      </v-col>
                    </v-row>


                    <!-- // TODO mais dados pra evidenciar a eficácia de overflow-x-auto -->
                    <!-- <ChartConsumption style="min-height: 200px" :labels="readings[i].labels"
                      :datasets="readings[i].datasets" /> -->

                      <NewChartConsumption
                        style="height: 200px"
                        :labels="readings[i].labels"
                        :datasets="readings[i].datasets"
                        :average="0"
                        :loading="false"
                      />


                    <div style="text-align:center; width: 100%;margin-top: 10px">
                      <span style="color: #696969; font-weight: bold; font-size: 14px;">{{
          getResumeData(reading.modemId, 'modemName') }}</span>
                    </div>

                    <div class="consumption-total-style">
                      <span style="color: #696969; font-weight: bold; font-size:larger">Consumo Médio Atual: </span>
                      <span style="font-size: larger; margin-left: 1rem; font-weight: bold">{{
          (getResumeData(reading.modemId, 'average')).toFixed(2) }} {{ isCubicMetersSelected ? 'm³' : 'L'
                        }}</span>
                    </div>
                    <div class="consumption-total-style">
                      <span style="color: #696969; font-weight: bold; font-size:larger">Consumo Medido/Total Estimado:
                      </span>
                      <span style="font-size: larger; margin-left: 1rem; font-weight: bold">{{
          getResumeData(reading.modemId, 'measured_total').toFixed(2) }} {{ isCubicMetersSelected ? 'm³' :
          'L' }} / {{ getResumeData(reading.modemId, 'estimated_total').toFixed(2) }} {{
          isCubicMetersSelected ? 'm³' : 'L' }}</span>
                    </div>

                  </v-card>
                </template>

              </v-dialog>
            </v-col>
          </v-row>
    <!-- Fim Gráfico -->

    <!-- Tabela -->
    <v-row class="dashboardData" align="center" justify="center" v-if="isDataTable" style="border-radius: 1rem">

      <v-card class="rounded-xl" style="border-radius: 20px;width:98%;" v-for="(reading, i) in readings_table" :key="i">
        <!-- Loading enquanto os dados estiverem sendo carregados -->
        <v-overlay absolute style="opacity: 0.8" :value="loadingReadings">
          <v-progress-circular indeterminate color="primary" v-if="loadingReadings"></v-progress-circular>
        </v-overlay>

        <v-toolbar color="primary" dark dense style="border-radius: 20px 20px 0px 0px">
          Leitura {{ getResumeData(reading.modemId, 'modemName') }}
        </v-toolbar>

        <!-- Tabela de Consumo -->
        <v-card-text>
          <div style="width:100%;text-align:center; border-radius:1rem; border: gray 1px solid">
            <v-data-table :headers="headers_tabledays" :items="reading.items" class="rounded-1"
              style="border-radius:1rem;" dense>
            </v-data-table>
            <div class="consumption-total-style">
              <span style="color: #696969; font-weight: bold; font-size:larger">Consumo Médio Atual: </span>
              <span style="font-size: larger; margin-left: 1rem; font-weight: bold">{{ (getResumeData(reading.modemId,
                'average')).toFixed(2) }} {{ isCubicMetersSelected ? 'm³' : 'L' }}</span>
            </div>
            <div class="consumption-total-style">
              <span style="color: #696969; font-weight: bold; font-size:larger">Consumo Medido:</span>
              <span style="font-size: larger; margin-left: 1rem; font-weight: bold">{{ getResumeData(reading.modemId,
                'measured_total').toFixed(2) }} {{ isCubicMetersSelected ? 'm³' : 'L' }}</span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row>
      <v-btn v-show="false" small color="primary" text>Baixar Planilha Completa</v-btn>
    </v-row>
  </v-container>

</template>

<script>
import ConsumptionScript from './ConsumptionScript.js';
export default ConsumptionScript;
</script>

<style lang="scss">
.boxChartsAutoHeighFirstLine {
  border: 1px solid #9fa8da75;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  height: calc(100vh - 64vh);
  min-height: 300px;
  background-color: white;
  border-radius: 16px;
  overflow: hidden;
  padding: 15px;
}
.litte {
  left: 10px;
}

.consumption-total-style {
  display: flex;
  justify-content: center;
  margin: 10px 0px 10px 0px;
}

.date-picker-height {
  height: 450px
}

.style-div {

  display: flex;
  //justify-content: space-evenly;
  justify-content: flex-start;

}

.layout-data-abstract-fields {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.layout-data-abstract {
  background: #1976d2 !important;
  border-radius: 1rem !important;


}

.style-card-city-name {
  background: #3298ed !important;

  text-align: center !important;
  border-radius: 1rem;
  padding: 1rem;
  font-size: 1rem;
  word-break: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  span {
    color: white;
    font-style: italic;
  }
}

.v-divider {
  border-color: white;
  border-width: 0.05rem;
}

.card-style-data-client {
  color: white !important;
  background: #1976d2 !important;
  text-align: center !important;
  border-style: none !important;
  font-size: 1rem !important;
}

.v-text-field-bar-class {
  border-style: none !important;
}

.text-large {
  font-size: 225%;
  font-weight: 500;
}

.cards {
  padding-top: 5px !important;
  padding-bottom: 4px !important;
  padding-left: 15px !important;
  padding-right: 10px !important;
}

.ticketCards {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.cardsLG {
  margin-bottom: 5px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.ticketCardsLG {
  margin-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.cardsBigger {
  padding-bottom: 4px !important;
  padding-left: 15px !important;
  padding-right: 10px !important;
}

.cardsBiggerLG {
  margin-bottom: 5px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
}
</style>
