<template>
    <div>
        <v-text-field 
            label="Imposto"
            type="number"
            v-model="value"
            :loading="loading"
            :disabled="loading"
            :rules="rule"
            @change="setValue"
            @focus="setFocus"
            @blur="setBlur"
            @update:error="getError"
        >
        </v-text-field>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import WaterScanService from '@/services/firebase/WaterScan/WaterScanService';
import WaterFixService from '@/services/firebase/WaterFix/WaterFixService';
import Utils from "@/scripts/Utils";
import Rules from "@/utils/Rules";

export default {
    props: {
        proposalType: {
            type: String,
            require: true,
        },
        roiType: {
            type: String,
            require: true,
        },
        proposal: {
            type: Object,
            require: true,
        },
    },
    data: () => ({
        field: 'tax',
        key: null,
        id: null,
        loading: true,
        focus: false,
        blur: false,
        error: false,
        value: 0,
    }),
    computed: {
        rule() {
            return Rules.number(true, 0, 100)
        },
        hasDisabled() {
            return this.error || this.focus
        },
    },
    methods: {
        ...mapActions([
            'subscribeChannel',
            'publishChannel',
        ]),
        setLoading(status) {
            this.loading = status
            this.publishChannel({
                channel: 'roi',
                event: `${this.key}:loading`,
                value: status,
            })
        },
        setDisabled(status) {
            this.publishChannel({
                channel: 'roi',
                event: `${this.key}:disabled`,
                value: status,
            })
        },
        getError(error) {
            this.error = error
        },
        setFocus(status) {
            this.focus = true
            this.blur = false
        },
        setBlur(status) {
            this.focus = false
            this.blur = true
        },
        async setValue(value) {
            if (this.error) return
            this.setLoading(true)
            try {
                if (this.proposalType == 'waterscan') {
                    await WaterScanService.updateById(this.key, value, this.id)
                } else {
                    
                }
                this.emitValue()
                this.setLoading(false)
            } catch (e) {
                console.error(e)
                this.setLoading(false)
            }
        },
        resetValue() {
            this.value = 0
        },
        emitValue() {
            this.publishChannel({
                channel: 'roi',
                event: this.key,
                val: this.value,
            })
        },
    },
    watch: {
        async proposal(newValue) {
            if (newValue) {
                this.id = newValue.id
                if (this.proposalType == 'waterscan') {
                    if (this.roiType == 'performance') {
                        this.key = this.field
                    } else if (this.roiType == 'sales') {
                        this.key = `${this.roiType}-roi.${this.field}`
                    } else {
                        throw new Error("Tipo do roi não identificado")
                    }
                    this.value = 0
                    try {
                        this.value = Utils.getting(this.proposal, this.key)
                    } catch (e) {
                        this.value = 0
                        await WaterScanService.updateById(this.key, this.value, this.id)
                    }
                } else {
                    
                }
            }
            this.setLoading(false)
        },
        hasDisabled(newVal) {
            if (newVal) {
                this.setDisabled(true)
            } else {
                this.setDisabled(false)
            }
        },
    },
}

</script>