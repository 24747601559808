<template>
  <div>
    <div>
      <span v-for="option in filterOptionsSetting()" :key="option.name">
        <v-btn
          color="white"
          id="btn-styles-options"
          @click="option.click"
        >
          <v-card class="text-xs-center ma-3">
            <v-responsive class="pt-4">
              <div>
                <v-icon class="icon-style-options" x-large>
                  {{ option.icon }}
                </v-icon>
              </div>
            </v-responsive>
            <v-card-actions>
              <div
                style="
                  width: 100%;
                  color: #737373;
                  font-size: 1.2rem;
                  text-transform: capitalize;
                "
              >
                {{ option.name }}
              </div>
            </v-card-actions>
          </v-card>
        </v-btn>
      </span>
    </div>

    <!-- Inicio consulta matricula -->
    <v-dialog v-model="enrollDialog" max-width="300px">
      <v-card width="100%">
        <v-card-title> Consultar Matrícula </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="validForm" lazy-validation>
            <v-text-field
              label="Matrícula"
              v-model="registration"
              v-mask="'#########'"
              type="number"
              :rules="rulesRequired"
            >
            </v-text-field>
          </v-form>
          <v-btn
            width="100%"
            color="primary"
            :loading="enrollLoading"
            :disabled="!validForm"
            @click="checkRegistrationFirebase(registration)"
          >
            Consultar
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="enrollLoading" text @click="enrollDialog = false">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Fim consulta matricula -->

    <!-- Inicio Nova Proposta -->
    <NewWaterFix
      :open="newProposalDialog"
      :closeFunc="closeComponent"
      :registration="registration"
    />
    <!-- Fim Nova Proposta -->

    <!-- Início Consulta de Proposta -->
    <v-dialog v-model="consultDialog" max-width="300px">
      <v-card style="width: 300px;height: auto;">
        <v-card-title>
          <h3 style="text-align: center; width: 100%">Alerta</h3>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text style="font-size: 1.2rem">
          <v-col cols="12">{{ consultText }}</v-col>
          <v-col
            cols="12"
            v-if="waterFixOpenStatus === 'register'"
          >
            <v-select
              v-model="consultSelected"
              :items="consultOptions"
              attach
              chips
              label="Items para Importar"
              multiple
              style="padding-top: 0px"
            ></v-select>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" :loading="consultLoading" text @click="openViewConsult()"> {{ openViewConsultTextButton }} </v-btn>
          <v-btn color="primary" :loading="consultLoading" text @click="closeViewConsult()">
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Fim Consulta de Proposta -->

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NewWaterFix from "./NewProposal/NewWaterFix.vue";
import WaterFixService from '@/services/firebase/WaterFix/WaterFixService';
import WaterFixStorage from "../../services/storage/WaterFixStorage";
import WaterScanService from '@/services/firebase/WaterScan/WaterScanService';

import { RoutesPath } from '@/router/paths.js';
import { SubMenuFeatures } from "@/router/features.js";
import Utils from '@/scripts/Utils';

import { showAditionDialogAlert } from "@/utils/DialogAlertHelper"
import ObjectValidator from '@/scripts/ObjectValidator';

export default {
  name: "ChooseWaterFix",
  components: {
    NewWaterFix,
  },
  data() {
    return {
      validForm: true,
      consultText: "",
      openViewConsultTextButton: "",
      consultDialog: false,
      consultOptions: [
        'Check List',
      ],
      consultSelected: ['Check List'],
      consultLoading: true,
      registration: "",
      newProposalDialog: false,
      enrollDialog: false,
      enrollLoading: false,
      optionsSettings: [
        {
          name: "Nova Proposta",
          icon: "mdi-book-plus",
          feature: SubMenuFeatures.CONSULT_WATERFIX,
          path: RoutesPath.WATER_SCAN_NEW,
          click: () => (this.enrollDialog = true),
        },
        {
          name: "Consultar Proposta",
          feature: SubMenuFeatures.CONSULT_WATERFIX,
          icon: "mdi-book-search",
          path: RoutesPath.WATER_FIX_ALL,
          click: () => (this.$router.push(RoutesPath.WATER_FIX_ALL)),
        },
      ],
    };
  },
  computed: {
    // Incialização dos getters definido na inicialização do vue.store em index.js
    ...mapGetters([
      "getCurrentUserData",
      "waterFixOpenStatus",
    ]),
    rulesRequired() {
      return [
        (value) => !!value || "Matrícula requerida",
        (value) => !(value && value.length < 9) || `Mínimo de 9 caratere(s)`,
      ];
    },
  },

  methods: {
    ...mapActions([
      'setWaterFixItemsToImport',
      'setWaterFixOpenStatus',
    ]),
    async openViewConsult(){
      this.setWaterFixItemsToImport(Utils.copyObject(this.consultSelected))
      this.setConsultLoading(true)
      let proposalWaterscan = await WaterScanService.getProposalByRegistration(this.registration)
      var solutionList = []
      if (this.consultSelected.includes('Check List')) {
        var _solutionList = proposalWaterscan.solutionList
        _solutionList = _solutionList ? _solutionList : []
        solutionList = _solutionList.map(
          (_solution) => {
            var imgNameList = _solution.imgNameList.map(
              (filepath) => {
                const newFilepath = filepath.replace('water_scan_imgs', 'water_fix_imgs')
                return newFilepath
              }
            )
            return {
              local: Utils.defaultSpace(_solution.local),
              sub_local: Utils.defaultSpace(_solution.sub_local),
              amountSolution: _solution.amountSolution,
              flowRateAfter: _solution.flowRateAfter,
              flowRateBefore: _solution.flowRateBefore,
              economy: _solution.economy,
              comments: _solution.comments ? _solution.comments : null,
              type: _solution.type,
              solution: _solution.solution,
              imgNameList,
              category: null,
              status: null,
            }
          }
        )
      }
      const proposal = {
        registration: this.registration,
        locationInformation: proposalWaterscan.locationInformation,
        dataProject: proposalWaterscan.dataProject,
        solutionList,
        devolutionList: [],
        compromiseList: [],
        installationList: [],
      }
      
      const proposalId = await WaterFixService.createProposal(proposal)
      this.openNewProposalDialog()
      this.setConsultLoading(false);
    },
    closeViewConsult() {
      this.setConsultDialog(false)
    },
    async checkRegistrationFirebase(registration) {
      this.setEnrollLoading(true);
      await Utils.sleep(500)
      // Verificar se o formulário está valido
      if ((this.$refs.form) && !this.$refs.form.validate()) {
        this.setEnrollLoading(false);
        return;
      }
      // Verificar se existe alguma proposta de water fix
      let statusWaterFix = await WaterFixService.checkRegistration(registration)
      if (statusWaterFix) {
        this.setWaterFixOpenStatus('edit')
        this.openConsultDialog('water_fix');
        this.setEnrollLoading(false);
        return;
      } 
      // Verificar se existe alguma proposta de water scan
      let statusWaterScan = await WaterScanService.checkRegistration(registration)
      if (statusWaterScan) {
        this.setWaterFixOpenStatus('register')
        this.openConsultDialog('water_scan');
        this.setEnrollLoading(false);
        return;
      } 
      // Caso não encontrado nem water scan e fix
      this.setWaterFixOpenStatus('register')

      showAditionDialogAlert(
        "Você deseja criar a proposta?",
        this.createProposal,
        () => {
          this.setEnrollLoading(false);
        }
      )
    },
    async createProposal() {
      const proposal = {
        registration: this.registration,
        locationInformation: ObjectValidator.create("enroll"),
        dataProject: { type: null },
        solutionList: [],
        devolutionList: [],
        compromiseList: [],
        installationList: [],
      }
      const proposalId = await WaterFixService.createProposal(proposal)
      this.openNewProposalDialog()
      this.setEnrollLoading(false);
    },
    setConsultDialog(status) {
      this.consultDialog = status;
      if (!status) this.setConsultLoading(false)
    },
    setConsultLoading(status) {
      this.consultLoading = status;
    },
    setEnrollDialog(status) {
      this.enrollDialog = status;
      if (!status) this.setEnrollLoading(false)
    },
    setEnrollLoading(status) {
      this.enrollLoading = status;
    },
    openConsultDialog(typeConsultFounded) {
      // Params:
      // typeConsultFounded = water_scan | water_fix
      this.setConsultDialog(false);
      switch (typeConsultFounded) {
        case 'water_scan':
          this.consultText = "Não foi encontrado uma proposta no Water Fix, porém existe no Water Scan.\nDeseja Importar?";
          this.openViewConsultTextButton = "Importar";
          this.setConsultDialog(true);
        break;
        case 'water_fix':
          this.consultText = "Já existe uma proposta vinculada a essa matrícula!\nVerifique em Consultar Proposta.";
          this.openViewConsultTextButton = "Consultar";
          this.setConsultDialog(true);
        break;
      }
    },
    openNewProposalDialog() {
      this.newProposalDialog = true;
    },
    closeComponent() {
      this.newProposalDialog = false;
      this.enrollDialog = false;
      this.consultDialog = false;
    },
    filterOptionsSetting() {
      return this.optionsSettings.filter(({feature}) => this.getCurrentUserData.permissions.includes(feature))
    },
  },
};
</script>

<style scoped>
#btn-styles-options {
  margin-left: 25px;
  margin-top: 25px;
  width: 15rem;
  height: 15rem;
  border-radius: 1rem;
  padding: 0px;
  /* box-shadow: inset 0 0 1em gold, 0 0 1em black; */
  box-shadow: 0 0 0.5em black;
}

.icon-style-options {
  color: #737373 !important;
  font-size: 10rem !important;
}

.v-card:hover {
  background-color: #eeeeee;
}

.v-card {
  width: 15rem;
  height: 15rem;
  margin: 0;
  border-radius: 1rem;
}
</style>