

import { storage } from "@/firebase"
import Compressor from 'compressorjs';
import { compressorConfig } from "@/utils/Constants.js"


const compressorFile = async (file) => {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            ...compressorConfig,
            success(result) {
                resolve(result);
            },
            error(err) {
                console.error(err.message);
                reject(err);
            },
        });
    })
}
const uploadImg = async (idLead, file) => {
    const fileCompress = await compressorFile(file)
    const imgNameSaved = new Date().getTime() +"_"+file.name
    const pathSaved = `leads/${idLead}/${imgNameSaved}`
    const storageWS_REF = storage.ref(pathSaved)
    var task = storageWS_REF.put(fileCompress);
    await new Promise((resolve, reject) => {
        task.on('state_changed',
            function progress(snapshot) {
                var percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;
            },
            function error(err) {
                console.error(`uploadImg - ${err}`)
                reject(err)
            },
            function complete() {
                var downloadURL = task.snapshot.downloadURL;
                resolve("Download Complet")
            }
        );
    })
    return pathSaved
}
const uploadFile = async (idLead, file) => {
    try {
        console.log("uploadFileeeeeeeeeeeeeeeeeeeeeeeeeeee")
        const allowedTypes = ["application/pdf", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];
        if (!allowedTypes.includes(file.type))  throw new Error("Somente arquivos PDF ou DOCX são permitidos.");

        const fileName = `${new Date().getTime()}_${file.name}`;
        const filePath = `leads/${idLead}/${fileName}`;

        const storageRef = storage.ref(filePath);

        const uploadTask = storageRef.put(file);

        await new Promise((resolve, reject) => {
            uploadTask.on(
                "state_changed",
                function progress(snapshot) {
                    const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                },
                function error(err) {
                    reject(err);
                },
                function complete() {
                    resolve();
                }
            );
        });
        // const downloadURL = await storageRef.getDownloadURL();
        return filePath;
    } catch (error) {
        console.error("Erro ao fazer upload do arquivo:", error.message);
        throw error;
    }
}
const downloadImg = async (path) => {
    try {
        const fileRef = storage.ref(path);
        const downloadURL = await fileRef.getDownloadURL();
        const response = await fetch(downloadURL);
        const blob = await response.blob();
        return blob;
    } catch (error) {
        console.error(error)
    }
}
const deleteImg = async (path) => {
    try{
        await storage.ref(path).delete()
    }catch(err){
        console.error(`Error - deleteImg ${err}`)
    }
}

export default {
    uploadImg,
    deleteImg,
    uploadFile,
    downloadImg
}