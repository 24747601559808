<template>
    <v-container fluid>
        <v-row class="dashboardData">
            <v-col cols="12">
                <v-card class="rounded-xl" >

                    <!-- Título do card de filtragem -->
                    <v-card-title class="title-displays">Metas e Previsões</v-card-title>
                    <v-card-title style="padding-right:2rem; padding-left:2rem; padding-bottom:0px;">
                        <v-row style="flex-wrap: wrap; min-width: 255px;">
                            <v-col style="min-width: 150px;position:relative;">
                                <v-autocomplete
                                    v-model="clientObject"
                                    :items=" getClients"
                                    item-text="data.name"
                                    dense
                                    
                                    outlined
                                    label="Cliente"
                                    return-object
                                    :loading="isLoadingClient"
                                    :disabled="isLoadingClient"
                                    @change="onChangeClient"
                                ></v-autocomplete>
                            </v-col>
                            <v-col style="min-width: 150px;position:relative;">
                                <v-autocomplete
                                    v-model="unitObject"
                                    :items="getListUnits"
                                    item-text="name"
                                    dense
                                    
                                    outlined
                                    label="Unidade"
                                    return-object
                                    :loading="isLoadingUnit"
                                    :disabled="!(clientObject)"
                                    @change="onChangeUnit"
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <!-- Botões de gráfico, leitura e gerar pdf -->
                    <!-- :loading="isLoadingGraphic" -->
                    <v-card-actions class="text-center" style="padding-top:0px; padding-right:2rem; padding-left:2rem">
                        <v-row> 
                            <v-col>
                                <v-row style="max-width: 145px;">
                                    <v-col>
                                        <v-btn
                                            @click="openGraphicDialog()"
                                            color="primary"                    
                                            style="font-weight: bold;"
                                            :disabled="isLoadingGraphic || !isReadySelect || alertDialog"
                                            outlined
                                        >
                                            <v-icon small>mdi-chart-bar</v-icon>
                                            Gráfico
                                        </v-btn>     
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col style="display: flex;justify-content: end;">
                                <!-- Gerar PDF -->
                                <!-- Botão de gerar pdf --> 
                                <!-- :loading="isLoadingGeneratePDF" -->
                                <v-row style="max-width: 160px;">
                                    <v-col>
                                        <v-btn
                                            color="primary"
                                            text
                                            @click="() => {}"
                                            style="height:100%"                                            
                                            :disabled="isLoadingGeneratePDF || !isReadySelect"
                                        >
                                            <v-img
                                                src="~@/assets/images/icon-pdf.png" 
                                                style="width:1.5rem; float: right; margin-left: 0.5rem"
                                                >
                                            </v-img>
                                            Gerar PDF
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <br>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card>
              <v-toolbar
                color="teal"
                dark
                style="background-color:#1976d2 !important;"
              >
                <v-toolbar-title>
                  Meta e Previsão
                </v-toolbar-title>
              </v-toolbar>
                  <v-col 
                  v-if="alertDialog" 
                  >
                      <v-alert 
                      style="padding-left: 13px !important; padding-right: -13 !important;"                      
                      dense 
                      outlined 
                      type="error">
                          {{message}}
                      </v-alert>
                  </v-col>
              <v-subheader>
                {{ formatSubheaderGraphic }}
              </v-subheader>
              <v-spacer></v-spacer>
              <v-row v-if="isReadyOpenGraphic" style="margin:0;justify-content:center;">
                <v-chip
                  class="ma-2"
                  color="rgb(30,144,255)"
                  text-color="white"
                >
                  Água:{{ getWaterValueTotalToPay }}
                </v-chip>
                <v-chip
                  class="ma-2 brown lighten-1"
                  color="grey"
                  text-color="black"
                >
                  Esgoto: {{ getSewerValueTotalToPay }}
                </v-chip>
                <v-chip
                  class="ma-2 orange lighten-1"
                  color="rgb(255,215,0)"
                  text-color="black"
                >
                  Total: {{ getValueTotalToPay }}
                </v-chip>
              </v-row>
              <v-row v-if="isReadyOpenGraphic" style="margin:0;justify-content:center;">
                <v-chip
                  class="ma-2"
                  color="rgb(30,144,255)"
                  text-color="white"
                >
                  {{ getTotalWater }}
                </v-chip>
                <v-chip
                  class="ma-2 brown lighten-1"
                  color="grey"
                  text-color="black"
                >
                  {{ getTotalSewer }}
                </v-chip>
                <v-chip
                  class="ma-2 orange lighten-1"
                  color="rgb(255,215,0)"
                  text-color="black"
                >
                  {{ getTotalConsumption }}
                </v-chip>
              </v-row>
              <v-container v-if="isReadyOpenGraphic">
                <goals-forecasts-graphic
                  :labels="labels"
                  :datasets="datasets"
                  :label="labelTarget"
                >
                </goals-forecasts-graphic>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
        
        <v-dialog
            transition="dialog-bottom-transition"
            max-width="600"
            v-model="graphicDialog"
        >
            <v-card>
                <v-toolbar
                    color="primary"
                    dark
                    >
                    Gráfico
                     <v-spacer></v-spacer>

                    <v-btn
                        text
                        @click="closeGraphicDialog()"
                    ><v-icon white>mdi-window-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text style="padding: 10px 30px;">
                    <v-row>
                        <v-text-field
                            :value="formatStartDate"
                            label="Data Inicial"
                            prepend-icon="mdi-calendar"
                            readonly
                            @click="startDateDialog = true"
                        ></v-text-field>
                    </v-row>
                    <v-row>
                        <v-text-field
                            :value="formatEndDate"
                            label="Data Final"
                            prepend-icon="mdi-calendar"
                            readonly
                            @click="endDateDialog = true"
                        ></v-text-field>
                    </v-row>
                    <v-row>
                        <v-switch
                            v-model="isLiter"
                            :label="`Em ${isLiter ? 'Litros (L)' : 'Metro cúbico (m³)'}`"
                        ></v-switch>
                    </v-row>
                </v-card-text>
                <v-card-actions class="justify-end">
                   
                    <v-btn
                        text
                        @click="openGraphic()"
                    >Buscar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <date-picker-dialog 
            class="date-picker-height"
            title="Escolha a data inicial"
            :show="startDateDialog"
            @close="status => startDateDialog = status"
            :input="startDate"
            @change="newDate => startDate = newDate"
            :max="endDate"
        />

        <date-picker-dialog 
            class="date-picker-height"
            title="Escolha a data final"
            :show="endDateDialog"
            @close="status => endDateDialog = status"
            :input="endDate"
            @change="newDate => endDate = newDate"
            :min="startDate"
        />

    </v-container>
</template>

<script>
import GoalsForscastsScript from './GoalsForscastsScripts';
export default GoalsForscastsScript;
</script>

<style lang="scss">

.consumption-total-style {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  
}

.style-div {
  
  display: flex;
  //justify-content: space-evenly;
  justify-content: flex-start;

}

#geraPDF, #geraPDF * {
    /* visibility: hidden; */
    visibility:hidden; 
    
    
  }

/* --------- PRINT --------- */

@media print{

  .dashboardData {
    display: none;
  }

  body * {
    visibility: hidden;
  }
  html, body {
    -webkit-print-color-adjust: exact;   /* Obriga o navegador a reconhecer o background*/ 
    height: 100%;
    width: 100%;
    
  }
  #geraPDF, #geraPDF * {
    visibility: visible;
    
  }

  #geraPDF {  
    height: 100%;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    margin: 0;
    padding: 15px 0px 15px 0px;
    font-size: 14px;
    line-height: 18px;
  }

  
  .layout-data-abstract {
    background:#1976d2 !important; 
    border-radius: 1rem !important;
    
    .layout-data-abstract-fields {
      padding-top:0px !important;
      padding-bottom:0px !important;
      margin-bottom: 1rem;

      .style-card-city-name {
        background:#3298ed  !important; 
        min-height: 4rem !important; 
        text-align:center !important;
        border-radius: 1rem;
        padding: 1rem;
        font-size: 0.7rem;
        word-break: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        span {
          color:white; 
          font-style: italic; 
        }
      }

      .card-style-data-client {
        color:white !important; 
        background:#1976d2 !important;
        text-align:center !important;
        border-style:none !important;
        font-size: 0.7rem !important;
        
      }
    }

    
  }

  

  .v-divider {
    border-color: white !important;
    border-width: 0.05rem;
  }

  




  #printlogo {
    
    margin-top: -8px;
    margin-bottom: 4rem;
    height: 5.5rem;
    background:#1976d2 !important; 
    
    width: 100%;



    .header-logo-foz{
      
      width: 30%;
      height: 100%;
      float: right; 
      margin-top:0;
      text-align: center;

      .v-img{
        width: 100%;
        height: auto;
      }
    }

    .header-text{
      float: left;
      font-size: 2rem;
      width: 52%;
      line-height: 200%;
      margin-left: 1rem;
      color:white !important; 
      
    }
  }
  
}


/* --------- PRINT --------- */



.layout-data-abstract-fields {
  padding-top:0px !important;
  padding-bottom:0px !important;
}

.layout-data-abstract {
  background:#1976d2 !important; 
  border-radius: 1rem !important;
  
  
}

.style-card-city-name {
  background:#3298ed  !important; 
  
  text-align:center !important;
  border-radius: 1rem;
  padding: 1rem;
  font-size: 1rem;
  word-break: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  span {
    color:white; 
    font-style: italic; 
  }
}

.v-divider {
  border-color: white;
  border-width: 0.05rem;
}

.card-style-data-client {
  color:white !important; 
  background:#1976d2 !important;
  text-align:center !important;
  border-style:none !important;
  font-size: 1rem !important;
}

.v-text-field-bar-class {
  border-style: none !important;
}

.text-large {
  font-size: 225%;
  font-weight: 500;
}
.cards {
  padding-top: 5px !important;
  padding-bottom: 4px !important;
  padding-left: 15px !important;
  padding-right: 10px !important;
}
.ticketCards {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.cardsLG {
  margin-bottom: 5px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.ticketCardsLG {
  margin-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.cardsBigger {
  padding-bottom: 4px !important;
  padding-left: 15px !important;
  padding-right: 10px !important;
}
.cardsBiggerLG {
  margin-bottom: 5px !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
}
</style>