<template>
	<v-container fluid>
		<v-card style="border-radius: 20px">
          <v-data-table 
		  	:headers="headers" 
			:items="getItems"
            no-data-text="Sem Projetos para exibir" 
			style="border-radius: 20px"
			:custom-sort="customSort"
			:loading="is_loading"
			>
            <template v-slot:top>
				<v-row>

					<div class="title-displays" style="padding-top: 15px; padding-left: 15px">
					  Projetos
					</div>
					<v-spacer></v-spacer>
					<div>
						<v-btn 
							style="padding-top: 15px; padding-left: 15px"
							color="primary" 
							text
							@click="openDialog()"
							:disabled="is_loading"
						>
							<v-icon>add</v-icon>
							Novo Projeto
						</v-btn>
					</div>
				</v-row>
              <v-spacer></v-spacer>
              <!-- Busca -->
              	<v-row dense>
					<v-col cols="8">
						<v-text-field  prepend-icon="search" label="Busca"
						v-model="searchField"/>
					</v-col>
					<v-col cols="2">
						<v-select 
							hint="Buscar nessa coluna" 
							persistent-hint 
							v-model="searchOptionSelected" 
							:items="searchOptions" 
							hide-details
							single-line
						/>
					</v-col>
					<v-col cols="2">
						<v-select 
							hint="Franqueado" 
							persistent-hint 
							v-model="franchisee_selected" 
							:items="franchisee_list" 
							item-text="name"
							return-object
							single-line
							:loading="is_loading"
							:disabled="is_loading"
						></v-select>
					</v-col>
              	</v-row>
			  	<v-row >
				</v-row>
				<v-row>
					<div class="chip-container">
						<div  class="chip-wrapper" v-for="(step, index) in project_steps_list">
							<v-chip
							class="ma-2"
							:color="isSelectedChip(step.id) ? 'blue darken-2' : 'clay'"
							text-color="white"
							@click="selectProjectTypeFilter(step.id)"
							>
								{{ step.name }}
							</v-chip>
						</div>
					</div>
				</v-row>
            </template>
       
            <template v-slot:item.actions="{ item }">
				<v-btn 
					@click="navigateProject(item)" 
					v-ripple 
					style="margin-right: 5px;"
					:loading="is_loading"
					:disabled="is_loading"
				>
					<v-icon small color="primary"> mdi-eye </v-icon>
				</v-btn>
				
				<!-- <v-btn 
					@click="() => {}" 
					v-ripple
					:loading="is_loading"
					:disabled="is_loading"
				>
					<v-icon small color="primary"> mdi-delete </v-icon>
				</v-btn> -->
              
            </template>
          </v-data-table>
        </v-card>

		<template>
			<v-row justify="center">
				<v-dialog
					v-model="dialog"
					persistent
					max-width="450"
				>
					<v-form 
						ref="newProjectForm"
						v-model="isValidForm"
						lazy-validation
					>
						<v-card>
							<v-card-title class="text-h5">
								<h5>Adicionar um Novo Projeto</h5>
							</v-card-title>
							<v-card-text>
								<v-row>
									<v-col>
										<v-text-field
											v-model="form.project_name"
											label="Nome do Projeto"
											:counter="64"
											required
											:rules="ruleString(true, 64)"
											:disabled="is_loading"
										></v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="4">
										<v-select
											v-model="type_lead_document_selected"
											:items="type_lead_documents"
											label="Tipo do Documento"
											:disabled="is_loading"
										></v-select>
									</v-col>
									<v-col cols="8">
										<v-text-field
											v-model="form.lead_document"
											:label="`Digite ${['CPF', 'CNPJ'].includes(type_lead_document_selected) ? `o` : `a `} ${type_lead_document_selected} do Cliente`"
											v-mask="type_lead_documents_masks[type_lead_document_selected]"
											:rules="type_lead_document_selected == 'CPF' ? ruleCPF(true) : type_lead_document_selected == 'CNPJ' ? ruleCNPJ(true) : ruleRequired()"
											:disabled="is_loading"
										></v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-select 
											label="Franqueado"
											persistent-hint 
											v-model="form.id_franchisee" 
											:items="project_franchisee_list" 
											item-text="name"
											return-object
											single-line
											:disabled="is_loading"
											:rules="ruleRequired()"
										></v-select>
									</v-col>
								</v-row>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									color="green darken-1"
									text
									@click="closeDialog"
									:disabled="is_loading"
								>
									Cancelar
								</v-btn>
								<v-btn
									color="green darken-1"
									text
									:loading="is_loading"
									@click="createProject"
								>
									Criar
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-form>
			  </v-dialog>
			</v-row>
		</template>

		<Propecting 
			v-if="openProspection" 
            :openModal="openProspection"
            :idLead="null"
            :closeFunc="closeFunc"
			:newLead="newLead"
		/>

	</v-container>
</template>
  
<script>
import ProjectsScript from "./ProjectsScript.js";
export default ProjectsScript;
</script>
  
<style scoped>
.title-displays {
	padding-top: 15px; 
	padding-left: 15px;
	font-size: 25px;
	padding-top: 8px;
	padding-bottom: 8px;
}
.chip-container {
  display: flex;
  overflow-x: auto; /* Enables horizontal scrolling */
  white-space: nowrap; /* Prevents wrapping of chips */
}

.chip-wrapper {
  display: inline-block; /* Ensures chips stay inline */
}

/* Optional: Add smooth scrolling behavior */
.chip-container::-webkit-scrollbar {
  height: 6px; /* Adjust scrollbar height */
}

.chip-container::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar */
  border-radius: 4px;
}

.chip-container::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color on hover */
}
</style>
  