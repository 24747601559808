<template>
    <div 
        name="checklist-container" 
        v-if="show" 
        style="
            width: 100%;
            margin-top: 72px;
        "
    >
        <v-form style="height: 100%;">
            <v-row class="no-margin">
                <v-col cols="12">
                    <h3>Projeto:</h3>
                </v-col>
            </v-row>
            <v-row class="no-margin">
                <v-col>
                  <v-select
                    outlined
                    label="Tipo do Projeto"
                    :items="options"
                    :rules="rulesRequired"
                    v-model="checkList.tipoProjeto"
                  >
                  </v-select>
                </v-col>
            </v-row>

            <v-divider></v-divider>
            
            <!-- Start Project Data -->
            <v-row class="no-margin">
                <v-col cols="12">
                    <h3>Dados do Local:</h3>
                </v-col>
            </v-row>
            <v-row v-if="getGroupProjectType() == '2'">
                <v-col cols="4">
                    <v-text-field
                    outlined
                    label="Torres / Blocos/ Galpões "
                    type="Number"
                    prepend-inner-icon="mdi-chess-rook"
                    :rules="rulesRequired"
                    v-model="checkList.torresBlocosGalpoes"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field
                        outlined
                        label="Pavimentos"
                        type="Number"
                        required
                        prepend-inner-icon="mdi-floor-plan"
                        :rules="rulesRequired"
                        v-model="checkList.pavimentos"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field
                        outlined
                        label="Unidades por pavimento"
                        type="Number"
                        required
                        prepend-inner-icon="mdi-home"
                        :rules="rulesRequired"
                        v-model="checkList.unidadesPorPavimento"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field
                        outlined
                        label="Wcs por unidade"
                        type="Number"
                        required
                        prepend-inner-icon="mdi-shower"
                        :rules="rulesRequired"
                        v-model="checkList.wcsPorUnidade"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field
                        outlined
                        label="Total de Wcs"
                        type="Number"
                        required
                        prepend-inner-icon="mdi-shower"
                        :rules="rulesRequired"
                        v-model="checkList.totalWcs"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="4">
                    <v-text-field
                        outlined
                        label="Total de Unidades"
                        type="Number"
                        required
                        prepend-inner-icon="mdi-home"
                        :rules="rulesRequired"
                        v-model="checkList.totalDeUnidades"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="getGroupProjectType() == '1'">
                <v-col cols="3">
                    <v-text-field
                        outlined
                        label="Usuários"
                        type="Number"
                        prepend-inner-icon="person"
                        required
                        :rules="rulesRequired"
                        v-model="checkList.numeroUsuarios"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-text-field
                        outlined
                        label="Total de Wcs"
                        type="Number"
                        prepend-inner-icon="mdi-shower"
                        required
                        :rules="rulesRequired"
                        v-model="checkList.totalWcs"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-text-field
                        outlined
                        label="Chuveiro Elétrico"
                        type="Number"
                        prepend-inner-icon="mdi-shower-head"
                        required
                        :rules="rulesRequired"
                        v-model="checkList.chuveiroEletrico"
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-text-field
                        outlined
                        label="Chuveiro a Gás"
                        type="Number"
                        prepend-inner-icon="mdi-shower-head"
                        required
                        :rules="rulesRequired"
                        v-model="checkList.chuveiroGas"
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <!-- End Project Data -->

            <v-divider></v-divider>
            
            <!-- Start Hydrometers -->
            <HydrometerList 
                :show="showHydrometerList()"
                :list="checkList.hidrometros"
                :add="addHydrometer"
                :remove="removeHydrometer"
            />
            <!-- End Hydrometers -->
            
            <v-divider></v-divider>

            <BombList 
                :show="showBombList()"
                :list="checkList.bombas"
                :add="addBomb"
                :remove="removeBomb"
            />

            <v-divider></v-divider>
            
            <ReservoirList 
                :show="showReservoirList()"
                :list="checkList.reservatorios"
                :add="addReservoir"
                :remove="removeReservoir"
            />

            <v-divider></v-divider>
            
            <!-- 
                SolutionList Component:
                projectId = create dir in firebase storage
            -->
            <SolutionList 
                :list="checkList.solucoes"
                :add="addSolution"
                :remove="removeSolution"
                :projectId="projectId"
            />

        </v-form>
    </div>
</template>
  
<script>
import CheckList from "./CheckList.js";
export default CheckList;
</script>
  
<style scoped>
.no-margin {
    margin: 0;
}
.d-flex {
    display: flex;
}
.f-align-center {
    align-items: center;
}
.f-1 {
    flex: 1;
}
.f-2 {
    flex: 2;
}
</style>