<template>
    <v-col cols="12">
        <!-- start Investor Dialog -->
        <v-row justify="center">
            <v-dialog transition="dialog-top-transition" v-model="modal" persistent max-width="500px">
                <v-card>
                <v-form ref="downloadPdfForm" lazy-validation>
                    <v-toolbar color="primary" dark> <span class="text-h5">{{ headerTxt }}</span></v-toolbar>
                    <v-card-text style="padding-bottom: 0px;">
                        <v-row>
                            <v-col>
                                <v-text-field 
                                    label="Solução"
                                    :value="item.solution"
                                    readonly
                                    disabled
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field 
                                    label="Quantidade"
                                    :value="item.amount"
                                    readonly
                                    disabled
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field 
                                    label="R$ Unidade"
                                    type="number"
                                    v-model="item.unitPrice"
                                    :rules="ruleValue"
                                    @focus="setFocus"
                                    @blur="setBlur"
                                    @update:error="(e) => setError(e, 'unitPrice')"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field 
                                    label="Total"
                                    :value="formatCurrency(item.total)"
                                    readonly
                                    disabled
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="() => closeModal()" :loading="loading" :disabled="loading">
                            Cancelar
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="() => saveItem()" :loading="loading" :disabled="loading || hasDisabled">{{ saveButtonTxt }}</v-btn>
                    </v-card-actions> 
                </v-form>
                </v-card>
            </v-dialog>
        </v-row>
        <!-- end Investor Dialog -->

        <!-- Start Table Solutions -->
        <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="12"
        class="elevation-1"
        style="width: 100%"
        >
        <template v-slot:top>
            <v-toolbar flat class="rounded-lg">
            <v-toolbar-title>Soluções</v-toolbar-title>
            <v-spacer></v-spacer>
            <!-- <v-btn 
                color="primary" 
                class="mb-2"
                text 
                @click="() => addNewItem()" 
   
            >
                <v-icon>add</v-icon>
            </v-btn> -->
            </v-toolbar>
        </template>
        
        <template v-slot:body="{ items }">
            <tbody>      
            <tr v-for="(item, index) in items" :key="index">
                <td style="display: flex">
                <v-text-field
                    :value="item.solution"
                    readonly
                >
                </v-text-field> 
                </td>
                <td>
                <v-text-field
                    :value="item.amount"
                    readonly
                >
                </v-text-field>
                </td>
                <td>
                <v-text-field
                    :value="formatCurrency(item.unitPrice)"
                    readonly
                >
                </v-text-field>
                </td>
                <td>
                <v-text-field
                    :value="formatCurrency(item.total)"
                    readonly
                >
                </v-text-field>
                </td>
                <td>
                <v-btn
                    elevation="2"
                    @click="() => editItem(index)"
                    style="height: 30px; margin: 2px"
                    :loading="loading" 
                    :disabled="loading"
                >
                    <v-icon color="primary" small> edit </v-icon>
                </v-btn>
                <v-btn
                    v-if="false"
                    elevation="2"
                    @click="() => deleteItem(index)"
                    style="height: 30px; margin: 2px"
                    :loading="loading" 
                    :disabled="loading"
                >
                    <v-icon color="primary" small> mdi-delete </v-icon>
                </v-btn>
                </td>
            </tr>
            </tbody>
        </template>
        </v-data-table>
        <!-- End Table Solutions -->
    </v-col>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import WaterScanService from '@/services/firebase/WaterScan/WaterScanService';
import WaterFixService from '@/services/firebase/WaterFix/WaterFixService';
import Utils from "@/scripts/Utils";
import Rules from "@/utils/Rules";
import SolutionsServices from '@/services/firebase/SolutionsServices/SolutionsServices';

export default {
    props: {
        proposal: {
            type: Object,
            require: true,
        },
    },
    data: () => ({
        field: 'solutionPrice',
        key: `proposed.solutionPrice`,
        id: null,
        headers: [
            { text: "Solução", value: "solution" },
            { text: "Quantidade", value: "amount" },
            { text: "R$ Unidade", value: "unitPrice" },
            { text: "Total", value: "total" },
            { text: "Ações ", value: "action" },
        ],
        modal: false,
        loading: true,
        focus: false,
        blur: false,
        error: false,
        list: [],
        mode: 'create',
        headerTxt: 'Adição de Solução',
        saveButtonTxt: 'Adicionar',
        indexEdit: null,
        item: {
            unitPrice: 0,
        },
        errors: {
            unitPrice: false,
        }
    }),
    computed: {
        ruleValue() {
            return Rules.number(true, 0)
        },
        hasError() {
            return Object.values(this.errors).reduce((prev, cur) => prev || cur, false)
        },
        hasDisabled() {
            return this.hasError || this.focus
        },
    },
    methods: {
        ...mapActions([
            'publishChannel',
            'subscribeChannel',
        ]),
        formatNumber(value, numberDigits = 2) {
            return Utils.formatNumber(value, numberDigits)
        },
        formatCurrency(value, numberDigits = 2) {
            if (value < 0) {
                return `R$ -${this.formatNumber(value, numberDigits)}`
            } else {
                return `R$ ${this.formatNumber(value, numberDigits)}`
            }
        },
        resetItem() {
            this.item = {
                unitPrice: 0,
            }
            this.errors = {
                unitPrice: false,
            }
        },
        addNewItem() {
            this.resetItem()
            this.mode = 'create'
            this.headerTxt = 'Adição de Solução'
            this.indexEdit = null
            this.saveButtonTxt = 'Adicionar'
            this.openModal()
        },
        openModal() {
            this.modal = true
        },
        closeModal() {
            this.modal = false
        },
        setLoading(status) {
            this.loading = status
            this.publishChannel({
                channel: 'waterscan',
                event: `${this.key}:loading`,
                value: status,
            })
        },
        setDisabled(status) {
            this.publishChannel({
                channel: 'waterscan',
                event: `${this.key}:disabled`,
                value: status,
            })
        },
        setError(error, key) {
            this.errors[key] = error
        },
        setFocus(status) {
            this.focus = true
            this.blur = false
        },
        setBlur(status) {
            this.focus = false
            this.blur = true
        },
        forceUpdateList(newList) {
            this.list = Utils.copyObject(newList)
        },
        async saveItem() {
            if (this.hasError) return
            this.setLoading(true)
            if (this.mode == 'create') {
                this.list.push(Utils.copyObject(this.item))
            } else {
                this.list[this.indexEdit] = Utils.copyObject(this.item)
            }
            this.forceUpdateList(this.list)
            try {
                this.emitList()
                this.setLoading(false)
                this.closeModal()
            } catch (e) {
                console.error(e)
                this.setLoading(false)
            }
        },
        async editItem(index) {
            this.resetItem()
            this.mode = 'edit'
            this.indexEdit = index
            this.headerTxt = 'Edição de Solução'
            this.saveButtonTxt = 'Editar'
            this.item = Utils.copyObject(this.list[index])
            this.openModal()
        },
        async deleteItem(index) {
            this.setLoading(true)
            try {
                this.list.splice(index, 1)
                this.emitList()
                this.closeModal()
                this.setLoading(false)
            } catch (e) {
                console.error(e)
                this.setLoading(false)
            }
        },
        emitList() {
            this.publishChannel({
                channel: 'waterscan',
                event: this.key,
                val: Utils.copyObject(this.list),
            })
        },
        async readSolutionList(solutionList) {
            // Verificar dentro das soluções do checklist da proposta
            // se há algum faltando dentro da lista de soluções
            let _solutionList = solutionList
            let _list = []
            
            let countSolutionlistResume = {}
            _solutionList.forEach(
                ({ solution, amountSolution }) => {
                    if (!countSolutionlistResume.hasOwnProperty(solution)) {
                        countSolutionlistResume[solution] = 0
                    }
                    countSolutionlistResume[solution] += parseInt(amountSolution)
                }
            );
            
            let listSearchSolution = Object.keys(countSolutionlistResume)
            listSearchSolution = listSearchSolution.filter(v => v !== null)
            let response = await SolutionsServices.GetSolutionsByIds(listSearchSolution)
            Object.values(response).forEach(
                (item) => {
                    if (item === null) return
                    let { id, solution, price } = item
                    _list.push({
                        solutionId: id,
                        solution,
                        unitPrice: parseFloat(price),
                        amount: countSolutionlistResume[id],
                        total: countSolutionlistResume[id] * parseFloat(price)
                    })
                }
            )
            
            let prices = {}
            this.list.forEach(
                ({ solutionId, unitPrice }) => {
                    if (!prices.hasOwnProperty(solutionId)) {
                        prices[solutionId] = parseFloat(unitPrice)
                    }
                }
            )

            this.list = _list.map(
                (item) => {
                    if (!prices.hasOwnProperty(item.solutionId)) return item
                    return {
                        ...item, 
                        unitPrice: prices[item.solutionId], 
                        total: prices[item.solutionId] * parseInt(item.amount)
                    }
                }
            )

            this.emitList()
        }
    },
    watch: {
        async proposal(newValue) {
            if (newValue) {
                this.id = newValue.id
                try {
                    // this.key = `proposed.${this.field}`
                    try {
                        this.list = Utils.getting(newValue, this.key)
                    } catch (e) {
                        this.list = []
                        await WaterScanService.updateById(this.key, this.list, this.id)
                    }
                    // Verificar dentro das soluções do checklist da proposta
                    // se há algum faltando dentro da lista de soluções
                    let solutionList = []
                    try {
                        solutionList = Utils.getting(newValue, 'solutionList')
                    } catch (e) {
                        solutionList = []
                    }
                    this.readSolutionList(solutionList)
                    this.setLoading(false)
                } catch (e) {
                    console.error(e)
                    this.setLoading(false)
                }
            }
        },
        hasDisabled(newVal) {
            if (newVal) {
                this.setDisabled(true)
            } else {
                this.setDisabled(false)
            }
        },
        'item.unitPrice': function (newVal) {
            if (newVal !== null) {
                this.item.total = this.item.amount * newVal
            }
        }
    },
    created() {
        this.subscribeChannel({
            channel: 'waterscan',
            events: [
                {
                    event: 'solutionList:update',
                    fn: (solutionList) => {
                        this.readSolutionList(solutionList)
                    }
                },
            ]
        })
    },
}

</script>