<template>
  <v-container fluid>
    <Signature 
      v-if="dialogSignature"
      :pdfUrl="documentUrl"
      :dialog="dialogSignature"
    />
    <!-- Dialog de novo local -->
    <v-row justify="center">
      <v-dialog transition="dialog-top-transition" v-model="choosePdfModal" persistent max-width="600px">
        <v-card>
          <v-form ref="downloadPdfForm" lazy-validation>
            <v-toolbar color="primary" dark> <span class="text-h5">Escolher a forma de baixar</span></v-toolbar>
            <v-card-title>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text style="padding-bottom: 0px;">
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="termOption"
                    :items="termOptions"
                    label="Opção do PDF"
                    item-text="name"
                    item-value="id"
                    outlined
                    :loading="loading"
                    :disabled="loading"
                    :rules="rulesRequired"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row  v-if="terms_with_multiple_options.includes(termOption)">
                <v-col cols="12">
                  <v-autocomplete
                    v-model="chosen_option_by_term[termOption]"
                    :items="options_by_terms[termOption]"
                    label="Qual a tipo do termo?"
                    item-text="name"
                    item-value="id"
                    outlined
                    :loading="loading"
                    :disabled="loading"
                    :rules="rulesRequired"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="chosen_option_by_term[termOption] == 'Local'" cols="12">
                  <v-autocomplete
                    v-model="chosen_local_by_term[termOption]"
                    :items="locals"
                    item-text="local"
                    item-value="id"
                    outlined
                    dense
                    chips
                    small-chips
                    label="Qual o local?"
                    :rules="rulesRequired"
                    :loading="loading"
                    :disabled="loading"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="() => closeChoosePdfModal()" :loading="loading" :disabled="loading">
                    Cancelar
                </v-btn>
                <v-btn color="blue darken-1" text @click="() => downloadPdfModal()" :loading="loading" :disabled="loading">Baixar</v-btn>
            </v-card-actions> 
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card style="border-radius: 20px">
          <v-overlay absolute style="opacity: 0.6" :value="isLoadingProposal">
            <v-progress-circular
              indeterminate
              color="primary"
              :size="50"
            ></v-progress-circular>
          </v-overlay>
          <v-data-table :headers="headers" :items="filterItems" :custom-sort="customSort"
            :no-data-text="noDataText" style="border-radius: 20px">
            <template v-slot:top>
              <div class="title-displays" style="padding-top: 15px; padding-left: 15px">
                Propostas
              </div>
              <v-spacer></v-spacer>

              <!-- Busca -->
              <v-row style="justify-content: space-between">
                <v-text-field style="width: 80%; padding: 1em" class="px-4" prepend-icon="search" label="Busca"
                  v-model="searchText"></v-text-field>
                <v-select hint="Buscar nessa coluna" persistent-hint style="width:20%; padding: 1em" item-text="text"
                  item-value="value" v-model="columnToSeach" :items="columnsToChoose" hide-details
                  single-line></v-select>
              </v-row>
            </template>
            <template v-slot:item.id_franchisee="{ item }">
              {{ checkFranchisee(item) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                elevation="2"
                @click="() => editProposal(item)"
              >
                <v-icon color="primary" small> edit </v-icon>
              </v-btn>

              <v-btn
                elevation="2"
                @click="getProposalDelet(item.registration)"
              >
                <v-icon color="primary" small> mdi-delete </v-icon>
              </v-btn>

              <v-btn
                elevation="2"
                @click="() => newDownloadPdf(item)"
              >
                <v-icon color="primary" small>mdi-file-pdf-box</v-icon>
              </v-btn>  
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- Inicio Nova Proposta -->
    <NewWaterFix
      v-if="openViewProposal"
      :open="openViewProposal"
      :closeFunc="closeComponent"
      :registration="registration"
      :viewOrEdit="'view'"
    />
    <!-- Fim Nova Proposta -->
  </v-container>
</template>
<script>
import ConsultWaterFixScript from "./ConsultWaterFixScript";
export default ConsultWaterFixScript;
</script>