import { db } from "@/firebase"
const project_hunters_ref = db.collection("project_hunters")

const createProjectHunter = async (data) => {
    try {
        const get_data = await project_hunters_ref.add({...data, deletedAt: null});
        await editProjectHunter(get_data.id, { ...data, id_doc: get_data.id })
        return get_data.id;
    } catch (error) {
        throw new Error("createProjectHunter Fail!" + error)
    }
}
const editProjectHunter = async (idDoc, data) => {
    try {
        await project_hunters_ref.doc(idDoc).update(data);
        return true;
    } catch (error) {
        throw new Error("editProjectHunter Fail!" + error)
    }
}
async function getProjectHunters() {
    let project_partner_list = []
    try {
        const unitsRef = await project_hunters_ref.where("deletedAt","==",null).get()
        unitsRef.forEach((doc) => project_partner_list.push(doc.data()));
        return project_partner_list
    } catch (error) {
        console.error(error)
        throw new Error("getProjectHunters Fail!" + error)
    }
}
async function getProjectHuntersByIdFranchisee(IdFranchise) {
    let project_partner_list = []
    try {
        const unitsRef = await project_hunters_ref
        .where("id_franchisee","==",IdFranchise)
        .where("deletedAt","==",null)
        .get()
        unitsRef.forEach((doc) => project_partner_list.push(doc.data()));
        return project_partner_list
    } catch (error) {
        console.error(error)
        throw new Error("getProjectHuntersByIdFranchisee Fail!" + error)
    }
}
async function getProjectHuntersById(idProjectHunter) {
    try {
        const projectHunter = await project_hunters_ref.doc(idProjectHunter).get()
        return projectHunter.data()
    } catch (error) {
        console.error(error)
        throw new Error("getProjectHuntersById Fail!" + error)
    }
}
async function getProjectHuntersByDocument(document) {
    let project_hunter_list = []
    try {
        const unitsRef = await project_hunters_ref
        .where("document","==", document)
        .get()
        
        unitsRef.forEach((doc) => project_hunter_list.push(doc.data()));
        return project_hunter_list.length? project_hunter_list[0] : null
    } catch (error) {
        console.error(error)
        throw new Error("getProjectHuntersByDocument Fail!" + error)
    }
}

export default {
    getProjectHuntersByIdFranchisee,
    getProjectHuntersByDocument,
    getProjectHuntersById,
    createProjectHunter,
    editProjectHunter,
    getProjectHunters,
};