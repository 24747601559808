import Utils from '@/scripts/Utils';
import ConsumptionApi from '@/services/apiPdf/ConsumptionApi.js';
import UsersService from "@/services/server/UsersService";
import UnitsService from "@/services/server/UnitsService";
import ChartConsumption from "@/components/charts/ChartsConsumption/Consumption.vue";
import ConsumptionFunctions from "@/pages/Consumption/ConsumptionFunctions.js"
import SectorsService from "@/services/firebase/Sectors/SectorsService";
import UnitsFbService from "@/services/firebase/Units/UnitsService";
import MdensService from "@/services/firebase/Modens/MdensService";
import KpiCard from "@/components/KPI/KpiCard.vue";
import ConsumptionService from "@/services/server/ConsumptionService.js";
import NewChartConsumption from "./ChartsConsumption/Consumption.vue";
import Rules from "@/utils/Rules";
import { showErrorSnackBar, showSuccessSnackBar, showInfoSnackBar } from "@/utils/SnackBartHelper";
import debounce from 'lodash/debounce';
var { DateTime } = require("luxon");

const PROJECT_START_YEAR = '2020'

import { generateXLSX } from "./ReportEconomyXLSX";

export default {

  name: "Consumption",

  components: {
    NewChartConsumption,
    ChartConsumption,
    KpiCard
  },


  data() {
    return {
      unitsDataGraphic: [],
      summedGraphs: [],
      selectsLoading: {
        client: false,
        unit: false,
        sector: false
      },
      consultDatalLoading: {
        graphicButton: false,
        readingButton: false,
      },
      totalConsumptionUnitsSelected: false,
      groupingDataGraphic: false,
      monthListIndex: [],
      isDataGraphic: false,
      menuDateStart: false,
      menuDateEnd: false,
      dateSelectedEnd: '',
      data_abstract_client: {
        name: "Selecione um cliente",
        consumptionType: "-/-",
        registry: "-",
        address: "-",
        last_updated: "-"
      },
      readings_table_table1: [],
      readings_table_table2: [],
      loadButtonPdf: false,
      listClients: [],
      selectedClient: null,
      listUnits: [],
      selectedUnits: [],
      listSectors: [],
      selectedSector: [],
      readings: [],
      optionMetric: true,
      switch1: "Metros Cúbicos (m³)",
      validDayPeriodSelected: true,
      validHourPeriodSelected: true,
      validMonthPeriodSelected: true,
      consultReading: false,
      optionsToDisplay: [{ label: "Dia", value: "day" }],
      optionToDisplaySelected: "day",
      totalEstimatedConsumption: [],
      dateFormattedSelectedStart: '',
      dateFormattedSelectedEnd: '',
      dateSelectedFormatted: Utils.formatDate(new Date(), "<dd>/<MM>/<YYYY>"),
      dateSelectedStart: '',
      dateSelected: Utils.formatDate(new Date(), "<YYYY>-<MM>-<dd>"),
      menuDate: false,
      byMonth: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
      byYear: Utils.generateYearsByInterval(PROJECT_START_YEAR, 'now').map((date) => Utils.getDateInfo(date, false)['year']),
      monthSelected: '',
      yearSelected: '',
      optionPeriod: '',
      consultGraph: false,
      consultTable: false,
      graphicFlag: false,
      isDataTable: false,
      headers_tabledays: [
        { text: 'Data', align: 'center', sortable: false, value: 'date' },
        { text: 'Medição', value: 'value', align: 'center', },
      ],
      readings_table: [],
      monthListIndex: {
        Janeiro: { month: 1 },
        Fevereiro: { month: 2 },
        Março: { month: 3 },
        Abril: { month: 4 },
        Maio: { month: 5 },
        Junho: { month: 6 },
        Julho: { month: 7 },
        Agosto: { month: 8 },
        Setembro: { month: 9 },
        Outubro: { month: 10 },
        Novembro: { month: 11 },
        Dezembro: { month: 12 }
      },
      loadingReadings: false,
      dateOptions: {
        startDate: DateTime.utc()
          .setZone("America/Sao_Paulo")
          .startOf("day")
          .toSeconds(),
        endDate: DateTime.utc().setZone("America/Sao_Paulo").toSeconds(),
        mode: "day",
      },
      chartMenuItems: ["Últimos 7 dias", "Por mês", "Por dia", "Por hora", "Por intervalo"],
      dialog: false,
      showNextReading: false,
      nextDateFormated: '',
      resume: {},
      totalDates: 0,
      permittedStartDate: null,
      permittedEndDate: null,
      selectsModal: false,
      validForm: true,
      unitSelectedDataKPIs: {}
    };
  },

  computed: {
    rulesRequiredArray() {
      return [this.requiredRuleArray()]
    },
    getNameByIdClient() {
      const client = this.listClients.find(({ id }) => id === this.selectedClient)
      return client ? client.name : ""
    },
    rulesRequired() {
      return Rules.required();
    },
    rulesRequired() {
      return Rules.required();
    },
    isCubicMetersSelected() {
      return this.switch1 == 'Metros Cúbicos (m³)'
    },
    isLitersSelected() {
      return !this.isCubicMetersSelected
    },
    unitMeasurement() {
      return this.isCubicMetersSelected ? 'cubic_meter' : 'liters'
    },
    likesAllUnits() {
      return this.selectedUnits.length === this.listUnits.length
    },
    likesSomeUnits() {
      return this.selectedUnits.length > 0 && !this.likesAllUnits
    },
    iconUnitSelect() {
      if (this.likesAllUnits) return 'mdi-close-box'
      if (this.likesSomeUnits) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    likesAllSectors() {
      return this.selectedSector.length === this.listSectors.length
    },
    likesSomeSectors() {
      return this.selectedSector.length > 0 && !this.likesAllSectors
    },
    iconSectorSelect() {
      if (this.likesAllSectors) return 'mdi-close-box'
      if (this.likesSomeSectors) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
  },

  methods: {
    requiredRuleArray(errorText = 'Campo requerido') {
      return value => value === null || !value.length ? errorText : true;
    },
    toggleSectorsList() {
      this.$nextTick(() => {
        if (this.likesAllSectors) {
          this.selectedSector = []
        } else {
          this.selectedSector = this.listSectors.slice()
        }
        this.onChangeSector(this.selectedSector)
      })
    },
    toggleUnitsList() {
      this.$nextTick(() => {
        if (this.likesAllUnits) {
          this.selectedUnits = []
        } else {
          this.selectedUnits = this.listUnits.slice().map((unit => unit.id))
        }
        this.onChangeUnit(this.selectedUnits)
      })
    },
    async setNextReading() {
      this.nextDateFormated = ''
      this.showNextReading = false;
      if (!this.selectedUnits.length) return;
      let historicos = []
      const historics = await UnitsFbService.getSubCollectionHistorics(this.selectedUnit)

      if (historics.length <= 0) {
        this.nextDateFormated = 'Não há histórico'
        this.showNextReading = true;
        return;
      }
      historicos = historics.map(d => {
        let day = '01';
        if (d.hasOwnProperty('day')) day = d.day;
        return { ...d, day }
      })
      let dates = historicos.map(h => Utils.instanceDate(`${h.month}-${h.day}`))
      dates = dates.sort((datePrevious, dateNext) => {
        if (datePrevious < dateNext) return -1;
        else if (datePrevious > dateNext) return 1;
        else return 0;
      });
      let lastDate = dates.pop()
      this.nextDateFormated = `${Utils.formatDate(lastDate, '<DD>')}/${Utils.formatDate('now', '<MM>/<YYYY>')}`
      this.showNextReading = true;
    },
    onKeydownSector(key) {
      if (key.code === 'Backspace') {
        this.closeInfoData();
        this.selectedSector = [];
      }
    },
    onChangeSector(sector) {
      this.readings_table = []
      this.groupingDataGraphic = false

      this.isDataGraphic = false;
      this.isDataTable = false;
    },
    onKeydownUnit(key) {
      if (key.code === 'Backspace') {
        this.closeInfoData();
        this.selectedUnits = [];
        this.selectedSector = [];
        this.listSectors = [];
      }
    },
    async getSectorsByUnitId(idUnits) {
      const execFuncs = idUnits.map(id => SectorsService.getSectorsByUnitId(id))

      await Promise.all(execFuncs).then((values) => {
        this.listSectors = values.flatMap(value => [...value])
      })
    },
    async onChangeUnit(idUnits) {
      this.listSectors = [];
      this.selectedSector = [];
      this.selectsLoading.sector = true
      await this.getSectorsByUnitId(idUnits)
      this.closeInfoData();
      // this.setNextReading();
      if(idUnits.length === 1) this.onChangeInfoUnit(idUnits[0])
      this.selectedSector = [];
      this.selectsLoading.sector = false
      if (this.listSectors.length === 1) this.selectedSector = this.listSectors;
    },
    onKeydownClient(key) {
      if (key.code === 'Backspace') {
        this.closeInfoData();
        this.selectedClient = [];
        this.selectedUnits = [];
        this.listUnits = [];
        this.selectedSector = [];
        this.listSectors = [];
      }
    },
    async onChangeClient(idClient) {
      this.selectsLoading.unit = true
      this.closeInfoData();
      this.selectedUnits = [];
      this.selectedSector = [];
      this.showNextReading = false;

      const response = await UnitsService.findAllByClient(idClient)
      this.listUnits = response.data.data
      this.selectsLoading.unit = false
      if (this.listUnits.length === 1) {
        this.selectedUnits = [this.listUnits[0].id];
        this.onChangeUnit(this.selectedUnits);
      }
    },

    async exportSheets() {
      if (this.$refs.formConsumption && !this.$refs.formConsumption.validate()) return;
      if (this.selectedUnits.length > 1) {
        showInfoSnackBar("Selecione apenas uma unidade para gerar a planilha!")
        return
      }
      if (!this.selectedSector.length) {
        showInfoSnackBar("Selecione ao menos um setor para gerar a planilha")
        return
      }
      const unitSelected = this.listUnits.find(({ id }) => id === this.selectedUnits[0])

      let excelData = {
        unit_name: unitSelected.name,
        sectors: []
      }
      let resumeValues = Object.values(this.resume)
      for (let i = 0; i < resumeValues.length; i++) {
        let resume = resumeValues[i]
        let modemName = resume['modemName']
        let rows = []
        rows.push(modemName)
        resume.readings.forEach(
          (reading) => {
            let row = [reading.label, reading.water_liters.toFixed(0), reading.water_cubic_meters.toFixed(3)]
            rows.push(row)
          }
        )
        let lastRow = ['Total', resume.total_liters.toFixed(0), resume.total_cubic_meters.toFixed(3)]
        rows.push(lastRow)
        excelData.sectors.push(rows)
      }
      await generateXLSX(excelData)
    },
    estimatedConsumption(total, estimated) {
      let sum = total + estimated
      return sum.toFixed(2)
    },
    closeInfoData() {
      this.unitSelectedDataKPIs = {}
      this.data_abstract_client = {
        name: "Selecione um cliente",
        consumptionType: "-/-",
        registry: "-",
        address: "-",
        last_updated: "-"
      };
      this.isDataGraphic = false;
      this.isDataTable = false;

    },
    async onChangeInfoUnit(idUnit) {
      const formatValue = (value) => {
        return new Intl.NumberFormat('pt-BR').format(value);
      }
      const unitSelected = this.listUnits.find(({ id }) => id === idUnit)
      const averageBeforeFoz = unitSelected.averageBeforeFoz
      this.unitSelectedDataKPIs.goalEconomy = `${formatValue(averageBeforeFoz - (averageBeforeFoz * (unitSelected.metaEconomia/100)))} M³`
      this.unitSelectedDataKPIs.averageBeforeFoz = `${formatValue(averageBeforeFoz)} M³`
      this.unitSelectedDataKPIs.goalPercentage = `${formatValue(unitSelected.metaEconomia)} %`
      this.unitSelectedDataKPIs.consumptionType = `${unitSelected.tarrifsTypes.water.tariffType} / ${unitSelected.tarrifsTypes.sewer.tariffType}`
    },
    async startConsultTable() {
      this.graphicFlag = false;
      this.consultTable = false;
      this.consultGraph = false; 
      this.isDataGraphic = false; 
      this.isDataTable = false;
      // Ativa flags que permite fazer a impressão e também flag de carregamento dos dados (vue loading)
      if (this.$refs.formConsumption && !this.$refs.formConsumption.validate()) return;
      this.loadingReadings = true;
      this.readings_table = []
      this.validDayPeriodSelected = true;
      this.validHourPeriodSelected = true;
      this.validMonthPeriodSelected = true;


      this.consultDatalLoading.readingButton = true
      await this.generateAllConsumption(false)
      this.consultDatalLoading.readingButton = false

      this.readings_table = ConsumptionFunctions.createDataTable(this.resume)

      if (this.isCubicMetersSelected) {
        this.headers_tabledays = [
          { text: 'Data', align: 'center', sortable: false, value: 'date' },
          { text: 'Medição (m³)', value: 'value', align: 'center', },
        ]
      } else {
        this.headers_tabledays = [
          { text: 'Data', align: 'center', sortable: false, value: 'date' },
          { text: 'Medição (L)', value: 'value', align: 'center', },
        ]
      }
      this.consultReading = false;
      this.isDataGraphic = false;        // Não exibe gráfico
      this.isDataTable = true;
      this.consultGraph = false;
      this.loadingReadings = false;
      this.selectsModal = false


    },
    getResumeData(modemId, key) {
      if (!this.resume.hasOwnProperty(modemId)) throw new Error(`Não foi encontrado o modem '${modemId}' no resumo dos dados`)
      let resumeModem = this.resume[modemId]
      if (!resumeModem.hasOwnProperty(key)) throw new Error(`Não foi encontrado o dado '${key}' no resumo dos dados`)
      return resumeModem[key]
    },
    generateModemIds() {
      let modems = []
      if (!Array.isArray(this.selectedSector)) {
        modems.push(this.selectedSector)
      } else {
        modems = this.selectedSector
      }
      let modemIds = modems.map((modem) => modem.id_modem)
      return modemIds
    },
    getPeriod() {
      let startDate = null
      let endDate = null


      // Se período selecionado foi 'Últimos 7 dias'
      if (this.optionPeriod == 'Últimos 7 dias') { // ok
        this.dateOptions.startDate = Utils.add_day(Utils.getMidnightToday(), -7)
        this.dateOptions.endDate = Utils.getLastDateOfDay(Utils.getMidnightToday())
        this.dateOptions.mode = "day";
        // Convertendo para o intervalo correspondente
        startDate = this.dateOptions.startDate
        endDate = this.dateOptions.endDate
      } else if (this.optionPeriod == 'Por dia') { // ok
        // Trata o mês ao qual o usuário selecionou.
        this.yearSelected = parseInt(this.yearSelected);
        this.dateOptions.startDate = Utils.instanceDate(`${this.yearSelected}-${this.monthListIndex[this.monthSelected].month}`);
        startDate = this.dateOptions.startDate
        this.dateOptions.endDate = Utils.getLastDayOfMonth(startDate)
        this.dateOptions.mode = "day";
        //`${yearSelect}-`
        endDate = Utils.getLastDateOfDay(Utils.getLastDayOfMonth(startDate))
      } else if (this.optionPeriod == 'Por mês') { // ok
        // Trata o mês ao qual o usuário selecionou.
        var yearSelect = this.yearSelected;
        // Inicializando parâmetros de data
        this.dateOptions.startDate = Utils.instanceDate(`${yearSelect}-01-01 00:00:00`);
        this.dateOptions.endDate = Utils.instanceDate(`${yearSelect}-12-31 23:59:59`);
        this.dateOptions.mode = "month";
        startDate = this.dateOptions.startDate
        endDate = this.dateOptions.endDate
      } else if (this.optionPeriod == 'Por hora') { // ok
        // Quebra data selecionada para o usuário, afim de facilitar a construção do vetor com o intervalo do gráfico.
        var dateSelect = this.dateSelected.split('-');
        // Converte cada elemento do array para inteiro.       
        const year = parseInt(dateSelect[0]);
        const month = parseInt(dateSelect[1]);
        const day = parseInt(dateSelect[2]);
        // Inicializando parâmetros de data
        this.dateOptions.startDate = Utils.instanceDate(`${year}-${month}-${day} 00:00:00`);
        this.dateOptions.endDate = Utils.instanceDate(`${year}-${month}-${day} 23:59:59`);
        this.dateOptions.mode = "hour";
        startDate = this.dateOptions.startDate
        endDate = this.dateOptions.endDate
      } else if (this.optionPeriod == 'Por intervalo') { // ok
        // Quebra data selecionada para o usuário, afim de facilitar a construção do vetor com o intervalo do gráfico.
        var dateSelectStart = this.dateSelectedStart.split('-');
        // Converte cada elemento do array para inteiro.       
        dateSelectStart[0] = parseInt(dateSelectStart[0]);
        dateSelectStart[1] = parseInt(dateSelectStart[1]);
        dateSelectStart[2] = parseInt(dateSelectStart[2]);
        // Quebra data selecionada para o usuário, afim de facilitar a construção do vetor com o intervalo do gráfico.
        var dateSelectEnd = this.dateSelectedEnd.split('-');
        // Converte cada elemento do array para inteiro.       
        dateSelectEnd[0] = parseInt(dateSelectEnd[0]);
        dateSelectEnd[1] = parseInt(dateSelectEnd[1]);
        dateSelectEnd[2] = parseInt(dateSelectEnd[2]);
        this.dateOptions.startDate = Utils.instanceDate(`${dateSelectStart[0]}-${dateSelectStart[1]}-${dateSelectStart[2]} 00:00:00`);
        this.dateOptions.endDate = Utils.instanceDate(`${dateSelectEnd[0]}-${dateSelectEnd[1]}-${dateSelectEnd[2]} 23:59:59`);
        this.dateOptions.mode = this.optionToDisplaySelected;
        startDate = this.dateOptions.startDate
        endDate = this.dateOptions.endDate
      } else {
        throw new Error("Unidentified period")
      }
      return { startDate, endDate }
    },
    mountDataChart(resultConsumptionsUnit) {

      const labels = resultConsumptionsUnit.consumptions.map(consumption => {
        return this.optionPeriod == 'Por hora'? new Date(consumption.time).toLocaleString() : new Date(consumption.time).toLocaleDateString()
      });
      const dataset = [
        {
          label: 'Água',
          data: resultConsumptionsUnit.consumptions.map(consumption => consumption.valueWater),  // Exemplo: [10, 20, 30, 0]
          backgroundColor: '#2FA4DD',
          borderColor: '#2FA4DD',
          fill: false,
          draw: false,
          borderSkipped: false,
          // barThickness: 30,
          order: 1
        },
        {
          label: 'Efluentes',
          data: resultConsumptionsUnit.consumptions.map(consumption => consumption.valueSewer),  // Exemplo: [5, 0, 10, 15]
          backgroundColor: '#0A517F',
          borderColor: '#0A517F',
          fill: false,
          draw: false,
          borderSkipped: false,
          // barThickness: 30,
          order: 1
        },
        {
          label: 'Média Água',
          data: Array(labels.length).fill(resultConsumptionsUnit.totalWaterAverageCurrent), // Cria um array com a média repetida
          type: 'line',
          borderColor: '#0E76BC',
          backgroundColor: '#0E76BC',
          borderWidth: 1,
          fill: false,
          pointRadius: 0, // Remove os pontos da linha
          order: 2,
        },
        // Linha média para Consumo de Esgoto
        {
          label: 'Média Efluentes',
          data: Array(labels.length).fill(resultConsumptionsUnit.totalSewerAverageCurrent), // Cria um array com a média repetida
          type: 'line',
          borderColor: '#0a317f',
          backgroundColor: '#0a317f',
          borderWidth: 1,
          fill: false,
          pointRadius: 0, // Remove os pontos da linha
          order: 2,
        },
      ]
      return { labels, dataset }
    },
    shadeColor(hex, percent) {
      hex = hex.replace(/^#/, '');

      let r = parseInt(hex.substring(0, 2), 16);
      let g = parseInt(hex.substring(2, 4), 16);
      let b = parseInt(hex.substring(4, 6), 16);

      r = Math.min(255, Math.max(0, r + Math.round((255 - r) * percent / 100)));
      g = Math.min(255, Math.max(0, g + Math.round((255 - g) * percent / 100)));
      b = Math.min(255, Math.max(0, b + Math.round((255 - b) * percent / 100)));

      return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
    },

    mountGroupDataChart(allConsumptions) {
      const baseHexWater = "2FA4DD";
      const baseHexSewer = "0a317f";
      const labels = allConsumptions[0].consumptions.map(consumption => {
        return this.optionPeriod == 'Por hora'? new Date(consumption.time).toLocaleString() : new Date(consumption.time).toLocaleDateString()
      });
      const currentDate = new Date();
      const dataset = allConsumptions.flatMap((unit, index) => [
        {
          data: unit.consumptions.map(consumption => consumption.valueWater),
          label: 'Água: ' + unit.unit_name,
          backgroundColor: unit.consumptions.map(consumption => 
            new Date(consumption.time) > currentDate ? this.shadeColor('#0ffc03', index * -30) : this.shadeColor(baseHexWater, index * -30)
          ),
          borderColor: unit.consumptions.map(consumption => 
            new Date(consumption.time) > currentDate ? this.shadeColor('#0ffc03', index * -30) : this.shadeColor(baseHexWater, index * -30)
          ),
          stack: index,
          fill: false,
          draw: false,
          borderSkipped: false,
          order: 1,
          barPercentage: 0.8, // Ajuste essa porcentagem conforme necessário
          categoryPercentage: 0.8, // Ajuste essa porcentagem conforme necessário
          maxBarThickness: 40, // Ajuste conforme necessário
        }
        ,
        {
          label: 'Efluentes: ' + unit.unit_name,
          data: unit.consumptions.map(consumption => consumption.valueSewer),
          backgroundColor: unit.consumptions.map(consumption => 
            new Date(consumption.time) > currentDate ? this.shadeColor('#067300', index * -30) : this.shadeColor(baseHexSewer, index * -30)
          ),
          borderColor: unit.consumptions.map(consumption => 
            new Date(consumption.time) > currentDate ? this.shadeColor('#067300', index * -30) : this.shadeColor(baseHexSewer, index * -30)
          ),
          stack: index,
          fill: false,
          draw: false,
          borderSkipped: false,
          order: 1,
          barPercentage: 0.8, // Ajuste essa porcentagem conforme necessário
          categoryPercentage: 0.8, // Ajuste essa porcentagem conforme necessário
          maxBarThickness: 40, // Ajuste conforme necessário
        }
      ]
      )



      return { labels, dataset }
    },
    sumConsumptionsByDate(allConsumptions) {

      const consumptionMap = {};
      let totalWater = 0;
      let totalSewer = 0;

      allConsumptions.forEach(unit => {
        unit.consumptions.forEach(consumption => {
          const date = new Date(consumption.time).toISOString();
          if (!consumptionMap[date]) {
            consumptionMap[date] = { valueWater: 0, valueSewer: 0 };
          }
          consumptionMap[date].valueWater += consumption.valueWater;
          consumptionMap[date].valueSewer += consumption.valueSewer;

          totalWater += consumption.valueWater;
          totalSewer += consumption.valueSewer;
        });
      });

      const results = Object.entries(consumptionMap).map(([time, values]) => ({
        time,
        ...values
      }));

      const averageWater = results.length ? totalWater / results.length : 0;
      const averageSewer = results.length ? totalSewer / results.length : 0;
      const consumptions = {
        summedConsumptions: results,
        averageWater,
        averageSewer
      };

      const labels = consumptions.summedConsumptions.map(consumption => {
        return this.optionPeriod == 'Por hora'? new Date(consumption.time).toLocaleString() : new Date(consumption.time).toLocaleDateString()
      });

      const currentDate = new Date();

      const dataset = [
        {
          label: 'Água',
          data: consumptions.summedConsumptions.map(consumption => +(consumption.valueWater.toFixed(2))), 
          backgroundColor: consumptions.summedConsumptions.map(consumption => 
            new Date(consumption.time) > currentDate ? '#0ffc03' : '#2FA4DD'
          ),
          borderColor: consumptions.summedConsumptions.map(consumption => 
            new Date(consumption.time) > currentDate ? '#0ffc03' : '#2FA4DD'
          ),
          fill: false,
          draw: false,
          borderSkipped: false,
          order: 1
        },
        {
          label: 'Efluentes',
          backgroundColor: consumptions.summedConsumptions.map(consumption => 
            new Date(consumption.time) > currentDate ? '#067300' : '#0A517F'
          ),
          borderColor: consumptions.summedConsumptions.map(consumption => 
            new Date(consumption.time) > currentDate ? '#067300' : '#0A517F'
          ),
          data: consumptions.summedConsumptions.map(consumption =>  +(consumption.valueSewer.toFixed(2))), 
          fill: false,
          draw: false,
          borderSkipped: false,
          // barThickness: 30,
          order: 1
        },
        {
          label: 'Média Água',
          data: Array(labels.length).fill(averageWater), // Cria um array com a média repetida
          type: 'line',
          borderColor: '#0E76BC',
          backgroundColor: '#0E76BC',
          borderWidth: 1,
          fill: false,
          pointRadius: 0, // Remove os pontos da linha
          order: 2,
        },
        // Linha média para Consumo de Esgoto
        {
          label: 'Média Efluentes',
          data: Array(labels.length).fill(averageSewer), // Cria um array com a média repetida
          type: 'line',
          borderColor: '#0a317f',
          backgroundColor: '#0a317f',
          borderWidth: 1,
          fill: false,
          pointRadius: 0, // Remove os pontos da linha
          order: 2,
        },
      ]

      return { labels, dataset }
    },
    async generateAllConsumption(isToPredict = true) {
      this.resume = {}
      this.readings = []
      let modems = []

      const { startDate, endDate } = this.getPeriod()
      if (this.selectedSector.length === 0 && this.selectedUnits.length) {
      
        const endDateFormated = this.optionPeriod == 'Por hora'? endDate : Utils.subtractDate(endDate,1,'day')

        const execFuncs = this.selectedUnits.map(unit_id => ({
          unit_id,
          promise: ConsumptionService.findByUnit({
            start_date: startDate,
            end_date: endDateFormated,
            mode: this.dateOptions.mode,
            unit_measurement: this.unitMeasurement,
            unit_id
          })
        }))

        await Promise.all(execFuncs.map(obj => obj.promise)).then((values) => {
          const datas = values.map((value, index) => ({
            unit_id: execFuncs[index].unit_id,
            unit_name: this.listUnits.find(({ id }) => id === execFuncs[index].unit_id).name,
            ...value.data.data
          }));
          if(this.selectedUnits.length > 1 && this.totalConsumptionUnitsSelected)  this.summedGraphs = [this.sumConsumptionsByDate(datas)]
          if (this.groupingDataGraphic) {

            this.unitsDataGraphic = [this.mountGroupDataChart(datas)]
            
          } else {
            this.unitsDataGraphic = values.map((value, index) => ({
              unit_id: execFuncs[index].unit_id,
              unit_name: this.listUnits.find(({ id }) => id === execFuncs[index].unit_id).name,
              ...this.mountDataChart(value.data.data)
            }));
          }
          
        })
      } else {
        if (!Array.isArray(this.selectedSector)) {
          modems.push(this.selectedSector)
        } else {
          modems = this.selectedSector
        }
        let { permittedStartDate, permittedEndDate, resume, labels } = await ConsumptionFunctions.generateConsumption(
          startDate,
          endDate,
          this.dateOptions.mode,
          modems,
          this.unitMeasurement,
          isToPredict
        )
        this.permittedStartDate = permittedStartDate;
        this.permittedEndDate = permittedEndDate;
        this.totalDates = labels.length;
        this.resume = resume;
      }

    },
    // Filtragem do consumo pelo Gráfico. Onde serão mostrados os consumos no gráfico: 
    //    por 7 dias     -> registros dos últimos 7 dias
    //    por dia        -> registros dos dias de um mês específico
    //    por hora       -> registros por hora de um dia específico
    //    por intervalo  -> registros por hora em intervalo definido
    async consultConsumptionGraphic() {
      this.graphicFlag = true; 
      this.consultTable = false; 
      this.isDataGraphic = false;
      
      if (this.$refs.formConsumption && !this.$refs.formConsumption.validate()) return;

      // Ativa flags que permite fazer a impressão e também flag de carregamento dos dados (vue loading)
      this.isDataTable = false;
      this.unitsDataGraphic = [];
      this.summedGraphs = [];
      this.loadingReadings = true;
      this.validDayPeriodSelected = true;
      this.validHourPeriodSelected = true;
      this.validMonthPeriodSelected = true;

      this.consultDatalLoading.graphicButton = true
      await this.generateAllConsumption()
      this.consultDatalLoading.graphicButton = false

      this.readings = ConsumptionFunctions.createDataset(this.resume)
      this.consultReading = false;
      this.loadingReadings = false;
      this.consultGraph = false;        // Fecha modal de gráfico
      this.isDataGraphic = true;        // Exibe gráfico  
      this.selectsModal = false
    },
    async startPdf() {
      if (this.$refs.formConsumption && !this.$refs.formConsumption.validate()) return;
      try {
        if (this.selectedUnits.length > 1) {
          showInfoSnackBar("Selecione apenas uma unidade para gerar o PDF")
          return
        }
        if (!this.selectedSector.length) {
          showInfoSnackBar("Selecione ao menos um setor para gerar o PDF")
          return
        }
        this.loadButtonPdf = true
        const params = {
          unitId: this.selectedUnits[0],
          modemIds: this.selectedSector.map(({ id_modem }) => id_modem),
          unitMeasurement: this.unitMeasurement,
          ...this.dateOptions
        }
        await ConsumptionApi.generateConsumptionPdf(this.data_abstract_client.name, params)

      } catch (error) {
        console.error(error)
        showErrorSnackBar("Erro ao gerar PDF! Entre em contato com nossa equipe!")
      } finally { this.loadButtonPdf = false }
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    debouncedGetSectors: debounce(function (e) {
      this.onChangeUnit(e);
    }, 1000),
  },

  watch: {
    optionMetric() {
      if (this.optionMetric == true) {
        this.switch1 = "Metros Cúbicos (m³)";
      } else {
        this.switch1 = "Litros (L)";
      }
    },
    dateSelectedEnd() {
      this.dateFormattedSelectedEnd = this.formatDate(this.dateSelectedEnd);
    },
    dateSelectedStart() {
      this.dateFormattedSelectedStart = this.formatDate(this.dateSelectedStart);
    },
    dateSelected() {
      this.dateSelectedFormatted = this.formatDate(this.dateSelected);
    },
  },
  async created() {
    this.selectsLoading.client = true
    const response = await UsersService.findAllClients()
    this.listClients = response.data.data
    this.selectsLoading.client = false
    if (this.listClients.length === 1) {
      this.selectedClient = this.listClients[0]
      this.onChangeClient(this.selectedClient.id)
    }
  },
};