import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { VueJsonp } from 'vue-jsonp'
import store from './store'
import vuetify from './plugins/vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { VueMaskDirective } from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.directive('mask', VueMaskDirective);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyALmdPk58vfHmaOw-tkEe2eUdI9I2TJVoM',
    libraries: 'places', // Adicione outras bibliotecas necessárias
  },
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  VueJsonp,
  render: h => h(App)
}).$mount('#app')
