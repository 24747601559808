<template>
  <ChooseRoute 
    :routes="optionsSettings"
  />
</template>
<script>
import ChooseRoute from "@/components/ChooseRoute/ChooseRoute.vue";
import { RoutesPath } from '@/router/paths.js';
import { SubMenuFeatures } from '@/router/features.js';
export default {
  name: "ChooseSolutions",
  components:{
    ChooseRoute
  },
  data() {
    return {
      optionsSettings: [
        { name: "Soluções", feature: SubMenuFeatures.SOLUTIONS, icon: 'mdi-package-variant-closed', path: RoutesPath.SETTING_SOLUTIONS},
        { name: "Grupos", feature: SubMenuFeatures.GROUPS, icon: 'mdi-ungroup', path: RoutesPath.SETTING_GROUP},
        { name: "Pontos", feature: SubMenuFeatures.POINTS, icon: 'mdi-faucet-variant', path: RoutesPath.SETTING_POINT},
      ],
    }
  },
}
</script>