<template>
    <v-row v-if="has_error && is_created">
        <v-col style="
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;
        ">
            <div
                style="
                    font-size: 20px;
                    font-weight: 500;
                    padding: 20px;
                "
            >Projeto não encontrado</div>
            <div>
                <v-btn @click="() => $router.push('/projects')">Voltar</v-btn>
            </div>
        </v-col>
    </v-row>
    <div v-else-if="!has_error">
        <v-row>
            <v-col>
                <v-card
                    class="mx-auto my-12"
                    style="
                        width: 100%;
                        margin: 0 !important;
                        height: auto;
                        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                    "
                >
                    <v-skeleton-loader
                        v-if="!is_created"
                        type="table-heading, table-heading"
                    ></v-skeleton-loader>
                    <v-row v-if="is_created">
                        <v-col cols="6">
                            <div><span
                                style="
                                    font-weight: 500;
                                    font-size: 20px;
                                " 
                            >Nome do Projeto</span></div>
                            <div><span
                                style="
                                    font-weight: 500;
                                    font-size: 12px;
                                    text-transform: uppercase;
                                "
                            >{{ project.project_name }}</span></div>
                        </v-col>
                        <v-col cols="6">
                            <div><span
                                style="
                                    font-weight: 500;
                                    font-size: 20px;
                                " 
                            >Criado em</span></div>
                            <div><span
                                style="
                                    font-weight: 500;
                                    font-size: 12px;
                                    text-transform: uppercase;
                                "
                            >{{ project.created_at_formatted }}</span></div>
                        </v-col>
                    </v-row>
                    <v-divider v-if="is_created"></v-divider>
                    <v-row v-if="is_created">
                        <v-col cols="6">
                            <div><span
                                style="
                                    font-weight: 500;
                                    font-size: 20px;
                                " 
                            >Nome do Cliente</span></div>
                            <div><span
                                style="
                                    font-weight: 500;
                                    font-size: 12px;
                                    text-transform: uppercase;
                                "
                            >{{ project.lead_name }}</span></div>
                        </v-col>
                        <v-col cols="6">
                            <div><span
                                style="
                                    font-weight: 500;
                                    font-size: 20px;
                                " 
                            >Documento do Cliente</span></div>
                            <div><span
                                style="
                                    font-weight: 500;
                                    font-size: 12px;
                                    text-transform: uppercase;
                                "
                            >{{ project.lead_document }}</span></div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-skeleton-loader
                    v-if="!is_created"
                    type="image"
                ></v-skeleton-loader>
                <span v-if="is_created" v-for="option in options" :key="option.name">
                    <v-btn
                        color="white"
                        id="btn-styles-options"
                        @click="openStep(option.name)"
                        :disabled="!option.completed && !option.isCurrentStep"
                    >
                        <v-card class="text-xs-center ma-3">
                        <v-responsive class="pt-4">
                            <div>
                            <v-icon class="icon-style-options" x-large>
                                {{ option.icon }}
                            </v-icon>
                            </div>
                        </v-responsive>
                        <v-card-actions>
                            <div
                            style="
                                width: 100%;
                                color: #737373;
                                font-size: 1.2rem;
                                text-transform: capitalize;
                            "
                            >
                            {{ option.name_formatted }}
                            </div>
                        </v-card-actions>
                        </v-card>
                    </v-btn>
                </span>
                <v-btn
                    v-if="is_created"
                    color="white"
                    id="btn-styles-options"
                    @click="() => $router.push('/projects')"
                >
                    <v-card class="text-xs-center ma-3">
                    <v-responsive class="pt-4">
                        <div>
                        <v-icon class="icon-style-options" x-large>
                            mdi-arrow-left
                        </v-icon>
                        </div>
                    </v-responsive>
                    <v-card-actions>
                        <div
                        style="
                            width: 100%;
                            color: #737373;
                            font-size: 1.2rem;
                            text-transform: capitalize;
                        "
                        >
                        Voltar
                        </div>
                    </v-card-actions>
                    </v-card>
                </v-btn>
            </v-col>
        </v-row>

        <Prospecting 
            v-if="openProspection" 
            :openModal="openProspection"
            :idLead="project.id_lead"
            :closeFunc="closeFunc"
            :updateLead="true"
        
        />
        <Diagnostic v-if="false" />
    </div>
</template>
  
<script>
import ProjectDetailsScript from "./ProjectDetailsScript.js";
export default ProjectDetailsScript;
</script>
  
<style scoped>
#btn-styles-options {
  margin-left: 25px;
  margin-top: 25px;
  width: 15rem;
  height: 15rem;
  border-radius: 1rem;
  padding: 0px;
  box-shadow: 0 0 0.5em black;
}
.icon-style-options {
  color: #737373 !important;
  font-size: 10rem !important;
}
.v-card:hover {
  background-color: #eeeeee;
}
.v-card {
  width: 15rem;
  height: 15rem;
  margin: 0;
  border-radius: 1rem;
}
</style>