// import User from '@/scripts/Types/User';

const requiredRule = (errorText = 'Campo requerido') => value => value === null || !value ? errorText : true;

export default class Rules {

    static required() {
        return [
            requiredRule(),
        ];
    }

    static string(isRequired = false, min = 1, max = 64) {
        let optionals = [];
        if (isRequired) optionals.push(requiredRule());
        if ((min) && min > 0) optionals.push(value => !(value && value.length < min) || `Mínimo de ${min} caratere(s)`);
        if ((max) && max > 0) optionals.push(value => !(value && value.length > max) || `Máximo de ${max} caratere(s)`);
        return optionals;
    }

    static email(isRequired = false, max = 64) {
        let optionals = [];
        if (isRequired) {
            optionals.push(requiredRule('Email requerido'));
        }
        return [
            ...optionals,
            value => !(value && value.length > max) || `Email no máximo de ${max} caratere(s)`,
            value => !(value && !value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)) || `Digite um email válido`
        ];
    }

    static password(min = 6, max = 32) {
        return [
            requiredRule('Senha requerido'),
            value => !(value && value.length < min) || `Senha no mínimo de ${min} caratere(s)`,
            value => !(value && value.length > max) || `Senha no máximo de ${max} caratere(s)`,
        ];
    }

    static cpf(isRequired = true) {
        let optionals = [];
        if (isRequired) {
            optionals.push(requiredRule('CPF requerido'));
        }
        return [
            ...optionals,
            value => !(value && !value.match(/([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/g)) || `CPF inválido`
        ]
    }

    static cnpj(isRequired = true) {
        let optionals = [];
        if (isRequired) {
            optionals.push(requiredRule('CNPJ requerido'));
        }
        return [
            ...optionals,
            value => !(value && !value.match(/^[0-9]{2}(\.)?[0-9]{3}(\.)?[0-9]{3}\/[0-9]{4}(\-)?[0-9]{2}$/g)) || `CNPJ inválido`
        ]
    }

    static phone(isRequired = false) {
        let optionals = [];
        if (isRequired) {
            optionals.push(requiredRule('Telefone requerido'));
        }
        return [
            ...optionals,
            value => !(value && !value.match(/^(\([0-9]{2}\)|([0-9]{2})) *[0-9]{4,5}\-?[0-9]{4}$/g)) || `Digite um telefone válido com DDD`
        ];
    }

    static cep(isRequired = false) {
        let optionals = [];
        if (isRequired) {
            optionals.push(requiredRule('CEP requerido'));
        }
        return [
            ...optionals,
            value => !(value && !value.match(/^[0-9]{2}\.?[0-9]{3}\-?[0-9]{3}$/g)) || `Digite um CEP válido`
        ];
    }

    // files: FileList
    static photoUser(files) {
        let photo = null;
        if (files.length > 0) photo = files[0];
        return [
            v => !(files.length > 1) || 'Só permitido uma foto',
            v => !(photo && !photo.type.match(/^image\/(jpeg|jpg|png)$/)) || 'Só é permitido nos formatos JPEG, JPG ou PNG',
            v => !(photo && photo.size > 700000) || 'Só é permitido até 700 Kb de tamanho',
        ]
    }

    static number(isRequired = false, min = 1, max = null) {
        let optionals = [];
        if (isRequired) optionals.push(requiredRule('Requerido'));
        if (min !== null && max === null) optionals.push(value => !(value < min) || `É permitido valor maior ou igual a ${min}`);
        if (max !== null && min === null) optionals.push(value => !(value > max) || `É permitido valor menor ou igual a ${max}`);
        if (max !== null && min !== null) optionals.push(value => !(value < min || value > max) || `É permitido valor entre ${min} até ${max}`);
        return optionals;
    }

}

