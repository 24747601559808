import { mapActions, mapGetters } from "vuex";
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import Utils from '@/scripts/Utils';
import Rules from "@/utils/Rules";

import ProjectsService from "@/services/server/ProjectsService.js";
import LeadsService from "@/services/server/LeadsService.js";
import UsersService from "@/services/server/UsersService";


import Prospecting from "@/components/Projects/Prospecting/Prospecting.vue"
import Diagnostic from "@/components/Projects/Diagnostic/Diagnostic.vue"

export default {
	name: "Detalhes do Projeto",

	components: {
        Prospecting,
        Diagnostic
	},

	data: () => ({
		openProspection: false,
		is_loading: true,
        is_created: false,
        has_error: false,
        project: {
            id_lead: null,
            id_doc: null,
        },
        options: [
            {
                icon: 'mdi-magnify',
                name_formatted: 'Prospecção',
                name: 'prospecting',
                completed: false,
                isCurrentStep: true,
            },
            {
                icon: 'mdi-water',
                name_formatted: 'Diagnóstico',
                name: 'diagnostic',
                completed: false,
                isCurrentStep: false,
            },
            {
                icon: 'mdi-check-bold',
                name_formatted: 'Aprovação',
                name: 'approval',
                completed: false,
                isCurrentStep: false,
            },
            // {
            //     icon: 'mdi-file-sign',
            //     name_formatted: 'Fechamento',
            //     name: 'closing',
            //     completed: false,
            //     isCurrentStep: false,
            //     color: "primary",
            //     open: function () {
                    
            //     }
            // },
            // {
            //     icon: 'mdi-file-document',
            //     name_formatted: 'Contratos',
            //     name: 'contracts',
            //     completed: false,
            //     isCurrentStep: false,
            //     color: "primary",
            //     open: function () {
                    
            //     }
            // },
            // {
            //     icon: 'mdi-cube-outline',
            //     name_formatted: 'Materiais',
            //     name: 'materials',
            //     completed: false,
            //     isCurrentStep: false,
            //     color: "primary",
            //     open: function () {
                    
            //     }
            // },
            // {
            //     icon: 'mdi-wrench-cog',
            //     name_formatted: 'Instalação',
            //     name: 'installation',
            //     completed: false,
            //     isCurrentStep: false,
            //     color: "primary",
            //     open: function () {
                    
            //     }
            // },
        ],
	}),

	computed: {
		
	},

	methods: {
		...mapActions([
			"publishChannel",
            "subscribeChannel",
		]),
        openStep(step){
            console.log("-----------",step)
            switch (step) {
                case "prospecting":
                    this.openProspection = true
                    break;
                case "diagnostic":
                    
                    break;
                case "approval":
                    
                    break;
                default:
                    break;
            }
        },
        closeFunc(type){
            console.log("closeFunc",type)
            switch (type) {
                case "prospecting":
                    this.openProspection = false   
                    this.getProject() 
                    break;
                case "diagnostic":
                    
                    break;
                case "approval":
                    
                    break;
                default:
                    break;
            }
        },
        checkId(id_name) {
            return this.project.hasOwnProperty(id_name)
        },
        isCompleted(step_name) {
            let index = this.options.findIndex(
                (option) => {
                    return option.name == step_name
                }
            )
            if (index < 0) return true
            return this.options[index].completed
        },
        async getProject() {
            this.is_loading = true
            let [status, project] = await ProjectsService.getProjectById(this.$route.params.id)
            console.log(project)
            if (!status) this.has_error = true
            else {
                // this.openProspection = true
                let created_at = new Date(project.created_at._seconds * 1000)
                this.project = {
                    ...project,
                    created_at,
                    created_at_formatted: Utils.formatDate(created_at, '<dd>/<MM>/<YYYY>')
                }
                console.log("this.project", this.project)
                if (this.project.steps) {
                    this.project.steps.forEach(
                        ({ name, isCurrentStep, completed, next }) => {
                            let index = this.options.findIndex(
                                (option) => {
                                    return option.name == name
                                }
                            )
                            if (index >= 0) {
                                this.options[index].isCurrentStep = isCurrentStep
                                this.options[index].completed = completed
                            }
                        }
                    )
                }

                console.log("this.options", this.options)
                console.log("this.isCompleted('prospecting')", this.isCompleted('prospecting'))
                console.log("this.isCompleted('diagnostic')", this.isCompleted('diagnostic'))
                // Prospecção
                // this.options[0].open = () => {
                //     this.publishChannel({
                //         channel: 'projects',
                //         event: 'leads:open',
                //         value: [
                //             this.project.id_lead,
                //             this.project.id_doc,
                //             this.isCompleted('prospecting'),
                //         ],
                //     })
                // }

                // // Diagnóstico
                // this.options[1].open = () => {
                //     this.publishChannel({
                //         channel: 'projects',
                //         event: 'diagnostic:open',
                //         value: [
                //             this.project.id_doc,
                //             this.project.id_diagnostic,
                //             this.isCompleted('diagnostic'),
                //         ],
                //     })
                // }
            }
            this.is_loading = false
        }
	},
	
	watch: {
		
	},

	async created() {
        this.subscribeChannel({
            channel: 'projectDetails',
            events: [
                {
                    event: 'project:get',
                    fn: () => {
                        this.getProject()
                    }
                },
            ]
        })
		this.getProject()
        this.is_created = true
    },
};