import { db } from "@/firebase"

const alertsConfig_ref = db.collection("alerts")
const sectorsAlerts_ref = db.collection("sectors_alerts")
const unitsAlerts_ref = db.collection("units_alerts")

const arrayChunkSeparate500 = async (data, chunkSize = 500) => {
    let result = []
    for (let i = 0; i < data.length; i += chunkSize) {
        const chunk = data.slice(i, i + chunkSize);
        result.push(chunk)
    }
    return result
}

const createAlertConfig = async (data) => {
    try {
        const get_data = await alertsConfig_ref.add(data);
        await editAlert(get_data.id, { ...data, id_doc: get_data.id })
        return get_data.id;
    } catch (error) {
        throw new Error("createAlertConfig Fail!" + error)
    }
}
const editAlert = async (idDoc, data) => {
    try {
        await alertsConfig_ref.doc(idDoc).update(data);
        return true;
    } catch (error) {
        throw new Error("editAlert Fail!" + error)
    }
}
const deleteAlertConfig = async (idDoc) => {
    try {
        await alertsConfig_ref.doc(idDoc).delete()
    } catch (e) {
        console.error("deleteAlertConfig", e)
    }
}
const getAlertsConfig = async () => {
    let alerts_config_list = []
    try {
        const get_data = await alertsConfig_ref.where('deletedAt',"==", null).get();
        get_data.forEach((doc) => { alerts_config_list.push({ ...doc.data(), id_doc: doc.id }); });
        return alerts_config_list;
    } catch (error) {
        throw new Error("getAlertsConfig Fail!" + error)
    }
}
const deleteAlertConfigList = async (alertList) => {
    try {
        const listSave = await arrayChunkSeparate500(alertList, 200)
        for (let list of listSave) {
            let batch = db.batch();
            for (let alert of list) {
                let ref = sectorsAlerts_ref.doc(alert.id_doc)
                batch.delete(ref);
            }
            await batch.commit();
        }
        return true
    } catch (error) {
        throw new Error(`deleteAlertConfigList Fail! ${error}`)
    }
}
const deleteUnitsAlertConfigList = async (alertList) => {
    try {
        const listSave = await arrayChunkSeparate500(alertList, 200)
        for (let list of listSave) {
            let batch = db.batch();
            for (let alert of list) {
                let ref = unitsAlerts_ref.doc(alert.id_doc)
                batch.delete(ref);
            }
            await batch.commit();
        }
        return true
    } catch (error) {
        throw new Error(`deleteUnitsAlertConfigList Fail! ${error}`)
    }
}
const updateAlertConfigList = async (alertList) => {
    try {
        const listSave = await arrayChunkSeparate500(alertList, 200)
        for (let list of listSave) {
            let batch = db.batch();
            for (let alert of list) {
                let ref = sectorsAlerts_ref.doc(alert.id)
                batch.update(ref, { ...alert });
            }
            await batch.commit();
        }
        return true
    } catch (error) {
        throw new Error(`updateAlertConfigList Fail! ${error}`)
    }
}
const createUnitsAlertConfigList = async (alertList) => {
    try {
        const listSave = await arrayChunkSeparate500(alertList, 200)
        for (let list of listSave) {
            let batch = db.batch();
            for (let alert of list) {
                let ref = unitsAlerts_ref.doc()
                batch.set(ref, { ...alert });
            }
            await batch.commit();
        }
        return true
    } catch (error) {
        throw new Error(`createUnitsAlertConfigList Fail! ${error}`)
    }
}
const createAlertConfigList = async (alertList) => {
    try {
        const listSave = await arrayChunkSeparate500(alertList, 200)
        for (let list of listSave) {
            let batch = db.batch();
            for (let alert of list) {
                let ref = sectorsAlerts_ref.doc()
                batch.set(ref, { ...alert });
            }
            await batch.commit();
        }
        return true
    } catch (error) {
        throw new Error(`createAlertConfigList Fail! ${error}`)
    }
}
const getSectorsByAlertConfig = async (idAlert) => {
    let sectors_by_alert_list = []
    try {
        const get_data = await sectorsAlerts_ref.where("id_alert", "==", idAlert).get();
        get_data.forEach((doc) => { sectors_by_alert_list.push({ ...doc.data(), id_doc: doc.id }); });
        return sectors_by_alert_list;
    } catch (error) {
        throw new Error("getSectorsByAlertConfig Fail!" + error)
    }
}
const getUnitsByAlertConfig = async (idAlert) => {
    let units_by_alert_list = []
    try {
        const get_data = await unitsAlerts_ref.where("id_alert", "==", idAlert).get();
        get_data.forEach((doc) => { units_by_alert_list.push({ ...doc.data(), id_doc: doc.id }); });
        return units_by_alert_list;
    } catch (error) {
        throw new Error("getUnitsByAlertConfig Fail!" + error)
    }
}

export default {
    editAlert,
    getAlertsConfig,
    createAlertConfig,
    deleteAlertConfig,
    getUnitsByAlertConfig,
    deleteAlertConfigList,
    updateAlertConfigList,
    createAlertConfigList,
    getSectorsByAlertConfig,
    deleteUnitsAlertConfigList,
    createUnitsAlertConfigList
};