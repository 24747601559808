<template>
    <div v-if="show">
        <v-form ref="form" v-model="validForm" lazy-validation>
            <v-col>
                <HeaderCheckList
                    title="Hidrômetros"
                    :click="() => create()"
                />
            </v-col>
            <div v-for="hyd, i in list">
                <CardCheckList
                    :view="() => edit(i)"
                    :remove="() => remove(i)"
                >
                    <template v-slot:content>
                        <div style="
                            padding: 10px;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-around;
                        ">
                            <div>
                                <h3 style="
                                    font-size: 15px;
                                    color: black;
                                    text-align: center;
                                ">{{ hyd.localHidrometro }}</h3>
                            </div>
                            <div>
                                <h4 style="
                                    font-size: 12px;
                                    color: black;
                                    text-align: center;
                                ">{{ hyd.abastecimento }}</h4>
                            </div>
                            <div>
                                <h5 style="
                                    font-size: 12px;
                                    color: black;
                                    text-align: center;
                                ">{{ getTextHydrometerModel(hyd.modeloHidrometro) }}</h5>
                            </div>
                        </div>
                    </template>
                </CardCheckList>
            </div>
            <v-dialog transition="dialog-top-transition" v-model="dialog" v-if="dialog" persistent max-width="500px">
                <v-card>
                    <v-toolbar color="primary" dark> <span class="text-h5">Adicionar Hidrômetro</span></v-toolbar>
                    <v-card-title>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text style="padding-bottom: 0px;">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field 
                                    v-model="hydrometer.localHidrometro" 
                                    outlined 
                                    label="Local" 
                                    type="text"
                                    required :rules="rulesRequired"
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-select 
                                    v-model="hydrometer.abastecimento" 
                                    outlined 
                                    label="Abastecimento"
                                    :items="optionsSupply" 
                                    required 
                                    :rules="rulesRequired"
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-select 
                                    v-model="hydrometer.modeloHidrometro" 
                                    outlined 
                                    label="Modelo Hidrômetro"
                                    :items="optionsHydrometer" 
                                    required 
                                    :rules="rulesRequired"
                                />
                            </v-col>
                        </v-row>
                        <!-- <InputImg v-on:rawFiles="rawFiles = $event"/> -->
                    </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="() => closeDialog()">
                    Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="() => save()"> Salvar </v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
        </v-form>
    </div>
</template>
  
<script>
import HeaderCheckList from "@/components/Projects/Diagnostic/Tabs/CheckList/HeaderCheckList.vue"
import CardCheckList from "@/components/Projects/Diagnostic/Tabs/CheckList/CardCheckList.vue"

import Rules from "@/utils/Rules";
import Utils from '@/scripts/Utils';

const newHydrometer = () => {
    return {
        localHidrometro: "",
        abastecimento: "",
        modeloHidrometro: "",
        imagemHidrometro: "",
    }
}

export default {
    name: "HydrometerList",
    
    components: {
        HeaderCheckList,
        CardCheckList,
    },
    
    props: {
        show: {
            type: Boolean,
            default: true
        },
        list: {
            type: Array,
            default: () => ([]),
        },
        add: {
            type: Function,
            default: (hydrometer, index) => {}
        },
        remove: {
            type: Function,
            default: (index) => {}
        },
    },
    
    data: () => ({
        validForm: true,
        dialog: false,
        index: -1,
        optionsSupply: ["Poço", "Concessionária"],
        optionsHydrometer: [
            { text: "DN 15", value: "dn_15" },
            { text: "DN 20", value: "dn_20" },
            { text: "DN 25", value: "dn_25" },
            { text: "DN 32", value: "dn_32" },
            { text: "DN 40", value: "dn_40" },
            { text: "DN 50", value: "dn_50" },
            { text: "DN 80", value: "dn_80" },
            { text: "DN 110", value: "dn_110" },
        ],
        hydrometer: newHydrometer(),
    }),

    computed: {
        rulesRequired() {
            return Rules.required();
        },
    },

    methods: {
        create() {
            this.index = -1
            this.hydrometer = newHydrometer()
            this.dialog = true
        },
        edit(index) {
            this.index = index
            this.hydrometer = Utils.copyObject(this.list[index])
            this.dialog = true
        },
        save() {
            this.add(Utils.copyObject(this.hydrometer), this.index)
            this.dialog = false
        },
        closeDialog() {
            this.dialog = false
        },
        getTextHydrometerModel(value) {
            return this.optionsHydrometer.find((options) => options.value === value).text
        }
    },

    watch: {
        
    },

};
</script>
  
<style scoped>

</style>