<template>
  <v-dialog v-if="closeAllPages"  persistent no-click-animation v-model="open" max-width="300px" fullscreen>
    <v-card width="100%">
      <v-card-title class="primary lighten white--text">
        Nova Proposta
        <v-spacer></v-spacer>
        <v-btn class="litte white--text" text @click="clearDisplay()">
          <v-icon white>mdi-window-close</v-icon>
        </v-btn>
      </v-card-title>
          <StepsBar 
            :steps="steps" 
            v-on:update:step="positionStep = $event"
            :enableWarn="enableStepWarn"
            :selectStep="selectStep"
            />
            <v-col v-if="closeAllPages">
        
              <v-overlay absolute style="opacity: 0.6" :value="overlay">
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      :size="50"
                    ></v-progress-circular>
                  </v-overlay>
                  <LocationInformation
                    ref="LocationInformation"
                    v-show="nextStep == 0"
                    v-if="closeAllPages"
                    :registration="registration"
                    :idProposal="idProposal"
                    :viewOrEdit="viewOrEdit"
                    :closeProposed="clearDisplay"
                    :validFormProp="validFormPages"
                    @update:overley="overlay = $event"
                  />
                  <CheckList
                    ref="CheckList"
                    v-show="nextStep == 1"
                    v-if="closeAllPages"
                    :registration="registration"
                    :idProposal="idProposal"
                    :viewOrEdit="viewOrEdit"
                    :closeProposed="clearDisplay"
                    :validFormProp="validFormPages"
                    v-on:update:overley="overlay = $event"
                  />
                  <HistoricConsumption
                    ref="HistoricConsumption"
                    v-show="nextStep == 2"
                    v-if="closeAllPages"
                    :registration="registration"
                    :idProposal="idProposal"
                    :viewOrEdit="viewOrEdit"
                    :closeProposed="clearDisplay"
                    :validFormProp="validFormPages"
                    v-on:update:overley="overlay = $event"
                  />
                  <BuildProposed
                    ref="BuildProposed"
                    v-show="nextStep == 3"
                    v-if="closeAllPages"
                    :registration="registration"
                    :idProposal="idProposal"
                    :requestSave="saveBuildProposal"
                    :closeProposed="clearDisplay"
                    :validFormProp="validFormPages"
                    v-on:update:overley="overlay = $event"
                  />
      </v-col>
      <v-divider></v-divider>
      <v-card-text>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeFunc()">
          Cancel
        </v-btn>
        <v-btn color="primary" @click="saveProposal()" >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import NewWaterScanScript from "./NewWaterScanScript";
export default NewWaterScanScript;
</script>
<style>
#buttonProgressRight {
  position: fixed;
  outline: none;
  color: rgb(25, 118, 210);
  margin-right: 16px;
  cursor: pointer;
  padding: 15px;
  background-color: rgb(207, 236, 250);
  border-radius: 10px;
}
#buttonProgressLeft {
  position: fixed;
  outline: none;
  color: rgb(25, 118, 210);
  margin-left: 16px;
  cursor: pointer;
  padding: 15px;
  background-color: rgb(207, 236, 250);
  border-radius: 10px;
}
</style>