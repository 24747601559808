import * as XLSX from 'xlsx-js-style';

export const generateXLSX = async (readings) => {
    // STEP 1: Create a new workbook
    const wb = XLSX.utils.book_new();

    let rows = []

    let whiteLine = [
        { v: " ", t: "s", s: { fill: { fgColor: { rgb: "FFFFFF" } } } },
    ];
    let col_merges = []

    // Linha de cabeçalho
    rows.push([
        { v: readings.unit_name, t: "s", s: { alignment: { horizontal: "center", vertical: "center", wrapText: true }, font: { name: "Roboto", bold: true, sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0086D7" } } } },
        { v: "", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", bold: true, sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0086D7" } } } },
        { v: "", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", bold: true, sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0086D7" } } } },
    ])
    col_merges.push({ s: { r: rows.length-1, c: 0 }, e: { r: rows.length-1, c: 2 } })
    rows.push(whiteLine)
    col_merges.push({ s: { r: rows.length-1, c: 0 }, e: { r: rows.length-1, c: 2 } })

    let last_sector_index = readings.sectors.length - 1;
    readings.sectors.forEach(
        (sector, index_sector) => {

            let sector_name = sector[0]
            rows.push([
                { v: sector_name, t: "s", s: { alignment: { horizontal: "center", vertical: "center", wrapText: true }, font: { name: "Roboto", bold: true, sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0086D7" } } } },
                { v: "", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", bold: true, sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0086D7" } } } },
                { v: "", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", bold: true, sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0086D7" } } } },
            ])
            col_merges.push({ s: { r: rows.length-1, c: 0 }, e: { r: rows.length-1, c: 2 } })
            rows.push([
                { v: "Data", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
                { v: "Litros", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "056AAE" } } } },
                { v: "M³", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
            ])

            let total = sector.length
            if (total > 2) {
                sector.slice(1, total-1).forEach(
                    (columns, index) => {
                        let row = []
                        columns.forEach(
                            (data) => {
                                row.push({
                                    s:  index % 2 == 0 ? 
                                            { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "FFFFFF" } } } :
                                            { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "F7F7F7" } } }
                                        ,
                                    v: data
                                })
                            }
                        )
                        rows.push(row)
                    }
                )
            }

            let last_line = sector[total-1]
            rows.push([
                { v: last_line[0], s: { alignment: { horizontal: "center" }, font: { name: "Roboto", bold: true, sz: 12, color: { rgb: "000000" } }, fill: { fgColor: { rgb: "FFFFFF" } } }, },
                { v: last_line[1], s: { alignment: { horizontal: "center" }, font: { name: "Roboto", bold: true, sz: 12, color: { rgb: "000000" } }, fill: { fgColor: { rgb: "FFFFFF" } } }, },
                { v: last_line[2], s: { alignment: { horizontal: "center" }, font: { name: "Roboto", bold: true, sz: 12, color: { rgb: "000000" } }, fill: { fgColor: { rgb: "FFFFFF" } } }, },
            ])

            if (index_sector != last_sector_index) {
                rows.push(whiteLine)
                col_merges.push({ s: { r: rows.length-1, c: 0 }, e: { r: rows.length-1, c: 2 } })
            }

        }
    )

    // // STEP 3: Create worksheet with rows; Add worksheet to workbook
    const ws = XLSX.utils.aoa_to_sheet(rows);
    var wscols = [
        { wch: 20 },
        { wch: 20 },
        { wch: 20 },
    ];
    ws['!cols'] = wscols;
    XLSX.utils.book_append_sheet(wb, ws, "Historico");

    
    wb.Sheets["Historico"]["!merges"] = col_merges

    // STEP 4: Write Excel file to browser
    XLSX.writeFile(wb, `historico-${readings.unit_name.toLowerCase()}.xlsx`);

}