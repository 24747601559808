import { mapActions } from "vuex";
import Utils from '@/scripts/Utils';
import FormReport from "@/components/Forms/Report/Report.vue";
import SkeletonComponent from "@/components/Skeleton/Skeleton.vue";
import AlertsService from "../../../services/firebase/Alerts/AlertsService";
import { showErrorSnackBar, showSuccessSnackBar, showInfoSnackBar } from "@/utils/SnackBartHelper"
import UnitsService from "@/services/server/UnitsService";
import UsersService from "@/services/server/UsersService.js";
import axios from "axios";
export default {
  components: {
    FormReport,
    SkeletonComponent,
  },
  name: "Alerts",
  data: () => ({
    main_headers: [
      {
        text: 'Cliente',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      { text: 'Qntd.', value: 'amount', align: 'center' },
      { text: 'Bateria Fraca', value: 'low_battery', align: 'center' },
      { text: 'Falta de Sinal', value: 'lack_of_signal', align: 'center' },
      { text: 'Sem variação', value: 'no_variation', align: 'center' },
      { text: 'Sem decodificação', value: 'no_decodification', align: 'center' },
      { text: 'Ações', value: 'actions', sortable: false },
      { text: '', value: 'data-table-expand' },
    ],
    secondary_headers: [
      {
        text: 'Modem',
        align: 'start',
        value: 'name'
      },
      { text: 'Data do Alerta', value: 'date_of_occurrence', align: 'center' },
      { text: 'Ações', value: 'actions', sortable: false },
      { text: '', value: 'data-table-expand' },
    ],
    summary: {
      amount: 0,
      lack_of_signal: 0,
      low_battery: 0,
      no_variation: 0,
      no_decodification: 0,
    },
    details_dialog: false,
    solution_dialog: false,
    modems_solutiion: [],
    show_expand: true,
    last_type_selected: null,
    alerts_data: [],
    startDatePicker: [Utils.formatDateDayJs(Utils.subtractDate(new Date(),1,"month"), "YYYY-MM-DD"),
      Utils.formatDateDayJs(new Date(), "YYYY-MM-DD")],
    loading: {
      main_table: false,
      main_button_check: false,
      secondary_table: false,
      secondary_button_check: false,
      pdf_index_button: '',
      skeleton: false,
    },
    idCurrentAlert: null,
    startDateDialog: false,
    maxStartDate: '',
    data_table: [],
    data_table_expand: [],
    expanded: [],
    searchText: '',
    columnToSearch: "Setor",
    columnsToChoose: ["Setor"],
    alert_action_type: null,

  }),
  methods: {
    ...mapActions(["getClientsBy", "getUnitsBy"]),

    async catch_view(action, func, line, status) {
      this.alert_action_type = action
      func(line, status)
    },
    async catch_id(data_id, onExpand = false) {
      if (!onExpand) this.summary = {
        amount: 0,
        exceeded_limit: 0
      }
      const id = data_id.id
      if (!onExpand) {
        this.data_table = []
        this.last_serach_alerts = data_id
        this.last_type_selected = data_id.type
      } else {
        this.data_table_expand = []
      }
      this.show_expand = true
      let alerts = []
      let alertsListTable = []

      try {
      switch (data_id.type) {
        case "franchisee":
          this.unit_id = null
          this.client_id = null
          this.franchisee_id = id;
          if (!onExpand) this.setHeaders("client")
          this.alerts_data = await AlertsService.getAlertsPayloadTelemetryAndEntity("id_franchisee", this.franchisee_id, this.formatDateTimestamp())
          alertsListTable = await this.countAlerts(this.alerts_data, "id_client")
          await this.getClientsName(this.franchisee_id, alertsListTable)
          await this.sumValuesTable(alertsListTable)
          if (!onExpand) await this.summarySum(alertsListTable)
          break;
        case "client":
          this.client_id = id;
          if (!onExpand) this.setHeaders("unit")
          alerts = this.alerts_data.filter((alert) => alert.id_client === this.client_id)
          alertsListTable = await this.countAlerts(alerts, "id_unit")
          await this.getUnitsName(this.client_id, alertsListTable)
          await this.sumValuesTable(alertsListTable)
          if (!onExpand) await this.summarySum(alertsListTable)
          break;
        case "unit":
          this.setHeaders("sector")
          this.show_expand = false
          this.unit_id = id;
          alerts = this.alerts_data.filter((alert) => alert.id_unit === this.unit_id)
          alertsListTable = await this.alterAlertList(alerts)
          await this.sumValuesTable(alertsListTable)
          await this.addedPropertySectorsName(alertsListTable)
          break;
        default:
          alert("Tipo não identificado entre em contato com o suporte!")
          break;
      }

      } catch {
        alert("Erro não identificado entre em contato com o suporte!")
      }
      if (alertsListTable.length === 0) showErrorSnackBar("Não existe alertas para o item selecionado!")
      !onExpand ? this.data_table = alertsListTable : this.data_table_expand = alertsListTable
    },

    setHeaders(action) {
      const secondary_franchisee = [
        {
          text: 'Unidade',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Qntd.', value: 'amount', align: 'center' },
        { text: 'Bateria Fraca', value: 'low_battery', align: 'center' },
        { text: 'Falta de Sinal', value: 'lack_of_signal', align: 'center' },
        { text: 'Sem variação', value: 'no_variation', align: 'center' },
        { text: 'Sem decodificação', value: 'no_decodification', align: 'center' },
        { text: 'Ações', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' },
      ]
      const secondary_other = [
        {
          text: 'Modem',
          align: 'start',
          value: 'name'
        },
        { text: 'Tipo', value: 'type', align: 'center' },
        { text: 'Data do Alerta', value: 'date_of_occurrence', align: 'center' },
        { text: 'Ações', value: 'actions', sortable: false },
      ]
      const main_headers = [{
        text: 'Cliente',
        align: 'start',
        sortable: false,
        value: 'name'
      },
      { text: 'Qntd.', value: 'amount', align: 'center' },
      { text: 'Bateria Fraca', value: 'low_battery', align: 'center' },
      { text: 'Falta de Sinal', value: 'lack_of_signal', align: 'center' },
      { text: 'Sem variação', value: 'no_variation', align: 'center' },
      { text: 'Sem decodificação', value: 'no_decodification', align: 'center' },
      { text: 'Ações', value: 'actions', sortable: false },
      { text: '', value: 'data-table-expand' }
      ]
      switch (action) {
        case 'client':
          this.main_headers = main_headers
          this.secondary_headers = secondary_franchisee
          break;
        case 'unit':
          this.main_headers = main_headers
          this.main_headers['0'].text = 'Unidade'
          this.secondary_headers = secondary_other
          break;
        case 'sector':
          this.main_headers = secondary_other
          break;
      }
    },
    async onExpand({ item, value }) {
      let type = null
      switch (this.last_type_selected) {
        case "franchisee":
          type = "client"
          await this.catch_id({ id: item.id, type }, true)
          break;
        case "client":
          type = "unit"
          this.data_table_expand = await this.alterAlertList(item.alerts)
          break;
        case "unit":
          this.data_table_expand = await this.alterAlertList(item.alerts)
          break;
      }

    },
    async summarySum(data) {
      this.summary = {
        amount: 0,
        lack_of_signal: 0,
        low_battery: 0,
        no_variation: 0,
        no_decodification: 0,
      }
      data.map((line) => {
        this.summary.amount += line.amount
        this.summary.lack_of_signal += line.lack_of_signal
        this.summary.low_battery += line.low_battery
        this.summary.no_variation += line.no_variation
        this.summary.no_decodification += line.no_decodification
      })
    },
    async alterAlertList(alertsGroup) {
      return alertsGroup.map((alert) => {
        let type = ""
        switch (alert.type) {
          case "lack_of_signal":
            type = "Falta de Sinal"
            break;
          case "low_battery":
            type = "Bateria Fraca"
            break;
          case "no_variation":
            type = "Sem Variação"
            break;
          case "no_decodification":
            type = "Sem Decodificação"
            break;
          default:
            break;
        }
        const date_of_occurrence = Utils.formatDateDayJs(new Date(alert.date_of_occurrence.seconds * 1000), 'DD/MMYYYY')
        let last_signal_date = ""
        let diff_dates = ""
        if (alert.last_signal_date) {
          diff_dates = alert.diff_dates
          last_signal_date = Utils.formatDateDayJs(new Date(alert.last_signal_date.seconds * 1000), 'DD/MM/YYYY')
        }
        return {
          ...alert,
          type,
          name: alert.modem_id,
          date_of_occurrence,
          date_of_occurrence_origin: alert.date_of_occurrence,
          last_signal_date,
          diff_dates
        }
      })
    },
    async getClientsName(franchiseeId, data) {
      const response = await UsersService.findClientsByFranchisee(franchiseeId)
      const clientsByFranchisee = response.data.data
      data.map(({ name }, i) => {
        let client_data = clientsByFranchisee.filter(({ id }) => name === id)
        data[i].id = data[i].name
        data[i].name = client_data[0].name
      })
    },
    async getUnitsName(clientId, data) {
      const response = await UnitsService.findAllByClient(clientId)
      const unitsByClient = response.data.data
      data.map(({ name }, i) => {
        let unit_data = unitsByClient.filter(({ id }) => name === id)
        if (unit_data.length > 0) {
          data[i].id = data[i].name
          data[i].name = unit_data[0].name
        }
      })
    },
    async addedPropertySectorsName(data) {
      data.forEach(({ modem_id }, i) => {
        data[i].name = modem_id
      })
    },
    async sumValuesTable(data) {

      if (data[0] && data[0].alerts) {
        data.map((line, index) => {

          let lack_of_signal = 0
          let low_battery = 0
          let no_variation = 0
          let no_decodification = 0

          line.alerts.map((alert) => {
            switch (alert.type) {
              case "lack_of_signal":
                lack_of_signal += 1
                break;
              case "low_battery":
                low_battery += 1
                break;
              case "no_variation":
                no_variation += 1
                break;
              case "no_decodification":
                no_decodification += 1
                break;

              default:
                break;
            }

            data[index] = {
              ...data[index],
              lack_of_signal: lack_of_signal,
              low_battery: low_battery,
              no_variation: no_variation,
              no_decodification: no_decodification,
              index
            }

          })
        })

      } else {
        this.summary = {
          amount: 0,
          lack_of_signal: 0,
          low_battery: 0,
          no_variation: 0,
          no_decodification: 0,
        }
        data.map((alert) => {
          switch (alert.type) {
            case "Falta de Sinal":
              this.summary.lack_of_signal += 1
              break;
            case "Bateria Fraca":
              this.summary.low_battery += 1
              break;
            case "Sem Variação":
              this.summary.no_variation += 1
              break;
            case "Sem Decodificação":
              this.summary.no_decodification += 1
              break;

            default:
              break;
          }

          this.summary.amount += 1

        })
      }
    },
    async countAlerts(alerts, entity) {
      let list = []
      if (entity) {
        alerts.map(async (alert) => {
          let resultSearchName = list.map(i => {
            return i.name === alert[entity]
          }).find(resultMap => resultMap === true)
          if (!resultSearchName) {
            list.push({
              name: alert[entity],
              amount: 1,
              has_urgency: false,
              alerts: [alert]
            })
          }
          else {
            list.map((n) => {
              if (n.name === alert[entity]) {
                n.alerts = [...n.alerts, alert]
                n.amount += 1
              }
            })
          }
        })
        return list
      }
      return []
    },
    findGenerateType(type = null, item) {
      switch (this.last_serach_alerts.type) {
        case "franchisee":
          return type ? "id_unit" : "id_client"
        case "client":
          return type ? "id" : "id_unit"
        case "unit":
          if (item.amount) return "id_unit"
          return "id"
        default:
          console.error("Find Generate Type error!")
      }
    },
    async generatePdf(item, status = null) {
      try {
        let data = {}
        data.id = item.id
        data.type = this.findGenerateType(status, item)
        if (this.alert_action_type === "Detalhes" && status != 'general') {
          let units = await this.getUnitsBy({
            by: "cliente",
            id: this.client_id,
          });
          let unit_data = units.filter(({ id }) => item.unit_id === id)
          if (unit_data.length > 0) {
            item.unit_name = unit_data[0].data.name
          }

          this.data_alert_for_dialog = item
          this.details_dialog = true
        } else {

          await this.checkAlert(item, data)
        }

      } catch (e) {
        console.error(e)
        showErrorSnackBar("Erro ao arquivar alerta!")
      }

    },
    async checkAlert(alert, data) {
      let ids = []
      if (!alert.alerts) {
        if (alert.entity === "client") {
          data.type = "null"
          alert.alerts = this.alerts_data.filter(({ client_id }) => client_id === data.id)
          alert.alerts.map(i => {
            ids.push(i.id)
          })
          await AlertsService.updateAlerts(ids)
          this.data_table = []
          this.alerts_data = this.alerts_data.filter(({ client_id }) => client_id != data.id)

        } else if (alert.entity === "unit") {
          data.type = "unit_id"
          alert.alerts = this.alerts_data.filter(({ unit_id }) => unit_id === data.id)

          let units = await this.getUnitsBy({
            by: "cliente",
            id: this.client_id,
          });
          let unit_data = units.filter(({ id }) => alert.id === id)
          if (unit_data.length > 0) {
            alert.name = unit_data[0].data.name
          }
        }
      }

      switch (data.type) {
        case "client_id":
          alert.alerts.map(i => {
            ids.push(i.id)
          })
          await AlertsService.updateAlerts(ids)
          this.data_table = this.data_table.filter(({ name }) => {
            return name != alert.name
          })
          this.alerts_data = this.alerts_data.filter(({ client_id }) => client_id != data.id)
          break;
        case "unit_id":
          alert.alerts.map(i => {
            ids.push(i.id)
          })
          await AlertsService.updateAlerts(ids)
          if (this.last_serach_alerts.type === "franchisee") {
            this.data_table_expand = this.data_table_expand.filter(({ name }) => {
              return name != alert.name
            })
          } else if (this.last_serach_alerts.type === "unit") {
            this.data_table = this.data_table.filter(({ unit_id }) => {
              return unit_id != alert.id
            })
          } else {
            this.data_table = this.data_table.filter(({ name }) => {
              return name != alert.name
            })
          }
          this.alerts_data = this.alerts_data.filter(({ unit_id }) => unit_id != data.id)
          break;
        case "id":

          await AlertsService.updateAlerts([data.id])
          this.data_table = this.data_table.filter(({ id }) => {
            return id != alert.id
          })
          this.data_table_expand = this.data_table_expand.filter(({ id }) => {
            return id != alert.id
          })
          this.alerts_data = this.alerts_data.filter(({ id }) => id != data.id)
          break;
        default:
          console.error("type error!")
      }
      showSuccessSnackBar("Alerta arquivado!")
    },
    async getAlertsPerData(selectDate) {

      this.startDatePicker = selectDate
      if (this.last_serach_alerts) {
        if (this.last_serach_alerts.type === "franchisee") await this.catch_id(this.last_serach_alerts, false)
        else {
          this.alerts_data = await AlertsService.getAlertsPayloadTelemetryAndEntity("franchisee_id", this.franchisee_id, this.formatDateTimestamp())
          await this.catch_id(this.last_serach_alerts, false)
        }

      }
    },
    formatDateTimestamp() {
      let date = this.startDatePicker.map(date => {
        return (new Date(date).getTime())
      })
      if (date[0] > date[1]) {
        date = [
          date[1],
          date[0]
        ]
      }
      date = [
        new Date(date[0] + 10800000), // 00:00
        new Date(date[1] + 86400000 + 10799999), // 23:59:59
      ]
      this.startDatePicker
      return date
    },
    async generatedAlertSolution() {
      this.modems_solutiion = []
      this.solution_dialog = true
      this.loading.skeleton = true
      let alertList = this.data_table
      let generateSolution = false
      alertList.forEach((alert) => {
        const date = new Date(alert.date_of_occurrence_origin.seconds * 1000)
        const difDate = new Date() - date
        const difDateInDays = parseInt(difDate / (1000 * 60 * 60 * 24))
        if (difDateInDays <= 7) generateSolution = true
      })
      try {
        if (generateSolution) {
          alertList = await this.countAlerts(alertList, "modem_id")
          for (let key in alertList) {
            await axios.post(
              "https://us-central1-centralfoz-homolog.cloudfunctions.net/api/functions/modems/alert-solution",
              { "modem_id": alertList[key].name, "env": "homolog" })
              .then((res) => {
                if (res.data) {
                  if (res.data.response.length > 0) {
                    alertList[key].solutions = res.data.response.map(({ solutions }) => solutions[0])[0]
                    alertList[key].problem = res.data.response.map(({ problem }) => problem)
                    alertList[key].has_urgency = res.data.response.map(({ has_urgency }) => has_urgency)[0]
                  }
                }
              })
          }
          this.modems_solutiion = alertList
          this.loading.skeleton = false
        } else {
          this.solution_dialog = false
          showInfoSnackBar("Não existem alertas gerados dentro do período dos últimos 7 dias!")

        }
      } catch (err) {
        console.error(err)
      }
    },
    isActiveSolutionButton() {
      return this.unit_id && this.summary.amount
    }
  },
  computed: {
    isActiveDetails() {
      return this.show_expand ?
        [{ title: "Arquivar" }] :
        [{ title: "Arquivar" }, { title: "Detalhes" }]
    },


  },
};