import axios from "axios";
import { showErrorSnackBar, showInfoSnackBar } from "@/utils/SnackBartHelper";
import Utils from "@/scripts/Utils";
export var HOST =Utils.getEnv() == 'local' ? 'http://localhost:3000/api/pdf/v1/' : "https://pdf-generator-cu622gxaua-uc.a.run.app/api/pdf/v1/"

const apiService = axios.create({
    baseURL: HOST,
    timeout: 5 * 60000,
});
apiService.defaults.headers.common['Authorization'] = "123123";

apiService.interceptors.request.use( async function (config) {
    if (config.method && config.method === "post") await apiService.options()
    return config;
}, function (error) {
    return Promise.reject(error);
});

apiService.interceptors.response.use((response) => response, (error) => {
    console.error("Response error", error);
    if (!error.response || error.response.status == 500)
        showErrorSnackBar("Ocorreu um erro não identificado, entre em contato com nossa equipe de suporte.")
    else
        showInfoSnackBar(error.response.data.message)
    throw error;
});

export default apiService