import apiService from "./BaseApi";
import { auth } from "@/firebase"
const ENDPOINT_CLIENT_PATH = "users/client/findAll";
const ENDPOINT_FRANCHISEE_PATH = "users/franchisee/findAll";
const ENDPOINT_ClIENTBYFRANCHISEE_PATH = "/users/clients/byFranchisee";
const ENDPOINT_PERMISSIONS_PATH = "users/permissions/findAll";
import store from '@/store/index'
const UsersService = {
    findAllFranchisee: async () => apiService.post(ENDPOINT_FRANCHISEE_PATH, { currentUserId: store.state.currentUser?.uid }),
    findAllClients: async () => apiService.post(ENDPOINT_CLIENT_PATH, { currentUserId: store.state.currentUser?.uid }),
    findClientsByFranchisee: async (idFranchisee) => apiService.post(ENDPOINT_ClIENTBYFRANCHISEE_PATH, { currentUserId: store.state.currentUser?.uid, idFranchisee }),
    findAllPermissions: async () => apiService.post(ENDPOINT_PERMISSIONS_PATH, { currentUserId: auth.currentUser.uid }),
};

export default UsersService;
