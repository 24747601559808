<template>
  <v-container fluid>

    <v-dialog transition="dialog-bottom-transition" max-width="800" v-if="modalAlert" v-model="modalAlert">
      <template v-slot:default>
        <v-card>
          <v-card-title class="primary lighten white--text">{{ modalTitle }}
            <v-spacer></v-spacer>
            <v-btn text class="litte white--text" @click="closeAlertModal()">
              <v-icon white>mdi-window-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-tabs v-model="tabItem">
              <v-tab v-for="selectedTab in tabItems" :key="selectedTab">
                {{ selectedTab }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabItem">
              <v-tab-item key="1">
                <v-form ref="form" v-model="validForm" lazy-validation>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field label="Nome do Alerta" v-model="editedItem.name" placeholder="Nome"
                        :rules="rulesRequired"></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-select label="Tipo do Alerta" v-model="editedItem.alerType" :items="alertTypes" type="text"
                        :rules="rulesRequired" />
                    </v-col>
                  </v-row>
                  <v-row>

                    <v-col v-if="editedItem.alerType == 'goal'" cols="12" style="
                                background: #1976D205;
                                border-radius: 15px;
                                border: 1px solid #ccc!important;
                            ">
                      <h2 style="text-align: center;padding-bottom: 10px"> Parametros Meta</h2>
                      <v-divider />
                      <v-row>
                        <v-col cols="12">
                          <v-radio-group outlined row label="Avisar antes de atingir a meta?"
                            v-model="editedItem.notificationBeforeGoal">
                            <v-radio :key="1" label="Sim" :value="true"></v-radio>
                            <v-radio :key="2" label="Não" :value="false"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col v-if="editedItem.notificationBeforeGoal" cols="12">
                          <v-radio-group outlined row
                            label="Avisar apenas quando a previsão de consumo ultrapassar a meta"
                            v-model="editedItem.notificationPointWhenForecastExceedingTheGoal">
                            <v-radio :key="1" label="Sim" :value="true"></v-radio>
                            <v-radio :key="2" label="Não" :value="false"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col v-if="editedItem.notificationBeforeGoal" cols="12">
                          <v-data-table :headers="headersGoalParam" :items="editedItem.pointsList" sort-by="calories"
                            class="elevation-1">
                            <template v-slot:top>
                              <v-toolbar flat>
                                <v-toolbar-title>Pontos de Avisos</v-toolbar-title>
                                <v-divider class="mx-4" inset vertical></v-divider>
                                <v-tooltip right>

                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                                      mdi-information-outline
                                    </v-icon>
                                  </template>
                                  <span>
                                    Quando o consumo atingir X % da meta da unidade,<br> o sistema gera um alerta
                                    avisando que chegou no ponto de
                                    aviso.<br>
                                    Exemplo:<br>
                                    Meta da unidade 100 Litros. Ponto de Aviso 50%. <br>
                                    Quando o consumo da unidade chegar em 50% da meta ( 50 Litros), a plataforma vai
                                    emitir um aviso.<br>
                                    Nesse aviso vai conter a media de consumo diario, e a estimativa de consumo até o
                                    final do mes<br>
                                  </span>
                                </v-tooltip>
                                <v-spacer></v-spacer>
                                <v-dialog v-model="dialogGoalList" max-width="500px">
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                      Novo Ponto
                                    </v-btn>
                                  </template>
                                  <v-card>
                                    <v-card-title>
                                      <span class="text-h5">Adicionar %</span>
                                    </v-card-title>

                                    <v-card-text>
                                      <v-container>
                                        <v-row>
                                          <v-col cols="12" sm="12" md="12">
                                            <v-slider style="margin-top: 20px;" v-model="editedPointsList.point"
                                              :label="`Ponto de Aviso ${editedPointsList.point}%`" thumb-label ticks
                                              :rules="rulesRequired" min="1" max="100"></v-slider>
                                          </v-col>
                                          <v-col cols="12" sm="12" md="12">
                                            <v-textarea v-model="editedPointsList.message"
                                              label="Mensagem Personalizada" />
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                    </v-card-text>

                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="blue darken-1" text @click="dialogGoalList = !dialogGoalList">
                                        Cancelar
                                      </v-btn>
                                      <v-btn color="blue darken-1" text @click="addPointList">
                                        Salvar
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>

                              </v-toolbar>
                            </template>
                            <template v-slot:item.actions="{ item }">
                              <v-icon small @click="deletePointListItem(item)">
                                mdi-delete
                              </v-icon>
                            </template>
                          </v-data-table>
                        </v-col>

                      </v-row>

                    </v-col>
                    <v-col v-if="editedItem.alerType == 'comsumption'" cols="6" style="
                                background: #1976D205;
                                border-radius: 15px;
                                border: 1px solid #ccc!important;
                            ">
                      <h2 style="text-align: center;padding-bottom: 10px"> Parametros do Consumo</h2>
                      <v-divider />
                      <v-row v-if="editedItem.alerType">

                        <v-col cols="12">
                          <v-select label="Consumo Base" v-model="editedItem.consumptionType"
                            :items="advancedConfigsTypes" type="text" :rules="rulesRequired" />
                        </v-col>
                        <v-col v-if="editedItem.consumptionType == 'manual'" cols="12">
                          <v-text-field background-color="white" v-model="editedItem.consumptionLimit" outlined
                            label="Consumo Total" suffix="Litros" :rules="rulesRequired" />
                        </v-col>
                        <v-col v-if="editedItem.consumptionType == 'movingAverage'" cols="12">
                          <v-slider style="margin-top: 20px;" v-model="editedItem.consumptionLimit"
                            :label="`dos últimos ${editedItem.consumptionLimit} dias`" thumb-label ticks
                            :rules="rulesRequired" min="1" max="30"></v-slider>
                        </v-col>
                        <v-col v-if="editedItem.consumptionType == 'averageBefore'" cols="12">
                          <v-slider style="margin-top: 20px;" v-model="editedItem.consumptionLimit"
                            :label="`${editedItem.consumptionLimit} %`" thumb-label ticks :rules="rulesRequired"
                            max="200"></v-slider>
                        </v-col>


                        <v-col cols="12">
                          <v-radio-group outlined row label="Acumulativo no Período?" v-model="editedItem.cumulative">
                            <v-radio :key="1" label="Sim" :value="true"></v-radio>
                            <v-radio :key="2" label="Não" :value="false"></v-radio>
                          </v-radio-group>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <v-text-field label="Período do Alerta" prepend-icon="mdi-calendar" disabled></v-text-field>


                        </v-col>
                      </v-row>

                    </v-col>
                    <v-col v-if="editedItem.alerType == 'comsumption'" cols="6" style="
                                background: #1976D205;
                                border-radius: 15px;
                                border: 1px solid #ccc!important;
                            ">
                      <h2 style="text-align: center;padding-bottom: 10px">Período</h2>
                      <v-divider />
                      <v-row>
                        <v-col cols="12">
                          <v-select label="Período" v-model="editedItem.periodType" :items="periodTypes" type="text"
                            :rules="rulesRequired" />
                        </v-col>
                        <v-col cols="6">
                          <v-menu ref="menu" v-model="menuStart" :close-on-content-click="false" :nudge-right="40"
                            :return-value.sync="editedItem.startHour" transition="scale-transition" offset-y
                            max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="editedItem.startHour" label="Hora Inicial"
                                prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"
                                :rules="rulesRequired" />
                            </template>
                            <v-time-picker format="24hr" v-if="menuStart" v-model="editedItem.startHour" full-width
                              @click:minute="$refs.menu.save(editedItem.startHour)" />
                          </v-menu>
                        </v-col>
                        <v-col cols="6">
                          <v-menu ref="menu2" v-model="menuEnd" :close-on-content-click="false" :nudge-right="40"
                            :return-value.sync="editedItem.endHour" transition="scale-transition" offset-y
                            max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field v-model="editedItem.endHour" label="Hora Final"
                                prepend-icon="mdi-clock-time-four-outline" readonly v-bind="attrs" v-on="on"
                                :rules="rulesRequired"></v-text-field>
                            </template>
                            <v-time-picker format="24hr" v-if="menuEnd" v-model="editedItem.endHour" full-width
                              @click:minute="$refs.menu2.save(editedItem.endHour)"></v-time-picker>
                          </v-menu>
                        </v-col>
                        <v-col v-if="editedItem.periodType === 'week'" cols="6">
                          <v-select label="Dia da Semana Início" @change="clearEndPeriod"
                            v-model="editedItem.startPeriod" :items="week" type="text" :rules="rulesRequired" />
                        </v-col>
                        <v-col v-if="editedItem.periodType === 'week'" cols="6">
                          <v-select label="Dia da Semana Final" @click="endSelectDate" v-model="editedItem.endPeriod"
                            :items="endWeek" type="text" :rules="rulesRequired" />
                        </v-col>
                        <v-col v-if="editedItem.periodType === 'month'" cols="6">
                          <v-select label="Dia do Mes Início" @change="clearEndPeriod" v-model="editedItem.startPeriod"
                            :items="month" type="text" :rules="rulesRequired" />
                        </v-col>
                        <v-col v-if="editedItem.periodType === 'month'" cols="6">
                          <v-select label="Dia do Mes Final" v-model="editedItem.endPeriod" @click="endSelectDate"
                            :items="endMonth" type="text" :rules="rulesRequired" />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col v-if="editedItem.alerType == 'comsumption'" cols="12" style="
                                background: #1976D205;
                                border-radius: 15px;
                                border: 1px solid #ccc!important;
                            ">
                      <h2 style="text-align: center;padding-bottom: 10px">Vincular Alerta a Setores</h2>
                      <v-divider />
                      <v-autocomplete :loading="loading.sectorList" multiple chips required v-model="sectorsAttachAlert"
                        :items="sectorsData" background-color="white" label="Setores" item-value="id_doc"
                        item-text="name">
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index <= 3">
                            <span>{{ item.name }}</span>
                          </v-chip>
                          <span v-if="index === 4" class="grey--text text-caption">
                            (+{{ sectorsAttachAlert.length - 4 }} setores)
                          </span>
                        </template>
                        <template v-slot:append-outer>
                          <v-slide-x-reverse-transition mode="out-in">
                            <v-icon :disabled="loading.sectorList" :key="`icon-${deleteOrAddAll}`"
                              :color="deleteOrAddAll === 'delete' ? 'error' : 'success'" @click="deleteOrAdd"
                              v-text="deleteOrAddAll === 'delete' ? 'mdi-delete-sweep-outline' : 'mdi-playlist-plus'"></v-icon>
                          </v-slide-x-reverse-transition>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col v-if="editedItem.alerType == 'goal'" cols="12" style="
                                background: #1976D205;
                                border-radius: 15px;
                                border: 1px solid #ccc!important;
                            ">
                      <h2 style="text-align: center;padding-bottom: 10px">Vincular Alerta a Unidades</h2>
                      <v-divider />
                      <v-autocomplete :loading="loading.unitsList" multiple chips required v-model="unitsAttachAlert"
                        :items="unitsData" background-color="white" label="Unidades" item-value="id" item-text="name">
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index <= 3">
                            <span>{{ item.name }}</span>
                          </v-chip>
                          <span v-if="index === 4" class="grey--text text-caption">
                            (+{{ unitsAttachAlert.length - 4 }} unidades)
                          </span>
                        </template>
                        <template v-slot:append-outer>
                          <v-slide-x-reverse-transition mode="out-in">
                            <v-icon :disabled="loading.unitsList" :key="`icon-${deleteOrAddAll}`"
                              :color="deleteOrAddAll === 'delete' ? 'error' : 'success'" @click="deleteOrAdd"
                              v-text="deleteOrAddAll === 'delete' ? 'mdi-delete-sweep-outline' : 'mdi-playlist-plus'"></v-icon>
                          </v-slide-x-reverse-transition>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-form>
              </v-tab-item>
              <v-tab-item key="2">
                <v-row>
                  <v-col cols="6">
                    <v-switch v-model="editedItem.notificationIsActive"
                      :label="`Notificações: ${editedItem.notificationIsActive ? 'Ligada' : 'Desligada'}`"></v-switch>
                  </v-col>
                  <v-col cols="6">
                    <v-select multiple :disabled="!editedItem.notificationIsActive" label="Canal"
                      v-model="editedItem.notificationChannel" :items="channelsTypes" type="text"
                      :rules="rulesRequired" />
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-if="editedItem.notificationIsActive && editedItem.notificationChannel.find((type) => type === 'whatsapp')"
                      :rules="rulesRequired" :loading="loading.usersList" multiple chips required
                      v-model="editedItem.usersSendWhatsappNotification" :items="usersData" background-color="white"
                      label="Escolha os usuários que receberão o Alerta via Whatsapp" item-value="id" item-text="name">
                      <template v-slot:selection="{ item, index }">
                        <v-chip>
                          <span>{{ (item.name + " - ") + (item.metadata.phone ? item.metadata.phone : "Sem Telefone"
                          ) }}</span>
                        </v-chip>
                      </template>
                      <template v-slot:append-outer>
                        <v-slide-x-reverse-transition mode="out-in">
                          <v-icon :disabled="loading.usersList" @click="deleteUsersList" color="error"
                            v-text="'mdi-delete-sweep-outline'"></v-icon>
                        </v-slide-x-reverse-transition>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item key="3">
                <v-row>
                  <v-col cols="12" >
                    <v-data-table :loading="loading.unitsList" :items="unitsByAlertData"
                      :headers="headersUnitAlertByAlert" :footer-props="{
                        'items-per-page-options': [5, 10, 20, 30]
                      }" no-data-text="Sem Alertas para exibir">
                      <template v-slot:item.id_unit="{ item }">
                        {{ changeUnitIdPerUnitName(item.id_unit) }}
                      </template>
                      <template v-slot:item.next_reading_date="{ item }">
                        {{ getNextReadingDateByUnit(item.id_unit) }}
                      </template>
                      <template v-slot:item.reading_date="{ item }">
                        {{ getReadingDateByUnit(item.id_unit) }}
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>

          </v-card-text>

          <v-card-actions class="justify-end">

            <v-btn :loading="loading.modalAlert" text @click="save()">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <v-card style="border-radius: 20px">

          <v-row style="justify-content: space-between">
            <v-card-title class="title-displays"> Configuração Alertas </v-card-title>
            <v-card-title>
              <v-btn right @click="addNewAlert()" color="primary" text>
                <v-icon> add </v-icon>
                Adicionar Alerta
              </v-btn>
            </v-card-title>
            <v-text-field style="width:80%; padding:1em" class="px-4" prepend-icon="search" label="Busca"
              v-model="searchText"></v-text-field>
            <v-select hint="Buscar nessa coluna" persistent-hint style="width:20%; padding: 1em"
              v-model="columnToSearch" :items="columnsToChoose" return-object single-line></v-select>
          </v-row>


          <v-data-table :loading="loading.mainTable" :items="filteredItems" :headers="headers" :footer-props="{
            'items-per-page-options': [5, 10, 20, 30]
          }" no-data-text="Sem Alertas para exibir">
            <template v-slot:item.alerType="{ item }">
              {{ alertTypeSlug(item.alerType) }}
            </template>
            <template v-slot:item.periodType="{ item }">
              {{ periodTypeSlug(item.periodType) }}
            </template>
            <template v-slot:item.consumptionType="{ item }">
              {{ consumptionTypeSlug(item.consumptionType) }}
            </template>
            <template v-slot:item.consumptionLimit="{ item }">
              {{ consumptionLimitSlug(item) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :loading="loading.buttonList" v-bind="attrs" v-on="on" color="primary"
                    style="font-weight: bold" outlined>
                    <v-icon small>
                      mdi-text-box-multiple-outline
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item link v-for="(line, index) in listActions" :key="index" :value="index">
                    <v-list-item-title @click="selectAction(item, line.value)">{{ line.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Alertscript from './AlertsScript';
export default Alertscript;
</script>


<style scoped lang="scss">
.title-displays {
  font-size: 25px;
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>