<template>
  <div>
    <v-row>
      <Solutions  v-if="showSolutions" :proposal="proposal" />
      <Products   :proposal="proposal" :solutions="solutionsData" />
      <Services   :proposal="proposal" :solutions="solutionsData" />
    </v-row>
    <v-divider style="margin-top: 8px"></v-divider>
  </div>
</template>
<script>
import Solutions from '@/components/Waterscan/ProposeTab/ReportSolutions/Solutions.vue'
import Services from '@/components/Waterscan/ProposeTab/ReportSolutions/Services.vue'
import Products from '@/components/Waterscan/ProposeTab/ReportSolutions/Products.vue'
import SolutionsServices from '@/services/firebase/SolutionsServices/SolutionsServices';

export default {
    name: "reportSolution",
    components: {
        Solutions,
        Services,
        Products,
    },
    props: {
        proposal: {
            type: Object,
            require: true,
        },
        showSolutions: {
            type: Boolean,
            require: false,
            default: true,
        },
    },
    data: function () {
        return {
            solutionsData: [],
        };
    },
    async created() {
        this.solutionsData = await SolutionsServices.GetSolutions()
    }
};
</script>