import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import AddressForm from '@/components/FormsComponents/Address.vue'
import BuildProposed from '@/components/FormsComponents/WaterScanSteps/BuildProposed.vue'
import CheckList from '@/components/FormsComponents/WaterScanSteps/CheckList.vue'
import HistoricConsumption from '@/components/FormsComponents/WaterScanSteps/HistoricConsumption.vue'
import LocationInformation from '@/components/FormsComponents/WaterScanSteps/LocationInformation.vue'
import ProgressBar from '@/components/ProgressBar/ProgressBar.vue'
import StepsBar from '@/components/StepsBar/StepsBar.vue'
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"

export default {

  name: "NewWaterScan",
  components: {
    LocationInformation,
    HistoricConsumption,
    BuildProposed,
    ProgressBar,
    StepsBar,
    AddressForm,
    CheckList
  },
  props: ['open', 'closeFunc', 'registration', 'idProposal', 'viewOrEdit'],
  data: function () {
    return {
      closeAllPages: true,
      requestSave: false,
      validFormPag1: false,
      validFormPag2: false,
      validFormPag3: false,
      validFormPag4: false,
      advance: [],
      overlay: false,
      statusPos: 1,
      widthScreen: 0,
      enrollDialog: false,
      infoAlerts: 0,
      isPwsVisible: false,
      modensAlert: [],
      enrollObj: {
        locationInformation: [],
        checkList: [],
        historicConsumption: [],
      },
      statusProgress: [
        { id: 1, active: true, name: 'Cadastro' },
        { id: 2, active: false, name: 'Check List' },
        { id: 3, active: false, name: 'Histórico' },
        { id: 4, active: false, name: 'Proposta' }],
      positionStep: 0,
      selectStep: 1,
      steps: ['Cadastro', 'Check List', 'Historico', 'Proposta'],
      enableStepWarn: [],
      nextStep: 0,

      saveLocaleInfo: false,
      saveChecklist: false,
      saveHistoryc: false,
      saveBuildProposal: false,

    }
  },
  computed: {},
  methods: {
    ...mapActions([
      'closeChannel',
    ]),
    async saveProposal() {

      switch (this.positionStep) {
        case 0:
          await this.$refs.LocationInformation.resquetEvent(this.positionStep, true)
          break;
        case 1:
          await this.$refs.CheckList.resquetEvent(this.positionStep, true)
          break;
        case 2:
          await this.$refs.HistoricConsumption.resquetEvent(this.positionStep, true)
          break;
        case 3:
          if (this.enableStepWarn.find((p) => p === 1)) {
            showErrorSnackBar(`Preencha o checklist para concluir a formatação da proposta!`)
            return
          }
          await this.$refs.BuildProposed.updateProposalinFirebase()
          break;
        default:
          break;
      }
      if (this.enableStepWarn.length > 0) {
        showErrorSnackBar(`Existem campos pendentes!`)
        return
      }
      this.clearDisplay();

    },
    clearDisplay() {
      this.closeFunc();
      this.enableStepWarn = []
      this.closeAllPages = false
    },
    async validFormPages(valid, page) {
      this.overlay = false
      if (valid) {
        this.enableStepWarn = this.enableStepWarn.filter(stepWarn => stepWarn != page)
      } else {
        this.enableStepWarn.push(page)
      }
    },
  },
  watch: {
    open() {
      this.closeAllPages = this.open
    },
    async positionStep(position) {
      let locationInfoValid = await this.$refs.LocationInformation.resquetEvent(position)
      if (!this.viewOrEdit && locationInfoValid === false) {
        showErrorSnackBar("Preencha os dados de Cadastro!!")
      } else {
        await this.$refs.CheckList.resquetEvent(position)
        await this.$refs.HistoricConsumption.resquetEvent(position)
        await this.$refs.BuildProposed.getCurrentProposal(position)
        this.nextStep = position
      }
    }
  },
};