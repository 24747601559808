import { db } from "@/firebase"
const leads_ref = db.collection("leads")



const createLead = async (data) => {
    try {
        const get_data = await leads_ref.add(data);
        await editLead(get_data.id, { ...data, id_doc: get_data.id })
        return get_data.id;
    } catch (error) {
        throw new Error("createLead Fail!" + error)
    }
}
const editLead = async (idDoc, data) => {
    try {
        await leads_ref.doc(idDoc).update(data);
        return true;
    } catch (error) {
        throw new Error("editLead Fail!" + error)
    }
}

async function getLeads() {
    let leads_list = []
    try {
        const unitsRef = await leads_ref.get()
        unitsRef.forEach((doc) => leads_list.push(doc.data()));
        return leads_list
    } catch (error) {
        console.error(error)
    }
}
async function getLeadsById(idUser) {
    try {
        const user = await leads_ref.doc(idUser).get()
        return user.data()
    } catch (error) {
        console.error(error)
    }
}


export default {
    createLead,
    editLead,
    getLeads,
};