<template>
  <v-form ref="form" v-model="validForm" lazy-validation>
    <v-row justify="center">
      <v-dialog transition="dialog-top-transition" v-model="dialog" v-if="dialog" persistent max-width="600px">
        <v-card>
          <v-toolbar color="primary" dark> <span class="text-h5">Adicionar Bomba</span></v-toolbar>
          <v-card-title>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text style="padding-bottom: 0px;">
            <v-row @keyup="(e) => trigger(e)">
              <v-col :cols="cols">
                <v-text-field v-model="bomb.locale" outlined label="Local" type="text" required :rules="rulesRequired">
                </v-text-field>
              </v-col>
              <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
                <v-select v-model="bomb.reservoir" outlined label="Reservatorio" :items="optionsBomb"
                  prepend-inner-icon="mdi-cup-water" required :rules="rulesRequired">
                </v-select>
              </v-col>
              <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
                <v-text-field outlined label="Potênca" prefix="W" type="Number" v-model="bomb.watt"
                  prepend-inner-icon="mdi-lightning-bolt-outline" required :rules="rulesRequired">
                </v-text-field>
              </v-col>
              <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
                <v-text-field v-model="bomb.hour_day_use" outlined label="Horas Uso/Dia" type="Number"
                  prepend-inner-icon="mdi-clock-time-five-outline" required :rules="rulesNumber">
                </v-text-field>
              </v-col>
              <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
                <v-text-field v-model="bomb.value_kwh" outlined label="Valor do Kw/h" prefix="R$ " type="Number" required
                  :rules="rulesRequired">
                </v-text-field>
              </v-col>
            </v-row>
            <div>
              <v-row>
                <h3> Adiconar Imagem </h3>
                <v-btn color="primary" text @click="pickFile()">
                  <v-icon>add</v-icon>
                </v-btn>
                <input multiple type="file" id="get_image" ref="get_image" accept="image/*" style="display: none"
                  @input="onFilePicked" />
              </v-row>
              <vue-horizontal>
                <section style=" margin: 10px;" v-for="img, n in listImgs" :key="n">
                  <v-img :src="img.image" aspect-ratio="1" style="width: 200px" @click="deleteImgSelect(img)">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular indeterminate color="grey-lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </section>
              </vue-horizontal>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog()">
              Cancelar
            </v-btn>
            <v-btn color="blue darken-1" text @click="save()"> Salvar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-form>
</template>

<script>
import Rules from "@/utils/Rules";
import InputImg from "@/components/Forms/Input/input-img";
import WaterScanStoragefrom from '@/services/storage/WaterScanStorage';
import VueHorizontal from 'vue-horizontal';
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper";

export default {
  components: {
    InputImg,
    VueHorizontal
  },
  props: {
    dialog: {
      default: false,
      type: Boolean,
    },
    data: {},
    saveFunc: {},
    closeDialogFunc: {},
  },

  data: () => ({
    cols: "12",
    mdApt: "6",
    lgApt: "6",
    xlApt: "6",
    smApt: "6",

    listImgs: [],
    editedItem: {},
    deleteImgFile: {},

    validForm: true,
    validForm: true,
    deletedFiles: [],
    bomb: {
  
    },
    optionsBomb: ["Poço", "Recalque"],
    rawFiles: []
  }),
  methods: {
    trigger(e) {
      switch (e.key) {
        case "Enter":
          this.save()
          break;
        case "Escape":
          this.closeDialog()
          break;
      }
    },
    closeDialog() {
      this.closeDialogFunc();
      this.bomb = {
        locale: null,
        reservoir: null,
        watt: null,
        hour_day_use: null,
        value_kwh: null
      }
      this.listImgs = []
      this.rawFiles = []
      this.deletedFiles = []
      this.$refs.get_image.value = null;
    },

    async save() {
      if (this.$refs.form && !this.$refs.form.validate()) return

      this.bomb.imgList = []
      this.listImgs.forEach(img => {
        if(img.pathFirebase)this.bomb.imgList.push(img.pathFirebase)
      });
      this.saveFunc("bomb", this.bomb,this.rawFiles,this.deletedFiles);
      this.listImgs = []
      this.rawFiles = []
      this.deletedFiles = []
      this.$refs.get_image.value = null;
    },
    async deleteImgSelect(event) {
      this.deleteImgFile = event
      showDeleteDialogAlert(`Deseja Excluir a imagem ${event.imageName}`, this.deleteImg)
    },
    async deleteImg() {
      if(this.deleteImgFile.pathFirebase) this.deletedFiles.push(this.deleteImgFile)
      this.listImgs = this.listImgs.filter(({ image }) => image != this.deleteImgFile.image)
      this.rawFiles = this.rawFiles.filter(({ name }) => name != this.deleteImgFile.imageName)
    },
    pickFile() {
      this.$refs.get_image.click();
    },
    async onFilePicked(e) {
      const inputFiles = document.getElementById("get_image").files;
      if (inputFiles[0] !== undefined && inputFiles[0].type.split("/")[0] == "image") {
        this.rawFiles.push(...document.getElementById("get_image").files)
        for (let file of inputFiles) {
          await this.processImg(file)
        }
        this.$refs.get_image.value = null;
        this.$emit("rawFiles", this.rawFiles);
      } else {
        showErrorSnackBar("Arquivo inválido.")
        this.editedItem.image = "";
        this.editedItem.imageName = "";
      }
    },
    async processImg(file) {
      try {
        this.editedItem = {}
        this.editedItem.imageName = file.name;
        if (this.editedItem.imageName.lastIndexOf(".") <= 0) reject("Nome Invalido")
        var output = document.getElementById('output');
        this.listImgs.unshift({
          image: URL.createObjectURL(file),
          imageName: file.name
        })

      } catch (err) {
        console.error(err)
        showErrorSnackBar("Erro ao processar imagem.")
      }
    },
    async getStorageImages(listImgs) {
      for (let imgPath of listImgs) {
        const urlImg = await WaterScanStoragefrom.downloadImg(imgPath)

        const mainPathEnd = imgPath.indexOf('/')+1
        const matricula = imgPath.slice(mainPathEnd)
        const matriculaEnd = imgPath.slice(mainPathEnd).indexOf('/')+1
        const imgNameOrigin = matricula.slice(matriculaEnd+14)
       
        this.listImgs.unshift({
          image: urlImg,
          pathFirebase: imgPath,
          imageName: imgNameOrigin
        })
      }
    },
  },
  computed: {
    rulesRequired() {
      return Rules.required();
    },
    rulesNumber() {
      return Rules.number(true, 0, 24);
    },
  },
  watch: {
    data(newVal, oldVal) {
      if(this.dialog){
          this.bomb = newVal
          this.getStorageImages(this.bomb.imgList)
        }
    },
  },
};
</script>