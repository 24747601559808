<template>
    <v-row
        style="
            position: fixed;
            bottom: 0px;
            width: 100%;
            background-color: white;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        "
    >
        <v-col
            style="
                display: flex;
                justify-content: end;
            "
        >
            <v-btn
                @click="click"
                style="
                    background-color: #00b33c;
                    color: black;
                    font-size: 20px;
                    height: 50px;
                "
                :loading="is_loading"
                :disabled="is_loading || is_disabled"
            >
                <span>Habilitar o próximo passo</span>
                <span><v-icon>mdi-chevron-right</v-icon></span>
            </v-btn>
        </v-col>
    </v-row>
</template>
  
<script>
import ProjectsService from "@/services/server/ProjectsService.js";

export default {
	name: "Prospecção",

    props: {
        click: {
            type: Function,
            require: true,
            default: () => {},
        },
        loading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        // id_project: {
        //     type: String,
        //     default: null,
        // },
    },

	data: () => ({
		is_loading: false,
        is_disabled: false,
	}),

	computed: {
		
	},

	methods: {
		// async run_next_step() {
        //     console.log("run_next_step...")
        //     if (this.is_loading || this.is_disabled) return
        //     this.$emit('loading', true)
        //     this.is_loading = true
        //     await ProjectsService.nextStepProject(this.id_project)
        //     await this.nextStep()
        //     this.is_loading = false
        // }
	},
	
	watch: {
        // is_loading: function(newVal) {
        //     this.$emit('loading', newVal)
        // },
        loading: {
            immediate: true,
            handler: function (newVal) {
                this.is_loading = newVal
            }
        },
        disabled: {
            immediate: true,
            handler: function (newVal) {
                this.is_disabled = newVal
            }
        },
	},

	async created() {
        
    },
};
</script>
  
<style scoped>

</style>