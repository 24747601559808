import SelectFranchisee from "@/components/Widgets/Select/select-franchisee.vue";
import SelectClient from "@/components/Widgets/Select/select-client.vue";
import SelectUnit from "@/components/Widgets/Select/select-unit.vue";
import Utils from '@/scripts/Utils';
import { mapGetters } from "vuex";
import UsersService from "@/services/firebase/Users/UsersService";


export default {
  props: {
    title: {
      type: String,
      required: false,
      default: "Relatório"
    },
    main_headers: {
      type: Array,
      required: false,
      default: () => []
    },
    secondary_headers: {
      type: Array,
      required: false,
      default: () => []
    },
    data_table_prop: {
      type: Array,
      required: false,
      default: () => []
    },
    data_table_expand_prop: {
      type: Array,
      required: false,
      default: () => []
    },
    show_expand_prop: {
      type: Boolean,
      required: false,
      default: false
    },
    select_id: {
      type: Function,
      required: false,
      default: () => { }
    },
    onExpand_prop: {
      type: Function,
      required: false,
      default: () => { }
    },
    getAlertsPerData: {
      type: Function,
      required: false,
      default: () => { }
    },
    generatePdf: {
      type: Function,
      required: false,
      default: () => { }
    },
    loading_prop: {
      required: false,
    },
    calendar_type: {
      required: false,
      default: 'date'
    },
    activate_pdf_button: {
      required: false,
      default: true
    },
    general_button_pdf_name: {
      required: false,
      default: "PDF RESUMO"
    },
    start_view_date: {
      required: false,
      default: null
    },
  },
  components: {
    SelectFranchisee,
    SelectClient,
    SelectUnit
  },
  data() {
    return {
      franchisee_id: null,
      client_id: null,
      unit_id: null,
      reset_client: true,
      expanded: [],
      loading: {
        main_table: false,
        main_button_pdf: false,
        secondary_table: false,
        secondary_button_pdf: false,
        general_button_pdf: false,
        pdf_index_button: ''
      },
      data_table: [],
      startDateDialog: false,
      maxStartDate: '',
      startDatePicker: this.start_view_date,
      lastValueSelect: ""
    }
  },
  computed: {
    ...mapGetters(["getCurrentUserRank", "getCurrentUser", "getCurrentUserRank", "getUnitByIDs", "getAssociados"]),
    isShowAdmin() {
      return this.getCurrentUserRank === "Super Admin" || this.getCurrentUserRank === "Admin"
    },
    isShowFranchisee() {
      return this.getCurrentUserRank === "Franqueado"
    },
    isShowClient() {
      return this.getCurrentUserRank === "Cliente"
    },
    isShowAssociated() {
      return this.getCurrentUserRank === "Associado"
    },
    disabledGeneralButtonPdf() {
      return this.loading.main_table || this.data_table_prop.length == 0 || this.loading.main_button_pdf || this.loading.secondary_button_pdf
    },
    showGeneralButtonPdf() {
      return this.lastValueSelect.entity == 'client' || this.lastValueSelect.entity == 'unit'
    },
    SelectDateFormat() {
      return this.startDatePicker ? Utils.formatDate(this.orderStartDate()[0], "<dd>/<MM>/<YYYY>") + " a " + Utils.formatDate(this.orderStartDate()[1], "<dd>/<MM>/<YYYY>") : ""

    },
    processedItems() {
      return this.data_table.map((item, index) => ({
        ...item,
        uniqueKey: `${index}-${new Date().getTime()}`,
      }));
    },
  },
  methods: {
    async onExpand({ item, value }) {
      if (!value) return
      this.loading.secondary_table = true
      await this.onExpand_prop({ item, value })
      this.loading.secondary_table = false
    },
    async catch_franchisee_id(franchisee_id) {
      this.data_table = []
      this.loading.main_table = true
      this.franchisee_id = franchisee_id;
      this.lastValueSelect = { entity: "franchisee", id: this.franchisee_id }

      if (this.isShowAdmin) {
        await this.select_id({ id: franchisee_id, type: 'franchisee' })
        this.loading.main_table = false
      } else {
        this.loading.main_table = false
      }
    },
    async catch_client_id(client_id) {
      this.data_table = []
      if (client_id === null) {
        this.catch_franchisee_id(this.franchisee_id)
        return
      }
      this.lastValueSelect = { entity: "client", id: client_id }
      this.client_id = client_id;
      this.unit_id = null
      this.loading.main_table = true
      await this.select_id({ id: this.client_id, type: 'client' })
      this.loading.main_table = false

      if (this.isShowAdmin || this.isShowFranchisee) {

      } else {
        this.loading.main_table = false
      }
    },
    async catch_unit_id(unit_id) {
      this.data_table = []
      if (unit_id === null) {
        return
      }
      this.lastValueSelect = { entity: "unit", id: unit_id }
      this.unit_id = unit_id;
      this.loading.main_table = true
      await this.select_id({ id: this.unit_id, type: 'unit' })
      this.loading.main_table = false
    },
    resetSelects() {
      if (this.client_id) {
        this.data_table = []
        this.expanded = []
        this.client_id = null
        this.reset_client = !this.reset_client
      }
    },
    async selectDateCalendar(date) {
      this.startDatePicker = date
      this.startDateDialog = !this.startDateDialog
      this.loading.main_table = true
      await this.getAlertsPerData(date)
      this.loading.main_table = false

    },
    orderStartDate() {
      if (this.startDatePicker[0] > this.startDatePicker[1]) {
        return [
          this.startDatePicker[1],
          this.startDatePicker[0]
        ]
      }
      return [
        this.startDatePicker[0],
        this.startDatePicker[1]
      ]
    },
    async clickTableRow(line) {
      switch (this.lastValueSelect.entity) {
        case "franchisee":
          this.catch_client_id(line.id)
          break;
        case "client":
          this.catch_unit_id(line.id)
          break;
        case "unit":
          break;
      }
    },
    startDateCalendar() {
      const date = Utils.formatDate(new Date(), "<YYYY>-<MM>-<dd>");
      this.startDateDialog = true
      this.maxStartDate = date
    },
    async generatePdfComponent(item, status = null) {
      if (item) this.loading.pdf_index_button = item.index
      try {
        switch (status) {
          case null:
            this.loading.main_button_pdf = true
            await this.generatePdf(item, status)
            this.loading.main_button_pdf = false
            break;
          case "exapand":
            this.loading.secondary_button_pdf = true
            await this.generatePdf(item, status)
            this.loading.secondary_button_pdf = false
            break;
          case "general":
            this.loading.general_button_pdf = true
            await this.generatePdf(this.lastValueSelect, status)
            this.loading.general_button_pdf = false
            break;
        }

      } catch {
        this.loading.main_button_pdf = false
        this.loading.general_button_pdf = false
        this.loading.secondary_button_pdf = false
      }
      this.loading.pdf_index_button = ""
    },
    activeLoadingMainButtonPdf(item) {
      return this.loading.main_button_pdf && this.loading.pdf_index_button == item.index
    },
    activeLoadingSecondaryButtonPdf(item) {
      return this.loading.secondary_button_pdf && this.loading.pdf_index_button == item.index
    },
    disabledMainButtonPdf(item) {
      return this.loading.main_button_pdf && this.loading.pdf_index_button != item.index || this.loading.secondary_button_pdf
    },
    disabledSecondaryButtonPdf(item) {
      return this.loading.main_button_pdf && this.loading.pdf_index_button != item.index || this.loading.secondary_button_pdf || this.loading.general_button_pdf
    },
    disabledSecondaryButtonPdf(item) {
      return this.loading.secondary_button_pdf && this.loading.pdf_index_button != item.index || this.loading.main_button_pdf || this.loading.general_button_pdf
    },
  },
  mounted() {

  },
  watch: {
    async data_table_prop() {
      const associados = await UsersService.getUsersByRank(5)
      let associado = associados.find(a => a.id === this.getCurrentUser.uid);
      if (associado && this.lastValueSelect.entity === "client") {
        let allowedIDUnits = associado.unitsAllowedByClient.map(u => typeof u === 'object' ? u.id : u);
        let listUnit = this.getUnitByIDs(allowedIDUnits);
        this.data_table = this.data_table_prop.filter((line_table) => listUnit.find(({ id }) => line_table.id === id))
      } else {
        this.data_table = this.data_table_prop
      }
    }
  }
}