import { db } from "@/firebase"
import Utils from '@/scripts/Utils';
const solutions_ref = db.collection("solutions")

async function GetSolutions() {
    try {
        let solutions = []
        const get_data = await solutions_ref.get();
        get_data.forEach((doc) => solutions.push(doc.data()));
        return solutions
    } catch (error) {
        console.error(error);
    }
}

async function GetSolutionsByIds(solutionIds) {
    try {
        let chunkIds = Utils.chunkArray(Utils.removeDuplicates(solutionIds))
        let responses = {}
        for (let i = 0; i < chunkIds.length; i++) {
            const get_data = await solutions_ref.where("id", "in", chunkIds[i]).get();
            chunkIds[i].forEach((id) => responses[id] = null)
            get_data.forEach((doc) => responses[doc.id] = {...doc.data(), id: doc.id});
        }
        return responses
    } catch (error) {
        console.error(error);
    }
}

async function UpdateSolutions(solution) {
    try {
        await db
            .collection("solutions")
            .doc(solution.id)
            .update(solution);

    } catch (error) {
        console.error(error);
    }
}

async function AddSolutions(solution){
    if (solution === undefined) throw "field 'user' is missing";
    
    try{
        const doc_ref = await solutions_ref.add(solution);
        const doc_id = doc_ref.id;
        await db
            .collection("solutions")
            .doc(doc_id)
            .update({
                id: doc_id,
            });
        return doc_id;

    }catch(e){

    }
}

async function DeleteSolution(solution_id) {
    try {
        await db
            .collection("solutions")
            .doc(solution_id)
            .delete();
        return true;
    } catch (error) {
        console.error(error);
        return false;
    }
}


export default {
    GetSolutions,
    GetSolutionsByIds,
    AddSolutions,
    DeleteSolution,
    UpdateSolutions
};
