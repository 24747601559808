<template>
  <v-container fluid>
    <FormReport :title="'Relatório de Telemetria'" 
      :main_headers="main_headers" 
      :secondary_headers="secondary_headers"
      :select_id="catch_id"
      :data_table_prop="data_table" 
      :data_table_expand_prop="data_table_expand"
      :show_expand_prop="show_expand" 
      :onExpand_prop="onExpand"
      :getAlertsPerData="getAlertsPerData"
      :activate_pdf_button="false"
      :generatePdf="generatePdf"
      :start_view_date="startDatePicker"
      >
      
      <table style="
        border-radius: 5px;
        border-collapse: collapse;
        width: 100%;
        overflow: hidden;
        height: 85%;
      "
      slot="summary-header"
      >
        <caption class="TitleChecklist" style="background: #0a517f; color: white">
          <strong> Resumo </strong>
        </caption>
        <tr>
          <td class="TitleChecklist column-statitcs-info" style="background: #056aae; color: white;">
            Alertas: <strong> {{ summary.amount }}</strong>
          </td>
          <td class="TitleChecklist column-statitcs-info" style="background: #0e76bc; color: white">
            Falta de Sinal: <strong> {{ summary.lack_of_signal }}</strong>
          </td>      
          <td class="TitleChecklist column-statitcs-info" style="background: #056aae; color: white;">
            Bateria Fraca: <strong> {{ summary.low_battery }}</strong>
          </td>
          <td class="TitleChecklist column-statitcs-info" style="background: #0e76bc; color: white">
            Sem Variação: <strong> {{ summary.no_variation }}</strong>
          </td>      
          <td class="TitleChecklist column-statitcs-info" style="background: #056aae; color: white;">
            Sem Decodificação: <strong> {{ summary.no_decodification }}</strong>
          </td>
        </tr>

      </table>
      <template v-slot:button-main-table="{onClick,prop,disabled,loading,status}">
          <v-menu  offset-y >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                style="font-weight: bold"
                outlined       
                :disabled="disabled(prop)"
                :loading="loading(prop)"                       
                
              >
                <v-icon small >
                  mdi-text-box-multiple-outline
                </v-icon>
              </v-btn>
            </template>
            <v-list >
                <v-list-item
                  link
                  v-for="(line, index) in titles_button_download"
                  :key="index"
                  :value="index"
                >
                  <v-list-item-title  @click="catch_report_tipe(line.title,onClick,prop,status)">{{ line.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
          </v-menu>
        </template>
      </FormReport>
      <DialogSendEmail :open="dialog_send_email" :runFunc="sendEmail"/>
  </v-container>
</template>

<script>
import ReportScript from "./ReportScript";
export default ReportScript;
</script>

<style ></style>