<template>
  <div>
    <h3 v-if="title !== null" style="color: #437EBE; font-size: 1.2em;">{{ title }}</h3>
    <v-skeleton-loader v-show="loading" type="image"></v-skeleton-loader>
    <canvas v-show="!loading" style="min-width: 800px; padding-bottom: 10px;" :id="canvaID"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    datasets: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false, // Indica se o valor está carregando
    },
  },
  data() {
    return {
      id: null,
      canvaID: null,
      myChart: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false,
          },
          roundedBarsPlugin: true,
        },
        legend: {
          display: true,
          labels: {
            usePointStyle: true,
          },
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
            },
            ticks: {
              beginAtZero: true,
            },
            offset: false,
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              stepSize: 100,
            },
            gridLines: {
              borderDash: [5, 5],
              display: true,
              color: "rgba(0, 0, 0, 0.2)",
            },
          }],
        },
      },
    };
  },
  created() {

    this.id = this._uid;
    this.canvaID = `graphic-consumption-${this.id}`;
  },
  mounted() {
    this.render();
  },
  methods: {
    render() {
      if (this.myChart) {
        this.myChart.destroy();
      }

      const filteredDatasets = this.datasets.filter(dataset => {
        return dataset.data.some(value => value !== 0);
      });

      let ctx = document.getElementById(this.canvaID).getContext('2d');

      const extendLinePlugin = {
        id: 'extendLinePlugin',
        afterDatasetsDraw(chart) {
          const { ctx } = chart;
          const yAxes = chart.scales['y-axis-0']
          const xAxes = chart.scales['x-axis-0']

          chart.data.datasets.forEach((dataset, index) => {
            if (dataset.type === 'line' && dataset.label.includes('Média')) {
              // Define a posição média para desenhar a linha horizontal
              const averageValue = dataset.data[0]; // Supondo que todos os pontos de média têm o mesmo valor
              const yPos = yAxes.getPixelForValue(averageValue); // Pega a posição em pixels do valor médio no eixo Y

              // Configura o estilo da linha
              ctx.save();
              ctx.strokeStyle = dataset.borderColor || '#0E76BC'; // Cor da linha
              ctx.lineWidth = dataset.borderWidth || 1;

              // Desenha a linha horizontal de ponta a ponta no eixo X
              ctx.beginPath();
              ctx.moveTo(xAxes.left, yPos); // Começa do lado esquerdo do eixo X
              ctx.lineTo(xAxes.right, yPos); // Vai até o lado direito do eixo X
              ctx.stroke();
              ctx.restore();
            }
          });
        }
      };


      this.myChart = new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.labels,
          datasets: filteredDatasets.map(dataset => ({
            ...dataset,
            backgroundColor: dataset.backgroundColor,
            borderSkipped: false,
            barThickness: dataset.type === "line" ? undefined : 20,
            type: dataset.type || "bar",
            order: dataset.type === "line" ? 2 : 1,  // Linhas desenhadas acima das barras
            fill: false,
            spanGaps: true,
            pointRadius: dataset.type === "line" ? 0 : undefined,
          })),
        },
        options: {
          ...this.options,
          scales: {
            ...this.options.scales,
            xAxes: [{
              ...this.options.scales.xAxes[0],
              offset: true, // Para expandir as linhas médias
            }]
          }
        },
        plugins: [extendLinePlugin],
      });
    },
    clearAndRedraw() {
      if (this.myChart) {
        this.myChart.destroy();
      }
      this.render();
    },
  },
  watch: {
    datasets: {
      immediate: true,
      handler(newValue) {
        if (newValue.length > 0) {
          this.$nextTick(() => {
            this.clearAndRedraw();
          });
        }
      },
    },
    labels: {
      immediate: true,
      handler(newValue) {
        if (newValue.length > 0) {
          this.$nextTick(() => {
            this.clearAndRedraw();
          });
        }
      },
    },
  },
};

</script>
