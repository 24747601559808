import { db } from "@/firebase"
const modens_ref = db.collection("modens")

const arrayChunkSeparate500 = async (data, chunkSize = 500) => {
  let result = []
  for (let i = 0; i < data.length; i += chunkSize) {
      const chunk = data.slice(i, i + chunkSize);
      result.push(chunk)
  }
  return result
}

const getModens = async () => {
  let modems = [];
  try {
    const doc = await modens_ref.get();
    doc.forEach((modem) => {
      modems.push(modem.data());
    });
    return modems;
  } catch (error) {
    console.error(error);
    return;
  }
}
const getModemByID = async (idDoc) => {
  try {
    const doc = await modens_ref.doc(idDoc).get();
    return doc.data();
  } catch (error) {
    console.error(error);
    return;
  }
}
const getModemByIdList = async (idList) => {
  let modems = [];
  try {
    if(idList.length){
      const arraySeparated = await arrayChunkSeparate500(idList,10)
      for (let listConsult of arraySeparated) {
        const doc = await modens_ref.where("id","in",listConsult).get();
        doc.forEach((modem) => {
          modems.push(modem.data());
        });
      }
    }
    return modems;
  } catch (error) {
    console.error(error);
    return;
  }
}
const getModensWithoutUnitId = async () => {
  let modems = [];
  try {
    const doc = await modens_ref.get();
    doc.forEach((modem) => {
      modems.push(modem.data());
    });
    return modems;
  } catch (error) {
    console.error(error);
    return;
  }
}
const listenerModem = async (idDoc) => {
  let modem = [];
  try {
    return modens_ref.doc(idDoc)
      .onSnapshot((doc) => {
        modem = doc.data()
        return modem;
      });
  } catch (error) {
    console.error(error);
    return;
  }
}
const generateIDModem = async () => {
  try {
    const doc = modens_ref.doc();
    return doc.id;
  } catch (error) {
    console.error(error);
    return;
  }
}
const createModem = async (data) => {
  try {
    await modens_ref.doc(data.id).set(data);
    return true;
  } catch (error) {
    console.error(error);
    return;
  }
}

async function getModensStartAfter(pages = 10, lastDoc) {
  let modens = []
  try {
    let queryRef = db.collection('modens').limit(pages)
    if (lastDoc) {
      queryRef = queryRef.startAfter(lastDoc)
    }
    const resultModens = await queryRef.get()
    const lastDocSnapshot = resultModens.docs[resultModens.docs.length - 1]
    resultModens.forEach((doc) => {
      modens.push(doc.data());
    });
    return { list: modens, lastDocSnapshot }

  } catch (error) {
    console.error("getModensStartAfter: " + error)
  }

}


export default {
  getModens,
  createModem,
  getModemByID,
  listenerModem,
  generateIDModem,
  getModemByIdList,
  getModensStartAfter,
  getModensWithoutUnitId,
}
