import DatePickerDialog from "@/components/DatePickerDialog/DatePickerDialog.vue";
import SkeletonComponent from "@/components/Skeleton/Skeleton.vue";
import firebase from "@/firebase";
import ObjectValidator from '@/scripts/ObjectValidator';
import Utils from '@/scripts/Utils';
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper";
import { mapActions, mapGetters } from "vuex";

export default {
    components: {
        SkeletonComponent,
        DatePickerDialog,
    },
    data: () => ({
        firstLoading: true,
        concessionare: ObjectValidator.create('concessionare_compesa'),
        beforeConcessionare: null,
        concessionareModal: false,
        dateDialog: false,
        isLoadingDialog: false,
        alertDialog: false,
        message: '',
        teste: 'residential',
        tabConcessionare: null,
        tariffsTitle: {
            residential: 'Residencial',
            commercial: 'Comercial',
            industrial: 'Industrial',
            public: 'Público'
        },
        list: [],

        // ['residential', 'public', 'industrial','commercial'] ****************************************************************
        states: [],
        initialStates: ['edit', 'create', 'delete', 'view'],
        paramsState: {},
        // ****************************************************************
    }),
    methods: {
        ...mapActions([
            'getWaterAuthority',
            'updateWaterAuthority',
        ]),
        // ****************************************************************
        FSM_push(state, params = {}) {
            if (this.initialStates.includes(state)) {
                this.states = [];
                this.paramsState = {};
            }
            this.states.push(state.toLowerCase());
            for (let key in params) {
                this.paramsState[key] = params[key]
            }
        },
        FSM_lastState() {
            if (this.states.length <= 0) return null;
            return this.states[this.states.length - 1];
        },
        FSM_firstState() {
            if (this.states.length <= 0) return null;
            return this.states[0];
        },
        // ****************************************************************
        async FSM(state = 'create', params = {}) {
            this.FSM_push(state, params);
            this.alertDialog = false
            switch (this.FSM_lastState()) {
                case 'create':
                    let concessionareCreate = ObjectValidator.create('concessionare_compesa');
                    this.FSM('open-modal', { concessionare: concessionareCreate })
                    break;

                case 'view':
                    let concessionareIdView = this.paramsState.id;
                    let concessionareView = this.list.find(c => c.id === concessionareIdView);
                    Object.keys(concessionareView.tariffs).forEach(
                        (key) => {
                            concessionareView.tariffs[key] = concessionareView.tariffs[key].filter(
                                (intervalConsumption) => {
                                    return intervalConsumption.value !== null
                                }
                            )
                        }
                    );
                    this.FSM('open-modal', { concessionare: concessionareView })
                    break;

                case 'edit':
                    let concessionareIdEdit = this.paramsState.id;
                    let concessionareEdit = this.list.find(c => c.id === concessionareIdEdit);

                    this.FSM('open-modal', { concessionare: concessionareEdit })
                    break;

                case 'delete':
                    showDeleteDialogAlert("Deseja deletar essa tarifa?", this.deleteTarifa)
                    break;

                case 'open-modal':
                    this.concessionareModal = true;
                    // Remover a referencia do objeto
                    this.concessionare = Utils.copyObject(this.paramsState.concessionare);
                    // Remover a referencia do objeto
                    this.beforeConcessionare = Utils.copyObject(this.concessionare);
                    break;

                case 'close-modal':
                    this.concessionareModal = false;
                    this.concessionare = Utils.copyObject(this.beforeConcessionare);
                    break;

                case 'save-modal':
                    let concessionareToSave = Utils.copyObject(this.concessionare);

                    if (concessionareToSave.date != null) {
                        concessionareToSave.month = parseInt(concessionareToSave.date.replace(/[0-9]{4}\-/, ''));
                        concessionareToSave.year = parseInt(concessionareToSave.date.replace(/\-[0-9]{2}/, ''));
                    }
                    Object.keys(concessionareToSave.tariffs).forEach(
                        (key) => {
                            concessionareToSave.tariffs[key] = concessionareToSave.tariffs[key].map(
                                (intervalConsumption) => {
                                    if (intervalConsumption.value !== null) {
                                        intervalConsumption.value = parseFloat(intervalConsumption.value).toFixed(2)
                                    }
                                    return intervalConsumption;
                                }
                            )
                        }
                    );

                    let saveFlag = this.validationForm(concessionareToSave)

                    if (saveFlag === true) {
                        this.isLoadingDialog = true;
                        try {
                            await firebase.addWaterAuthority(concessionareToSave);
                            this.updateWaterAuthority([concessionareToSave.id]);
                            this.list = await this.getWaterAuthority('compesa');
                            this.FSM('close-modal')
                            this.isLoadingDialog = false;
                        } catch (e) {
                            this.isLoadingDialog = false;
                        }
                    }

                    break;

            }
        },
        async deleteTarifa() {
            await firebase.deleteWaterAuthority(this.paramsState.id);
            this.list = await this.getWaterAuthority('compesa');
        },
        formatTitleInterval(interval) {
            if (interval.is_fixed) return `Até ${Utils.formatNumber(interval.final_liter)} litros (TAXA FIXA)`;
            else return `Entre ${Utils.formatNumber(interval.start_liter)} e ${Utils.formatNumber(interval.final_liter)} litros`;
        },
        formatDateInterval(interval) {
            return Utils.formatMonthDate(interval.date, '<MM> de <YYYY>', true);
        }, validationForm(concessionareToSave) {
            let saveFlag = true
            let breakFlag = false
            this.isLoadingDialog = false;

            if (concessionareToSave.date === null) {
                this.alertDialog = true;
                saveFlag = !saveFlag
                this.message = "Selecione um mês!"
                return saveFlag
            }
            //Validando se existe algum item da lista que já tenha a data da nova concessionaria ( Menos com o proprio ID)
            if (this.FSM_firstState() === "edit" && saveFlag && !this.alertDialog) {
                for (let item in this.list) {
                    if (concessionareToSave.id != this.list[item].id && concessionareToSave.date === this.list[item].date) {
                        breakFlag = !breakFlag
                        saveFlag = !saveFlag

                        this.alertDialog = true;
                        this.message = "O mês da tarifa selecionado já existe!"
                        return saveFlag
                    }
                }
            }
            //Validando se existe algum item da lista que já tenha a data da nova concessionaria
            if (this.FSM_firstState() === "create" && saveFlag && !this.alertDialog) {
                for (let item in this.list) {
                    if (concessionareToSave.date === this.list[item].date) {
                        breakFlag = !breakFlag
                        saveFlag = !saveFlag


                        this.alertDialog = true;
                        this.message = "O mês da tarifa selecionado já existe!"
                        return saveFlag
                    }
                }
            }
            //Validando se existe algum componente null se a data não for igual
            if (saveFlag && !this.alertDialog) {
                for (let item in concessionareToSave.tariffs) {
                    for (let j in concessionareToSave.tariffs[item]) {
                        if (concessionareToSave.tariffs[item][j].value === null) {
                            breakFlag = !breakFlag;
                            saveFlag = false
                            this.alertDialog = true;
                            this.message = "Existe campo(s) sem valores em " + this.tariffsTitle[item]
                            return saveFlag
                        }
                    }
                }
            }
            return saveFlag
        }
    },
    computed: {
        ...mapGetters(["isLoadingWaterAuthority"]),
        concessionareModalTitle() {
            let state = this.FSM_firstState();
            if (state !== 'create' && state !== 'edit') return "Tarifa";
            return `${state === 'create' ? 'Criação' : 'Edição'} de Tarifa`;
        },
        typesConcessionare() {
            //Object.keys(this.concessionare.tariffs)
            let tariffsName = ['residential', 'commercial', 'industrial', 'public']
            return tariffsName;
        },
        getDateTariff() {
            if (!this.concessionare.date) return null;
            return Utils.formatMonthDate(this.concessionare.date, '<MM> de <YYYY>', true)
        },
        isLoading() {
            return (
                this.firstLoading
                ||
                this.isLoadingWaterAuthority
            );
        },
        isEdit() {
            let state = this.FSM_firstState();
            return state === 'edit';
        },
        isCreate() {
            let state = this.FSM_firstState();
            return state === 'create';
        },
        isView() {
            let state = this.FSM_firstState();
            return state === 'view';
        }
    },
    async created() {
        this.list = await this.getWaterAuthority('compesa');
    },
    mounted() {
        setTimeout(
            () => {
                this.firstLoading = false;
            },
            2000
        );
    },
}