const HOST = "https://us-central1-centralfoz-homolog.cloudfunctions.net/api/functions/"

// const HOST = "http://127.0.0.1:5001/centralfoz-homolog/us-central1/api/functions/"
import Utils from '@/scripts/Utils';

const Request = async (route, data) => {
    return new Promise(
        async (resolve, reject) => {
            const env = Utils.getEnv();
            // const env = 'prod'
            data = {...data, env}
            fetch(HOST + route, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
                .then(response => {
                    response.json()
                    .then(res => {                        
                        resolve(res)
                    })
                })
                .catch(err => {
                    reject(err)
                })
        }
    )

}

export default Request