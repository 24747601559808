<template>
  <v-container fluid>
    <FormReport :title="'Alertas de Consumo'" 
      :main_headers="main_headers" 
      :secondary_headers="secondary_headers"
      :select_id="catch_id"
      :data_table_prop="data_table" 
      :data_table_expand_prop="data_table_expand"
      :show_expand_prop="show_expand" 
      :onExpand_prop="onExpand"
      :getAlertsPerData="getAlertsPerData"
      :generatePdf="generatePdf"
      :activate_pdf_button="false"
      :general_button_pdf_name="'Arquivar Todos'"
      :start_view_date="startDatePicker"
      >
      
      <table style="
        border-radius: 5px;
        border-collapse: collapse;
        width: 100%;
        overflow: hidden;
        height: 85%;
      "
      slot="summary-header"
      >
        <caption class="TitleChecklist" style="background: #0a517f; color: white">
          <strong> Resumo </strong>
        </caption>
        <tr>
          <td class="TitleChecklist column-statitcs-info" style="background: #056aae; color: white;">
            Qntd: <strong> {{ summary.amount }}</strong>
          </td>
          <td class="TitleChecklist column-statitcs-info" style="background: #0e76bc; color: white;">
            Limite Consumo (L): <strong> {{ summary.limit_consumption? summary.limit_consumption.toFixed(2) : 0 }}</strong>
          </td>
          <td class="TitleChecklist column-statitcs-info" style="background: #056aae; color: white">
            Consumo no Periodo (L): <strong> {{  summary.liters_consumed_in_period? summary.liters_consumed_in_period.toFixed(2) :0 }}</strong>
          </td>      
          <td class="TitleChecklist column-statitcs-info" style="background: #0e76bc; color: white">
            Excedido no Periodo (L): <strong> {{ summary.liters_exceeded_in_period? summary.liters_exceeded_in_period.toFixed(2) : 0 }}</strong>
          </td>    
         
        </tr>
      </table>
      <template v-slot:button-main-table="{onClick,prop,disabled,loading,status}">
          <v-menu  offset-y >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                style="font-weight: bold"
                outlined       
                :disabled="disabled(prop)"
                :loading="loading(prop)"   
                @click="catch_view(onClick,prop,status)"            
              >
                <v-icon small >
                  mdi-paperclip
                </v-icon>
              </v-btn>
            </template>
          </v-menu>
      </template> 
      </FormReport>
  </v-container>
</template>

<script>
import AlertsScript from './AlertsScript';
export default AlertsScript;
</script>


<style >
 
</style>