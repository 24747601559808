<template>
  <v-form ref="form" v-model="validForm" lazy-validation >
    <v-row justify="center">
      <v-dialog transition="dialog-top-transition" v-model="dialog" v-if="dialog" persistent max-width="500px">
        <v-card   >
          <v-toolbar color="primary" dark> <span class="text-h5">Adicionar Hidrômetro</span></v-toolbar>
          <v-card-title>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text style="padding-bottom: 0px;">
            <v-row @keyup="(e) => trigger(e)">

              <v-col :cols="cols" >
                <v-text-field v-model="hydrometer.locale" outlined label="Local" type="text"
                  required :rules="rulesRequired">
                </v-text-field>
              </v-col>
              <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
                <v-select v-model="hydrometer.supply" outlined label="Abastecimento"
                  :items="optionsSupply" required :rules="rulesRequired">
                </v-select>
              </v-col>
              <v-col :cols="cols" :md="mdApt" :lg="lgApt" :sm="smApt" :xl="xlApt">
                <v-select v-model="hydrometer.model" outlined label="Modelo Hidrômetro"
                  :items="optionsHydrometer" required :rules="rulesRequired">
                </v-select>
              </v-col>
            </v-row>
            <InputImg v-on:rawFiles="rawFiles = $event"/>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog()">
              Cancelar
            </v-btn>
            <v-btn color="blue darken-1" text @click="save()"> Salvar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-form>
</template>

<script>
import Rules from "@/utils/Rules";
import InputImg from "@/components/Forms/Input/input-img";
export default {
  components: {
    InputImg
  },
  props: {
    dialog: {
      default: false,
      type: Boolean,
    },
    saveFunc: {},
  },

  data: () => ({
    cols: "12",
    mdApt: "6",
    lgApt: "6",
    xlApt: "6",
    smApt: "6",
    validForm: true,
    hydrometer: {},
    optionsSupply: ["Poço", "Concessionária"],
    optionsHydrometer: [
      { text: "DN 15", value: "dn_15" },
      { text: "DN 20", value: "dn_20" },
      { text: "DN 25", value: "dn_25" },
      { text: "DN 32", value: "dn_32" },
      { text: "DN 40", value: "dn_40" },
      { text: "DN 50", value: "dn_50" },
      { text: "DN 80", value: "dn_80" },
      { text: "DN 110", value: "dn_110" },
    ],
    rawFiles: []
  }),
  methods: {
    trigger(e) {
      switch (e.key) {
        case "Enter":
          this.save()
          break;
        case "Escape":
          this.closeDialog()
          break;
      }
    },
    closeDialog() {
      this.saveFunc("hydrometer", false);
      this.hydrometer = {}
      this.$emit("status:save", false)
    },

    async save() {
      if (this.$refs.form && !this.$refs.form.validate()) return
      this.hydrometer.imgList = this.rawFiles
      this.saveFunc("hydrometer", false, this.hydrometer);
      this.hydrometer = {}
      this.$emit("status:save", true)
    },
  },
  computed: {
    rulesRequired() {
      return Rules.required();
    },
  },
  watch: {
    rawFiles() {},
  },
};
</script>