<template>
  <v-card outlined class="pa-3 kpi-card">
    <div class="d-flex justify-space-between align-center">
      <!-- Ícone sempre visível -->
      <v-icon :style="{ fontSize: iconSize }" :color="color">{{ icon }}</v-icon>
      <div class="text-right">
        <!-- Exibe o skeleton loader apenas no valor -->
        <template v-if="loading">
          <v-skeleton-loader type="card" width="100" height="30"></v-skeleton-loader>
        </template>
        <template v-else>
          <h3 :style="{ fontSize: valueSize }" class="mb-0">{{ value }}</h3>
        </template>
        <small :style="{ fontSize: labelSize }">{{ label }}</small>
      </div>
    </div>
    <!-- <v-progress-linear :value="progress" height="7" color="green" class="mt-2"></v-progress-linear> -->
  </v-card>
</template>

<script>
export default {
  name: "KpiCard",
  props: {
    icon: {
      type: String,
      default: 'mdi-chart-line',
    },
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'primary',
    },
    progress: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false, // Indica se o valor está carregando
    },
  },
  computed: {
    iconSize() {
      return this.getResponsiveSize(3);
    },
    valueSize() {
      return this.getResponsiveSize(1);
    },
    labelSize() {
      return this.getResponsiveSize(0.5);
    },
  },
  methods: {
    getResponsiveSize(multiplier) {
      const baseSize = 6;
      return `calc(${baseSize}px + ${multiplier}vw)`;
    },
  },
};
</script>

<style scoped>
.kpi-card {
  border-radius: 20px;
}
</style>
