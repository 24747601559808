<template>
    <div style="width: 162px;display: flex;">
        <!-- <v-text-field
            :value="dateFormat"
            label="Data"
            type="text"
            single-line
            autofocus
            readonly
            @click="openDialogDate"
            :disabled="disabled"
        ></v-text-field> -->
        <v-text-field
          label="Data"
          :value="dateFormat"
          @click="openDialogDate"
        ></v-text-field>
        <date-picker-dialog 
            title="Escolha a data de leitura"
            :show="isOpenDialog"
            @close="status => isOpenDialog = status"
            :input="dateDialog"
            @change="changeDate"
        />
    </div>
</template>

<script>
import DatePickerDialog from "@/components/DatePickerDialog/DatePickerDialog.vue";
import Utils from '@/scripts/Utils';

import DateChip from "@/pages/Units/Widgets/date-chip.vue"

/**
 * Campo de leitura da data do histórico que tem como objetivo manter a estrutura
 * do histórico para não quebrar a compatibiidade
 * 
 * @params {String} month: é o campo de leitura do mês da leitura no format de 'YYYY-MM'
 * @params {String} day: é o campo de leitura do dia da leitura no format 'DD'
*/
export default {

  components: {
    DatePickerDialog,
    DateChip,
  },

  props: {
    month: {
        type: String,
        required: true,
        default: null,
    },
    day: {
        type: String,
        required: false,
        default: '01',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: true,
    },
    instalattionDate: {
      type: String,
      required: false,
      default: null,
    },
  },

  data: () => ({
    dateFormat: null,
    date: null,
    dateDialog: null,
    isOpenDialog: false,

    adapterDate: {
      month: null,
      day: null,
    },
  }),

  computed: {
    
  },

  methods: {
    formatCurrency: Utils.formatCurrency,
    formatNumber: Utils.formatNumber,
    formatDate: Utils.formatDate,
    openDialogDate() {
      if (this.disabled) return;
      this.isOpenDialog = true;
    },
    setDateFormat(date) {
      this.dateFormat = this.formatDate(date, '<DD>/<MM>/<YYYY>')
    },
    setDateDialog(date) {
      this.dateDialog = Utils.formatDate(date, '<YYYY>-<MM>-<DD>')
    },
    changeDate(date) {
      this.date = date;
      this.setNewDate(date)
      this.$emit('change', this.adapterDate)
    },
    setNewDate(date) {
      this.setDateDialog(date)
      this.setDateFormat(date)
      this.setAdapterDate(date);
    },
    setAdapterDate(date) {
      this.adapterDate.month = Utils.formatDate(date, '<YYYY>-<MM>')
      this.adapterDate.day = Utils.formatDate(date, '<DD>')
    },
  },

  watch: {
    day: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          this.date = Utils.instanceDate(`${this.month}-${newValue}`)
          this.setNewDate(this.date)
        }
      },
    },

    month: {
      immediate: true,
      handler: function (newValue) {
        if (newValue) {
          this.date = Utils.instanceDate(`${newValue}-${this.day}`)
          this.setNewDate(this.date)
        }
      },
    },
  },

};
</script>