<template>
  <v-form ref="formConsumption" v-model="validForm" lazy-validation>
    <div>
      <v-row>
        <v-col cols="12">
          <h3>Teste de Consumo Soluções:</h3>
        </v-col>
      </v-row>
      <v-row>
        <!-- Start Table Solutions -->
        <v-data-table
          :headers="headers"
          :items="checkListObj"
          class="elevation-1"
          style="width: 100%"
          :key="tableKey"
          :items-per-page="itemsPerPage"
        >
          <template v-slot:top>
            <v-toolbar flat class="rounded-lg">
              <v-toolbar-title>Relatório</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                class="mb-2"
                text
                @click="addSolutionReport()"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </v-toolbar>
          </template>
          <template v-slot:body="{ items }">
            <tbody :key="bodyKey">
              <tr v-for="(report, index) in items" :key="index">
                <td>
                 <v-text-field       
                    disabled             
                    v-model="report.local" 
                    :rules="rulesRequired"
                  >
                  </v-text-field>
                </td>
                <td>
                 <v-text-field    
                    disabled                
                    v-model="report.sub_local" 
                  >
                  </v-text-field>
                </td>
                <td>
                  <v-text-field   
                    disabled
                    :value="convertNameType(report.type)"
                    :rules="rulesRequired"
                  />
                </td>
                <td>
                  <v-text-field
                    disabled
                    :value="convertSolutionName(report.solution)"
                    :rules="rulesRequired"
                  />
                </td>
                <td>
                  <v-text-field 
                    disabled
                    type="Number" 
                    v-model="report.amountSolution" 
                    :rules="rulesRequired"
                  >
                  </v-text-field>
                </td>
                <td>
                  <v-text-field
                    disabled
                    type="Number"
                    v-model="report.flowRateBefore"
                    suffix="L/min"
                    :rules="rulesRequired"
                  >
                  </v-text-field>
                </td>
                <td>
                  <v-text-field
                    disabled
                    type="Number"
                    v-model="report.flowRateAfter"
                    suffix="L/min"
                    :rules="rulesRequired"
                  >
                  </v-text-field>
                </td>
                <td width="90px">
                  <v-text-field
                    disabled
                    type="Number"
                    v-model="report.economy"
                    suffix="%"
                  >
                  </v-text-field>
                </td>
                <td>
                  <v-text-field disabled v-model="report.comments"> </v-text-field>
                </td>
                <td>
                  <v-icon :color="changeColorIconListImg(report)" small> mdi-image </v-icon>
                </td>
                <td>
                  <v-btn
                    
                    elevation="2"
                    :loading="loading.actionsSolutionList"
                    @click="confirmDeleteSolution(report, index)"
                    style="height: 30px; margin: 2px"
                  >
                    <v-icon color="primary" small> mdi-delete </v-icon>
                  </v-btn>
                  <v-btn
                    elevation="2"
                    :loading="loading.actionsSolutionList"
                    @click="editReportSolution(report, index)"
                    style="height: 30px; margin: 2px"
                  >
                    <v-icon color="primary" small> mdi-pencil </v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <!-- End Table Solutions -->
      </v-row>
      <v-divider style="margin-top: 8px"></v-divider>
    </div>
    <solutionInsert 
      v-if="dialogSolution"
      :idProposal="idProposal"
      :dialog="dialogSolution" 
      :closeDialog="closeDialog" 
      :saveFunc="addSolutionAfterSaveButtonModal" 
      :registration="registration"
      :editableSolution="editableSolution"
      :clearEditItem="clearEditItem"
      :solutionsOptions="solutionsData"
      :pointsOptions="pointsData"
      @status:save="(e) => $emit('status:save', e)"
    />
  </v-form>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import Rules from "@/utils/Rules";
import { showInfoSnackBar } from "@/utils/SnackBartHelper";
import solutionInsert from "@/components/FormsComponents/DialogCheckList/Solution";
import WaterScanService from '@/services/firebase/WaterScan/WaterScanService';
import WaterScanStoragefrom from '@/services/storage/WaterScanStorage';
import { showDeleteDialogAlert } from "@/utils/DialogAlertHelper";
import SolutionsServices from '@/services/firebase/SolutionsServices/SolutionsServices';
import SolutionsPoint from '@/services/firebase/SolutionsPoint/SolutionsPoint';

export default {
  name: "hydrometerForm",
  props: ["requestData","solutionListItens","registration", "idProposal"],
  components: {
    solutionInsert,
  },
  data: function () {
    return {
      cols: "12",
      md: "4",
      lg: "4",
      xl: "4",
      sm: "4",
      validForm: true,
      optionSolution: [],
      checkbox1: null,
      checkbox2: null,
      headers: [
        { text: "Local", value: "local", width: 150, sortable: false },
        { text: "Sub local", value: "sub_local", width: 150, sortable: false },
        { text: "Tipo", value: "name", width: 130, sortable: false },
        { text: "Solução", value: "solution", width: 200, sortable: false },
        { text: "Quantidade", value: "amount", width: 100, sortable: false },
        { text: "Vazão Antes", value: "flowRateBefore", width: 130, sortable: false },
        { text: "Vazão Depois", value: "flowRateAfter", width: 130, sortable: false },
        { text: "Economia (%) ", value: "economy", sortable: false },
        { text: "Observação", value: "comments", sortable: false },
        { text: "Img", value: "Img", sortable: false },
        { text: "Ações", value: "action", sortable: false },
      ],
      checkListObj: [],
      optionsType: [
        { text: "Torneira", value: "faucet" },
        { text: "Chuveiro", value: "shower" },
        { text: "Descarga", value: "discharge" },
      ],
      dialogSolution: false,
      editableSolution: [],
      editableIndex: -1,
      loading: {
        actionsSolutionList: false
      }, 
      solutionsData: [],
      pointsData: [],
      deleteItem: null,
      tableKey: 0,
      bodyKey: 0,
      // dataTable: [],
      itemsPerPage: 5,
    };
  },
  methods: {
    ...mapActions([
        'subscribeChannel',
    ]),
    closeDialog(){
      this.dialogSolution = false
    }, 
    forceTableUpdate(newData) {
      // referência: https://michaelnthiessen.com/force-re-render/
      this.checkListObj = newData;
      // Força a reatividade na tabela
      this.tableKey += 1;
    },
    async addSolutionAfterSaveButtonModal(solutionList){
      this.loading.actionsSolutionList = true
      if(this.editableSolution.length) {
        this.checkListObj[this.editableIndex] = solutionList
        this.optionSolution[this.editableIndex] = [solutionList.solution]
      }else{
        this.checkListObj.unshift(solutionList);
        this.optionSolution.unshift([solutionList.solution]);  
      }
      await this.updateSolutionList(this.checkListObj)
      this.loading.actionsSolutionList = false
      this.editableSolution = []
      this.$emit("update:info", this.checkListObj);
      // this.forceTableUpdate()
      this.bodyKey += 1
    },
    addSolutionReport() {
      this.dialogSolution = true
      setTimeout(()=>{
        this.$refs.formConsumption.validate()
        this.$emit("update:form", false);
      },500)      
    },
    async updateSolutionList(solutionList){
      await WaterScanService.updateSolutionListById(solutionList, this.idProposal)
    },
    async confirmDeleteSolution(item, index) {
      this.deleteItem = item
      showDeleteDialogAlert(`Deseja excluir esse item ${item.local}?`, () => this.deleteReportSolution(item, index))
    },
    async deleteReportSolution(item, index) {
      const itemDelete = item
      let total = await WaterScanService.getProposalTotalByRegistration(this.registration)
      if (total === 1) {
        if(itemDelete.imgNameList){
          for(let imgPath of itemDelete.imgNameList){
            await WaterScanStoragefrom.deleteImg(imgPath)
          }
        }
      }
      this.checkListObj = this.checkListObj.filter((_, i) => i !== index);
      this.optionSolution = this.optionSolution.filter((_, i) => i !== index);
      await this.updateSolutionList(this.checkListObj)
      this.deleteItem = null
      this.$emit('status:save', true)
      this.bodyKey += 1
    },
    editReportSolution(solution, index) {
      this.editableSolution = [solution];
      this.editableIndex = index
      this.dialogSolution = true
    },
    clearEditItem() {
      this.editableSolution = []
    },
    resquetEvent() {
      this.$emit("update:info", this.checkListObj);
      if (this.$refs.formConsumption && !this.$refs.formConsumption.validate()) {
        showInfoSnackBar("Preencha os campos obrigatórios do checklist!")
        this.$emit("update:form", false);
        return;
      }else{
        this.$emit("update:form", true);            
      }
    },
    convertNameType(pointId){
      const type = this.pointsData.filter(({id}) => id == pointId)
      return type.length > 0?type[0].name : "Não Existe"
    },
    convertSolutionName(solutionId){
      const solutionFilter = this.solutionsData.filter(({id}) => id == solutionId)
      return solutionFilter.length > 0? solutionFilter[0].solution : "Não Existe"
    },
    async listSolutions(){
      this.solutionsData =  await SolutionsServices.GetSolutions()
    },
    async listPoints(){
      let pointIds = this.solutionsData.map((item) => item.type == 'Produto' ? item.pointId : null).filter((item) => item !== null && item !== undefined)
      let response =  await SolutionsPoint.GetSolutionsPointByIds(pointIds)
      this.pointsData = Object.values(response)
    },
    changeColorIconListImg(line) {
      if(line.imgNameList && line.imgNameList.length > 0){
        return "primary"
      }
      return ""
    },
  },
  computed: {
    rulesRequired() {
      return Rules.required();
    },
  },
  watch: {
    requestData() {
      this.resquetEvent();
    },
    checkListObj() {
      this.resquetEvent();
    },
    solutionListItens(newVal){
      this.forceTableUpdate(newVal)
    },
  },
  async created(){
    await this.listSolutions()
    await this.listPoints()

    this.subscribeChannel({
      channel: 'waterscan',
      events: [
        {
          event: 'solutionList:update',
          fn: (solutionList) => {
            this.forceTableUpdate(solutionList)
            this.itemsPerPage = -1
          }
        },
      ]
    })
  }
};
</script>