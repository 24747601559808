<template>
  <div style="display: flex">
    <v-autocomplete
      dense
      v-model="selected"
      :items="list"
      :label="label"
      item-text="name"
      item-value="id"
      outlined
      hide-details
      :loading="isLoading"
      :disabled="isLoading || list.length == 0"
    >
    </v-autocomplete>
    <div
      v-if="addClearButton"
      class="text-center"
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px;
      "
    >
      <v-btn
        small
        color="blue"
        dark
        style="height: 100%; margin-left: 5px"
        @click="clear_option"
      >
        X
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UsersService from "@/services/server/UsersService";
/**
 * Será emitido a lista de clientes que ao ser escolhido um franqueado
 */
export default {
  data: () => ({
    label: "Clientes",

    list: [],
    // associated_items: [],
    selected: null,
    disabledRank: false,
    by: null,
    id: null,
    isLoadingData: false,
  }),

  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    reset: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    franchiseeId: {
      default: null,
      type: String,
    },
    value: {
      default: null,
      type: String,
    },
    addClearButton: {
      default: false,
      type: Boolean,
    },
    defaultAllClient: {
      default: false,
      type: Boolean,
    },
  },

  computed: {
    ...mapGetters(["getCurrentUser", "getCurrentUserRank"]),
    isLoading() {
      return this.isLoadingData || this.loading ;
    },
    isDisabled() {
      return (
        this.disabled ||
        (this.list.length == 0) ||
        this.disabledRank
      );
    },
  },

  methods: {
    clear_option() {
      this.selected = null;
    },
  },

  watch: {
    reset() {
      if(this.getCurrentUserRank === "Super Admin" || this.getCurrentUserRank === "Admin"){
        this.selected = null;
        this.$emit("id", null);
      }else if(this.selected){
        this.$emit("id", null);
        setTimeout(()=>{
          this.$emit("id", this.selected.id);
        },200)
      }
    },
    selected(id) {
      if (id) {
        this.$emit("id", id);
      } else {
        this.$emit("id", null);
      }
    },
    async franchiseeId(newValue) {
      this.list = [];
      this.selected = null
      this.isLoadingData = true

      const response = await UsersService.findClientsByFranchisee(newValue)
      this.list = response.data.data
      
      if(this.list.length === 1)  this.selected = this.list[0].id
      this.isLoadingData = false
    },
    async value(newValue) {
      this.selected = newValue
    },
  },

  async created() {

  },
};
</script>


<style>
</style>