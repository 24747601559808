<template>
   <v-dialog
        v-if="dialogAlert"
        v-model="dialogAlert"
        persistent
        max-width="300px"
      >    
        <v-card color="primary"> 
          <v-progress-linear
            v-if="loadingBar"
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
          <v-card-title style="color: white">
            {{title}}
            <v-spacer></v-spacer>            
          </v-card-title>
          <v-card-text style="color: white">    
            {{text}}
          </v-card-text>
          <v-card-actions >
            <v-spacer></v-spacer> 
            <v-btn
              :disabled="loadingBar"
              color="white"
              text
              @click="save()"
            >
              Confirmar
            </v-btn>
            <v-btn
              :disabled="loadingBar"
              color="white"
              text
              @click="close()"
            >
              Cancelar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        open: {
          type: Boolean,
          require: true,
          default: false,
        },
        runFunc:{
          type: Function,
          require: true,
        },
        closeFunc:{
          type: Function,
          require: true,
        }     
    },
    data: () => ({
      dialogAlert: false,
      loadingBar: false,
    }),
    methods: {
      async save(){
        this.loadingBar = true
        await this.runFunc();
        this.loadingBar = false
        this.dialogAlert = false
      },
      close() {
      this.loadingBar = true
        this.closeFunc()
        this.loadingBar = false
        this.dialogAlert = false
      },
    },
    watch: {
      open() {
        this.dialogAlert = true
      },
  },
}

</script>