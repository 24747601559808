import { db } from "@/firebase"

const responsible_types_ref = db.collection("responsible_types")

const getResponsibleTypes = async () => {
    let features_list = []
    try {
        const get_data = await responsible_types_ref.get();
        get_data.forEach((doc) => { features_list.push({...doc.data(), id_doc: doc.id}) });
        return features_list;
    } catch (error) {
        throw new Error("getResponsibleTypes Fail!" + error)
    }
}

export default {
    getResponsibleTypes
};