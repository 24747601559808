<template>
    <div :style="{width: width}">
        <!-- <v-text-field
          :value="dateFormat"
          label="Competência"
          type="text"
          readonly
          @click="openDialogDate"
          :disabled="disabled"
        ></v-text-field> -->
        <v-text-field
          label="Competência"
          :value="dateFormat"
          @click="openDialogDate"
        ></v-text-field>
        <date-picker-dialog 
            title="Escolha a competência"
            :type="'month'"
            :show="isOpenDialog"
            @close="status => isOpenDialog = status"
            :input="dateDialog"
            @change="changeDate"
        />
    </div>
</template>

<script>
import DatePickerDialog from "@/components/DatePickerDialog/DatePickerDialog.vue";
import Utils from '@/scripts/Utils';

import { showErrorSnackBar } from "@/utils/SnackBartHelper"

/**
 * 
 * @params {String} competence: é o campo da competência do mês no format de 'YYYY-MM'
 * @params {Array} listCompetencies é um array de datas no formato 'YYYY-MM' que são as competências já criadas
*/
export default {

  components: {
    DatePickerDialog,
  },

  props: {
    width: {
        type: String,
        required: false,
        default: '55px',
    },
    competence: {
        type: String,
        required: false,
        default: null,
    },
    listCompetencies: {
      type: Array,
      required: false,
      default: () => []
    },
    disabled: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data: () => ({
    dateFormat: null,
    dateDialog: null,
    isOpenDialog: false,
  }),

  computed: {
    
  },

  methods: {
    openDialogDate() {
      if (this.disabled) return;
      this.isOpenDialog = true;
    },
    setDateFormat(date) {
      if (date === null) {
        this.dateFormat = null;
      } else {
        this.dateFormat = Utils.formatMonthDate(date, '<MM>/<YY>')
      }
    },
    setDateDialog(date) {
      if (date === null) {
        this.dateDialog = null;
      } else {
        this.dateDialog = Utils.formatDate(date, '<YYYY>-<MM>')
      }
    },
    changeDate(date) {
      if (this.listCompetencies.includes(date)) {
        showErrorSnackBar("Competência já selecionada")
        return;
      }
      this.setNewDate(date)
      // Emissão da data
      this.$emit('change', date)
    },
    setNewDate(date) {
      this.setDateDialog(date)
      this.setDateFormat(date)
    },
  },

  watch: {
    competence: {
        immediate: true,
        handler: function (newValue) {
          this.setNewDate(newValue)
        },
    }
  },

};
</script>