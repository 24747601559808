<template>
    <v-dialog
        v-model="datePickerDialog"
        max-width="338px"
        @click:outside="closeDialog"
    >
        <v-card>
            <v-card-title>
                <span>{{title}}</span>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text>
                <v-date-picker
                    class="date-picker-height"
                    v-model="datePickerModel"
                    locale="pt-br"
                    :min="min"
                    :max="max"
                    :type="type"
                    @change="changeDate"
                ></v-date-picker>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    text
                    @click="closeDialog"
                >
                Fechar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

/**
 * Componente responsável por renderizar a modal de escolha de data
 * atualmente podem ser escolhido somente dia e mês
 * 
 * Propriedades:
 * @param {String} type indica o formato que será manipulado a data
 *  - "date": o formato esperado é "yyyy-mm-dd", é possível escolher dias
 *  - "month": o formato esperado é "yyyy-mm", é possível escolher somente meses
 * @param {Boolean} show indica o status quando será aberto a modal
 * @param {String} title indica o titulo da modam
 * @param {String} min indica a data no formato de string para indica a data minima de escolha
 * @param {String} max indica a data no formato de string para indica a data máxima de escolha
 * @param {String} input indica qual a data atual que já deixe selecionado
 * 
 * Eventos:
 * change (date: String|Null): é emitido o valor da data escolhida de tipo já escolhido
 * close (status: Boolean): é emitido quando a modal é fechado com o status da modal
 */

export default {
    props: {
        show: {
            type: Boolean,
            required: false,
            default: false,
        },
        title: {
            type: String,
            required: false,
            default: "Selecione uma data"
        },
        min: {
            type: String,
            required: false,
            default: null,
        },
        max: {
            type: String,
            required: false,
            default: null,
        },
        input: {
            type: String,
            required: false,
            default: null,
        },
        type: {
            type: String,
            required: false,
            default: 'date',
        },
    },
    data: () => ({
        datePickerDialog:   false,
        datePickerModel:    null,
        datePickerTitle:    null,
        datePickerMin:      null,
        datePickerMax:      null,
    }),
    methods: {
        ...mapActions([
            "closeDialogDate",
            "setInputDialogDate",
            "resetDialogDate",
        ]),
        async changeDate(date) {
            this.paramDialogDate.change(date);
            this.setInputDialogDate(date)
            await this.resetDialogDate();
            this.$emit('change', date)
            this.closeDialog();
        },
        async closeDialog() {
            this.datePickerDialog = false;
            // await this.closeDialogDate();
            this.$emit('close', this.datePickerDialog)
        },
        formatDate(input) {
            let format = input;
            // if (input) {
            //     switch (this.type) {
            //         case 'date':
            //             format = input.toString().replace(/(?<=[0-9]{4}\-[0-9]{2}\-[0-9]{2}).*/, '')
            //         break;
            //         case 'month':
            //             format = input.toString().replace(/(?<=[0-9]{4}\-[0-9]{2}).*/, '')
            //         break;
            //         default:
            //             format = input;
            //     }
            // }
            return format
        },
    },
    computed: {
        ...mapGetters([
            "paramDialogDate",
        ]),
    },
    watch: {
        input(newValue) {
            if (this.input) this.datePickerModel = this.formatDate(this.input);
        },
        show (newValue) {
            this.datePickerDialog = newValue;
        }
    },
    mounted() {
        this.datePickerModel = this.formatDate(this.input);
    },
}

</script>