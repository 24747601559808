import { mapActions, mapGetters, mapMutations } from "vuex";
import BarLineChart from "@/components/charts/BarLineChart.vue";
import GoalsForecastsGraphic from "@/components/charts/GoalsForecastsGraphic.vue";
import DatePickerDialog from "@/components/DatePickerDialog/DatePickerDialog.vue";
import Utils from '@/scripts/Utils';
import firebase from "@/firebase";

const CONSTANTS = {
    route: 'goals_forecasts',
    decimalFixed: 2,

    teste_reduzirConsumo: '15',
    // teste_modemInstallationDate: '2022-01',
    // teste_historicalConsumption: [
    //     {
    //         data: '2021-11',
    //         consumo: '200',
    //         valor: '280'
    //     },
    //     {
    //         data: '2021-12',
    //         consumo: '250',
    //         valor: '320'
    //     },
    //     {
    //         data: '2022-01',
    //         consumo: '300',
    //         valor: '370'
    //     },
    //     {
    //         data: '2022-02',
    //         consumo: '400',
    //         valor: '430'
    //     }
    // ],
    teste_modemInstallationDate: '2022-01',
    teste_historicalConsumption: [
        {
            data: '2021-11',
            consumo: '200',
            valor: '280'
        },
        {
            data: '2021-12',
            consumo: '250',
            valor: '320'
        },
        {
            data: '2022-01',
            consumo: '300',
            valor: '370'
        },
        {
            data: '2022-02',
            consumo: '400',
            valor: '430'
        }
    ],
}

/*
*   Variáveis que indica se está ocupado 
*   um determinado componente ou a página 
*/
const BUSY_VARS_DATA = {
    isBusyPage:         false,
    isBusyClient:      false,
    isBusyUnit:      false,
    isBusyGraphic:      false,
    isBusyGeneratePDF:     false,
};

/*
*   Setar flags de carregamento baseado se está ocupado ou não
*/
const LOADING_FUNCTIONS_COMPUTED = {
    isLoading() {
        return (
            this.isBusyPage
            ||
            this.isLoadingUsers
            ||
            this.isLoadingUnits
        );
    },
    isLoadingClient() {
        return (
            this.isLoading
            ||
            this.isBusyClient
        );
    },
    isLoadingUnit() {
        return (
            this.isLoading
            ||
            this.isBusyUnit
        );
    },
    isLoadingGraphic() {
        return (
            this.isLoading
            ||
            this.isBusyGraphic
        );
    },
    isLoadingGeneratePDF() {
        return (
            this.isLoading
            ||
            this.isBusyGeneratePDF
        );
    },
};

/*
*   Dados e funções da modal para pegar a data
*/
// const DATE_PICKER = {
//     data: {
//         datePickerDialog:   false,
//         datePickerModel:    null,
//         datePickerTitle:    null,
//         datePickerMin:      null,
//         datePickerMax:      null,
//         datePickerTargetIndexData:    null,
//     },
//     methods: {
//         openDatePicker({targetIndex, title, min, max}) {
//             this._clearDatePicker();
//             this._setDatePickerTargetIndexData(targetIndex);
//             this.datePickerDialog   = true;
//             this.datePickerModel    = this[this.datePickerTargetIndexData];
//             this.datePickerTitle    = title === undefined ? "Escolha um data" : title;
//             this.datePickerMin      = min === undefined ? null : min;
//             this.datePickerMax      = max === undefined ? null : max;
//         },
//         closeDatePicker() {
//             this.datePickerDialog = false;
//         },
//         _clearDatePicker() {
//             this.datePickerDialog   = false;
//             this.datePickerModel    = null;
//             this.datePickerTitle    = null;
//             this.datePickerMin      = null;
//             this.datePickerMax      = null;
//         },
//         _setDatePickerTargetIndexData(datePickerTargetIndexData) {
//             if (!datePickerTargetIndexData) throw new Error("Data index is required");
//             if (!this.hasOwnProperty(datePickerTargetIndexData)) throw new Error("Data index not found");
//             this.datePickerTargetIndexData = datePickerTargetIndexData;
//         },
//         _changeDatePickerModel() {
//             this[this.datePickerTargetIndexData] = this.datePickerModel;
//             this.datePickerDialog   = false;
//         },
//     },
// };

export default {

    components: {
        BarLineChart,
        GoalsForecastsGraphic,
        DatePickerDialog,
    },

    data: () => ({
        ...CONSTANTS,
        ...BUSY_VARS_DATA,
        // ...DATE_PICKER['data'],
        averageDays:        [],
        consumptionGoal:  [],
        clientObject:       null,
        unitObject:         null,
        tariffs:            [],
        listUnits:          [],
        consumption:        [],
        consumptionByDate:  [],
        alertDialog:     false,
        message:            '',
        averageConsumption: {},
        historicalAverage:  0,
        reduceHistoricalAverage: 0,
        eixoAbscissa:       [],
        consumptionToPlot:  {},
        totalDays:          0,
        dataGraphic:        {},
        labels:             [],
        forecastConsumption: 0,
        labelTarget:        null,
        totalWater: null,
        totalSewer: null,
        totals: 0,
        // labels:             ['1900', '1950', '1999', '2050'],
        datasets:           [
            // {
            //     label: 'Europe',
            //     type: 'line',
            //     borderColor: '#8e5ea2',
            //     data: [408,547,675,734],
            //     fill: false
            // }, {
            //     label: 'Africa',
            //     type: 'line',
            //     borderColor: '#3e95cd',
            //     data: [133,221,783,2478],
            //     fill: false
            // }, {
            //     label: 'Europe',
            //     type: 'bar',
            //     backgroundColor: 'rgba(0,0,0,0.2)',
            //     data: [408,547,675,734],
            // }, {
            //     label: 'Africa',
            //     type: 'bar',
            //     backgroundColor: 'rgba(0,0,0,0.2)',
            //     backgroundColorHover: '#3e95cd',
            //     data: [133,221,783,2478]
            // }
        ],

        startDate:          null,
        startDateDialog:    false,
        endDate:            null,
        endDateDialog:      false,
        isLiter:            true,
        typeToSearch:       ['supplier', 'well', 'sewer', 'historicalAverage', 'reduceHistoricalAverage'],
        graphicDialog:      false,
        isOpenedGraphic:    false,

    }),
    
    methods: {
        ...mapActions([
            "initUsersAndUnits",
            "requestDailyConsumption",
            "getUnitsByUser",
        ]),
        // ...DATE_PICKER['methods'],
        resetClient() {
            this.clientObject = null;
        },
        resetUnit() {
            this.unitObject = null;
        },
        resetMenu() {
            this.resetClient();
            this.resetUnit();
        },
        openGraphicDialog() {
            this.resetGraphicDialog();
            this.graphicDialog = true;
        },
        closeGraphicDialog() {
            this.graphicDialog = false;
        },
        resetGraphicDialog() {
            this.startDate  = null;
            this.endDate    = null;
            this.isLiter   = false;
        },
        setConsumption(data) {
            this.consumption = data;
        },
        getConsumption() {
            return this.consumption;
        },
        getConsumptionByDate(startDateString, endDateString) {
            let startDate = Utils.getStartDay(startDateString);
            let endDate = Utils.getStartDay(endDateString);
            let today = Utils.getStartDay('today');

            this.totals = 0;
            try {
                this.totals = Utils.getDiffDays(startDate, today) + 1;
            } catch (e) {
                this.totals = 0;
            }
            let consumptionByDate = [];
            this.consumption.forEach(
                (consumption) => {
                    if (consumption.day >= startDate && consumption.day <= endDate && consumption.day <= today) {
                        let dateInfo = Utils.getDateInfo(consumption.day);
                        let consumptionObject = {
                            supplier_liters:    parseFloat(consumption.supplier_liters),
                            sewer_liters:       parseFloat(consumption.sewer_liters),
                            well_liters:        parseFloat(consumption.well_liters),
                            day:                `${dateInfo.day}/${dateInfo.month}`,
                            isForecast:         false,
                        };
                        if (consumption.day <= today) {
                            consumptionByDate.push(consumptionObject);
                        }
                    }
                }
            );
            return consumptionByDate;
        },
        getAverageConsumption(totalIntervalDays) {
            let averageConsumption = {
                supplier: 0,
                well: 0,
                sewer: 0,
            };
            if (totalIntervalDays <= this.consumptionByDate.length) return averageConsumption;
            this.consumptionByDate.forEach(
                (consumption) => {
                    averageConsumption['supplier']   += consumption.supplier_liters;
                    averageConsumption['well']       += consumption.well_liters;
                    averageConsumption['sewer']      += consumption.sewer_liters;
                }
            );
            if (this.totals > 0) {
                averageConsumption['supplier'] /= this.totals;
                averageConsumption['well'] /= this.totals;
                averageConsumption['sewer'] /= this.totals;
            }
            return averageConsumption;
        },
        fillConsumptionByDate(startDateString, endDateString, averageConsumption) {
            let startDate = Utils.getStartDay(startDateString);
            let endDate = Utils.getStartDay(endDateString);
            let today = Utils.getStartDay('today');
            if (today > endDate || today < startDate) return;
            let dateInfo = Utils.getDateInfo(today);
            /**
             * ! Potencialmente pode ser usado a lógica de determinar a data de inicio do preenchimento
             */
            // let formatToday = `${dateInfo.day}/${dateInfo.month}`;
            // let hasTodayReading = this.consumptionByDate.find(c => c.day === formatToday);
            // let nextDay = hasTodayReading !== undefined ? Utils.nextDayFrom(today) : today;
            let nextDay = Utils.nextDayFrom(today-1); // Adicionado -1 para media contar do dia atual tambem
            let factor = this.isLiter ? 1 : 1/1000;
            while (nextDay <= Utils.nextDayFrom(endDate)) {
                dateInfo = Utils.getDateInfo(nextDay);
                let consumptionObject = {
                    // factor,
                    supplier_liters:    averageConsumption['supplier'],
                    sewer_liters:       averageConsumption['sewer'],
                    well_liters:        averageConsumption['well'],
                    day:                `${dateInfo.day}/${dateInfo.month}`,
                    isForecast:         true,
                };
                this.consumptionByDate.push(consumptionObject);
                nextDay = Utils.nextDayFrom(nextDay);
            }
        },
        mountConsumptionToPlot() {
            this.consumptionToPlot = {
                supplierByDate: [],
                wellByDate: [],
                sewerByDate: [],
                historicalAverage: [],
                reduceHistoricalAverage: [],
            };
            if (this.eixoAbscissa.length <= 0) return;
            let supplierByDate          = Array(this.eixoAbscissa.length).fill(0);
            let wellByDate              = Array(this.eixoAbscissa.length).fill(0);
            let sewerByDate             = Array(this.eixoAbscissa.length).fill(0);
            let historicalAverage       = Array(this.eixoAbscissa.length).fill(this.getHistoricalAverage);
            let reduceHistoricalAverage = Array(this.eixoAbscissa.length).fill(this.getReduceHistoricalAverage);
            this.consumptionByDate.forEach(
                (c) => {
                    let indexEixoAbscissas = this.eixoAbscissa.indexOf(c.day);
                    if (indexEixoAbscissas < 0) return;
                    supplierByDate[indexEixoAbscissas]  = this.convertValue(c.supplier_liters, 'liter').toFixed(this.decimalFixed);
                    wellByDate[indexEixoAbscissas]      = this.convertValue(c.well_liters, 'liter').toFixed(this.decimalFixed);
                    sewerByDate[indexEixoAbscissas]     = this.convertValue(c.sewer_liters, 'liter').toFixed(this.decimalFixed);
                }
            );
            this.consumptionToPlot = {
                supplierByDate,
                wellByDate,
                sewerByDate,
                historicalAverage,
                reduceHistoricalAverage,
            };
        },averageLine(){
            let dataMediaHistorico = this.unitObject.dataMediaHistorico
            let historicos = this.unitObject.historicos
            this.consumptionGoal = []
            this.averageDays = []
            let medias = []
            let j = 0
            let total = 0
            for(let i = 0;i < historicos.length;i++){
                if(historicos[i].month < dataMediaHistorico){
                    medias[j] = parseFloat(historicos[i].sewer_cubic_meters)
                    total +=  parseFloat(historicos[i].sewer_cubic_meters)
                    j ++ 
                }
            }
            medias = (total/medias.length)/30
            medias = medias*(this.isLiter ? 1000  : 1)
            if(medias > 0){
                let averageGoal = medias-(medias*(this.unitObject.metaEconomia/100))
                this.averageDays = []
                for(let i = 0;i < this.eixoAbscissa.length;i++){
                    this.averageDays[i] = medias.toFixed(this.decimalFixed);
                    this.consumptionGoal[i] = averageGoal.toFixed(this.decimalFixed);
                }
            }
        }, async getWaterAuthorities(){ 
                let waterAuthorities = await firebase.getWaterAuthorities("compesa")
                this.alertDialog = false
                this.message = ''

                if(waterAuthorities.length === 0){
                    this.alertDialog = true
                    this.message = "Tarifa para calculos não encontrada"
                }else{
                    let testDate = waterAuthorities[0].date
                    let j = 0

                    for(let index in waterAuthorities){  
                    if(Utils.getStartDay(testDate) < Utils.getStartDay(waterAuthorities[index].date)){
                            testDate = waterAuthorities[index].date
                            j = index
                    }
                    }
                    //Utils.getStartDay('today')
                    this.tariffs =  waterAuthorities[j].tariffs
                }
        },
        mountDataGraphic() {
            this.averageLine();
            let datasets = [];
            let labels = [];
            let labelTarget = null;
            let today = Utils.getStartDay('today');
            if (
                this.consumptionToPlot.supplierByDate.length <= 0
                ||
                this.consumptionToPlot.wellByDate.length <= 0
                ||
                this.consumptionToPlot.sewerByDate.length <= 0
                // ||
                // this.consumptionToPlot.historicalAverage.length <= 0
                // ||
                // this.consumptionToPlot.reduceHistoricalAverage.length <= 0
            ) {
                this.labels = labels;
                this.datasets = datasets;
                this.labelTarget = labelTarget;
                return;
            }
            //labelTarget = Utils.formatDate('now', "<DD>/<MM>");
            var data = new Date();
            data.setDate(data.getDate() - 1);

            labels = this.eixoAbscissa;
            labelTarget = Utils.formatDate(data, "<DD>/<MM>")
          
            datasets = [
                {
                    label: "Água",
                    type: 'bar',
                    backgroundColor:  "rgb(30,144,255)",
                    backgroundColorHover: '#3e95cd',
                    data: this.consumptionToPlot.supplierByDate,
                }, {
                    label: "Poço",
                    type: 'bar',
                    backgroundColor: "rgb(15,72,127)",
                    backgroundColorHover: '#3e95cd',
                    data: this.consumptionToPlot.wellByDate,
                }, {
                    label: "Esgoto",
                    type: 'bar',
                    backgroundColor: "rgb(99,99,99)",
                    backgroundColorHover: '#3e95cd',
                    data: this.consumptionToPlot.sewerByDate,
                }
            ]
            if (this.averageDays.length === this.eixoAbscissa.length) {
                datasets.push( {
                    label: "Média de consumo",
                    type: 'line',
                    borderColor: 'rgb(125, 56, 56)',
                    data: this.averageDays,
                    fill: false
                });
            }
            
            if (this.consumptionGoal.length === this.eixoAbscissa.length) {
                datasets.push({
                    label: "Meta",
                    type: 'line',
                    borderColor: 'rgb(173, 248, 2)',
                    data: this.consumptionGoal,
                    fill: false
                });
            }
            // // this.datasets = datasets;
            // this.dataGraphic = {
            //     labels: this.eixoAbscissa,
            //     datasets: datasets,
            // };
            this.labels = labels;
            this.datasets = datasets;
            this.labelTarget = labelTarget;
        },
        /*
        *   É calculado a média do histórico de consumo, é esperado os seguintes parametros:
        *   ----------------------------------------------------------------------
        *   Entrada:
        *   modemInstallationDate: String => yyyy-mm
        *   historicalConsumption: Array<Object> 
        *       Object: {
        *           data: String => yyyy-mm
        *           consumo: Number => em metros cúbicos,
        *           valor: Number => em reais
        *       }
        *   -----------------------------------------------------------------------
        *   Saída:
        *   historicalConsumption: Array<Object> 
        *       Object: {
        *           data: String => yyyy-mm
        *           consumo: Number => em litros,
        *           valor: Number => em reais
        *       }          
        */
        calculateHistoricalAverage(modemInstallationDate = null, historicalConsumption = []) {
            let historicalAverage = 0;
            if (!modemInstallationDate || historicalConsumption.length <= 0) return historicalAverage;
            // let dateModem = Utils.getFirstDayOfMonth(modemInstallationDate);
            let lastDayPreviousMonthOfModem = Utils.previousMonthFrom(modemInstallationDate, true);
            let initialDate = lastDayPreviousMonthOfModem;
            let totalConsumption = 0;
            let parsedHistoricalConsumption = historicalConsumption.map(
                (consumption) => {
                    let consumo = parseFloat(consumption.consumo)*1000;
                    let valor = parseFloat(consumption.valor);
                    return {
                        data: Utils.instanceDate(consumption.data),
                        consumo,
                        valor
                    }
                }
            ).filter(
                (consumption) => {
                    if (lastDayPreviousMonthOfModem >= consumption.data) {
                        initialDate = consumption.data <= initialDate ? consumption.data : initialDate;
                        totalConsumption += consumption.consumo;
                        return true;
                    }
                    return false;
                }
            )
            if (parsedHistoricalConsumption.length <= 0) return historicalAverage;
            // let lastDayPreviousMonthOfModem = Utils.getLastDayOfMonth(modemInstallationDate);
            let now = Utils.instanceDate('now');
            let intervalDays = 0;
            let startDate = null;
            let endDate = null;
            if (lastDayPreviousMonthOfModem > now) {
                startDate = initialDate;
                endDate = now;
            } else {
                startDate = initialDate;
                endDate = lastDayPreviousMonthOfModem;
            }

            intervalDays = Utils.getDiffDays(startDate, endDate);
            historicalAverage = (totalConsumption/intervalDays).toFixed(this.decimalFixed);

            return historicalAverage;
        },
        /*
        *   Calculo da redução da média histórica de consumo
        *   ------------------------------------------------------------
        *   Entrada:
        *   percentage: Number => em %
        *   historicalAverage: Number => em litros
        *   ------------------------------------------------------------
        *   Saída:
        *   reduceHistoricalAverage: Number => em litros
        */
        calculateReduceHistoricalAverage(percentage, historicalAverage) {
            let reduceHistoricalAverage = 0;
            if (percentage === null) return reduceHistoricalAverage;
            let _percentage = parseFloat(percentage);
            if (_percentage < 0 || _percentage > 100) throw new Error("Percentage is invalid");
            reduceHistoricalAverage = (historicalAverage * (1-(_percentage/100))).toFixed(2);
            return reduceHistoricalAverage;
        },
        /*
        *   Calculo da previsão do consumo de acordo com o intervalo escolhido
        *   consumptionToPlot: Array => [
        *       supplierByDate: Array<Number> => Cada posição indica o consumo da água no determinado dia,
        *       wellByDate: Array<Number> => Cada posição indica o consumo do poço no determinado dia,
        *       sewerByDate: Array<Number> => Cada posição indica o esgoto do poço no determinado dia,
        *       historicalAverage: Array<Number> => Cada posição indica a média de consumo no determinado dia,
        *       reduceHistoricalAverage: Array<Number> => Cada posição indica a redução da média de consumo no determinado dia,
        *   ]
        *   grupoFaturamento: String => Determina uma das opções do grupo de faturamento,
        *   
        */
        calculateForecastConsumption(consumptionToPlot, grupoFaturamento, economias) {
            let forecastConsumption = {
                waterValueTotalToPay: 0,
                sewerValueTotalToPay: 0,
                valueTotalToPay: 0,
            }

            if (
                !grupoFaturamento
                || (
                    consumptionToPlot.supplierByDate.length  <= 0
                    && consumptionToPlot.wellByDate.length   <= 0
                    && consumptionToPlot.sewerByDate.length  <= 0
                )
            ) return forecastConsumption;
            // https://lojavirtual.compesa.com.br:8443/gsan/exibirConsultarEstruturaTarifariaPortalAction.do
            // const TARIFFS = {
            //     residencial: [
            //         {
            //             start_liter: 1,
            //             final_liter: 10000,
            //             value: 50.50,
            //             is_fixed: true,
            //         },
            //         {
            //             start_liter: 10001,
            //             final_liter: 20000,
            //             value: 5.79,
            //             is_fixed: false,
            //         },
            //         {
            //             start_liter: 20001,
            //             final_liter: 30000,
            //             value: 6.88,
            //             is_fixed: false,
            //         },
            //         {
            //             start_liter: 30001,
            //             final_liter: 50000,
            //             value: 9.48,
            //             is_fixed: false,
            //         },
            //         {
            //             start_liter: 50001,
            //             final_liter: 90000,
            //             value: 11.23,
            //             is_fixed: false,
            //         },
            //         {
            //             start_liter: 90001,
            //             final_liter: 999999000,
            //             value: 21.58,
            //             is_fixed: false,
            //         },
            //     ],
            //     comercial: [
            //         {
            //             start_liter: 1,
            //             final_liter: 10000,
            //             value: 74.30,
            //             is_fixed: true,
            //         },
            //         {
            //             start_liter: 10001,
            //             final_liter: 999999000,
            //             value: 14.73,
            //             is_fixed: false,
            //         },
            //     ],
            //     industrial: [
            //         {
            //             start_liter: 1,
            //             final_liter: 10000,
            //             value: 93.10,
            //             is_fixed: true,
            //         },
            //         {
            //             start_liter: 10001,
            //             final_liter: 999999000,
            //             value: 19.73,
            //             is_fixed: false,
            //         },
            //     ],
            //     publica: [
            //         {
            //             start_liter: 1,
            //             final_liter: 10000,
            //             value: 71.81,
            //             is_fixed: true,
            //         },
            //         {
            //             start_liter: 10001,
            //             final_liter: 999999000,
            //             value: 10.89,
            //             is_fixed: false,
            //         },
            //     ],
            // }['residential','commercial', 'industrial', 'public']
            // residencial, comercial, industrial , publica

            grupoFaturamento = Utils.createSlug(grupoFaturamento);

            switch(grupoFaturamento){
                case 'residencial':
                    grupoFaturamento = 'residential'
                    break;
                case 'comercial': 
                    grupoFaturamento =  'commercial' 
                    break;
                case 'industrial':
                    grupoFaturamento =  'industrial'
                    break;
                case 'publica':
                    grupoFaturamento =  'public'
                    break;
            } 
            economias = grupoFaturamento !== 'residential' ? 1 : economias ?? 1;

            let _totalWater = consumptionToPlot.supplierByDate.reduce((previous, current) => previous + (parseFloat(current)*(!this.isLiter ? 1000 : 1)), 0);
            let _totalSewer = _totalWater + consumptionToPlot.wellByDate.reduce((previous, current) => previous + (parseFloat(current)*(!this.isLiter ? 1000 : 1)), 0);
            this.totalWater = Math.floor(_totalWater - (_totalWater % 1000));
            this.totalSewer = Math.floor(_totalSewer - (_totalSewer % 1000));

            //For para verificar a existencia do objeto 'tarrifsTypes', 
            //caso exista vai modificar os valores de agua e esgoto dependendo da tarifa
            for(let key in this.unitObject){
                if(key == 'tarrifsTypes'){

                    if(this.unitObject.tarrifsTypes.water.tariffType == 'Real'){     
                        this.totalWater = this.totalWater
                    }else if(this.unitObject.tarrifsTypes.water.tariffType == 'Suprimido 30%'){
                        this.totalWater = this.totalWater*0.3
                    }else if(this.unitObject.tarrifsTypes.water.tariffType == 'Fixo'){
                        this.totalWater = (parseInt(this.unitObject.tarrifsTypes.water.value) * 1000)
                    }
                    if(this.unitObject.tarrifsTypes.sewer.tariffType == 'Real'){
                        this.totalSewer = this.totalSewer
                    }else if(this.unitObject.tarrifsTypes.sewer.tariffType == 'Fixo'){
                        this.totalSewer = (parseInt(this.unitObject.tarrifsTypes.sewer.value) * 1000)
                    }
                }
            }
  




            let counterDownWater = this.totalWater;
            let counterDownSewer = this.totalSewer;

            /**
             * Fazer o calculo de consumo para água e esgoto de acordo com a faixa
             * @param total_liter Total de litros da faixa
             * @param water A quantidade de água que será usado na faixa
             * @param multiplier_value_water Indica qual o multiplicador da água do valor a ser pago na faixa
             * @param value_payment_water Indica o valor que será pago da água na faixa, podendo ser um valor fixo ou por metro cúbico na faixa
             * @param sewer A quantidade de esgoto que será usado na faixa
             * @param multiplier_value_sewer Indica qual o multiplicador do esgoto do valor a ser pago na faixa
             * @param value_payment_sewer Indica o valor que será pago do esgoto na faixa, podendo ser um valor fixo ou por metro cúbico na faixa
             */

            let rangers = this.tariffs[grupoFaturamento].map(
                (ranger) => {
                    let total_liters = ((ranger.final_liter - ranger.start_liter) + 1) * economias;
                    let water = 0;
                    let multiplier_value_water = 0;
                    let value_payment_water = ranger.is_fixed ? ranger.value : 0;
                    let sewer = 0;
                    let multiplier_value_sewer = 0;
                    let value_payment_sewer = 0;

                    if (counterDownWater > 0) {
                        counterDownWater -= total_liters;
                        water = counterDownWater < 0 ? (counterDownWater + total_liters) : total_liters;
                        multiplier_value_water = ranger.is_fixed ? 1 : water/1000;
                        value_payment_water = multiplier_value_water * ranger.value;
                    }

                    if (counterDownSewer > 0) {
                        counterDownSewer -= total_liters;
                        sewer = counterDownSewer < 0 ? (counterDownSewer + total_liters) : total_liters;
                        multiplier_value_sewer = ranger.is_fixed ? 1 : sewer/1000;
                        value_payment_sewer = multiplier_value_sewer * ranger.value;
                    }

                    return {
                        ...ranger,
                        total_liters,
                        water,
                        multiplier_value_water,
                        value_payment_water,
                        sewer,
                        multiplier_value_sewer,
                        value_payment_sewer,
                    }
                }
            );
            let waterValueTotalToPay = rangers.reduce((p, c) => p + c.value_payment_water, 0);
            let sewerValueTotalToPay = rangers.reduce((p, c) => p + c.value_payment_sewer, 0);
            let valueTotalToPay = waterValueTotalToPay + sewerValueTotalToPay;

            forecastConsumption = {
                waterValueTotalToPay,
                sewerValueTotalToPay,
                valueTotalToPay,
            }

            return forecastConsumption;
        },
        /*
        *   Função que faz todos os cálculos para plotar o gráfico
        */
        openGraphic() {
            this.isOpenedGraphic = true;
            this.graphicDialog = false;
            this.historicalAverage = 0;
            this.reduceHistoricalAverage = 0;
        
            let intervalDates = Utils.generateDatesByInterval(this.startDate, this.endDate);
            let intervalInfoDates = Utils.getDateInfoFromList(intervalDates);
            this.eixoAbscissa = intervalInfoDates.map(info => `${info.day}/${info.month}`);
            this.consumptionByDate = this.getConsumptionByDate(this.startDate, this.endDate);
            let totalIntervalDays = intervalDates.length;
            this.averageConsumption = this.getAverageConsumption(totalIntervalDays);            
            this.fillConsumptionByDate(this.startDate, this.endDate, this.averageConsumption); 
            /*
            *   dataMediaHistorico: String => Date => formato: yyyy-mm || null
            *   historicos: Array<Historico>
            */
            let dataMediaHistorico = this.unitObject.hasOwnProperty('dataMediaHistorico') ? this.unitObject.dataMediaHistorico : null; 
            let historicos = this.unitObject.hasOwnProperty('historicos') ? this.unitObject.historicos : []; 
            this.historicalAverage = this.calculateHistoricalAverage(
                dataMediaHistorico,
                historicos
            );

            /*
            *   metaEconomia: Number => entre 0 a 100 indica a porcentagem || null
            */
            let metaEconomia = this.unitObject.hasOwnProperty('metaEconomia') ? this.unitObject.metaEconomia : null; 

            this.reduceHistoricalAverage = this.calculateReduceHistoricalAverage(metaEconomia, this.historicalAverage);
            this.mountConsumptionToPlot();

            let economias = this.unitObject.hasOwnProperty('economias') ? this.unitObject.economias : null; 
            let grupoFaturamento = this.unitObject.hasOwnProperty('grupoFaturamento') ? this.unitObject.grupoFaturamento : null; 
            this.forecastConsumption = this.calculateForecastConsumption(
                this.consumptionToPlot, 
                grupoFaturamento, 
                economias
            );


            this.mountDataGraphic()

        },
        async onChangeUnit(unit) {
            this.getWaterAuthorities();
            this.defaultValues();
            this.isBusyPage = true;
            this.setConsumption([]);
            await this.requestDailyConsumption(this.unitObject.id);
            this.setConsumption(this.getDailyConsumption(this.unitObject));
            this.isBusyPage = false;
        },
        async onChangeClient(client) {
            this.defaultValues();
            this.setConsumption([]);
            this.unitObject = null;
            this.listUnits = await this.getUnitsByUser(client);
        },
        defaultValues() {
            this.startDate = null;
            this.endDate = null;
            this.isOpenedGraphic = false;
            this.graphicDialog = false;
            this.historicalAverage = 0;
            this.reduceHistoricalAverage = 0;
        },
        /*
        *   Converte o valor medido para a unidade destino escolhido pelo usuário
        */
        convertValue(measure, unitOfMeasurement = 'l') {
            let factor = 1;
            switch (unitOfMeasurement.toString()) {
                case 'l':
                case 'liter':
                    factor = this.isLiter ? 1 : 1/1000;
                break;
                case 'm3':
                case 'cubic meter':
                case 'cubic metre':
                    factor = this.isLiter ? 1000 : 1;
                break;
            }
            return parseFloat(measure) * factor;
        },
    },

    watch: {
        /*
        *   Requisitar o consumo da unidade caso mude-a
        */
        // async unitObject(newValue) {
            
        // },
    },

    computed: {
        ...mapGetters([
            "isLoadingUsers",
            "isLoadingUnits",
            "getClients",
            "getDailyConsumption",
        ]),
        ...LOADING_FUNCTIONS_COMPUTED,
        isReadySelect() {
            return (
                this.clientObject !== null
                &&
                this.unitObject !== null
                &&
                this.consumption.length > 0
            );
        },
        isReadyOpenGraphic() {
            return (
                this.isReadySelect
                &&
                this.startDate !== null
                &&
                this.endDate !== null
                &&
                this.isOpenedGraphic
                &&
                !this.graphicDialog
            );
        },
        formatStartDate() {
            return Utils.formatDate(this.startDate, '<DD>/<MM>/<YYYY>');
        },
        formatEndDate() {
            return Utils.formatDate(this.endDate, '<DD>/<MM>/<YYYY>');
        },
        formatSubheaderGraphic() {
            let startDateFormat = Utils.formatDate(this.startDate, '<DD>/<MM>/<YYYY>');
            let endDateFormat = Utils.formatDate(this.endDate, '<DD>/<MM>/<YYYY>');
            if (!startDateFormat || !endDateFormat) return "Selecione um período de intervalo";
            return `Intervalo de ${Utils.formatDate(this.startDate, '<DD>/<MM>/<YYYY>')} até ${Utils.formatDate(this.endDate, '<DD>/<MM>/<YYYY>')}`
        },
        getHistoricalAverage() {
            return this.convertValue(this.historicalAverage, 'liter').toFixed(this.decimalFixed);
            // return (this.historicalAverage * (this.isLiter ? 1000 : 1)).toFixed(this.decimalFixed);
        },
        getReduceHistoricalAverage() {
            return this.convertValue(this.reduceHistoricalAverage, 'liter').toFixed(this.decimalFixed);
        },
        getWaterValueTotalToPay() {
            let value = this.forecastConsumption.waterValueTotalToPay;
            return  isNaN(value) ? '--' : Utils.formatCurrency(value, 2);
        },
        getSewerValueTotalToPay() {
            let value = this.forecastConsumption.sewerValueTotalToPay;
            return isNaN(value) ? '--' : Utils.formatCurrency(value, 2);
        },
        getValueTotalToPay() {
            let value = this.forecastConsumption.valueTotalToPay;
            return isNaN(value) ? '--' : Utils.formatCurrency(value, 2);
        },
        getListUnits() {
            return this.listUnits;
        },
        getTotalWater() {
            let value = this.totalWater * (this.isLiter ? 1  : 1/1000);
            return isNaN(value) ? '--' : `${Utils.formatNumber(value)} ${this.isLiter ? 'litros' : 'm³'}`;
        },
        getTotalSewer() {
            let value = this.totalSewer * (this.isLiter ? 1  : 1/1000);
            return isNaN(value) ? '--' : `${Utils.formatNumber(value)} ${this.isLiter ? 'litros' : 'm³'}`;
        },
        getTotalConsumption() {
            let total = (this.totalSewer + this.totalWater) * (this.isLiter ? 1  : 1/1000);
            return isNaN(total) ? '--' : `${Utils.formatNumber(total)} ${this.isLiter ? 'litros' : 'm³'}`;
        }
    },

    created() {
        this.initUsersAndUnits();
    },

}