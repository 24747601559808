import * as XLSX from 'xlsx-js-style';
import firebase from '@/firebase';

export const generateXlsx = async (dataUnit) => {
  let wb = XLSX.utils.book_new();

  dataUnit.map((unit, index) => {
    let dataLines = []

    unit.monthSelect.map((line) => {
      let month = []
      for (let key in line) {
        let formatValue = month.length % 2 == 0 ?
          { alignment: { horizontal: "left" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "FFFFFF" } } }
          :
          { alignment: { horizontal: "left" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "F7F7F7" } } }
        month.push({
          s: formatValue, v: line[key]
        })
      }

      dataLines.push(month)

    })

    let whiteLine = [
      { v: " ", t: "s", s: { fill: { fgColor: { rgb: "FFFFFF" } } } },
    ];
    let headersUnitsData = [
      { v: unit.name, t: "s", s: { alignment: { horizontal: "center", vertical: "center", wrapText: true }, font: { name: "Roboto", bold: true, sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0086D7" } } } },
      { v: "", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", bold: true, sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", bold: true, sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "Tipo de Consumo", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", bold: true, sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "Matricula", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", bold: true, sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "Endereço", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", bold: true, sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", bold: true, sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", bold: true, sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
    ];
    let unitsData = [
      { v: " ", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: " ", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: " ", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: unit.tipoConsumo, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: unit.matricula, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: unit.address.uf + ", " + unit.address.bairro + ", " + unit.address.logradouro, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: " ", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: " ", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
    ];
    let headersGeneralData = [
      { v: "Período", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "Referencia", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "056AAE" } } } },
      { v: "Valor", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "Água (L)", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "056AAE" } } } },
      { v: "Esgoto (L)", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "Economia", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "056AAE" } } } },
      { v: "", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "Meta", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
    ];
    let generalData = [
      { v: "Período", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "FFFFFF" } } } },
      { v: unit.reference, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "F7F7F7" } } } },
      { v: unit.account_value, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "FFFFFF" } } } },
      { v: unit.water_liters, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "F7F7F7" } } } },
      { v: unit.sewer_liters, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "FFFFFF" } } } },
      { v: unit.economy_monetary, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "F7F7F7" } } } },
      { v: unit.economy_percentage, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "FFFFFF" } } } },
      { v: unit.goal, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "F7F7F7" } } } },
    ];
    let headersDetails = [
      { v: "Data", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "Referencia", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "056AAE" } } } },
      { v: "Valor", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "Água (L)", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "056AAE" } } } },
      { v: "Esgoto (L)", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "Economia", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "056AAE" } } } },
      { v: "", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "Meta", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
    ];
    const datailName = [
      { v: "Detalhados", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 14, bold: true, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "095183" } } } },
    ];
    const generalDataName = [
      { v: "DADOS GERAIS", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 16, bold: true, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "095183" } } } },
    ];

    let ws = XLSX.utils.aoa_to_sheet([
      headersUnitsData,
      unitsData,
      whiteLine,
      generalDataName,
      headersGeneralData,
      generalData,
      whiteLine,
      datailName,
      headersDetails,
      ...dataLines
    ]);
    var wscols = [
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];
    ws['!cols'] = wscols;

    XLSX.utils.book_append_sheet(wb, ws, index + "-" + unit.name.substr(0, 25));
    for (let key in wb.Sheets) {
      wb.Sheets[key]["!merges"] = [
        { s: { r: 0, c: 0 }, e: { r: 1, c: 2 } },
        { s: { r: 0, c: 5 }, e: { r: 0, c: 7 } },
        { s: { r: 1, c: 5 }, e: { r: 1, c: 7 } },
        { s: { r: 2, c: 0 }, e: { r: 2, c: 7 } },
        { s: { r: 3, c: 0 }, e: { r: 3, c: 7 } },
        { s: { r: 4, c: 5 }, e: { r: 4, c: 6 } },
        { s: { r: 6, c: 0 }, e: { r: 6, c: 7 } },
        { s: { r: 7, c: 0 }, e: { r: 7, c: 7 } },
        { s: { r: 8, c: 5 }, e: { r: 8, c: 6 } },
      ]
    }
  })
  XLSX.writeFile(wb, `Relatorio-Economia.xlsx`);

}
export const generateResumeXlsx = async (dataClient) => {
  let wb = XLSX.utils.book_new();
  const infosClient = await firebase.getUserbyId(dataClient[0].id)
  dataClient.map((client) => {
    let lengthNamesUnits = []
    let dataLines = []
    client.resultSumHistoric.map((unitResume, index) => {
      lengthNamesUnits.push(unitResume.name.length)
      dataLines.push([
        { v: unitResume.name, t: "s", s: { alignment: { horizontal: "left" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "FFFFFF" } } } },
        { v: unitResume.reference, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "F7F7F7" } } } },
        { v: unitResume.account_value, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "FFFFFF" } } } },
        { v: unitResume.water_liters, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "F7F7F7" } } } },
        { v: unitResume.sewer_liters, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "FFFFFF" } } } },
        { v: unitResume.economy_monetary, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "F7F7F7" } } } },
        { v: unitResume.economy_percentage, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "F7F7F7" } } } },
        { v: unitResume.goal, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "FFFFFF" } } } }
      ])
    })
    var maxLengthNameUnit = lengthNamesUnits.reduce(function(a, b) {
      return Math.max(a, b);
    }, -Infinity);

    let whiteLine = [
      { v: " ", t: "s", s: { fill: { fgColor: { rgb: "FFFFFF" } } } },
    ];
    let headersClientData = [
      { v: client.name, t: "s", s: { alignment: { horizontal: "center", vertical: "center", wrapText: true }, font: { name: "Roboto", bold: true, sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0086D7" } } } },
      { v: infosClient.metadata.idType, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", bold: true, sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", bold: true, sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", bold: true, sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "Endereço", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", bold: true, sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", bold: true, sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
    ];
    let clientData = [
      { v: " ", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: infosClient.metadata.idNumber, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: " ", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: " ", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: infosClient.metadata.address.uf + ", " +infosClient.metadata.address.bairro + ", " + infosClient.metadata.address.logradouro, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: " ", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
    ];
    let headersGeneralData = [
      { v: "Período", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "Referencia", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "056AAE" } } } },
      { v: "Valor", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "Água (L)", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "056AAE" } } } },
      { v: "Esgoto (L)", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "Economia", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "056AAE" } } } },
      { v: "", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "Meta", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
    ];
    let generalData = [
      { v: "Período", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "FFFFFF" } } } },
      { v: client.reference, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "F7F7F7" } } } },
      { v: client.account_value, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "FFFFFF" } } } },
      { v: client.water_liters, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "F7F7F7" } } } },
      { v: client.sewer_liters, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "FFFFFF" } } } },
      { v: client.economy_monetary, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "F7F7F7" } } } },
      { v: client.economy_percentage, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "FFFFFF" } } } },
      { v: client.goal, t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "6b6b6b" } }, fill: { fgColor: { rgb: "F7F7F7" } } } },
    ];
    let headersDetails = [
      { v: "Unidade", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "Referencia", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "056AAE" } } } },
      { v: "Valor", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "Água (L)", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "056AAE" } } } },
      { v: "Esgoto (L)", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "Economia", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "056AAE" } } } },
      { v: "", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
      { v: "Meta", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 12, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "0C77BD" } } } },
    ];
    const datailName = [
      { v: "Detalhados", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 14, bold: true, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "095183" } } } },
    ];
    const generalDataName = [
      { v: "DADOS GERAIS", t: "s", s: { alignment: { horizontal: "center" }, font: { name: "Roboto", sz: 16, bold: true, color: { rgb: "ffffff" } }, fill: { fgColor: { rgb: "095183" } } } },
    ];
    let ws = XLSX.utils.aoa_to_sheet([
      headersClientData,
      clientData,
      whiteLine,
      generalDataName,
      headersGeneralData,
      generalData,
      whiteLine,
      datailName,
      headersDetails,
      ...dataLines
    ]);
    var wscols = [
      { wch: maxLengthNameUnit*1.3 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
      { wch: 20 },
    ];
    ws['!cols'] = wscols;

    XLSX.utils.book_append_sheet(wb, ws,client.name.substr(0, 25));
    wb.Sheets[client.name.substr(0, 25)]["!merges"] = [
      { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } },
      { s: { r: 0, c: 1 }, e: { r: 0, c: 3 } },
      { s: { r: 1, c: 1 }, e: { r: 1, c: 3 } },
      { s: { r: 0, c: 4 }, e: { r: 0, c: 7 } },
      { s: { r: 1, c: 4 }, e: { r: 1, c: 7 } },
      { s: { r: 2, c: 0 }, e: { r: 2, c: 7 } },
      { s: { r: 3, c: 0 }, e: { r: 3, c: 7 } },
      { s: { r: 4, c: 5 }, e: { r: 4, c: 6 } },
      { s: { r: 6, c: 0 }, e: { r: 6, c: 7 } },
      { s: { r: 7, c: 0 }, e: { r: 7, c: 7 } },
      { s: { r: 8, c: 5 }, e: { r: 8, c: 6 } },
    ]

  })


  XLSX.writeFile(wb, `Relatorio-Economia.xlsx`);

}
