import check from 'check-types'
import Utils from '../../../scripts/Utils';
import SignatureModel from './SignatureModel';
import WaterFixService from '../../../services/firebase/WaterFix/WaterFixService';
import WaterFixStorage from '../../../services/storage/WaterFixStorage.js';

export default class SignatureController {
    constructor() {}

    read_signatures_from_proposal(proposal) {
        try {
            var signatures = Utils.getting(proposal, 'signatures')
            signatures = signatures.map(
                (signature) => {
                    return new SignatureModel(signature)
                }
            )
            return signatures
        } catch (e) {
            return []
        }
    }

    async upload_signature(proposalId, registration, termOption, signatureURL) {
        const imgNameSaved = new Date().getTime() +"_"+termOption
        const signatureFilepath = `water_fix_imgs/${registration}/${proposalId}/signature/${imgNameSaved}`
        const signatureBlob = await Utils.dataURLToBlob(signatureURL)
        await WaterFixStorage.uploadImageByFilepath(signatureFilepath, signatureBlob)
        return signatureFilepath
    }

    async save_signature(proposalId, registration, termOption, signatureURL, signatures) {
        const signatureFilepath = await this.upload_signature(proposalId, registration, termOption, signatureURL)
        const signature = new SignatureModel({
            type: termOption,
            imageList: [signatureFilepath],
        })
        const signatureData = signature.get()
        signatures.push(signatureData)
        await WaterFixService.updateProposal(proposalId, {
            'signatures': signatures,
        })
        return signatures
    }

    async reset_all_signatures(proposalId) {
        var proposal = await WaterFixService.getProposalById(proposalId)
        var { registration, signatures } = proposal
        signatures = signatures ? signatures : []
        let signaturesToDelete = signatures.filter(
            ({ type }) => {
                return type !== 'inspection-term'
            }
        )
        signatures = signatures.filter(
            ({ type }) => {
                return type === 'inspection-term'
            }
        )
        await WaterFixService.updateProposal(proposalId, {
            'signatures': signatures,
        })
        for (let i = 0; i < signaturesToDelete.length; i++) {
            const { imageList } = signaturesToDelete[i]
            for (let y = 0; y < imageList.length; y++) {
                await WaterFixStorage.deleteImg(imageList[y])
            }
        }
        return signatures
    }
}