import Utils from '@/scripts/Utils';
import firebase  from '@/firebase';
const INTERVAL_UPDATE = 60*30*1000

class WaterAuthotity {

    static getByCompany(list, company) {
        return new Promise(
            (resolve, reject) => {
                try {
                    let companySlug = Utils.createSlug(company)
                    let listByCompany = list.filter(water => water.company === companySlug)
                    resolve(listByCompany)
                } catch (e) {
                    reject(e)
                }
            }
        );
    }

    static createStats(list) {
        let newStats = {}
        list.forEach(
            (water, index) => {
                let id = water.id;
                newStats[id] = {
                    id: id,
                    index: index,
                    nextRequest: Date.now() + INTERVAL_UPDATE
                }
            }
        );
    }

    static mergeList(list, newestList) {
        let keys = {}
        newestList.forEach((water) => {
            keys[water.id] = water;
        })
        list = list.map(
            (water) => {
                if (keys.hasOwnProperty(water.id)) {
                    water = newestList[water.id]
                }
            }
        );
    }

    static requestByCompany(company) {
        return new Promise(
            async (resolve, reject) => {
                try {
                    let companySlug = Utils.createSlug(company)
                    let listByCompany = await firebase.getWaterAuthorities(companySlug);
                    resolve(listByCompany)
                } catch (e) {
                    reject(e)
                }
            }
        );
    }

    static requestAll() {
        return new Promise(
            async (resolve, reject) => {
                try {
                    let list = await firebase.getWaterAuthorities();
                    resolve(list)
                } catch (e) {
                    reject(e)
                }
            }
        );
    }

}

export default WaterAuthotity;