import { mapActions, mapGetters } from "vuex";
import firebase from "@/firebase"
import AlertsService from "@/services/firebase/Alerts/AlertsService";
import AlertApi from "@/services/apiPdf/AlertApi";
import EmailService from "@/services/email/Email";
import DialogSendEmail from "@/components/DialogSendEmail/DialogSendEmail";
import { filterAssociatedUnit } from "@/utils/AssocietedUnitsFilter"
import FormReport from "@/components/Forms/Report/Report.vue";
import UnitsService from "@/services/server/UnitsService";
import SectorsService from "@/services/firebase/Sectors/SectorsService";
import UsersService from "@/services/server/UsersService.js";
import AlertsConfigService from "@/services/firebase/AlertsConfig/AlertsConfigService.js";
import { showErrorSnackBar, showSuccessSnackBar } from "@/utils/SnackBartHelper"
import Utils from '@/scripts/Utils';

export default {
  components: {
    FormReport,
    DialogSendEmail
  },
  data() {
    return {
      alerts_data: [],
      alertsConfigData: [],
      show_expand: true,
      main_headers: [
        {
          text: 'Cliente',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Qntd.', value: 'size', align: 'center' },
        { text: 'Meta', value: 'goal', align: 'center' },
        { text: 'Alertas de Consumo', value: 'consumption', align: 'center' },
        { text: 'Ações', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      secondary_headers: [
        {
          text: 'Unidade',
          align: 'start',
          sortable: false,
          value: 'name'
        },
        { text: 'Qntd.', value: 'size', align: 'center' },
        { text: 'Meta', value: 'goal', align: 'center' },
        { text: 'Alertas de Consumo', value: 'consumption', align: 'center' },
        { text: 'Ações', value: 'actions', sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      franchisee_id: null,
      client_id: null,
      unit_id: null,
      sector_id: null,
      startDatePicker: [Utils.formatDateDayJs(Utils.subtractDate(new Date(),1), "YYYY-MM-DD"),
      Utils.formatDateDayJs(new Date(), "YYYY-MM-DD")],
      select_unit: [],
      select_unit_adress: null,
      select_sector_name: null,
      titles_button_download: [{ title: "PDF" }, { title: "Email" }],
      date_format: null,
      reset_client: true,
      last_type_selected: null,
      last_serach_alerts: null,
      data_table: [],
      data_table_expand: [],
      data_table_filter_days: [],
      summary: {
        consumption: 0,
        goal: 0,
        size: 0
      },
      desabled: {
        search_button: true,
        report_view: false
      },
      startDateDialog: false,
      maxStartDate: '',
      notification: {
        open: false,
        text: ""
      },
      dialog_send_email: false,
      data_body_email: false,
      report_type: ''
    }
  },
  methods: {
    ...mapActions(["getClientsBy", "getUnitsBy"]),
    async catch_report_tipe(type, func, prop, status) {
      this.report_type = type
      func(prop, status)
    },
    async catch_id(data_id, onExpand = false) {
      if (!onExpand) this.summary = {
        consumption: 0,
        goal: 0,
        size: 0
      }
      const id = data_id.id

      if (!onExpand) {
        this.data_table = []
        this.last_serach_alerts = data_id
        this.last_type_selected = data_id.type
      } else {
        this.data_table_expand = []
      }
      this.show_expand = true
      let alerts = []
      let alertsListTable = []

      try {
        switch (data_id.type) {
          case "franchisee":
            this.franchisee_id = id;
            if (!onExpand) this.setHeaders("client")
            else this.setHeadersExpand("client")
            this.alerts_data = await this.searchAlertList("id_franchisee", this.franchisee_id)
            alertsListTable = await this.filterAlerts(this.alerts_data, "id_client")
            await this.getClientsName(this.franchisee_id, alertsListTable)
            await this.sumValuesTable(alertsListTable)
            if (!onExpand) await this.summarySum(alertsListTable)
            break;
          case "client":
            this.client_id = id;
            if (!onExpand) this.setHeaders("unit")
            else this.setHeadersExpand("unit")
            alerts = this.alerts_data.filter((alert) => alert.id_client === this.client_id)
            alertsListTable = await this.filterAlerts(alerts, "id_unit")
            await this.getUnitsName(this.client_id, alertsListTable)
            await this.sumValuesTable(alertsListTable)
            if (!onExpand) await this.summarySum(alertsListTable)
            break;
          case "unit":
            if (!onExpand) this.setHeaders("sector")
            else this.setHeadersExpand("sector")
            if (this.last_type_selected === "unit") this.show_expand = false
            this.unit_id = id;
            alerts = this.alerts_data.filter((alert) => alert.id_unit === this.unit_id)
            alertsListTable = await this.alterAlertListSector(alerts)

            alertsListTable.map((alert) => {
              this.summary.size += 1
              switch (alert.type) {
                case "goal_exceeded":
                  this.summary.goal += 1
                  break;
                case "goal_scheduled":
                  this.summary.goal += 1
                  break;
                case "consumption":
                  this.summary.consumption += 1
                  break;
              }
            })
            break;
          default:
            alert("Tipo não identificado entre em contato com o suporte!")
            break;
        }

      } catch {
        alert("Erro não identificado entre em contato com o suporte!")
      }
      if (alertsListTable.length === 0) showErrorSnackBar("Não existe alertas para o item selecionado!")
      !onExpand ? this.data_table = alertsListTable : this.data_table_expand = alertsListTable
    },
    async alterAlertListSector(alertsGroup) {
      function changeTypeName(type) {
        switch (type) {
          case 'consumption':
            return "Consumo"
          case 'goal_exceeded':
            return "Meta"
          case 'goal_scheduled':
            return "Meta Programada"
          default:
            return "Não definido!"
        }
      }
      let listSectors = []
      if (alertsGroup.length) listSectors = await SectorsService.getSectorsByUnitId(alertsGroup[0].id_unit)
      return alertsGroup.map((alert) => {
        const alertConfig = this.alertsConfigData.find(({ id_doc }) => id_doc === alert.id_alert_config)
        const sectorByAlert = listSectors.find(({ id_doc }) => id_doc === alert.id_sector)
        const date_of_occurrence = Utils.formatDate(new Date(alert.date_of_occurrence.seconds * 1000), '<DD>/<MM>/<YYYY> às <hh>:<mm>:<ss>')
        return {
          ...alert,
          name: alertConfig.name,
          sectorName: sectorByAlert ? sectorByAlert.name : "",
          typeName: changeTypeName(alert.type),
          type: alert.type,
          point_exceeded: alert.point_exceeded,
          limit_consumption: +alert.liters_consumed_in_period.toFixed(2) - +alert.liters_exceeded_in_period.toFixed(2),
          liters_consumed_in_period: +alert.liters_consumed_in_period.toFixed(2),
          liters_exceeded_in_period: Number(alert.liters_exceeded_in_period.toFixed(2)),
          date_of_occurrence,
        }
      })
    },
    async filterAlerts(AlertsData, entity) {
      let list = []
      if (AlertsData.length > 0) {
        AlertsData.map(alert => {
          let resultSearchName = list.map(i => {
            return i.name === alert[entity]
          }).find(resultMap => resultMap === true)

          if (!resultSearchName) {
            list.push({
              name: alert[entity],
              size: 1,
              alerts: [alert]
            })
          } else {
            list.map((n) => {
              if (n.name === alert[entity]) {
                n.alerts = [...n.alerts, alert]
                n.size += 1
              }
            })
          }
        })
      }
      return list
    },
    async sumValuesTable(data) {
      data.map((generalAlert, index) => {
        let goal = 0
        let consumption = 0
        generalAlert.alerts.map((alert) => {
          switch (alert.type) {
            case "goal_exceeded":
              goal += 1
              break;
            case "goal_scheduled":
              goal += 1
              break;
            case "consumption":
              consumption += 1
              break;
          }
        })
        data[index] = {
          ...data[index],
          goal,
          consumption,
          index
        }
      })
    },
    async sendEmail(receiver, message) {
      try {
        if (!this.data_body_email) throw "Body não definido!"
        const name = this.data_body_email.name
        const user_email = receiver
        const relatorio = "Alertas"
        const data = {
          id: this.data_body_email.data.id,
          currentUser: this.data_body_email.data.currentUser,
          rank: this.data_body_email.data.rank,
          report_type: 'PDF',
          type: this.data_body_email.data.type
        }
        const date_time = this.data_body_email.startDatePicker
        const file_name = this.data_body_email.name.split(" ").join("_")
        const endpoint = "/report/alert"

        await EmailService.sendEmail({
          name,
          user_email,
          relatorio,
          data,
          date_time,
          file_name,
          endpoint
        })

        showSuccessSnackBar("Email enviado!")
      } catch (err) {
        console.error(err)
        showErrorSnackBar("Erro ao enviar email!")
      }
      this.data_body_email = null
    },

    async getClientsName(franchiseeId, data) {
      const response = await UsersService.findClientsByFranchisee(franchiseeId)
      const clientsByFranchisee = response.data.data
      data.map(({ name }, i) => {
        let client_data = clientsByFranchisee.filter(({ id }) => name === id)
        data[i].id = data[i].name
        data[i].name = client_data[0].name
      })
    },
    async getUnitsName(clientId, data) {
      const response = await UnitsService.findAllByClient(clientId)
      const unitsByClient = response.data.data
      data.map(({ name }, i) => {
        let unit_data = unitsByClient.filter(({ id }) => name === id)
        if (unit_data.length > 0) {
          data[i].id = data[i].name
          data[i].name = unit_data[0].name
        }
      })
    },
    async getSectoresName(unitId, data) {
      let list = []
      let { sectors } = await firebase.getUnitbyId(unitId);

      if (sectors && sectors.length > 0) {
        list = sectors.map((i) => {
          if (i.name && i.modem) return { name: i.name, id: i.modem.id };
        });

        data.map(({ name }, i) => {
          let sector_data = list.filter(({ id }) => name === id)
          data[i].id = data[i].name
          data[i].name = sector_data[0].name
        })

      } else {
        delete data[0]
        showErrorSnackBar("A Unidade não possui setores vinculados!")
      }
    },
    async summarySum(data) {

      this.summary = {
        consumption: 0,
        goal: 0,
        size: 0
      }
      data.map((line) => {
        this.summary.consumption += line.consumption
        this.summary.goal += line.goal
        this.summary.size += line.size
      })
    },
    formatDateTimestamp() {
      let date = this.startDatePicker.map(date => {
        return (new Date(date).getTime())
      })
      if (date[0] > date[1]) {
        date = [
          date[1],
          date[0]
        ]
      }
      date = [
        new Date(date[0] + 10800000), // 00:00
        new Date(date[1] + 86400000 + 10799999), // 23:59:59
      ]
      this.startDatePicker
      return date
    },
    async onExpand({ item, value }) {
      let type = null
      switch (this.last_type_selected) {
        case "franchisee":
          type = "client"
          break;
        case "client":
          type = "unit"
          break;
      }
      await this.catch_id({ id: item.id, type }, true)
    },
    async getAlertsPerData(selectDate) {
      this.startDatePicker = selectDate
      if (this.last_serach_alerts) {
        await this.catch_id(this.last_serach_alerts, false)
      }
    },
    async searchAlertList(entity, id = null) {
      return await AlertsService.getAlertsPayloadConsumptionAndEntity(entity, id, this.formatDateTimestamp())
    },
    setHeadersExpand(action) {
      switch (action) {
        case 'client':
          break;
        case 'unit':
          if (this.secondary_headers.length === 8) this.secondary_headers.push({ text: 'Ações', value: 'actions', sortable: false, align: 'center' })
          break;
        case 'sector':
          this.secondary_headers = this.secondary_headers.filter(({ value }) => value !== "actions")
          break;
      }
    },
    setHeaders(action) {
      switch (action) {

        case 'client':

          this.main_headers = [{
            text: 'Cliente',
            align: 'start',
            sortable: false,
            value: 'name'
          },
          { text: 'Qntd.', value: 'size', align: 'center' },
          { text: 'Meta', value: 'goal', align: 'center' },
          { text: 'Alertas de Consumo', value: 'consumption', align: 'center' },
          { text: 'Ações', value: 'actions', sortable: false },
          { text: '', value: 'data-table-expand' }]

          this.secondary_headers = [
            {
              text: 'Unidade',
              align: 'start',
              sortable: false,
              value: 'name'
            },
            { text: 'Qntd.', value: 'size', align: 'center' },
            { text: 'Meta', value: 'goal', align: 'center' },
            { text: 'Alertas de Consumo', value: 'consumption', align: 'center' },
            { text: 'Ações', value: 'actions', sortable: false },
            { text: '', value: 'data-table-expand' },
          ]

          break;
        case 'unit':


          this.secondary_headers = [{
            text: 'Alerta',
            align: 'start',
            sortable: false,
            value: 'name'
          },
          { text: 'Tipo', value: 'typeName', align: 'center' },
          { text: 'Consumo no periodo.', value: 'liters_consumed_in_period', align: 'center' },
          { text: 'Consumo Excedido', value: 'liters_exceeded_in_period', align: 'center' },
          { text: 'Limite de Consumo', value: 'limit_consumption', align: 'center' },
          { text: 'Data', value: 'date_of_occurrence', align: 'center' },
          ]

          this.main_headers = [{
            text: 'Unidade',
            align: 'start',
            sortable: false,
            value: 'name'
          },
          { text: 'Qntd.', value: 'size', align: 'center' },
          { text: 'Meta', value: 'goal', align: 'center' },
          { text: 'Alertas de Consumo', value: 'consumption', align: 'center' },
          { text: 'Ações', value: 'actions', sortable: false },
          { text: '', value: 'data-table-expand' }]

          break;
        case 'sector':
          this.main_headers = [{
            text: 'Alerta',
            align: 'start',
            sortable: false,
            value: 'name'
          },
          { text: 'Setor', value: 'sectorName', align: 'center' },
          { text: 'Tipo', value: 'typeName', align: 'center' },
          { text: 'Consumo no periodo.', value: 'liters_consumed_in_period', align: 'center' },
          { text: 'Consumo Excedido', value: 'liters_exceeded_in_period', align: 'center' },
          { text: 'Limite de Consumo', value: 'limit_consumption', align: 'center' },
          { text: 'Data', value: 'date_of_occurrence', align: 'center' },
          ]

          break;
      }
    },

    async generatePdf(item, status = null) {
      let data = {}
      data.id = item.id
      data.currentUser = this.getCurrentUser.uid
      data.rank = this.getCurrentUser.data.rank
      switch (this.last_serach_alerts.type) {
        case "franchisee":
          data.type = status ? "id_unit" : "id_client"
          break;
        case "client":
          data.type = status ? "id_modem" : "id_unit"
          if (item.entity) data.type = "id_client"
          break;
        case "unit":
          data.type = "id_modem"
          if (item.entity) data.type = "id_unit"
          break;
        default:
          console.error("type error!")
      }
      if (this.report_type === "Email") {
        this.dialog_send_email = !this.dialog_send_email
        this.data_body_email = { data, startDatePicker: this.startDatePicker, name: item.name || await this.getClientName(item) }
      } else {

        let response = await AlertApi.generatePdf(data, this.startDatePicker)
        const nameReportDownload = (this.orderStartDate()[0] + "_" + this.orderStartDate()[1] + " " + (item.name || await this.getClientName(item)))
        response.data && response.data.success == true ? await AlertApi.downloadPdf(nameReportDownload) : showErrorSnackBar("Erro ao gerar pdf!")
      }
    },
    async getClientName(item) {
      let data = []
      if (item.entity === "client") data = await firebase.getUserbyId(item.id)
      else data = await firebase.getUnitbyId(item.id)
      return data.name
    },
    orderStartDate() {
      if (this.startDatePicker[0] > this.startDatePicker[1]) {
        this.startDatePicker = [
          this.startDatePicker[1],
          this.startDatePicker[0]
        ]
      }
      this.startDatePicker = [
        this.startDatePicker[0],
        this.startDatePicker[1]
      ]
      return this.startDatePicker
    },
  },
  computed: {
    ...mapGetters([
      "getCurrentUser"
    ])
  },
  async created() {
    this.alertsConfigData = await AlertsConfigService.getAlertsConfig()
  }
}