<template>
    <!-- <v-col cols="12">
        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            scrollable
        >
            <v-card tile>
                <div 
                    style="
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        background-color: #1976d2;
                        height: 64px;
                    "
                >
                    <v-btn
                        icon
                        dark
                        @click="() => closeModal()"
                        style="
                            height: 100%;
                            width: 80px;
                        "
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title 
                        style="
                            display: flex; 
                            align-items: center; 
                            color: white;
                        "
                    >Assinatura</v-toolbar-title>
                    <v-toolbar-items>
                        <v-btn
                            dark
                            text
                            @click="() => saveModal()"
                        >
                            Salvar
                        </v-btn>
                    </v-toolbar-items>
                </div>
                <v-card-text>
                    <v-row justify="center">
                        <v-col cols="12">
                            <v-card>
                                <v-card-text
                                    style="
                                        height: 65vh;
                                    "
                                >
                                    <iframe
                                        :src="pdfUrl"
                                        width="100%"
                                        height="500px"
                                        frameborder="0"
                                        allowfullscreen
                                        style="transform-origin: 0 0; transition: transform 0.3s ease; height: 100%;"
                                    ></iframe>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-divider></v-divider>

                    <v-row justify="center" style="margin-top: 0px;">
                        <v-col cols="12">
                            <v-card>
                                <div 
                                    style="
                                        width: 100%;
                                        display: flex;
                                        justify-content: space-between;
                                        background-color: #1976d2;
                                        height: 64px;
                                        padding: 0px 15px;
                                    "
                                >
                                    <v-toolbar-title 
                                        style="
                                            display: flex; 
                                            align-items: center; 
                                            color: white;
                                        "
                                    >Assine abaixo</v-toolbar-title>
                                    <v-btn
                                        icon
                                        dark
                                        @click="() => this.$refs.signaturePad.clearSignature()"
                                        style="
                                            height: 100%;
                                            width: 80px;
                                        "
                                    >
                                        Limpar
                                    </v-btn>
                                </div>
                                <v-card-text>
                                    <SignaturePad ref="signaturePad" />
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                </v-card-text>

                <div style="flex: 1 1 auto;"></div>
            </v-card>
        </v-dialog>
    </v-col> -->
</template>
<script>
import { mapActions } from "vuex";
import WaterScanService from '@/services/firebase/WaterScan/WaterScanService';
import WaterFixService from '@/services/firebase/WaterFix/WaterFixService';
import Utils from "@/scripts/Utils";
import Rules from "@/utils/Rules";
import SignaturePad from "../SignaturePad.vue";

export default {
    props: {
        proposal: {
            required: false,
            default: () => ({
                signatures: {}
            })
        },
        // dialog: {
        //     type: Boolean,
        //     require: true,
        // },
        // pdfUrl: {
        //     type: String,
        //     require: true,
        // },
    },
    components: {
        SignaturePad
    },
    data: () => ({
        id: null,
        registration: null,
        signatures: {},
        // loading: false,
        
    }),
    methods: {
        // ...mapActions([
        //     'publishChannel',
        // ]),
        // closeModal() {
        //     this.publishChannel({
        //         channel: 'waterfix',
        //         event: `signature:modal`,
        //         value: false,
        //     })
        // },
        // saveModal() {
        //     this.loading = true
        //     const dataURL = this.$refs.signaturePad.saveSignature()
        //     if (dataURL === null) {
        //         alert("Assine o documento abaixo!")
        //         this.loading = false
        //         return
        //     }
        //     // Emitir um evento de assinatura
        //     this.publishChannel({
        //         channel: 'waterfix',
        //         event: `signature`,
        //         value: dataURL,
        //     })
        //     this.$refs.signaturePad.clearSignature()
        //     this.loading = false
        // },
    },
    watch: {
        proposal: {
            immediate: true,
            handler: function (proposal) {
                if (proposal) {
                    this.id = proposal.id
                    this.registration = proposal.registration
                    this.signatures = proposal.signatures
                }
            }
        }
    },
}

</script>

<style scoped>

</style>