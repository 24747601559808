import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { RoutesPath } from '@/router/paths.js';

// Import de Páginas
import Configs from '@/pages/Configs/ChooseConfigs';
import Login from '@/pages/Login/Login';
import Users from '@/pages/Configs/Users/Users';
import Modems from '@/pages/Configs/Modems/Modems';
import Alerts from '@/pages/Configs/Alerts/Alerts';
import Roles from '@/pages/Configs/Roles/Roles';
import SolutionsServices from '@/pages/Solutions/SolutionsServices/SolutionsServices.vue';
import SolutionsGroup from '@/pages/Solutions/Groups/Groups.vue';
import SolutionsPoint from '@/pages/Solutions/Point/Point.vue';
import ChooseSolutions from '@/pages/Solutions/ChooseSolutions.vue';
import Dashboard from '@/pages/Dashboard/Dashboard';
import GoalsForscasts from '@/pages/GoalsForecasts/GoalsForecasts';
import Consumption from '@/pages/Consumption/Consumption';
import ChooseAlerts from '@/pages/Alerts/ChooseAlerts';
import AlertsConsumption from '@/pages/Alerts/AlertsConsumption/AlertsConsumption';
import AlertsTelemet from '@/pages/Alerts/AlertsTelemet/AlertsTelemet';
import AlertsGoal from '@/pages/Alerts/AlertsGoal/AlertsGoal';
import ReportAlert from '@/pages/Report/Alert/Report';
import Called from '@/pages/Called/Called';
import ReportCalled from '@/pages/Report/Called/Report';
import ChooseReports from '@/pages/Report/ChooseReport';
import ReportEconomy from '@/pages/Report/Economy/Report';
import ReportTelemet from '@/pages/Report/Telemetry/Report';
import Units from '@/pages/Units/Units';
import ChooseWaterFix from '@/pages/WaterFix/ChooseWaterFix.vue';
import WaterFixNewProposal from '@/pages/WaterFix/NewProposal/NewWaterFix.vue';
import WaterFixConsultProposal from '@/pages/WaterFix/ConsultProposal/ConsultWaterFix.vue';
import ChooseWaterScan from '@/pages/WaterScan/ChooseWaterScan.vue';
import WaterScanNewProposal from '@/pages/WaterScan/NewProposal/NewWaterScan.vue';
import WaterScanConsultProposal from '@/pages/WaterScan/ConsultProposal/ConsultWaterScan.vue';
import WaterAuthoritiesCompesa from '@/pages/Configs/WaterAuthorities/Compesa/Compesa';
import NotFound from '@/pages/NotFound/NotFound';
import Forbidden from '@/pages/Forbidden/Forbidden';
import Projects from '@/pages/Projects/Projects.vue';
import ProjectDetails from '@/pages/Projects/ProjectDetails.vue';

import { MenuFeatures, SubMenuFeatures } from "@/router/features.js";

Vue.use(VueRouter);

const createRoute = (path, name, component, meta) => ({ path, name, component, meta });

const routes = [
  createRoute(RoutesPath.CHOOSE_ALERT, 'Alertas', ChooseAlerts, {
    auth: true,
    feature: MenuFeatures.ALERTS,
  }),
  createRoute(RoutesPath.ALERT_CONSUMPTION, 'AlertasC', AlertsConsumption, {
    auth: true,
    feature: SubMenuFeatures.CONSUMPTION_ALERTS,
  }),
  createRoute(RoutesPath.ALERT_TELEMETRY, 'AlertasT', AlertsTelemet, {
    auth: true,
    feature: SubMenuFeatures.TELEMETRY_ALERTS,
  }),
  createRoute(RoutesPath.ALERT_GOAL, 'AlertasG', AlertsGoal, {
    auth: true,
    feature: SubMenuFeatures.GOAL_ALERTS,
  }),
  createRoute(RoutesPath.CHOOSE_SETTING, 'Settings', Configs, {
    auth: true,
    feature: MenuFeatures.CONFIGS,
  }),
  createRoute(RoutesPath.DASHBOARD, 'Dashboard', Dashboard, {
    auth: true,
    feature: MenuFeatures.DASHBOARD,
  }),
  createRoute(RoutesPath.CONSUMPTION, 'Consumption', Consumption, {
    auth: true,
    feature: MenuFeatures.CONSUMPTION,
  }),
  createRoute(RoutesPath.SETTING_USERS, 'Usuários', Users, {
    auth: true,
    feature: SubMenuFeatures.USERS_CONFIG,
  }),
  createRoute(RoutesPath.CHOOSE_WATER_FIX, 'ChooseWaterFix', ChooseWaterFix, {
    auth: true,
    feature: MenuFeatures.WATERFIX,
  }),
  createRoute(RoutesPath.WATER_FIX_NEW, 'NewWaterFix', WaterFixNewProposal, {
    auth: true,
  }),
  createRoute(RoutesPath.WATER_SCAN_CONSULT, 'ConsultWaterScan', WaterScanConsultProposal, {
    auth: true,
    feature: SubMenuFeatures.CONSULT_WATERSCAN,
  }),
  createRoute(RoutesPath.CHOOSE_WATER_SCAN, 'ChooseWaterScan', ChooseWaterScan, {
    auth: true,
    feature: MenuFeatures.WATERSCAM,
  }),
  createRoute(RoutesPath.WATER_SCAN_NEW, 'NewWaterScan', WaterScanNewProposal, {
    auth: true,
  }),
  createRoute(RoutesPath.WATER_FIX_CONSULT, 'ConsultWaterFix', WaterFixConsultProposal, {
    auth: true,
    feature: SubMenuFeatures.CONSULT_WATERFIX,
  }),
  createRoute(RoutesPath.CALLED, 'Called', Called, {
    auth: true,
    feature: MenuFeatures.CALLEDS,
  }),
  createRoute(RoutesPath.UNITS, 'Unidades', Units, {
    auth: true,
    feature: MenuFeatures.UNITS,
  }),
  createRoute(RoutesPath.SETTING_SOLUTIONS, 'Soluções', SolutionsServices, {
    auth: true,
    feature: SubMenuFeatures.SOLUTIONS,
  }),
  createRoute(RoutesPath.SETTING_GROUP, 'SoluçõesGroup', SolutionsGroup, {
    auth: true,
    feature: SubMenuFeatures.GROUPS,
  }),
  createRoute(RoutesPath.SETTING_POINT, 'SoluçõesPoint', SolutionsPoint, {
    auth: true,
    feature: SubMenuFeatures.POINTS,
  }),
  createRoute(RoutesPath.CHOOSE_SOLUTION, 'ChooseSolutions', ChooseSolutions, {
    auth: true,
    feature: MenuFeatures.SOLUTIONS,
  }),
  createRoute(RoutesPath.LOGIN, 'Login', Login, {
    auth: false,
  }),
  createRoute(RoutesPath.SETTING_ALERTS, 'Alerts', Alerts, {
    auth: true,
    feature: SubMenuFeatures.ALERTS_CONFIG,
  }),
  createRoute(RoutesPath.SETTING_PERMISSIONS, 'Permissions', Roles, {
    auth: true,
    feature: SubMenuFeatures.PERMISSIONS_CONFIG
  }),
  createRoute(RoutesPath.SETTING_MODEMS, 'Modems', Modems, {
    auth: true,
    feature: SubMenuFeatures.MODEMS_CONFIG,
  }),
  createRoute(RoutesPath.SETTING_WATER_AUTHORITIES, 'Compesa', WaterAuthoritiesCompesa, {
    auth: true,
    feature: SubMenuFeatures.WATERAUTHORITIES_CONFIG
  }),
  createRoute(RoutesPath.GOAL_FORECASTS, 'Metas e Previsões', GoalsForscasts, {
    auth: true,
    feature: MenuFeatures.GOAL_FORECASTS,
  }),
  createRoute(RoutesPath.REPORT_ECONOMY, 'Relatório de Economia', ReportEconomy, {
    auth: true,
    feature: SubMenuFeatures.ECONOMY_REPORT,
  }),
  createRoute(RoutesPath.CHOOSE_REPORT, 'Relatório', ChooseReports, {
    auth: true,
    feature: MenuFeatures.REPORTS,
  }),
  createRoute(RoutesPath.REPORT_CALLED, 'Relatório de Chamados', ReportCalled, {
    auth: true,
    feature: SubMenuFeatures.CALLED_REPORT,
  }),
  createRoute(RoutesPath.REPORT_TELEMETRY, 'Relatório de Telemetria', ReportTelemet, {
    auth: true,
    feature: SubMenuFeatures.TELEMETRY_REPORT,
  }),
  createRoute(RoutesPath.REPORT_ALERT, 'Relatório de Alertas', ReportAlert, {
    auth: true,
    feature: MenuFeatures.REPORTS,
  }),
  createRoute(RoutesPath.NOTFOUND, 'NotFound', NotFound, {
    auth: false,
  }),
  createRoute(RoutesPath.FORBIDDEN, 'Forbidden', Forbidden, {
    auth: false,
  }),
  createRoute(RoutesPath.PROJECTS, 'Projetos', Projects, {
    auth: true,
    feature: MenuFeatures.PROJECTS,
  }),
  createRoute(RoutesPath.PROJECTS_DETAILS, 'Detalhes do Projeto', ProjectDetails, {
    auth: true,
    feature: MenuFeatures.PROJECTS,
  }),
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  next()
  if (to.matched.length === 0 || !store.state.loggedIn) next('/')
  else if (to.matched[0].meta.auth) {
    const currentUser = store.getters.getCurrentUserData;
    if (currentUser.permissions.includes(to.matched[0].meta.feature)) next();
    else next('/forbidden')
  }

});

export default router;
