<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

Chart.defaults.scale.gridLines.display = false;

// TODO opção de tirar o eixo Y nas props e, consequentemente, colocar as labels Y diretamente em cada uma
export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ["chartData", "titulo", "showYAxis"],
  data() {
    return {
      options: {
        scaleShowValues: true,
        title: {
          display: true,
          text: this.titulo
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            { 
              gridLines: { display: false }, 
              ticks: {
                autoSkip: false,
                beginAtZero: true,
                maxTicksLimit: 25 // DEBUG
              },
            }
          ],
          yAxes: [
            {
              display: this.showYAxis != undefined ? this.showYAxis : true,
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 8
              }
            }
          ]
        },
        hover: {
          mode: "nearest",
          intersect: true
        },
        onClick: function clickHandler(evt) {
          var firstPoint = this.getElementAtEvent(evt)[0];

          if (firstPoint) {
            var label = this.data.labels[firstPoint._index];
            var value = this.data.datasets[firstPoint._datasetIndex].data[
              firstPoint._index
            ];

            evt.stopPropagation()

            document.dispatchEvent(
              new CustomEvent("openDay", {
                detail: label
              })
            );
          }
        },
        plugins: {
          datalabels: !this.showYAxis ? {
            anchor: 'end',
            align: 'end',
            clamp: true
          } : false
        }
      },
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  watch: {
    chartData: () => {
      this.renderChart(this.chartData, this.options);
    }
  },
  computed: {
    // filteredData: function(){
    //   if(this.chartData.length > 7 && this.showYAxis){
    //     // ? run sorting algorithm to choose 7 max labels
    //     let newArray = [], last = this.chartData.length -1
    //     //using roof, remove midway, first and last, then x1.3, x1.6, x0.7 and x0.4 from mid
    //     newArray.push([this.chartData[0], this.chartData[last], this.chartData[Math.floor(last/2)]])

    //     newArray.push([this.chartData[Math.floor((last/2)*0.4)], this.chartData[Math.floor((last/2)*0.7)]])
    //     newArray.push([this.chartData[Math.floor((last/2)*1.3)], this.chartData[Math.floor((last/2)*1.6)]])

    //     return newArray
    //   }else return this.chartData
    // }
  }
};
</script>