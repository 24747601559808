<template>
    <div>
        <v-dialog
            v-model="datePickerDialog"
            max-width="338px"
            @click:outside="closeDatePicker"
        >
            <v-card>
                <v-card-title>
                    <span>Escolha uma data</span>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                    <v-date-picker
                        v-model="date"
                        locale="pt-br"
                    ></v-date-picker>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="primary"
                        text
                        @click="closeDatePicker"
                    >
                    Fechar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        
        <v-text-field 
            v-model="item" 
            outlined 
            :label="label" 
            :type="'text'" 
            @change="inputChange"
            @focus="inputFocus"
            @blur="inputBlur"
            @keyup.delete="clearInput"
            :loading="loading"
            :disabled="disabled"
            :rules="noRule ? [] : rules"
            @click="openDatePicker"
            readonly
            >
            <v-icon
                slot="append"
                color="primary"
                @click="openDatePicker"
            >
                mdi-calendar
            </v-icon>
        </v-text-field>
    </div>
</template>

<script>
import Rules from "@/utils/Rules";
import Utils from '@/scripts/Utils';

export default {
    name: "DateField",
    props: {
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        value: {
            type: String,
            default: '',
        },
        noRule: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            required: false,
            default: 'Data',
        },
        rules: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    components: {

    },
    data: () => ({
        item: null,
        date: null,
        focus: false,
        datePickerDialog: false,
    }),
    methods: {
        inputChange() {
            this.emitLoading(true)
            this.emitDisabled(true)
            this.emitInput()
        },
        inputFocus() {
            this.focus = true
            this.emitFocus(this.focus)
        },
        inputBlur() {
            this.focus = false
            this.emitFocus(this.focus)
        },
        emitLoading(status) {
            this.$emit('loading:input', status)
        },
        emitDisabled(status) {
            this.$emit('disabled:input', status)
        },
        emitFocus(status) {
            this.$emit('focus:input', status)
        },
        emitInput() {
            this.$emit('change:input', this.date)
            this.emitLoading(false)
            this.emitDisabled(false)
            this.emitFocus(false)
        },
        openDatePicker() {
            this.datePickerDialog = true;
        },
        closeDatePicker() {
            this.datePickerDialog = false;
        },
        clearInput(e) {
            this.date = null
            this.item = null
        },
    },
    computed: {
        
    },
    watch: {
        item() {
            this.inputChange()
        },
        value: {
            immediate: true,
            handler: function (newVal) {
                if (newVal) {
                    this.date = newVal
                    this.item = Utils.formatDate(newVal, "<DD>/<MM>/<YYYY>")
                }
            }
        },
        date(newVal) {
            if (newVal) {
                this.item = Utils.formatDate(newVal, "<DD>/<MM>/<YYYY>")
                this.closeDatePicker()
            }
        }
    },
};
</script>