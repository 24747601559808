<template>
  <div style="">
    <h3 v-if="title !== null" style="">{{ title }}</h3>
    <v-skeleton-loader v-show="loading" type="image"></v-skeleton-loader>
    <v-list v-show="!loading"  style="max-height: calc(100vh - 260px); overflow-y: auto;">
      <h4 v-show="!data.length">Não existem alertas</h4>
      <v-list-item v-for="alert in data" :key="alert.id" >
        <v-list-item-content>
          <div><strong>{{ alert.alertName }}</strong></div>
          <div>{{ formatDate(alert.date_of_occurrence) }}</div>
          <div ><em>{{ alert.secotorNameOrIdModem }}</em></div>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import Utils from '@/scripts/Utils';
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    data: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false, // Indica se o valor está carregando
    },
  },
  data() {
    return {
    
        
      
    };
  },

  methods: {
    formatDate(date){
      const d = new Date(date._seconds * 1000);
      return Utils.formatDateDayJs(d, "DD/MM/YYYY [as] HH:MM")
    }
  },
 
};

</script>
